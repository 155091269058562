import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';

import { DefaultLayout } from '@/component';
import { Details } from '@/views/importLicensing/importLicensingDetail/Details';

export const ImportLicensingDetailPage = () => {
  const { t } = useTranslation(['importLicensingInfo']);

  return (
    <DefaultLayout>
      <DefaultLayout.Header>
        <Breadcrumb>
          <Breadcrumb.Item>{t('importLicensingInfo:detailPage.title')}</Breadcrumb.Item>
        </Breadcrumb>
      </DefaultLayout.Header>
      <DefaultLayout.Content>
        <Details />
      </DefaultLayout.Content>
    </DefaultLayout>
  );
};
