import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from '@gomi/component';
import { Modal } from 'antd/es';
import { flatMap, some } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ImportLicensingDocumentStatusEnum,
  ImportLicensingSheetStepEnum,
} from '@/generated/graphql-react-query';
import { useContentListHook } from '@/hooks/importLicensing/details/useContentListHook';
import { useControllerHandler } from '@/hooks/importLicensing/details/useControllerHandler';
import { useImportLicensingHook } from '@/hooks/importLicensing/details/useImportLicensingHook';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';

export const Actions = () => {
  const {
    importLicensingDetailModel: { steps },
  } = useStore();
  const { importLicensingId } = useParams<IParams>();
  const { t } = useTranslation(['importLicensingInfo']);

  /* 해당 스탭의 자료 상태 - */
  const { list, refetch: refetchContentList } = useContentListHook({
    importLicensingId,
    step: ImportLicensingSheetStepEnum.DocumentUploadPerProduct,
  });

  const { refetch } = useImportLicensingHook({
    importLicensingId,
  });

  const { requestConfirmation, isLoading } = useControllerHandler();

  const handleRequest = async () => {
    Modal.confirm({
      title: t('importLicensingInfo:detailPage.steps.common.requestConfirmation.title'),
      icon: <ExclamationCircleOutlined />,
      content: t(
        'importLicensingInfo:detailPage.steps.common.requestConfirmation.content',
      ),
      okText: t('importLicensingInfo:detailPage.steps.common.requestConfirmation.ok'),
      cancelText: t(
        'importLicensingInfo:detailPage.steps.common.requestConfirmation.cancel',
      ),
      centered: true,
      onOk: async () => {
        await requestConfirmation({
          input: {
            id: importLicensingId,
            update: {
              step: ImportLicensingSheetStepEnum.DocumentUploadPerProduct,
            },
          },
        });
        refetch();
        refetchContentList();
      },
    });
  };

  // 자료 중 준비 완료가 있을 경우
  const canCheckRequest =
    list.length &&
    some(
      flatMap(list, (item) => item.documentList),
      { status: ImportLicensingDocumentStatusEnum.ReadyDone },
    );

  const isDoneStep = steps.status === ImportLicensingDocumentStatusEnum.Done;
  const isDisabled = steps.status === ImportLicensingDocumentStatusEnum.RequestConfirm;
  return !isDoneStep ? (
    <>
      <Button
        color='danger'
        btnType='outlined'
        onClick={handleRequest}
        isDisabled={!canCheckRequest || isLoading || isDisabled}
        className='gm-mr-1'
      >
        {t('importLicensingInfo:detailPage.controlBar.checkRequestButton')}
      </Button>
    </>
  ) : null;
};
