import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import i18next from 'i18next';
import { uniq } from 'lodash-es';
import { TFunction } from 'react-i18next';

import { CURRENCY_UNIT } from '@/constant/common';
import { CountryEnum, ProductRequestTypeEnum } from '@/generated/graphql-react-query';
import { IPriceInfo } from '@/views/product/common/modal/PriceTab';

const { confirm } = Modal;

/** 해당 브랜드의 진출국가 */
export const getEntryOfCountry = (productGroup?: {
  category?: string | null;
  krRecommendedSalesPrice?: string | null;
  productGroupKrName?: string | null;
  productGroupKrNameKeyword?: string | null;
  productGroupName: string;
  productGroupNameKeyword?: string | null;
  recommendedSalesPrice?: string | null;
  brand?: {
    countryIds: Array<CountryEnum>;
    id: string;
    brandName: string;
    brandKrName: string;
  } | null;
  company?: {
    id: string;
    companyName: string;
    companyKrName: string;
  } | null;
}) => uniq(productGroup?.brand?.countryIds);

/** 상품 상세모달 > detail tab > 나라별 필드 표시 정보 */
export const entryOfCountryDetailField = (t: TFunction<'productInfo'[]>) => ({
  [CountryEnum.Vn]: {
    importLicenseName: {
      name: 'importLicenseNameVn',
      label: t('productInfo:label.importLicenseNameVn'),
      message: t('productInfo:message.importLicenseNameVnEmpty'),
      placeholder: t('productInfo:placeholder.importLicenseNameVn'),
    },
  },
  [CountryEnum.Th]: {
    importLicenseName: {
      name: 'importLicenseNameTh',
      label: t('productInfo:label.importLicenseNameTh'),
      message: t('productInfo:message.importLicenseNameThEmpty'),
      placeholder: t('productInfo:placeholder.importLicenseNameVn'),
    },
  },
  [CountryEnum.Cl]: null,
  [CountryEnum.Jp]: null,
  [CountryEnum.Kr]: null,
  [CountryEnum.Sg]: null,
});

/** 상품 상세모달 > price tab > 나라별 필드 표시 정보 */
export const entryOfCountryPriceField = (
  t: TFunction<'productInfo'[]>,
  productInfo?: IPriceInfo,
) => ({
  [CountryEnum.Vn]: {
    unit: CURRENCY_UNIT.VN,
    retailPrice: {
      name: 'retailPriceVn',
      label: t('productInfo:label.retailPriceVn'),
      value: `${productInfo?.retailPriceVn} ${CURRENCY_UNIT.VN}`,
      message: t('productInfo:message.retailPriceVnEmpty'),
    },
    regularSalePrice: {
      name: 'regularSalePriceVn',
      label: t('productInfo:label.regularSalePriceVn'),
      value: `${productInfo?.regularSalePriceVn} ${CURRENCY_UNIT.VN}`,
      message: t('productInfo:message.regularSalePriceVnEmpty'),
    },
    discountSalePrice: {
      name: 'discountSalePriceVn',
      label: t('productInfo:label.discountSalePriceVn'),
      message: t('productInfo:message.discountSalePriceVnEmpty'),
    },
    discountSalePriceS: {
      name: 'discountSalePriceVnS',
      value: `${productInfo?.discountSalePriceVnS} ${CURRENCY_UNIT.VN}`,
    },
    discountSalePriceA: {
      name: 'discountSalePriceVnA',
      value: `${productInfo?.discountSalePriceVnA} ${CURRENCY_UNIT.VN}`,
    },
    discountSalePriceB: {
      name: 'discountSalePriceVnB',
      value: `${productInfo?.discountSalePriceVnB} ${CURRENCY_UNIT.VN}`,
    },
  },
  [CountryEnum.Th]: {
    unit: CURRENCY_UNIT.TH,
    retailPrice: {
      name: 'retailPriceTh',
      label: t('productInfo:label.retailPriceTh'),
      value: `${productInfo?.retailPriceTh} ${CURRENCY_UNIT.TH}`,
      message: t('productInfo:message.retailPriceThEmpty'),
    },
    regularSalePrice: {
      name: 'regularSalePriceTh',
      label: t('productInfo:label.regularSalePriceTh'),
      value: `${productInfo?.regularSalePriceTh} ${CURRENCY_UNIT.TH}`,
      message: t('productInfo:message.regularSalePriceThEmpty'),
    },
    discountSalePrice: {
      name: 'discountSalePriceTh',
      label: t('productInfo:label.discountSalePriceTh'),
      message: t('productInfo:message.discountSalePriceThEmpty'),
    },
    discountSalePriceS: {
      name: 'discountSalePriceThS',
      value: `${productInfo?.discountSalePriceThS} ${CURRENCY_UNIT.TH}`,
    },
    discountSalePriceA: {
      name: 'discountSalePriceThA',
      value: `${productInfo?.discountSalePriceThA} ${CURRENCY_UNIT.TH}`,
    },
    discountSalePriceB: {
      name: 'discountSalePriceThB',
      value: `${productInfo?.discountSalePriceThB} ${CURRENCY_UNIT.TH}`,
    },
  },
  [CountryEnum.Cl]: null,
  [CountryEnum.Jp]: null,
  [CountryEnum.Kr]: null,
  [CountryEnum.Sg]: null,
});

/** 검수자 confirm 창 */
export const showConfirmInspector = (
  requestType: ProductRequestTypeEnum,
  onOk: () => Promise<void>,
) => {
  const titleStore = {
    [ProductRequestTypeEnum.Logistics]: i18next.t(
      'productInfo:title.logisticsRegistrationTitle',
    ),
    [ProductRequestTypeEnum.Price]: i18next.t('productInfo:title.priceRegistrationTitle'),
    [ProductRequestTypeEnum.Detail]: i18next.t(
      'productInfo:title.detailRegistrationTitle',
    ),
    [ProductRequestTypeEnum.Basic]: i18next.t('productInfo:title.basicRegistrationTitle'),
  };

  confirm({
    title: titleStore[requestType],
    icon: <ExclamationCircleOutlined />,
    content: (
      <>
        {i18next.t('productInfo:message.registration')}
        <br />
        {i18next.t('productInfo:message.registration2')}
      </>
    ),
    okText: i18next.t('partnerInfo:modal.button.registration'),
    cancelText: i18next.t('partnerInfo:modal.button.cancel'),
    centered: true,
    onOk,
  });
};

/** 편집자 confirm 창 */
export const showConfirmEditor = (onOk: () => Promise<void>) => {
  confirm({
    title: i18next.t('partnerInfo:modal.title.requestForInspection'),
    icon: <ExclamationCircleOutlined />,
    content: i18next.t('partnerInfo:modal.message.requestForInspection'),
    okText: i18next.t('partnerInfo:modal.button.requestForInspection'),
    cancelText: i18next.t('partnerInfo:modal.button.cancel'),
    centered: true,
    onOk,
  });
};
