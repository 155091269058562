import {
  BarChartOutlined,
  BranchesOutlined,
  DollarCircleOutlined,
  HomeOutlined,
  QrcodeOutlined,
  SendOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@/component/Menu/types';
import { globalEnv } from '@/config/env';
import {
  AuthorizationEnum,
  RoleEnum,
  SalesTypeEnum,
} from '@/generated/graphql-react-query';
import { AppMode } from '@/lib';
import { PATH } from '@/routes';

export const rootKeys = [
  'menuHome',
  'menuSettlement',
  'menuPartnerInfoManagement',
  'menuImportLicensing',
];

export const MenuData = (userInfo?: {
  authCode?: string;
  createdAt: any;
  email: string;
  id: string;
  phoneNumber?: string;
  role: RoleEnum;
  updatedAt: any;
  userCode?: string;
  userName: string;
  company?: {
    id: string;
    isBeforeReportV2: boolean;
    companyKrName: string;
    salesType?: SalesTypeEnum;
  };
}): MenuItem[] => {
  const { t } = useTranslation();

  const menus: MenuItem[] = [
    {
      key: 'menuHome',
      title: t('MENU.HOME'),
      path: PATH.HOME,
      hidden: false,
      icon: <HomeOutlined />,
      appMode: [AppMode.Develop, AppMode.Production],
    },
    {
      key: 'menuInformation',
      title: '정보관리',
      type: 'group',
      appMode: [AppMode.Develop, AppMode.Production],
      children: [
        {
          key: 'menuBrand-1',
          title: '브랜드',
          icon: <BranchesOutlined />,
          path: PATH.BRAND_LIST,
          appMode: [AppMode.Develop, AppMode.Production],
        },
        {
          key: 'menuProduct-1',
          title: '상품',
          icon: <QrcodeOutlined />,
          path: PATH.PRODUCT_LIST,
          appMode: [AppMode.Develop, AppMode.Production],
        },
        {
          key: 'menuImportLicensing',
          title: '수입 허가',
          icon: <SendOutlined />,
          path: PATH.IMPORT_LICENSING_LIST,
          appMode: [AppMode.Develop, AppMode.Production],
          authorizations: [AuthorizationEnum.ImportLicensingRoot],
        },
      ],
    },
    {
      key: 'menuExport',
      title: '국제운송',
      type: 'group',
      appMode: [AppMode.Develop, AppMode.Production],
      children: [
        {
          key: 'menuOrder-2',
          icon: <ShoppingCartOutlined />,
          title: t('MENU.order.management'),
          path: PATH.ORDER_MANAGEMENT,
          appMode: [AppMode.Develop, AppMode.Production],
        },
      ],
      authorizations: [AuthorizationEnum.OrderRoot],
    },
    {
      key: 'menuFulfillment',
      title: '풀필먼트',
      type: 'group',
      appMode: [AppMode.Develop, AppMode.Production],
      authorizations: [AuthorizationEnum.ReportRoot],
      children: [
        {
          key: 'menuReport',
          icon: <BarChartOutlined />,
          title: t('MENU.REPORT'),
          appMode: [AppMode.Develop, AppMode.Production],
          children: [
            {
              key: 'menuReport-2-3',
              title: t('MENU.SALES_REPORT'),
              path: PATH.SALES_REPORT,
              appMode: [AppMode.Develop, AppMode.Production],
            },
            {
              key: 'menuReport-2-1',
              title: t('MENU.LOGISTIC_REPORT'),
              path: PATH.LOGISTIC_REPORT,
              appMode: [AppMode.Develop, AppMode.Production],
            },
          ],
        },
        {
          key: 'menuSettlement',
          icon: <DollarCircleOutlined />,

          title: t('MENU.MONTHLY_SETTLEMENT'),
          path: PATH.MONTHLY_SETTLEMENT,
          appMode: [AppMode.Develop, AppMode.Production],
          authorizations: [AuthorizationEnum.SettlementRoot],
        },
      ],
    },
  ];

  const guestMenus = [
    {
      key: 'menuRegister',
      title: '',
      path: '',
      hidden: false,
      icon: <UserOutlined />,
      appMode: [AppMode.Develop, AppMode.Staging],
    },
  ];

  function menuEnvFilter(appMode: AppMode, menuItems: MenuItem[]) {
    const mode = appMode === AppMode.Staging ? AppMode.Production : appMode;
    return menuItems
      .filter((menu) => !menu.hidden)
      .filter((menu) => {
        const keep = menu.appMode?.includes(mode);
        if (keep && menu.children) {
          menu.children = menuEnvFilter(mode, menu.children);
        }
        return keep;
      });
  }
  const menuByUser = userInfo?.company?.id?.toString() ? menus : guestMenus;
  return menuEnvFilter(globalEnv.MODE as AppMode, menuByUser);
};
