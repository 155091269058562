export const PATH = {
  LOGIN: '/',
  REGISTER: '/register',
  FIND_PASSWORD: '/find-password',
  RESET_PASSWORD: '/find-password/:id',
  HOME: '/home',
  SALES_REPORT: '/report/sales',
  LOGISTIC_REPORT: '/report/logistic',
  MONTHLY_SETTLEMENT: '/monthly-settlement',
  BRAND_LIST: '/brand/list',
  PRODUCT_LIST: '/products/list',
  SETTLEMENT_DETAIL_BY_PARTNER_ID:
    '/office/settlement/detail/:partnerId/:yearMonth/:settlementDashboardId',
  /** 수입 허가 등록 */
  IMPORT_LICENSING_LIST: '/importLicensing/list',
  IMPORT_LICENSING_DETAIL: '/importLicensing/details/:importLicensingId',

  GUIDE: '/guide',
  DEMO: '/demo',

  /** 주문 */
  ORDER_MANAGEMENT: '/order/management',
  ORDER_CREATE: '/order/create/:orderId',
  ORDER_CHECK: '/order/check/:orderId',
  ORDER_DETAIL: '/order/detail/:orderId',
};
