export const en = {
  MENU: {
    HOME: 'HOME',
    information: 'Information management',
    REPORT: 'Report',
    ORDER: '주문',
    SALES_REPORT: 'Sales',
    IMPORT_LICENSING: 'Import Licensing',
    LOGISTIC_REPORT: 'Logistics',
    MONTHLY_SETTLEMENT: 'Settlement',
    GUIDE: 'User manual',
    SETTLEMENT: 'Settlement',
    product: {
      list: 'Product',
    },
    brand: {
      rootMenu: 'Brand information',
      list: 'Brand',
    },
    importLicensing: {
      list: 'Import Licensing',
    },
    order: {
      management: '주문 관리',
    },
  },
  common: {
    contactMe: {
      description:
        "We may be unable to respond to all the voices received from 'Send feedback', but every voice matters to us for our growth.",
      title: 'Send feedback',
      button: {
        send: 'Send',
      },
      placeholder: {
        content: 'Please leave your comment or feedback. (Max. 500 characters)',
      },
      completedModal: {
        title: 'Feedback sent',
        content1: 'Thank you for your feedback.',
        content2: 'Growth is crucial to Gomi Partner.',
        button: {
          confirm: 'Confirm',
        },
      },
    },
    userNameSubtext: 'Mr./Ms.',
    placeholder: {
      searchPartner: 'Search a partner',
      dragClickFileUpload: 'Click or drag a file to upload.',
      selectBox: 'Please select',
      https: 'https://',
      selectBrand: 'Select a brand',
      country: 'Total country',
      name: 'Name',
    },
    button: {
      add: 'Add',
      delete: 'Delete',
      register: 'Registration',
      toRegister: 'Register',
      confirm: 'Confirm',
      cancel: 'Cancel',
      new: 'New',
      exist: 'Existing',
      download: 'Download',
      searchAddress: 'Address search',
      save: 'Save',
      toDelete: 'Delete',
      toEdit: 'Edit',
      toSignUp: 'Sign up',
      toLoginPage: 'Move to login page',
      excelExport: 'Export to Excel',
    },
    confirm: {
      create: 'Are you sure you want to register?',
      edit: 'Are you sure you want to edit?',
      deleteTitle: 'Are you sure you want to delete?',
      addTitle: 'Are you sure you want to add?',
      process: 'Are you sure you want to process?',
      ok: 'Confirm',
      cancel: 'Cancel',
    },
    notification: {
      failFileDownload: 'Failed to download the file',
      failProcess: 'Failed to process',
      successCreate: 'Registered successfully',
      successProcess: 'Processed',
      successDelete: 'Deleted',
      failedDelete: 'Failed to delete',
      invalidRequest: 'Invalid request',
      successSave: 'Changed',
    },
  },
  COMMON_POP_CONFIRM: {
    CONFIRM_ADD: 'Are you sure you want to add?',
    CONFIRM_DELETE: 'Are you sure you want to delete?',
    OK: 'Confirm',
    CANCLE: 'Cancel',
  },
  COMMON_MODAL_FOOTER: {
    SAVE: 'Save',
    CANCLE: 'Cancel',
  },
  COMMON_BUTTON: {
    DELETE: 'Delete',
  },
  SETTLEMENT: {
    APPROVAL: 'Settlement has been processed successfully.',
    APPROVAL_CANCEL: 'Settlement has been cancelled.',
    APPROVAL_ERROR: 'Failed to process',
    APPROVAL_CANCEL_ERROR: 'Failed to process',
    OFFSET_ERROR: 'Failed to process',
  },
  SETTLEMENT_STATUS_BADGE: {
    EDITING: 'Editing',
    APPROVAL: 'Approved',
  },
  SETTLEMENT_DETAIL_TYPE: {
    ALL: 'All',
    ERROR_SETTLEMENT: 'Settlement error',
    ERROR_OFFSET: 'Offset error',
    CANCEL: 'Cancel',
    UNSETTLEMENT: 'Unsettlement',
    ERROR_GSPC: 'Missing GSPC',
    ERROR_ORDER_NUMBER: 'Missinh order number',
    WHOLESALE: 'Wholesale',
    SETTLEMENT: 'Settlement',
    OFFSET: 'Offset',
  },
  PARTNER_USER: {
    SEARCH_PLACEHOLDER: 'Name, Email or partner name',
    SEARCH_KEYWORD_TOO_SHORT_ERROR: 'Search terms must be more than three characters.',
  },
  GOMI_USER: {
    SEARCH_PLACEHOLDER: 'Name or Email',
    SEARCH_KEYWORD_TOO_SHORT_ERROR: 'Search terms must be more than three characters.',
  },
  NOTIFICATION: {
    SUCCESS_DELETE: 'Deleted',
    FAILED_DELETE: 'Failed to delete',
  },
  settlement: {
    detailList: 'Settlement history list',
    review: 'Settlement review',
    partnerApproval: 'Settlement approved',
    requestApproval: 'Approval request',
    approval: 'Approved',
    paid: 'Payment successful',
    detail: {
      title: 'Settlement history',
      table: {
        tab: {
          paid: {
            title: 'Payment history',
          },
          offset: {
            title: 'Offset history',
          },
        },
      },
    },
  },
  notification: {
    settlement: {
      pending:
        'Pending settlement history({{count}}) has been approved. Please refer to the past history.',
      detailOnReview: 'Settlement history has not yet been prepared.',
      detailBlockApproval: 'Settlement history is now in review. Please wait.',
      dashboardEmpty: 'Settlement for the month is incomplete.',
      dashboardError: 'Unable to load settlement history.',
      successSendEmail: 'Email sent',
      successRequestApproval: 'Approval request sent',
      successCancelRequestApproval: 'Approval cancelled',
      successApproval: 'Approved',
      successPayment: 'Payment successful',
      successCancelPayment: 'Payment has been cancelled.',
      failedSendEmail: 'Failed to send an Email',
      partner: {
        register: {
          success: 'It has been added to the settlement list.',
          fail: 'Registration failed',
        },
        delete: {
          success: 'It has been deleted from the settlement list.',
          fail: 'Failed to delete',
        },
      },
      changeOrderStatueReasonEmpty: 'Please leave a comment.',
      noSearchOrderData: 'Sorry. No result found',
      alreadyExistOrder: 'It is in the settlement list for this month.',
      includeOrderInSettlement: 'It has been added to the settlement list.',
      includeOrderInSettlementFail: 'An error occurred.',
      failCreateSettlementIndex: 'Failed to generate settlement dashboard.',
      notExistSettlementIndex:
        'No dashboard available for the year/month.\n Click the button below to generate a dashboard.',
      failUploadFile: 'Failed to upload. Please try again.',
    },
    entering: {
      deleteBrand: 'Delete the registered brand first.',
      registerBrand: 'Register a brand',
    },
    signUp: {
      successRegister: 'Sign up successful',
    },
    serverError: 'Try again',
    internalServerError: 'Unexpected error! Please contact Gomi Partner.',
  },
  country: {
    vn: 'Vietnam',
    cl: 'Chile',
    th: 'Thailand',
    jp: 'Japan',
    kr: 'Korea',
  },
  column: {
    settlement: {
      partner: 'Partner',
      price: 'Payment amount (KRW)',
      feePrice: 'Fee (KRW)',
      offsetPrice: 'Offset amount (KRW)',
      totalPrice: 'Final settlement amount (KRW)',
      requestApprovalDate: 'Approval request date',
      approvalDate: 'Approval completion date',
      paidDate: 'Payment completion date',
      orderNumber: 'Order number',
      productName: 'Product name',
      orderDate: 'Order date',
      count: 'Quantity',
      orderPrice: 'Price',
      description: 'Remark',
      channel: 'Channel',
      gspc: 'GSPC',
      orderStatus: 'Order status',
      paymentStatus: 'Payment status',
      partnerCenterPrice: 'Gomi Partner price (₫)',
      misaPrice: 'Misa price (₫)',
      itemName: 'Product name',
      payment: 'Payment method',
      gomiflowPrice: 'Payment amount (VND)',
      exchangeRate: 'Average exchange rate',
      feeRate: 'Fee rate (%)',
      settlementPriceKr: 'Payment amount (KRW)',
      emailCount: 'Number of Emails sent',
    },
    partner: {
      entering: {
        title: 'Parnter',
        companyRegistrationNumber: 'Business Registration Number',
        brandCount: 'Brand count',
        memberCount: 'Member count',
      },
    },
    brand: {
      entering: {
        title: 'Brand',
        logo: 'Logo',
        homepage: 'Homepage',
        countries: 'Target country',
        partner: 'Partner',
        status: 'Status',
      },
      list: {
        brandKrName: 'Brand name (KR)',
        brandEnName: 'Brand name (EN)',
        productCount: 'Quantity of registered products',
        brandCountry: 'Target country',
        brandLogoImage: 'Logo image',
      },
    },
    product: {
      list: {
        productKrName: 'Product name (KR)',
        gspc: 'GSPC',
        brandKrName: 'Brand name (KR)',
        brandName: 'Brand name (EN)',
        countryOfEntry: 'Target country',
        category: 'Product category',
        productGroupKrName: 'Product group name (KR)',
        productGroupName: 'Product group name (EN)',
        barcode: 'Barcode',
        supplierProductCode: 'Supplier product code',
        hsCode: 'HS Code',
        productUnit: 'Product unit',
        outerBoxIntake: 'Product quantity per outer box',
        outerBoxStandardWidth: 'Outer box size (Width)',
        outerBoxStandardHeight: 'Outer box size (Depth)',
        palletIntake: 'Product quantity per pallet',
        palletStandardWidth: 'Pallet size (Width)',
        palletStandardHeight: 'Pallet size (Depth)',
        productNetWeight: 'Product net weight',
        productTotalWeight: 'Product gross weight',
        outerBoxTotalWeight: 'Outer box gross weight',
        retailPriceVn: 'Retail price (VN)',
        retailPriceTh: 'Retail price (TH)',
        regularSalePrice: 'Selling price (Normal)',
        discountSalePrice: 'Selling price (Discounted)',
        purchaseUnitPrice: 'Supply price',
        supplyUnitPrice: 'Supply price',
        productNameVn: 'Product name (VN)',
        productNameTh: 'Product name (TH)',
        importLicenseNameVn: 'Product name (VN) on Certificate of Free Sale (CFS)',
        importLicenseNameTh: 'product name (TH) on Certificate of Free Sale (CFS)',
        manufacturerName: 'Manufacturer name',
        productKeyword: 'Product keyword',
      },
    },
    user: {
      list: {
        name: 'Name',
        email: 'Email',
        phoneNumber: 'Phone number',
      },
    },
    report: {
      date: 'Date',
      channel: 'Channel',
      orderNumber: 'Order number',
      payMethod: 'Payment method',
      orderDate: 'Order date',
      orderStatus: 'Order status',
      paymentStatus: 'Payment status',
      shippingStatus: 'Delivery status',
      productName: 'Product name',
      price: 'Selling price',
      priceOriginal: 'Retail price',
      settlementMonth: 'Settlement month',
      count: 'Quantity',
      totalPrice: 'Total sales amount',
      sum: 'Sum',
      ok: 'Confirm',
      cancel: 'Cancel',
    },
  },
  button: {
    registration: 'Register',
    delete: 'Delete',
    change: 'Change',
    header: {
      logout: 'Log out',
      guide: 'User manual',
    },
    settlement: {
      delete: 'Delete',
      approvalPartner: 'Confirm',
      cancelPaid: 'Cancel settlement process',
      cancelApproval: 'Cancel approval request',
      sendEmail: 'Send a settlement Email',
      requestApproval: 'Request for settlement approval',
      paid: 'Process settlement',
      orderManagement: 'Order management',
      addPartner: 'Add to settlement list',
      uploadDataCheck: 'Confirm data to upload',
      fileDownload: 'Download settlement data',
      misaUpload: 'Upload settlement data',
      selectFile: 'Select a file',
      uploadFile: 'Upload',
      confirm: 'Confirm',
      cancelConfirm: 'Confirmation cancel',
      create: 'Create',
      uploadRunning: 'File uploading...',
      completeUpload: 'File upload complete',
      misaDataConfirm: 'Misa data upload',
      misaCancelUpload: 'Misa upload cancel',
      offset: 'Offset',
      settlement: 'Settlement',
    },
    partner: {
      businessRegistration: 'View Certificate of Business Registration',
      emptyBusinessRegistration: 'Certificate of Business Registration unavailable',
      account: 'View bank book',
      emptyAccount: 'Bank book unavilable',
      entering: {
        registration: 'Partner registration',
      },
    },
    brand: {
      entering: {
        registration: 'Brand registration',
      },
    },
    product: {
      all: 'All',
      default: 'Default',
      logis: 'Logistics',
      price: 'Price',
      detail: 'Additional',
    },
  },
  tag: {
    settlement: {
      review: 'Review settlement',
      requestApproval: 'Request for approval',
      approval: 'Approval complete',
      paid: 'Payment complete',
      cancelPayment: 'Cancel payment',
      cancelRequestApproval: 'Cancel approval',
    },
    entering: {
      waitConfirm: 'Waiting for Approval',
      approve: 'Approved',
      onReview: 'In review',
      deny: 'Denied',
    },
  },
  placeholder: {
    email: 'Email',
    search: 'Search',
    newPassword: 'New password (Max. 10 characters)',
    confirmPassword: 'Confirm password',
    settlement: {
      searchOrder: 'Search order number',
      changeOrderStatueReasonTextArea: 'Please leave a comment.',
    },
    entering: {
      postalCode: 'Postal code',
      defaultAddress: 'Basic address',
      detailAddress: 'Detailed address',
      accountHolder: 'Account holder',
      accountNumber: 'Bank account number',
      bank: 'Select a bank',
    },
    signUp: {
      email: 'Email',
      password: 'Password (Max. 10 characters)',
      confirmPassword: 'Confirm password',
      userName: 'Name (Max. 2 characters)',
      phoneNumber: 'ex: 01012345678',
    },
    brand: {
      EnKrBrandName: 'Brand name (KR & EN)',
    },
    product: {
      productNameGspc: 'Product name, GSPC',
    },
  },
  pageTitle: {
    settlement: {
      partner: 'Settlement list management',
      verification: 'Detailed history',
      misaUpload: 'Settlement data upload',
      orderRegister: 'Order management',
    },
    partner: {
      info: 'Company information',
      businessRegistration: 'Business Registration Number',
      account: 'Bank book',
      entering: {
        signup: 'Partner sign-up',
        enrollment: 'Partner registration',
        info: 'Partner information',
        editPartner: 'Edit partner information',
      },
    },
    brand: {
      entering: {
        management: 'Brand management',
        enrollment: 'Brand registration',
      },
      list: 'Brand ({{count}})',
    },
    product: {
      list: 'Product ({{count}})',
    },
    user: {
      list: 'User ({{count}})',
    },
    signUp: {
      description1: 'With Gomi,',
      description2: 'start your overseas sales!',
    },
    findPassword: 'Find password',
    changePassword: 'Change password',
  },
  title: {
    report: {
      detailOrderItem: 'Order details',
      salesStatusByDate: 'Sales activity per data',
      salesStatusByProduct: 'Sales activity per product',
      stock: 'Inventory',
      stockIn: 'inbound logistics',
      stockOut: 'Outbound logistics',
    },
  },
  modalTitle: {
    settlement: {
      partner: 'Add to settlement list',
      misaUpload: 'Settlement data upload',
    },
  },
  channel: {
    gomimall: 'GOMI',
    haravanMall: 'Mall',
    haravanKr: 'GOMI KR',
    haravanMobile: 'App',
    tiki: 'Tiki',
    haravan: 'Haravan',
    sendo: 'Sendo',
    lazada: 'Lazada',
    shopee: 'Shopee',
  },
  popConfirm: {
    settlement: {
      approval:
        'Unable to return to the previous state once approved. Are you sure you want to approve?',
      requestApproval: 'Are you sure you want to process request for approval?',
      payment: 'Are you sure you want to process payment?',
      cancelPayment: 'Are you sure you want to cancel payment process?',
      cancelRequestApproval: 'Are you sure you want to cancel request for approval?',
      sendEmail: 'Are you sure you want to send the Email? (Send {{count}} Email(s))',
      askConfirm: 'Are you sure you want to confirm settlement data?',
      aksCancelConfirm: 'Are you sure you want to cancel confirmation?',
    },
    entering: {
      requestApproval: 'Are you sure you want to request for approval?',
      cancelRequestApproval: 'Are you sure you want to cancel request for approval?',
    },
  },
  card: {
    settlement: {
      settlementAmount: 'Settlement amount',
      offsetAmount: 'Offset amount',
      finalAmount: 'Final settlement amount',
      amountPaid: 'Payment amount',
      totalPaidDetailVn: 'Sum of payment history (VND)',
      exchangeRateInfo: 'Average exchange rate (Basic Exchange Rate - 100 VND)',
      totalPaidDetailKr: 'Sum of payment history (KRW)',
      fee: 'Fee',
      totalFeeGomi: 'Total fees on Gomi Mall',
      totalFeeChannel: 'Total fess on other channels',
      amountOffset: 'Offset amount',
      totalOffsetDetailKr: 'Sum of offset history (KRW)',
      totalSettlementAmount: 'Final settlement amount',
      totalSettlementAmountDesc: '(Payment amount) - (Fee) - (Offset amount)',
    },
  },
  tooltip: {
    settlement: {
      offset: 'Change history for orders with settlement completed',
    },
  },
  label: {
    entering: {
      companyRegistration: 'Business Registration Number',
      accountCopy: 'Bank book for settlement',
    },
    report: {
      brand: 'Brand name',
      item: 'Product name',
      channel: 'Channel name',
      period: 'Search period',
      orderStatus: 'Order status',
      payStatus: 'Payment status',
      deliveryStatus: 'Delivery status',
      gift: 'Gift (Yes/No)',
      excludeGift: 'Gift excluded',
      onlyGift: 'See gift only',
      case: 'Orders',
      total: 'Total',
      all: 'All',
      ok: 'Confirm',
      cancel: 'Cancel',
      paid: 'Paid',
      notPaid: 'Not Paid',
      refund: 'Refund',
      ready: 'Preparing for delivery',
      delivering: 'In delivery',
      delivered: 'Delivered',
      return: 'Return',
      waitingForReturn: 'Waiting for return',
      gomi: 'GOMI',
      gomiKr: 'GOMI_KR',
      mall: 'MALL',
      lazada: 'Lazada',
      sendo: 'Sendo',
      shopee: 'Shopee',
      tiki: 'Tiki',
      app: 'APP',
      tiktok: 'Tiktok',
    },
  },
  inputLabel: {
    entering: {
      companyRegistrationNumber: 'Business Registration Number',
      companyRegistration: 'Certificate of Business Registration',
      representativeName: 'CEO name',
      companyKrName: 'Corporation name (KR)',
      companyUsName: 'Corporation name (EN)',
      corporationRegistrationNumber: 'Corporate Registration Number',
      address: 'company address',
      account: 'Bank account for settlement',
      taxBillEmail: 'Tax-related Email address',
      partner: 'Partner',
      countries: 'Target country',
      brandKo: 'Brand (KR)',
      brandEn: 'Brand (EN)',
      brandDesc: 'Brand description',
      homepage: 'Homepage',
      brandLogoImage: 'Brand logo image',
      logoImage: 'Logo image',
      bankBook: 'Bank book',
    },
    signUp: {
      email: 'Email',
      password: 'Password (Max. 10 characters)',
      confirmPassword: 'Confirm password',
      userName: 'Name (Max. 2 characters)',
      phoneNumber: 'Phone number',
    },
  },
  validationMessage: {
    requiredEmail: 'Input Email',
    selectBrand: 'Select a brand',
    requiredNewPassword: 'Input new password',
    requiredPassword: 'Input password',
    passwordMinLength: 'Please input more than 10 characters.',
    notMatchPassword: 'Password does not match.',
    entering: {
      required: {
        defaultMessage: 'Input {{title}}',
        companyRegistrationNumber: 'Input Business Registration Number',
        representativeName: 'Input CEO name',
        companyKrName: 'Input corporation name (KR)',
        companyUsName: 'Input corporation name (EN)',
        postalCode: 'Input postal code',
        detailAddress: 'Input detailed address',
        defaultAddress: 'Input basic address',
        selectBank: 'Select a bank',
        accountHolder: 'Input account holder',
        accountNumber: 'Input bank account number',
        upload: 'Upload an image',
      },
      pattern: {
        companyRegistrationNumber: 'It is not in the correct format (000-00-00000).',
      },
    },
    signUp: {
      email: 'Email address must be in the correct format.',
      password: 'Password must be more than 10 characters',
      confirmPassword: 'Password does not match.',
      userName: 'Name must be more than 2 characters.',
      phoneNumber: 'Phone number must be in the correct format',
    },
  },
  link: {
    findPassword: 'Find password',
  },
  info: {
    changedPassword: 'Password changed',
    sendEmail: 'Email sent',
    inputEmailSignup: 'Input registered Email',
  },
  table: {
    message: {
      noRowBrandData: 'No registered brands',
      noRowProductData: 'No registered products',
      noRowUserData: 'No registered users',
      noSearchRow: 'No search results',
    },
  },
  tab: {
    report: {
      salesStatus: 'Sales activity',
      deliveryStatus: 'Logistics activity',
    },
  },
  error: {
    report: {
      failedLoadReport: 'Loading report failed',
      noReport: 'No countries currently selling products.',
      noProductsForSaleInCountry: 'No products being sold in the country.',
    },
  },
  chart: {
    salesStatus: 'Sales activity (VND)',
    salesAmountByProduct: 'Sales amount per product (VND)',
    legend: {
      ok: 'Order confirm',
      cancel: 'Order cancel',
      totalAmount: 'Total amount',
    },
  },
};
