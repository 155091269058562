import { Card } from '@gomi/component';
import { numbers } from '@gomi/utils';
import { ResponsiveChoropleth } from '@nivo/geo';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSummary } from '@/hooks/home/useSummary';
import { useStore } from '@/stores';

export const StatusMap = observer(() => {
  const { t } = useTranslation(['home']);
  const {
    homeModel: { startDate, endDate },
  } = useStore();

  const { salesPrice } = useSummary({
    startDate,
    endDate,
  });
  const [map, setMap] = useState([]);

  useEffect(() => {
    /* 지도 데이터 호출 */
    fetch(
      'https://partner-center.s3.ap-southeast-1.amazonaws.com/resource/world_countries.json',
    ).then(async (response) => {
      const result = await response.json();
      setMap(result.features);
    });
  }, []);

  return (
    <Card header={t('home:dashboard.statusOfAdvancement.title')}>
      <div className='gm-h-[12rem] gm-text-sm'>
        <ResponsiveChoropleth
          data={[
            {
              id: 'VNM',
              value: salesPrice,
            },
          ]}
          features={map}
          colors='blues'
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          valueFormat={(value) => `${numbers.convertMoneyText(value, 0)} ₫`}
          domain={[0, 1000000]}
          unknownColor='#F0F0F0'
          label='properties.name'
          projectionScale={140}
          projectionTranslation={[0.5, 0.7]}
          projectionRotation={[-90, 0, 0]}
          enableGraticule
          graticuleLineColor='#dddddd'
          borderWidth={0.5}
          borderColor='#D9D9D9'
        />
      </div>
    </Card>
  );
});
