import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CountryEnum,
  useMonthlyExchangeRateQuery,
} from '@/generated/graphql-react-query';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';

/** props */
export interface IPropsHeader {
  /** 환율 visible */
  isShowExchangeRate?: boolean;
  /** 월 */
  yearMonth?: string;
  /** country id */
  countryId?: CountryEnum;
  header?: string | ReactNode | undefined;
}

type ExchangeRateCountry = 'VND' | 'THB' | '';

const getExchangeRateCountryName = (countryId: CountryEnum): ExchangeRateCountry => {
  if (countryId === CountryEnum.Vn) {
    return 'VND';
  }

  if (countryId === CountryEnum.Th) {
    return 'THB';
  }

  return '';
};

/** component: 정산 > 해당월 카드 대쉬보드 > Actions */
const Header: FC<IPropsHeader> = ({
  isShowExchangeRate = true,
  yearMonth,
  countryId,
  header,
}) => {
  const { t } = useTranslation('settlementInfo');
  const { isPC } = useMediaQueryHook();

  /** gql - 환율 정보 */
  const { data: monthlyExchangeRate } = useMonthlyExchangeRateQuery(
    {
      input: {
        filter: {
          countryId: countryId as CountryEnum,
          yearMonth: yearMonth as string,
        },
      },
    },
    {
      enabled: !!yearMonth,
    },
  );

  return (
    <div className={`gm-flex gm-justify-between ${isPC ? '' : 'gm-flex-col gm-gap-1'}`}>
      {header}
      {countryId && isShowExchangeRate && (
        <div className={`gm-text-gray-800 ${isPC ? '' : 'gm-text-xs'}`}>
          <span className='gm-font-normal'>{t(`monthlyDashBoard.exchangeRate`)}</span>
          <span className='gm-font-normal'> | </span>

          <span>
            {getExchangeRateCountryName(countryId)}{' '}
            {monthlyExchangeRate?.monthlyExchangeRate.currencyCriteriaAmount} = KRW{' '}
            {monthlyExchangeRate?.monthlyExchangeRate.avgExchangeRate}
          </span>

          <Tooltip
            placement='bottomLeft'
            title={t('monthlyDashBoard.totalSettlementInfoTooltip')}
          >
            <InfoCircleOutlined className='gm-ml-2 gm-text-sm' />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export { Header };
