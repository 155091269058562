import { useTopSellingBrandsQuery } from '@/generated/graphql-react-query';

export const useTopSellingBrands = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const { data, isLoading } = useTopSellingBrandsQuery({
    input: {
      filter: {
        startedAt: startDate,
        endAt: endDate,
      },
    },
  });

  return {
    data: data?.topSellingBrands || [],
    isLoading,
  };
};
