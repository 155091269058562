import { BrandInfoLocale } from '@/locale/data/types/brandInfoLocale.type';

export const koBrandInfo: BrandInfoLocale = {
  rootMenu: '브랜드 정보',
  listPage: {
    title: '브랜드 목록',
  },
  managementPage: {
    title: '브랜드 관리',
  },
  control: {
    searchPlaceHolder: '브랜드 코드 또는 브랜드 명을 검색해주세요.',
  },
  modal: {
    title: {
      registrationTitle: '브랜드 등록',
      uploadBulk: '브랜드 일괄 등록',
    },
    message: {
      emptyCompanyName:
        '임시저장 시 "파트너명 국문, 파트너명 영문, 파트너"는 필수 입니다.',
      exportFailContent1: '다운로드할 브랜드 정보가 없습니다.',
      exportFailContent2: '브랜드를 등록해주세요.',
      uploadWarning1: '일괄 등록은 신규 등록 브랜드의 경우에 사용해주세요.',
      tableNoDataMessage1: '등록된 브랜드가 없습니다.',
      tableNoDataMessage2: '브랜드를 등록해주세요.',
      noSpecialCharacter: '"브랜드명"에 특수문자를 사용할 수 없습니다.',
      noUseSpace: '"브랜드명"에 공백을 사용할 수 없습니다.',
      notUseKorean: '"브랜드명 영문"에 한글은 사용할 수 없습니다.',
      noSingleQuote: '"작은따옴표(‘)는 사용할 수 없습니다."',
      onlyCapitalEnglish: '"영문명에는 대문자만 사용가능합니다."',
      registration:
        '브랜드 정보를 틍록하시겠습니까?\n' +
        '해당 정보는 브랜드 목록으로 업데이트 됩니다.',
      noEdit: '현재 미운영중인 브랜드로 수정이 불가합니다. 전략기획팀에 문의하여 주세요.',
    },
    label: {
      brandId: '브랜드 ID',
      brandCode: '브랜드 코드',
      brandKrName: '브랜드명 국문',
      brandName: '브랜드명 영문',
      countries: '진출 국가',
      logo: '로고',
      consignment: '위탁',
      purchase: '사입',
      partner: '파트너',
      partnerCode: '파트너 코드',
    },
    placeholder: {
      noSpaceAndNoSpecialCharEn: `대문자 사용, 작은따옴표(‘) 사용 불가`,
      noSpaceAndNoSpecialChar: `작은따옴표(‘) 사용 불가`,
      brandCode: '브랜드 등록시 자동 생성',
      countries: '진출 국가를 선택하세요.',
      partner: '파트너를 선택하세요.',
    },
    button: {
      addBrand: '브랜드 등록',
      uploadBulk: '브랜드 일괄 등록',
    },
  },
  table: {
    brandCode: '브랜드 코드',
    brandName: '브랜드 명 (국문/영문)',
    companyName: '파트너',
  },
};
