import { ColDef, IHeaderParams } from '@ag-grid-community/core';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd/es';
import i18next from 'i18next';

import { StockLocationEnum } from '@/generated/graphql-react-query';
import { convertNumberFormat } from '@/lib/convertIntlFormat';

const InformationRenderer = ({
  displayName,
  tooltip,
}: IHeaderParams & { tooltip: string }) => (
  <div className='gm-flex gm-items-center gm-h-full'>
    <div className='gm-mr-1'>{displayName}</div>
    <Tooltip placement='top' title={tooltip}>
      <InfoCircleOutlined className='gm-text-gray-800' />
    </Tooltip>
  </div>
);

export const StockColumn = () => {
  return [
    {
      headerName: 'No.',
      field: 'no',
      cellStyle: { textAlign: 'center' },
      width: 64,
    },
    {
      headerName: i18next.t('report:column.location'),
      field: 'location',
      width: 100,
      cellRenderer: (row: { value: StockLocationEnum }) =>
        row.value === StockLocationEnum.Han
          ? i18next.t('report:column.han')
          : i18next.t('report:column.hcm'),
    },
    {
      headerName: i18next.t('report:column.gspc'),
      field: 'gspc',
      width: 160,
    },
    {
      headerName: i18next.t('report:column.productName'),
      field: 'productName',
      resizable: true,
      flex: 1,
      minWidth: 290,
    },
    {
      headerName: 'APT',
      headerComponent: (params: IHeaderParams) => (
        <InformationRenderer
          {...params}
          tooltip={i18next.t('report:stockPage.table.information.apt')}
        />
      ),
      field: 'quantity',
      cellStyle: { textAlign: 'right' },
      width: 110,
      cellRenderer: (row: { value: number }) => convertNumberFormat('ko', row.value),
    },
    {
      headerName: 'Usable',
      headerComponent: (params: IHeaderParams) => (
        <InformationRenderer
          {...params}
          tooltip={i18next.t('report:stockPage.table.information.usable')}
        />
      ),
      field: 'usableQuantity',
      cellStyle: { textAlign: 'right' },
      width: 110,
      cellRenderer: (row: { value: number }) => convertNumberFormat('ko', row.value),
    },
    {
      headerName: 'Buffer',
      headerComponent: (params: IHeaderParams) => (
        <InformationRenderer
          {...params}
          tooltip={i18next.t('report:stockPage.table.information.buffer')}
        />
      ),
      field: 'bufferQuantity',
      cellStyle: { textAlign: 'right' },
      width: 110,
      cellRenderer: (row: { value: number }) => convertNumberFormat('ko', row.value),
    },
    {
      headerName: 'In Progress',
      headerComponent: (params: IHeaderParams) => (
        <InformationRenderer
          {...params}
          tooltip={i18next.t('report:stockPage.table.information.inProgress')}
        />
      ),
      field: 'inProcessQuantity',
      cellStyle: { textAlign: 'right' },
      width: 110,
      cellRenderer: (row: { value: number }) => convertNumberFormat('ko', row.value),
    },
    {
      headerName: 'In Picking',
      headerComponent: (params: IHeaderParams) => (
        <InformationRenderer
          {...params}
          tooltip={i18next.t('report:stockPage.table.information.inPicking')}
        />
      ),
      field: 'inPickingQuantity',
      cellStyle: { textAlign: 'right' },
      width: 110,
      cellRenderer: (row: { value: number }) => convertNumberFormat('ko', row.value),
    },
    {
      headerName: 'Unusable',
      headerComponent: (params: IHeaderParams) => (
        <InformationRenderer
          {...params}
          tooltip={i18next.t('report:stockPage.table.information.unusable')}
        />
      ),
      field: 'unusableQuantity',
      cellStyle: { textAlign: 'right' },
      width: 110,
      cellRenderer: (row: { value: number }) => convertNumberFormat('ko', row.value),
    },
    {
      headerName: 'On Hold/Problem',
      headerComponent: (params: IHeaderParams) => (
        <InformationRenderer
          {...params}
          tooltip={i18next.t('report:stockPage.table.information.problem')}
        />
      ),
      field: 'problemQuantity',
      cellStyle: { textAlign: 'right' },
      width: 160,
      cellRenderer: (row: { value: number }) => convertNumberFormat('ko', row.value),
    },
    {
      headerName: 'Incoming',
      headerComponent: (params: IHeaderParams) => (
        <InformationRenderer
          {...params}
          tooltip={i18next.t('report:stockPage.table.information.incoming')}
        />
      ),
      field: 'incomingQuantity',
      cellStyle: { textAlign: 'right' },
      width: 110,
      cellRenderer: (row: { value: number }) => convertNumberFormat('ko', row.value),
    },
  ];
};

export const StockLogColumn = (dates: string[], isMobile: boolean): ColDef[] => {
  return [
    {
      headerName: i18next.t('report:column.productName'),
      field: 'productName',
      resizable: true,
      width: 340,
      pinned: isMobile ? false : 'left',
    },
    ...dates.map((date) => ({
      headerName: date,
      field: date,
      headerClass: 'gm-text-center',
      width: 110,
      type: 'numberColumn',
    })),
  ];
};
