import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { numbers } from '@gomi/utils';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { SettlementOrderItemSettlementStatusEnum as StatusEnum } from '@/generated/graphql';

interface IPropsOrdersModalColumn {
  /** 활성화 탭 _ 판매내역 or 상계 */
  activeTab: StatusEnum;
}

/** component: 판매내역 모달 리스트 컬럼 - 월별 정산내역 > 위탁 상세 > 판매/상계 내역 */
export const OrdersModalColumn = ({
  activeTab,
}: IPropsOrdersModalColumn): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation('settlementInfo');
  const tranTableColumn = t('modal.tableColumn', {
    returnObjects: true,
  });
  const convertMoney = (params: ValueFormatterParams) =>
    numbers.convertMoneyText(params.value);

  return [
    {
      // 주문날짜
      headerName: tranTableColumn.orderedAt,
      field: 'orderedAt',
      cellClass: 'gm-flex gm-justify-start gm-items-center',
      width: 144,
      cellRenderer: (params: ICellRendererParams) => (
        <div>{moment(params.data.orderedAt).format('YYYY-MM-DD HH:MM')}</div>
      ),
    },
    {
      // 채널
      headerName: tranTableColumn.channelName,
      field: 'channelName',
      cellClass: 'gm-flex gm-justify-start gm-items-center',
      width: 112,
    },
    {
      // 주문번호
      headerName: tranTableColumn.orderNumber,
      field: 'orderNumber',
      cellClass: 'gm-flex gm-justify-start gm-items-center',
      width: 166,
    },
    {
      // 매입단가
      headerName: tranTableColumn.originalPrice,
      field: 'originalPrice',
      width: 120,
      valueFormatter: convertMoney,
      headerClass: 'gm-text-right',
      cellClass: 'gm-flex gm-justify-end gm-items-center',
    },
    {
      // 수량
      headerName: tranTableColumn.salesCount,
      field: 'salesCount',
      minWidth: 80,
      flex: 1,
      resizable: false,
      headerClass: 'gm-text-right',
      cellClass: 'gm-flex gm-justify-end gm-items-center',
      cellRenderer: (params: ICellRendererParams) => (
        <div className=''>
          {activeTab === StatusEnum.Setoff ? <span className=''>-</span> : ''}
          {numbers.convertMoneyText(params.data.salesCount)}
        </div>
      ),
    },
    {
      // 총 판매 가격
      headerName: tranTableColumn.totalSalesPrice,
      field: 'salesPrice',
      width: 120,
      resizable: false,
      valueFormatter: convertMoney,
      headerClass: 'gm-text-right',
      cellClass: 'gm-flex gm-justify-end gm-items-center',
      cellRenderer: (params: ICellRendererParams) => (
        <div className=''>
          {activeTab === StatusEnum.Setoff ? <span className=''>-</span> : ''}
          {numbers.convertMoneyText(params.data.salesPrice)}
        </div>
      ),
    },
  ];
};
