import '@/pages/auth/index.css';

import styled from '@emotion/styled';
import GomiLogo from '@gomi/component/src/assets/icons/gomiLogo.svg?component';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import tw from 'twin.macro';

import { setRootMenus } from '@/component/Menu';
import { AuthContainer } from '@/container/AuthContainer';
import { PATH } from '@/routes';
import { RegisterForm } from '@/views/auth/RegisterForm';

const RegisterPageRow = styled(Row)`
  background-color: ${(props) => props.theme.color.neutrals[300]};
  height: 100vh;
  width: 100vw;
`;

const RegisterContentRow = styled(Row)`
  background-color: ${(props) => props.theme.color.neutrals[100]};
  width: 32.5rem;
  ${tw`gm-rounded-md`}
`;

const RegisterFormCol = styled(Col)`
  height: 100%;
  padding: 2.5rem;
`;

const RegisterPageTitleRow = styled(Row)({
  fontSize: '1.875rem',
  fontWeight: 600,
  lineHeight: '2.25rem',
  marginBottom: '2rem',
});

export const RegisterPage = () => {
  const { isLogin } = AuthContainer.useContainer();
  const history = useHistory();

  const { t } = useTranslation(['newCommon']);
  const tranMsg = t('newCommon:register', { returnObjects: true });

  useEffect(() => {
    setRootMenus([]);
    if (isLogin) {
      history.push(PATH.HOME);
    }
  }, []);
  return (
    <>
      <RegisterPageRow id='registerPage' justify='center' align='middle'>
        <RegisterContentRow justify='space-around'>
          <RegisterFormCol md={24} xs={24}>
            <RegisterPageTitleRow justify='center' align='middle'>
              {tranMsg.title}
            </RegisterPageTitleRow>
            <RegisterForm />
          </RegisterFormCol>
        </RegisterContentRow>
      </RegisterPageRow>
    </>
  );
};
