import { UseMutationOptions } from '@tanstack/react-query';
import { Moment } from 'moment';

import {
  DtoUpdateB2bOrderEstimatedArrivalDateInput,
  Exact,
  UpdateB2bOrderByEstimatedArrivalDateMutation,
  useUpdateB2bOrderByEstimatedArrivalDateMutation,
} from '@/generated/graphql-react-query';

export const useSetEstimatedArrivalDateHook = (
  options?: UseMutationOptions<
    UpdateB2bOrderByEstimatedArrivalDateMutation,
    unknown,
    Exact<{
      input: DtoUpdateB2bOrderEstimatedArrivalDateInput;
    }>,
    unknown
  >,
) => {
  const {
    mutate: updateEstimatedArrivalDateMutate,
    isLoading,
    ...rest
  } = useUpdateB2bOrderByEstimatedArrivalDateMutation(options);

  const setEstimatedArrivalDate = (id: string, estimatedArrivalDate: Moment) => {
    updateEstimatedArrivalDateMutate({ input: { id, update: { estimatedArrivalDate } } });
  };

  return {
    setEstimatedArrivalDate,
    isLoadingSetEstimatedArrivalDate: isLoading,
    ...rest,
  };
};
