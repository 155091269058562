import { Card, CardTextItem } from '@gomi/component';
import { numbers } from '@gomi/utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { useSummary } from '@/hooks/home/useSummary';
import { useStore } from '@/stores';

export const Summary = observer(() => {
  const { t } = useTranslation(['home']);
  const {
    homeModel: { startDate, endDate },
  } = useStore();
  const { isPC } = useMediaQueryHook();

  const { salesPrice, orders, isLoading } = useSummary({
    startDate,
    endDate,
  });
  const makeItems = [
    {
      title: t('home:summary.items.total'),
      value: salesPrice || 0,
    },
    {
      title: t('home:summary.items.totalOrderCount'),
      value: orders?.total || 0,
    },
    {
      title: t('home:summary.items.totalCancelCount'),
      value: orders?.cancel || 0,
    },
  ].map((item) => {
    const content = numbers.convertMoneyText(item.value, 0);
    return {
      ...item,
      content,
    };
  });

  return (
    <div>
      <Card
        loading={isLoading}
        header={t('home:summary.title')}
        className='gm-mb-5'
        sectionClassName={isPC ? '' : 'gm-flex gm-flex-col'}
        arrayArticles={makeItems.map((item, idx) => (
          <CardTextItem key={idx} header={item.title}>
            {item.content}
          </CardTextItem>
        ))}
        isContentsBackground
      />
    </div>
  );
});
