import { PartnerInfo } from '@/locale/data/types/partnerInfoLocale.type';

export const koPartnerInfo: PartnerInfo = {
  rootMenu: '파트너 정보',
  listPage: {
    title: '파트너 목록',
  },
  publicCreatePage: {
    header: {
      applyConfirmHeader: '회사 정보 등록',
      completedHeader: '제출 완료',
      requestHeader: '파트너센터 회사 등록',
      failHeader: '회사 정보 등록 실패',
    },
    placeholder: {
      countryId: '사업자등록증 기준 국가',
      companyGroup: '고미코퍼레이션과의 계약에 따른 거래관계',
      corporateRegistrationNumber: '사업자등록증 기준 (“-“을 포함)',
      accountNumber: '통장사본 기준 (“-“을 포함)',
    },
    label: {
      confirm: '확인',
      close: '취소',
      submit: '제출',
      companyKrName: '회사 국문명',
      companyName: '회사 영문명',
      countryId: '사업장 소재 국가',
      companyGroup: '그룹 분류',
      companyImagePath: 'CI',
    },
    message: {
      companyImageHelpText:
        '파일명 “CI_회사명(국문)”,  용량 200MB 이하로 업로드해주세요.',
      registrationImageHelpText:
        '파일명 “사업자등록증사본_회사명(국문)”,  용량 200MB 이하로 업로드해주세요.',
      contractHelpText1: '물품공급거래계약서 또는 기타 계약서의 날인본&스캔본 입니다.',
      contractHelpText2: '파일명 “계약서_회사명(국문)” 용량 200MB 이하로 업로드해주세요.',
      bankImageHelpText:
        '파일명 “통장사본_회사명(국문)” 용량 200MB 이하로 업로드해주세요.',
      message1: '안녕하세요. 고미코퍼레이션 파트너센터입니다.',
      message2: '파트너센터에 등록하기 위해 아래에 기재된 항목들을 작성후 제출해주세요.',
      message3: '* 필수 입력 항목입니다.',
      message4:
        '작성해주신 내용들은 담당자가 검토후 고미코퍼레이션 파트너센터에 등록이 완료됩니다.',
      completedMessage1: '제출해주셔서 감사합니다!',
      completedMessage2:
        '작성해주신 내용들은 담당자가 검토후 고미코퍼레이션 파트너센터에 등록이 완료됩니다.',
      completedMessage3:
        '검토 후 등록되기까지 조금 시간이 걸리니 영업일 기준 1 ~ 3일 정도 기다려주세요.',
      confirmMessage1: '입력하신 정보가 정확한가요?',
      confirmMessage2: '모두 정확하게 입력하셨다면 제출을 눌러주세요!',
      errorMessage1: '해당 요청을 처리할 수 없습니다.',
      errorMessage2: '개발팀에게 문의해주시기 바랍니다.',
    },
  },
  managementPage: {
    title: '파트너 관리',
    sharingApplyLinkTitle: '등록링크 공유',
    tab: {
      checkComplete: '검수 완료',
      checkRequest: '검수 요청',
      checkReject: '반려',
      tempSave: '임시 저장',
      partnerRequest: '파트너 인입',
    },
  },
  control: {
    title: '목록',
    count: '{{count}}건',
    label: {
      country: '진출 국가',
      partnerCountry: '파트너 국가',
      partner: '파트너',
      group: '파트너 그룹',
      manager: '담당자',
      all: 'ALL',
    },
    searchPlaceHolder: '파트너 코드 또는 파트너 명을 검색해주세요.',
  },
  modal: {
    title: {
      registrationTitle: '파트너 등록',
      requestForInspection: '검수 요청',
      cancelRequestForInspection: '검수 요청 취소',
      batchRegistration: '파트너 일괄 등록',
      deleteRequest: '삭제',
      confirmRegistration: '검수 확인',
      confirmRejection: '반려 확인',
      requestCancel: '요청 취소',
    },
    message: {
      invalidFileExtension: '정해진 확장자만 업로드 가능합니다.',
      maxFileSize: '200MB 이하만 업로드 가능합니다.',
      uploadFail: '파일 업로드에 실패했습니다.',
      inputRequiredValueError: '필수값 입니다.',
      emptyCompanyName: '임시저장 시 "파트너명 국문, 파트너명 영문"은 필수 입니다.',
      requestForInspection: '요청한 내용은 수정, 삭제가 불가능합니다.',
      registrationSuccess: '등록이 완료되었습니다.',
      registrationFail: '등록에 실패했습니다.',
      requestSuccess: '요청되었습니다.',
      requestFail: '요청에 실패했습니다.',
      delete: '해당 요청을 삭제하였습니다.',
      rejection: '반려되었습니다.',
      cancelRequest: '취소되었습니다.',
      selectPartner: '파트너를 선택해주세요.',
      requestCancel: '요청 취소하시겠습니까?',
      temporary: '임시 저장하시겠습니까?',
      saveSuccess: '저장되었습니다.',
      saveFail: '저장에 실패했습니다.',
      cancelRequestForInspection: '해당 요청을 취소처리 하시겠습니까?',
      deleteRequest: '삭제 하시겠습니까?',
      deleteRequestSuccess: '삭제했습니다.',
      deleteRequestFail: '삭제 과정에서 오류가 발생했습니다',
      registration:
        '파트너 정보를 틍록하시겠습니까?\n' +
        '해당 정보는 파트너 목록으로 업데이트 됩니다.',
      confirmRegistration: '해당 요청을 완료처리 하시겠습니까?',
      confirmRejection: '해당 요청을 반려처리 하시겠습니까?',
      needRegistrationImage: '사업자 등록증 사본이 필요합니다',
      lackOfInformation: '필수 정보가 부족합니다.',
      selectFile: '파일을 선택해주세요.',
      bulkUploadSuccessTitle: '파트너 일괄 등록 완료',
      bulkUploadSuccessContent1: '파일이 정상적으로 업로드되었습니다.',
      bulkUploadSuccessContent2: '업로드한 정보는 임시저장 탭에서 확인 가능합니다.',
      bulkUploadFailTitle: '파트너 일괄 등록 실패',
      bulkUploadFailContent: '업로드에 실패하였습니다. 양식에 맞게 입력해주세요.',
      overSize: '파일 업로드는 200MB까지 가능합니다.',
      dragFile: '이곳을 클릭 또는 파일을 드래그하세요.',
      uploadWarningTitle: '엑셀 파일 업로드시 유의사항',
      uploadWarning1: '일괄 등록은 신규 등록 파트너의 경우에 사용해주세요.',
      uploadWarning2: '(일괄 수정 불가)',
      uploadWarning3: '파일 양식 다운로드',
      uploadWarning4: '후 양식에 맞게 입력하여 업로드해주세요.',
      uploadWarning5: '엑셀 파일을 양식에 맞지 않게 입력 시 일괄 등록이 실패 처리됩니다.',
      uploadWarning6: '업로드시 200Mb 이하로 제한됩니다.',
      rejectionMessage: '해당 요청은 반려되었습니다.',
      exportFail: '엑셀 다운로드 실패',
      exportFailContent1: '다운로드할 파트너 정보가 없습니다.',
      exportFailContent2: '파트너를 등록해주세요.',
      doNotHavePermission: '권한이 없습니다.',
      lackOfCompanyInformation: '파트너 정보가 부족합니다.',
      tableNoDataMessage1: '등록된 파트너가 없습니다.',
      tableNoDataMessage2: '파트너를 등록해주세요.',
      notUseKorean: '"파트너명 영문"에 한글은 사용할 수 없습니다.',
      notUseCompanyKorean: '"회사명 영문"에 한글은 사용할 수 없습니다.',
      noEdit: '현재 미운영중인 파트너로 수정이 불가합니다. 전략기획팀에 문의하여 주세요.',
    },
    label: {
      purchase: '사입',
      purchaseBySales: '판매분 사입',
      consignment: '위탁',
      none: '-',
      salesType: '계약 유형',
      accountNumber: '계좌번호',
      address: '사업자 소재지',
      bankImagePath: '통장사본',
      bankName: '은행명',
      ceoName: '대표자명',
      companyGroup: '파트너 그룹',
      companyImagePath: '파트너 CI',
      companyKrName: '파트너명 국문',
      companyName: '파트너명 영문',
      contractPath: '계약서',
      corporateRegistrationNumber: '법인등록번호',
      countryId: '파트너 국가',
      digitalTaxInvoiceEmail: '전자계산서 이메일',
      partnerId: '파트너 ID',
      partnerCode: '파트너 코드',
      registrationImagePath: '사업자등록증사본',
      registrationNumber: '사업자 등록번호',
      gomiManager: 'GOMI 담당자',
    },
    button: {
      requestCancel: '요청 취소',
      modalToggle: '파트너 등록',
      upload: '업로드',
      uploadBulk: '파트너 일괄 등록',
      fileUpload: '파일 업로드',
      requestForInspection: '검수 요청',
      temporarySave: '임시 저장',
      cancel: '취소',
      confirm: '확인',
      cancelRequestForInspection: '요청 취소',
      delete: '삭제',
      modify: '수정',
      save: '저장',
      registration: '등록',
      confirmRegistration: '검수 확인',
      rejection: '반려',
    },
    placeholder: {
      salesType: '계약 유형을 선택해주세요.',
      registrationWithDash: '사업자등록증 기준 ("-"을 포함)',
      bankName: '통장사본 기준으로 작성',
      companyGroup: '파트너 그룹을 선택하세요.',
      companyImagePath: 'jpeg, jpg, png 형식으로 업로드해주세요.',
      registrationWithWhiteSpace: '사업자등록증 기준 (띄어쓰기 포함)',
      registration: '사업자등록증 기준',
      companyName: '영문사업자등록증 기준 (띄어쓰기 및 특수문자 포함)',
      countryId: '파트너 국가를 선택하세요.',
      digitalTaxInvoiceEmail: '전자(세금)계산서를 수취할 이메일 주소',
      pdfUpload: 'pdf 형식으로 업로드해주세요.',
      gomiManagers: 'GOMI 담당자를 선택하세요.',
      confirmRejection: '(선택) 반려사유를 입력하세요. 최대 150자',
      notExistFile: '파일이 없습니다.',
      notExistGomiManager: '담당자가 없습니다.',
    },
  },
  table: {
    no: 'No',
    partnerCode: '파트너 코드',
    routeType: '인입경로',
    sellingCountry: '진출국가',
    partnerName: '파트너명 (국문/영문)',
    partnerCountry: '파트너 국가',
    partnerGroup: '파트너 그룹',
    ceoName: '대표자명',
    manager: 'GOMI 담당팀 / 담당자',
    dateDone: '완료 날짜',
    dateRequest: '요청 날짜',
    dateReject: '반려 날짜',
    dateTemp: '저장 날짜',
    dateUpdate: '업데이트 날짜',
    routeTypeAdmin: '담당자',
    routeTypePartner: '파트너',
  },
};
