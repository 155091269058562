import { ControlBar } from '@/views/importLicensing/importLicensingDetail/common/ControlBar';
import { FileUploadArea } from '@/views/importLicensing/importLicensingDetail/common/fileUploadArea';
import { Actions } from '@/views/importLicensing/importLicensingDetail/productDocumentUploadStep/Actions';
import { List } from '@/views/importLicensing/importLicensingDetail/productDocumentUploadStep/List';

export const ProductDocumentUploadStep = () => {
  return (
    <>
      <ControlBar>
        <Actions />
      </ControlBar>
      <div className='gm-flex gm-flex-col lg:gm-flex-row gm-gap-4 gm-w-full gm-h-full gm-mt-4'>
        <List />
        <FileUploadArea />
      </div>
    </>
  );
};
