import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  useCompleteConfirmationImportLicensingByPartnerMutation,
  useRequestConfirmationImportLicensingMutation,
} from '@/generated/graphql-react-query';

export const useControllerHandler = () => {
  const { t } = useTranslation(['importLicensingInfo']);
  const { mutateAsync: requestConfirmation, isLoading } =
    useRequestConfirmationImportLicensingMutation({
      onSuccess: () => {
        message.success(
          t('importLicensingInfo:detailPage.steps.common.requestConfirmationSuccess'),
        );
      },
    });

  const { mutateAsync: completeConfirmation, isLoading: isCompleteLoading } =
    useCompleteConfirmationImportLicensingByPartnerMutation({
      onSuccess: () => {
        message.success(t('importLicensingInfo:detailPage.steps.common.complete'));
      },
    });

  return {
    requestConfirmation,
    isLoading,
    completeConfirmation,
    isCompleteLoading,
  };
};
