import { ALL } from '@/constant';
import { useSalesStatusByProductQuery } from '@/generated/graphql-react-query';
import { IReportControl } from '@/types/report/type';

// 아래 node 데이터 유형
// [
//   {
//     prices: [
//       {
//         channels: [
//           {
//             channel: 'SHOPEE',
//             orderStatuses: [
//               {
//                 orderCountPrice: {
//                   count: 1,
//                   totalPrice: 259200,
//                 },
//                 orderStatus: 'CANCEL',
//               },
//             ],
//           },
//         ],
//         price: 259200,
//       },
//       {
//         channels: [
//           {
//             channel: 'SHOPEE',
//             orderStatuses: [
//               {
//                 orderCountPrice: {
//                   count: 17,
//                   totalPrice: 4773600,
//                 },
//                 orderStatus: 'OK',
//               },
//               {
//                 orderCountPrice: {
//                   count: 2,
//                   totalPrice: 561600,
//                 },
//                 orderStatus: 'CANCEL',
//               },
//             ],
//           },
//           {
//             channel: 'LAZADA',
//             orderStatuses: [
//               {
//                 orderCountPrice: {
//                   count: 3,
//                   totalPrice: 842400,
//                 },
//                 orderStatus: 'OK',
//               },
//               {
//                 orderCountPrice: {
//                   count: 1,
//                   totalPrice: 280800,
//                 },
//                 orderStatus: 'CANCEL',
//               },
//             ],
//           },
//         ],
//         price: 280800,
//       },
//       {
//         channels: [
//           {
//             channel: 'SHOPEE',
//             orderStatuses: [
//               {
//                 orderCountPrice: {
//                   count: 1,
//                   totalPrice: 302400,
//                 },
//                 orderStatus: 'OK',
//               },
//             ],
//           },
//         ],
//         price: 302400,
//       },
//     ],
//     productName: 'ATOBARRIER365 Cream Mist - 120ml * 1',
//   },
// ];

export const useSalesStatusByProductHook = ({
  startDate,
  endDate,
  brandId,
  channel,
  gift,
  orderStatus,
  paymentStatus,
  shippingStatus,
  salesCode,
  countryId,
}: IReportControl) => {
  const { data, isLoading } = useSalesStatusByProductQuery({
    input: {
      filter: {
        countryId,
        startOrderedAt: startDate,
        endOrderedAt: endDate,
        ...(brandId !== ALL && { brandId }),
        ...(channel !== ALL && { channel }),
        ...(gift !== ALL && { giftType: gift }),
        ...(orderStatus !== ALL && { orderStatus }),
        ...(paymentStatus !== ALL && { paymentStatus: [paymentStatus] }),
        ...(shippingStatus !== ALL && { shippingStatus: [shippingStatus] }),
        ...(salesCode !== ALL && { salesCode: [salesCode] }),
      },
    },
  });

  const result: any[] = [];
  let totalAmount = 0;
  let totalCount = 0;
  let totalChannelDict: { [key: string]: number } = {};

  (data?.salesStatusByProduct.node || []).forEach((item) => {
    // 상품마다 채널별로 금액, 수량을 계산한다.
    item.prices.forEach((price, index) => {
      let eachTotalAmount = 0;
      let eachTotalCount = 0;
      let eachChannelDict: { [key: string]: number } = {};

      price.channels.forEach((channel) => {
        channel.orderStatuses.forEach((orderStatusDict) => {
          eachTotalAmount += orderStatusDict.orderCountPrice.totalPrice;
          eachTotalCount += orderStatusDict.orderCountPrice.count;
          eachChannelDict = {
            ...eachChannelDict,
            [`${channel.channel}_${orderStatusDict.orderStatus}_AMOUNT`]:
              orderStatusDict.orderCountPrice.totalPrice,
            [`${channel.channel}_${orderStatusDict.orderStatus}_COUNT`]:
              orderStatusDict.orderCountPrice.count,
          };
        });
      });

      result.push({
        ...eachChannelDict,
        price: price.price,
        productName: item.productName,
        rowSpan: index === 0 ? item.prices.length : 1,
        totalAmount: eachTotalAmount,
        totalCount: eachTotalCount,
      });

      // 전체에 대해 채널별로 금액, 수량을 계산한다.
      totalCount += eachTotalCount;
      totalAmount += eachTotalAmount;
      Object.keys(eachChannelDict).forEach((key) => {
        const isKeyInTotalChannelDict = key in totalChannelDict;
        if (isKeyInTotalChannelDict) {
          totalChannelDict = {
            ...totalChannelDict,
            [key]: eachChannelDict[key] + totalChannelDict[key],
          };
        }

        if (!isKeyInTotalChannelDict) {
          totalChannelDict = {
            ...totalChannelDict,
            [key]: eachChannelDict[key],
          };
        }
      });
    });
  });

  return {
    data: result,
    footer: {
      ...totalChannelDict,
      rowSpan: 1,
      productName: '합계',
      totalCount,
      totalAmount,
    },
    isLoading,
  };
};
