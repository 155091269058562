import styled from '@emotion/styled';
import { Button } from '@gomi/component';
import { Page } from 'react-pdf';
import tw from 'twin.macro';

interface NavigationProps {
  totalPage: number;
  currentPage: number;
  updateCurrentPage(pageNumber: number): void;
}

const CustomPage = styled(Page)`
  .textLayer,
  .annotationLayer {
    ${tw`gm-hidden`}
  }
`;

export const Navigator = ({
  totalPage,
  currentPage,
  updateCurrentPage,
}: NavigationProps) => (
  <div className='gm-flex gm-flex-row lg:gm-flex-col gm-items-center gm-gap-1.5 lg:gm-w-[115px] gm-p-3 lg:gm-p-4 gm-bg-gray-700 gm-overflow-auto'>
    {Array.from(new Array(totalPage), (_, index) => (
      <Button
        key={`page_${index}`}
        onClick={() => updateCurrentPage(index)}
        type='button'
        btnType='text'
      >
        <CustomPage
          pageIndex={index}
          width={75}
          loading=''
          className={index !== currentPage ? 'gm-opacity-50' : ''}
        />
      </Button>
    ))}
  </div>
);
