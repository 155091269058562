import { message } from 'antd/es';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CompanyGroupEnum, useRegisterMutation } from '@/generated/graphql-react-query';

export const useRegisterHook = () => {
  const { mutateAsync, isLoading } = useRegisterMutation();
  const history = useHistory();

  const { t } = useTranslation(['newCommon']);
  const tranMsg = t('newCommon:message', { returnObjects: true });
  const tranRegisterMsg = t('newCommon:register', { returnObjects: true });

  const handleRegister = async (data: {
    email: string;
    companyName: string;
    password: string;
  }) => {
    try {
      await mutateAsync({
        input: {
          email: data.email,
          companyName: data.companyName,
          password: data.password,
          groupId: CompanyGroupEnum.G99,
        },
      });
      message.success(tranRegisterMsg.success);
      history.push('/');
    } catch (error) {
      message.warning(tranMsg.error);
    }
  };

  return {
    register: handleRegister,
    isLoading,
  };
};
