import { useTranslation } from 'react-i18next';

import { useGetB2bOrderListHook } from '@/hooks/order/management/useGetB2bOrderListHook';

export const FilterControlBar = () => {
  const { t } = useTranslation(['orderInfo']);
  const { total } = useGetB2bOrderListHook();

  return (
    <div className='gm-flex gm-justify-between gm-items-center gm-flex-nowrap gm-overflow-x-auto'>
      <div className='gm-min-w-24'>
        <span className='gm-mr-2'>{t('orderInfo:managementPage.control.title')}</span>
        <span>{t('orderInfo:managementPage.control.count', { count: total })}</span>
      </div>
    </div>
  );
};
