import { ICellRendererParams } from '@ag-grid-community/core';
import { Button, Popconfirm, Tag } from 'antd/es';
import { TFunction } from 'react-i18next';

import { B2bOrderStatusEnum } from '@/generated/graphql-react-query';
import { B2bOrderDetail } from '@/hooks/order/common/useGetB2bOrderHook';

interface IListColumnProps {
  t: TFunction<'orderInfo'[]>;
  isCheckingInventory: boolean;
  isBuyer: boolean;
  isSeller: boolean;
  status: B2bOrderStatusEnum;
  onDelete(id: string): void;
}

export const getListColumn = ({
  t,
  isCheckingInventory,
  isBuyer,
  isSeller,
  status,
  onDelete,
}: IListColumnProps) => {
  return [
    {
      headerName: t('orderInfo:createPage.label.productName'),
      field: 'productName',
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: t('orderInfo:createPage.label.barcode'),
      field: 'barcode',
      width: 150,
    },
    {
      headerName: t('orderInfo:createPage.label.innerBoxQuantity'),
      field: 'innerBoxQuantity',
      width: 100,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.innerBoxQuantity.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.outerBoxQuantity'),
      field: 'outerBoxQuantity',
      width: 100,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.outerBoxQuantity.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.retailPrice'),
      field: 'retailPrice',
      hide: isBuyer, // 구매자일 경우 소비자가 숨김
      width: 125,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.retailPrice.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.purchaseUnitPrice'),
      field: 'purchaseUnitPrice',
      hide: isBuyer, // 구매자일 경우 소비자가 숨김
      width: 125,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.purchaseUnitPrice.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.salesPrice'),
      field: 'salesPrice',
      hide: isSeller, // 판매자일 경우 공급가 숨김
      width: 125,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.salesPrice.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.orderQuantity'),
      field: 'orderQuantity',
      width: 120,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.orderQuantity.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.inventoryQuantity'),
      field: 'inventoryQuantity',
      width: 120,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        isCheckingInventory ? (
          <Tag>재고 확인 중</Tag>
        ) : (
          data?.inventoryQuantity.toLocaleString()
        ),
    },
    {
      headerName: t('orderInfo:createPage.label.totalPrice'),
      field: 'totalPrice',
      width: 140,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.totalPrice.toLocaleString(),
    },
    status === B2bOrderStatusEnum.OrderWaitingForConfirm && isBuyer
      ? {
          headerName: '',
          cellStyle: { textAlign: 'center' },
          field: '',
          cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) => {
            return (
              <Popconfirm
                title='해당 주문 내역을 취소하시겠습니까?'
                onConfirm={() => data?.id && onDelete(data?.id)}
                okText='네'
                cancelText='아니오'
              >
                <Button disabled={!data?.id}>취소</Button>
              </Popconfirm>
            );
          },
        }
      : {},
  ];
};
