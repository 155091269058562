import styled from '@emotion/styled';
import { Steps } from 'antd/es';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import {
  ImportLicensingDocumentStatusEnum,
  ImportLicensingSheetStepEnum,
} from '@/generated/graphql-react-query';
import { useImportLicensingHook } from '@/hooks/importLicensing/details/useImportLicensingHook';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';

export const MAX_STEPS = 6;

/** 숫자 스탭 -> 문자열 스탭으로 변경 */
export const changeStringStep = (current: number) => {
  const steps: { [key: number]: ImportLicensingSheetStepEnum } = {
    0: ImportLicensingSheetStepEnum.CreateProduct,
    1: ImportLicensingSheetStepEnum.DocumentUploadPerProduct,
    2: ImportLicensingSheetStepEnum.ExtraDocument,
    3: ImportLicensingSheetStepEnum.DocumentDelivery,
    4: ImportLicensingSheetStepEnum.Estimate,
    5: ImportLicensingSheetStepEnum.Done,
  };

  return steps[current];
};

/** 문자열 스탭 -> 숫자 스탭으로 변경 */
export const changeNumberStep = (step: ImportLicensingSheetStepEnum) => {
  const steps: { [key in ImportLicensingSheetStepEnum]: number } = {
    [ImportLicensingSheetStepEnum.CreateProduct]: 0,
    [ImportLicensingSheetStepEnum.DocumentUploadPerProduct]: 1,
    [ImportLicensingSheetStepEnum.ExtraDocument]: 2,
    [ImportLicensingSheetStepEnum.DocumentDelivery]: 3,
    [ImportLicensingSheetStepEnum.Estimate]: 4,
    [ImportLicensingSheetStepEnum.Done]: 5,
  };

  return steps[step];
};

/** antd 스탭 컴포넌트 상태 처리 process: 진행중, finish: 완료, wait: 대기 */
const getAntdStepStatus = (
  isCurrent: boolean,
  status: ImportLicensingDocumentStatusEnum,
): 'wait' | 'process' | 'finish' | 'error' | undefined => {
  if (status === ImportLicensingDocumentStatusEnum.Done) {
    return 'finish';
  }

  // 완료가 아니고 현재스탭이면 진행 중
  if (isCurrent) {
    return 'process';
  }

  return 'wait';
};

const CustomSteps = styled(Steps)`
  ${tw`gm-gap-3 gm-overflow-x-auto`}

  .ant-steps-item {
    .ant-steps-item-container {
      ${tw`gm-ml-0`}
    }

    .ant-steps-item-content {
      ${tw`gm-mr-3`}
    }
  }
`;

export const StepsComponent = observer(() => {
  const { t } = useTranslation(['importLicensingInfo']);
  const { importLicensingId } = useParams<IParams>();
  const {
    importLicensingDetailModel: { steps },
  } = useStore();

  const { stepStatuses } = useImportLicensingHook({
    importLicensingId,
  });

  return (
    <CustomSteps
      type='navigation'
      size='small'
      current={steps.current}
      responsive={false}
      items={[
        {
          title: t('importLicensingInfo:detailPage.steps.first.label'),
          status: getAntdStepStatus(
            changeStringStep(steps.current) ===
              ImportLicensingSheetStepEnum.CreateProduct,
            stepStatuses[0],
          ),
        },
        {
          title: t('importLicensingInfo:detailPage.steps.second.label'),
          status: getAntdStepStatus(
            changeStringStep(steps.current) ===
              ImportLicensingSheetStepEnum.DocumentUploadPerProduct,
            stepStatuses[1],
          ),
        },
        {
          title: t('importLicensingInfo:detailPage.steps.third.label'),
          status: getAntdStepStatus(
            changeStringStep(steps.current) ===
              ImportLicensingSheetStepEnum.ExtraDocument,
            stepStatuses[2],
          ),
        },
        {
          title: t('importLicensingInfo:detailPage.steps.forth.label'),
          status: getAntdStepStatus(
            changeStringStep(steps.current) ===
              ImportLicensingSheetStepEnum.DocumentDelivery,
            stepStatuses[3],
          ),
        },
        {
          title: t('importLicensingInfo:detailPage.steps.fifth.label'),
          status: getAntdStepStatus(
            changeStringStep(steps.current) === ImportLicensingSheetStepEnum.Estimate,
            stepStatuses[4],
          ),
        },
        {
          title: t('importLicensingInfo:detailPage.steps.sixth.label'),
          status: getAntdStepStatus(
            changeStringStep(steps.current) === ImportLicensingSheetStepEnum.Done,
            stepStatuses[5],
          ),
        },
      ]}
    />
  );
});
