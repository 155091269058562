import { ALL } from '@/constant';
import { useSalesCodesQuery } from '@/generated/graphql-react-query';
import { useStore } from '@/stores';

export const useSalesCodeListHook = () => {
  const {
    reportControlModel: { brandId, countryId },
  } = useStore();
  const { data: salesCodeList } = useSalesCodesQuery({
    input: {
      filter: {
        brandIds: brandId !== ALL ? [brandId] : undefined,
        countryId,
      },
    },
  });

  return {
    salesCodeList: salesCodeList?.salesCodes || [],
  };
};
