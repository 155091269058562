import { AuthContainer } from '@/container';
import { momentDateFormat } from '@/lib';

export const ThQuicksightReport = () => {
  const { companyInfoById } = AuthContainer.useContainer();
  const url =
    'https://ap-southeast-1.quicksight.aws.amazon.com/sn/embed/share/accounts/414853458649/dashboards/5d9c98b0-e8c3-4782-9d36-8bc8cfa3edc2?directory_alias=gomidev';
  const fromDateQueryParam = `fromDate=${momentDateFormat.get1WeekAgoFromToday()}`;
  const toDateQueryParam = `toDate=${momentDateFormat.getToday}`;
  const companyName = `companyName=${companyInfoById?.companyByPartner.companyName}`;
  return (
    (
      <div className='gm-mt-2'>
        <iframe
          title='태국 판매 리포트'
          width='100%'
          style={{ border: 'none', height: 'calc(100vh - 63px)' }}
          src={[url, fromDateQueryParam, toDateQueryParam, companyName].join('&')}
        />
      </div>
    ) || null
  );
};
