import { Button } from '@gomi/component';
import { Descriptions, Divider, message } from 'antd/es';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  ImportLicensingSheetStepEnum,
  useImportLicensingRequiredOriginalDocumentsQuery,
} from '@/generated/graphql-react-query';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { useControllerHandler } from '@/hooks/importLicensing/details/useControllerHandler';
import { useImportLicensingHook } from '@/hooks/importLicensing/details/useImportLicensingHook';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';
import { ControlBar } from '@/views/importLicensing/importLicensingDetail/common/ControlBar';
import { changeNumberStep } from '@/views/importLicensing/importLicensingDetail/Steps';

export const DocumentDeliveryStep = observer(() => {
  const { t } = useTranslation(['importLicensingInfo']);
  const location = useLocation();
  const history = useHistory();
  const { importLicensingId } = useParams<IParams>();
  const {
    importLicensingDetailModel: { getNext, setCurrent },
  } = useStore();
  const { isMobile } = useMediaQueryHook();

  /** 수입 허가 단건 조회하여 현재 스탭을 처리 */
  const { step, stepStatuses, isValidStep, refetch } = useImportLicensingHook({
    importLicensingId,
  });

  const { data } = useImportLicensingRequiredOriginalDocumentsQuery({
    importLicenseId: importLicensingId,
  });
  const originalExtraDocuments =
    data?.importLicensingRequiredOriginalDocuments.extraDocuments || [];

  const { completeConfirmation, isCompleteLoading } = useControllerHandler();

  useEffect(() => {
    if (!isValidStep(changeNumberStep(step))) {
      message.warning(t('importLicensingInfo:detailPage.messages.stepWarning'));
      history.push(location.pathname);
    }
  }, []);

  const handleSubmit = async () => {
    await completeConfirmation({
      input: {
        id: importLicensingId,
        update: {
          step: ImportLicensingSheetStepEnum.DocumentDelivery,
        },
      },
    });

    const next = getNext();
    setCurrent(next, stepStatuses[next]);
    history.replace(`${history.location.pathname}?current=${next}`);
    refetch();
  };

  return (
    <>
      <ControlBar />
      <div className='gm-flex gm-flex-col gm-w-full gm-min-h-full gm-mt-12 gm-mb-6'>
        <p className='gm-text-center'>
          <img
            className='gm-w-full gm-max-w-md gm-mb-4'
            src='https://partner-center.s3.ap-southeast-1.amazonaws.com/resource/export-licensing-01.png'
            alt={t('importLicensingInfo:detailPage.steps.forth.description1')}
          />
          <br />
          {t('importLicensingInfo:detailPage.steps.forth.description1')}
          <br /> {t('importLicensingInfo:detailPage.steps.forth.description2')}
        </p>

        {originalExtraDocuments.length ? (
          <Descriptions
            bordered
            column={1}
            className='gm-mt-12 gm-w-[700px] gm-self-center'
          >
            <Descriptions.Item
              label={t('importLicensingInfo:detailPage.steps.forth.originalDocuments')}
              labelStyle={{ minWidth: '120px' }}
            >
              {originalExtraDocuments.map((document) => (
                <>
                  <div key={document.id}>
                    <div>{document.documentName}</div>
                    <small className='gm-text-gray-600'>{document.message}</small>
                  </div>
                  <Divider className='gm-mt-2 gm-mb-2' />
                </>
              ))}
            </Descriptions.Item>
          </Descriptions>
        ) : null}

        <Descriptions
          bordered
          column={1}
          layout={isMobile ? 'vertical' : 'horizontal'}
          className='gm-mt-12 md:gm-w-[450px] gm-self-center'
        >
          <Descriptions.Item
            label={t('importLicensingInfo:detailPage.steps.forth.receiver')}
          >
            {t('importLicensingInfo:detailPage.steps.forth.receiverContent', {
              manager: '000',
            })}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('importLicensingInfo:detailPage.steps.forth.address')}
          >
            {t('importLicensingInfo:detailPage.steps.forth.addressContent')}
          </Descriptions.Item>
        </Descriptions>

        <div className='gm-mt-8 gm-max-w-md gm-self-center'>
          <Button
            btnType='filled'
            color='primary'
            size='lg'
            loading={isCompleteLoading}
            onClick={handleSubmit}
            className='gm-h-auto gm-py-2'
          >
            {t('importLicensingInfo:detailPage.steps.forth.submit')}
          </Button>
        </div>
      </div>
    </>
  );
});
