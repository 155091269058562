import React, { FC, ReactNode } from 'react';
import tw, { styled } from 'twin.macro';

const Wrapper = styled.button<IPropsSquareButton>`
  /* default _ type, color, size 미설정시 보여지는 버튼 */
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  ${tw`gm-rounded-sm gm-box-border gm-bg-white gm-border-gray-400 gm-border gm-text-gray-900 gm-cursor-pointer`}
  &.disabled {
    ${tw`gm-bg-gray-200 gm-border-gray-400 gm-text-gray-500`}
  }

  /* size */
  &.size-sm {
    ${tw`gm-p-0 gm-w-7 gm-h-7`}
  }
  &.size-md {
    ${tw`gm-p-0 gm-w-8 gm-h-8`}
  }
  &.size-lg {
    ${tw`gm-p-0 gm-w-10 gm-h-10`}
  }

  /* filled type */
  &.type-filled {
    ${tw`gm-text-white`}
    &.color-default {
      ${tw`!gm-bg-gray-900 !gm-border-gray-900`}
      :hover {
        ${tw`!gm-bg-gray-800`}
      }
      :active {
        ${tw`!gm-bg-black !gm-border-black`}
      }
    }
    &.disabled {
      ${tw`!gm-bg-gray-200 !gm-border-gray-200 !gm-text-gray-500`}
    }
  }

  /* outlined type */
  &.type-outlined {
    &.color-default {
      ${tw`gm-bg-white gm-border-gray-400 gm-border !gm-text-gray-900`}
      :hover {
        ${tw`!gm-text-blue-600`}
      }
      :active {
        ${tw`!gm-bg-blue-100 gm-border-blue-600 !gm-border-gray-400`}
      }
    }
    &.disabled {
      ${tw`!gm-bg-gray-200 !gm-border-gray-400 !gm-text-gray-500`}
      :hover {
        ${tw`!gm-bg-gray-200 !gm-border-gray-400 !gm-text-gray-500`}
      }
    }
  }

  /* ghost type */
  &.type-ghost {
    ${tw`gm-bg-transparent gm-border-none gm-text-gray-900`}
    :hover {
      ${tw`!gm-bg-gray-100 !gm-border !gm-border-gray-100`}
    }
    :active {
      ${tw`!gm-bg-gray-100 !gm-border !gm-border-gray-100`}
    }
    &.disabled {
      ${tw`!gm-text-gray-500`}
    }
  }
`;

const colorVariation = {
  primary: 'blue',
  default: 'default',
  success: 'green',
  warning: 'yellow',
  danger: 'red',
};

export interface IPropsSquareButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** btnType _ outlined, filled */
  btnType?: 'outlined' | 'filled' | 'ghost';
  /** color _ default, primary, danger, warning, success */
  color?: keyof typeof colorVariation;
  /** size _ lg, md, sm */
  size?: 'sm' | 'md' | 'lg';
  /** disabled */
  isDisabled?: boolean;
  /** css class */
  className?: string;
  children?: ReactNode;
}

/** component: 기본 버튼 컴포넌트 */
export const SquareButton: FC<IPropsSquareButton> = ({
  btnType = 'outlined',
  color = 'default',
  size = 'md',
  isDisabled = false,
  className,
  children,
  ...props
}) => {
  const btnColor = colorVariation[color];

  // style 정의
  let mainColor = '';
  let hoverColor = '';
  let activeColor = '';

  switch (btnType) {
    case 'filled': {
      mainColor = `gm-bg-${btnColor}-600 gm-border-${btnColor}-600`;
      hoverColor = `hover:gm-bg-${btnColor}-500 hover:gm-border-${btnColor}-500`;
      activeColor = `active:gm-bg-${btnColor}-700 active:gm-border-${btnColor}-700`;
      break;
    }

    case 'outlined': {
      mainColor = `gm-text-${btnColor}-600`;
      hoverColor = `hover:gm-border-${btnColor}-500 hover:gm-text-${btnColor}-500`;
      activeColor = `active:gm-bg-${btnColor}-100 active:gm-border-${btnColor}-700 active:gm-text-${btnColor}-700`;
      break;
    }

    case 'ghost': {
      mainColor = `gm-bg-none gm-text-${btnColor}-600`;
      break;
    }

    default: {
      break;
    }
  }

  const styleClassName = `${mainColor} ${hoverColor} ${activeColor}`;
  return (
    <Wrapper
      type={props.type}
      onClick={props.onClick}
      className={
        isDisabled
          ? `disabled ${styleClassName} type-${btnType} color-${btnColor} size-${size} ${className}}`
          : `${styleClassName} type-${btnType} color-${btnColor} size-${size} ${className}}`
      }
    >
      {children}
    </Wrapper>
  );
};
