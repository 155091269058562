import styled from '@emotion/styled';
import { AgChip, AgTable, Button } from '@gomi/component';
import { Modal } from 'antd/es';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  SettlementOrderItemSettlementStatusEnum as StatusEnum,
  useSettlementOrdersByPartnerQuery,
} from '@/generated/graphql';
import { PATH } from '@/routes';

import { OrdersModalColumn } from './OrdersModalColumn';

/** style */
const Wrapper = styled.div``;

/** props */
interface IPropsOrdersModal {
  /** 활성화 탭 _ 판매내역 or 상계 */
  activeTab: StatusEnum;
  /** visible 여부 */
  isVisible: boolean;
  /** 상품명 */
  productName: string;
  /** 파트너 아이디 */
  partnerId?: string;
  /** 상품 아이디 */
  productId: string;
  /** 닫기 클릭시 */
  onCancel?: () => void;
}

/** component: 판매내역 모달 - 월별 정산내역 > 판매분사입 상세 > 판매/상계 내역 */
const OrdersModal: FC<IPropsOrdersModal> = ({
  activeTab,
  isVisible,
  productName,
  partnerId,
  productId,
  onCancel,
}) => {
  const { t } = useTranslation('settlementInfo');
  const history = useHistory();

  /** gql - 상세 > 판매분 사입 모달 리스트  */
  const { data: modalList, loading: loadingModalList } =
    useSettlementOrdersByPartnerQuery({
      variables: {
        input: {
          filter: {
            settlementStatus: activeTab,
            settlementPartnerId: partnerId as string,
            productId,
          },
        },
      },
      skip: !productId,
    });

  /** 닫기 클릭시 */
  const handleCancel = () => {
    onCancel?.();
  };

  /** 세일즈 리포트로 이동 */
  const handleMoveSalesReport = () => {
    history.push(PATH.SALES_REPORT);
  };

  /** 모달 title */
  const ModalHeader = () => {
    return (
      <div className='gm-flex'>
        {activeTab === StatusEnum.Setoff ? (
          <AgChip color='red' text='상계' className='gm-mr-2' />
        ) : (
          ''
        )}{' '}
        {productName}
      </div>
    );
  };

  return (
    <Wrapper>
      <Modal
        open={isVisible}
        title={<ModalHeader />}
        width={672}
        onCancel={handleCancel}
        footer={null}
      >
        <AgTable
          className='gm-h-[540px]'
          loading={loadingModalList}
          columnDefs={OrdersModalColumn({ activeTab })}
          defaultColDef={{ resizable: true }}
          rowData={modalList?.settlementOrdersByPartner || []}
        />
        <div className='gm-text-blue-600 gm-py-4'>
          {t('modal.footer.info1')}
          <Button
            type='button'
            btnType='text'
            color='primary'
            className='gm-underline gm-pl-1'
            onClick={handleMoveSalesReport}
          >
            {t('button.salesReport')}
          </Button>
          {t('modal.footer.info2')}
        </div>
      </Modal>
    </Wrapper>
  );
};

export { OrdersModal };
