export const enum ThemeType {
  dark = 'dark',
  light = 'light',
}

export const enum UseGa {
  true = 'true',
  false = 'false',
}

export enum NotionKey {
  Home = 'HOME',
  Guide = 'GUIDE',
}

export const enum RegisterType {
  Create = 'CREATE',
  Edit = 'EDIT',
}

export const enum CountryType {
  None = '',
}

export const enum Size {
  Small = 'small',
  Default = 'default',
  Large = 'large',
}

export enum TagColor {
  Blue = 'blue',
  Green = 'green',
  Volcano = 'volcano',
  Magenta = 'magenta',
  Red = 'red',
  Cyan = 'cyan',
  Lime = 'lime',
  Orange = 'orange',
  Default = 'default',
  Warning = '#cd201f',
  Normal = '#87d068',
}
