import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { FC, ReactNode } from 'react';

type EMPTY_SIZE = 'small' | 'default';

/** props */
export interface IPropsNoData {
  /** children */
  children?: ReactNode;
  onShowImage?: boolean;
  size?: EMPTY_SIZE;
}

/** component: 테이블 데이터 없음 */
export const Empty: FC<IPropsNoData> = ({ children, onShowImage, size = 'default' }) => {
  const iconSize = size === 'default' ? 'gm-text-[2.5rem]' : 'gm-text-[1.5rem]';
  const textSize = size === 'default' ? 'gm-text-base' : 'gm-text-sm';
  return (
    <div className='gm-text-gray-500 gm-text-center'>
      <div>
        {onShowImage ? (
          <img
            src='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
            alt='Empty'
          />
        ) : (
          <ExclamationCircleOutlined className={iconSize} />
        )}
      </div>
      <div className={`gm-pt-2 ${textSize}`}>{children}</div>
    </div>
  );
};
