import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  en,
  enBrandInfo,
  enCommon,
  enHomeLocale,
  enImportLicensingInfo,
  enOrderInfo,
  enPartnerInfo,
  enProductInfo,
  enReport,
  enSettlementInfo,
  ko,
  koBrandInfo,
  koCommon,
  koHomeLocale,
  koImportLicensingInfo,
  koOrderInfo,
  koPartnerInfo,
  koProductInfo,
  koReport,
  koSettlementInfo,
} from '@/locale/data';

export const resources = {
  ko: {
    common: ko,
    newCommon: koCommon,
    settlementInfo: koSettlementInfo,
    partnerInfo: koPartnerInfo,
    brandInfo: koBrandInfo,
    productInfo: koProductInfo,
    report: koReport,
    importLicensingInfo: koImportLicensingInfo,
    home: koHomeLocale,
    orderInfo: koOrderInfo,
  },
  en: {
    common: en,
    newCommon: enCommon,
    settlementInfo: enSettlementInfo,
    partnerInfo: enPartnerInfo,
    brandInfo: enBrandInfo,
    productInfo: enProductInfo,
    report: enReport,
    importLicensingInfo: enImportLicensingInfo,
    home: enHomeLocale,
    orderInfo: enOrderInfo,
  },
};

i18n.use(initReactI18next).init({
  resources,
  ns: [
    'common',
    'settlementInfo',
    'partnerInfo',
    'brandInfo',
    'productInfo',
    'report',
    'importLicensingInfo',
    'home',
    'orderInfo',
  ],
  defaultNS: 'common',
  lng: 'ko',
  fallbackLng: 'ko',
});
