import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useSettlementProductSummaryByPartnerQuery } from '@/generated/graphql';
import { MonthlyDashboard } from '@/views/settlement/common/monthlyDashboard';

/** component: 월별 정산내역 > 판매분사입 상세 > 대시보드
 * 전체 정산내역, 채널별 수수료 및 정산 금액
 */
interface IPropsDashBoard {
  /** 파트너 아이디 */
  partnerId: string;
}

const DashBoard: FC<IPropsDashBoard> = ({ partnerId }) => {
  const { t } = useTranslation('settlementInfo');

  /** gql - summary */
  const { data: summary } = useSettlementProductSummaryByPartnerQuery({
    variables: {
      input: {
        filter: {
          settlementPartnerId: partnerId,
        },
      },
    },
    skip: !partnerId,
  });

  return (
    <MonthlyDashboard
      header={t('monthlyDashBoard.totalSettlementInfo')}
      isShowExchangeRate={false}
      yearMonth={summary?.settlementProductSummaryByPartner.settlementYearMonth}
      salesPrice={summary?.settlementProductSummaryByPartner.salesPrice || 0}
      setoffPrice={summary?.settlementProductSummaryByPartner.setoffPrice || 0}
      settlementPrice={summary?.settlementProductSummaryByPartner.settlementPrice || 0}
    />
  );
};

export { DashBoard };
