import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import {
  B2bOrderQuery,
  DtoReturnB2bOrder,
  useB2bOrderQuery,
} from '@/generated/graphql-react-query';

export type B2bOrderFiles = Pick<
  DtoReturnB2bOrder,
  // 도착 예정일
  | 'estimatedArrivalDate'
  // 구매자 입금 확인 파일 정보
  | 'buyerDepositConfirmationFile'
  // 판매자 입금 확인 파일 정보
  | 'sellerDepositConfirmationFile'
  // 거래 확정서 파일 정보
  | 'orderConfirmDocumentFile'
  // 발주서 파일 정보
  | 'orderDocumentFile'
>;

export const useGetB2bOrderFilesHook = (
  id: string,
  options?: UseQueryOptions<B2bOrderQuery, unknown, B2bOrderFiles, QueryKey>,
) => {
  const { data, refetch, isLoading, ...rest } = useB2bOrderQuery(
    { b2BOrderId: id },
    {
      enabled: !!id,
      select: ({ b2bOrder }) => ({
        estimatedArrivalDate: b2bOrder.estimatedArrivalDate,
        buyerDepositConfirmationFile: b2bOrder.buyerDepositConfirmationFile,
        sellerDepositConfirmationFile: b2bOrder.sellerDepositConfirmationFile,
        orderConfirmDocumentFile: b2bOrder.orderConfirmDocumentFile,
        orderDocumentFile: b2bOrder.orderDocumentFile,
      }),
      ...options,
    },
  );

  return {
    b2bOrderFiles: data || {
      estimatedArrivalDate: null,
      buyerDepositConfirmationFile: null,
      sellerDepositConfirmationFile: null,
      orderConfirmDocumentFile: null,
      orderDocumentFile: null,
    },
    refetchB2bOrderFiles: refetch,
    isB2bOrderFilesLoading: isLoading,
    ...rest,
  };
};
