import { LeftCircleOutlined, MenuOutlined, RightCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { AntButton, LoggingClick, SideMenuLogo } from '@gomi/component';
import { Col, Layout, Row } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { removeMenuInSessionStorage } from '@/component/Menu';
import { MenuOpenClose } from '@/component/Menu/MenuOpenClose';
import { TRACK_EVENT_NAME } from '@/constant/track';
import { AuthContainer } from '@/container/AuthContainer';
import { PATH } from '@/routes';
import { Mobile, PC } from '@/views/common/MediaQuery';

interface MainHeaderProps {
  // eslint-disable-next-line react/no-unused-prop-types
  collapsed: boolean;
  onChangeCollapsed(): void;
  children?: ReactNode;
}

const { Header } = Layout;

const NoPaddingHeader = styled(Header)`
  padding: 0 1rem;
`;

const HeaderUserName = styled.span`
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: rgba(107, 114, 128, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
`;

const PCHeader = ({ collapsed, onChangeCollapsed, children }: MainHeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { userInfo, signOut } = AuthContainer.useContainer();

  const onLogOut = () => {
    signOut();
    removeMenuInSessionStorage();
  };

  return (
    <PC>
      <NoPaddingHeader>
        <Row justify='space-between' className='gm-relative gm-h-full' align='middle'>
          <MenuOpenClose onChangeCollapsed={onChangeCollapsed}>
            {collapsed ? (
              <RightCircleOutlined key='trigger-key-4' className='gm-bg-white' />
            ) : (
              <LeftCircleOutlined key='trigger-key-5' className='gm-bg-white' />
            )}
          </MenuOpenClose>
          <Col>{children}</Col>
          <Col>
            <HeaderUserName>
              {userInfo?.partnerMe.userName} {t('common.userNameSubtext')}
            </HeaderUserName>
            <LoggingClick eventName={TRACK_EVENT_NAME.LOGOUT_CLICK}>
              <AntButton type='default' onClick={onLogOut}>
                {t('button.header.logout')}
              </AntButton>
            </LoggingClick>
          </Col>
        </Row>
      </NoPaddingHeader>
    </PC>
  );
};

const MobileHeader = ({ onChangeCollapsed, children }: MainHeaderProps) => {
  const location = useLocation();

  return (
    <Mobile>
      <NoPaddingHeader>
        <Row justify='space-between' className='gm-relative gm-h-full' align='middle'>
          <MenuOpenClose onChangeCollapsed={onChangeCollapsed}>
            <MenuOutlined key='trigger-key-4' />
          </MenuOpenClose>
          {location.pathname === PATH.HOME ? (
            <>
              <SideMenuLogo
                size='small'
                collapsed={location.pathname !== PATH.HOME}
                path={PATH.HOME}
              />
              <div />
            </>
          ) : (
            <>
              <Col>{children}</Col>
              <SideMenuLogo
                size='small'
                collapsed={location.pathname !== PATH.HOME}
                path={PATH.HOME}
              />
            </>
          )}
        </Row>
      </NoPaddingHeader>
    </Mobile>
  );
};

export const MainHeader = (props: MainHeaderProps) => (
  <>
    <PCHeader {...props} />
    <MobileHeader {...props} />
  </>
);
