import { ProductInfoLocaleType } from '@/locale/data/types/productInfoLocale.type';

export const koProductInfo: ProductInfoLocaleType = {
  rootMenu: '상품 정보',
  listPage: {
    title: '상품 목록',
  },
  managementPage: {
    title: '상품 관리',
  },
  createPage: {
    title: '상품 등록',
  },
  groupPage: {
    title: '상품 그룹 편집',
  },
  title: {
    uploadBulkCreate: '상품 일괄 등록',
    uploadBulkUpdate: '상품 일괄 수정',
    uploadBulkSuccess: '상품 일괄 업로드 완료',
    logisticsRegistrationTitle: 'Logistics 정보 등록',
    priceRegistrationTitle: 'Price 정보 등록',
    detailRegistrationTitle: 'Detail 정보 등록',
    basicRegistrationTitle: 'Basic 정보 등록',
    productGroupEdit: '상품 그룹 편집',
    deleteProduct: '상품 목록 삭제',
  },
  message: {
    emptyValue: '필수값입니다',
    emptyBarcode: '바코드를 입력하지 않은 상품이 존재합니다.',
    importLicenseNameEmpty: '상품에 맞는 수입 허가명을 입력해주세요.',
    importExcelDataEmpty: '업로드할 데이터가 존재하지 않습니다.',
    importExcelSheetEmpty:
      '업로드할 엑셀 시트가 존재하지 않습니다. 엑셀 시트명을 확인해주세요.',
    duplicateProduct: '중복되는 상품이 있습니다',
    duplicateBarcode: '중복되는 바코드입니다.',
    alreadyExistsBarcode: '이미 존재하는 바코드입니다.',
    duplicateSupplierCode: '중복되는 제품코드입니다.',
    barcodeEmpty: '바코드를 입력해주세요.',
    supplierProductCodeEmpty: '공급사 상품코드를 입력해주세요.',
    hsCodeEmpty: 'HS-Code를 입력해주세요.',
    outerBoxCountEmpty: '외박스 수량을 입력해주세요.',
    innerBoxCountEmpty: '패키지 수량을 입력해주세요.',
    productUnitEmpty: '상품 단위를 입력해주세요',
    productNetWeightEmpty: '상품 순중량을 입력해주세요.',
    productTotalWeightEmpty: '상품 총중량을 입력해주세요.',
    outerBoxIntakeEmpty: '외박스 입수량을 입력해주세요.',
    outerBoxTotalWeightEmpty: '외박스 총 중량을 입력해주세요.',
    outerBoxStandardSizeEmpty: '외박스 규격을 입력해주세요.',
    palletLoadCapacityEmpty: '팔레트 1단 적재량을 입력해주세요.',
    palletTotalCountEmpty: '팔레트 총 단수을 입력해주세요.',
    palletIntakeEmpty: '팔레트 입수량을 입력해주세요.',
    purchaseUnitPriceEmpty: '매입 단가를 입력해주세요.',
    retailPriceKrEmpty: '소비자가를 입력해주세요.',
    retailPriceVnEmpty: 'VN 소비자가를 입력해주세요.',
    regularSalePriceVnEmpty: 'VN 상시 판매가를 입력해주세요.',
    discountSalePriceVnEmpty: 'VN 할인 판매가를 입력해주세요.',
    retailPriceThEmpty: 'TH 소비자가를 입력해주세요.',
    regularSalePriceThEmpty: 'TH 상시 판매가를 입력해주세요.',
    discountSalePriceThEmpty: 'TH 할인 판매가를 입력해주세요.',
    manufacturerKrNameEmpty: '제조사명 국문을 입력해주세요.',
    manufacturerNameEmpty: '제조사명 영문을 입력해주세요.',
    manufacturerKrAddressEmpty: '제조사 주소지 국문을 입력해주세요.',
    manufacturerAddressEmpty: '제조사 주소지 영문을 입력해주세요.',
    importLicenseNameVnEmpty: 'VN 수입허가명을 입력해주세요.',
    importLicenseNameThEmpty: 'TH 수입허가명을 입력해주세요.',
    uploadWarning1: '일괄 등록은 신규 상품 경우에 사용해주세요.',
    uploadWarning2: 'Basic 정보가 등록되어있지 않은 상품은 수정이 불가능합니다.',
    uploadWarning3: '(GSPC 필수)',
    productGroupEditConfirm1: '상품 그룹 정보를 변경하시겠습니까?',
    productGroupEditConfirm2: '변경 내용은 모든 상품에 즉시 적용됩니다.',
    apply: '적용되었습니다',
    productTableEmptyWarning1: '등록된 정보가 없습니다.',
    productTableEmptyWarning2: '정보를 추가해주세요.',
    productGroupNameEmpty: '상품 그룹명 영문을 입력해주세요.',
    productGroupKrNameEmpty: '상품 그룹명 국문을 입력해주세요.',
    brandEmpty: '브랜드를 선택해주세요.',
    partnerEmpty: '파트너를 선택해주세요.',
    optionEmpty: '옵션을 선택해주세요.',
    optionValueEmpty: '옵션 값을 입력해주세요.',
    productOptionHelp: '옵션 목록은 상품 그룹 생성시 선택 후 수정이 불가능합니다.',
    tableInputHelp:
      '*옵션 값을 조합하여 생성된 상품 목록입니다. 존재하지 않는 제품을 삭제해주세요.',
    productOptionInputHelp:
      '* 옵션 국문명과 영문명은 아래 예시와 같이 슬래시로 구분해주시고, 한 카테고리 당 2개 이상의 옵션을 입력하시려면 엔터 후 입력해주세요.',
    productOptionInputHelp2: '예시 : #21N1 바닐라/#21N1 Vanilla',
    discountSalePriceHelp: '* 할인율 높은 순 : S급 → A급 → B급 → 상시 판매가',
    tableNoDataMessage1: '등록된 상품이 없습니다.',
    deleteProduct: '해당 상품을 삭제하시겠습니까?',
    tableNoDataMessage2: '상품을 등록해주세요.',
    deleteProducts: '{{count}}개의 상품 목록을 삭제합니다.',
    requestContent:
      '[{{productGroupName}}] 에 [{{count}}] 개의 상품을 검수요청 하시겠습니까?',
    applyContent:
      '[{{productGroupName}}] 에 [{{count}}] 개의 상품을 등록하시겠습니까? 해당 상품은 상품 목록으로 업데이트됩니다.',
    registration: '정보를 등록하시겠습니까?',
    registration2: '해당 정보는 상품목록으로 업데이트 됩니다.',
    sameProductGroupKrName: '동일한 상품 그룹명이 존재합니다.',
    priceInfo1: 'VAT미포함 : 매입단가',
    priceInfo2: 'VAT포함 : 소비자가,판매가,할인판매가',
    priceInfo3: '할인판매가 : 할인율 높은 순 S급 → A급 → B급 → 상시 판매가',
    registrationSuccess: '등록이 완료되었습니다.',
    registrationFail: '등록에 실패했습니다.',
    requestSuccess: '요청되었습니다.',
    requestFail: '요청에 실패했습니다.',
    saveSuccess: '저장되었습니다.',
    saveFail: '저장에 실패했습니다.',
  },
  label: {
    productKrName: '상품명 국문',
    productName: '상품명 영문',
    brand: '브랜드',
    partner: '파트너',
    addProduct: '상품 등록',
    addProduct2: '상품 추가',
    productGroupKrName: '상품 그룹명 국문',
    productGroupName: '상품 그룹명 영문',
    gspc: 'GSPC',
    barcode: '바코드',
    hsCode: 'HS-Code',
    outerBoxCount: '외박스 수량',
    innerBoxCount: '패키지 수량',
    productUnit: '상품 단위',
    productNetWeight: '상품 순중량',
    productTotalWeight: '상품 총중량',
    outerBoxIntake: '외박스 입수량',
    outerBoxTotalWeight: '외박스 총 중량',
    outerBoxStandardSize: '외박스 규격',
    palletLoadCapacity: '팔레트 1단 적재량',
    palletTotalCount: '팔레트 총 단수',
    palletIntake: '팔레트 입수량',
    purchaseUnitPrice: '매입 단가',
    retailPriceKr: '소비자가',
    retailPriceVn: 'VN 소비자가',
    regularSalePriceVn: 'VN 상시 판매가',
    discountSalePriceVn: 'VN 할인 판매가',
    retailPriceTh: 'TH 소비자가',
    regularSalePriceTh: 'TH 상시 판매가',
    discountSalePriceTh: 'TH 할인 판매가',
    manufacturerKrName: '제조사명 국문',
    manufacturerName: '제조사명 영문',
    manufacturerKrAddress: '제조사 주소지 국문',
    manufacturerAddress: '제조사 주소지 영문',
    importLicenseNameVn: 'VN 수입허가명',
    importLicenseNameTh: 'TH 수입허가명',
    discountSalePriceS: 'S급',
    discountSalePriceA: 'A급',
    discountSalePriceB: 'B급',
    width: '가로',
    vertical: '세로',
    height: '높이',
    supplierProductCode: '공급사 상품코드',
    uploadBulkCreate: '상품 일괄 등록',
    uploadBulkUpdate: '상품 일괄 수정',
    groupInfo: '상품 그룹 정보',
    groupOriginalInfo: '기존 정보',
    groupEditInfo: '변경 정보',
    optionInfo: '상품 옵션',
    optionValue: '옵션 값',
    optionList: '옵션 목록',
    productList: '상품 목록',
    deleteCheckedProducts: '선택삭제',
    optionSubmit: '옵션 적용',
    option: '옵션',
    type: '종류',
    scent: '향',
    color: '색상',
    taste: '맛',
    size: '사이즈',
    count: '용량/수량',
    apply: '적용',
  },
  placeholder: {
    manufacturerKrName: '제조사 사업자등록증 기준',
    importLicenseNameVn: '수입 허가 받은 후 입력',
    search: '상품명, GSPC, 바코드 중 검색해주세요.',
    gspc: '상품 등록시 자동 생성',
    productGroupKrName: '패키지 제품명 기준 상품 그룹명을 입력하세요',
    productGroupName:
      '패키지 제품명 기준 상품 그룹명 (띄워쓰기 후 단어의 첫 글자는 대문자, 상황에 따라 대문자일 경우는 대문자 사용)',
    brand: '브랜드를 선택하세요',
    partner: '브랜드 선택 시 자동 입력',
    options: {
      type: '향, 색상, 맛, 사이즈, 용량/수량에 해당하지 않는 나머지 옵션 (예 : 리필 / Refill, 남산 AP-1009CH / NAMSAN AP-1009CH)',
      scent:
        '향수, 바디워시 등 제품이 향별로 존재하는 경우 (예 : 체리 블라썸 / Cherry Blossom, 코랄(장미) / Coral(Rose))',
      color: '제품이 색상별로 존재하는 경우 (예 : #01 윈썸 / #01 Winsome, 25Y / 25Y )',
      taste: '제품이 맛별로 존재하는 경우 (예 : 우유 / Milk, 오리지널 / Original)',
      size: '제품이 사이즈별로 존재하는 경우(예 : 스몰 / Small, 11 인치 / 11 Inch)',
      count: '제품의 용량/수량 (예 : 100ml/100ml, 20g*5E/20g*5E)',
    },
  },
  table: {
    productGroupName: '상품 그룹명',
    productName: '상품명',
    gspc: 'GSPC',
    barcode: '바코드',
    brandName: '브랜드',
    productType: '요청 구분',
  },
};
