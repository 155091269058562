import { useState } from 'react';
import { createContainer } from 'unstated-next';

import { localStorageController } from '@/lib/localStorageController';
import { ThemeType } from '@/types/enums';
import { ThemeKey } from '@/types/Keys';

interface ThemeState {
  theme: ThemeType;
  current: number;
}

const settingContainer = () => {
  /*
  테마 색 상태
   */
  const [theme, setTheme] = useState<ThemeState>({
    theme: localStorageController.get(ThemeKey)
      ? localStorageController.get(ThemeKey) === ThemeType.light
        ? ThemeType.light
        : ThemeType.dark
      : ThemeType.light,
    current: 1,
  });

  const onChangeTheme = () => {
    localStorageController.set(
      'theme',
      theme.theme === ThemeType.light ? ThemeType.dark : ThemeType.light,
    );
    setTheme({
      ...theme,
      theme: theme.theme === ThemeType.light ? ThemeType.dark : ThemeType.light,
    });
  };

  return { theme, onChangeTheme };
};

export const SettingContainer = createContainer(settingContainer);
