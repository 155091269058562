import { mst } from '@gomi/utils';
import { Instance, SnapshotIn, types } from 'mobx-state-tree';

import { ALL } from '@/constant';
import {
  CountryEnum,
  ImportLicensingDocumentStatusEnum,
} from '@/generated/graphql-react-query';
import { MAX_STEPS } from '@/views/importLicensing/importLicensingDetail/Steps';

const IDENTIFIER = 'importLicensingDetailModel';

export const country = { ...CountryEnum, [ALL]: ALL };
const StepControl = types.model({
  current: types.number,
  status: mst.types.enumeration<ImportLicensingDocumentStatusEnum>(
    ImportLicensingDocumentStatusEnum,
  ),
});

const FileControl = types.model({
  selected: types.string,
  contentName: types.string,
});

export const model = types
  .model(IDENTIFIER, {
    identifier: IDENTIFIER,
    steps: StepControl,
    file: FileControl,
  })
  .actions((self) => ({
    setFile({ contentName, fileId }: { contentName: string; fileId: string }) {
      self.file.selected = fileId;
      self.file.contentName = contentName;
    },

    setCurrent(current: number, status?: ImportLicensingDocumentStatusEnum) {
      self.steps.current = current;
      self.steps.status = status || ImportLicensingDocumentStatusEnum.Ready;

      // 스탭 이동 시 파일 선택된 거 리셋
      self.file.selected = '';
      self.file.contentName = '';
    },

    getPrev() {
      const prev = self.steps.current - 1;
      if (prev < 0) {
        return 0;
      }

      return prev;
    },

    getNext() {
      const next = self.steps.current + 1;
      if (self.steps.current >= MAX_STEPS) {
        return MAX_STEPS - 1;
      }

      return next;
    },
  }));

/** 초기화 값 */
const defaultValue: SnapshotIn<typeof model> = {
  steps: {
    current: 0,
    status: ImportLicensingDocumentStatusEnum.Ready,
  },
  file: {
    selected: '',
    contentName: '',
  },
};

/** create or initialize */
const create = model.create(defaultValue);

const importLicensingDetailStore = {
  create,
  defaultValue,
  model,
};

/** 모델 타입 */
export type IImportLicensingDetailModelType = Instance<typeof model>;

export default importLicensingDetailStore;
