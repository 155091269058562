import { Track } from '@gomi/utils';
import React from 'react';

export interface IPropsLoggingClick {
  children: React.ReactElement;
  eventName: string;
  eventProperties?: Record<string, any> | undefined;
}

export const LoggingClick = ({
  eventName,
  eventProperties,
  children,
}: IPropsLoggingClick) => {
  return (
    <>
      {React.cloneElement(children, {
        onClick: () => {
          // 만약 props에 onClick이 있는 경우 함께 실행한다.
          if (children.props.onClick) {
            children.props.onClick();
          }
          console.info(`[track] event: ${eventName}`);
          Track.track(eventName, eventProperties);
        },
      })}
    </>
  );
};
