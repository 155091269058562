import { useImportLicensingTemplateListQuery } from '@/generated/graphql-react-query';

export const useTemplateList = () => {
  const { data, isLoading } = useImportLicensingTemplateListQuery({
    input: {},
  });

  return {
    list: data?.importLicensingTemplateList.nodes || [],
    total: data?.importLicensingTemplateList.total || 0,
    isLoading,
  };
};
