import { ColDef, ColGroupDef, ICellRendererParams } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';

/** list column */
export const ProductListColumn = (): ColDef[] | ColGroupDef[] => {
  const { t } = useTranslation(['importLicensingInfo']);

  return [
    {
      headerName: t('importLicensingInfo:table.no'),
      width: 55,
      cellStyle: { textAlign: 'center' },
      cellRenderer: ({ rowIndex }: ICellRendererParams) => rowIndex + 1,
    },
    {
      headerName: t('importLicensingInfo:table.productName'),
      field: 'contentName',
      cellClass: 'gm-flex gm-items-center',
      width: 240,
      minWidth: 240,
      flex: 1,
      cellRenderer: ({ data }: ICellRendererParams) =>
        `${data.contentKrName} / ${data.contentName}`,
    },
    {
      headerName: t('importLicensingInfo:table.brandName'),
      field: 'brandKrName',
      cellClass: 'gm-flex gm-items-center',
      width: 220,
      cellRenderer: ({ data }: ICellRendererParams) =>
        `${data.brandKrName} / ${data.brandName}`,
    },
    {
      headerName: t('importLicensingInfo:table.manufacturerName'),
      field: 'manufacturerKrName',
      cellClass: 'gm-flex gm-items-center',
      width: 220,
      cellRenderer: ({ data }: ICellRendererParams) =>
        `${data.manufacturerKrName} / ${data.manufacturerName}`,
    },
  ];
};
