import styled from '@emotion/styled';
import { Button, LoggingClick } from '@gomi/component';
import { Form, Input } from 'antd/es';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { TRACK_EVENT_NAME } from '@/constant/track';
import { useEmailCheckHook } from '@/hooks/user/useEmailCheckHook';
import { useRegisterHook } from '@/hooks/user/useRegisterHook';

const RegisterFormArea = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 84%;
  margin: auto;
  ${tw`gm-mb-8`}
`;

const RegisterFormInput = styled(Input)`
  border-radius: 5px;
  height: 45px;
`;

type TRegisterForm = { email: string; password: string; companyName: string };

export const RegisterForm = () => {
  const { t } = useTranslation(['newCommon']);
  const tranMsg = t('newCommon:register', { returnObjects: true });

  const [form] = Form.useForm<TRegisterForm>();
  const email = Form.useWatch('email', form);
  const { exist: isExist } = useEmailCheckHook({
    email,
  });

  const { isLoading, register } = useRegisterHook();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      register(values);
    });
  };

  return (
    <RegisterFormArea form={form} requiredMark={false} layout='vertical'>
      <Form.Item
        name='companyName'
        label={tranMsg.form.companyName.label}
        rules={[
          {
            required: true,
            message: tranMsg.form.companyName.required,
          },
        ]}
      >
        <Input size='large' />
      </Form.Item>

      <Form.Item
        name='email'
        label={tranMsg.form.email.label}
        rules={[
          {
            type: 'email',
            message: tranMsg.form.email.pattern,
          },
          {
            required: true,
            message: tranMsg.form.email.required,
          },
          {
            validator: async (_: any, email: string) => {
              if (!email) {
                return Promise.reject();
              }

              if (!isExist) {
                return Promise.resolve();
              }

              return Promise.reject(new Error(tranMsg.form.email.exist));
            },
          },
        ]}
      >
        <RegisterFormInput size='large' />
      </Form.Item>

      <Form.Item
        name='password'
        label={tranMsg.form.password.label}
        rules={[
          {
            required: true,
            message: tranMsg.form.password.required,
          },
        ]}
      >
        <RegisterFormInput type='password' size='large' />
      </Form.Item>
      <LoggingClick eventName={TRACK_EVENT_NAME.REGISTER_CLICK}>
        <Button
          btnType='filled'
          color='primary'
          onClick={handleSubmit}
          size='lg'
          isDisabled={isLoading}
        >
          {tranMsg.submit}
        </Button>
      </LoggingClick>
    </RegisterFormArea>
  );
};
