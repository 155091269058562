import { useTranslation } from 'react-i18next';

import { useGetB2bOrderHook } from '@/hooks/order/common/useGetB2bOrderHook';

interface IFilterControlBarProps {
  id: string;
}

export const FilterControlBar = ({ id }: IFilterControlBarProps) => {
  const { t } = useTranslation(['orderInfo']);
  const { b2bOrder } = useGetB2bOrderHook(id);

  return (
    <div className='gm-flex gm-justify-between gm-items-center gm-flex-nowrap gm-overflow-x-auto'>
      <div className='gm-min-w-24'>
        <span className='gm-mr-2'>{t('orderInfo:detailPage.control.title')}</span>
        <span>
          {t('orderInfo:detailPage.control.count', {
            count: b2bOrder.orderDetails.length,
          })}
        </span>
      </div>
    </div>
  );
};
