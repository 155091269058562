import { PartnerInfo } from '@/locale/data/types/partnerInfoLocale.type';

export const enPartnerInfo: PartnerInfo = {
  rootMenu: 'Partner information',
  listPage: {
    title: 'Partner list',
  },
  publicCreatePage: {
    header: {
      applyConfirmHeader: 'Company information registration',
      completedHeader: 'Submitted',
      requestHeader: 'Partner registration for Gomi Partner',
      failHeader: 'Failed to register partner information',
    },
    placeholder: {
      countryId: 'Partner country (Based on Certificate of Business Registration)',
      companyGroup: 'Contract type with Gomi Corporation',
      corporateRegistrationNumber:
        "Based on Certificate of Business Registration (including '-')",
      accountNumber: "Based on bank book (including '-')",
    },
    label: {
      confirm: 'Confirm',
      close: 'Cancel',
      submit: 'Submit',
      companyKrName: 'Company name (KR)',
      companyName: 'Company name (EN)',
      countryId: 'Partner country',
      companyGroup: 'Group category',
      companyImagePath: 'CI',
    },
    message: {
      companyImageHelpText:
        'File name must be “CI_Partner name (KR)”. Upload files under 200MB.',
      registrationImageHelpText:
        'File name must be “Certificate of Business Registration_Partner name (KR)”. Upload files under 200MB.',
      contractHelpText1: 'Supply Agreement or other types of contract',
      contractHelpText2:
        'File name must be “Contract_Partner name (KR)”. Upload files under 200MB.',
      bankImageHelpText:
        'File name must be “Bank book_Partner name (KR)”. Upload files under 200MB.',
      message1: 'Hello, this is Gomi Partner!',
      message2:
        'Please fill in all the required information to be registered on Gomi Partner.',
      message3: '* Required',
      message4:
        'The information you requested will be registered on Gomi Partner after internal review.',
      completedMessage1: 'Thank you for your submission!',
      completedMessage2:
        'The information you requested will be registered on Gomi Partner after internal review.',
      completedMessage3: 'Please wait for 1-3 working days for review.',
      confirmMessage1: 'Is everything correct?',
      confirmMessage2: 'If everything is correct, proceed to submit!',
      errorMessage1: 'The request cannot be processed.',
      errorMessage2: 'Please contact the help center.',
    },
  },
  managementPage: {
    title: 'Partner management',
    sharingApplyLinkTitle: 'Share a registration URL',
    tab: {
      checkComplete: 'Complete reviewing',
      checkRequest: ' Request for review',
      checkReject: 'Not approved',
      tempSave: 'Save temporarily',
      partnerRequest: 'Partner inflow',
    },
  },
  control: {
    title: 'List',
    count: '{{count}}',
    label: {
      country: 'Target country',
      partnerCountry: 'Partner country',
      partner: 'Partner',
      group: 'Partner group',
      manager: 'Person in charge',
      all: 'All',
    },
    searchPlaceHolder: 'Search partner code or partner name',
  },
  modal: {
    title: {
      registrationTitle: 'Partner registration',
      requestForInspection: 'Request for review',
      cancelRequestForInspection: 'Cancel request for review',
      batchRegistration: 'Partner registration in all',
      deleteRequest: 'Delete',
      confirmRegistration: 'Confirm review',
      confirmRejection: 'Confirm non-approval',
      requestCancel: 'Cancel request',
    },
    message: {
      invalidFileExtension: 'Upload files only in certain formats.',
      maxFileSize: 'Upload files under 200MB.',
      uploadFail: 'Failed to upload a file',
      inputRequiredValueError: 'Necessary value',
      emptyCompanyName:
        '"Partner name (KR) & partner name (EN)" are required when saving temporarily.',
      requestForInspection: 'Unable to edit or delete once request has been made.',
      registrationSuccess: 'Registered successfully',
      registrationFail: 'Registration failed',
      requestSuccess: 'Requested successfully',
      requestFail: 'Request failed',
      delete: 'Deleted the request',
      rejection: 'Not approved',
      cancelRequest: 'Cancelled',
      selectPartner: 'Select a partner',
      requestCancel: 'Cancel the request?',
      temporary: 'Save temporarily?',
      saveSuccess: 'Saved successfully',
      saveFail: 'Save failed',
      cancelRequestForInspection: 'Cancel the request?',
      deleteRequest: 'Delete?',
      deleteRequestSuccess: 'Deleted',
      deleteRequestFail: 'Deletion failed',
      registration:
        'Register partner information?\nIt will be updated into the partner list.',
      confirmRegistration: 'Complete the request?',
      confirmRejection: "Don't approve the request?",
      needRegistrationImage: 'Certificate of Business Registration is required.',
      lackOfInformation: 'Required information is missing.',
      selectFile: 'Select a file',
      bulkUploadSuccessTitle: 'Partner registered in all successfully',
      bulkUploadSuccessContent1: 'File uploaded successfully',
      bulkUploadSuccessContent2:
        'Uploaded information will be available in the Temporary Save tab.',
      bulkUploadFailTitle: 'Partner registration in all failed',
      bulkUploadFailContent: 'Upload failed! Upload files in the correct format.',
      overSize: 'Upload files under 200MB.',
      dragFile: 'Click here or drag a file.',
      uploadWarningTitle: 'Notice on Excel upload',
      uploadWarning1: 'Partner registration (in all) is for new partners.',
      uploadWarning2: '(Unavailable to register in all)',
      uploadWarning3: 'Template download',
      uploadWarning4: 'Upload files in the correct format.',
      uploadWarning5:
        'Partner registration (in all) cannot be processed when Excel file is not in the correct format.',
      uploadWarning6: 'Upload files under 200MB.',
      rejectionMessage: 'Your request has not been approved.',
      exportFail: 'Export to Excel failed',
      exportFailContent1: 'No downloadable partner information',
      exportFailContent2: 'Register a partner',
      doNotHavePermission: 'Access denied',
      lackOfCompanyInformation: 'Not enough partner information',
      tableNoDataMessage1: 'No registered partner',
      tableNoDataMessage2: 'Register a partner',
      notUseKorean: 'Type "Partner name (EN)" in English.',
      notUseCompanyKorean: 'Type "Partner name (EN)" in English.',
      noEdit:
        'Unable to edit currently non-operating partner. Please contact the help center.',
    },
    label: {
      purchase: 'Buying',
      purchaseBySales: 'Sales-based buying',
      consignment: 'Consignment',
      none: '-',
      salesType: 'Contract type',
      accountNumber: 'Bank account number',
      address: 'Partner address',
      bankImagePath: 'Bank book',
      bankName: 'Bank name',
      ceoName: 'CEO name',
      companyGroup: 'Partner group',
      companyImagePath: 'Partner CI',
      companyKrName: 'Partner name (KR)',
      companyName: 'Partner name (EN)',
      contractPath: 'Contract',
      corporateRegistrationNumber: 'Corporate registration number',
      countryId: 'Partner country',
      digitalTaxInvoiceEmail: 'Tax-related E-mail address',
      partnerId: 'Partner ID',
      partnerCode: 'Partner code',
      registrationImagePath: 'Certificate of Business Registration',
      registrationNumber: 'Business registration number',
      gomiManager: "GOMI's person in charge",
    },
    button: {
      requestCancel: 'Cancel request',
      modalToggle: 'Partner registration',
      upload: 'Upload',
      uploadBulk: 'Partner registration in all',
      fileUpload: 'File upload',
      requestForInspection: 'Request for review',
      temporarySave: 'Save temporarily',
      cancel: 'Cancel',
      confirm: 'Confirm',
      cancelRequestForInspection: 'Cancel request',
      delete: 'Delete',
      modify: 'Edit',
      save: 'Save',
      registration: 'Registration',
      confirmRegistration: 'Confirm review',
      rejection: 'Not approved',
    },
    placeholder: {
      salesType: 'Select contract type',
      registrationWithDash:
        'Based on Certificate of Business Registration (including "-")',
      bankName: 'Based on bank book',
      companyGroup: 'Select partner group',
      companyImagePath: 'Upload in jpeg, jpg, png format',
      registrationWithWhiteSpace:
        'Based on Certificate of Business Registration (Spacing included)',
      registration: 'Based on Certificate of Business Registration',
      companyName:
        'Based on Certificate of Business Registration (EN) (Spacing & special chracters included)',
      countryId: 'Select partner country',
      digitalTaxInvoiceEmail: 'Tax-related E-mail address',
      pdfUpload: 'Upload in pdf format',
      gomiManagers: "Select GOMI's person in charge",
      confirmRejection: '(Optional) Input reason for non-approval (Max. 150 characters)',
      notExistFile: 'No files',
      notExistGomiManager: "GOMI's person in charge not found",
    },
  },
  table: {
    no: 'No',
    partnerCode: 'Partner code',
    routeType: 'Inflow route',
    sellingCountry: 'Target country',
    partnerName: 'Partner name (KR/EN)',
    partnerCountry: 'Partner country',
    partnerGroup: 'Partner group',
    ceoName: 'CEO name',
    manager: 'GOMI team in charge / Person in charge',
    dateDone: 'Complete date',
    dateRequest: 'Request date',
    dateReject: 'Non-approval date',
    dateTemp: 'Save date',
    dateUpdate: 'Update date',
    routeTypeAdmin: 'Person in charge',
    routeTypePartner: 'Partner',
  },
};
