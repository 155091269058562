import { AgTable, Card } from '@gomi/component';
import { usePagingHook } from '@gomi/hooks';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ALL } from '@/constant';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { useProductListHook } from '@/hooks/product/list/useProductListHook';
import { ListHeader } from '@/views/product/common/ListHeader';
import { ProductTableNoData } from '@/views/product/common/ProductTableNoData';
import { ListColumn } from '@/views/product/list/ListColumn';
import { DetailModal } from '@/views/product/list/modal';

/** props */
interface IPropsList {}

/** component: 상품 목록 > 리스트 */
const List: FC<IPropsList> = () => {
  const { paging, onChangePage } = usePagingHook();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [brandIds, setBrandIds] = useState<string[]>([ALL]);
  const [productId, setProductId] = useState<string>();
  const [isShowProductModal, setIsShowProductModal] = useState<boolean>(false);
  const { productListQuery, exceldownMutation, productListTotalCount, getFilter } =
    useProductListHook({
      paging,
      searchKeyword,
      brandIds,
    });
  const { t } = useTranslation(['partnerInfo', 'productInfo']);
  const { isPC } = useMediaQueryHook();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryProductId = queryParams.get('productId');

  /** 정산검수 > 매입단가 등록 버튼 > 페이지 이동후 모달 */
  useEffect(() => {
    if (queryProductId) {
      onClickProduct(queryProductId);
    }
  }, [queryProductId]);

  /** 엑셀 다운로드 */
  const handleClickExcelDown = () => {
    exceldownMutation.mutate({
      input: {
        filter: getFilter(),
      },
    });
  };

  /** 상품명 클릭시 - 다이얼로그박스 show */
  const onClickProduct = (productId: string) => {
    setProductId(productId);
    setIsShowProductModal(true);
  };

  return (
    <>
      <Card
        className='gm-h-full'
        header={
          <ListHeader
            isShowExcelButton
            brandIds={brandIds}
            isLoadingExcelDown={exceldownMutation.isLoading}
            totalCount={productListTotalCount}
            onSearch={(value) => setSearchKeyword(value)}
            onClickExcelDown={handleClickExcelDown}
            onChangeBrand={setBrandIds}
          />
        }
        isContentsBackground={false}
      >
        <AgTable
          loading={productListQuery.isLoading}
          rowData={productListQuery.data?.productList.nodes || []}
          columnDefs={ListColumn({ onClickProduct, paging, tran: t, isPC })}
          defaultColDef={{ resizable: true }}
          paginationProps={{
            totalCount: productListTotalCount,
            currentPage: paging.no,
            onChangePage,
            pageSize: paging.size,
          }}
          noRowsOverlayComponent={ProductTableNoData}
          enableBrowserTooltips
        />
      </Card>
      {productId && (
        <DetailModal
          isShow={isShowProductModal}
          id={productId}
          onClose={() => setIsShowProductModal(false)}
        />
      )}
    </>
  );
};

export { List };
