import { CountryEnum } from '@/generated/graphql-react-query';

import { defaultOption } from '../common';

export const controlTargetCountryOptionList = [
  defaultOption,
  { title: CountryEnum.Kr, value: CountryEnum.Kr },
  { title: CountryEnum.Vn, value: CountryEnum.Vn },
  { title: CountryEnum.Th, value: CountryEnum.Th },
  { title: CountryEnum.Jp, value: CountryEnum.Jp },
  { title: CountryEnum.Cl, value: CountryEnum.Cl },
];
