import '@/pages/auth/index.css';

import styled from '@emotion/styled';
import GomiLogo from '@gomi/component/src/assets/icons/gomiLogo.svg?component';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import tw from 'twin.macro';

import { setRootMenus } from '@/component/Menu';
import { AUTH } from '@/constant/auth';
import { AuthContainer } from '@/container/AuthContainer';
import { DtoGetLogin } from '@/generated/graphql';
import { PATH } from '@/routes';
import { LoginForm } from '@/views/auth/LoginForm';

const LoginPageRow = styled(Row)`
  background-color: ${(props) => props.theme.color.neutrals[300]};
  height: 100vh;
  width: 100vw;
`;

const LoginContentRow = styled(Row)`
  background-color: ${(props) => props.theme.color.neutrals[100]};
  width: 32.5rem;
  ${tw`gm-rounded-md`}
`;

const LoginFormCol = styled(Col)`
  height: 100%;
  padding: 2.5rem;
`;

const LoginPageTitleRow = styled(Row)({
  fontSize: '1.875rem',
  fontWeight: 600,
  lineHeight: '2.25rem',
  marginBottom: '2rem',
});

const LoginPageCautionP = styled.p({
  fontSize: '0.75rem',
  lineHeight: '1rem',
  textAlign: 'center',
});

const FindPasswordLink = styled(Link)`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.color.primary};
  ${tw`gm-text-sm gm-mb-4`}
`;

export const LoginPage = () => {
  const { onSubmitLogin, isLogin } = AuthContainer.useContainer();
  const history = useHistory();
  const { t } = useTranslation(['newCommon']);
  const tranRegisterMsg = t('newCommon:register', { returnObjects: true });
  const tranLoginMsg = t('newCommon:login', { returnObjects: true });

  const onSubmit = (formData: DtoGetLogin) => {
    onSubmitLogin(formData);
  };

  useEffect(() => {
    setRootMenus([]);
    if (isLogin) {
      history.push(PATH.HOME);
    }
  }, []);

  return (
    <>
      <LoginPageRow id='loginPage' justify='center' align='middle'>
        <LoginContentRow justify='space-around'>
          <LoginFormCol md={24} xs={24}>
            <LoginPageTitleRow justify='center' align='middle'>
              <GomiLogo width={250} height={40} />
            </LoginPageTitleRow>
            <LoginForm onSubmit={onSubmit} />
            <div className='gm-flex gm-justify-center'>
              <FindPasswordLink to={PATH.REGISTER} className='gm-mr-4'>
                <span>{tranRegisterMsg.title}</span>
              </FindPasswordLink>
              <FindPasswordLink to={PATH.FIND_PASSWORD}>
                <span>{tranLoginMsg.findPassword}</span>
              </FindPasswordLink>
            </div>
            <LoginPageCautionP>{AUTH.NOTICE}</LoginPageCautionP>
          </LoginFormCol>
        </LoginContentRow>
      </LoginPageRow>
    </>
  );
};
