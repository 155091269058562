import { Instance, SnapshotIn, types } from 'mobx-state-tree';

import { momentDateFormat } from '@/lib';

const IDENTIFIER = 'homeModel';

export const model = types
  .model(IDENTIFIER, {
    identifier: IDENTIFIER,
    startDate: types.string,
    endDate: types.string,
  })
  .actions((self) => ({
    setDate(startDate: string, endDate: string) {
      self.startDate = startDate;
      self.endDate = endDate;
    },
  }));

/** 초기화 값 */
const defaultValue: SnapshotIn<typeof model> = {
  startDate: momentDateFormat.get1WeekAgoFromToday(),
  endDate: momentDateFormat.getToday,
};

/** create or initialize */
const create = model.create(defaultValue);

const homeStore = {
  create,
  defaultValue,
  model,
};

/** 모델 타입 */
export type IHomeModelType = Instance<typeof model>;

export default homeStore;
