import { useEffect, useState } from 'react';

import { useB2bOrderQuery } from '@/generated/graphql-react-query';

export interface InventoryQuantity {
  id: string;
  inventoryQuantity: number;
  totalPrice: number;
}

export const useUpdateB2bInventoryQuantitiesHook = (id: string) => {
  const [b2bInventoryQuantities, setB2bInventoryQuantities] = useState<
    InventoryQuantity[]
  >([]);
  const { data } = useB2bOrderQuery({ b2BOrderId: id });

  const onInventoryQuantityChange = ({
    index,
    newInventoryQuantity,
  }: {
    index: number;
    newInventoryQuantity: InventoryQuantity;
  }) => {
    const newInventoryQuantities = [...b2bInventoryQuantities];
    newInventoryQuantities[index] = newInventoryQuantity;
    setB2bInventoryQuantities(newInventoryQuantities);
  };

  useEffect(() => {
    if (!data) return;

    const newInventoryQuantities = data.b2bOrder.orderDetails.map((item) => ({
      id: item.id,
      inventoryQuantity: item.inventoryQuantity,
      totalPrice: item.totalPrice,
    }));
    setB2bInventoryQuantities(newInventoryQuantities);
  }, [data]);

  return {
    b2bInventoryQuantities,
    onInventoryQuantityChange,
  };
};
