import { FormInstance } from 'antd';
import { Form, Input } from 'antd/es';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { RegisterB2bOrderInputType } from '@/generated/graphql-react-query';
import { useGetB2bOrderHook } from '@/hooks/order/common/useGetB2bOrderHook';

interface IOrderFormProps {
  form: FormInstance<Omit<RegisterB2bOrderInputType, 'orderDetails'>>;
}

export const OrderForm = ({ form }: IOrderFormProps) => {
  const { t } = useTranslation(['orderInfo']);
  const { orderId } = useParams<{ orderId: string }>();
  const { b2bOrder } = useGetB2bOrderHook(orderId);

  useEffect(() => {
    form.setFieldsValue({
      receiverAddress: b2bOrder.receiverAddress,
      receiverName: b2bOrder.receiverName,
      receiverContactInformation: b2bOrder.receiverContactInformation,
    });
  }, [b2bOrder]);

  return (
    <Form form={form} layout='vertical' autoComplete='off'>
      {/* 입고지 주소 */}
      <Form.Item
        name='receiverAddress'
        label={t('orderInfo:createPage.form.receiverAddress.label')}
      >
        <Input
          disabled
          placeholder={t('orderInfo:createPage.form.receiverAddress.placeholder')}
        />
      </Form.Item>

      {/* 이름 */}
      <Form.Item
        name='receiverName'
        label={t('orderInfo:createPage.form.receiverName.label')}
      >
        <Input
          disabled
          placeholder={t('orderInfo:createPage.form.receiverName.placeholder')}
        />
      </Form.Item>

      {/* 연락처 */}
      <Form.Item
        name='receiverContactInformation'
        label={t('orderInfo:createPage.form.receiverContactInformation.label')}
      >
        <Input
          disabled
          placeholder={t(
            'orderInfo:createPage.form.receiverContactInformation.placeholder',
          )}
        />
      </Form.Item>
    </Form>
  );
};
