import { ReactNode } from 'react';

import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';

interface MediaQueryProps {
  children: ReactNode;
}

export const Mobile = ({ children }: MediaQueryProps) => {
  const { isMobile, isTablet } = useMediaQueryHook();

  return <>{(isMobile || isTablet) && children}</>;
};

export const PC = ({ children }: MediaQueryProps) => {
  const { isPC } = useMediaQueryHook();

  return <>{isPC && children}</>;
};
