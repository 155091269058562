import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { Card, Empty, ExcelButton, LoggingClick } from '@gomi/component';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AgTable } from '@/component/Table/AgTable';
import { ReportTableColumnWidth } from '@/constant/report';
import { TRACK_EVENT_NAME } from '@/constant/track';
import { ReportExportExcelEnum } from '@/generated/graphql-react-query';
import { useChannelsHook } from '@/hooks/report/useChannelsHook';
import { useReportDownloadHook } from '@/hooks/report/useReportDownloadHook';
import { useSalesStatusByDateHook } from '@/hooks/report/useSalesStatusByDateHook';
import { useStore } from '@/stores';
import { parseNumberStringFormat } from '@/views/report/sales/DetailOrderInfoColumn';

const enum OrderStatusEnum {
  ok = 'OK',
  cancel = 'CANCEL',
}

const channelColDef = (
  orderStatus: OrderStatusEnum,
  channelList: { channelName: string; displayName: string }[],
): ColGroupDef[] => {
  return channelList.map((channelInfo) => {
    return {
      headerName: channelInfo.displayName,
      children: [
        {
          field: `${channelInfo.channelName}_${orderStatus}_COUNT`,
          headerName: i18next.t('report:column.count'),
          type: 'numberColumn',
          width: ReportTableColumnWidth.CountColumnWidth,
          valueFormatter: parseNumberStringFormat,
        },
        {
          field: `${channelInfo.channelName}_${orderStatus}_AMOUNT`,
          headerName: i18next.t('report:column.totalPrice'),
          type: 'numberColumn',
          width: ReportTableColumnWidth.PriceColumnWidth,
          valueFormatter: parseNumberStringFormat,
        },
      ],
    };
  });
};

/**
 * 날짜별 판매현황 테이블
 */
export const SalesStatusByDateList = observer(() => {
  const { t } = useTranslation();
  const {
    reportControlModel: {
      countryId,
      brandId,
      salesCode,
      startDate,
      endDate,
      orderStatus,
      paymentStatus,
      shippingStatus,
      channel,
      gift,
    },
  } = useStore();

  const { channelList } = useChannelsHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
  });

  const { data, footer, isLoading } = useSalesStatusByDateHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
  });

  /* 시간별 판매 현황 엑셀 다운로드 */
  const { download, isLoading: isDownloadLoading } = useReportDownloadHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
  });

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'date',
      headerName: i18next.t('report:column.date'),
      pinned: 'left',
    },
    {
      field: 'ok',
      headerName: i18next.t('report:column.ok'),
      marryChildren: true,
      children: channelColDef(OrderStatusEnum.ok, channelList),
    },
    {
      field: 'cancel',
      headerName: i18next.t('report:column.cancel'),
      marryChildren: true,
      children: channelColDef(OrderStatusEnum.cancel, channelList),
    },
    {
      field: 'total',
      headerName: i18next.t('report:column.sum'),
      marryChildren: true,
      children: [
        {
          field: 'totalCount',
          headerName: i18next.t('report:column.count'),
          type: 'numberColumn',
          width: ReportTableColumnWidth.CountColumnWidth,
          valueFormatter: parseNumberStringFormat,
        },
        {
          field: 'totalAmount',
          headerName: i18next.t('report:column.totalPrice'),
          type: 'numberColumn',
          width: ReportTableColumnWidth.PriceColumnWidth,
          valueFormatter: parseNumberStringFormat,
        },
      ],
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
      width: ReportTableColumnWidth.DefaultColumnWidth,
    };
  }, []);

  const handleExcelDownload = () => {
    download(ReportExportExcelEnum.SalesStatusByDate);
  };

  return (
    <div>
      <Card
        header={
          <div className='gm-flex gm-justify-between gm-items-center'>
            {t('report:title.salesStatusByDate')}
            <LoggingClick
              eventName={TRACK_EVENT_NAME.REPORT_SALES_STATUS_BY_DATE_DOWNLOAD}
            >
              <ExcelButton
                type='button'
                loading={isDownloadLoading}
                onClick={handleExcelDownload}
                isDisabled={!data.length || isDownloadLoading}
              >
                {t('common:common.button.download')}
              </ExcelButton>
            </LoggingClick>
          </div>
        }
      >
        <AgTable
          loading={isLoading}
          defaultColDef={defaultColDef}
          column={columnDefs}
          rowData={data}
          pinnedBottomRowData={[footer]}
          noRowsOverlayComponent={() => <Empty onShowImage>No Data</Empty>}
        />
      </Card>
    </div>
  );
});
