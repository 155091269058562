import { AxiosResponse } from 'axios';

export const downloadFile = (response: AxiosResponse<Blob>) => {
  const blob = new Blob([response.data], { type: response.headers['content-type'] });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;

  const filename = response.headers['content-disposition']
    .split('filename=')[1]
    .split(';')[0];

  link.setAttribute('download', decodeURI(filename));
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

export const downloadFileUrl = (url: string) => {
  const link = document.createElement('a');
  link.href = url;

  link.setAttribute('download', decodeURI('filename'));
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};
