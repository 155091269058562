import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useDeleteImportLicensingContentMutation } from '@/generated/graphql-react-query';

export const useDeleteContentHook = () => {
  const { t } = useTranslation(['importLicensingInfo']);
  const { mutateAsync } = useDeleteImportLicensingContentMutation({
    onSuccess: () => {
      message.success(
        t('importLicensingInfo:detailPage.steps.first.content.deleteConfirm.success'),
      );
    },
  });

  return {
    mutateAsync,
  };
};
