import { CloseOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, LoggingClick, SideMenuLogo } from '@gomi/component';
import { Menu as AntdMenu, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import {
  getRootMenus,
  removeMenuInSessionStorage,
  setRootMenus,
} from '@/component/Menu/lib';
import { MenuData, rootKeys } from '@/component/Menu/MenuData';
import { MenuItem } from '@/component/Menu/types';
import { COPYRIGHT } from '@/constant';
import { TRACK_EVENT_NAME } from '@/constant/track';
import { AuthContainer } from '@/container';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { PATH } from '@/routes';
import { routeList } from '@/routes/routeList';
import { IRoute } from '@/routes/types';
import { Mobile, PC } from '@/views/common/MediaQuery';

interface MenuProps {
  collapsed: boolean;
  onChangeCollapsed(): void;
}

const { SubMenu, Item, ItemGroup } = AntdMenu;

const HeaderUserName = styled.span`
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: rgba(107, 114, 128, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
`;

const Footer = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
  position: absolute;
  bottom: 0;
`;

export const Menu = ({ collapsed, onChangeCollapsed }: MenuProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [openKeys, setOpenKeys] = useState<string[]>(getRootMenus());
  const { userInfo, signOut, hasAuthority } = AuthContainer.useContainer();
  const { isPC } = useMediaQueryHook();

  const onClickMenu = (menu: MenuItem) => {
    if (menu.path) {
      history.push(menu.path);
    }
  };

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find(
      (key: string) => openKeys.indexOf(key) === -1,
    ) as string;

    if (rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);

      if (isPC) {
        setRootMenus(keys);
      }
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);

      if (isPC) {
        setRootMenus([latestOpenKey]);
      }
    }
  };

  const onLogOut = () => {
    signOut();
    removeMenuInSessionStorage();
  };

  const getMatchedKey = (path: string) => {
    const pathInfo =
      (routeList.find((route) => matchPath(path, route.path)) as IRoute) || {};
    return pathInfo.menuPath || pathInfo.path || '';
  };

  const MenuTree = (menu: MenuItem[]) => {
    return menu
      .filter((item) => {
        // 모두 허용되는 권한일 경우 항상 보이기
        if (!item.authorizations) {
          return true;
        }

        return item.authorizations.some((authorization) => hasAuthority(authorization));
      })
      .map((item) => {
        if (item.type === 'group') {
          return (
            item.children && (
              <ItemGroup title={item.title}>{MenuTree(item.children)}</ItemGroup>
            )
          );
        }

        return item.children ? (
          <SubMenu key={item.key} title={item.title} icon={item.icon}>
            {MenuTree(item.children)}
          </SubMenu>
        ) : (
          <Item key={item.path} icon={item.icon} onClick={() => onClickMenu(item)}>
            {item.title}
          </Item>
        );
      });
  };

  return (
    <>
      <PC>
        <SideMenuLogo collapsed={collapsed} path={PATH.HOME} />
      </PC>
      <Mobile>
        <div className='gm-flex gm-justify-between gm-py-4 gm-pl-6 gm-pr-2'>
          <HeaderUserName className='gm-flex-1 gm-text-base'>
            {userInfo?.partnerMe.userName} {t('common.userNameSubtext')}
          </HeaderUserName>
          <Space>
            <LoggingClick eventName={TRACK_EVENT_NAME.LOGOUT_CLICK}>
              <Button type='button' onClick={onLogOut} className='gm-flex-1'>
                {t('button.header.logout')}
              </Button>
            </LoggingClick>
            <Button
              btnType='ghost'
              key='trigger-key-2'
              onClick={onChangeCollapsed}
              className='gm-relative gm-right-0'
            >
              <span id='sidebar-trigger' key='trigger-key-3'>
                <CloseOutlined key='trigger-key-5' />
              </span>
            </Button>
          </Space>
        </div>
      </Mobile>
      <AntdMenu
        mode='inline'
        selectedKeys={[getMatchedKey(location.pathname)]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        inlineCollapsed={collapsed}
      >
        {MenuTree(MenuData(userInfo?.partnerMe))}
      </AntdMenu>

      <Footer>{COPYRIGHT}</Footer>
    </>
  );
};
