import { ImportLicensingLocaleType } from '@/locale/data/types/importLicensingLocale.type';

export const koImportLicensingInfo: ImportLicensingLocaleType = {
  rootMenu: '수입 허가 등록',
  detailPage: {
    messages: {
      newComment: '새로운 수정 요청을 확인해보세요!',
      stepWarning: '이전 단계를 완료 후 이동 가능합니다.',
    },
    title: '수입 허가 등록 상세',
    upload: {
      exampleView: '예시보기',
      title: '자료 업로드',
      info: {
        title: '자료 업로드 시 주의사항',
      },
      confirm: {
        title: '파일 업로드 등록',
        content: '선택하신 파일을 등록하시겠습니까?',
        ok: '등록',
        cancel: '취소',
      },
      createButton: '등록',
      success: '업로드한 파일이 등록되었습니다.',
      empty: '필요한 서류들을 클릭하여 업로드해주세요.',
    },
    controlBar: {
      checkRequestButton: '확인요청',
      nextButton: '다음',
      previousButton: '이전',
    },
    status: {
      ready: '준비중',
      readyDone: '준비완료',
      requestConfirm: '확인중',
      requestEdit: '수정요청',
      done: '완료',
    },
    steps: {
      common: {
        requestConfirmationSuccess: '요청을 완료하였습니다.',
        complete: '완료되었습니다.',
        requestConfirmation: {
          title: '확인요청',
          content: '확인을 요청하시겠습니까?',
          ok: '요청',
          cancel: '취소',
        },
      },
      first: {
        label: '상품 등록',
        controlBar: {
          createButton: '상품등록',
        },
        content: {
          deleteConfirm: {
            title: '해당 내용을 삭제하시겠습니까?',
            ok: '삭제',
            cancel: '취소',
            success: '삭제되었습니다.',
          },
        },
        modal: {
          submit: '등록',
          title: '상품등록',
          form: {
            brandKrName: {
              label: '브랜드 (국문)',
              requiredMessage: '브랜드를 선택해주세요.',
              placeholder: '브랜드를 선택해주세요.',
              emptyComment1: '해당 파트너에 브랜드가 존재하지 않습니다.',
              emptyComment2: '브랜드를 등록 후 이용해주세요.',
            },
            brandName: {
              label: '브랜드 (영문)',
              requiredMessage: '브랜드를 선택해주세요.',
              placeholder: '브랜드를 선택하시면 자동으로 입력됩니다.',
            },
            productId: {
              label: '상품',
              requiredMessage: '상품을 선택해주세요.',
              placeholder: '상품을 선택해주세요.',
              emptyComment1: '해당 브랜드에 상품이 존재하지 않습니다.',
              emptyComment2: '상품을 등록 후 이용해주세요.',
            },
            productKrName: {
              label: '상품명 (국문)',
              requiredMessage: '상품명을 입력해주세요.',
              placeholder: '상품명을 입력해주세요.',
            },
            productName: {
              label: '상품명 (영문)',
              requiredMessage: '상품명을 입력해주세요.',
              placeholder: '상품명을 입력해주세요.',
            },
            manufacturerKrName: {
              label: '제조사명 (국문)',
              requiredMessage: '제조사를 입력해주세요.',
              placeholder: '제조사를 입력해주세요.',
            },
            manufacturerName: {
              label: '제조사명 (영문)',
              requiredMessage: '제조사를 입력해주세요.',
              placeholder: '제조사를 입력해주세요.',
            },
            salesPrice: {
              label: '판매가(₩)',
              placeholder: '실제 판매되고 있는 금액을 입력해주세요.',
            },
            desiredSalesPrice: {
              label: '희망 판매가({{unit}})',
              placeholder: '희망하시는 판매 금액을 입력해주세요.',
            },
          },
          message: {
            createSuccess: '등록되었습니다.',
            createError: '등록에 실패했습니다.',
          },
          confirm: {
            title: '상품 등록',
            content: '새로운 상품을 등록하시겠습니까?',
            ok: '등록',
            cancel: '취소',
          },
        },
      },
      second: {
        label: '상품별 서류 등록',
      },
      third: {
        label: '추가 서류',
        controlBar: {
          createButton: '서류 등록',
        },
        modal: {
          submit: '등록',
          title: '서류 등록',
          form: {
            documentName: {
              label: '서류명',
              requiredMessage: '서류명을 입력해주세요.',
              placeholder: '서류명을 입력해주세요.',
            },
          },
          message: {
            createSuccess: '등록되었습니다.',
            createError: '등록에 실패했습니다.',
          },
          confirm: {
            title: '서류 등록',
            content: '새로운 서류를 등록하시겠습니까?',
            ok: '등록',
            cancel: '취소',
          },
        },
      },
      forth: {
        label: '서류 전달',
        description1: '모든 서류가 준비 완료되었습니다.',
        description2: '아래 주소로 원본을 발송해주세요!',
        originalDocuments: '원본 서류',
        receiver: '받는 사람',
        receiverContent: '고미코퍼레이션 {{manager}} 매니저',
        address: '주소',
        addressContent: '서울특별시 강남구 강남대로 624, ICT Tower 7층',
        submit: '완료하고 견적 안내 확인하기',
      },
      fifth: {
        label: '견적 안내 및 대금 결제',
        controlBar: {
          deposit: '입금완료',
          onDepositChecking: '입금 확인 중',
          taxBill: '세금계산서 발행',
        },
        taxBillModal: {
          title: '세금계산서 발행 요청',
          submit: '요청',
          form: {
            email: {
              label: '세금계산서 받을 이메일',
              requiredMessage: '이메일을 입력해주세요.',
              placeholder: '이메일을 입력해주세요.',
            },
          },
          confirm: {
            title: '세금계산서 발행',
            message: '세금계산서 발행을 요청하시겠습니까?',
            ok: '요청',
            cancel: '취소',
            success: '세금계산서 발행을 요청하였습니다.',
          },
        },
        submitConfirm: {
          title: '입금완료',
          content: '입금을 완료하셨습니까?',
          ok: '완료',
          cancel: '취소',
        },
        information: {
          info1:
            '수입 허가 등록 소요 기간은 모든 서류 취합 이후 영업일 기준으로 40일 내외로 소요됩니다. 단, 베트남 현지 공휴일 및 상황에 따라 일정 변동이 발생할 수 있습니다.',
          info2: '본 견적은 견적 작성일로부터 30일간 유효합니다.',
        },
        productList: {
          control: {
            title: '견적 대상',
          },
        },
        bankInfo: {
          control: {
            title: '입금 정보',
            description: '입금 완료 후 우측 상단의 [임금 완료] 버튼을 클릭해주세요.',
          },
          accountHolder: '예금주',
          accountHolderContent: '주식회사 고미코퍼레이션',
          bankName: '은행명',
          bankNameContent: 'KB 국민은행',
          accountNumber: '계좌번호',
          accountNumberContent: '342301-04-170800',
        },
        estimateList: {
          control: {
            title: '견적 내용',
          },
          modal: {
            confirm: {
              exportExcel: {
                exportFail: '엑셀 다운로드 실패',
                exportFailContent1: '다운로드할 견적이 없습니다.',
                exportFailContent2: '견적을 등록해주세요.',
                ok: '확인',
              },
            },
          },
        },
      },
      sixth: {
        label: '발급 완료 ',
        controlBar: {
          upload: '공증 업로드',
          complete: '최종완료',
          confirm: {
            title: '최종완료',
            content: '파트너에게 완료 알림을 전달하시겠습니까?',
            ok: '완료',
            cancel: '취소',
            success: '파트너에게 완료 알림을 전달하였습니다.',
          },
          modal: {
            title: '최종완료',
            submit: '완료',
            success: '정상적으로 공증을 업로드하였습니다.',
          },
        },
        progress: {
          description1: '최종 발급을 위해 현재 베트남으로 전달 중이며',
          description2: '영업일 기준으로 {{period}}일 내외로 소요됩니다.',
          description3: '완료되는대로 안내해 드리겠습니다.',
          description4:
            '(단, 베트남 현지 공휴일 및 상황에 따라 일정 변동이 발생할 수 있습니다.)',
        },
        success: {
          description1: '수입 허가 등록이 완료되었습니다.',
          description2: '아래 버튼을 클릭하여 공증서를 확인해보세요.',
          download: '공증서 다운로드',
        },
      },
    },
  },
  listPage: {
    title: '수입 허가 등록',
    modal: {
      button: '수입 허가 구성',
      title: '수입 허가 구성',
      confirm: {
        title: '수입 허가 구성',
        message: '새로운 수입 허가를 구성하시겠습니까?',
        ok: '구성',
        cancel: '취소',
      },
      message: {
        success: '신규 수입 허가가 생성되었습니다.',
        fail: '수입 허가가 생성이 실패하였습니다.',
      },
      form: {
        submit: '구성',
        template: {
          label: '템플릿',
          requiredMessage: '템플릿을 선택해주세요.',
          placeholder: '템플릿을 선택해주세요.',
        },
        country: {
          label: '수입 허가를 받을 국가',
          requiredMessage: '수입 허가를 받을 국가를 선택해주세요.',
        },
      },
    },
  },
  fileViewerPage: {
    placeholder: {
      searchComment: '코멘트 검색',
    },
    button: {
      addComment: '요청 남기기',
    },
    error: {
      loading: '파일 로드에 실패했습니다.\n재시도해주세요.',
    },
    header: {
      status: {
        success: '변경되었습니다.',
      },
    },
    comment: {
      success: '새로운 요청이 추가하였습니다.',
      deleteSuccess: '해당 요청을 삭제하였습니다.',
      emptyComment: '요청을 작성해주세요.',
      deleteConfirm: {
        content: '요청을 삭제하시겠습니까?',
        ok: '삭제',
        cancel: '취소',
      },
      confirm: {
        title: '요청 등록',
        content: '새로운 요청을 등록하시겠습니까?',
        ok: '등록',
        cancel: '취소',
      },
    },
  },
  table: {
    no: 'No',
    name: '수입 허가 등록 명',
    status: '진행 상태',
    country: '국가',
    createdAt: '등록일',
    managerName: '담당자명',

    productName: '상품명',
    brandName: '브랜드',
    manufacturerName: '제조사',
    salesPrice: '판매가(₩)',
    desiredSalesPrice: '희망 판매가({{unit}})',
    doneAt: '완료일',
    download: '다운로드',

    documentName: '서류명',

    item: '항목',
    document: '서류',
    unit: '단위',
    count: '수량',
    price: '단가 (₩)',
    vat: 'VAT (₩)',
    totalPrice: '비용 (₩)',
    total: '합계',

    empty: '수입 허가를 등록한 내역이 없습니다.',
    emptyProducts: '상품을 등록해주세요.',
    emptyEstimate: '견적 내용이 없습니다.',
    emptyItem: '목록이 없습니다.\n항목을 추가해주세요.',

    category: '카테고리',
    updatedAt: '수정 날짜',
    writer: '수정자',

    actions: {
      edit: '수정',
    },
  },
  control: {
    title: '목록',
    count: '{{count}} 건',
    status: '진행 상태',
    country: '국가',
    searchPlaceHolder: '수입 허가명을 검색하세요.',
    statusOptions: {
      running: '진행중',
      done: '완료',
    },
  },
};
