import { ALL } from '@/constant';
import {
  ImportLicensingListInputFilterType,
  InputMaybe,
  useImportLicensingListQuery,
} from '@/generated/graphql-react-query';
import { TFilterControl } from '@/stores/importLicensing/list/store';

export const useImportLicensingListHook = ({
  countryId,
  step,
  searchKeyword,
}: TFilterControl) => {
  const { data, ...rest } = useImportLicensingListQuery({
    input: {
      filter: {
        ...(countryId !== ALL && { countryId }),
        ...(step !== ALL && { step }),
      } as InputMaybe<ImportLicensingListInputFilterType>,
      ...(searchKeyword && { keyword: searchKeyword }),
    },
  });

  const importLicensingListForTable =
    data?.importLicensingList.nodes.map((i, index) => ({ no: index + 1, ...i })) || [];

  return {
    total: data?.importLicensingList.total || 0,
    importLicensingListForTable,
    ...rest,
  };
};
