import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, message, Modal } from 'antd';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { useGetB2bOrderFilesHook } from '@/hooks/order/detail/useGetB2bOrderFilesHook';
import { useSetEstimatedArrivalDateHook } from '@/hooks/order/detail/useSetEstimatedArrivalDateHook';

interface IForm {
  estimatedArrivalDate: Moment;
}

interface ArrivalModalProps {
  id: string;
  open: boolean;
  onClose(): void;
  defaultDate: Moment | undefined;
}

export const ArrivalModal = ({ id, open, onClose, defaultDate }: ArrivalModalProps) => {
  const { t } = useTranslation(['orderInfo']);
  const [form] = Form.useForm<IForm>();

  const { refetchB2bOrderFiles } = useGetB2bOrderFilesHook(id);
  const { setEstimatedArrivalDate, isLoadingSetEstimatedArrivalDate } =
    useSetEstimatedArrivalDateHook({
      onSuccess: () => {
        refetchB2bOrderFiles();
        message.success(
          t(
            'orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.message.success',
          ),
        );
        onClose();
      },
      onError: () => {
        message.error(
          t('orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.message.error'),
        );
      },
    });

  const handleSetArrivalDateConfirmOk = () => {
    const values = form.getFieldsValue();

    setEstimatedArrivalDate(id, values.estimatedArrivalDate);
  };

  const handleSetArrivalDate = async () => {
    await form.validateFields();

    Modal.confirm({
      title: t('orderInfo:detailPage.actions.setEstimatedArrivalDate'),
      icon: <ExclamationCircleOutlined />,
      content: t(
        'orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.message.confirm',
      ),
      okText: t('orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.button.ok'),
      cancelText: t(
        'orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.button.cancel',
      ),
      centered: true,
      onOk: handleSetArrivalDateConfirmOk,
    });
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      width={320}
      destroyOnClose
      okText={t('orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.button.ok')}
      cancelText={t(
        'orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.button.cancel',
      )}
      cancelButtonProps={{ disabled: isLoadingSetEstimatedArrivalDate }}
      closable={!isLoadingSetEstimatedArrivalDate}
      confirmLoading={isLoadingSetEstimatedArrivalDate}
      onOk={handleSetArrivalDate}
    >
      <Form form={form} layout='vertical' autoComplete='off'>
        <Form.Item
          name='estimatedArrivalDate'
          label={t('orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.label')}
          rules={[
            {
              required: true,
              message: t(
                'orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.message.require',
              ),
            },
          ]}
          className='gm-mt-4 gm-mb-0'
        >
          <DatePicker
            defaultValue={defaultDate}
            placeholder={t(
              'orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.placeholder',
            )}
            className='gm-w-full'
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
