import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from '@gomi/component';
import { message, Modal, Tag } from 'antd';
import { Form, Select } from 'antd/es';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetB2bOrderContractListHook } from '@/hooks/order/common/useGetB2bOrderContractListHook';
import { useCreateB2bOrderHook } from '@/hooks/order/management/useCreateB2BOrderHook';
import { useGetB2bOrderListHook } from '@/hooks/order/management/useGetB2bOrderListHook';

interface CreateModalProps {
  open: boolean;
  onClose(): void;
}

interface IForm {
  companyId: string;
}

export const CreateModal = ({ open, onClose }: CreateModalProps) => {
  const { t } = useTranslation(['orderInfo']);
  const [form] = Form.useForm<IForm>();

  const { b2bOrderContractList } = useGetB2bOrderContractListHook();
  const { refetchB2bOrderList } = useGetB2bOrderListHook({ enabled: open });
  const { createB2bOrder, isLoading } = useCreateB2bOrderHook({
    onSuccess: () => {
      message.success(t('orderInfo:managementPage.message.createOrderSuccess'));
      refetchB2bOrderList();
      onClose();
    },
    onError: () => message.error(t('orderInfo:managementPage.message.createOrderError')),
  });

  const handleCreateOrderConfirmOk = () => {
    const values = form.getFieldsValue();

    createB2bOrder({ input: { b2bOrderContractId: values.companyId } });
  };

  const handleCreateOrder = async () => {
    await form.validateFields();

    Modal.confirm({
      title: t('orderInfo:managementPage.createModal.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('orderInfo:managementPage.message.createOrderConfirm'),
      okText: t('orderInfo:managementPage.createModal.form.submit'),
      cancelText: t('orderInfo:managementPage.createModal.form.cancel'),
      centered: true,
      onOk: handleCreateOrderConfirmOk,
    });
  };

  return (
    <Modal
      title={t('orderInfo:managementPage.createModal.title')}
      open={open}
      onCancel={onClose}
      width={664}
      destroyOnClose
      afterClose={form.resetFields}
      footer={
        <Button
          color='primary'
          btnType='filled'
          onClick={handleCreateOrder}
          isDisabled={isLoading}
        >
          {t('orderInfo:managementPage.createModal.form.submit')}
        </Button>
      }
    >
      <Form form={form} layout='vertical' autoComplete='off'>
        {/* 파트너 */}
        <Form.Item
          name='companyId'
          label={t('orderInfo:managementPage.createModal.form.company.label')}
          rules={[
            {
              required: true,
              message: t(
                'orderInfo:managementPage.createModal.form.company.requiredMessage',
              ),
            },
          ]}
        >
          <Select
            placeholder={t(
              'orderInfo:managementPage.createModal.form.company.placeholder',
            )}
            showSearch
            filterOption={(inputValue, option) =>
              (option?.children as ReactNode as string)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
          >
            {b2bOrderContractList.map((contract) => (
              <Select.Option value={contract.id} key={contract.id}>
                <span>{contract.sellerCompany.companyKrName}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
