import styled from '@emotion/styled';
import { AgTable } from '@gomi/component';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import {
  CountryEnum,
  useSettlementConsignmentPartnerFeeSummaryByPartnerQuery,
  useSettlementConsignmentPartnerSalesPriceSummaryByPartnerQuery,
  useSettlementConsignmentPartnerSummaryByPartnerQuery,
} from '@/generated/graphql-react-query';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { MonthlyDashboard } from '@/views/settlement/common/monthlyDashboard';
import { DashBoardFeeColumn } from '@/views/settlement/consignment/DashBoardFeeColumn';
import { DashBoardSettlementColumn } from '@/views/settlement/consignment/DashBoardSettlementColumn';

interface IPropsDashBoard {
  /** 파트너 아이디 */
  partnerId: string;
}

/** style */
const Wrapper = styled.div`
  .ag-row {
    ${tw`gm-border-b-0`}
  }
`;

/** component: 월별 정산내역 > 위탁 상세 > 대시보드
 * 전체 정산내역, 채널별 수수료 및 정산 금액
 */
const DashBoard: FC<IPropsDashBoard> = ({ partnerId }) => {
  const { t } = useTranslation('settlementInfo');
  const { isPC } = useMediaQueryHook();

  /** gql - summary */
  const { data: summary } = useSettlementConsignmentPartnerSummaryByPartnerQuery(
    {
      input: {
        filter: {
          settlementPartnerId: partnerId,
        },
      },
    },
    {
      enabled: !!partnerId,
    },
  );

  /** gql - 채널별 수수료 fee sumnary */
  const { data: feeSummaryData } =
    useSettlementConsignmentPartnerFeeSummaryByPartnerQuery(
      {
        input: {
          filter: {
            settlementPartnerId: partnerId,
          },
        },
      },
      {
        enabled: !!partnerId,
      },
    );

  /** gql - 채널별 정산 금액 sales price summary */
  const { data: salesPriceSummaryData } =
    useSettlementConsignmentPartnerSalesPriceSummaryByPartnerQuery(
      {
        input: {
          filter: {
            settlementPartnerId: partnerId,
          },
        },
      },
      {
        enabled: !!partnerId,
      },
    );

  const feeSummary = {
    channels:
      feeSummaryData?.settlementConsignmentPartnerFeeSummaryByPartner.channels || [],
    totalFee:
      feeSummaryData?.settlementConsignmentPartnerFeeSummaryByPartner.totalFee || 0,
  };

  const salesPriceSummary = {
    channels:
      salesPriceSummaryData?.settlementConsignmentPartnerSalesPriceSummaryByPartner
        .channels || [],
    totalSalesPrice:
      salesPriceSummaryData?.settlementConsignmentPartnerSalesPriceSummaryByPartner
        .totalSalesPrice || 0,
  };

  return (
    <Wrapper>
      <MonthlyDashboard
        isShowExchangeRate
        header={t('monthlyDashBoard.totalSettlementInfo')}
        yearMonth={
          summary?.settlementConsignmentPartnerSummaryByPartner.settlementYearMonth
        }
        countryId={CountryEnum.Vn}
        salesPrice={summary?.settlementConsignmentPartnerSummaryByPartner.salesPrice || 0}
        salesFee={summary?.settlementConsignmentPartnerSummaryByPartner.fee || 0}
        setoffPrice={
          summary?.settlementConsignmentPartnerSummaryByPartner.setoffPrice || 0
        }
        settlementPrice={
          summary?.settlementConsignmentPartnerSummaryByPartner.settlementPrice || 0
        }
      />

      <div className='gm-flex gm-flex-col md:gm-flex-row gm-gap-4'>
        {/* 채널별 정산 금액 */}
        <div className='gm-flex-1'>
          <AgTable
            className='gm-h-32 gm-min-h-fit'
            loading={false}
            rowData={[salesPriceSummary]}
            columnDefs={DashBoardSettlementColumn(isPC)}
            defaultColDef={{ resizable: true }}
            enableBrowserTooltips
          />
        </div>

        {/* 채널별 수수료 */}
        <div className='gm-flex-1'>
          <AgTable
            className='gm-h-32 gm-min-h-fit'
            loading={false}
            rowData={[feeSummary]}
            columnDefs={DashBoardFeeColumn(isPC)}
            defaultColDef={{ resizable: true }}
            enableBrowserTooltips
          />
        </div>
      </div>
    </Wrapper>
  );
};

export { DashBoard };
