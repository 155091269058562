import { useImportLicensingLicensingEstimateListQuery } from '@/generated/graphql-react-query';

export const useEstimateHook = (importLicensingLicensingId: string) => {
  const { data, isLoading, refetch } = useImportLicensingLicensingEstimateListQuery(
    {
      importLicensingLicensingEstimateListId: importLicensingLicensingId,
    },
    {
      enabled: !!importLicensingLicensingId,
    },
  );

  return {
    list: {
      list:
        data?.importLicensingLicensingEstimateList.nodes?.map((item) => ({
          ...item,
          total: (item.price + item.vat) * item.count,
        })) || [],
      footer: {
        itemName: '합계',
        total: data?.importLicensingLicensingEstimateList.nodes?.reduce(
          (acc, cur) => acc + (cur.price + cur.vat) * cur.count,
          0,
        ),
      },
      isLoading,
      total: data?.importLicensingLicensingEstimateList.total || 0,
      refetch,
    },
  };
};
