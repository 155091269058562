import { useEffect, useState } from 'react';

import {
  RegisterB2bOrderDetailInputType,
  useB2bOrderQuery,
} from '@/generated/graphql-react-query';

export interface OrderQuantity extends RegisterB2bOrderDetailInputType {
  totalPrice: number;
}

export const useUpdateB2bOrderQuantitiesHook = (id: string) => {
  const [b2bOrderQuantities, setB2bOrderQuantities] = useState<OrderQuantity[]>([]);
  const { data } = useB2bOrderQuery({ b2BOrderId: id });

  const validateOrderQuantity = (orderQuantity: OrderQuantity) => {
    if (orderQuantity.orderQuantity <= 0) {
      const newOrderQuantity = {
        id: orderQuantity.id,
        orderQuantity: 0,
        totalPrice: 0,
      };
      return newOrderQuantity;
    }
    return orderQuantity;
  };

  const onOrderQuantityChange = (index: number, newOrderQuantity: OrderQuantity) => {
    const validatedOrderQuantity = validateOrderQuantity(newOrderQuantity);

    const newOrderQuantities = [...b2bOrderQuantities];
    newOrderQuantities[index] = validatedOrderQuantity;
    setB2bOrderQuantities(newOrderQuantities);
  };

  useEffect(() => {
    if (!data) return;

    const newOrderQuantities = data.b2bOrder.orderDetails.map((item) => ({
      id: item.id,
      orderQuantity: item.orderQuantity,
      totalPrice: item.totalPrice,
    }));
    setB2bOrderQuantities(newOrderQuantities);
  }, [data]);

  return {
    b2bOrderQuantities,
    setB2bOrderQuantities,
    onOrderQuantityChange,
  };
};
