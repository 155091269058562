import { groupBy, map } from 'lodash-es';

import { StockLogData, StockQuantityByDate } from '@/types/report/type';

/**
 * 입고/출고 테이블 데이터 생성
 * @param stockLogData
 */
export const transformStockLogData = (stockLogData: StockLogData) => {
  if (!stockLogData.length) {
    return { datesForColumn: [], rowData: [] };
  }
  // 데이터가 있는 모든 날짜 - column header가 됨
  const dates = stockLogData.map((stockLog) => stockLog.orderedAt.split('T')[0]);

  const dateSet = new Set(dates);
  const datesForColumn = [...dateSet];

  datesForColumn.sort((a, b) => {
    if (a < b) return 1;
    if (a === b) return 0;
    return -1;
  });

  const groupByProductName = groupBy(stockLogData, 'productName');

  // ex : {productName:xxx, 날짜1:2, 날짜2:1, 날짜3:-1,...}
  const rowData: StockQuantityByDate[] = map(groupByProductName, (logData, key) => {
    const dateQuantity: StockQuantityByDate = logData.reduce((acc, cur) => {
      const formattedDate = cur.orderedAt.split('T')[0];
      if (acc[formattedDate]) {
        acc[formattedDate] += cur.quantity;
      } else {
        acc[formattedDate] = cur.quantity;
      }

      return acc;
    }, {} as { [key: string]: number });

    dateQuantity.productName = key;

    return dateQuantity;
  });

  return { datesForColumn, rowData };
};
