import i18next from 'i18next';

import {
  OrderChannelEnum,
  OrderPaymentStatusEnum,
  OrderShippingStatusEnum,
  SettlementOrderStatus,
} from '@/generated/graphql';

export const ALL = 'ALL';

export const DatePickerFormat = 'YYYY-MM-DD';

export const COUNTRY_SELECT_BOX_OPTION = {
  VIETNAM: {
    NAME: '베트남',
    ID: 'VN',
  },
  THAILAND: {
    NAME: '태국',
    ID: 'TH',
  },
  CHILE: {
    NAME: '칠레',
    ID: 'CL',
  },
};

export const OrderStatusToEnum: { [key: string]: SettlementOrderStatus | undefined } = {
  ALL: undefined,
  OK: SettlementOrderStatus.Ok,
  CANCEL: SettlementOrderStatus.Cancel,
};

export const OrderStatusLabel: {
  [key in SettlementOrderStatus]: string;
} = {
  [SettlementOrderStatus.Ok]: i18next.t('label.report.ok'),
  [SettlementOrderStatus.Cancel]: i18next.t('label.report.cancel'),
};

export const PaymentStatusToEnum: { [key: string]: OrderPaymentStatusEnum } = {
  PAID: OrderPaymentStatusEnum.Paid,
  NOT_PAID: OrderPaymentStatusEnum.NotPaid,
  REFUND: OrderPaymentStatusEnum.Refund,
};

export const PaymentLabel: { [key in OrderPaymentStatusEnum]: string } = {
  [OrderPaymentStatusEnum.Paid]: i18next.t('label.report.paid'),
  [OrderPaymentStatusEnum.NotPaid]: i18next.t('label.report.notPaid'),
  [OrderPaymentStatusEnum.Refund]: i18next.t('label.report.refund'),
};

export const ShippingStatusToEnum: { [key: string]: OrderShippingStatusEnum } = {
  RETURN: OrderShippingStatusEnum.Return,
  DELIVERED: OrderShippingStatusEnum.Delivered,
  READYTOPICK: OrderShippingStatusEnum.Readytopick,
  DELIVERING: OrderShippingStatusEnum.Delivering,
  CANCEL: OrderShippingStatusEnum.Cancel,
  WAITINGFORRETURN: OrderShippingStatusEnum.WaitingForReturn,
};

export const ShippingStatusLabel: { [key in OrderShippingStatusEnum]: string } = {
  [OrderShippingStatusEnum.Return]: i18next.t('label.report.return'),
  [OrderShippingStatusEnum.Delivered]: i18next.t('label.report.delivered'),
  [OrderShippingStatusEnum.Readytopick]: i18next.t('label.report.ready'),
  [OrderShippingStatusEnum.Delivering]: i18next.t('label.report.delivering'),
  [OrderShippingStatusEnum.Cancel]: '',
  [OrderShippingStatusEnum.WaitingForReturn]: '',
};

export const BarTypesByChannel: { [key in OrderChannelEnum]: { color: string } } = {
  [OrderChannelEnum.Gomi]: { color: '#91D5FF' },
  [OrderChannelEnum.Mall]: { color: '#85A5FF' },
  [OrderChannelEnum.Shopee]: { color: '#9254DE' },
  [OrderChannelEnum.ShopeeBrand]: { color: '#FFC069' },
  [OrderChannelEnum.Lazada]: { color: '#FF7A45' },
  [OrderChannelEnum.LazadaBrand]: { color: '#B7EB8F' },
  [OrderChannelEnum.Sendo]: { color: '#36CFC9' },
  [OrderChannelEnum.SendoBrand]: { color: '#17A589' },
  [OrderChannelEnum.Tiki]: { color: '#D35400' },
  [OrderChannelEnum.TikiBrand]: { color: '#73C6B6' },
  [OrderChannelEnum.Tiktok]: { color: '#F4D03F' },
  [OrderChannelEnum.TiktokBrand]: { color: '#239B56' },
  [OrderChannelEnum.Etc]: { color: '#2C3E50' },
};

export const ReportTableColumnWidth = {
  DefaultColumnWidth: 130,
  DateColumnWidth: 150,
  ProductNameColumnWidth: 400,
  CountColumnWidth: 100,
  PriceColumnWidth: 130,
};
