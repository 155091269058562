import React from 'react';

import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { SalesRatioByChannel } from '@/views/home/SalesRatioByChannel';
import { StatusMap } from '@/views/home/StatusMap';
import { Summary } from '@/views/home/Summary';
import { TopSellingBrands } from '@/views/home/TopSellingBrands';
import { TopSellingCategories } from '@/views/home/TopSellingCategories';
import { TopSellingProducts } from '@/views/home/TopSellingProducts';

export const Dashboard = () => {
  const { isPC } = useMediaQueryHook();

  return (
    <>
      <Summary />
      <div
        className={
          isPC
            ? 'gm-grid gm-grid-rows-2 gm-grid-cols-4 gm-gap-4'
            : 'gm-flex gm-flex-col gm-gap-4'
        }
      >
        <div>
          <TopSellingCategories />
        </div>
        <div className='gm-col-span-2'>
          <TopSellingProducts />
        </div>
        <div className='gm-row-span-2'>
          <SalesRatioByChannel />
        </div>
        <div>
          <TopSellingBrands />
        </div>
        <div className='gm-col-span-2'>
          <StatusMap />
        </div>
      </div>
    </>
  );
};
