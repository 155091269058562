import { SettlementInfoLocale } from '@/locale/data/types/settlementInfoLocale.type';

export const enSettlementInfo: SettlementInfoLocale = {
  rootMenu: 'Settlement',
  header: {
    label: {
      countyInfo: 'Vietnam',
      title: 'Monthly Settlement',
    },
    placeholder: {
      monthly: 'Year and Month',
    },
  },
  monthlyDashBoard: {
    totalSalesInfo: 'Total sales per month',
    totalSettlementInfo: 'Total settlement details',
    defaultFeeRateInformation: 'The commission rate by channel is 30%.',
    totalSales: 'Total Sales',
    totalSalesAmount: 'Total Sales Amount (₩)',
    setoffPrice: 'Total Setoff Price (₩)',
    salesFee: 'Total Fee (₩)',
    totalSettlementAmount: 'Total Settlement (₩)',
    exchangeRate: 'Exchange Rate',
    totalSalesInfoTooltip: 'Based on GSPC',
    totalSettlementInfoTooltip:
      'Average Monthly Exchange Rate of Seoul Foreign Exchange Intermediary',
  },
  tab: {
    settlement: 'Sales List',
    setoff: 'Setoff List',
  },
  formatter: {
    feeRate: 'Fee Rate {{feeRate}}%',
  },
  tableHeader: {
    exportList: 'Output',
    productClickNotiTooltip:
      'You can check the order information by clicking the product name.',
  },
  tableColumn: {
    no: 'No.',
    productName: 'Product Name',
    salesPrice: 'Sales Price (₫)',
    externalChannel: 'Channel',
    salesCount: 'Count',
    channelSalesPrice: 'Sales Price (₫)',
    setoffPrice: 'Setoff Price (₫)',
    sum: 'Total',
    sumCountrySalesPrice: 'Total Sales Price (₫)',
    sumSalesPrice: 'Total Sales Price (₩)',
    sumCountrySetoffPrice: 'Total Setoff Price (₫)',
    sumSetoffPrice: 'Total Setoff Price (₩)',
    originalPrice: 'Unit Price (₩)',
    total: 'Total',
  },
  channel: {
    tableColumn: {
      externalChannel: 'Channel',
      totalFee: 'Total Fee (₩)',
      totalSalesPrice: 'Total Sales Price (₩)',
    },
  },
  modal: {
    label: {
      all: 'All',
      channelName: 'Channel',
    },
    tableColumn: {
      orderedAt: 'Date',
      channelName: 'Channel',
      orderNumber: 'Order Number',
      salesPrice: 'Sales Price (₫)',
      salesCount: 'Count',
      originalPrice: 'Unit Price (₩)',
      totalSalesPrice: 'Total Sales Price (₫)',
    },
    footer: {
      info1: 'For more information',
      info2: 'Please go to the and check it out!',
    },
  },
  empty: {
    message: {
      message1: 'There is no settlement details for the month.',
      message2: 'If there is anything wrong, please contact the button below.',
    },
    modal: {
      title: 'Inquiries',
      message: {
        message1: 'Would you like to inquire about the monthly settlement details?',
        message2:
          'Please wait a little while until the person in charge checks after registering.',
      },
      button: {
        inquiries: 'Inquiries',
        cancel: 'Cancel',
      },
    },
    button: {
      inquiries: 'Inquiries',
    },
  },
  message: {
    tableNoDataMessage: 'No Settlement',
    tableNoSetoffDataMessage: 'No Setoff',
    noDataModalTitle: 'Settlement Error',
    noDataModalMsg1: 'Unable to retrieve monthly settlement details.',
  },
  button: {
    confirm: 'Confirm',
    detail: 'Detail',
    complete: 'Complete',
    cancel: 'Cancel',
    download: 'Download',
    sendEmail: 'Send Settlement',
    salesReport: 'Sales Report↗',
  },
};
