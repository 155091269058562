import '../../styles/notion.css';

import React, { FC } from 'react';
import { BlockMapType, NotionRenderer } from 'react-notion';

/** type export 예제 */
export interface IPropsNotion {
  blockMap: BlockMapType;
}

export const Notion: FC<IPropsNotion> = ({ blockMap }) => {
  return (
    <div>
      <NotionRenderer blockMap={blockMap} />
    </div>
  );
};
