import { REFERRER_SESSION_KEY } from '@/constant/auth';

export const sessionStorageController = {
  get: (key: string) => {
    const result = window.sessionStorage.getItem(key);
    return result || null;
  },
  set: (key: string, value: string) => {
    window.sessionStorage.setItem(key, value);
  },
  remove: (key: string) => window.localStorage.removeItem(key),
};

export const referrerSessionStorage = {
  getItem: () => sessionStorageController.get(REFERRER_SESSION_KEY),
  setItem: (path: string): void =>
    sessionStorageController.set(REFERRER_SESSION_KEY, path),
  clear: () => sessionStorageController.remove(REFERRER_SESSION_KEY),
};
