import { ExcelButton, LoggingClick } from '@gomi/component';
import { Form, Input, Modal, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOptionType } from '@/component/types';
import { controlTargetCountryOptionList } from '@/constant/partner';
import { TRACK_EVENT_NAME } from '@/constant/track';
import { useBrandListHook } from '@/hooks/brand/useBrandListHook';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { useStore } from '@/stores';

export const FilterControlBar = observer(() => {
  const {
    brandListModel: { filterControl, onChangeFilterControl },
  } = useStore();
  const { t } = useTranslation(['partnerInfo', 'brandInfo', 'common']);
  const { isMobile } = useMediaQueryHook();

  /** 브랜드 정보 리스트 */
  const { totalCount, initPageNo } = useBrandListHook();

  /** 브랜드 엑셀 추출 */
  const { exportBrandListMutation } = useBrandListHook();

  const handleCountryChange = (option: SelectOptionType) => {
    onChangeFilterControl('countryId', option.value);
    initPageNo();
  };

  const handleSearch = (value: string) => {
    onChangeFilterControl('searchKeyword', value);
    initPageNo();
  };

  const handleExportExcelButtonClick = async () => {
    if (!totalCount) {
      Modal.error({
        title: t('partnerInfo:modal.message.exportFail'),
        content: (
          <div>
            {t('partnerInfo:modal.message.exportFailContent1')}
            <br />
            {t('partnerInfo:modal.message.exportFailContent2')}
          </div>
        ),
        okText: t('partnerInfo:modal.button.confirm'),
      });
    }

    const result = await exportBrandListMutation();
    window.open(result.exportPartnerBrandList);
  };

  return (
    <div className='gm-relative gm-flex gm-flex-col md:gm-flex-row gm-justify-between md:gm-items-center md:gm-flex-nowrap md:gm-overflow-x-auto'>
      <div className='gm-min-w-[6rem]'>
        <span className='gm-mr-2'>{t('partnerInfo:control.title')}</span>
        <span>{t('partnerInfo:control.count', { count: totalCount })}</span>
      </div>
      <Form layout={isMobile ? 'horizontal' : 'inline'} className='md:gm-flex-nowrap'>
        {/* 진출 국가 */}
        <Form.Item
          label={t('partnerInfo:control.label.country')}
          className='gm-font-normal gm-my-4 md:gm-my-0'
        >
          <Select
            defaultValue={filterControl.countryId}
            showSearch
            onChange={(selectedValue: string) => {
              const selected = controlTargetCountryOptionList.find(
                (option) => option.value === selectedValue,
              );
              if (selected) {
                handleCountryChange(selected as SelectOptionType);
              }
            }}
            optionFilterProp='label'
            filterOption={(inputValue, option) =>
              (option?.children as ReactNode as string)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
          >
            {controlTargetCountryOptionList.map((option) => {
              return (
                <Select.Option key={option.value} value={option.value}>
                  {option.title}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        {/* 검색 바 */}
        <Form.Item className='gm-mb-0'>
          <Input.Search
            className='md:gm-w-72'
            defaultValue={filterControl.searchKeyword}
            placeholder={t('brandInfo:control.searchPlaceHolder')}
            onSearch={handleSearch}
            allowClear
          />
        </Form.Item>

        <LoggingClick eventName={TRACK_EVENT_NAME.BRAND_DOWNLOAD_CLICK}>
          <ExcelButton
            type='button'
            onClick={handleExportExcelButtonClick}
            className='gm-absolute gm-top-0 gm-right-0 md:gm-static gm-w-fit'
          >
            {t('common:common.button.download')}
          </ExcelButton>
        </LoggingClick>
      </Form>
    </div>
  );
});
