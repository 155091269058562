import { Empty } from '@gomi/component';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const HomeEmpty = () => {
  const { t } = useTranslation(['home']);
  return (
    <div className='gm-mt-5'>
      <Empty size='small' onShowImage>
        {t('home:empty.content')}
      </Empty>
    </div>
  );
};
