import { ALL } from '@/constant';
import {
  CountryEnum,
  StockLocationEnum,
  StockTypeEnum,
  useStockLogsQuery,
  useStockQuery,
} from '@/generated/graphql-react-query';
import { transformStockLogData } from '@/lib/report/transformData';
import { IReportControl } from '@/types/report/type';

export const useStockHook = ({
  startDate,
  endDate,
  brandId,
  countryId,
  location,
}: Pick<IReportControl, 'startDate' | 'endDate' | 'brandId' | 'countryId'> & {
  location: typeof ALL | StockLocationEnum;
}) => {
  // 재고
  const { data: stocks, isLoading: loadingStocks } = useStockQuery({
    input: {
      filter: {
        countryId: countryId as CountryEnum,
        brandIds: brandId === ALL ? [] : [brandId],
        locations: location === ALL ? undefined : [location],
      },
    },
  });

  // 입고
  const { data: stockIns, isLoading: loadingStockIns } = useStockLogsQuery({
    input: {
      filter: {
        brandIds: brandId === ALL ? [] : [brandId],
        countryId: countryId as CountryEnum,
        endDateAt: endDate,
        startDateAt: startDate,
        type: StockTypeEnum.StockIn,
        locations: location === ALL ? undefined : [location],
      },
    },
  });

  // 출고
  const { data: stockOuts, isLoading: loadingStockOuts } = useStockLogsQuery({
    input: {
      filter: {
        brandIds: brandId === ALL ? [] : [brandId],
        countryId: countryId as CountryEnum,
        endDateAt: endDate,
        startDateAt: startDate,
        type: StockTypeEnum.StockOut,
        locations: location === ALL ? undefined : [location],
      },
    },
  });

  return {
    stockData: stocks?.stocks.nodes ?? [],
    stockInData: transformStockLogData(stockIns?.stockLogs ?? []),
    stockOutData: transformStockLogData(stockOuts?.stockLogs ?? []),
    loadingStocks,
    loadingStockIns,
    loadingStockOuts,
  };
};
