import { Spin } from 'antd';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useState } from 'react';
import { Document, pdfjs } from 'react-pdf';

import { LoadError } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/common/LoadError';
import { Navigator } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/pdfViewer/Navigator';
import { PdfArea } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/pdfViewer/PdfArea';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfViewerProps {
  url: string;
}

export const PdfViewer = ({ url }: PdfViewerProps) => {
  const [totalPage, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handleDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy) => {
    setNumPages(nextNumPages);
  };

  const handleReloadButtonClick = () => {
    // TODO: PDF refetch
  };

  const updateCurrentPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Document
      file={url}
      className='gm-relative gm-flex gm-flex-col lg:gm-flex-row gm-h-full'
      loading={
        <Spin size='large' className='gm-absolute gm-top-1/2 gm-left-1/2 gm-z-10' />
      }
      onLoadSuccess={handleDocumentLoadSuccess}
      error={<LoadError onClick={handleReloadButtonClick} />}
    >
      <Navigator
        totalPage={totalPage}
        currentPage={currentPage}
        updateCurrentPage={updateCurrentPage}
      />
      <PdfArea totalPage={totalPage} currentPage={currentPage} />
    </Document>
  );
};
