import moment from 'moment';

import { DateFormat } from '@/types/dateFormat';

const getCurrent = () => {
  return moment(new Date());
};

const getLastMonth = (date: string) => {
  return moment(date).subtract(1, 'months');
};

const getStartDayFromLastMonth = (date: string) => {
  return getLastMonth(date).startOf('month');
};

const getEndDayFromLastMonth = (date: string) => {
  return getLastMonth(date).endOf('month');
};

export const momentDateFormat = {
  convertDateKrYYYYMM: (date: string) => moment(date).format('YYYY년 MM월'),
  getMoment: (date: string) => moment(date),
  convertDateYYYYMMDD: (date: string) => moment(date).format(DateFormat['YYYY-MM-DD']),
  convertDateYYMMDD: (date: string) => moment(date).format(DateFormat['YY/MM/DD']),
  convertDateYYYYMMDDHHmm: (date: string) =>
    moment(date).format(DateFormat['YYYY-MM-DD HH:mm']),
  convertDateYYYYMMDDHHmmss: (date: string) =>
    moment(date).format(DateFormat['YYYY-MM-DD HH:mm:ss']),
  getStartDayFromLastMonth,
  getEndDayFromLastMonth,
  getStartDayFromCurrentMonth: getCurrent()
    .startOf('month')
    .format(DateFormat['YYYY-MM-DD']),
  getToday: getCurrent().format(DateFormat['YYYY-MM-DD']),
  getFormattedStartDayFromLastMonth: (date: string) =>
    getStartDayFromLastMonth(date).format(DateFormat['YYYY-MM-DD']),
  getFormattedEndDayFromLastMonth: (date: string) =>
    getEndDayFromLastMonth(date).format(DateFormat['YYYY-MM-DD']),
  getCurrentMonth: () => moment(new Date()).format(DateFormat.YYYYMM),

  getLastMonth: () => moment(new Date()).subtract(1, 'months').format(DateFormat.YYYYMM),
  get30DaysAgoFromToday: () =>
    moment().subtract(30, 'days').format(DateFormat['YYYY-MM-DD']),
  get1WeekAgoFromToday: () =>
    moment().subtract(6, 'days').format(DateFormat['YYYY-MM-DD']),
  getEndDayOfLastMonth: () =>
    moment(new Date())
      .subtract('1', 'month')
      .endOf('month')
      .format(DateFormat['YYYY-MM-DD']),
  getFirstDayOfLastMonth: () =>
    moment(new Date()).subtract(1, 'months').date(1).format(DateFormat['YYYY-MM-DD']),
  getFirstDayOfThisMonth: () =>
    moment(new Date()).date(1).format(DateFormat['YYYY-MM-DD']),
};
