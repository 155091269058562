import { Report } from '@/locale/data/types/report.type';

export const enReport: Report = {
  rootMenu: 'Report',
  label: {
    noSelect: 'Select a partner',
    brand: 'Brand name',
    item: 'Product name',
    channel: 'Channel name',
    period: 'Search period',
    orderStatus: 'Order status',
    payStatus: 'Payment status',
    deliveryStatus: 'Delivery status',
    gift: 'Gift',
    excludeGift: 'Gift excluded',
    onlyGift: 'See gift only',
    case: 'orders',
    total: 'Total',
    all: 'All',
    ok: 'Confirm',
    cancel: 'Cancel',
    waitingForReturn: 'Waiting for cancellation',
    paid: 'Paid',
    notPaid: 'Not paid',
    refund: 'Refund',
    ready: 'Preparing\n for delivery',
    delivering: 'In delivery',
    delivered: 'Delivered',
    return: 'Return',
    gomi: 'GOMI',
    gomiKr: 'GOMI_KR',
    mall: 'MALL',
    lazada: 'Lazada',
    sendo: 'Sendo',
    shopee: 'Shopee',
    tiki: 'Tiki',
    app: 'APP',
    tiktok: 'Tiktok',
  },
  title: {
    detailOrderItem: 'Order details',
    salesStatusByDate: 'Sales activity per date',
    salesStatusByProduct: 'Sales activity per product',
    stock: 'Inventory',
    stockIn: 'Inbound logistics',
    stockOut: 'Outbound logistics',
  },
  tab: {
    salesStatus: 'Sales activity',
    deliveryStatus: 'Logistics activity',
  },
  error: {
    failedLoadReport: 'Failed to load a report',
    noReport: 'No countries currently selling products',
    noProductsForSaleInCountry: 'No products being sold for the country',
  },
  chart: {
    salesStatus: 'Sales activity (VND)',
    salesAmountByProduct: 'Sales price per product (VND)',
    legend: {
      ok: 'Order confirmation',
      cancel: 'Order cancellation',
      totalAmount: 'Total amount',
    },
  },
  column: {
    date: 'Date',
    channel: 'Channel',
    orderNumber: 'Order number',
    payMethod: 'Payment method',
    orderDate: 'Order date',
    orderStatus: 'Order status',
    paymentStatus: 'Payment status',
    shippingStatus: 'Delivery status',
    productName: 'Product name',
    voucher: 'Voucher',
    price: 'Sales price',
    companyName: 'Company',
    brandName: 'Brand',
    gspc: 'GSPC',
    priceOriginal: 'Retail price',
    settlementMonth: 'Settlement month',
    count: 'Availability',
    location: 'Warehouse',
    han: 'Hanoi',
    hcm: 'Ho Chi Minh',
    totalPrice: 'Total sales amount',
    sum: 'SUM',
    ok: 'Confirmed',
    cancel: 'Cancel',
  },
  salesPage: {
    title: 'Sales report',
    detailFilter: 'Detailed filter',
  },
  stockPage: {
    title: 'Logistics report',
    label: {
      download: 'Download',
    },
    table: {
      information: {
        apt: 'Quantity available for sales (Available to promise)',
        usable: 'APT + Buffer (Capable to promise)',
        buffer: 'Buffer stock (Safe stock)',
        inProgress: 'Quantity currently in packing (Waiting for outbound logistics)',
        inPicking: 'Quantity currently in picking (Waiting for outbound logistics)',
        unusable: 'Out of date & damaged (Obsolete stock)',
        problem: 'Reserved quantity due to problems with orders',
        incoming: 'Shipping & Waiting for processing inbound logistics',
      },
    },
  },
};
