import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Modal } from 'antd';
import createUploadLink from 'apollo-upload-client/public/createUploadLink';
import i18next from 'i18next';
import { some } from 'lodash-es';

import { globalEnv } from '@/config/env';
import { authTokenStorage } from '@/lib/authToken';

export const gqlClientSetting = () => {
  const gqlErrorHandler = onError(({ graphQLErrors, forward, operation }) => {
    const isInternalServerError =
      graphQLErrors &&
      some(
        graphQLErrors,
        ({ extensions }) => extensions.code === 'INTERNAL_SERVER_ERROR',
      );

    if (isInternalServerError) {
      Modal.error({
        content: i18next.t('notification.internalServerError'),
      });

      return forward(operation).map((result) => {
        result.errors = undefined;
        return result;
      });
    }
    return forward(operation);
  });

  const authLink = setContext((_, { headers }) => {
    const token = authTokenStorage.getToken();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        'keep-alive': 'true',
      },
    };
  });

  const httpLink = createUploadLink({
    uri: globalEnv.GRAPHQL_URL,
  });

  return new ApolloClient({
    name: 'partner-center-front',
    cache: new InMemoryCache(),
    link: from([authLink, gqlErrorHandler, httpLink as any as ApolloLink]),
    connectToDevTools: true,
    queryDeduplication: false,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
      watchQuery: {
        fetchPolicy: 'network-only',
      },
    },
  });
};
