import {
  CompanyRequestStatusEnum,
  ProductRequestTypeEnum,
} from '@/generated/graphql-react-query';

export interface IProductInfoForm {
  productKrName?: string | null;
  productName?: string | null;
  retailPriceVn?: number | null;
  retailPriceTh?: number | null;
  regularSalePriceTh?: number | null;
  regularSalePriceVn?: number | null;
  retailPriceKr?: number | null;
  supplierProductCode?: string | null;
  discountSalePriceTh?: string | null;
  discountSalePriceThA?: number | null;
  discountSalePriceThB?: number | null;
  discountSalePriceThS?: number | null;
  discountSalePriceVn?: string | null;
  discountSalePriceVnA?: number | null;
  discountSalePriceVnB?: number | null;
  discountSalePriceVnS?: number | null;
  status?: CompanyRequestStatusEnum;
  productGroupName?: string;
  productGroupKrName?: string;
  rejectReason?: string;
  brandName?: string;
  productId?: string | null;
  importLicenseNameTh?: string | null;
  importLicenseNameVn?: string | null;
  productGroupId: string;
  type: ProductRequestTypeEnum;
  partnerName?: string;
  gspc?: string;
  hsCode?: string | null;
  barcode?: string | null;
  productOptionRels?: {
    productOptionId: string;
  }[];
}

export enum ProductFromNameEnum {
  LOGISTICS_FORM = 'LOGISTICS_FORM',
  PRICE_FORM = 'PRICE_FORM',
  DETAIL_FORM = 'DETAIL_FORM',
  GROUP_FORM = 'GROUP_FORM',
  GROUP_EDIT_FORM = 'GROUP_EDIT_FORM',
  TABLE_FORM = 'TABLE_FORM',
}
