import { Button, Upload } from '@gomi/component';
import { UploadFile } from 'antd';
import { Modal } from 'antd/es';
import { RcFile } from 'antd/es/upload';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useUploadB2bOrderDetailsHook } from '@/hooks/order/common/useUploadB2bOrderDetailsHook';
import { useGetB2bOrderFilesHook } from '@/hooks/order/detail/useGetB2bOrderFilesHook';

export const UploadModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { orderId } = useParams<{ orderId: string }>();

  const { refetchB2bOrderFiles } = useGetB2bOrderFilesHook(orderId);
  const { upload } = useUploadB2bOrderDetailsHook();

  const handleReset = () => {
    setOpen(false);
  };

  const handleChange = async ({ fileList: files }: { fileList: UploadFile[] }) => {
    await upload.mutate(
      orderId,
      new File([files[0].originFileObj as RcFile], files[0].name),
    );
    await refetchB2bOrderFiles();
    handleReset();
  };

  const loading = false;
  return (
    <>
      <Button onClick={() => setOpen(true)}>엑셀 업로드</Button>
      <Modal
        maskClosable
        title='주문 상세 업로드'
        open={open}
        onCancel={handleReset}
        width={500}
        afterClose={handleReset}
        destroyOnClose
        closable={!loading}
        footer={null}
      >
        <div className='gm-mb-2'>
          다운로드한 엑셀 파일을 수정하여 업로드할 수 있습니다.
        </div>
        <Upload
          type='EXCEL'
          maxFiles={1}
          fileList={[]}
          isLoading={upload.isLoading}
          onChange={handleChange}
          multiple
        />
      </Modal>
    </>
  );
};
