import { ColDef, ColGroupDef, ICellRendererParams } from '@ag-grid-community/core';
import { Button, LoggingClick } from '@gomi/component';
import { TFunction } from 'react-i18next';

import { TRACK_EVENT_NAME } from '@/constant/track';
import { CompanyRequestStatusEnum, CountryEnum } from '@/generated/graphql-react-query';
import { CountryFlag } from '@/views/common/CountryFlag';

export type BrandRequestStatus =
  | CompanyRequestStatusEnum.Done
  | CompanyRequestStatusEnum.Rejection
  | CompanyRequestStatusEnum.Request
  | CompanyRequestStatusEnum.Temporary;

interface IPropsListColumnProps {
  t: TFunction;
  onClick(id: string): void;
  requestStatus?: BrandRequestStatus;
  isPC: boolean;
}

export const getListColumn = ({
  t,
  onClick,
  requestStatus,
  isPC,
}: IPropsListColumnProps): ColDef[] | ColGroupDef[] => {
  const headerText = {
    [CompanyRequestStatusEnum.Done]: t('partnerInfo:table.dateDone'),
    [CompanyRequestStatusEnum.Request]: t('partnerInfo:table.dateRequest'),
    [CompanyRequestStatusEnum.Rejection]: t('partnerInfo:table.dateReject'),
    [CompanyRequestStatusEnum.Temporary]: t('partnerInfo:table.dateTemp'),
  };

  return [
    {
      headerName: t('partnerInfo:table.no'),
      field: 'no',
      cellStyle: { textAlign: 'center' },
      width: 64,
    },
    // 브랜드 코드
    {
      headerName: t('brandInfo:table.brandCode'),
      field: 'brandCode',
      width: 85,
    },
    // 브랜드 명
    {
      headerName: t('brandInfo:table.brandName'),
      field: 'brandName',
      // tooltipField: 'brandName',
      minWidth: isPC ? 372 : 280,
      flex: 1,
      cellRenderer: ({ data, valueFormatted, value }: ICellRendererParams) => (
        <LoggingClick eventName={TRACK_EVENT_NAME.BRAND_DETAIL_CLICK}>
          <Button
            className='gm-p-1 hover:gm-underline'
            btnType='text'
            onClick={() => onClick(data.id)}
          >
            {valueFormatted || value}
          </Button>
        </LoggingClick>
      ),
    },
    // 진출 국가
    {
      headerName: t('partnerInfo:table.sellingCountry'),
      field: 'countryRels',
      width: isPC ? 280 : 150,
      tooltipField: 'countryRels',
      cellRenderer: ({ data }: { data: { countryRels: CountryEnum[] } }) =>
        data.countryRels.map((country: CountryEnum, index) =>
          CountryFlag(country, index, data.countryRels.length - 1),
        ),
    },
    // 업데이트 날짜
    {
      headerName: requestStatus
        ? headerText[requestStatus]
        : t('partnerInfo:table.dateUpdate'),
      field: 'updatedAt',
      width: 110,
    },
  ];
};
