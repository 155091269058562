import { usePagingHook } from '@gomi/hooks';
import { message } from 'antd';
import { useMemo } from 'react';

import { ALL } from '@/constant';
import {
  CountryEnum,
  FindCompaniesFilter,
  useBrandListQuery,
  useExportPartnerBrandListMutation,
} from '@/generated/graphql-react-query';
import { momentDateFormat } from '@/lib';
import { useStore } from '@/stores';
import { TFilterControl } from '@/stores/brand/list/store';

export const getBrandListFilter = (filterControl: TFilterControl) => ({
  ...(filterControl.countryId !== ALL && {
    countryId: filterControl.countryId,
  }),
});

export const useBrandListHook = () => {
  const {
    brandListModel: { filterControl },
  } = useStore();
  const { paging, initPageNo, onChangePage } = usePagingHook();

  const {
    data: brandList,
    refetch: refetchBrandList,
    ...rest
  } = useBrandListQuery(
    {
      input: {
        pagination: {
          limit: paging.size,
          offset: (paging.no - 1) * paging.size,
        },
        keyword: filterControl.searchKeyword,
        filter: getBrandListFilter(filterControl) as FindCompaniesFilter,
      },
    },
    { onError: (error: Error) => message.error(error.message) },
  );

  const { mutateAsync: exportBrandListMutation } = useExportPartnerBrandListMutation({
    onError: (error: Error) => message.error(error.message),
  });

  const totalCount = brandList?.partnerBrandsV2.total || 0;

  const getBrandListForTable = () =>
    useMemo(
      () =>
        brandList?.partnerBrandsV2.nodes.map((brand, idx) => {
          return {
            no: (paging.no - 1) * paging.size + idx + 1,
            id: brand.id,
            brandCode: brand.brandCode,
            brandName: `${brand.brandKrName} / ${brand.brandName}`,
            updatedAt: momentDateFormat.convertDateYYYYMMDD(brand.updatedAt),
            countryRels: brand.countryIds?.reduce(
              (acc: CountryEnum[], countryId) =>
                acc.includes(countryId) ? [...acc] : [...acc, countryId],
              [],
            ),
          };
        }),
      [brandList],
    ) || [];

  return {
    refetchBrandList,
    getBrandListForTable,
    totalCount,
    paging,
    initPageNo,
    onChangePage,
    exportBrandListMutation: () =>
      exportBrandListMutation({
        input: {
          pagination: {
            limit: paging.size,
            offset: (paging.no - 1) * paging.size,
          },
          keyword: filterControl.searchKeyword,
          filter: getBrandListFilter(filterControl) as FindCompaniesFilter,
        },
      }),
    ...rest,
  };
};
