import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { StockLocationEnum } from 'partner-center-admin/src/generated/graphql-react-query';

import { ALL } from '@/constant';
import { GiftEnum } from '@/generated/graphql';
import {
  CountryEnum,
  OrderChannelEnum,
  OrderPaymentStatusEnum,
  OrderShippingStatusEnum,
  SettlementOrderStatus,
} from '@/generated/graphql-react-query';
import { momentDateFormat } from '@/lib';

const IDENTIFIER = 'reportControlModel';

export const model = types
  .model(IDENTIFIER, {
    identifier: IDENTIFIER,
    isQuicksight: types.boolean,
    brandId: types.string,
    startDate: types.string,
    endDate: types.string,
    isEmptyReport: types.boolean,
    countryId: types.enumeration('CountryEnum', [
      CountryEnum.Cl,
      CountryEnum.Jp,
      CountryEnum.Kr,
      CountryEnum.Sg,
      CountryEnum.Th,
      CountryEnum.Vn,
    ]),
    salesCode: types.string,
    orderStatus: types.enumeration<typeof ALL | SettlementOrderStatus>(
      'SettlementOrderStatusEnum',
      [ALL, SettlementOrderStatus.Ok, SettlementOrderStatus.Cancel],
    ),
    paymentStatus: types.enumeration<typeof ALL | OrderPaymentStatusEnum>(
      'OrderPaymentStatusEnum',
      [
        ALL,
        OrderPaymentStatusEnum.Paid,
        OrderPaymentStatusEnum.Refund,
        OrderPaymentStatusEnum.NotPaid,
      ],
    ),
    shippingStatus: types.enumeration<typeof ALL | OrderShippingStatusEnum>(
      'OrderShippingStatusEnum',
      [
        ALL,
        OrderShippingStatusEnum.Readytopick,
        OrderShippingStatusEnum.Delivering,
        OrderShippingStatusEnum.Delivered,
        OrderShippingStatusEnum.Return,
      ],
    ),
    channel: types.enumeration<typeof ALL | OrderChannelEnum>('OrderChannelEnum', [
      ALL,
      OrderChannelEnum.Gomi,
      OrderChannelEnum.Mall,
      OrderChannelEnum.Lazada,
      OrderChannelEnum.LazadaBrand,
      OrderChannelEnum.Sendo,
      OrderChannelEnum.SendoBrand,
      OrderChannelEnum.Shopee,
      OrderChannelEnum.ShopeeBrand,
      OrderChannelEnum.Tiki,
      OrderChannelEnum.TikiBrand,
      OrderChannelEnum.Tiktok,
      OrderChannelEnum.TiktokBrand,
      OrderChannelEnum.Etc,
    ]),
    gift: types.enumeration<typeof ALL | GiftEnum>('GiftEnum', [
      ALL,
      GiftEnum.OnlyGift,
      GiftEnum.ExcludeGift,
    ]),
    location: types.enumeration<typeof ALL | StockLocationEnum>('StockLocationEnum', [
      ALL,
      StockLocationEnum.Han,
      StockLocationEnum.Sgn,
    ]),
  })
  .actions((self) => ({
    toggleQuicksight() {
      self.isQuicksight = !self.isQuicksight;
    },
    setCountryId(countryId: CountryEnum) {
      self.countryId = countryId;
    },
    setBrandId(brandId: string) {
      self.brandId = brandId;
    },
    setSalesCode(salesCode: string) {
      self.salesCode = salesCode;
    },
    setDate(startDate: string, endDate: string) {
      self.startDate = startDate;
      self.endDate = endDate;
    },
    setOrderStatus(orderStatus: typeof ALL | SettlementOrderStatus) {
      self.orderStatus = orderStatus;
    },
    setPaymentStatus(paymentStatus: typeof ALL | OrderPaymentStatusEnum) {
      self.paymentStatus = paymentStatus;
    },
    setShippingStatus(shippingStatus: typeof ALL | OrderShippingStatusEnum) {
      self.shippingStatus = shippingStatus;
    },
    setChannel(channel: typeof ALL | OrderChannelEnum) {
      self.channel = channel;
    },
    setGift(gift: typeof ALL | GiftEnum) {
      self.gift = gift;
    },
    setLocation(location: typeof ALL | StockLocationEnum) {
      self.location = location;
    },
  }));

/** 초기화 값 */
const defaultValue: SnapshotIn<typeof model> = {
  isQuicksight: false,
  countryId: CountryEnum.Vn,
  isEmptyReport: false,
  brandId: ALL,
  salesCode: ALL,
  startDate: momentDateFormat.get1WeekAgoFromToday(),
  endDate: momentDateFormat.getToday,
  orderStatus: ALL,
  paymentStatus: ALL,
  shippingStatus: ALL,
  channel: ALL,
  gift: ALL,
  location: ALL,
};

/** create or initialize */
const create = model.create(defaultValue);

const reportControlStore = {
  create,
  defaultValue,
  model,
};

/** 모델 타입 */
export type IReportControlModelType = Instance<typeof model>;

export default reportControlStore;
