import { ExcelButton } from '@gomi/component';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useExportExcelImportLicensingEstimateMutation } from '@/generated/graphql-react-query';
import { useEstimateHook } from '@/hooks/importLicensing/details/useEstimateHook';
import { downloadFileUrl } from '@/lib/downloadFile';
import { IParams } from '@/types/importLicensing/details';

export const EstimateListControlBar = () => {
  const { t } = useTranslation(['importLicensingInfo', 'common']);
  const { importLicensingId } = useParams<IParams>();
  const {
    list: { total },
  } = useEstimateHook(importLicensingId);
  const { mutate: exportBrandListMutation } =
    useExportExcelImportLicensingEstimateMutation({
      onSuccess: (data) => {
        downloadFileUrl(data.exportExcelImportLicensingEstimate);
      },
    });

  const handleExportExcelButtonClick = () => {
    if (!total) {
      Modal.error({
        title: t(
          'importLicensingInfo:detailPage.steps.fifth.estimateList.modal.confirm.exportExcel.exportFail',
        ),
        content: (
          <div>
            {t(
              'importLicensingInfo:detailPage.steps.fifth.estimateList.modal.confirm.exportExcel.exportFailContent1',
            )}
            <br />
            {t(
              'importLicensingInfo:detailPage.steps.fifth.estimateList.modal.confirm.exportExcel.exportFailContent2',
            )}
          </div>
        ),
        okText: t(
          'importLicensingInfo:detailPage.steps.fifth.estimateList.modal.confirm.exportExcel.ok',
        ),
      });

      return;
    }

    exportBrandListMutation({ importLicenseId: importLicensingId });
  };

  return (
    <div className='gm-flex gm-justify-between gm-items-center gm-flex-nowrap gm-overflow-x-auto'>
      <div className='gm-min-w-24'>
        <span className='gm-mr-2'>
          {t('importLicensingInfo:detailPage.steps.fifth.estimateList.control.title')}
        </span>
      </div>
      <ExcelButton type='button' onClick={handleExportExcelButtonClick}>
        {t('common:common.button.download')}
      </ExcelButton>
    </div>
  );
};
