import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { FC, ReactNode } from 'react';
import { styled } from 'twin.macro';

import GomiExcelIcon from '../assets/icons/gomiExcelIcon.svg?component';
import { Button } from './Button';

/** style */
const Wrapper = styled.div``;

const colorVariation = {
  primary: 'blue',
  default: 'gray',
  success: 'green',
  warning: 'yellow',
  danger: 'red',
};

const antIcon = <LoadingOutlined className='gm-text-2xl' spin />;

export interface IPropsExcelButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  btnType?: 'outlined' | 'filled' | 'ghost' | 'text';
  color?: keyof typeof colorVariation;
  size?: 'sm' | 'md' | 'lg';
  children?: ReactNode;
  isDisabled?: boolean;
  icon?: ReactNode;
  loading?: boolean;
  className?: string;
}

/** component:ExcelButton 컴포넌트 */
export const ExcelButton: FC<IPropsExcelButton> = ({
  btnType = 'outlined',
  color = 'default',
  size = 'md',
  children,
  isDisabled,
  icon,
  loading = false,
  className,
  ...props
}) => {
  return (
    <Wrapper className={className}>
      <Spin spinning={loading} indicator={antIcon}>
        <Button
          type='button'
          onClick={props.onClick}
          btnType={btnType}
          size={size}
          loading={loading}
          color={color}
          isDisabled={isDisabled}
          className='gm-flex gm-justify-start gm-items-center'
        >
          {/* 엑셀 버튼 사용시 icon 안넣어도 자동으로 들어감 */}
          {icon ?? <GomiExcelIcon className='gm-mr-1' width={18} height={18} />}
          {children}
        </Button>
      </Spin>
    </Wrapper>
  );
};
