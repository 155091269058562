import { B2bOrderStatusEnum } from '@/generated/graphql-react-query';
import { OrderLocaleType } from '@/locale/data/types/orderLocale.type';

export const enOrderInfo: OrderLocaleType = {
  rootMenu: '주문',
  connectionPage: {
    title: '파트너 연결',
    configureModal: {
      title: '거래 구성',
      form: {
        submit: '추가',
        product: {
          label: '상품',
          requiredMessage: '상품을 선택해주세요.',
          placeholder: '상품을 선택해주세요.',
        },
        retailPrice: {
          label: '소비자가',
          requiredMessage: '소비자가를 입력해주세요.',
          placeholder: '소비자가를 입력해주세요.',
        },
        purchaseFeeRate: {
          label: '매입단가',
          requiredMessage: '매입단가를 입력해주세요.',
          placeholder: '매입단가를 입력해주세요.',
        },
        salesFeeRate: {
          label: '공급가',
          requiredMessage: '공급가를 입력해주세요.',
          placeholder: '공급가를 입력해주세요.',
        },
      },
    },
    connectionModal: {
      title: '파트너 연결',
      form: {
        submit: '연결',
        seller: {
          label: '판매자',
          requiredMessage: '판매자를 선택해주세요.',
          placeholder: '판매자를 선택해주세요.',
        },
        sellerManager: {
          label: '판매자 담당자',
          requiredMessage: '판매자 담당자를 선택해주세요.',
          placeholder: '판매자 담당자를 선택해주세요.',
        },
        buyer: {
          label: '구매자',
          requiredMessage: '구매자를 선택해주세요.',
          placeholder: '구매자를 선택해주세요.',
        },
        buyerManager: {
          label: '구매자 담당자',
          requiredMessage: '구매자 담당자를 선택해주세요.',
          placeholder: '구매자 담당자를 선택해주세요.',
        },
        buyerAddress: {
          label: '입고지 주소',
          requiredMessage: '입고지 주소를 선택해주세요.',
          placeholder: '입고지 주소를 선택해주세요.',
        },
        manager: {
          label: '고미 담당자',
          requiredMessage: '고미 담당자를 선택해주세요.',
          placeholder: '고미 담당자를 선택해주세요.',
        },
      },
    },
    control: {
      title: '목록',
      count: '{{count}} 건',
    },
    message: {
      empty: '주문 생성을 위한 파트너를 연결해주세요.',
      connectConfirm: '연결하시겠습니까?',
      connectSuccess: '연결되었습니다.',
      connectError: '연결에 실패했습니다.',
      disConnectConfirm: '연결 해지하시겠습니까?',
      disConnectSuccess: '연결 해지되었습니다.',
      disConnectError: '연결 해지에 실패했습니다.',
      createConfigSuccess: '추가되었습니다.',
      createConfigError: '추가에 실패하였습니다.',
      deleteConfigConfirm: '삭제하시겠습니까?',
      deleteConfigSuccess: '삭제되었습니다.',
      deleteConfigError: '삭제에 실패하였습니다.',
    },
    label: {
      no: 'No.',
      sellerName: '판매자',
      buyerName: '구매자',
      managerName: '담당자',
      createdAt: '등록 날짜',
      disable: '연결 해지',
      connect: '재연결',
      configure: '거래구성',
      delete: '삭제',
      cancel: '취소',
    },
  },
  checkPage: {
    title: '주문 확인',
    control: {
      title: '목록',
      count: '{{count}} 건',
    },
    message: {
      empty: '등록된 상품이 없습니다.',
      confirmOrderConfirm: '주문을 확인하셨습니까?',
      confirmOrderSuccess: '주문 확인 처리되었습니다.',
      confirmOrderError: '주문 확인 처리에 실패했습니다.',
      confirmOrderValidate: '재고 수량을 확인해주세요.',
    },
    button: {
      cancel: '취소',
      confirm: '확인',
    },
  },
  detailPage: {
    title: '주문 상세',
    control: {
      title: '목록',
      count: '{{count}} 건',
    },
    arrivalModal: {
      form: {
        estimatedArrivalDate: {
          label: '도착 예정일',
          placeholder: '도착 예정일을 선택해주세요.',
          button: {
            ok: '설정',
            cancel: '취소',
          },
          message: {
            require: '도착 예정일을 선택해주세요.',
            confirm: '해당 날짜로 설정하시겠습니까?',
            success:
              '도착 예정일이 설정되었습니다. 우측 상단 버튼을 통해 발주소를 다운로드하실 수 있습니다.',
            error: '도착 예정일 설정에 실패했습니다.',
          },
        },
      },
    },
    uploadModal: {
      form: {
        button: {
          upload: '업로드',
          cancel: '취소',
        },
        message: {
          require: '파일을 업로드해주세요.',
          success: '업로드 되었습니다.',
          error: '업로드에 실패했습니다.',
        },
      },
    },
    doneModal: {
      button: {
        done: '완료',
        cancel: '취소',
      },
      message: {
        doneConfirm: '완료하시겠습니까?',
        doneSuccess: '완료 처리 되었습니다.',
        doneError: '완료 처리에 실패했습니다.',
      },
    },
    actions: {
      setEstimatedArrivalDate: '도착 예정일 설정',
      updateEstimatedArrivalDate: '도착 예정일 변경',
      sellerDepositConfirmation: '송금확인서 다운로드',
      buyerDepositConfirmation: '입금확인서 다운로드',
      confirmationOfTransaction: '거래확정서 다운로드',
      orderForm: '발주서 다운로드',
      done: '완료',
    },
    message: {
      fileDownloadLinkError: '파일 생성 중입니다. 잠시 후에 다시 시도해주세요.',
    },
  },
  createPage: {
    title: '주문 생성',
    control: {
      title: '목록',
      count: '{{count}} 건',
    },
    message: {
      empty: '등록된 상품이 없습니다.',
      registerOrderConfirm: '주문을 생성하시겠습니까?',
      registerOrderSuccess: '주문을 생성하였습니다.',
      registerOrderError: '주문 생성에 실패하였습니다.',
      registerOrderValidate: '주문 수량을 확인해주세요.',
    },
    button: {
      cancel: '취소',
      register: '생성',
    },
    label: {
      productName: '상품명',
      barcode: '바코드',
      innerBoxQuantity: '패키지 수량',
      outerBoxQuantity: '외박스 수량',
      retailPrice: '소비자가(₩)',
      purchaseUnitPrice: '매입단가(₩)',
      salesPrice: '공급가(₩)',
      orderQuantity: '주문 수량',
      totalPrice: '금액',
      inventoryQuantity: '재고 수량',
    },
    form: {
      receiverAddress: {
        label: '입고지 주소',
        requiredMessage: '입고지 주소를 입력해주세요.',
        placeholder: '입고지 주소를 입력해주세요.',
      },
      receiverName: {
        label: '이름',
        requiredMessage: '이름을 입력해주세요.',
        placeholder: '이름을 입력해주세요.',
      },
      receiverContactInformation: {
        label: '연락처',
        requiredMessage: '연락처를 입력해주세요.',
        placeholder: '연락처를 입력해주세요.',
      },
    },
  },
  managementPage: {
    title: '주문 관리',
    createButton: '주문 생성',
    summary: {
      title: '요약',
      totalPrice: '총 금액(₩)',
      totalOrderCount: '총 주문',
      totalDoneCount: '총 완료',
      totalProgressCount: '총 진행 중',
      totalDisabledCount: '총 비활성화',
    },
    createModal: {
      title: '주문 생성',
      form: {
        submit: '주문',
        cancel: '취소',
        company: {
          label: '판매자',
          requiredMessage: '판매자를 선택해주세요.',
          placeholder: '판매자를 선택해주세요.',
        },
      },
    },
    control: {
      title: '목록',
      count: '{{count}} 건',
    },
    message: {
      empty: '새로운 주문을 생성해주세요.',
      orderConfirm: '주무 확인 요청하시겠습니까?',
      createOrderConfirm: '주문을 생성하시겠습니까?',
      createOrderSuccess: '주문을 생성했습니다.',
      createOrderError: '주문 생성에 실패했습니다.',
    },
    label: {
      no: 'No.',
      sellerName: '판매자',
      buyerName: '구매자',
      managerName: '담당자',
      createdAt: '등록 날짜',
      status: '진행 상태',
      totalPrice: '총 금액(₩)',
      disable: '비활성화',
      able: '활성화',
      confirm: '주문 확인 요청',
      confirmDone: '주문 확인 요청 완료',
    },
    button: {
      cancel: '취소',
      confirm: '요청',
    },
    tag: {
      [B2bOrderStatusEnum.OrderReady]: '주문 준비',
      [B2bOrderStatusEnum.OrderCreate]: '주문 생성',
      [B2bOrderStatusEnum.OrderRequestConfirm]: '주문 확인 요청',
      [B2bOrderStatusEnum.OrderWaitingForConfirm]: '주문 확인 중',
      [B2bOrderStatusEnum.PendingDeposit]: '입금 대기 중',
      [B2bOrderStatusEnum.Done]: '완료',
    },
  },
};
