import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from '@gomi/component';
import { InputNumber, message, Select } from 'antd';
import { Form, Input, Modal } from 'antd/es';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ImportLicensingDocumentStatusEnum,
  ImportLicensingSheetStepEnum,
  useBrandListQuery,
  useCreateImportLicensingContentMutation,
  useProductListQuery,
} from '@/generated/graphql-react-query';
import { useContentListHook } from '@/hooks/importLicensing/details/useContentListHook';
import { useImportLicensingHook } from '@/hooks/importLicensing/details/useImportLicensingHook';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';

interface IForm {
  brandId: string;
  brandKrName: string;
  brandName: string;
  productId: string;
  contentKrName: string;
  contentName: string;
  manufacturerKrName: string;
  manufacturerName: string;
  salesPrice: number;
  desiredSalesPrice: number;
}

export const CreateModal = observer(() => {
  const { t } = useTranslation(['importLicensingInfo']);
  const [open, setOpen] = useState(false);
  const { importLicensingId } = useParams<IParams>();
  const [form] = Form.useForm<IForm>();
  const brandId = Form.useWatch('brandId', form);

  const {
    importLicensingDetailModel: { steps },
  } = useStore();

  const { companyId } = useImportLicensingHook({ importLicensingId });

  const { refetch: refetchProductCreateList } = useContentListHook({
    importLicensingId,
    step: ImportLicensingSheetStepEnum.CreateProduct,
  });
  const { data: brandListData } = useBrandListQuery({ input: { filter: { companyId } } });
  const { data: productListData } = useProductListQuery(
    {
      input: {
        filter: {
          brandIds: [brandId],
        },
      },
    },
    {
      enabled: !!brandId,
    },
  );
  const productList = productListData?.productList?.nodes || [];

  const { mutate: createImportLicensingContent } =
    useCreateImportLicensingContentMutation({
      onSuccess: () => {
        message.success(
          t('importLicensingInfo:detailPage.steps.first.modal.message.createSuccess'),
        );
        refetchProductCreateList();
        handleClose();
      },
      onError: () =>
        message.error(
          t('importLicensingInfo:detailPage.steps.first.modal.message.createError'),
        ),
    });

  const handleClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleSubmitConfirmOk = async () => {
    const values = await form.validateFields();
    createImportLicensingContent({
      input: {
        manufacturerKrName: values.manufacturerKrName,
        manufacturerName: values.manufacturerName,
        brandKrName: values.brandKrName,
        brandName: values.brandName,
        productId: values.productId,
        contentKrName: values.contentKrName,
        contentName: values.contentName,
        salesPrice: Number(values.salesPrice),
        desiredSalesPrice: Number(values.desiredSalesPrice),
        importLicensingId,
      },
    });
  };

  const handleSubmit = async () => {
    await form.validateFields();
    Modal.confirm({
      title: t('importLicensingInfo:detailPage.steps.first.modal.confirm.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('importLicensingInfo:detailPage.steps.first.modal.confirm.content'),
      okText: t('importLicensingInfo:detailPage.steps.first.modal.confirm.ok'),
      cancelText: t('importLicensingInfo:detailPage.steps.first.modal.confirm.cancel'),
      centered: true,
      onOk: handleSubmitConfirmOk,
    });
  };

  const isDoneStep = steps.status === ImportLicensingDocumentStatusEnum.Done;
  const disabled = !brandId;

  return (
    <>
      <Button onClick={() => setOpen(true)} isDisabled={isDoneStep}>
        {t('importLicensingInfo:detailPage.steps.first.controlBar.createButton')}
      </Button>
      <Modal
        maskClosable
        title={t('importLicensingInfo:detailPage.steps.first.modal.title')}
        open={open}
        onCancel={handleClose}
        width={664}
        bodyStyle={{ maxHeight: '672px', overflowY: 'scroll' }}
        className='gm-pb-0'
        destroyOnClose
        footer={
          <>
            <Button color='primary' btnType='filled' onClick={handleSubmit}>
              {t('importLicensingInfo:detailPage.steps.first.modal.submit')}
            </Button>
          </>
        }
      >
        <Form form={form} layout='vertical' autoComplete='off'>
          <Form.Item name='brandId' hidden />

          {/* 브랜드 (국문) */}
          <Form.Item
            name='brandKrName'
            label={t(
              'importLicensingInfo:detailPage.steps.first.modal.form.brandKrName.label',
            )}
            rules={[
              {
                required: true,
                message: t(
                  'importLicensingInfo:detailPage.steps.first.modal.form.brandKrName.requiredMessage',
                ),
              },
            ]}
          >
            <Select
              placeholder={t(
                'importLicensingInfo:detailPage.steps.first.modal.form.brandKrName.placeholder',
              )}
              showSearch
              allowClear
              options={brandListData?.partnerBrandsV2.nodes.map(({ brandKrName }) => ({
                label: brandKrName,
                value: brandKrName,
              }))}
              onChange={(value) => {
                const brand = brandListData?.partnerBrandsV2.nodes.find(
                  (brand) => brand.brandKrName === value,
                );
                form.setFieldsValue({ brandName: brand?.brandName, brandId: brand?.id });
              }}
              notFoundContent={
                <p className='gm-mt-2 gm-text-gray-600 gm-text-center'>
                  {t(
                    'importLicensingInfo:detailPage.steps.first.modal.form.brandKrName.emptyComment1',
                  )}
                  <br />
                  {t(
                    'importLicensingInfo:detailPage.steps.first.modal.form.brandKrName.emptyComment2',
                  )}
                </p>
              }
            />
          </Form.Item>

          {/* 브랜드 (영문) */}
          <Form.Item
            name='brandName'
            label={t(
              'importLicensingInfo:detailPage.steps.first.modal.form.brandName.label',
            )}
            rules={[
              {
                required: true,
                message: t(
                  'importLicensingInfo:detailPage.steps.first.modal.form.brandName.requiredMessage',
                ),
              },
            ]}
          >
            <Input
              disabled
              placeholder={t(
                'importLicensingInfo:detailPage.steps.first.modal.form.brandName.placeholder',
              )}
            />
          </Form.Item>

          {/* 상품 */}
          <Form.Item
            name='productId'
            label={t(
              'importLicensingInfo:detailPage.steps.first.modal.form.productId.label',
            )}
            rules={[
              {
                required: true,
                message: t(
                  'importLicensingInfo:detailPage.steps.first.modal.form.productId.requiredMessage',
                ),
              },
            ]}
          >
            <Select
              placeholder={t(
                'importLicensingInfo:detailPage.steps.first.modal.form.productId.placeholder',
              )}
              disabled={disabled}
              showSearch
              allowClear
              options={productList.map(({ productKrName, id }) => ({
                label: productKrName,
                value: id,
              }))}
              onChange={(value) => {
                const product = productList.find((product) => product.id === value);
                form.setFieldsValue({
                  contentKrName: product?.productKrName || undefined,
                  contentName: product?.productName || undefined,
                });
              }}
              notFoundContent={
                <p className='gm-mt-2 gm-text-gray-600 gm-text-center'>
                  {t(
                    'importLicensingInfo:detailPage.steps.first.modal.form.productId.emptyComment1',
                  )}
                  <br />
                  {t(
                    'importLicensingInfo:detailPage.steps.first.modal.form.productId.emptyComment2',
                  )}
                </p>
              }
            />
          </Form.Item>

          {/* 상품명 (국문) */}
          <Form.Item
            name='contentKrName'
            label={t(
              'importLicensingInfo:detailPage.steps.first.modal.form.productKrName.label',
            )}
            rules={[
              {
                required: true,
                message: t(
                  'importLicensingInfo:detailPage.steps.first.modal.form.productKrName.requiredMessage',
                ),
              },
            ]}
          >
            <Input
              disabled={disabled}
              placeholder={t(
                'importLicensingInfo:detailPage.steps.first.modal.form.productKrName.placeholder',
              )}
            />
          </Form.Item>

          {/* 상품명 (영문) */}
          <Form.Item
            name='contentName'
            label={t(
              'importLicensingInfo:detailPage.steps.first.modal.form.productName.label',
            )}
            rules={[
              {
                required: true,
                message: t(
                  'importLicensingInfo:detailPage.steps.first.modal.form.productName.requiredMessage',
                ),
              },
            ]}
          >
            <Input
              disabled={disabled}
              placeholder={t(
                'importLicensingInfo:detailPage.steps.first.modal.form.productName.placeholder',
              )}
            />
          </Form.Item>

          {/* 제조사명 (국문) */}
          <Form.Item
            name='manufacturerKrName'
            label={t(
              'importLicensingInfo:detailPage.steps.first.modal.form.manufacturerKrName.label',
            )}
            rules={[
              {
                required: true,
                message: t(
                  'importLicensingInfo:detailPage.steps.first.modal.form.manufacturerKrName.requiredMessage',
                ),
              },
            ]}
          >
            <Input
              disabled={disabled}
              placeholder={t(
                'importLicensingInfo:detailPage.steps.first.modal.form.manufacturerKrName.placeholder',
              )}
            />
          </Form.Item>

          {/* 제조사명 (영문) */}
          <Form.Item
            name='manufacturerName'
            label={t(
              'importLicensingInfo:detailPage.steps.first.modal.form.manufacturerName.label',
            )}
            rules={[
              {
                required: true,
                message: t(
                  'importLicensingInfo:detailPage.steps.first.modal.form.manufacturerName.requiredMessage',
                ),
              },
            ]}
          >
            <Input
              disabled={disabled}
              placeholder={t(
                'importLicensingInfo:detailPage.steps.first.modal.form.manufacturerName.placeholder',
              )}
            />
          </Form.Item>

          {/* 판매가(₩) */}
          <Form.Item
            name='salesPrice'
            label={t(
              'importLicensingInfo:detailPage.steps.first.modal.form.salesPrice.label',
            )}
          >
            <InputNumber
              disabled={disabled}
              className='gm-w-full'
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              step={1000}
              min={0}
              placeholder={t(
                'importLicensingInfo:detailPage.steps.first.modal.form.salesPrice.placeholder',
              )}
            />
          </Form.Item>

          {/* 희망 판매가 (₫) */}
          <Form.Item
            name='desiredSalesPrice'
            label={t(
              'importLicensingInfo:detailPage.steps.first.modal.form.desiredSalesPrice.label',
              { unit: '₫' },
            )}
          >
            <InputNumber
              disabled={disabled}
              className='gm-w-full'
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              step={1000}
              min={0}
              placeholder={t(
                'importLicensingInfo:detailPage.steps.first.modal.form.desiredSalesPrice.placeholder',
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});
