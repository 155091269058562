import { useTranslation } from 'react-i18next';

import { Comment } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/commentArea/Comment';

interface CommentListProps {
  total: number;
  commentList: {
    id: string;
    writer: string;
    updateAt: string;
    content: string;
  }[];
}

export const CommentList = ({ commentList, total }: CommentListProps) => {
  const { t } = useTranslation(['importLicensingInfo']);

  return (
    <div className='gm-mt-10'>
      <div className='gm-text-sm'>
        {t('importLicensingInfo:control.count', { count: total })}
      </div>
      <div className='gm-flex gm-flex-col gm-gap-2 gm-max-h-[calc(100vh-65px-230px)] gm-mt-2 gm-overflow-auto'>
        {commentList.map((comment) => (
          <Comment key={comment.id} {...comment} />
        ))}
      </div>
    </div>
  );
};
