import { DownloadOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button } from '@gomi/component';
import { Dropdown, message } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ContentHeader as PageHeader } from '@/component';
import { B2bOrderStatusEnum } from '@/generated/graphql-react-query';
import { useConfirmB2bOrderHook } from '@/hooks/order/check/useConfirmB2bOrderHook';
import { useGetB2bOrderHook } from '@/hooks/order/common/useGetB2bOrderHook';
import { useGetB2bOrderStatusHook } from '@/hooks/order/common/useGetB2bOrderStatusHook';
import { useGetB2bOrderFilesHook } from '@/hooks/order/detail/useGetB2bOrderFilesHook';
import { OrderStatusTag } from '@/views/order/common/OrderStatusTag';
import { ArrivalModal } from '@/views/order/detail/ArrivalModal';

export type ModalType = 'arrival' | 'upload' | null;

const CustomDropdown = styled(Dropdown.Button)`
  .ant-btn-compact-first-item {
    ${tw`gm-hidden`}
  }

  .ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(
      .ant-btn-compact-first-item
    ):not(.ant-btn-compact-item-rtl) {
    ${tw`gm-rounded-sm`}
  }
`;

export const ContentHeader = () => {
  const { t } = useTranslation(['orderInfo']);
  const { orderId } = useParams<{ orderId: string }>();
  const [visibleModal, setVisibleModal] = useState<ModalType>(null);

  const { confirmB2bOrderByBuyer, isLoadingConfirmB2bOrderByBuyer } =
    useConfirmB2bOrderHook();
  const { isBuyer, isSeller, refetchB2bOrder } = useGetB2bOrderHook(orderId);
  const { b2bOrderFiles } = useGetB2bOrderFilesHook(orderId);
  const { b2bOrderStatus } = useGetB2bOrderStatusHook(orderId);

  const isDone = b2bOrderStatus === B2bOrderStatusEnum.Done;
  const isSetEstimatedArrivalDate = !!b2bOrderFiles.estimatedArrivalDate;

  const handleModalClose = () => {
    setVisibleModal(null);
  };

  const handleArrivalModalOpen = () => {
    setVisibleModal('arrival');
  };

  const handleDownloadClick = (link: string | undefined) => {
    if (!isSetEstimatedArrivalDate) {
      if (isBuyer) {
        message.error(
          '거래확정서를 준비하고 있습니다. 준비 완료된 후 이메일로 전달드리겠습니다.',
        );
      } else {
        message.error(
          t(
            'orderInfo:detailPage.arrivalModal.form.estimatedArrivalDate.message.require',
          ),
        );
      }
    } else if (!link)
      message.warning(t('orderInfo:detailPage.message.fileDownloadLinkError'));
  };

  const handleConfirmByBuyer = async () => {
    await confirmB2bOrderByBuyer(orderId);
    await refetchB2bOrder();
  };

  const isWaitingForBuyerConfirm =
    B2bOrderStatusEnum.OrderWaitingForConfirm === b2bOrderStatus;
  const isPendingDepositStatus = B2bOrderStatusEnum.PendingDeposit === b2bOrderStatus;

  return (
    <>
      <PageHeader
        title={<span>{t('orderInfo:detailPage.title')}</span>}
        subtitle={b2bOrderStatus && <OrderStatusTag status={b2bOrderStatus} />}
      >
        {/* 도착 예정일 설정 */}
        {isWaitingForBuyerConfirm && isBuyer && !isDone && (
          <Button
            color='primary'
            onClick={handleConfirmByBuyer}
            isDisabled={isLoadingConfirmB2bOrderByBuyer}
          >
            최종 주문 확인
          </Button>
        )}

        {/* 도착 예정일 설정 */}
        {isPendingDepositStatus && isSeller && !isDone && (
          <Button
            color={!b2bOrderFiles.estimatedArrivalDate ? 'primary' : 'default'}
            onClick={handleArrivalModalOpen}
          >
            {b2bOrderFiles.estimatedArrivalDate
              ? t('orderInfo:detailPage.actions.updateEstimatedArrivalDate')
              : t('orderInfo:detailPage.actions.setEstimatedArrivalDate')}
          </Button>
        )}

        {(isPendingDepositStatus || isDone) && (
          <CustomDropdown
            menu={{
              items: [
                // 거래 확정서
                isBuyer
                  ? {
                      key: 3,
                      label: (
                        <a
                          href={
                            isSetEstimatedArrivalDate
                              ? b2bOrderFiles.orderConfirmDocumentFile?.link
                              : undefined
                          }
                          download
                          onClick={() =>
                            handleDownloadClick(
                              b2bOrderFiles.orderConfirmDocumentFile?.link,
                            )
                          }
                        >
                          <DownloadOutlined className='gm-mr-2' />
                          {t('orderInfo:detailPage.actions.confirmationOfTransaction')}
                        </a>
                      ),
                    }
                  : null,
                // 구매자 입금 확인서
                isBuyer && isDone
                  ? {
                      key: 5,
                      label: (
                        <a
                          href={
                            isSetEstimatedArrivalDate
                              ? b2bOrderFiles.buyerDepositConfirmationFile?.link
                              : undefined
                          }
                          download
                          onClick={() =>
                            handleDownloadClick(
                              b2bOrderFiles.buyerDepositConfirmationFile?.link,
                            )
                          }
                        >
                          <DownloadOutlined className='gm-mr-2' />
                          {t('orderInfo:detailPage.actions.buyerDepositConfirmation')}
                        </a>
                      ),
                    }
                  : null,
                // 발주서
                isSeller
                  ? {
                      key: 4,
                      label: (
                        <a
                          href={
                            isSetEstimatedArrivalDate
                              ? b2bOrderFiles.orderDocumentFile?.link
                              : undefined
                          }
                          download
                          onClick={() =>
                            handleDownloadClick(b2bOrderFiles.orderDocumentFile?.link)
                          }
                        >
                          <DownloadOutlined className='gm-mr-2' />
                          {t('orderInfo:detailPage.actions.orderForm')}
                        </a>
                      ),
                    }
                  : null,
                // 구매자 입금 확인서
                isSeller && isDone
                  ? {
                      key: 5,
                      label: (
                        <a
                          href={
                            isSetEstimatedArrivalDate
                              ? b2bOrderFiles.sellerDepositConfirmationFile?.link
                              : undefined
                          }
                          download
                          onClick={() =>
                            handleDownloadClick(
                              b2bOrderFiles.sellerDepositConfirmationFile?.link,
                            )
                          }
                        >
                          <DownloadOutlined className='gm-mr-2' />
                          {t('orderInfo:detailPage.actions.sellerDepositConfirmation')}
                        </a>
                      ),
                    }
                  : null,
              ],
            }}
          />
        )}
      </PageHeader>
      {isSeller && (
        <ArrivalModal
          id={orderId}
          open={visibleModal === 'arrival'}
          onClose={handleModalClose}
          defaultDate={
            b2bOrderFiles.estimatedArrivalDate
              ? moment(b2bOrderFiles.estimatedArrivalDate)
              : undefined
          }
        />
      )}
    </>
  );
};
