import { ImportLicensingLocaleType } from '@/locale/data/types/importLicensingLocale.type';

export const enImportLicensingInfo: ImportLicensingLocaleType = {
  rootMenu: 'Import Licensing Registration',
  detailPage: {
    messages: {
      newComment: 'Check out new modification requests!',
      stepWarning: 'Moveable after completing the previous step.',
    },
    title: 'Import Licensing Registration Detail',
    upload: {
      exampleView: 'Example',
      title: 'Document Upload',
      info: {
        title: 'Precautions for document upload',
      },
      confirm: {
        title: 'Document Upload',
        content: 'Are you sure you want to register the selected file?',
        ok: 'register',
        cancel: 'cancel',
      },
      createButton: 'register',
      success: 'The uploaded file has been registered.',
      empty: 'Please click on the necessary document and upload.',
    },
    controlBar: {
      checkRequestButton: 'Confirmation Request',
      nextButton: 'Next',
      previousButton: 'Prev',
    },
    status: {
      ready: 'Preparing',
      readyDone: 'Ready',
      requestConfirm: 'Checking',
      requestEdit: 'Modification Request',
      done: 'Done',
    },
    steps: {
      common: {
        requestConfirmationSuccess: 'Your request has been completed.',
        complete: 'Completed',
        requestConfirmation: {
          title: 'Confirmation Request',
          content: 'Do you want to request confirmation?',
          ok: 'Request',
          cancel: 'Cancel',
        },
      },
      first: {
        label: 'Product Create',
        controlBar: {
          createButton: 'Product Registration',
        },
        content: {
          deleteConfirm: {
            title: 'Are you sure you want to delete this content?',
            ok: 'Delete',
            cancel: 'Cancel',
            success: 'Deleted.',
          },
        },
        modal: {
          submit: 'Create',
          title: 'Product Create',
          form: {
            brandKrName: {
              label: 'Brand Korean Name',
              requiredMessage: 'Please enter the brand Korean name.',
              placeholder: 'Please enter the brand Korean name.',
              emptyComment1: 'The brand does not exist in that partner.',
              emptyComment2: 'Please register the brand and use it.',
            },
            brandName: {
              label: 'Brand Name',
              requiredMessage: 'Please enter the brand name.',
              placeholder: 'Please enter the brand name.',
            },
            productId: {
              label: 'product',
              requiredMessage: 'Please enter the product',
              placeholder: 'Please enter the product',
              emptyComment1: 'The product does not exist in that brand.',
              emptyComment2: 'Please register the product and use it.',
            },
            productKrName: {
              label: 'Product Korean Name',
              requiredMessage: 'Please enter the product Korean name.',
              placeholder: 'Please enter the product Korean name.',
            },
            productName: {
              label: 'Product Name',
              requiredMessage: 'Please enter the product name.',
              placeholder: 'Please enter the product name.',
            },
            manufacturerKrName: {
              label: 'Manufacturer Korean Name',
              requiredMessage: 'Please enter the manufacturer Korean name.',
              placeholder: 'Please enter the manufacturer Korean name.',
            },
            manufacturerName: {
              label: 'Manufacturer Name',
              requiredMessage: 'Please enter the manufacturer name.',
              placeholder: 'Please enter the manufacturer name.',
            },
            salesPrice: {
              label: 'Selling Price(₩)',
              placeholder: 'Please enter the actual amount sold.',
            },
            desiredSalesPrice: {
              label: 'Desired Selling Price({{unit}})',
              placeholder: 'Please enter the desired sales amount.',
            },
          },
          message: {
            createSuccess: 'Registered.',
            createError: 'Fail to register.',
          },
          confirm: {
            title: 'Product Create',
            content: 'Would you like to register a new product?',
            ok: 'Create',
            cancel: 'Cancel',
          },
        },
      },
      second: {
        label: 'Registration of documents by product',
      },
      third: {
        label: 'additional documents',
        controlBar: {
          createButton: 'Create Document',
        },
        modal: {
          submit: 'Create',
          title: 'Create Document',
          form: {
            documentName: {
              label: 'Document Name',
              requiredMessage: 'Please enter the document name.',
              placeholder: 'Please enter the document name.',
            },
          },
          message: {
            createSuccess: 'Created.',
            createError: 'Fail to create',
          },
          confirm: {
            title: 'Create Document',
            content: 'Would you like to create a new document?',
            ok: 'Create',
            cancel: 'Cancel',
          },
        },
      },
      forth: {
        label: 'Document Delivery',
        description1: 'All the documents are ready.',
        description2: 'Please send the original to the address below!',
        originalDocuments: 'Original Document',
        receiver: 'Recipient',
        receiverContent: 'Gomi Corp Manager {{manager}}',
        address: 'Address',
        addressContent: '7th floor of ICT Tower, 624, Gangnam-daero, Gangnam-gu, Seoul',
        submit: 'Complete and check\nthe estimate instructions',
      },
      fifth: {
        label: 'Quotation guidance and payment',
        controlBar: {
          deposit: 'Deposit completed',
          onDepositChecking: '입금 확인 중',
          taxBill: 'Issuance of tax invoice',
        },
        taxBillModal: {
          title: 'Request to issue tax invoice',
          submit: 'Request',
          form: {
            email: {
              label: 'Email to receive tax invoice',
              requiredMessage: 'Please enter your email.',
              placeholder: 'Please enter your email.',
            },
          },
          confirm: {
            title: 'Issuance of tax invoice',
            message: 'Would you like to request the issuance of a tax invoice?',
            ok: 'Request',
            cancel: 'Cancel',
            success: 'Requested to issue a tax invoice.',
          },
        },
        submitConfirm: {
          title: 'Complete Deposit',
          content: 'Did you complete the deposit?',
          ok: 'Complete',
          cancel: 'Cancel',
        },
        information: {
          info1:
            'The import license registration period takes about 40 business days after all documents are collected. However, schedule changes may occur depending on local holidays and circumstances in Vietnam.',
          info2: 'This estimate is valid for 30 days from the date of the estimate.',
        },
        productList: {
          control: {
            title: 'Estimate List',
          },
        },
        bankInfo: {
          control: {
            title: 'Deposit information',
            description:
              'Please click the [Complete Deposit] button on the upper right after the payment is completed.',
          },
          accountHolder: 'Account Holder',
          accountHolderContent: 'Gomi Corporation Co., Ltd',
          bankName: 'Bank Name',
          bankNameContent: 'KB Kookmin Bank',
          accountNumber: 'Bank Account Number',
          accountNumberContent: '342301-04-170800',
        },
        estimateList: {
          control: {
            title: 'Estimate Detail',
          },
          modal: {
            confirm: {
              exportExcel: {
                exportFail: 'Excel download failed.',
                exportFailContent1: 'There is no estimate to download.',
                exportFailContent2: 'Please register the estimate.',
                ok: 'Ok',
              },
            },
          },
        },
      },
      sixth: {
        label: 'Issuance completed',
        controlBar: {
          upload: 'Upload Notarization',
          complete: 'Final completion',
          confirm: {
            title: 'Final completion',
            content: 'Do you want to notify your partner of completion?',
            ok: 'Completion',
            cancel: 'Cancel',
            success: 'Partner has been notified of completion.',
          },
          modal: {
            title: 'Final completion',
            submit: 'Completion',
            success: 'Notarization uploaded successfully.',
          },
        },
        progress: {
          description1: 'It is currently being delivered to Vietnam for final issuance',
          description2: 'It takes about {{period}} business days.',
          description3: "I'll let you know as soon as it's done.",
          description4:
            '(However, schedule changes may occur depending on local holidays and circumstances in Vietnam.)',
        },
        success: {
          description1: 'Import licensing registration completed.',
          description2: 'Click the button below to check the notarization.',
          download: 'Download Notary',
        },
      },
    },
  },
  listPage: {
    title: 'Import Licensing Registration',
    modal: {
      button: 'Configuring Import Licensing',
      title: 'Configuring Import Licensing',
      confirm: {
        title: 'Configuring Import Licensing',
        message: 'Do you want to configure a new import licensing?',
        ok: 'Configure',
        cancel: 'Cancel',
      },
      message: {
        success: 'Created.',
        fail: 'Failed to create import licensing',
      },
      form: {
        submit: 'Create',
        template: {
          label: 'Template',
          requiredMessage: 'Select a template',
          placeholder: 'Select a template',
        },
        country: {
          label: 'Country to be licenced for importation',
          requiredMessage: 'Please select a country to get an import licensing.',
        },
      },
    },
  },
  fileViewerPage: {
    placeholder: {
      searchComment: 'Search',
    },
    button: {
      addComment: 'Add',
    },
    error: {
      loading: 'Fail to load',
    },
    header: {
      status: {
        success: 'Updated.',
      },
    },
    comment: {
      success: 'Added',
      deleteSuccess: 'Deleted',
      emptyComment: 'Please fill out the request.',
      deleteConfirm: {
        content: 'Are you sure you want to delete the request?',
        ok: 'Deleted',
        cancel: 'Cancel',
      },
      confirm: {
        title: 'Create Request',
        content: 'Are you sure you want to create a new request?',
        ok: 'Create',
        cancel: 'Cancel',
      },
    },
  },
  table: {
    no: 'No',
    name: 'Import Licensing Name',
    status: 'Status',
    country: 'Country',
    createdAt: 'Created Date',
    managerName: 'Manager Name',

    productName: 'Product Name',
    brandName: 'Brand',
    manufacturerName: 'Manufacturer',
    salesPrice: 'Sales Price(₩)',
    desiredSalesPrice: 'Desired Sales Price({{unit}})',
    doneAt: 'Completion date',
    download: 'Download',

    documentName: 'Document name',

    item: 'Item',
    document: 'Document',
    unit: 'Unit',
    count: 'Count',
    price: 'Price (₩)',
    vat: 'VAT (₩)',
    totalPrice: 'Total Price (₩)',
    total: 'Total',

    empty: 'There is no history of registration of import permit.',
    emptyProducts: 'Please register the product.',
    emptyEstimate: 'There is no estimate.',
    emptyItem: 'There is no list.\nPlease add an item.',

    category: 'Category',
    updatedAt: 'Modify Date',
    writer: 'Writer',

    actions: {
      edit: 'Edit',
    },
  },
  control: {
    title: 'List',
    count: '{{count}}',
    status: 'Status',
    country: 'Country',
    searchPlaceHolder: 'Search the import license name.',
    statusOptions: {
      running: 'Ongoing',
      done: 'Done',
    },
  },
};
