import { Instance, SnapshotIn, types } from 'mobx-state-tree';

const IDENTIFIER = 'BrandModel';

export type TVisibleModal = 'detail' | null;

export const model = types
  .model(IDENTIFIER, {
    identifier: IDENTIFIER,
    visibleModal: types.union(types.literal('detail'), types.null),
  })
  .actions((self) => ({
    setVisibleModal(visibleModal: TVisibleModal) {
      self.visibleModal = visibleModal;
    },
  }));

/** 초기화 값 */
const defaultValue: SnapshotIn<typeof model> = {
  visibleModal: null,
};

/** create or initialize */
const create = model.create(defaultValue);

const partnerStore = {
  create,
  defaultValue,
  model,
};

/** 모델 타입 */
export type IBrandModelType = Instance<typeof model>;

export default partnerStore;
