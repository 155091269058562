import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { globalEnv } from '@/config/env';
import { UseGa } from '@/types/enums';

export const HelmetComponent = (): ReactElement => {
  if (globalEnv.GA === UseGa.true) {
    return (
      <Helmet>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-XER0534LG9' />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {dataLayer.push(arguments);};
            gtag('js', new Date());
      
            gtag('config', 'G-XER0534LG9');
          `}
        </script>
      </Helmet>
    );
  }
  return <Helmet />;
};
