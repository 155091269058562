import { useState } from 'react';

import { BrandQuery, FilePathEnum } from '@/generated/graphql-react-query';

type BrandInfoFiles = FilePathEnum.BrandLogoImage;

export interface BrandInfoFileData
  extends Pick<
    {
      createdAt: any;
      deletedAt?: any | null;
      fileExtension: string;
      fileKey: string;
      fileName: string;
      id: string;
      link: string;
      type: FilePathEnum;
      updatedAt: any;
    },
    'fileName' | 'id' | 'link'
  > {
  type: BrandInfoFiles;
}

type UploadFileStatus = {
  [FilePathEnum.BrandLogoImage]: BrandInfoFileData | undefined;
};

const initUploadedFiles = {
  [FilePathEnum.BrandLogoImage]: undefined,
};

export const useUploadedFilesHook = () => {
  const [uploadedFiles, setUploadedFiles] = useState<{
    [key in BrandInfoFiles]: BrandInfoFileData | undefined;
  }>(initUploadedFiles);

  const setUploadFilesValue = ({ data }: { data: BrandQuery['partnerBrand'] }) => {
    if (data) {
      const uploadFileData: UploadFileStatus = {
        [FilePathEnum.BrandLogoImage]: {
          ...data.brandImage,
          type: FilePathEnum.BrandLogoImage,
        } as BrandInfoFileData,
      };
      setUploadedFiles(uploadFileData);
    }
  };

  return {
    uploadedFiles,
    setUploadFilesValue,
  };
};
