import { InfoCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { AgTable, Card, Empty, Tooltip } from '@gomi/component';
import { Tabs } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import {
  SettlementOrderItemSettlementStatusEnum as StatusEnum,
  useSettlementProductListByPartnerQuery,
} from '@/generated/graphql';

import { ListColumn } from './ListColumn';
import { OrdersModal } from './OrdersModal';

const { TabPane } = Tabs;

interface IPropsList {
  /** 파트너 아이디 */
  partnerId: string;
}

/** style */
const Wrapper = styled.div`
  .ag-floating-bottom-viewport {
    ${tw`gm-border-t gm-border-gray-900`}
  }
`;

/** component: 월별 정산내역 > 판매분 사입 상세 > 판매/상계 내역 리스트 */
const List: FC<IPropsList> = ({ partnerId }) => {
  const [activeTab, setActiveTab] = useState<StatusEnum>(StatusEnum.Settlement);
  const { t } = useTranslation('settlementInfo');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [productName, setProductName] = useState<string>('');
  const [productId, setProductId] = useState<string>('');

  /** tab 변경시 */
  const handleChangeTab = (tab: StatusEnum) => {
    setActiveTab(tab);
  };

  const { data: list, loading: loadingList } = useSettlementProductListByPartnerQuery({
    variables: {
      input: {
        filter: {
          settlementStatus: activeTab,
          settlementPartnerId: partnerId,
        },
      },
    },
    skip: !partnerId,
  });

  const pinnedBottomRowData = [
    {
      no: t('tableColumn.total'),
      productName: '',
      salesCount: list?.settlementProductListByPartner.totalCount,
      settlementCriteriaPrice: '',
      salesPrice: list?.settlementProductListByPartner.totalSettlementPrice,
    },
  ];

  const onVisibleOrdersModal = (
    isVisible: boolean,
    productName: string,
    productId: string,
  ) => {
    setIsVisible(isVisible);
    setProductName(productName);
    setProductId(productId);
  };

  /** handle cancel orders modal */
  const handleCancelModal = () => {
    setIsVisible(false);
    setProductName('');
  };

  return (
    <Wrapper className='gm-h-full'>
      <Tabs
        type='card'
        defaultActiveKey={StatusEnum.Settlement}
        activeKey={activeTab}
        onChange={(tab) => handleChangeTab(tab as StatusEnum)}
      >
        <TabPane tab={t('tab.settlement')} key={StatusEnum.Settlement} />
        <TabPane tab={t('tab.setoff')} key={StatusEnum.Setoff} />
      </Tabs>
      <Card
        className='gm-h-full !gm-border-t-0'
        header={
          <Tooltip
            placement='top'
            arrowPointAtCenter={false}
            title={<div>{t('tableHeader.productClickNotiTooltip')}</div>}
          >
            <span className='gm-pr-1'>{t('tableHeader.exportList')}</span>
            <InfoCircleOutlined className='gm-text-gray-800 gm-pr-4' />
          </Tooltip>
        }
      >
        <AgTable
          loading={loadingList}
          columnDefs={ListColumn({ activeTab, onVisibleOrdersModal })}
          defaultColDef={{ resizable: true }}
          rowData={list?.settlementProductListByPartner.nodes || []}
          pinnedBottomRowData={pinnedBottomRowData}
          noRowsOverlayComponent={() => (
            <Empty>{t('message.tableNoSetoffDataMessage')}</Empty>
          )}
        />
      </Card>
      <OrdersModal
        isVisible={isVisible}
        onCancel={handleCancelModal}
        productName={productName}
        activeTab={activeTab}
        partnerId={partnerId}
        productId={productId}
      />
    </Wrapper>
  );
};

export { List };
