import styled from '@emotion/styled';
import { Tabs } from 'antd';
import tw from 'twin.macro';

export const ProductRequestFormTab = styled(Tabs)`
  ${tw`gm-mb-8`}

  .ant-tabs-nav,
  .ant-tabs-nav-list {
    width: 100% !important;
  }

  .ant-tabs-content-holder {
    display: ${({ emptyTabContent }: { emptyTabContent?: boolean }) =>
      emptyTabContent ? 'none' : ''};
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: flex !important;
    width: 100% !important;
  }

  .ant-tabs-tab {
    flex: 1;
    text-align: center;
    background-color: #fff !important;
    align-items: center;
    justify-content: center;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom-color: #1890ff !important;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #8c8c8c !important;
    text-shadow: none;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #262626 !important;
    text-shadow: none;
  }

  .ant-tabs-tabpane {
    margin-top: 24px;
  }

  .ant-tabs-content {
    border: 1px solid #f0f0f0;
    padding: 0 1rem 1rem 1rem;
  }

  .ant-select-arrow .anticon {
    height: 12px;
  }
`;
