import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from '@gomi/component';
import { Modal } from 'antd';
import { Form, Input } from 'antd/es';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useImportLicensingHook } from '@/hooks/importLicensing/details/useImportLicensingHook';
import { IParams } from '@/types/importLicensing/details';

export const TaxBillModal = observer(() => {
  const { t } = useTranslation(['importLicensingInfo']);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<{
    email: string;
  }>();
  const { importLicensingId } = useParams<IParams>();

  const { taxBill } = useImportLicensingHook({
    importLicensingId,
  });

  const handleSubmitConfirmOk = async () => {
    const values = form.getFieldsValue();
    await taxBill.request({
      input: {
        id: importLicensingId,
        update: {
          email: values.email,
        },
      },
    });
    setOpen(false);
  };

  const handleSubmit = async () => {
    await form.validateFields();
    Modal.confirm({
      title: t('importLicensingInfo:detailPage.steps.fifth.taxBillModal.confirm.title'),
      icon: <ExclamationCircleOutlined />,
      content: t(
        'importLicensingInfo:detailPage.steps.fifth.taxBillModal.confirm.message',
      ),
      okText: t('importLicensingInfo:detailPage.steps.fifth.taxBillModal.confirm.ok'),
      cancelText: t(
        'importLicensingInfo:detailPage.steps.fifth.taxBillModal.confirm.cancel',
      ),
      centered: true,
      onOk: handleSubmitConfirmOk,
    });
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        loading={taxBill.loading}
        isDisabled={taxBill.requested}
      >
        {t('importLicensingInfo:detailPage.steps.fifth.controlBar.taxBill')}
      </Button>
      <Modal
        title={t('importLicensingInfo:detailPage.steps.fifth.taxBillModal.title')}
        open={open}
        onCancel={() => setOpen(false)}
        width={664}
        destroyOnClose
        afterClose={form.resetFields}
        footer={
          <>
            <Button
              color='primary'
              btnType='filled'
              onClick={handleSubmit}
              isDisabled={taxBill.loading}
            >
              {t('importLicensingInfo:detailPage.steps.fifth.taxBillModal.submit')}
            </Button>
          </>
        }
      >
        <Form form={form} layout='vertical' autoComplete='off'>
          {/* 세금계산서 발행 이메일 */}
          <Form.Item
            name='email'
            label={t(
              'importLicensingInfo:detailPage.steps.fifth.taxBillModal.form.email.label',
            )}
            rules={[
              {
                required: true,
                message: t(
                  'importLicensingInfo:detailPage.steps.fifth.taxBillModal.form.email.requiredMessage',
                ),
              },
            ]}
          >
            <Input
              placeholder={t(
                'importLicensingInfo:detailPage.steps.fifth.taxBillModal.form.email.placeholder',
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});
