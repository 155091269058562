import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';

import { ContentHeader, DefaultLayout } from '@/component';
import { List } from '@/views/product/list/List';

const ProductListContent = () => {
  const { t } = useTranslation(['productInfo']);

  return (
    <>
      <ContentHeader title={t('productInfo:listPage.title')} />
      <List />
    </>
  );
};

export const ProductListPage = () => {
  const { t } = useTranslation(['productInfo']);
  return (
    <DefaultLayout>
      <DefaultLayout.Header>
        <Breadcrumb>
          <Breadcrumb.Item>{t('productInfo:rootMenu')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('productInfo:listPage.title')}</Breadcrumb.Item>
        </Breadcrumb>
      </DefaultLayout.Header>
      <DefaultLayout.Content>
        <ProductListContent />
      </DefaultLayout.Content>
    </DefaultLayout>
  );
};
