import { ICellRendererParams } from '@ag-grid-community/core';
import React, { FC } from 'react';
import tw, { styled } from 'twin.macro';

/** style */
const Wrapper = styled.div<Pick<IPropsAgChip, 'color'>>`
  ${tw`gm-flex gm-border gm-justify-center gm-items-center gm-box-border`}
  ${tw`gm-px-2 gm-py-[0.125rem] gm-border gm-rounded gm-w-fit gm-h-5 gm-text-xs`}

  &.chip-red {
    ${tw`gm-border-red-300 gm-bg-red-100 gm-text-red-600`}
  }

  &.chip-gray {
    ${tw`gm-border-gray-500 gm-bg-gray-200 gm-text-gray-900`}
  }

  &.chip-blue {
    ${tw`gm-border-blue-300 gm-bg-blue-100 gm-text-blue-600`}
  }
`;

/** props */
export interface IPropsAgChip extends Partial<ICellRendererParams> {
  /** css class */
  className?: string;
  /** text */
  text?: string;
  /** chip color */
  color?: 'gray' | 'red' | 'blue';
}

/** component: ag-grid chip 컴포넌트 */
export const AgChip: FC<IPropsAgChip> = ({ className, text, color = 'gray' }) => {
  return (
    <>{text && <Wrapper className={`${className} chip-${color}`}>{text}</Wrapper>}</>
  );
};
