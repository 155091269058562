import { ExcelButton } from '@gomi/component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetB2bOrderHook } from '@/hooks/order/common/useGetB2bOrderHook';
import { useUploadB2bOrderDetailsHook } from '@/hooks/order/common/useUploadB2bOrderDetailsHook';
import { UploadModal } from '@/views/order/common/UploadModal';

interface IFilterControlBarProps {
  id: string;
}

export const FilterControlBar = ({ id }: IFilterControlBarProps) => {
  const { t } = useTranslation(['orderInfo', 'common']);
  const { b2bOrder } = useGetB2bOrderHook(id);
  const { orderId } = useParams<{ orderId: string }>();

  const { download } = useUploadB2bOrderDetailsHook();

  return (
    <div className='gm-flex gm-justify-between gm-items-center gm-flex-nowrap gm-overflow-x-auto'>
      <div className='gm-min-w-24'>
        <span className='gm-mr-2'>{t('orderInfo:checkPage.control.title')}</span>
        <span>
          {t('orderInfo:checkPage.control.count', {
            count: b2bOrder.orderDetails.length,
          })}
        </span>
      </div>
      <div className='gm-flex'>
        <ExcelButton
          type='button'
          onClick={() => download.mutate(orderId)}
          isDisabled={download.isLoading}
          className='gm-mr-1'
        >
          {t('common:common.button.download')}
        </ExcelButton>
        <UploadModal />
      </div>
    </div>
  );
};
