import styled from '@emotion/styled';
import { Card } from '@gomi/component';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { useOrderStatusCountHook } from '@/hooks/report/useOrderStatusCountHook';
import { usePaymentStatusCountHook } from '@/hooks/report/usePaymentStatusCountHook';
import { useShippingStatusCountHook } from '@/hooks/report/useShippingStatusCountHook';
import { useStore } from '@/stores';
import { LabelValue } from '@/types/report/type';

interface IPropsReportStatusCardProps {
  title: string;
  contents: LabelValue[];
  loading: boolean;
}

const Wrapper = styled.div`
  ${tw`gm-flex gm-flex-col md:gm-flex-row md:gm-justify-between gm-gap-4 gm-my-4`}
  .card-contents {
    ${tw`gm-list-none gm-flex gm-justify-around gm-p-0 gm-m-0`}
    li:last-child {
      ${tw`gm-text-blue-600`}
    }
    li > p {
      ${tw`gm-text-center gm-m-0 gm-text-sm gm-py-1.5`}
    }
  }
`;

/** 주문/결제/배송 상태 수량 컴포넌트 */
const ReportStatusCard = ({ title, contents, loading }: IPropsReportStatusCardProps) => {
  return (
    <Card className='md:gm-w-1/3' header={title} loading={loading}>
      <ul className='card-contents'>
        {contents.map((content) => (
          <li key={content.label}>
            <p>{content.label}</p>
            <p>{content.value}</p>
          </li>
        ))}
      </ul>
    </Card>
  );
};

export const ReportStatusWrap = observer(() => {
  const { t } = useTranslation(['report']);

  const {
    reportControlModel: {
      countryId,
      brandId,
      salesCode,
      startDate,
      endDate,
      orderStatus,
      paymentStatus,
      shippingStatus,
      channel,
      gift,
    },
  } = useStore();

  /** 주문 상태 수량 훅 */
  const { data: orderStatusCount, isLoading: isOrderStatusCountLoading } =
    useOrderStatusCountHook({
      countryId,
      brandId,
      salesCode,
      startDate,
      endDate,
      orderStatus,
      paymentStatus,
      shippingStatus,
      channel,
      gift,
    });

  /** 결제 상태 수량 훅 */
  const { data: paymentStatusCount, isLoading: isPaymentStatusCountLoading } =
    usePaymentStatusCountHook({
      countryId,
      brandId,
      salesCode,
      startDate,
      endDate,
      orderStatus,
      paymentStatus,
      shippingStatus,
      channel,
      gift,
    });

  /** 배송 상태 수량 훅 */
  const { data: shippingStatusCount, isLoading: isShippingStatusCountLoading } =
    useShippingStatusCountHook({
      countryId,
      brandId,
      salesCode,
      startDate,
      endDate,
      orderStatus,
      paymentStatus,
      shippingStatus,
      channel,
      gift,
    });

  const orderContent: LabelValue[] = [
    {
      label: t('report:label.ok'),
      value: `${orderStatusCount.ok}`,
    },
    {
      label: t('report:label.cancel'),
      value: `${orderStatusCount.cancel}`,
    },
    {
      label: t('report:label.total'),
      value: `${orderStatusCount.ok + orderStatusCount.cancel}`,
    },
  ];

  const paymentContent: LabelValue[] = [
    {
      label: t('report:label.paid'),
      value: `${paymentStatusCount.paid}`,
    },
    {
      label: t('report:label.notPaid'),
      value: `${paymentStatusCount.not_paid}`,
    },
    {
      label: t('report:label.refund'),
      value: `${paymentStatusCount.refund}`,
    },
    {
      label: t('report:label.total'),
      value: `${
        paymentStatusCount.paid + paymentStatusCount.not_paid + paymentStatusCount.refund
      }`,
    },
  ];

  const shippingContent: LabelValue[] = [
    {
      label: t('report:label.ready'),
      value: `${shippingStatusCount.readytopick}`,
    },
    {
      label: t('report:label.delivering'),
      value: `${shippingStatusCount.delivering}`,
    },
    {
      label: t('report:label.delivered'),
      value: `${shippingStatusCount.delivered}`,
    },
    {
      label: t('report:label.return'),
      value: `${shippingStatusCount.return}`,
    },
    {
      label: t('report:label.total'),
      value: `${
        shippingStatusCount.readytopick +
        shippingStatusCount.delivering +
        shippingStatusCount.delivered +
        shippingStatusCount.return
      }`,
    },
  ];

  return (
    <Wrapper>
      <ReportStatusCard
        title={t('report:label.orderStatus')}
        contents={orderContent}
        loading={isOrderStatusCountLoading}
      />
      <ReportStatusCard
        title={t('report:label.payStatus')}
        contents={paymentContent}
        loading={isPaymentStatusCountLoading}
      />
      <ReportStatusCard
        title={t('report:label.deliveryStatus')}
        contents={shippingContent}
        loading={isShippingStatusCountLoading}
      />
    </Wrapper>
  );
});
