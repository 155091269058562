import { message, Spin } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContainer } from '@/container';
import { useDemoMutation } from '@/generated/graphql-react-query';
import { authTokenStorage } from '@/lib';
import { PATH } from '@/routes';

export const Demo = () => {
  const history = useHistory();
  const { getUserInfo, getPartnerInfoById } = AuthContainer.useContainer();

  const { mutate } = useDemoMutation({
    onSuccess: (res) => {
      authTokenStorage.setToken(res.demo.accessToken);
      getUserInfo();
      getPartnerInfoById();

      // 리다이랙션 처리
      history.push(PATH.HOME);
    },
    onError: () => {
      message.error('데모 계정 이용에 장애가 발생했습니다. 고미 직원에게 문의해주세요.');
      history.push(PATH.LOGIN);
    },
  });

  useEffect(() => {
    console.log('>>> demo');
    mutate({});
  }, []);

  return (
    <div className='gm-flex gm-justify-center gm-h-[100vh] gm-items-center'>
      <Spin tip='잠시만 기다려주세요...' size='large' />
    </div>
  );
};
