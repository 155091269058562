import { ColDef, ColGroupDef, ICellRendererParams } from '@ag-grid-community/core';
import { InputNumber } from 'antd';
import { TFunction } from 'react-i18next';

import { B2bOrderDetail } from '@/hooks/order/common/useGetB2bOrderHook';
import { OrderQuantity } from '@/hooks/order/create/useUpdateB2bOrderQuantitiesHook';

interface IListColumnProps {
  t: TFunction<'orderInfo'[]>;
  b2bOrderQuantities: OrderQuantity[];
  onOrderQuantityChange(index: number, newOrderQuantity: OrderQuantity): void;
}

export const getListColumn = ({
  t,
  b2bOrderQuantities,
  onOrderQuantityChange,
}: IListColumnProps): (ColDef | ColGroupDef)[] => {
  return [
    {
      headerName: t('orderInfo:createPage.label.productName'),
      field: 'productName',
      minWidth: 200,
      flex: 1,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) => (
        <div className='gm-text-ellipsis gm-overflow-hidden'>{data?.productName}</div>
      ),
    },
    {
      headerName: t('orderInfo:createPage.label.barcode'),
      field: 'barcode',
      cellClass: 'gm-text-right',
      width: 150,
    },
    {
      headerName: t('orderInfo:createPage.label.innerBoxQuantity'),
      field: 'innerBoxQuantity',
      cellClass: 'gm-text-right',
      width: 100,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.innerBoxQuantity.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.outerBoxQuantity'),
      field: 'outerBoxQuantity',
      cellClass: 'gm-text-right',
      width: 100,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.outerBoxQuantity.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.salesPrice'),
      field: 'salesPrice',
      cellClass: 'gm-text-right',
      width: 125,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.salesPrice.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.orderQuantity'),
      field: 'orderQuantity',
      width: 120,
      cellRenderer: ({ data, rowIndex }: ICellRendererParams<B2bOrderDetail>) =>
        data && (
          <InputNumber
            onBlur={({ target }) => {
              const value = Number(parseInt(target.value.replace(',', ''), 10)) || 0;
              onOrderQuantityChange(rowIndex, {
                id: data.id,
                orderQuantity: value,
                totalPrice: value * data.salesPrice,
              });
            }}
            onStep={(value) => {
              onOrderQuantityChange(rowIndex, {
                id: data.id,
                orderQuantity: value,
                totalPrice: value * data.salesPrice,
              });
            }}
            min={0}
            defaultValue={0}
            value={b2bOrderQuantities[rowIndex].orderQuantity}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        ),
    },
    {
      headerName: t('orderInfo:createPage.label.totalPrice'),
      field: 'totalPrice',
      cellClass: 'gm-text-right',
      width: 140,
      cellRenderer: ({ rowIndex }: ICellRendererParams<B2bOrderDetail>) =>
        b2bOrderQuantities[rowIndex].totalPrice.toLocaleString(),
    },
  ];
};
