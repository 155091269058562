import styled from '@emotion/styled';
import { Divider, Form, Input, message } from 'antd';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';

import { CountryEnum } from '@/generated/graphql-react-query';
import { modalStyle } from '@/lib/product/getFormStyle';
import { InputFormItem } from '@/views/common/FormItems';

const { formItemLayout, formItemLayoutWithOutLabel } = modalStyle;

/** style */
const Wrapper = styled.div``;

interface IProduct {
  productKrName?: string | null;
  productName?: string | null;
  gspc?: string | null;
  barcode?: string | null;
  hsCode?: string | null;
  supplierProductCode?: string | null;
  productGroup: {
    category?: string | null;
    krRecommendedSalesPrice?: string | null;
    productGroupKrName?: string | null;
    productGroupKrNameKeyword?: string | null;
    productGroupName: string;
    productGroupNameKeyword?: string | null;
    recommendedSalesPrice?: string | null;
    brand?: {
      countryIds: Array<CountryEnum>;
      id: string;
      brandName: string;
      brandKrName: string;
    } | null;
    company?: {
      id: string;
      companyName: string;
      companyKrName: string;
    } | null;
  };
  productOptions?: Array<{
    productOptionName: string;
    productOptionRelId: string;
    id: string;
    productOptionGroup: {
      productOptionGroupName: string;
      id: string;
    };
  }> | null;
}

/** props */
interface IPropsBasicInfo {
  /** form 컨트롤 boxed 여부 */
  isBoxed: boolean;
  /** 수정 여부 */
  isModify?: boolean;
  /** 상품 정보 */
  productInfo: IProduct;
}

export interface IBasicInfoForm {
  productGroupKrName?: string | null;
  productGroupName?: string | null;
  productKrName?: string | null;
  productName?: string | null;
  brandName?: string | null;
  partnerName?: string | null;
  gspc?: string | null;
  barcode?: string | null;
  supplierProductCode?: string | null;
  productOptionRels:
    | Array<{
        productOptionName: string;
        productOptionId: string;
        productOptionRelId: string;
        productOptionGroupName: string;
      }>
    | [];
}

/** ref */
export interface IRefBasicTab {
  submit: ({ isValidate }: { isValidate?: boolean }) => Promise<IBasicInfoForm | null>;
}

/** component: 상품 목록 > 상품 상세 모달의 상품 기본정보 */
const BasicInfo: ForwardRefRenderFunction<IRefBasicTab, IPropsBasicInfo> = (
  { isBoxed, isModify, productInfo },
  ref,
) => {
  const { t } = useTranslation(['productInfo', 'partnerInfo']);
  const [form] = Form.useForm<IBasicInfoForm>();

  /** ref */
  useImperativeHandle(ref, () => {
    return {
      /** submit & validation */
      submit: async ({ isValidate = true }: { isValidate?: boolean }) => {
        let formValues: IBasicInfoForm | null = null;
        try {
          formValues = isValidate
            ? await form.validateFields()
            : form.getFieldsValue(true);
        } catch (e) {
          message.error(t('partnerInfo:modal.message.lackOfInformation'));
        }

        return formValues;
      },
    };
  });

  /** Basic 정보 초기화 */
  useEffect(() => {
    form.setFieldsValue({
      productGroupKrName: productInfo.productGroup?.productGroupKrName,
      productGroupName: productInfo.productGroup?.productGroupName,
      productKrName: productInfo.productKrName,
      productName: productInfo.productName,
      brandName:
        productInfo.productGroup?.brand?.brandKrName ||
        productInfo.productGroup?.brand?.brandName ||
        '',
      partnerName:
        productInfo.productGroup?.company?.companyKrName ||
        productInfo.productGroup?.company?.companyName ||
        '',
      gspc: productInfo.gspc,
      barcode: productInfo.barcode,
      supplierProductCode: productInfo.supplierProductCode,
      productOptionRels:
        productInfo.productOptions?.map((productOption) => ({
          productOptionName: productOption.productOptionName,
          productOptionId: productOption.id,
          productOptionRelId: productOption.productOptionRelId,
          productOptionGroupName: productOption.productOptionGroup.productOptionGroupName,
        })) || [],
    });
  }, [productInfo]);

  return (
    <Wrapper className='gm-mb-6'>
      <Form
        name='productInfoForm'
        form={form}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 19 }}
        autoComplete='off'
      >
        {/* 상품 그룹명 국문 */}
        <InputFormItem
          label={t('productInfo:label.productGroupKrName')}
          name='productGroupKrName'
          disabled
          boxed={isBoxed}
          value={productInfo?.productGroup?.productGroupKrName}
        />

        {/* 상품 그룹명 영문 */}
        <InputFormItem
          label={t('productInfo:label.productGroupName')}
          name='productGroupName'
          disabled
          boxed={isBoxed}
          value={productInfo?.productGroup?.productGroupName}
        />

        {/* 상품명 국문 */}
        <InputFormItem
          label={t('productInfo:label.productKrName')}
          name='productKrName'
          disabled
          boxed={isBoxed}
          value={productInfo?.productKrName}
        />

        {/* 상품명 영문 */}
        <InputFormItem
          label={t('productInfo:label.productName')}
          name='productName'
          disabled
          boxed={isBoxed}
          value={productInfo?.productName}
        />

        {/* 브랜드 */}
        <InputFormItem
          label={t('productInfo:label.brand')}
          name='brandName'
          disabled
          boxed={isBoxed}
          value={
            productInfo?.productGroup?.brand?.brandKrName ||
            productInfo?.productGroup?.brand?.brandName ||
            ''
          }
        />

        {/* 파트너 */}
        <InputFormItem
          label={t('productInfo:label.partner')}
          name='partnerName'
          disabled
          boxed={isBoxed}
          value={
            productInfo?.productGroup?.company?.companyKrName ||
            productInfo?.productGroup?.company?.companyKrName
          }
        />

        <Divider />

        {/* GSPC */}
        <InputFormItem
          label={t('productInfo:label.gspc')}
          name='gspc'
          disabled
          boxed={isBoxed}
          value={productInfo?.gspc}
        />

        {/* 바코드 */}
        <InputFormItem
          label={t('productInfo:label.barcode')}
          name='barcode'
          disabled={!isModify}
          boxed={isBoxed}
          rules={[
            {
              required: true,
              message: t('productInfo:message.barcodeEmpty'),
            },
          ]}
        />

        {/* 공급사 상품코드 */}
        <InputFormItem
          label={t('productInfo:label.supplierProductCode')}
          name='supplierProductCode'
          disabled={!isModify}
          boxed={isBoxed}
          rules={[
            {
              required: true,
              message: t('productInfo:message.supplierProductCodeEmpty'),
            },
          ]}
        />

        {/* 상품 옵션 */}
        <Form.List name='productOptionRels'>
          {(fields) =>
            fields.map((opt, index) => {
              return (
                <Form.Item
                  className={`${index < fields?.length - 1 ? 'gm-mb-2' : ''}`}
                  {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  label={index === 0 ? '옵션' : ''}
                  required={false}
                  key={opt.key}
                >
                  <InputFormItem
                    label={null}
                    style={{
                      marginBottom: index === fields.length - 1 ? '24px' : '8px',
                    }}
                    addonBefore={
                      <Form.Item
                        key={opt.key}
                        name={[opt.name, 'productOptionGroupName']}
                        noStyle
                      >
                        <Input disabled style={{ width: 80 }} />
                      </Form.Item>
                    }
                    key={opt.key}
                    name={[opt.name, 'productOptionName']}
                    disabled
                    boxed={isBoxed}
                  />
                </Form.Item>
              );
            })
          }
        </Form.List>
      </Form>
    </Wrapper>
  );
};

export default forwardRef(BasicInfo);
