import { SettlementInfoLocale } from '@/locale/data/types/settlementInfoLocale.type';

export const koSettlementInfo: SettlementInfoLocale = {
  rootMenu: '정산내역',
  header: {
    label: {
      countyInfo: '베트남',
      title: '월 정산서',
    },
    placeholder: {
      monthly: '연월',
    },
  },
  monthlyDashBoard: {
    totalSalesInfo: '월 전체 판매 수',
    totalSettlementInfo: '전체 정산 내역',
    defaultFeeRateInformation: '채널별 수수료율은 30% 입니다.',
    totalSales: '총 판매 수',
    totalSalesAmount: '총 매출 금액 (₩)',
    setoffPrice: '총 상계 금액 (₩)',
    salesFee: '총 수수료 (₩)',
    totalSettlementAmount: '총 정산 금액 (₩)',
    exchangeRate: '환율',
    totalSalesInfoTooltip: 'GSPC 기준으로 집계됩니다',
    totalSettlementInfoTooltip: '서울외국환중개의 기말 월 평균 환율',
  },
  tab: {
    settlement: '판매 내역',
    setoff: '상계 내역',
  },
  formatter: {
    feeRate: '수수료율 {{feeRate}}%',
  },
  tableHeader: {
    exportList: '산출 목록',
    productClickNotiTooltip: '상품 명 클릭시 주문정보를 확인 할 수 있습니다.',
  },
  tableColumn: {
    no: 'No.',
    productName: '상품 명',
    salesPrice: '판매 가격 (₫)',
    externalChannel: '외부채널',
    salesCount: '수량',
    channelSalesPrice: '판매 금액 (₫)',
    setoffPrice: '상계 금액 (₫)',
    sum: '합계',
    sumCountrySalesPrice: '총 판매 금액 (₫)',
    sumSalesPrice: '총 판매 금액 (₩)',
    sumCountrySetoffPrice: '총 상계 금액 (₫)',
    sumSetoffPrice: '총 상계 금액 (₩)',
    originalPrice: '매입 단가 (₩)',
    total: '총',
  },
  channel: {
    tableColumn: {
      externalChannel: '외부채널',
      totalFee: '총 수수료 (₩)',
      totalSalesPrice: '총 매출 금액 (₩)',
    },
  },
  modal: {
    label: {
      all: '전체',
      channelName: '채널',
    },
    tableColumn: {
      orderedAt: '주문날짜',
      channelName: '채널',
      orderNumber: '주문번호',
      salesPrice: '판매 가격 (₫)',
      salesCount: '수량',
      originalPrice: '매입 단가 (₩)',
      totalSalesPrice: '총 판매 금액 (₫)',
    },
    footer: {
      info1: '더 자세한 정보는',
      info2: ' 로 이동해 확인해주세요!',
    },
  },
  empty: {
    message: {
      message1: '해당 월에 정산내역이 없습니다.',
      message2: '이상이 있는 경우 아래 버튼으로 문의해주세요.',
    },
    modal: {
      title: '정산내역 문의',
      message: {
        message1: '해당 월 정산내역을 문의하시겠어요?',
        message2: '접수 후 담당자 확인까지 조금만 기다려주세요.',
      },
      button: {
        inquiries: '문의',
        cancel: '취소',
      },
    },
    button: {
      inquiries: '문의하기',
    },
  },
  message: {
    tableNoDataMessage: '정산 목록이 없습니다.',
    tableNoSetoffDataMessage: '상계 내역이 없습니다',
    noDataModalTitle: '정산 내역 오류',
    noDataModalMsg1: '월 정산 내역을 불러올 수 없습니다.',
  },
  button: {
    confirm: '확인',
    detail: '상세',
    complete: '완료',
    cancel: '취소',
    download: '다운로드',
    sendEmail: '정산서 전송',
    salesReport: '판매리포트↗',
  },
};
