export const globalEnv = {
  TRACK_API_KEY: import.meta.env.VITE_APP_TRACK_API_KEY || '',
  API_BASE_URL: import.meta.env.VITE_APP_SERVER_URL || '',
  TOKEN_KEY: import.meta.env.VITE_APP_TOKEN_KEY || '',
  GA: import.meta.env.VITE_APP_GA || 'false',
  MODE: import.meta.env.VITE_APP_MODE || 'develop',
  GRAPHQL_URL: `${import.meta.env.VITE_APP_SERVER_URL}/graphql`,
  AG_GRID_LICENSE_KEY: `${import.meta.env.VITE_APP_AG_GRID_LICENSE_KEY}`,
  IS_API_MOCKING: `${import.meta.env.VITE_APP_IS_API_MOCKING}`,
};
