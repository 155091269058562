import i18next from 'i18next';

import { axisDateFormat, axisNumberFormat } from '@/pages/report/elements/chartUtils';
import { ComposedChartConfig } from '@/views/report/sales/salesStatusChart/types';

export const composedChartConfig: ComposedChartConfig = {
  chartData: {
    OK: {
      chartType: 'Bar',
      dataKey: 'OK',
      color: '#D9F7BE',
      stackId: '1',
      yAxisId: 'left',
      legendName: i18next.t('report:chart.legend.ok'),
      legendTextColor: '#262626',
    },
    CANCEL: {
      chartType: 'Bar',
      dataKey: 'CANCEL',
      color: '#D9D9D9',
      stackId: '1',
      yAxisId: 'left',
      legendName: i18next.t('report:chart.legend.cancel'),
      legendTextColor: '#262626',
    },
    TOTAL_AMOUNT: {
      chartType: 'Line',
      dataKey: 'TOTAL_AMOUNT',
      color: '#ff7300',
      stackId: '2',
      yAxisId: 'right',
      legendName: i18next.t('report:chart.legend.totalAmount'),
      legendTextColor: '#262626',
    },
  },
  gridColor: '#f5f5f5',
  xAxis: {
    dataKey: 'xAxisKey',
    type: 'category',
    tickFormatter: axisDateFormat,
  },
  yAxis: [
    {
      dataKey: 'OK',
      type: 'number',
      yAxisId: 'left',
      orientation: 'left',
      tickFormatter: axisNumberFormat,
    },
    {
      dataKey: 'TOTAL_AMOUNT',
      type: 'number',
      yAxisId: 'right',
      orientation: 'right',
      tickFormatter: axisNumberFormat,
    },
  ],
};
