import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Form, Input, InputNumber, message, Upload } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import Link from 'antd/es/typography/Link';
import { RcFile } from 'antd/es/upload';
import i18next from 'i18next';
import { CSSProperties, ReactNode } from 'react';
import tw from 'twin.macro';

import { FilePathEnum } from '@/generated/graphql-react-query';

interface CustomInputProps {
  isDone: boolean;
}

interface NumberInputFormItemProps {
  label: string | null;
  name: string | (string | number)[];
  disabled?: boolean;
  placeholder?: string;
  disabledPlaceholder?: string | null;
  rules?: any[];
  value?: string | number | null;
  hidden?: boolean;
  boxed?: boolean;
  formStyle?: CSSProperties;
  style?: CSSProperties;
  addonBefore?: ReactNode | null;
  addonAfter?: ReactNode | null;
}

export const NumberInputFormItem = ({
  label,
  name,
  formStyle = {},
  style = {},
  disabled,
  placeholder,
  addonBefore = null,
  addonAfter = null,
  disabledPlaceholder,
  rules,
  boxed,
  value,
  hidden,
}: NumberInputFormItemProps) => {
  const parsedPlaceholder = disabled ? disabledPlaceholder || '-' : placeholder;
  const customRules = rules?.map((rule, index) =>
    index === 0
      ? {
          ...rule,
          validator: (rule: any, value: number) =>
            value && value !== 0 ? Promise.resolve() : Promise.reject(rule.message),
        }
      : rule,
  );

  if (addonBefore || addonAfter) {
    const isDone = boxed ? false : !!disabled;
    if (!addonBefore && addonAfter && isDone) {
      return (
        <Form.Item
          label={label}
          name={name}
          style={style}
          rules={disabled ? [] : customRules}
          hidden={hidden}
        >
          {value ? (
            <span className='gm-pl-3'>
              {`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </span>
          ) : (
            <CustomAddonBeforeNumberInput
              placeholder={parsedPlaceholder}
              disabled={disabled}
              style={formStyle}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              addonBefore={addonBefore}
              isDone={isDone}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item
        label={label}
        name={name}
        style={style}
        rules={disabled ? [] : customRules}
        hidden={hidden}
      >
        <CustomAddonBeforeNumberInput
          placeholder={parsedPlaceholder}
          disabled={disabled}
          style={formStyle}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          isDone={isDone}
        />
      </Form.Item>
    );
  }

  return (
    <Form.Item
      label={label}
      name={name}
      rules={disabled ? [] : customRules}
      hidden={hidden}
      style={style}
    >
      <InputNumber
        className={`gm-text-black/[.85] ${
          !boxed && disabled ? 'gm-bg-white gm-border-none gm-cursor-auto' : ''
        }`}
        placeholder={parsedPlaceholder}
        disabled={disabled}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        value={value ?? ''}
        style={formStyle}
      />
    </Form.Item>
  );
};

interface InputFormItemProps {
  label: string | null;
  name: string | (string | number)[];
  autoComplete?: string;
  disabled?: boolean;
  normalize?: ((value: string) => string) | undefined;
  placeholder?: string;
  style?: CSSProperties;
  disabledPlaceholder?: string | null;
  rules?: any[];
  value?: string | number | null;
  initialValue?: string | number | null;
  hidden?: boolean;
  boxed?: boolean;
  addonAfter?: ReactNode | null;
  addonBefore?: ReactNode | null;
}

interface IUploadFileData
  extends Pick<
    {
      createdAt: any;
      deletedAt?: any | null;
      fileExtension: string;
      fileKey: string;
      fileName: string;
      id: string;
      link: string;
      type: FilePathEnum;
      updatedAt: any;
    },
    'fileName' | 'id' | 'link'
  > {
  type: FilePathEnum;
}

interface FileUploadFormItemProps {
  label: string;
  placeholder: string;
  onChangeFileInput?: (file: RcFile, partnerInfoFileType: FilePathEnum) => void;
  fileType: FilePathEnum;
  accept?: string;
  buttonText?: string;
  uploadFile?: IUploadFileData;
  disabled?: boolean;
  name?: string;
  rules?: any[];
  onClickDelete?: (fileType: FilePathEnum) => void;
  helpText?: string | ReactNode | undefined;
}

const CustomAddonBeforeNumberInput = styled(InputNumber)<CustomInputProps>`
  ${({ isDone }) => isDone && tw`gm-bg-white gm-border-none gm-cursor-auto`}

  .ant-input-number-input-wrap > input {
    ${({ isDone }) => isDone && tw`gm-bg-white gm-border-none gm-cursor-auto`}
    ${tw`gm-text-black/[.85]`}
  }

  .ant-input-number-group-addon {
    ${tw`gm-p-0 gm-border-none`}
  }

  .ant-input-number-group-addon .ant-select {
    ${tw`gm-m-0 gm-ml-[-1px]`}
    ${({ isDone }) => (isDone ? tw`gm-bg-white` : tw`gm-bg-gray-200`)};
  }

  .ant-input-number-group-addon .ant-select-arrow {
    ${({ isDone }) => isDone && tw`gm-invisible`};
  }

  .ant-input-number-group-addon .ant-select-selection-item {
    ${({ isDone }) => !isDone && tw`gm-pr-[18px]`};
  }

  .ant-input-number-group-addon .ant-select .ant-select-selector {
    ${({ isDone }) => !isDone && tw`gm-border gm-border-gray-400`};
  }

  .ant-input-number-group-addon > input {
    ${({ isDone }) => isDone && tw`gm-border-none`}
    ${tw`gm-mr-[-1px] gm-rounded-tr-none gm-rounded-br-none`}
  }

  .ant-input-number-group-addon:last-child > input {
    ${tw`gm-mr-[-1px] gm-border-l-0 gm-rounded-tr-sm gm-rounded-br-sm gm-text-center`}
  }
`;

const CustomFormItem = styled(Form.Item)<{ isTextValue: boolean }>`
  label {
    ${({ isTextValue }) => (isTextValue ? tw`gm-items-baseline` : tw`gm-items-center`)};
  }
`;

const CustomSelectLabel = styled.span`
  :not(:last-child):after {
    content: ' / ';
  }
`;

export const InputFormItem = ({
  label,
  name,
  style = {},
  disabled,
  placeholder,
  normalize = undefined,
  autoComplete,
  addonAfter = null,
  addonBefore = null,
  disabledPlaceholder,
  rules,
  boxed,
  value,
  initialValue,
  hidden,
}: InputFormItemProps) => {
  const parsedPlaceholder = disabled ? disabledPlaceholder || '-' : placeholder;

  if (addonBefore || addonAfter) {
    return (
      <Form.Item
        label={label}
        name={name}
        normalize={normalize}
        style={style}
        rules={disabled && !boxed ? [] : rules}
        hidden={hidden}
      >
        <CustomAddonBeforeNumberInput
          placeholder={parsedPlaceholder}
          disabled={disabled}
          autoComplete={autoComplete}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          isDone={boxed ? false : !!disabled}
        />
      </Form.Item>
    );
  }

  const isTextValue = (!boxed && value) as boolean;
  return (
    <CustomFormItem
      label={label}
      name={name}
      rules={disabled && !boxed ? [] : rules}
      style={style}
      normalize={normalize}
      hidden={hidden}
      isTextValue={isTextValue}
      initialValue={initialValue}
    >
      {isTextValue ? (
        <span className='gm-inline-flex gm-h-8 gm-pl-3 gm-break-all'>{`${value}`}</span>
      ) : (
        <Input
          className={
            !boxed && disabled ? 'gm-bg-white gm-border-none gm-cursor-auto' : ''
          }
          autoComplete={autoComplete}
          placeholder={parsedPlaceholder}
          disabled={disabled}
        />
      )}
    </CustomFormItem>
  );
};

export const FileUploadFormItem = ({
  label,
  uploadFile,
  placeholder,
  disabled,
  helpText,
  onChangeFileInput = () => null,
  fileType,
  accept = '',
  buttonText,
  name,
  rules,
  onClickDelete,
}: FileUploadFormItemProps) => (
  <Form.Item label={label} rules={rules} name={name}>
    <div className='gm-flex gm-justify-between gm-items-center'>
      {uploadFile?.id ? (
        <div
          className={`gm-flex gm-justify-between gm-items-center gm-w-full gm-h-8 gm-pt-1 gm-px-3 gm-leading-6 gm-rounded-sm ${
            disabled ? 'gm-bg-gray-100' : 'gm-border gm-border-gray-400'
          }`}
        >
          <Link className='gm-truncate' href={uploadFile?.link} target='_blank'>
            {uploadFile?.fileName}
          </Link>
          {onClickDelete && !disabled && (
            <DeleteOutlined onClick={() => onClickDelete(fileType)} />
          )}
        </div>
      ) : (
        <Input
          className={disabled ? 'gm-bg-gray-100 gm-border-none gm-cursor-auto' : ''}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
      {!disabled && (
        <Upload
          beforeUpload={(file) => {
            const invalidFileSize = file.size > 1024 * 1024 * 200; // 200mb 이상이면
            if (invalidFileSize) {
              message.error(i18next.t('partnerInfo:modal.message.maxFileSize'));
              return;
            }

            const extension = file.name.split('.').pop() as string;
            const acceptExtensions = accept.split(',').map((s) => s.trim().substring(1));

            if (!acceptExtensions.includes(extension)) {
              message.warning(
                i18next.t('partnerInfo:modal.message.invalidFileExtension'),
              );
              return;
            }

            onChangeFileInput(file, fileType);
          }}
          accept={accept}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>{buttonText}</Button>
        </Upload>
      )}
    </div>
    {helpText && <p className='gm-mt-2 gm-text-xs gm-text-blue-700'>{helpText}</p>}
  </Form.Item>
);

export const CascaderInputDisplayRender = (
  labels: string[],
  selectedOptions?: DefaultOptionType[],
) =>
  labels.map((label, i) => (
    <CustomSelectLabel key={(selectedOptions || [])[i]?.value || label}>
      {label}
    </CustomSelectLabel>
  ));

interface RadioFormItemProps {
  label: string | null;
  name: string | (string | number)[];
  rules?: any[];
  hidden?: boolean;
  boxed?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  children: ReactNode;
}

export const RadioFromItem = ({
  label,
  name,
  style = {},
  rules,
  hidden,
  children,
  boxed,
  disabled,
}: RadioFormItemProps) => {
  const isDone = boxed ? false : !!disabled;
  if (isDone) {
    return (
      <InputFormItem
        label={label}
        name={name}
        style={style}
        rules={rules}
        hidden={hidden}
        disabled={disabled}
        boxed={boxed}
      />
    );
  }

  return (
    <Form.Item
      label={label}
      name={name}
      style={style}
      rules={disabled ? [] : rules}
      hidden={hidden}
    >
      {children}
    </Form.Item>
  );
};
