import { globalEnv } from '@/config/env';

export const enum AppMode {
  Production = 'production',
  Staging = 'staging',
  Develop = 'development',
}

export const envCheck = {
  isDevelop: () => globalEnv.MODE === AppMode.Develop,
  isStaging: () => globalEnv.MODE === AppMode.Staging,
  isProduction: () => globalEnv.MODE === AppMode.Production,
};
