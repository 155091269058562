import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useImportLicensingHook } from '@/hooks/importLicensing/details/useImportLicensingHook';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';
import { DocumentDeliveryStep } from '@/views/importLicensing/importLicensingDetail/documentDeliveryStep';
import { EstimateStep } from '@/views/importLicensing/importLicensingDetail/estimateStep';
import { ExtraDocumentUploadStep } from '@/views/importLicensing/importLicensingDetail/extraDocumentUploadStep';
import { IssuanceCompletedStep } from '@/views/importLicensing/importLicensingDetail/issuanceCompletedStep';
import { ProductCreateStep } from '@/views/importLicensing/importLicensingDetail/productCreateStep';
import { ProductDocumentUploadStep } from '@/views/importLicensing/importLicensingDetail/productDocumentUploadStep';
import {
  changeNumberStep,
  StepsComponent,
} from '@/views/importLicensing/importLicensingDetail/Steps';

export const Details = observer(() => {
  const {
    importLicensingDetailModel: { steps, setCurrent },
  } = useStore();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { importLicensingId } = useParams<IParams>();
  const current =
    queryParams.get('current') === null ? null : Number(queryParams.get('current'));

  /** 수입 허가 단건 조회하여 현재 스탭을 처리 */
  const { step, stepStatuses } = useImportLicensingHook({
    importLicensingId,
  });

  useEffect(() => {
    if (current === null) {
      setCurrent(changeNumberStep(step), stepStatuses[changeNumberStep(step)]);
    }

    if (current !== null) {
      setCurrent(current, stepStatuses[current]);
    }
  }, [stepStatuses, step]);
  /** 수입 허가 단건 조회하여 현재 스탭을 처리 */

  return (
    <>
      <StepsComponent />
      {steps.current === 0 && <ProductCreateStep />}
      {steps.current === 1 && <ProductDocumentUploadStep />}
      {steps.current === 2 && <ExtraDocumentUploadStep />}
      {steps.current === 3 && <DocumentDeliveryStep />}
      {steps.current === 4 && <EstimateStep />}
      {steps.current === 5 && <IssuanceCompletedStep />}
    </>
  );
});
