import { ICellRendererParams } from '@ag-grid-community/core';
import { Tag } from 'antd/es';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ImportLicensingDocumentStatusEnum } from '@/generated/graphql';

export const FileStatusRenderer = ({ value }: ICellRendererParams) => {
  const { t } = useTranslation(['importLicensingInfo']);
  const cellValue = value as ImportLicensingDocumentStatusEnum;

  const statusInfo = {
    [ImportLicensingDocumentStatusEnum.Ready]: {
      value: t('importLicensingInfo:detailPage.status.ready'),
      color: 'default',
    },
    [ImportLicensingDocumentStatusEnum.ReadyDone]: {
      value: t('importLicensingInfo:detailPage.status.readyDone'),
      color: 'geekblue',
    },
    [ImportLicensingDocumentStatusEnum.RequestConfirm]: {
      value: t('importLicensingInfo:detailPage.status.requestConfirm'),
      color: 'blue',
    },
    [ImportLicensingDocumentStatusEnum.RequestEdit]: {
      value: t('importLicensingInfo:detailPage.status.requestEdit'),
      color: 'red',
    },
    [ImportLicensingDocumentStatusEnum.Done]: {
      value: t('importLicensingInfo:detailPage.status.done'),
      color: 'green',
    },
  };

  return statusInfo[cellValue] ? (
    <Tag color={statusInfo[cellValue].color}>{statusInfo[cellValue].value}</Tag>
  ) : null;
};
