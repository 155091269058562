import styled from '@emotion/styled';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { convertNumberFormat } from '@/lib/convertIntlFormat';
import { ChartDataConfig } from '@/views/report/sales/salesStatusChart/types';

const TooltipWrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  margin: 0;
  padding: 1rem;
  font-size: 0.8rem;
`;

const TooltipTitle = styled.p`
  text-align: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #d9d9d9;
`;

const TooltipContents = styled.div`
  min-width: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
`;

const TooltipKeyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-right: 12px;
`;

interface TooltipColorBoxProps {
  color: string;
}

const TooltipColorBox = styled.div<TooltipColorBoxProps>`
  width: 12px;
  height: 12px;
  margin: 0;
  border: none;
  background-color: ${(props: TooltipColorBoxProps) => props.color};
`;

export const ChartTooltip = ({
  active,
  payload,
  label,
  chartConfig,
}: TooltipProps<ValueType, NameType> & {
  chartConfig: ChartDataConfig;
}) => {
  if (active) {
    return (
      <TooltipWrapper>
        <TooltipTitle className='label'>{label}</TooltipTitle>
        {payload?.map((p) => (
          <div key={p.dataKey}>
            {p.dataKey && (
              <TooltipContents>
                <TooltipKeyWrapper>
                  <TooltipColorBox color={chartConfig[p.dataKey].color} />
                  <span>{chartConfig[p.dataKey].legendName}</span>
                </TooltipKeyWrapper>
                <span>{convertNumberFormat('ko', Number(p.value))}</span>
              </TooltipContents>
            )}
          </div>
        ))}
      </TooltipWrapper>
    );
  }

  return null;
};
