import { channelIO, Track } from '@gomi/utils';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createContainer } from 'unstated-next';

import { CountryOrder, NOTIFICATION } from '@/constant/auth';
import {
  DtoGetLogin,
  DtoReturnCountry,
  SalesTypeEnum,
  useChangePartnerPasswordV2Mutation,
  useCompanyByPartnerLazyQuery,
  useGetPartnerInfoLazyQuery,
  usePartnerLoginV3Mutation,
  useSendPartnerPasswordChangeEmailV2Mutation,
} from '@/generated/graphql';
import { AuthorizationEnum } from '@/generated/graphql-react-query';
import { useNotification } from '@/hooks';
import { authTokenStorage } from '@/lib';
import { referrerSessionStorage } from '@/lib/sessionStorageController';
import { getInitLocaleLang, localeStorage } from '@/locale/lib';
import { LocaleEnum } from '@/locale/types';
import { PATH } from '@/routes';
import { ChangePasswordFormData, FindPasswordFormData } from '@/types/types';

const authContainer = () => {
  const history = useHistory();
  const [locale, setLocale] = useState<LocaleEnum>(getInitLocaleLang());
  const [isLogin, setIsLogin] = useState(false);
  const [salesType, setSalesType] = useState<SalesTypeEnum>();
  const location = useLocation();

  const signOut = () => {
    setIsLogin(false);
    history.push(PATH.LOGIN);
    authTokenStorage.clearToken();
  };

  const [sendFindPasswordEmail, { data: isSendFindEmail }] =
    useSendPartnerPasswordChangeEmailV2Mutation({
      onError: () => useNotification('error', '존재하지 않는 이메일입니다'),
    });

  const [changeNewPassword, { data: isChangePassword }] =
    useChangePartnerPasswordV2Mutation();

  const [getUserInfo, { data: userInfo, loading: userInfoLoading }] =
    useGetPartnerInfoLazyQuery({
      onCompleted: (data) => {
        setIsLogin(true);
        if (location.pathname === PATH.LOGIN) {
          history.push(PATH.HOME);
        }
        setSalesType(data.partnerMe.company?.salesType);
      },
      onError: () => {
        signOut();
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    });

  const [getPartnerInfoById, { data: companyInfoById, loading: loadingCompanyInfoById }] =
    useCompanyByPartnerLazyQuery();

  const [loginMutate] = usePartnerLoginV3Mutation({
    onCompleted: (res) => {
      setIsLogin(true);
      authTokenStorage.setToken(res.partnerLoginV3.accessToken);
      getUserInfo();
      getPartnerInfoById();

      // track
      Track.identify(res.partnerLoginV3.email);
      Track.track('LOGIN');

      // 리다이랙션 처리
      const referrer = referrerSessionStorage.getItem();
      if (!referrer || referrer === PATH.LOGIN) {
        history.push(PATH.HOME);
      } else {
        history.push(referrer);
        referrerSessionStorage.clear();
      }
    },
    onError: () => {
      useNotification('error', NOTIFICATION.MESSAGE.FAILED_SIGN_IN);
      Track.track('FAIL_LOGIN');
    },
  });

  const onSubmitLogin = (formData: DtoGetLogin) => {
    loginMutate({ variables: { input: formData } });
  };

  const onFinishFindPassword = (formData: FindPasswordFormData) => {
    sendFindPasswordEmail({ variables: { email: formData.emailFindPassword } });
  };

  const onFinishChangePassword = (formData: ChangePasswordFormData, authCode: string) => {
    changeNewPassword({
      variables: { password: formData.newPassword, authCode },
    });
  };

  const onChangeLocale = (selectedLocale: LocaleEnum) => {
    localeStorage.setItem(selectedLocale);
    setLocale(LocaleEnum.En);
  };

  const toLoginPage = () => {
    history.push(PATH.LOGIN);
  };

  // TODO: back 에게 countryName optional 이유 확인하기
  const setOrderCountryList = (
    countryList: DtoReturnCountry[] | undefined,
  ): Required<DtoReturnCountry>[] => {
    if (!countryList) return [];

    const result: Required<DtoReturnCountry>[] = [];
    CountryOrder.forEach((order) => {
      const country = countryList.find((item) => item.countryId === order);
      if (country) {
        result.push({
          countryId: country.countryId,
          countryName: country.countryName || '',
        });
      }
    });
    return result;
  };

  const hasAuthority = (authorization: AuthorizationEnum): boolean => {
    if (!userInfo?.partnerMe.authorizations?.length) {
      return false;
    }

    return userInfo.partnerMe.authorizations.includes(authorization);
  };

  useEffect(() => {
    if (authTokenStorage.getToken()) {
      getUserInfo();
      getPartnerInfoById();
    }
  }, []);

  useEffect(() => {
    // 데모 계정의 경우 영문으로
    if (userInfo?.partnerMe.email) {
      // 로그인 후 채널 톡 재설정
      channelIO.boot({
        pluginKey: 'f5b9a431-b57e-4353-8a69-a26e78f57662',
        memberId: userInfo?.partnerMe.email,
        profile: {
          name: userInfo?.partnerMe.userName,
          mobileNumber: userInfo?.partnerMe.phoneNumber || '',
        },
      });

      Track.set({
        $first_name: userInfo?.partnerMe.userName,
        $Name: userInfo?.partnerMe.userName,
        $email: userInfo?.partnerMe.email,
        $phone: userInfo?.partnerMe.phoneNumber,
        companyKrName: userInfo?.partnerMe.company?.companyKrName,
      });
    }
  }, [userInfo]);

  /** 앱 로드 시 채널 톡 설정 */
  useEffect(() => {
    channelIO.boot({
      pluginKey: 'f5b9a431-b57e-4353-8a69-a26e78f57662',
    });
  }, []);

  return {
    onSubmitLogin,
    signOut,
    locale,
    onChangeLocale,
    getUserInfo,
    getPartnerInfoById,
    userInfo,
    salesType,
    isLogin,
    userInfoLoading,
    companyInfoById,
    hasAuthority,
    countryList: setOrderCountryList(companyInfoById?.companyByPartner.countryOfEntry),
    loadingCompanyInfoById,
    findPasswordPage: {
      onFinishFindPassword,
      onFinishChangePassword,
      isSendFindEmail: !!isSendFindEmail,
      isChangePassword: !!isChangePassword,
      toLoginPage,
    },
  };
};

export const AuthContainer = createContainer(authContainer);
