import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { B2bOrderStatusEnum } from '@/generated/graphql-react-query';

interface IOrderStatusTagProps {
  status: B2bOrderStatusEnum;
}

const orderStatusColor = {
  [B2bOrderStatusEnum.OrderReady]: 'green',
  [B2bOrderStatusEnum.OrderCreate]: 'blue',
  [B2bOrderStatusEnum.OrderRequestConfirm]: 'gold',
  [B2bOrderStatusEnum.OrderWaitingForConfirm]: 'magenta',
  [B2bOrderStatusEnum.PendingDeposit]: 'red',
  [B2bOrderStatusEnum.Done]: 'default',
};

export const OrderStatusTag = ({ status }: IOrderStatusTagProps) => {
  const { t } = useTranslation(['orderInfo']);

  // 주문 생성 상태일 때 확인 요청으로 보여야 함
  const parsedStatus =
    status === B2bOrderStatusEnum.OrderCreate
      ? B2bOrderStatusEnum.OrderRequestConfirm
      : status;

  return (
    <Tag color={orderStatusColor[parsedStatus]} className='gm-mr-0'>
      {t(`orderInfo:managementPage.tag.${parsedStatus}`)}
    </Tag>
  );
};
