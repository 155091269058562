import { ALL } from '@/constant';
import {
  ReportExportExcelEnum,
  useReportExportExcelV2Mutation,
} from '@/generated/graphql-react-query';
import { IReportControl } from '@/types/report/type';

export const useReportDownloadHook = ({
  startDate,
  endDate,
  brandId,
  channel,
  gift,
  orderStatus,
  paymentStatus,
  shippingStatus,
  salesCode,
  countryId,
}: IReportControl) => {
  const { mutateAsync, isLoading } = useReportExportExcelV2Mutation();

  const download = async (type: ReportExportExcelEnum) => {
    const downloadUrl = await mutateAsync({
      input: {
        filter: {
          countryId,
          startOrderedAt: startDate,
          endOrderedAt: endDate,
          ...(brandId !== ALL && { brandId }),
          ...(channel !== ALL && { channel }),
          ...(gift !== ALL && { giftType: gift }),
          ...(orderStatus !== ALL && { orderStatus }),
          ...(paymentStatus !== ALL && { paymentStatus: [paymentStatus] }),
          ...(shippingStatus !== ALL && { shippingStatus: [shippingStatus] }),
          ...(salesCode !== ALL && { salesCode: [salesCode] }),
        },
        type,
      },
    });

    window.open(downloadUrl.reportExportExcelV2);
  };

  return {
    download,
    isLoading,
  };
};
