import styled from '@emotion/styled';
import { AntButton } from '@gomi/component';
import { Form, Input } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { AuthContainer } from '@/container';
import { useNotification } from '@/hooks';
import { ChangePasswordFormData } from '@/types/types';

const FindPasswordEmailForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FindPasswordInput = styled(Input)`
  margin-bottom: 1rem;
`;

const FindPasswordInputFormItem = styled(Form.Item)`
  width: 100%;
`;

const ChangedPasswordMessage = styled.span`
  margin-top: 3rem;
  margin-bottom: 4rem;
  font-size: 1rem;
`;

export const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { findPasswordPage } = AuthContainer.useContainer();

  useEffect(() => {
    if (id === 'undefined') {
      history.push('/');
      useNotification('error', t('notification.serverError'));
    }
  }, [id]);
  return (
    <>
      {!findPasswordPage.isChangePassword ? (
        <FindPasswordEmailForm
          onFinish={(values) =>
            findPasswordPage.onFinishChangePassword(values as ChangePasswordFormData, id)
          }
        >
          <FindPasswordInputFormItem
            name='newPassword'
            rules={[
              {
                required: true,
                message: t('validationMessage.requiredNewPassword'),
              },
              {
                min: 10,
                message: t('validationMessage.passwordMinLength'),
              },
            ]}
          >
            <FindPasswordInput
              type='password'
              placeholder={t('placeholder.newPassword')}
              size='large'
            />
          </FindPasswordInputFormItem>
          <FindPasswordInputFormItem
            name='confirmNewPassword'
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
                message: t('validationMessage.requiredPassword'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('validationMessage.notMatchPassword')),
                  );
                },
              }),
            ]}
          >
            <FindPasswordInput
              type='password'
              placeholder={t('placeholder.confirmPassword')}
              size='large'
            />
          </FindPasswordInputFormItem>
          <AntButton htmlType='submit' size='large' block type='primary'>
            {t('common.button.confirm')}
          </AntButton>
        </FindPasswordEmailForm>
      ) : (
        <>
          <ChangedPasswordMessage>{t('info.changedPassword')}</ChangedPasswordMessage>
          <AntButton
            onClick={findPasswordPage.toLoginPage}
            type='primary'
            size='large'
            block
          >
            {t('common.button.toLoginPage')}
          </AntButton>
        </>
      )}
    </>
  );
};
