import { format } from 'date-fns';

import { ALL } from '@/constant';
import { useOrderDetailsQuery } from '@/generated/graphql-react-query';
import { IReportControl } from '@/types/report/type';

export const useOrderDetailsHook = ({
  startDate,
  endDate,
  brandId,
  channel,
  gift,
  orderStatus,
  paymentStatus,
  shippingStatus,
  salesCode,
  countryId,
  pagination = null,
}: IReportControl) => {
  const { data, isLoading } = useOrderDetailsQuery({
    input: {
      filter: {
        countryId,
        startOrderedAt: startDate,
        endOrderedAt: endDate,
        ...(brandId !== ALL && { brandId }),
        ...(channel !== ALL && { channel }),
        ...(gift !== ALL && { giftType: gift }),
        ...(orderStatus !== ALL && { orderStatus }),
        ...(paymentStatus !== ALL && { paymentStatus: [paymentStatus] }),
        ...(shippingStatus !== ALL && { shippingStatus: [shippingStatus] }),
        ...(salesCode !== ALL && { salesCode: [salesCode] }),
      },
      pagination,
    },
  });

  return {
    data:
      data?.orderDetails.nodes.map((orderItem) => ({
        ...orderItem,
        channel: orderItem.displayChannel,
        formattedOrderedAt: format(new Date(orderItem.orderedAt), 'yyyy-MM-dd'),
        companyName: orderItem.brand?.company?.companyKrName || '',
        brandName: orderItem.brand?.brandKrName || '',
        itemName: orderItem.productName,
        totalPrice: orderItem.totalPrice,
        price: orderItem.price,
        originalPrice: orderItem.originalPrice,
        voucher: orderItem.voucher,
        gspcs: orderItem.gspc,
      })) || [],
    total: data?.orderDetails.total || 0,
    footer: [
      {
        count: data?.orderDetails?.summary.totalCount,
        totalPrice: data?.orderDetails?.summary.totalPrice,
      },
    ],
    isLoading,
  };
};
