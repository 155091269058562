import { ProductInfoLocaleType } from '@/locale/data/types/productInfoLocale.type';

export const enProductInfo: ProductInfoLocaleType = {
  rootMenu: 'Product information',
  listPage: {
    title: 'Product list',
  },
  managementPage: {
    title: 'Product management',
  },
  createPage: {
    title: 'Product registration',
  },
  groupPage: {
    title: 'Product group edit',
  },
  title: {
    uploadBulkCreate: 'Product registration in all',
    uploadBulkUpdate: 'Product edit in all',
    uploadBulkSuccess: 'Product upload in all completed',
    logisticsRegistrationTitle: 'Register logistics information',
    priceRegistrationTitle: 'Register price information',
    detailRegistrationTitle: 'Register additional information',
    basicRegistrationTitle: 'Register basic information',
    productGroupEdit: 'Edit product group',
    deleteProduct: 'Delete product group',
  },
  message: {
    emptyValue: 'Necessary value',
    emptyBarcode: 'Barcode is missing.',
    importLicenseNameEmpty: 'Input product name based on the Certificate of Free Sale.',
    importExcelDataEmpty: 'Data unavailable to upload',
    importExcelSheetEmpty:
      "Excel unavailable to upload. Please refer to Excel's sheet name.",
    duplicateProduct: 'Duplicate product',
    duplicateBarcode: 'Duplicate barcode',
    alreadyExistsBarcode: 'Duplicate barcode',
    duplicateSupplierCode: 'Duplicate supplier product code',
    barcodeEmpty: 'Input a barcode',
    supplierProductCodeEmpty: 'Input supplier product code',
    hsCodeEmpty: 'Input HS-Code',
    outerBoxCountEmpty: '외박스 수량을 입력해주세요.',
    innerBoxCountEmpty: '패키지 수량을 입력해주세요.',
    productUnitEmpty: 'Input product unit',
    productNetWeightEmpty: 'Input product net weight.',
    productTotalWeightEmpty: 'Input product gross weight.',
    outerBoxIntakeEmpty: 'Input product quantity per outer box.',
    outerBoxTotalWeightEmpty: 'Input outer box gross weight.',
    outerBoxStandardSizeEmpty: 'Input outer box size.',
    palletLoadCapacityEmpty: 'Input product quantity on the first floor of a pallet.',
    palletTotalCountEmpty: 'Input pallet height',
    palletIntakeEmpty: 'Input product quantity per pallet',
    purchaseUnitPriceEmpty: 'Input supply price',
    retailPriceKrEmpty: 'Input retail price',
    retailPriceVnEmpty: 'Input retail price (VN)',
    regularSalePriceVnEmpty: 'Input normal sales price (VN)',
    discountSalePriceVnEmpty: 'Input discounted sales price (VND)',
    retailPriceThEmpty: 'Input retail price (TH)',
    regularSalePriceThEmpty: 'Input normal sales price (TH)',
    discountSalePriceThEmpty: 'Input discounted sales price (THB)',
    manufacturerKrNameEmpty: 'Input manufacturer name (KR)',
    manufacturerNameEmpty: 'Input manufacturer name (EN)',
    manufacturerKrAddressEmpty: 'Input manufacturer address (KR)',
    manufacturerAddressEmpty: 'Input manufacturer address (EN)',
    importLicenseNameVnEmpty:
      'Input the product name (VN) on Certificate of Free Sale (CFS)',
    importLicenseNameThEmpty:
      'Input the product name (TH) on Certificate of Free Sale (CFS)',
    uploadWarning1: 'Product registration (in all) is for new products.',
    uploadWarning2: 'Unable to edit products missing basic information.',
    uploadWarning3: '(GSPC required)',
    productGroupEditConfirm1: 'Edit the product group information?',
    productGroupEditConfirm2: 'All changes will be applied to products.',
    apply: 'Applied',
    productTableEmptyWarning1: 'No registered information',
    productTableEmptyWarning2: 'Add information',
    productGroupNameEmpty: 'Input product group name (EN)',
    productGroupKrNameEmpty: 'Input product group name (KR)',
    brandEmpty: 'Select a brand',
    partnerEmpty: 'Select a partner',
    optionEmpty: 'Select options',
    optionValueEmpty: 'Input option values',
    productOptionHelp:
      'Unable to edit the option list once product group has been generated.',
    tableInputHelp:
      '*The option list has been generated combining the option values. Please remove false products.',
    productOptionInputHelp:
      '* Seprate option name (KR) and option name (EN) with a slash(/). Press Enter to input more than two option values per category.',
    productOptionInputHelp2: 'Example: #21N1 바닐라/#21N1 Vanilla',
    discountSalePriceHelp:
      '* Highest discount rate (in order): S-list → A-list → B-list → Normal sales price',
    tableNoDataMessage1: 'No registered information',
    deleteProduct: 'Delete the product?',
    tableNoDataMessage2: 'Register a product',
    deleteProducts: '{{count}} product list(s) will be deleted.',
    requestContent:
      'Request for review for [{{count}}] product(s) into [{{productGroupName}}]?',
    applyContent:
      'Register [{{count}}] products into [{{productGroupName}}]? It will be updated into the product list.',
    registration: 'Register the information?',
    registration2: 'It will be updated into the product list.',
    sameProductGroupKrName: 'Product group name already exists',
    priceInfo1: 'VAT excluded: Supply price',
    priceInfo2: 'VAT included: Retail price, normal sales price, discounted sales price',
    priceInfo3:
      'Discounted sales rrice : Highest discount rate (in order) S-list → A-list → B-list → Normal sales price',
    registrationSuccess: 'Registered successfully',
    registrationFail: 'Registration failed',
    requestSuccess: 'Requested',
    requestFail: 'Request failed',
    saveSuccess: 'Saved successfully',
    saveFail: 'Save failed',
  },
  label: {
    productKrName: 'Product name (KR)',
    productName: 'Product name (EN)',
    brand: 'Brand',
    partner: 'Partner',
    addProduct: 'Product registration',
    addProduct2: 'Add a product',
    productGroupKrName: 'Product group name (KR)',
    productGroupName: 'Product group name (EN)',
    gspc: 'GSPC',
    barcode: 'Barcode',
    hsCode: 'HS-Code',
    outerBoxCount: 'Outer box count',
    innerBoxCount: 'Inner box count',
    productUnit: 'Product unit',
    productNetWeight: 'Product net weight',
    productTotalWeight: 'Product gross weight',
    outerBoxIntake: 'Product quantity per outer box',
    outerBoxTotalWeight: 'Outer box gross weight',
    outerBoxStandardSize: 'Outer box size',
    palletLoadCapacity: 'Product quantity on the first floor of a pallet',
    palletTotalCount: 'Pallet height',
    palletIntake: 'Product quantity per pallet',
    purchaseUnitPrice: 'Supply price',
    retailPriceKr: 'Retail price (KRW)',
    retailPriceVn: 'Retail price (VND)',
    regularSalePriceVn: 'Normal sales price (VND)',
    discountSalePriceVn: 'Discounted sales price (VND)',
    retailPriceTh: 'Retail price (THB)',
    regularSalePriceTh: 'Normal sales price (THB)',
    discountSalePriceTh: 'Discounted sales price (THB)',
    manufacturerKrName: 'Manufacturer name (KR)',
    manufacturerName: 'Manufacturer name (EN)',
    manufacturerKrAddress: 'Manufacturer address (KR)',
    manufacturerAddress: 'Manufacturer address (EN)',
    importLicenseNameVn: 'Product name (VN) on Certificate of Free Sale (CFS)',
    importLicenseNameTh: 'Product name (TH) on Certificate of Free Sale (CFS)',
    discountSalePriceS: 'S-list',
    discountSalePriceA: 'A-list',
    discountSalePriceB: 'B-list',
    width: 'Width',
    vertical: 'Depth',
    height: 'Height',
    supplierProductCode: 'Supplier product code',
    uploadBulkCreate: 'Product registration in all',
    uploadBulkUpdate: 'Product edit in all',
    groupInfo: 'Product group information',
    groupOriginalInfo: 'Existing information',
    groupEditInfo: 'New information',
    optionInfo: 'Product option',
    optionValue: 'Option value',
    optionList: 'Option list',
    productList: 'Product list',
    deleteCheckedProducts: 'Delete selected',
    optionSubmit: 'Apply options',
    option: 'Option',
    type: 'Type',
    scent: 'Fragrance',
    color: 'Color',
    taste: 'Flavor',
    size: 'Size',
    count: 'Volume/Weight/Quantity',
    apply: 'Apply',
  },
  placeholder: {
    manufacturerKrName: "Based on manufacturer's Certificate of Business Registration",
    importLicenseNameVn: 'Input after receiving an import license.',
    search: 'Search either product name, GSPC, or barcode.',
    gspc: 'Automatically generated upon product registration',
    productGroupKrName: 'Input product group name based on its pacakaging.',
    productGroupName:
      'Product group name based on its packaging (The first letter of every word must be capitalized, but exceptions exist (e.g., pH).)',
    brand: 'Select a brand',
    partner: 'Automatically shown upon selecting a brand',
    options: {
      type: 'Product type (Not otherwise specified) (Example: 리필 / Refill, 남산 AP-1009CH / NAMSAN AP-1009CH)',
      scent:
        "Product's fragrance (Example: 체리 블라썸 / Cherry Blossom, 코랄(장미) / Coral(Rose))",
      color: "Product's color (Example: #01 윈썸 / #01 Winsome, 25Y / 25Y )",
      taste: "Product's flavor (Example: 우유 / Milk, 오리지널 / Original)",
      size: "Product's size (Example: 스몰 / Small, 11 인치 / 11 Inch)",
      count: "Product's volume/weight/quantity (Example: 100ml/100ml, 20g*5E/20g*5E)",
    },
  },
  table: {
    productGroupName: 'Product group name',
    productName: 'Product name',
    gspc: 'GSPC',
    barcode: 'Barcode',
    brandName: 'Brand',
    productType: 'Request type',
  },
};
