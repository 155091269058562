import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';

import { DefaultLayout } from '@/component';
import { ContentHeader } from '@/views/order/detail/ContentHeader';
import { List } from '@/views/order/detail/List';

export const OrderDetailPage = () => {
  const { t } = useTranslation(['orderInfo']);

  return (
    <DefaultLayout>
      <DefaultLayout.Header>
        <Breadcrumb>
          <Breadcrumb.Item>{t('orderInfo:rootMenu')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('orderInfo:detailPage.title')}</Breadcrumb.Item>
        </Breadcrumb>
      </DefaultLayout.Header>
      <DefaultLayout.Content>
        <ContentHeader />
        <List />
      </DefaultLayout.Content>
    </DefaultLayout>
  );
};
