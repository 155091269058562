export const modalStyle = {
  /** form item style */
  formItemLayout: {
    labelCol: {
      xs: { span: 23 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  },
  /** form item layout style */
  formItemLayoutWithOutLabel: {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 5 },
    },
  },
};
