import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import {
  B2bOrderQuery,
  B2bOrderStatusEnum,
  useB2bOrderQuery,
} from '@/generated/graphql-react-query';
import { PATH } from '@/routes';

export const useGetB2bOrderStatusHook = (
  id: string,
  options?: UseQueryOptions<B2bOrderQuery, unknown, B2bOrderStatusEnum, QueryKey>,
) => {
  const history = useHistory();
  const { data, refetch, isLoading, ...rest } = useB2bOrderQuery(
    { b2BOrderId: id },
    {
      enabled: !!id,
      select: ({ b2bOrder }) => b2bOrder.status,
      onSuccess: (data) => redirectOrderPageBtStatus(data),
      ...options,
    },
  );

  const getOrderPath = (path: string) => {
    const orderPath = path.split('/');
    orderPath.splice(3, 1, id);

    return orderPath.join('/');
  };

  const redirectOrderPageBtStatus = (orderStatus: B2bOrderStatusEnum) => {
    switch (orderStatus) {
      case B2bOrderStatusEnum.OrderReady:
        if (history.location.pathname !== PATH.ORDER_CREATE) {
          history.replace(getOrderPath(PATH.ORDER_CREATE));
        }
        break;
      case B2bOrderStatusEnum.OrderCreate:
      case B2bOrderStatusEnum.OrderRequestConfirm:
        if (history.location.pathname !== PATH.ORDER_CHECK) {
          history.replace(getOrderPath(PATH.ORDER_CHECK));
        }
        break;
      case B2bOrderStatusEnum.PendingDeposit:
      case B2bOrderStatusEnum.Done:
        if (history.location.pathname !== PATH.ORDER_DETAIL) {
          history.replace(getOrderPath(PATH.ORDER_DETAIL));
        }
        break;
      default:
    }
  };

  return {
    b2bOrderStatus: data,
    redirectOrderPageBtStatus,
    refetchB2bOrderStatus: refetch,
    isB2bOrderStatusLoading: isLoading,
    ...rest,
  };
};
