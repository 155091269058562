import { Form, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommentHook } from '@/hooks/importLicensing/details/useCommentHook';
import { CommentList } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/commentArea/CommentList';

interface CommentAreaProps {
  fileId: string;
}

export const CommentArea = ({ fileId }: CommentAreaProps) => {
  const { t } = useTranslation(['importLicensingInfo']);
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleSearch = (value: string) => {
    setSearchKeyword(value);
  };

  const { commentList, total } = useCommentHook({
    fileId,
    keyword: searchKeyword,
  });

  return (
    <div className='lg:gm-w-[20%] lg:gm-max-w-xs gm-min-w-[15rem] gm-p-4 gm-bg-gray-200'>
      <Form className='gm-flex gm-flex-col gm-items-end'>
        <Input.Search
          defaultValue={searchKeyword}
          placeholder={t('importLicensingInfo:fileViewerPage.placeholder.searchComment')}
          onSearch={handleSearch}
          allowClear
        />
      </Form>
      <CommentList total={total} commentList={commentList} />
    </div>
  );
};
