import styled from '@emotion/styled';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { OrderChannelEnum } from '@/generated/graphql-react-query';
import { convertNumberFormat } from '@/lib/convertIntlFormat';

const TooltipWrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  margin: 0;
  padding: 1rem;
  & > .label {
    padding-bottom: 12px;
    border-bottom: 1px solid #d9d9d9;
  }
`;

const TooltipContents = styled.div`
  min-width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;

const TooltipChannelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-right: 12px;
`;

const TooltipText = styled.p`
  margin: 0;
  padding: 0;
`;

interface TooltipColorBoxProps {}

const TooltipColorBox = styled.div<TooltipColorBoxProps>`
  width: 12px;
  height: 12px;
  margin: 0;
  border: none;
`;

export const ChartTooltip = ({
  active,
  payload,
  label,
  channelList,
}: TooltipProps<ValueType, NameType> & {
  channelList: { channelName: OrderChannelEnum; displayName: string }[];
}) => {
  const values = payload
    ? payload.map((p) => {
        return p.dataKey && p.value ? Number(p.value) : 0;
      })
    : [];
  const sum = values.reduce((acc, curr) => acc + curr, 0);

  if (active) {
    return (
      <TooltipWrapper>
        <p className='label'>{label}</p>
        {payload?.map((p) => (
          <div key={p.dataKey}>
            {p.dataKey && (
              <TooltipContents>
                <TooltipChannelWrapper>
                  <TooltipColorBox />
                  <span>
                    {channelList.find((ch) => ch.channelName === p.dataKey)
                      ?.displayName || p.dataKey}
                  </span>
                </TooltipChannelWrapper>
                <span>{convertNumberFormat('ko', Number(p.value))}</span>
              </TooltipContents>
            )}
          </div>
        ))}
        <TooltipContents>
          <TooltipText>합계</TooltipText>
          <TooltipText>{convertNumberFormat('ko', sum)}</TooltipText>
        </TooltipContents>
      </TooltipWrapper>
    );
  }

  return null;
};
