import { message } from 'antd/es';

import {
  useExportB2bOrderDetailExcelMutation,
  useUploadB2bOrderDetailsMutation,
} from '@/generated/graphql-react-query';

export const useUploadB2bOrderDetailsHook = () => {
  const { mutateAsync, isLoading } = useExportB2bOrderDetailExcelMutation();
  const { mutateAsync: uploadMutateAsync, isLoading: uploadLoading } =
    useUploadB2bOrderDetailsMutation();

  const download = async (id: string) => {
    try {
      const result = await mutateAsync({
        exportB2BOrderDetailExcelId: id,
      });

      window.open(result.exportB2bOrderDetailExcel);
    } catch (error: unknown) {
      message.warning((error as Error).message);
    }
  };

  const upload = async (id: string, file: any) => {
    try {
      await uploadMutateAsync({
        uploadB2BOrderDetailsId: id,
        file,
      });

      message.success('주문 상세 정보를 업로드하였습니다.');
    } catch (error: unknown) {
      message.warning((error as Error).message);
    }
  };

  return {
    download: {
      mutate: download,
      isLoading,
    },
    upload: {
      mutate: upload,
      isLoading: uploadLoading,
    },
  };
};
