import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { Button } from '@gomi/component';
import { dates } from '@gomi/utils';
import i18next from 'i18next';

import { ImportLicensingDocumentStatusEnum } from '@/generated/graphql-react-query';
import { FileStatusRenderer } from '@/views/importLicensing/importLicensingDetail/common/FileStatusRenderer';

interface IListColumn {
  setFile(fileId: string): void;
  downloadFile(id: string): void;
  t: any;
}

const getStatusFormat = ({ value }: { value: ImportLicensingDocumentStatusEnum }) => {
  const statusFormat = {
    [ImportLicensingDocumentStatusEnum.Ready]: i18next.t(
      'importLicensingInfo:detailPage.status.ready',
    ),
    [ImportLicensingDocumentStatusEnum.ReadyDone]: i18next.t(
      'importLicensingInfo:detailPage.status.readyDone',
    ),
    [ImportLicensingDocumentStatusEnum.RequestConfirm]: i18next.t(
      'importLicensingInfo:detailPage.status.requestConfirm',
    ),
    [ImportLicensingDocumentStatusEnum.RequestEdit]: i18next.t(
      'importLicensingInfo:detailPage.status.requestEdit',
    ),
    [ImportLicensingDocumentStatusEnum.Done]: i18next.t(
      'importLicensingInfo:detailPage.status.done',
    ),
  };

  return statusFormat[value as ImportLicensingDocumentStatusEnum];
};

/** list column */
export const ListColumn = ({
  setFile,
  downloadFile,
  t,
}: IListColumn): ColDef[] | ColGroupDef[] => {
  const convertYyyyMMdd = (params: ValueFormatterParams) =>
    params.value ? dates.getFormatYYYYMMDD(params.value) : '';

  return [
    {
      headerName: t('importLicensingInfo:table.documentName'),
      field: 'documentName',
      cellClass: 'gm-flex gm-items-center',
      cellStyle: { textAlign: 'left' },
      width: 240,
      minWidth: 240,
      flex: 1,
      cellRenderer: ({ value, data }: ICellRendererParams<{ id: string }, string>) => {
        return (
          <span className='gm-cursor-pointer' onClick={() => setFile(String(data?.id))}>
            {value}
          </span>
        );
      },
    },
    {
      headerName: t('importLicensingInfo:table.createdAt'),
      field: 'createdAt',
      cellClass: 'gm-flex gm-items-center',
      width: 120,
      valueFormatter: convertYyyyMMdd,
    },
    {
      headerName: t('importLicensingInfo:table.doneAt'),
      field: 'completedAt',
      cellClass: 'gm-flex gm-items-center',
      width: 120,
      valueFormatter: convertYyyyMMdd,
    },
    {
      headerName: t('importLicensingInfo:table.status'),
      field: 'status',
      cellClass: 'gm-flex gm-items-center',
      valueFormatter: getStatusFormat,
      cellRenderer: FileStatusRenderer,
      width: 100,
    },
    {
      headerName: '',
      field: '',
      width: 130,
      cellClass: 'gm-flex gm-items-center gm-justify-center',
      cellRenderer: (params: ICellRendererParams) => (
        <div>
          {/* 상세 버튼 */}
          <Button onClick={() => downloadFile(params.data.id)}>
            {t('importLicensingInfo:table.download')}
          </Button>
        </div>
      ),
    },
  ];
};
