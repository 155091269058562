import { useState } from 'react';

import { CommentArea } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/commentArea';
import {
  FileFormat,
  FileInfo,
  FileList,
} from '@/views/importLicensing/importLicensingDetail/common/fileViewer/fileList';
import { ImageViewer } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/imageViewer';
import { PdfViewer } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/pdfViewer';

interface IFileViewerProps {
  files: FileInfo[];
}

export const FileViewer = ({ files }: IFileViewerProps) => {
  const [visibleFile, setVisibleFile] = useState<FileInfo>(files[0] || {});

  const changeVisibleFile = (fileInfo: FileInfo) => {
    setVisibleFile(fileInfo);
  };

  const getVisibleFileViewer = (format: string) => {
    switch (format) {
      case FileFormat.PDF:
        return <PdfViewer url={visibleFile.url} />;
      case FileFormat.JPG:
        return <ImageViewer url={visibleFile.url} />;
      case FileFormat.JPEG:
        return <ImageViewer url={visibleFile.url} />;
      case FileFormat.PNG:
        return <ImageViewer url={visibleFile.url} />;
      default:
        return null;
    }
  };
  const visibleFileViewer = getVisibleFileViewer(visibleFile.format);

  return (
    <div className='lg:gm-flex lg:gm-justify-between lg:gm-h-[calc(100vh-65px)]'>
      <FileList
        visibleFileId={visibleFile.id}
        changeVisibleFile={changeVisibleFile}
        files={files}
      />
      <div className='gm-relative gm-flex-grow lg:gm-w-[66%] gm-bg-gray-800 gm-overflow-auto'>
        {visibleFileViewer}
      </div>
      <CommentArea fileId={visibleFile.id} />
    </div>
  );
};
