import { ExclamationCircleOutlined } from '@ant-design/icons';
import i18next from 'i18next';

export const ProductTableNoData = () => {
  return (
    <div style={{ color: '#BFBFBF' }}>
      <div style={{ borderBottom: '8px' }}>
        <ExclamationCircleOutlined style={{ fontSize: '40px' }} />
      </div>
      <br />
      {i18next.t('productInfo:message.tableNoDataMessage1')}
      <br />
      {i18next.t('productInfo:message.tableNoDataMessage2')}
    </div>
  );
};
