import { Button } from '@gomi/component';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useImportLicensingHook } from '@/hooks/importLicensing/details/useImportLicensingHook';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';
import { MAX_STEPS } from '@/views/importLicensing/importLicensingDetail/Steps';

export const ControlBar = observer(({ children }) => {
  const {
    importLicensingDetailModel: { getPrev, getNext, setCurrent, steps },
  } = useStore();
  const { importLicensingId } = useParams<IParams>();
  const { t } = useTranslation(['importLicensingInfo']);
  const history = useHistory();
  const next = getNext();
  const prev = getPrev();

  const { stepStatuses, isValidNextStep } = useImportLicensingHook({
    importLicensingId,
  });

  const handlePrev = () => {
    setCurrent(prev, stepStatuses[prev]);
    history.replace(`${history.location.pathname}?current=${prev}`);
  };

  const handleNext = () => {
    setCurrent(next, stepStatuses[next]);
    history.replace(`${history.location.pathname}?current=${next}`);
  };

  const isMaximumStep = steps.current >= MAX_STEPS - 1;
  const disabledNext = isMaximumStep || !isValidNextStep(steps.current);

  return (
    <div className='gm-mt-12 gm-flex gm-justify-between gm-gap-1'>
      <div>
        <Button isDisabled={steps.current <= 0} onClick={handlePrev}>
          {t('importLicensingInfo:detailPage.controlBar.previousButton')}
        </Button>
      </div>
      <div className='gm-flex gm-gap-1'>
        {children}
        <Button isDisabled={disabledNext} onClick={handleNext}>
          {t('importLicensingInfo:detailPage.controlBar.nextButton')}
        </Button>
      </div>
    </div>
  );
});
