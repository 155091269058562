import { BrandInfoLocale } from '@/locale/data/types/brandInfoLocale.type';

export const enBrandInfo: BrandInfoLocale = {
  rootMenu: 'Brand information',
  listPage: {
    title: 'Brand list',
  },
  managementPage: {
    title: 'Brand management',
  },
  control: {
    searchPlaceHolder: 'Search brand code or brand name',
  },
  modal: {
    title: {
      registrationTitle: 'Brand registration',
      uploadBulk: 'Brand registration (in all)',
    },
    message: {
      emptyCompanyName:
        '"Partner name (KR), Partner name (EN), & Partner"are required when saving temporarily.',
      exportFailContent1: 'Unable to find downloadable brand information',
      exportFailContent2: 'Register a brand',
      uploadWarning1: 'Brand registration (in all) is for new brands.',
      tableNoDataMessage1: 'Unable to find registered brands',
      tableNoDataMessage2: 'Register a brand',
      noSpecialCharacter: 'Special characters are not permitted for "Brand name".',
      noUseSpace: 'Spacing is not permitted for "Brand name"',
      notUseKorean: 'Input only English for "Brand name (EN)".',
      noSingleQuote: '"Apostrophe(\') is not permitted."',
      onlyCapitalEnglish: '"English name must be all capitalized."',
      registration:
        'Register brand information?\nIt will be updated into the brand list.',
      noEdit:
        'Unable to edit currently non-operating brands. Please contact the help center.',
    },
    label: {
      brandId: 'Brand ID',
      brandCode: 'Brand code',
      brandKrName: 'Brand name (KR)',
      brandName: 'Brand name (EN)',
      countries: 'Target country',
      logo: 'Logo',
      consignment: 'Consignment',
      purchase: 'Buying',
      partner: 'Partner',
      partnerCode: 'Partner code',
    },
    placeholder: {
      noSpaceAndNoSpecialCharEn:
        "It must be all capitalized. Apostrophe(') is not permitted.",
      noSpaceAndNoSpecialChar: "Apostrophe(') is not permitted.",
      brandCode: 'Automatically generated upon brand registration',
      countries: 'Select target country',
      partner: 'Select a partner',
    },
    button: {
      addBrand: 'Brand registration',
      uploadBulk: 'Brand registration (in all)',
    },
  },
  table: {
    brandCode: 'Brand code',
    brandName: 'Brand name (KR/EN)',
    companyName: 'Partner',
  },
};
