import styled from '@emotion/styled';
import { Card, Empty, ExcelButton, LoggingClick } from '@gomi/component';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import tw from 'twin.macro';

import { TRACK_EVENT_NAME } from '@/constant/track';
import { ReportExportExcelEnum } from '@/generated/graphql-react-query';
import { useReportDownloadHook } from '@/hooks/report/useReportDownloadHook';
import { useSalesStatusHook } from '@/hooks/report/useSalesStatusHook';
import { useStore } from '@/stores';
import { ChartTooltip } from '@/views/report/sales/salesStatusChart/ChartTooltip';
import { composedChartConfig } from '@/views/report/sales/salesStatusChart/SalesStatusChartConfig';

export const ReportWrapper = styled.div`
  .card-article {
    ${tw`gm-h-[25rem] gm-flex gm-justify-center gm-items-center`}
  }
`;

const RenderLineTotalPriceLabel = ({ x, y, value }: any) => {
  if (typeof value === 'string') {
    return null;
  }

  if (!value || value === 0) {
    return null;
  }

  return (
    <text x={x} y={(y as number) - 10} style={{ fontSize: '.6rem' }} fill='#000000bf'>
      {(value as number).toLocaleString()}
    </text>
  );
};

const RenderBarCountLabel = ({ x, y, height, width, value }: any) => {
  if (typeof value === 'string') {
    return null;
  }

  if (!value || value === 0) {
    return null;
  }

  const valueLength = `${value}`.length;
  const textWidth = 6; // 한 글자당 width
  const barWidth = width as number;
  const valueWidth = valueLength * textWidth;
  const space =
    barWidth < valueWidth ? (valueWidth - barWidth) / 2 : (barWidth - valueWidth) / 2;

  return (
    <text
      x={(x as number) + space}
      y={(y as number) + (height as number) / 2}
      style={{ fontSize: '.6rem' }}
      fill='#000000bf'
    >
      {value > 1000 ? `${(value / 1000).toFixed(2)}K` : value}
    </text>
  );
};

const FormatLegendText = (value: string) => {
  return (
    <span
      className='gm-text-xs'
      style={{ color: composedChartConfig.chartData[value].legendTextColor }}
    >
      {composedChartConfig.chartData[value].legendName}
    </span>
  );
};

export const SalesStatusChart = observer(() => {
  const { t } = useTranslation();

  const {
    reportControlModel: {
      countryId,
      brandId,
      salesCode,
      startDate,
      endDate,
      orderStatus,
      paymentStatus,
      shippingStatus,
      channel,
      gift,
    },
  } = useStore();

  const { salesStatus } = useSalesStatusHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
  });

  /* 판매 현황 엑셀 다운로드 */
  const { download, isLoading: isDownloadLoading } = useReportDownloadHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
  });

  const handleDownloadExcel = () => {
    download(ReportExportExcelEnum.SalesStatus);
  };

  return (
    <ReportWrapper>
      <Spin spinning={!!salesStatus?.isLoading}>
        <Card
          header={
            <div className='gm-flex gm-justify-between gm-items-center'>
              {t('report:chart.salesStatus')}
              <LoggingClick eventName={TRACK_EVENT_NAME.REPORT_SALES_STATUS_DOWNLOAD}>
                <ExcelButton
                  type='button'
                  loading={isDownloadLoading}
                  onClick={handleDownloadExcel}
                  isDisabled={!salesStatus.list.length || isDownloadLoading}
                >
                  {t('common:common.button.download')}
                </ExcelButton>
              </LoggingClick>
            </div>
          }
        >
          {!salesStatus.list.length ? (
            <Empty onShowImage>No Data</Empty>
          ) : (
            <ResponsiveContainer width='100%' height='100%'>
              <ComposedChart
                width={500}
                height={400}
                data={salesStatus.list}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke={composedChartConfig.gridColor} />
                <XAxis
                  dataKey={composedChartConfig.xAxis.dataKey}
                  type={composedChartConfig.xAxis.type}
                  fontSize={10}
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={composedChartConfig.xAxis.tickFormatter}
                />
                {composedChartConfig.yAxis.map((yAxis) => (
                  <YAxis
                    key={yAxis.dataKey}
                    dataKey={yAxis.dataKey}
                    type={yAxis.type}
                    fontSize={10}
                    yAxisId={yAxis.yAxisId}
                    axisLine={false}
                    tickLine={false}
                    orientation={yAxis.orientation}
                    tickFormatter={yAxis.tickFormatter}
                  />
                ))}
                <Tooltip
                  content={<ChartTooltip chartConfig={composedChartConfig.chartData} />}
                />
                <Legend iconSize={12} fontSize={14} formatter={FormatLegendText} />
                {Object.values(composedChartConfig.chartData).map((chart) => {
                  switch (chart.chartType) {
                    case 'Bar':
                      return (
                        <Bar
                          key={chart.dataKey}
                          dataKey={chart.dataKey}
                          fill={chart.color}
                          barSize={32}
                          stackId={chart.stackId}
                          yAxisId={chart.yAxisId}
                        >
                          {chart.dataKey === 'OK' && (
                            <LabelList
                              dataKey='OK'
                              content={RenderBarCountLabel}
                              position='top'
                            />
                          )}
                          {chart.dataKey === 'CANCEL' && (
                            <LabelList
                              dataKey='CANCEL'
                              content={RenderBarCountLabel}
                              position='top'
                            />
                          )}
                        </Bar>
                      );
                    case 'Line':
                      return (
                        <Line
                          key={chart.dataKey}
                          dataKey={chart.dataKey}
                          type='monotone'
                          stroke={chart.color}
                          yAxisId={chart.yAxisId}
                          dot={{ strokeWidth: 2, fill: chart.color }}
                          strokeWidth={2}
                        >
                          <LabelList
                            dataKey='TOTAL_AMOUNT'
                            content={RenderLineTotalPriceLabel}
                            position='top'
                          />
                        </Line>
                      );
                    default:
                      return <div key='empty' />;
                  }
                })}
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </Card>
      </Spin>
    </ReportWrapper>
  );
});
