import { ICellRendererParams } from '@ag-grid-community/core';
import { InputNumber } from 'antd';
import { TFunction } from 'react-i18next';

import { InventoryQuantity } from '@/hooks/order/check/useUpdateB2bInventoryQuantitiesHook';
import { B2bOrderDetail } from '@/hooks/order/common/useGetB2bOrderHook';

interface IListColumnProps {
  t: TFunction<'orderInfo'[]>;
  b2bInventoryQuantities: InventoryQuantity[];
  onInventoryQuantityChange({
    index,
    newInventoryQuantity,
  }: {
    index: number;
    newInventoryQuantity: InventoryQuantity;
  }): void;
}

export const getListColumn = ({
  t,
  b2bInventoryQuantities,
  onInventoryQuantityChange,
}: IListColumnProps) => {
  return [
    {
      headerName: t('orderInfo:createPage.label.productName'),
      field: 'productName',
      cellStyle: { textAlign: 'left' },
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: t('orderInfo:createPage.label.barcode'),
      field: 'barcode',
      width: 150,
    },
    {
      headerName: t('orderInfo:createPage.label.innerBoxQuantity'),
      field: 'innerBoxQuantity',
      width: 100,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.innerBoxQuantity.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.outerBoxQuantity'),
      field: 'outerBoxQuantity',
      width: 100,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.outerBoxQuantity.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.retailPrice'),
      field: 'retailPrice',
      width: 125,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.retailPrice.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.purchaseUnitPrice'),
      field: 'purchaseUnitPrice',
      width: 125,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.purchaseUnitPrice.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.orderQuantity'),
      field: 'orderQuantity',
      width: 120,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrderDetail>) =>
        data?.orderQuantity.toLocaleString(),
    },
    {
      headerName: t('orderInfo:createPage.label.inventoryQuantity'),
      field: 'inventoryQuantity',
      width: 120,
      cellRenderer: ({ data, rowIndex }: ICellRendererParams<B2bOrderDetail>) =>
        data && (
          <InputNumber
            onBlur={({ target }) => {
              const value = parseInt(target.value.replace(',', ''), 10);
              onInventoryQuantityChange({
                index: rowIndex,
                newInventoryQuantity: {
                  id: data.id,
                  inventoryQuantity: Number(value),
                  totalPrice: Number(value) * data.purchaseUnitPrice,
                },
              });
            }}
            onStep={(value) => {
              onInventoryQuantityChange({
                index: rowIndex,
                newInventoryQuantity: {
                  id: data.id,
                  inventoryQuantity: value,
                  totalPrice: value * data.purchaseUnitPrice,
                },
              });
            }}
            min={0}
            max={data.orderQuantity}
            value={b2bInventoryQuantities[rowIndex].inventoryQuantity}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        ),
    },
    {
      headerName: t('orderInfo:createPage.label.totalPrice'),
      field: 'totalPrice',
      width: 140,
      cellRenderer: ({ rowIndex }: ICellRendererParams<B2bOrderDetail>) =>
        b2bInventoryQuantities[rowIndex].totalPrice.toLocaleString(),
    },
  ];
};
