import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  CellClickedEvent,
  ColDef,
  GridReadyEvent,
  Module,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import styled from '@emotion/styled';
import { Pagination, Spin } from 'antd';
import { ReactNode, useMemo } from 'react';

import { convertNumberFormat } from '@/lib/convertIntlFormat';

interface IAgTableProps {
  column: ColDef[];
  columnTypes?: { [key: string]: ColDef };
  defaultColDef?: ColDef;
  loading: boolean;
  noRowMessage?: string;
  suppressRowTransform?: boolean;
  limit?: number;
  rowData?: any;
  rowToFit?: boolean;
  tableHeight?: string;
  theme?: 'ag-theme-material' | 'ag-theme-balham' | 'ag-theme-alpine';
  pinnedBottomRowData?: any[];
  paginationProps?: {
    totalCount: number;
    currentPage: number;
    onChangePage?: (value: number, size: number) => void;
    pageSize?: number;
  };
  modules?: Module[];
  noRowsOverlayComponent?: ReactNode;
}

const AgGridContainer = styled.div<{ height?: string }>`
  height: ${(props) => props.height || '35rem'};
`;

const PaginationCenter = styled(Pagination)`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

export const AgTable = ({
  column,
  suppressRowTransform = false,
  columnTypes,
  defaultColDef,
  loading,
  noRowMessage,
  limit,
  rowData,
  rowToFit,
  tableHeight,
  theme = 'ag-theme-alpine',
  pinnedBottomRowData,
  paginationProps,
  modules,
}: IAgTableProps) => {
  /** ag-grid modules */
  const agModules = useMemo(
    () => [
      ClientSideRowModelModule,
      RowGroupingModule,
      ClipboardModule,
      RangeSelectionModule,
      ...(modules ?? []),
    ],
    [],
  );

  const gridOption = {
    onGridReady(event: GridReadyEvent) {
      return rowToFit && event.api.sizeColumnsToFit();
    },
  };

  const defaultColumnTypes = useMemo<{ [key: string]: ColDef }>(() => {
    return {
      numberColumn: {
        cellStyle: { textAlign: 'right' },
        valueFormatter: (param) => {
          if (!param.value) return '';
          return convertNumberFormat('ko', param.value);
        },
      },
    };
  }, []);

  return (
    <Spin spinning={loading}>
      <AgGridContainer height={tableHeight} className={theme}>
        <AgGridReact
          modules={agModules}
          suppressRowTransform={suppressRowTransform}
          gridOptions={gridOption}
          defaultColDef={defaultColDef}
          columnDefs={column}
          columnTypes={{ ...defaultColumnTypes, ...columnTypes }}
          rowData={rowData}
          rowSelection='multiple'
          paginationPageSize={limit}
          overlayNoRowsTemplate={noRowMessage}
          pinnedBottomRowData={pinnedBottomRowData}
          rowHeight={37}
          tooltipShowDelay={0}
          enableRangeSelection
        />
      </AgGridContainer>
      {paginationProps && (
        <PaginationCenter
          onChange={paginationProps?.onChangePage}
          current={paginationProps?.currentPage}
          total={paginationProps?.totalCount}
          showSizeChanger={false}
        />
      )}
    </Spin>
  );
};
