import styled from '@emotion/styled';
import { ExcelButton, LoggingClick, SearchBar } from '@gomi/component';
import { Form, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { ALL } from '@/constant';
import { defaultOption } from '@/constant/common';
import { TRACK_EVENT_NAME } from '@/constant/track';
import { useFilteredBrandsByProductV2Query } from '@/generated/graphql-react-query';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';

/** style */
const Wrapper = styled.div`
  form {
    .ant-form-item {
      ${tw`gm-m-0`}
    }
  }
`;

/** props */
interface IPropsListHeader {
  /** 엑셀 다운로드 버튼 show 여부 */
  isShowExcelButton?: boolean;
  /** 엑셀다운로드중 여부 */
  isLoadingExcelDown?: boolean;
  /** 총 카운트 */
  totalCount: number;
  /** brand id 들 */
  brandIds?: string[];
  /** 엑셀 다운로드 */
  onClickExcelDown?: () => void;
  /** 키워드 검색시 */
  onSearch: (keyword: string) => void;
  /** 브랜드 변경시 */
  onChangeBrand?: (brandIds: string[]) => void;
}

/** component: 상품 목록, 상품관리카드의 검색 및 건수표현 영역 */
const ListHeader = ({
  isShowExcelButton = false,
  isLoadingExcelDown = false,
  totalCount = 0,
  brandIds = [ALL],
  onClickExcelDown,
  onSearch,
  onChangeBrand,
}: IPropsListHeader) => {
  const { t } = useTranslation(['partnerInfo', 'productInfo', 'common']);
  const { isMobile } = useMediaQueryHook();

  /** gql - 브랜드 목록 */
  const { data: dataBrandList } = useFilteredBrandsByProductV2Query(
    {},
    {
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  /** 브랜드선택 change 핸들러 */
  const handleChangeBrand = (values: string[]) => {
    const isClickAll = values[values.length - 1] === ALL;
    const isNotAll = !!values.find((i) => i !== ALL);
    let brandIds: string[] = [ALL];

    if (isNotAll && !isClickAll) {
      brandIds = values.filter((i) => i !== ALL);
    }

    onChangeBrand?.(brandIds);
  };

  return (
    <Wrapper className='gm-relative gm-flex gm-flex-col lg:gm-flex-row gm-justify-between lg:gm-items-center lg:gm-flex-nowrap md:gm-overflow-x-auto'>
      <div className='gm-min-w-24'>
        <span className='gm-mr-2'>{t('partnerInfo:control.title')}</span>
        <span>{t('partnerInfo:control.count', { count: totalCount })}</span>
      </div>
      <Form
        layout={isMobile ? 'horizontal' : 'inline'}
        className='md:gm-flex-nowrap gm-gap-4 md:gm-mt-2 lg:gm-mt-0 gm-font-normal'
      >
        <Form.Item label={t('productInfo:label.brand')} className='gm-my-4 md:gm-my-0'>
          <Select
            showSearch
            mode='multiple'
            value={brandIds}
            className='md:gm-w-52 gm-text-left'
            maxTagCount={1}
            filterOption={(input, option) => {
              return `${option?.children}`
                .toLowerCase()
                .includes(`${input}`.toLowerCase());
            }}
            onChange={handleChangeBrand}
          >
            <Select.Option value={defaultOption.value}>
              {defaultOption.title}
            </Select.Option>
            {dataBrandList?.filteredBrandsByProductV2?.map((brand) => (
              <Select.Option key={brand.id} value={brand.id}>
                {`${brand.brandKrName || brand.brandName} (${brand.brandCode || '-'})`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <SearchBar
            className='md:gm-w-72'
            onSearch={onSearch}
            placeholder={t('productInfo:placeholder.search')}
          />
        </Form.Item>

        {isShowExcelButton && onClickExcelDown && (
          <LoggingClick eventName={TRACK_EVENT_NAME.PRODUCT_DOWNLOAD_CLICK}>
            <ExcelButton
              onClick={onClickExcelDown}
              loading={isLoadingExcelDown}
              className='gm-absolute gm-top-0 gm-right-0 md:gm-static gm-w-fit'
            >
              {t('common:common.button.download')}
            </ExcelButton>
          </LoggingClick>
        )}
      </Form>
    </Wrapper>
  );
};

export { ListHeader };
