import styled from '@emotion/styled';
import { PageHeader, Space } from 'antd';
import { ReactNode } from 'react';

interface ContentHeaderProps {
  children?: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  onBack?: boolean;
}

const NoneSidePaddingPageHeader = styled(PageHeader)`
  padding: 0 0 1rem 0;
`;

export const ContentHeader = ({
  title,
  subtitle,
  children,
  onBack,
}: ContentHeaderProps) => (
  <NoneSidePaddingPageHeader
    title={title}
    subTitle={subtitle}
    onBack={onBack ? () => window.history.back() : undefined}
    extra={<Space size={10}>{children}</Space>}
  />
);
