import { AgTable, Card, Empty } from '@gomi/component';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useBrandListHook } from '@/hooks/brand/useBrandListHook';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { useStore } from '@/stores';
import { getListColumn } from '@/views/brand/common/getListColumn';
import { DetailModal } from '@/views/brand/list/DetailModal';
import { FilterControlBar } from '@/views/brand/list/FilterControlBar';

export const List = observer(() => {
  const {
    brandModel: { setVisibleModal },
  } = useStore();
  const { t } = useTranslation(['brandInfo', 'partnerInfo']);
  const { isPC } = useMediaQueryHook();
  const [brandId, setBrandId] = useState('');

  // 브랜드 정보 리스트
  const { getBrandListForTable, totalCount, paging, onChangePage, isLoading } =
    useBrandListHook();
  const brandListForTable = getBrandListForTable();

  const handleBrandNameClick = (id: string) => {
    setVisibleModal('detail');
    setBrandId(id);
  };

  const listColumn = getListColumn({
    t,
    onClick: handleBrandNameClick,
    isPC,
  });

  return (
    <Card
      className='gm-h-full'
      header={<FilterControlBar />}
      isContentsBackground={false}
    >
      <AgTable
        loading={isLoading}
        rowData={brandListForTable}
        columnDefs={listColumn}
        defaultColDef={{ resizable: true }}
        paginationProps={{
          totalCount,
          currentPage: paging.no,
          onChangePage,
          pageSize: paging.size,
        }}
        noRowsOverlayComponent={() => (
          <Empty>
            {t('brandInfo:modal.message.tableNoDataMessage1')}
            <br />
            {t('brandInfo:modal.message.tableNoDataMessage2')}
          </Empty>
        )}
        enableBrowserTooltips
      />
      <DetailModal brandId={brandId} />
    </Card>
  );
});
