import styled from '@emotion/styled';
import { Form, Tabs } from 'antd';
import { Modal } from 'antd/es';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductRequestTypeEnum } from '@/generated/graphql';
import { useProductModalHook } from '@/hooks/product/list/useProductModalHook';
import { IProductInfoForm } from '@/types/product/list';
import LogisticsTab, {
  IRefLogisticsTab,
} from '@/views/product/common/modal/LogisticsTab';
import PriceTab, { IRefPriceTab } from '@/views/product/common/modal/PriceTab';
import { ProductRequestFormTab } from '@/views/product/common/ProductRequestFormTab';
import BasicInfo from '@/views/product/list/modal/BasicInfo';

/** style */
const Wrapper = styled.div``;

/** props */
interface IPropsDetailModal {
  /** show 여부 */
  isShow: boolean;
  /** product request id */
  id: string;
  /** modal close */
  onClose: () => void;
}

/** component: 상품 목록 > 상품 상세 모달 */
const DetailModal: FC<IPropsDetailModal> = ({ isShow, id, onClose }) => {
  const { t } = useTranslation(['partnerInfo', 'brandInfo', 'productInfo']);
  const [form] = Form.useForm<IProductInfoForm>();
  const [activeTab, setActiveTab] = useState<ProductRequestTypeEnum>(
    ProductRequestTypeEnum.Logistics,
  );
  const refLogisticsTab = useRef<IRefLogisticsTab>(null);
  const refPriceTab = useRef<IRefPriceTab>(null);

  const [isModify, setIsModify] = useState<boolean>(false);

  /** hook */
  const { dataProductInfo } = useProductModalHook({
    productId: id,
    isShow,
  });

  /** modal close 핸들러 */
  const handleClose = () => {
    setIsModify(false);

    onClose?.();
  };

  /** 탭 변경시 */
  const handleTabChange = (key: string) => {
    setIsModify(false);

    setActiveTab(key as ProductRequestTypeEnum);
  };

  return (
    <Wrapper>
      <Modal
        title={
          dataProductInfo?.productKrName ||
          dataProductInfo?.productName ||
          t('brandInfo:modal.title.registrationTitle')
        }
        open={isShow}
        onCancel={handleClose}
        width={664}
        bodyStyle={{ maxHeight: '672px', overflowY: 'scroll' }}
        afterClose={form.resetFields}
        destroyOnClose
        footer={null}
      >
        {/* 기본 정보 표시 */}
        {dataProductInfo && (
          <BasicInfo isBoxed={false} isModify={false} productInfo={dataProductInfo} />
        )}

        {/* 텝 영역 */}
        <ProductRequestFormTab
          style={{ marginBottom: 32 }}
          type='card'
          defaultActiveKey={ProductRequestTypeEnum.Logistics}
          activeKey={activeTab}
          onChange={handleTabChange}
        >
          {/* logistics */}
          <Tabs.TabPane
            tab='Logistics Information'
            key={ProductRequestTypeEnum.Logistics}
          >
            <LogisticsTab
              ref={refLogisticsTab}
              isActive={activeTab === ProductRequestTypeEnum.Logistics}
              isModify={isModify}
              isBoxed={false}
              productInfo={dataProductInfo}
            />
          </Tabs.TabPane>
          {/* logistics */}
          {/* price */}
          <Tabs.TabPane tab='Price Information' key={ProductRequestTypeEnum.Price}>
            <PriceTab
              ref={refPriceTab}
              isActive={activeTab === ProductRequestTypeEnum.Price}
              isModify={isModify}
              isBoxed={false}
              productInfo={dataProductInfo}
            />
          </Tabs.TabPane>
          {/* price */}
        </ProductRequestFormTab>
        {/* 텝 영역 */}
      </Modal>
    </Wrapper>
  );
};

export { DetailModal };
