import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button } from '@gomi/component';
import { useState } from 'react';
import { Page } from 'react-pdf';
import tw from 'twin.macro';

const PDF_SCALE = {
  MIN: 0.5,
  MAX: 3,
  RATIO: 0.25,
};

interface PdfAreaProps {
  totalPage: number;
  currentPage: number;
}

const CustomMainPage = styled(Page)`
  ${tw`gm-flex gm-justify-center gm-items-center`}
  min-height: 100% !important;

  canvas {
    ${tw`gm-m-5`}
  }

  .textLayer,
  .annotationLayer {
    ${tw`gm-hidden`}
  }
`;

export const PdfArea = ({ totalPage, currentPage }: PdfAreaProps) => {
  const [scale, setScale] = useState(1);

  const handleScaleDown = () => {
    if (scale > PDF_SCALE.MIN) setScale(scale - PDF_SCALE.RATIO);
  };

  const handleScaleUp = () => {
    if (scale < PDF_SCALE.MAX) setScale(scale + PDF_SCALE.RATIO);
  };

  return (
    <div className='gm-relative gm-flex-grow lg:gm-max-w-[calc(100%-115px)]'>
      <div className='gm-h-full gm-overflow-auto'>
        <CustomMainPage pageIndex={currentPage} scale={scale} loading='' />
      </div>
      <div className='gm-absolute gm-top-5 gm-left-5 gm-text-xs gm-text-white'>{`${
        currentPage + 1
      } / ${totalPage}`}</div>
      <div className='gm-absolute gm-top-4 gm-right-4 gm-text-xs gm-bg-white gm-rounded-full gm-shadow-md gm-overflow-hidden'>
        <Button
          btnType='text'
          onClick={handleScaleDown}
          className='gm-w-8 gm-h-8 gm-p-0 gm-text-xs'
        >
          <MinusOutlined />
        </Button>
        <span className='gm-px-2 gm-border-x gm-border-x-gray-400'>{`${
          scale * 100
        }%`}</span>
        <Button
          btnType='text'
          onClick={handleScaleUp}
          className='gm-w-8 gm-h-8 gm-p-0 gm-text-xs'
        >
          <PlusOutlined />
        </Button>
      </div>
    </div>
  );
};
