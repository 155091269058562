import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ContentHeader as PageHeader } from '@/component';
import { useGetB2bOrderStatusHook } from '@/hooks/order/common/useGetB2bOrderStatusHook';
import { OrderStatusTag } from '@/views/order/common/OrderStatusTag';

export const ContentHeader = () => {
  const { t } = useTranslation(['orderInfo']);
  const { orderId } = useParams<{ orderId: string }>();
  const { b2bOrderStatus } = useGetB2bOrderStatusHook(orderId);

  return (
    <PageHeader
      title={t('orderInfo:createPage.title')}
      subtitle={b2bOrderStatus && <OrderStatusTag status={b2bOrderStatus} />}
    />
  );
};
