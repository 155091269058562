import React from 'react';

const ROOT_MENU = 'rootMenu';

const sessionStorage = {
  getItem: (key: string) => {
    const item = window.sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  setItem: (key: string, value: unknown): void => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  },
  removeItem: (key: string) => {
    window.sessionStorage.removeItem(key);
  },
};

export const setRootMenus = (menus: React.Key[]) => {
  sessionStorage.setItem(ROOT_MENU, menus);
};

export const getRootMenus = () => {
  const rootMenus = sessionStorage.getItem(ROOT_MENU);
  return rootMenus || [];
};

export const removeMenuInSessionStorage = () => {
  sessionStorage.removeItem(ROOT_MENU);
};
