import { message } from 'antd';
import { UploadFile } from 'antd/es';
import { RcFile } from 'antd/es/upload';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FilePathEnum,
  useUploadFilesToS3Mutation,
} from '@/generated/graphql-react-query';

export const useUploadedFilesHook = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { t } = useTranslation(['newCommon']);

  const { mutate: uploadFilesMutation, isLoading } = useUploadFilesToS3Mutation({
    onSuccess: (response) => {
      const files = response.uploadFilesToS3.map(
        (file) =>
          ({
            uid: file.id,
            name: file.fileName,
            fileName: file.fileName,
            url: file.link,
            status: 'done',
            percent: 100,
          } as UploadFile),
      );
      setFileList([...fileList, ...files]);
    },
    onError: () => {
      message.error(t('newCommon:message.upload.fail'));
    },
  });

  const uploadFile = (files: UploadFile[], type: FilePathEnum) => {
    uploadFilesMutation({
      files: files
        .filter((file) => file.originFileObj)
        .map((file) => new File([file.originFileObj as RcFile], file.name)),
      type,
    });
  };

  const removeFile = (file: UploadFile) => {
    setFileList(fileList.filter((f) => f.uid !== file.uid));
  };

  const resetFiles = () => {
    setFileList([]);
  };

  return {
    fileList,
    setFileList,
    uploadFile,
    removeFile,
    resetFiles,
    isLoading,
  };
};
