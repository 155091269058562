import { InboxOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { Spin } from 'antd/es';
import Dragger from 'antd/es/upload/Dragger';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import React, { ReactNode } from 'react';

export interface IUploadProps {
  fileList: UploadFile[];
  onChange(info: UploadChangeParam<UploadFile>): void;
  onRemove?: (file: UploadFile) => void;
  maxFiles: number;
  isLoading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  multiple?: boolean;
  type: UploadType;
}

export type UploadType = 'IMAGE' | 'PDF' | 'EXCEL' | 'ALL';

export const Upload = ({
  fileList,
  onChange,
  onRemove = undefined,
  isLoading = false,
  maxFiles = 3,
  children,
  disabled = false,
  multiple,
  type = 'IMAGE',
}: IUploadProps) => {
  const acceptStore = {
    IMAGE: '.png, .jpg, .jpeg',
    EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    PDF: '.pdf',
    ALL: '.png, .jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .pdf',
  };

  const handleUploadChange = (info: UploadChangeParam<UploadFile>) => {
    if (fileList.length < maxFiles) onChange(info);
    else message.warning(`${maxFiles}개 이상 업로드하실 수 없습니다.`);
  };

  return (
    <Spin spinning={isLoading}>
      <Dragger
        disabled={disabled}
        accept={acceptStore[type]}
        beforeUpload={() => false}
        maxCount={maxFiles}
        fileList={fileList}
        onChange={handleUploadChange}
        onRemove={(file) => {
          onRemove?.(file);
          return false;
        }}
        multiple={multiple}
      >
        {children || (
          <>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>이곳을 클릭 또는 파일을 드래그하세요.</p>
          </>
        )}
      </Dragger>
    </Spin>
  );
};
