import {
  ColDef,
  ColGroupDef,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { numbers } from '@gomi/utils';
import { useTranslation } from 'react-i18next';

import { DtoReturnSettlementConsignmentPartnerFeeSummary } from '@/generated/graphql-react-query';

/** 채널별 수수료 list column */
export const DashBoardFeeColumn = (isPC: boolean): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation('settlementInfo');

  const convertMoney = (params: ValueFormatterParams) =>
    numbers.convertMoneyText(params.value);

  const valueGetterByChannel = (
    params: ValueGetterParams<DtoReturnSettlementConsignmentPartnerFeeSummary>,
    channelName: string,
  ) => {
    const gomiChannel = params?.data?.channels.find(
      (channel) => channel.channelName === channelName,
    );
    return gomiChannel ? gomiChannel.fee : 0;
  };

  return [
    {
      // gomi
      headerName: 'GOMI',
      headerClass: 'gm-text-right header-span-2',
      cellClass: 'gm-text-right',
      minWidth: 112,
      flex: 1,
      valueGetter: (params) => valueGetterByChannel(params, 'GOMI'),
      valueFormatter: convertMoney,
    },
    {
      headerName: t('channel.tableColumn.externalChannel'),
      minWidth: 115,
      flex: 1,
      children: [
        {
          // shopee
          columnGroupShow: 'open',
          headerName: 'SHOPEE',
          minWidth: 115,
          headerClass: 'gm-text-right',
          cellClass: 'gm-text-right',
          valueGetter: (params) => valueGetterByChannel(params, 'SHOPEE'),
          valueFormatter: convertMoney,
        },
        {
          // lazada
          columnGroupShow: 'open',
          headerName: 'LAZADA',
          minWidth: 115,
          headerClass: 'gm-text-right',
          cellClass: 'gm-text-right',
          valueGetter: (params) => valueGetterByChannel(params, 'LAZADA'),
          valueFormatter: convertMoney,
        },
        {
          // sendo
          columnGroupShow: 'open',
          headerName: 'SENDO',
          minWidth: 115,
          headerClass: 'gm-text-right',
          cellClass: 'gm-text-right',
          valueGetter: (params) => valueGetterByChannel(params, 'SENDO'),
          valueFormatter: convertMoney,
        },
        {
          // tiki
          columnGroupShow: 'open',
          headerName: 'TIKI',
          minWidth: 115,
          headerClass: 'gm-text-right',
          cellClass: 'gm-text-right',
          valueGetter: (params) => valueGetterByChannel(params, 'TIKI'),
          valueFormatter: convertMoney,
        },
        {
          // ETC
          columnGroupShow: 'open',
          headerName: 'ETC',
          minWidth: 115,
          headerClass: 'gm-text-right',
          cellClass: 'gm-text-right',
          valueGetter: (params) => valueGetterByChannel(params, 'ETC'),
          valueFormatter: convertMoney,
        },
        {
          // shopee
          columnGroupShow: 'closed',
          headerName: 'SHOPEE',
          minWidth: 115,
          flex: 1,
          headerClass: 'gm-text-right',
          cellClass: 'gm-text-right',
          valueGetter: (params) => valueGetterByChannel(params, 'SHOPEE'),
          valueFormatter: convertMoney,
        },
      ],
    },
    {
      // 총 수수료
      headerName: t('channel.tableColumn.totalFee'),
      field: 'totalFee',
      headerClass: 'gm-text-right header-span-2',
      cellClass: 'gm-text-right',
      pinned: isPC ? 'right' : false,
      minWidth: 112,
      flex: 1,
      valueFormatter: convertMoney,
    },
  ];
};
