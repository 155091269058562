import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { ReactNode } from 'react';
import { styled } from 'twin.macro';

import { Button } from './Button';

/** style */
const Wrapper = styled.div``;

const colorVariation = {
  primary: 'blue',
  default: 'gray',
  success: 'green',
  warning: 'yellow',
  danger: 'red',
};

export interface IPropsDownloadButton
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  link: string;
  btnType?: 'outlined' | 'filled' | 'ghost' | 'text';
  color?: keyof typeof colorVariation;
  size?: 'sm' | 'md' | 'lg';
  children?: ReactNode;
  isDisabled?: boolean;
  loading?: boolean;
  className?: string;
}

export const DownloadButton = ({
  link,
  btnType = 'outlined',
  color = 'default',
  size = 'md',
  children,
  isDisabled,
  loading = false,
  className,
  ...props
}: IPropsDownloadButton) => (
  <Wrapper className={className}>
    <Spin spinning={loading} indicator={<LoadingOutlined className='gm-text-2xl' spin />}>
      <a href={isDisabled ? undefined : link} onClick={props.onClick} download>
        <Button
          type='button'
          btnType={btnType}
          size={size}
          loading={loading}
          color={color}
          isDisabled={isDisabled}
          className='gm-flex gm-justify-start gm-items-center'
        >
          {children}
        </Button>
      </a>
    </Spin>
  </Wrapper>
);
