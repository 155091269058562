import styled from '@emotion/styled';
import { Spin } from 'antd';
import { ReactElement } from 'react';
import nextId from 'react-id-generator';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { NotFound } from '@/component/NotFound';
import { AuthContainer } from '@/container';
import { authTokenStorage } from '@/lib';
import { referrerSessionStorage } from '@/lib/sessionStorageController';
import { FindPasswordPage } from '@/pages/auth/FindPasswordPage';
import { LoginPage } from '@/pages/auth/LoginPage';
import { RegisterPage } from '@/pages/auth/RegisterPage';
import { Demo } from '@/pages/demo';
import { PATH } from '@/routes/constant';
import { routeList } from '@/routes/routeList';

const SpinWrapperDiv = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
});

export const RouteSwitch = (): ReactElement => {
  const { userInfoLoading } = AuthContainer.useContainer();
  const history = useHistory();
  if (userInfoLoading) {
    return (
      <SpinWrapperDiv>
        <Spin />
      </SpinWrapperDiv>
    );
  }

  const matchToExcludePath = [
    PATH.LOGIN,
    PATH.FIND_PASSWORD,
    PATH.RESET_PASSWORD,
    PATH.DEMO,
    `${PATH.DEMO}/`,
  ].some((path) => history.location.pathname.split('?')[0].indexOf(path) > -1);

  /** 리다이렉트시 쿼리 세션 스토리지에 저장 */
  if (!authTokenStorage.getToken() && !matchToExcludePath) {
    referrerSessionStorage.setItem(
      [history.location.pathname, history.location.search].join(''),
    );

    return (
      <Redirect
        to={{
          pathname: `${PATH.LOGIN}`,
        }}
      />
    );
  }

  return (
    <Switch>
      <Route key='partnerLogin' exact component={LoginPage} path={PATH.LOGIN} />
      <Route key='register' exact component={RegisterPage} path={PATH.REGISTER} />
      <Route key='demo' exact component={Demo} path={PATH.DEMO} />
      <Route
        key={nextId()}
        exact
        component={FindPasswordPage}
        path={PATH.FIND_PASSWORD}
      />
      <Route
        key={nextId()}
        exact
        component={FindPasswordPage}
        path={PATH.RESET_PASSWORD}
      />
      {routeList.map((route) => {
        return <Route key={route.id} exact component={route.page} path={route.path} />;
      })}
      <Route component={NotFound} />
    </Switch>
  );
};
