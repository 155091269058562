import {
  CellClassParams,
  ICellRendererParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { dates } from '@gomi/utils';
import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';

import { B2bOrderStatusEnum } from '@/generated/graphql-react-query';
import { B2bOrder } from '@/hooks/order/management/useGetB2bOrderListHook';
import { PATH } from '@/routes';
import { OrderStatusTag } from '@/views/order/common/OrderStatusTag';

interface IListColumnProps {
  t: TFunction;
  onDisable(id: string): void;
}

export const ORDER_PATH = {
  [B2bOrderStatusEnum.OrderReady]: PATH.ORDER_CREATE.split('/')[2],
  [B2bOrderStatusEnum.OrderCreate]: PATH.ORDER_CHECK.split('/')[2],
  [B2bOrderStatusEnum.OrderRequestConfirm]: PATH.ORDER_CHECK.split('/')[2],
  [B2bOrderStatusEnum.PendingDeposit]: PATH.ORDER_DETAIL.split('/')[2],
  [B2bOrderStatusEnum.OrderWaitingForConfirm]: PATH.ORDER_DETAIL.split('/')[2],
  [B2bOrderStatusEnum.Done]: PATH.ORDER_DETAIL.split('/')[2],
};

export const getListColumn = ({ t }: IListColumnProps) => {
  const convertYyyyMMdd = (params: ValueFormatterParams) =>
    dates.getFormatYYYYMMDD(params.value);

  return [
    {
      headerName: t('orderInfo:managementPage.label.no'),
      field: 'no',
      cellStyle: { textAlign: 'center' },
      width: 64,
      cellRenderer: ({ rowIndex }: ICellRendererParams) => rowIndex + 1,
    },
    {
      headerName: t('orderInfo:managementPage.label.sellerName'),
      field: 'sellerName',
      cellStyle: { textAlign: 'left' },
      minWidth: 200,
      flex: 1,
      cellClass: ({ data }: CellClassParams<B2bOrder>) =>
        data?.isDisable ? 'gm-opacity-40' : '',
      cellRenderer: ({ data }: ICellRendererParams<B2bOrder>) =>
        data &&
        (data.isDisable ? (
          data.sellerName
        ) : (
          <Link
            to={`${ORDER_PATH[data.status]}/${data.id}`}
            className='gm-block gm-text-[inherit] hover:gm-underline'
          >
            {data.sellerName}
          </Link>
        )),
    },
    {
      headerName: t('orderInfo:managementPage.label.buyerName'),
      field: 'buyerName',
      minWidth: 200,
      flex: 1,
      cellClass: ({ data }: CellClassParams) => (data.isDisable ? 'gm-opacity-40' : ''),
    },
    {
      headerName: t('orderInfo:managementPage.label.status'),
      field: 'status',
      width: 110,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrder>) =>
        data && <OrderStatusTag status={data.status} />,
      cellClass: ({ data }: CellClassParams) => (data.isDisable ? 'gm-opacity-40' : ''),
    },
    {
      headerName: t('orderInfo:managementPage.label.totalPrice'),
      field: 'totalPrice',
      width: 170,
      cellRenderer: ({ data }: ICellRendererParams<B2bOrder>) =>
        data && data.totalPrice.toLocaleString(),
      cellClass: ({ data }: CellClassParams) => (data.isDisable ? 'gm-opacity-40' : ''),
    },
    {
      headerName: t('orderInfo:managementPage.label.createdAt'),
      field: 'createdAt',
      width: 105,
      valueFormatter: convertYyyyMMdd,
      cellClass: ({ data }: CellClassParams) => (data.isDisable ? 'gm-opacity-40' : ''),
    },
  ];
};
