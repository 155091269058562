import { Modal, message } from 'antd';
import { request } from 'graphql-request';
import i18next from 'i18next';

type TAuthHeaderProps = {
  authorization: string;
};

/** type: fetch result */
type TFetchData<T> = () => Promise<T>;

/**
 * react-query 에서 graphql 호출을 위한 fetcher
 * @param graphqlUrl graphql url
 * @param authToken auth token
 * @returns fetch result data
 */
export const fetcher =
  (graphqlUrl: string, getAuthToken: () => string | null) =>
  <TData, TVariables>(
    query: string,
    variables?: TVariables,
    options?: RequestInit['headers'],
  ): TFetchData<TData> => {
    return async () => {
      const authHeaders = {} as TAuthHeaderProps;
      const authToken = typeof getAuthToken === 'function' ? getAuthToken() : null;
      if (authToken) {
        authHeaders.authorization = `Bearer ${authToken}`;
      }

      try {
        const response = await request(
          graphqlUrl,
          query,
          {
            ...variables,
          },
          {
            ...authHeaders,
            ...options,
          },
        );

        return response;
      } catch (error) {
        const { response: errorResponse } = JSON.parse(JSON.stringify(error));
        if (errorResponse.errors && errorResponse.errors.length > 0) {
          const error = errorResponse.errors[0];
          const isInternalServerError = error.extensions.code === 'INTERNAL_SERVER_ERROR';

          if (error.message === 'Unauthorized') {
            message.error('로그인 후 이용해주세요.');
            document.location.href = '/';
            return;
          }

          // internal service error 는 모달로 클라이언트에게 알려주고 내려주는 데이터에서
          // errors 는 없애준다.
          // extensions.code 가 인터널 서버에러가 아니면 에러 데이터 그대로 클라이언트로 내려준다.
          if (isInternalServerError) {
            errorResponse.errors = undefined;

            Modal.error({
              content: i18next.t('notification.internalServerError'),
            });

            return errorResponse.data;
          }

          throw new Error(error.message || 'Error...');
        }
      }
    };
  };
