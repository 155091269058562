import 'antd/dist/antd.min.css';
import '@/assets/index.css';
import '@/locale/i18n';
import '@/styles/global.scss';
import '@/styles/tailwind.css';

import { LicenseManager } from '@ag-grid-enterprise/core';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import { channelIO, Track } from '@gomi/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import makeInspectable from 'mobx-devtools-mst';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from '@/App';
import { globalEnv } from '@/config/env';
import { AppMode } from '@/lib';
import { gqlClientSetting } from '@/lib/gqlClientSetting';
import { initializeStore, StoreProvider } from '@/stores';
import { basic } from '@/styles/theme';

const gqlApolloClient = gqlClientSetting();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
    },
  },
});
const store = initializeStore();

/** ag-grid license */
LicenseManager.setLicenseKey(globalEnv.AG_GRID_LICENSE_KEY);

/** mock setup */
if (globalEnv.MODE === 'development') {
  // 크롬 mobx tools 에 MST 로 상태변화를 볼 수 있게 한다.
  makeInspectable(store);

  if (globalEnv.IS_API_MOCKING === 'Y') {
    import('~/mocks/setupMocks').then(({ setupMocks }) => {
      setupMocks();
    });
  }
}

/* 믹스패널 세팅 */
Track.init(globalEnv.TRACK_API_KEY, {
  track_pageview: true,
});

/* 채널톡 세팅 */
channelIO.loadScript();

ReactDOM.render(
  <StoreProvider value={store}>
    <ApolloProvider client={gqlApolloClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={basic}>
          <App />
        </ThemeProvider>

        {globalEnv.MODE === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </ApolloProvider>
    ,
  </StoreProvider>,
  document.getElementById('root'),
);
