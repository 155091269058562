import { Empty } from '@gomi/component';
import { Form, message } from 'antd';
import { some, values } from 'lodash-es';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { DtoReturnProductRequest } from '@/generated/graphql-react-query';
import { ProductFromNameEnum } from '@/types/product/list';
import { InputFormItem, NumberInputFormItem } from '@/views/common/FormItems';

/** ref */
export interface IRefLogisticsTab {
  submit: ({ isValidate }: { isValidate?: boolean }) => Promise<IFormLogistics | null>;
}

interface ILogisticsInfo {
  hsCode?: string | null;
  outerBoxCount?: number | null;
  innerBoxCount?: number | null;
}

/** props */
interface IPropsLogisticsTab {
  /** active 여부 */
  isActive: boolean;
  /** form 컨트롤 boxed 여부 */
  isBoxed: boolean;
  /** 수정 여부 */
  isModify?: boolean;
  /** 상품 정보 */
  productInfo?: ILogisticsInfo;
}

/** form extends */
type TFormExtends = Pick<
  DtoReturnProductRequest,
  'hsCode' | 'outerBoxCount' | 'innerBoxCount'
>;

/** form */
export interface IFormLogistics extends TFormExtends {}

/** 필드정보 make 함수 -  */
const makeFieldsInfo = (productInfo?: ILogisticsInfo) => ({
  hsCode: productInfo?.hsCode,
  outerBoxCount: productInfo?.outerBoxCount,
  innerBoxCount: productInfo?.innerBoxCount,
});

/** component: 상품상세 > 하단 탭영역 > logistics tab form */
const LogisticsTab: ForwardRefRenderFunction<IRefLogisticsTab, IPropsLogisticsTab> = (
  { isActive, isBoxed, isModify, productInfo },
  ref,
) => {
  const { t } = useTranslation(['productInfo', 'partnerInfo']);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [form] = Form.useForm<IFormLogistics>();

  /** ref */
  useImperativeHandle(ref, () => {
    return {
      /** submit & validation */
      submit: async ({ isValidate = true }: { isValidate?: boolean }) => {
        let formValues: IFormLogistics | null = null;

        try {
          formValues = isValidate
            ? await form.validateFields()
            : form.getFieldsValue(true);
        } catch (e) {
          message.error(t('partnerInfo:modal.message.lackOfInformation'));
        }

        return formValues;
      },
    };
  });

  /** effect */
  useEffect(() => {
    if (isActive && productInfo) {
      const info = makeFieldsInfo(productInfo);

      // field value set
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...info,
      });

      // empty check
      setIsEmpty(!some(values(info), (value) => !!value));
    }
  }, [isActive, productInfo]);

  return (
    <>
      {!isModify && isEmpty ? (
        <Empty size='small'>
          {t('productInfo:message.productTableEmptyWarning1')} <br />
          {t('productInfo:message.productTableEmptyWarning2')}
        </Empty>
      ) : (
        <Form
          form={form}
          name={ProductFromNameEnum.LOGISTICS_FORM}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          autoComplete='off'
        >
          <InputFormItem
            label={t('productInfo:label.hsCode')}
            name='hsCode'
            disabled={!isModify}
            boxed={isBoxed}
            rules={[
              {
                required: true,
                message: t('productInfo:message.hsCodeEmpty'),
              },
            ]}
          />

          <NumberInputFormItem
            label={t('productInfo:label.outerBoxCount')}
            rules={[
              {
                required: true,
                message: t('productInfo:message.outerBoxCountEmpty'),
              },
            ]}
            name='outerBoxCount'
            boxed={isBoxed}
            disabled={!isModify}
          />

          <NumberInputFormItem
            label={t('productInfo:label.innerBoxCount')}
            rules={[
              {
                required: true,
                message: t('productInfo:message.innerBoxCountEmpty'),
              },
            ]}
            name='innerBoxCount'
            boxed={isBoxed}
            disabled={!isModify}
          />
        </Form>
      )}
    </>
  );
};

export default forwardRef(LogisticsTab);
