import { Button } from '@gomi/component';
import { Result } from 'antd/es';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ImportLicensingDocumentStatusEnum } from '@/generated/graphql-react-query';
import { useImportLicensingHook } from '@/hooks/importLicensing/details/useImportLicensingHook';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';

export const ResultView = observer(() => {
  const { t } = useTranslation(['importLicensingInfo']);
  const { importLicensingId } = useParams<IParams>();
  const {
    importLicensingDetailModel: { steps },
  } = useStore();

  const isDoneStep = steps.status === ImportLicensingDocumentStatusEnum.Done;
  const { notarizationFile, requiredPeriod } = useImportLicensingHook({
    importLicensingId,
  });

  return isDoneStep ? (
    <div className='gm-flex gm-flex-col gm-w-full gm-h-full gm-text-center gm-mt-24'>
      <Result
        status='success'
        title={t('importLicensingInfo:detailPage.steps.sixth.success.description1')}
        subTitle={t('importLicensingInfo:detailPage.steps.sixth.success.description2')}
        extra={
          <Button
            onClick={() => notarizationFile.link && window.open(notarizationFile.link)}
            isDisabled={!notarizationFile.link}
          >
            {t('importLicensingInfo:detailPage.steps.sixth.success.download')}
          </Button>
        }
      />
    </div>
  ) : (
    <>
      <div className='gm-flex gm-flex-col gm-w-full gm-h-full gm-text-center gm-mt-24'>
        <p>
          <img
            className='gm-w-full gm-max-w-md'
            src='https://partner-center.s3.ap-southeast-1.amazonaws.com/resource/export-licensing-02.png'
            alt={t('importLicensingInfo:detailPage.steps.forth.description1')}
          />
        </p>
        <p>
          {t('importLicensingInfo:detailPage.steps.sixth.progress.description1')}
          <br />
          {t('importLicensingInfo:detailPage.steps.sixth.progress.description2', {
            period: requiredPeriod,
          })}
          <br /> {t('importLicensingInfo:detailPage.steps.sixth.progress.description3')}
          <br />
          <span className='gm-text-xs gm-text-gray-600'>
            {t('importLicensingInfo:detailPage.steps.sixth.progress.description4')}
          </span>
        </p>
      </div>
    </>
  );
});
