import { mst } from '@gomi/utils';
import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import moment, { Moment } from 'moment';

import { SalesTypeEnum } from '@/generated/graphql';

const IDENTIFIER = 'monthlyModel';

export const model = types
  .model(IDENTIFIER, {
    /** 스토어 아이덴티티 */
    identifier: IDENTIFIER,
    partnerSalesType: mst.types.enumeration<SalesTypeEnum>(SalesTypeEnum),
    settlementYearMonth: types.string,
  })
  .actions((self) => ({
    setSalesType(value: SalesTypeEnum) {
      self.partnerSalesType = value;
    },
    setSettlementYearMonth(value: string) {
      self.settlementYearMonth = value;
    },
  }))
  .views((self) => ({}));
/** 초기화 값 */
const defaultValue: SnapshotIn<typeof model> = {
  partnerSalesType: SalesTypeEnum.None,
  settlementYearMonth: moment().format('YYYY-MM'),
};

/** create or initialize */
const create = model.create(defaultValue);

const monthlyStore = {
  create,
  defaultValue,
  model,
};

/** 모델 타입 */
export type IMonthlyModelType = Instance<typeof model>;

export default monthlyStore;
