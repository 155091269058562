import { Card, CardTextItem } from '@gomi/component';
import { numbers } from '@gomi/utils';
import { useTranslation } from 'react-i18next';

import { useGetB2bOrderListHook } from '@/hooks/order/management/useGetB2bOrderListHook';

export const Summary = () => {
  const { t } = useTranslation(['orderInfo']);
  const { b2bOrderSummary } = useGetB2bOrderListHook();

  const tranDashboard = t('orderInfo:managementPage.summary', { returnObjects: true });
  const items = [
    {
      title: tranDashboard.totalPrice,
      value: numbers.convertMoneyText(b2bOrderSummary.totalPrice),
    },
    {
      title: tranDashboard.totalOrderCount,
      value: b2bOrderSummary.totalQuantity.toLocaleString(),
    },
    {
      title: tranDashboard.totalDoneCount,
      value: b2bOrderSummary.doneCount.toLocaleString(),
    },
    {
      title: tranDashboard.totalProgressCount,
      value: b2bOrderSummary.inProgressCount.toLocaleString(),
    },
    {
      title: tranDashboard.totalDisabledCount,
      value: b2bOrderSummary.disableCount.toLocaleString(),
    },
  ];

  return (
    <Card
      className='gm-mb-4'
      header={tranDashboard.title}
      arrayArticles={items.map((item, index) => (
        <CardTextItem key={index} header={item.title}>
          {item.value}
        </CardTextItem>
      ))}
      isContentsBackground
    />
  );
};
