import { ICellRendererParams, ValueFormatterParams } from '@ag-grid-community/core';
import { dates } from '@gomi/utils';
import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CountryEnum } from '@/generated/graphql';
import { CountryFlag } from '@/views/common/CountryFlag';
import { StepRenderer } from '@/views/importLicensing/importLicensingList/StepRenderer';

interface IListColumnProps {
  t: TFunction;
}

export const getListColumn = ({ t }: IListColumnProps) => {
  const convertYyyyMMdd = (params: ValueFormatterParams) =>
    dates.getFormatYYYYMMDD(params.value);

  return [
    {
      headerName: t('importLicensingInfo:table.no'),
      field: 'no',
      cellStyle: { textAlign: 'center' },
      width: 64,
    },
    {
      headerName: t('importLicensingInfo:table.name'),
      field: 'importLicenseName',
      cellClass: 'gm-flex gm-items-center',
      cellStyle: { textAlign: 'left' },
      minWidth: 450,
      flex: 1,
      cellRenderer: ({ value, data }: ICellRendererParams) => (
        <Link
          to={`/importLicensing/details/${data.id}`}
          className='gm-p-1 gm-text-[inherit] hover:gm-underline'
        >
          {value}
        </Link>
      ),
    },
    {
      headerName: t('importLicensingInfo:table.status'),
      field: 'step',
      cellClass: 'gm-flex gm-items-center',
      width: 160,
      cellRenderer: StepRenderer,
    },
    {
      headerName: t('importLicensingInfo:table.country'),
      field: 'countryId',
      cellClass: 'gm-flex gm-items-center',
      width: 120,
      cellRenderer: ({ data }: { data: { countryId: CountryEnum } }) =>
        CountryFlag(data.countryId, 1, 1),
    },
    {
      headerName: t('importLicensingInfo:table.createdAt'),
      field: 'createdAt',
      cellClass: 'gm-flex gm-items-center',
      width: 200,
      valueFormatter: convertYyyyMMdd,
    },
    {
      headerName: t('importLicensingInfo:table.managerName'),
      field: 'managerName',
      cellClass: 'gm-flex gm-items-center',
      width: 200,
    },
  ];
};
