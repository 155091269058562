import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { HelmetComponent } from '@/component/HelmetComponent';
import { AuthContainer } from '@/container';
import { SettingContainer } from '@/container/SettingContainer';
import { RouteSwitch } from '@/routes/RouteSwitch';

export const App = (): JSX.Element => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <SettingContainer.Provider>
          <AuthContainer.Provider>
            <HelmetProvider>
              <HelmetComponent />
              <RouteSwitch />
            </HelmetProvider>
          </AuthContainer.Provider>
        </SettingContainer.Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
};
