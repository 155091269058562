import { DeleteOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { AgTable, Card, Empty } from '@gomi/component';
import { Collapse, Popconfirm, Tag } from 'antd/es';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import {
  ImportLicensingDocumentStatusEnum,
  ImportLicensingSheetStepEnum,
} from '@/generated/graphql-react-query';
import { useContentListHook } from '@/hooks/importLicensing/details/useContentListHook';
import { useDeleteContentHook } from '@/hooks/importLicensing/details/useDeleteContentHook';
import { useDownloadFile } from '@/hooks/importLicensing/details/useDownloadFile';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';
import { ControlBar } from '@/views/importLicensing/importLicensingDetail/productDocumentUploadStep/ControlBar';
import { ListColumn } from '@/views/importLicensing/importLicensingDetail/productDocumentUploadStep/ListColumn';

const CustomAgTable = styled(AgTable)`
  ${tw`gm-min-h-[auto]`}

  .ag-root-wrapper-body.ag-layout-normal {
    ${tw`gm-h-auto`}
  }
`;

export const List = observer(() => {
  const { importLicensingId } = useParams<IParams>();
  const { t } = useTranslation(['importLicensingInfo']);

  const {
    importLicensingDetailModel: { setFile, steps },
  } = useStore();

  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  /** 상품 목록 호출 */
  const { list, isLoading, refetch } = useContentListHook({
    importLicensingId,
    step: ImportLicensingSheetStepEnum.DocumentUploadPerProduct,
  });
  /** 상품 목록 호출 */

  const { mutateAsync: deleteProduct } = useDeleteContentHook();

  const { download } = useDownloadFile();

  const handleDeleteProduct = async (id: string) => {
    const result = await deleteProduct({
      deleteImportLicensingContentId: id,
    });

    if (result.deleteImportLicensingContent.id) {
      refetch();
    }
  };

  const isDoneStep = steps.status === ImportLicensingDocumentStatusEnum.Done;

  const handleDownload = async (fileId: string) => {
    if (!fileId) {
      return;
    }

    const result = await download({
      downloadImportLicensingDocumentFileId: fileId,
    });
    window.open(result.downloadImportLicensingDocumentFile);
  };

  return (
    <Card
      className='lg:gm-h-full gm-w-full'
      articleClassName='gm-p-0'
      header={<ControlBar />}
      isContentsBackground={false}
    >
      <Collapse
        activeKey={activeKeys}
        onChange={(keys) => setActiveKeys(keys as string[])}
        className='gm-border-0'
      >
        {list.map((item) => (
          <Collapse.Panel
            header={
              <div className='gm-flex gm-gap-4 gm-justify-between gm-mr-8'>
                <div>{item.contentName}</div>
                <div className='gm-hidden lg:gm-flex lg:gm-gap-4'>
                  <div className='gm-flex gm-justify-between'>
                    <Tag color='default'>{t('importLicensingInfo:table.salesPrice')}</Tag>{' '}
                    <div className='gm-w-[70px] gm-text-right'>
                      {item.salesPrice.toLocaleString()}
                    </div>
                  </div>
                  <div className='gm-flex gm-justify-between'>
                    <Tag color='default'>
                      {t('importLicensingInfo:table.desiredSalesPrice', { unit: '₫' })}
                    </Tag>{' '}
                    <div className='gm-w-[70px] gm-text-right'>
                      {item.desiredSalesPrice.toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            }
            extra={
              !isDoneStep ? (
                <Popconfirm
                  placement='topRight'
                  title={t(
                    'importLicensingInfo:detailPage.steps.first.content.deleteConfirm.title',
                  )}
                  onConfirm={() => handleDeleteProduct(item.id)}
                  okText={t(
                    'importLicensingInfo:detailPage.steps.first.content.deleteConfirm.ok',
                  )}
                  cancelText={t(
                    'importLicensingInfo:detailPage.steps.first.content.deleteConfirm.cancel',
                  )}
                >
                  <DeleteOutlined onClick={(e) => e.stopPropagation()} />
                </Popconfirm>
              ) : null
            }
            key={item.id}
          >
            <CustomAgTable
              loading={isLoading}
              rowData={item.documentList}
              columnDefs={ListColumn({
                setFile: (id) => setFile({ fileId: id, contentName: item.contentName }),
                downloadFile: handleDownload,
                t,
              })}
              defaultColDef={{ resizable: true }}
              noRowsOverlayComponent={() => (
                <Empty onShowImage>{t('importLicensingInfo:table.emptyProducts')}</Empty>
              )}
              enableBrowserTooltips
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </Card>
  );
});
