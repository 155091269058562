import { Card, CardTextItem } from '@gomi/component';
import { numbers } from '@gomi/utils';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { Header, IPropsHeader } from '@/views/settlement/common/monthlyDashboard/Header';

/** props */
interface IPropsMonthlyDashboard extends IPropsHeader {
  /** css class */
  className?: string;
  /** header */
  header?: ReactNode;
  /** 총 판매수 */
  salesCount?: number;
  /** 총 매출 금액 */
  salesPrice?: number;
  /** 총 수수료('총 고미수수료'와 단어 통일됨) */
  salesFee?: number;
  /** 총 상계 금액 */
  setoffPrice?: number;
  /** 총 정산 금액 */
  settlementPrice?: number;
  /** 선택 월  */
  yearMonth?: string;
}

/** item 정보 */
interface IItem {
  title: string;
  value?: number;
  content?: string;
  prefix?: string;
  textClassName?: string;
  isShow?: boolean;
}

/** component: 정산 > 해당월 카드 대쉬보드
 * 각 price 나 count props가 undefined 일 경우 카드로 표시되지 않습니다.
 */
const MonthlyDashboard: FC<IPropsMonthlyDashboard> = ({
  isShowExchangeRate = true,
  className,
  header,
  yearMonth,
  countryId,
  salesCount,
  salesPrice,
  salesFee,
  setoffPrice,
  settlementPrice,
}) => {
  const { t } = useTranslation('settlementInfo');
  const tranDashboard = t('monthlyDashBoard', { returnObjects: true });
  const { isPC } = useMediaQueryHook();

  const makeItems: IItem[] = [
    {
      title: tranDashboard.totalSales,
      value: salesCount,
    },
    {
      title: tranDashboard.totalSalesAmount,
      value: salesPrice,
    },
    {
      title: tranDashboard.salesFee,
      value: salesFee,
      prefix: '-',
    },
    {
      title: tranDashboard.setoffPrice,
      value: setoffPrice,
      prefix: '-',
    },
    {
      title: tranDashboard.totalSettlementAmount,
      value: settlementPrice,
      textClassName: 'gm-text-blue-700',
    },
  ].map((item) => {
    const content = numbers.convertMoneyText(item.value, 0);
    return {
      ...item,
      content,
      prefix: content !== '0' ? item.prefix : '',
      isShow: item.value !== undefined,
    };
  });

  return (
    <Card
      className={`gm-mb-4 ${className}`}
      header={
        <Header
          header={header}
          isShowExchangeRate={isShowExchangeRate}
          yearMonth={yearMonth}
          countryId={countryId}
        />
      }
      sectionClassName={isPC ? '' : 'gm-flex gm-flex-col'}
      arrayArticles={makeItems
        .filter((item) => item.isShow)
        .map((item, idx) => (
          <CardTextItem
            key={idx}
            header={item.title}
            contentClassName={item.textClassName}
          >
            {item.prefix}
            {item.content}
          </CardTextItem>
        ))}
      isContentsBackground
    />
  );
};

export { MonthlyDashboard };
