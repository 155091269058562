import { useState } from 'react';

/** props */
interface IPropsUsePagingHook {
  defaultNo?: number;
  defaultSize?: number;
}

/** 페이징 interface */
export interface IPaging {
  no: number;
  size: number;
}

/**
 * 페이징 hooks
 */
export const usePagingHook = (props?: IPropsUsePagingHook) => {
  const defaultNo = props?.defaultNo ?? 1;
  const defaultSize = props?.defaultSize ?? 20;

  const [paging, setPaging] = useState<IPaging>({
    no: defaultNo,
    size: defaultSize,
  });

  /** 페이지번호 초기화 */
  const initPageNo = () => {
    setPaging((prev) => ({
      ...prev,
      no: defaultNo,
    }));
  };

  /** 페이징 change 시 */
  const onChangePage = (no: number, size: number) => {
    setPaging({
      no,
      size,
    });
  };

  /**
   * 리스트에서 번호부분에 사용
   * 외부에서 paging 상태값을 넣어서 쓸 수 있고
   * 외부에서 넣지 않았다면 hooks 내부의 상태값을 사용
   */
  const getListNo = (rowIndex: number, pagingObj: IPaging = paging) => {
    return (pagingObj.no - 1) * pagingObj.size + rowIndex + 1;
  };

  const getOffset = () => {
    return (paging.no - 1) * paging.size;
  };

  return {
    paging,
    setPaging,
    getOffset,
    getListNo,
    initPageNo,
    onChangePage,
  };
};
