import { useImportLicensingDocumentQuery } from '@/generated/graphql-react-query';

interface IProductCreateItemHookProps {
  id: string | null;
}

export const useImportLicensingDocumentHook = ({ id }: IProductCreateItemHookProps) => {
  const { data, isFetching, refetch } = useImportLicensingDocumentQuery(
    {
      importLicensingDocumentId: String(id),
    },
    {
      enabled: !!id,
    },
  );

  return {
    data: data?.importLicensingDocument,
    isLoading: isFetching,
    refetch,
  };
};
