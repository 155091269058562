import { SyncOutlined } from '@ant-design/icons';
import { Button } from '@gomi/component';
import { useTranslation } from 'react-i18next';

interface ReloadProps {
  onClick(): void;
}

export const LoadError = ({ onClick }: ReloadProps) => {
  const { t } = useTranslation(['importLicensingInfo']);

  return (
    <div className='gm-absolute gm-top-1/2 gm-left-1/2 gm-translate-x-[-50%] gm-translate-y-[-50%] gm-text-center gm-text-white'>
      <p className='gm-my-0 gm-text-sm'>
        {t('importLicensingInfo:fileViewerPage.error.loading')}
      </p>
      <Button onClick={onClick} type='button' btnType='text' className='gm-mt-1'>
        <SyncOutlined className='gm-block gm-text-[1.5rem] gm-text-white' />
      </Button>
    </div>
  );
};
