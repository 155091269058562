import { HomeLocaleType } from '@/locale/data/types/homeLocale.type';

export const enHomeLocale: HomeLocaleType = {
  rootMenu: 'Dashboard',
  filter: {
    today: 'Today',
    recent7Days: 'Last 7 Days',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
  },
  summary: {
    title: 'Total Sales',
    items: {
      total: 'Total Sales (₫)',
      totalOrderCount: 'Total Orders',
      totalCancelCount: 'Total Cancelled Orders',
    },
  },
  dashboard: {
    topSellingCategory: {
      title: 'Top Selling Category',
    },
    topSellingProduct: {
      title: 'Top Selling Product',
    },
    topSellingBrand: {
      title: 'Top Selling Brand',
    },
    percentageOfSalesByChannel: {
      title: 'Sales By Channel',
    },
    statusOfAdvancement: {
      title: 'Status',
    },
  },
  empty: {
    content: 'Empty',
  },
};
