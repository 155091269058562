import {
  ImportLicensingSheetStepEnum,
  useImportLicensingContentListQuery,
} from '@/generated/graphql-react-query';

interface IProductListHookProps {
  importLicensingId: string;
  step: ImportLicensingSheetStepEnum;
}

export const useContentListHook = ({
  importLicensingId,
  step,
}: IProductListHookProps) => {
  const { data, ...rest } = useImportLicensingContentListQuery({
    input: {
      filter: { step, importLicensingId },
    },
  });

  return {
    ...rest,
    list: data?.importLicensingContentList.nodes || [],
    total: data?.importLicensingContentList.total,
  };
};
