import { eachDayOfInterval, format, parseISO } from 'date-fns';

import { ALL } from '@/constant';
import { useSalesStatusQuery } from '@/generated/graphql-react-query';
import { IReportControl } from '@/types/report/type';

interface ISalesStatusChart {
  CANCEL: number;
  OK: number;
  TOTAL_AMOUNT: number;
  TOTAL_COUNT: number;
  xAxisKey: string;
}

export const useSalesStatusHook = ({
  startDate,
  endDate,
  brandId,
  channel,
  gift,
  orderStatus,
  paymentStatus,
  shippingStatus,
  salesCode,
  countryId,
}: IReportControl) => {
  const {
    data: salesStatusList,
    isLoading,
    refetch: refetchSalesStatus,
  } = useSalesStatusQuery(
    {
      input: {
        filter: {
          countryId,
          startOrderedAt: startDate,
          endOrderedAt: endDate,
          ...(brandId !== ALL && { brandId }),
          ...(channel !== ALL && { channel }),
          ...(gift !== ALL && { giftType: gift }),
          ...(orderStatus !== ALL && { orderStatus }),
          ...(paymentStatus !== ALL && { paymentStatus: [paymentStatus] }),
          ...(shippingStatus !== ALL && { shippingStatus: [shippingStatus] }),
          ...(salesCode !== ALL && { salesCode: [salesCode] }),
        },
      },
    },
    {
      staleTime: 0, // FIXME: 캐시를 활용하게 되었을 때 rechart legend 가 간헐적으로 보이지 않는 현상이 있음 (구현방법: 아래 캐시 모드 제거하고 필터를 반복)
      cacheTime: 0,
    },
  );

  /** 필터 기간의 day 목록 구성 */
  const days = eachDayOfInterval({
    start: parseISO(startDate),
    end: parseISO(endDate),
  });

  /** 데이터 가공 */
  const parsedSalesStatusList =
    salesStatusList?.salesStatus.map(
      (salesStatus) =>
        ({
          CANCEL: salesStatus.cancelOrderCount,
          OK: salesStatus.confirmOrderCount,
          TOTAL_AMOUNT: salesStatus.totalPrice,
          TOTAL_COUNT: salesStatus.cancelOrderCount + salesStatus.confirmOrderCount,
          xAxisKey: format(new Date(salesStatus.date), 'yyyy-MM-dd'),
        } as ISalesStatusChart),
    ) || [];

  /** day 목록과 실제 판매 현황 데이터 조합 */
  const result = days.map((day) => {
    const orderedAt = format(day, 'yyyy-MM-dd');
    const exist = parsedSalesStatusList.find(
      (salesStatus) => salesStatus.xAxisKey === orderedAt,
    );

    return (
      exist ||
      ({
        CANCEL: 0,
        OK: 0,
        TOTAL_AMOUNT: 0,
        TOTAL_COUNT: 0,
        xAxisKey: orderedAt,
      } as ISalesStatusChart)
    );
  });

  return {
    salesStatus: {
      list: salesStatusList?.salesStatus.length ? result : [],
      isLoading,
      refetch: refetchSalesStatus,
    },
  };
};
