import { DatePicker, Radio, RadioChangeEvent } from 'antd/es';
import { RangePickerProps } from 'antd/es/date-picker';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { DatePickerFormat } from '@/constant/report';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { momentDateFormat } from '@/lib';
import { useStore } from '@/stores';
import { RangeValue } from '@/types/report/type';

const TODAY = '오늘';
const LAST_SEVEN_DAYS = '최근 7일';
const THIS_MONTH = '이번달';
const LAST_MONTH = '지난달';

type DATE_TYPES =
  | typeof TODAY
  | typeof LAST_SEVEN_DAYS
  | typeof THIS_MONTH
  | typeof LAST_MONTH;

export const Controller = observer(() => {
  const { t } = useTranslation(['home']);
  const {
    homeModel: { startDate, endDate, setDate },
  } = useStore();
  const { isMobile } = useMediaQueryHook();

  const handleDateButtons = ({ target: { value } }: RadioChangeEvent) => {
    const datePick = {
      [TODAY]: {
        startDate: momentDateFormat.getToday,
        endDate: momentDateFormat.getToday,
      },
      [LAST_SEVEN_DAYS]: {
        startDate: momentDateFormat.get1WeekAgoFromToday(),
        endDate: momentDateFormat.getToday,
      },
      [THIS_MONTH]: {
        startDate: momentDateFormat.getFirstDayOfThisMonth(),
        endDate: momentDateFormat.getToday,
      },
      [LAST_MONTH]: {
        startDate: momentDateFormat.getFirstDayOfLastMonth(),
        endDate: momentDateFormat.getEndDayOfLastMonth(),
      },
    };

    const date = datePick[value as DATE_TYPES];
    setDate(date.startDate, date.endDate);
  };

  const handleDisabled: RangePickerProps['disabledDate'] = (current) => {
    // 3개월 전
    const disableDate = moment().subtract(3, 'months');
    const tomorrow = moment();

    return current && (current < disableDate || current > tomorrow);
  };

  return (
    <div
      className={isMobile ? 'gm-flex gm-flex-col gm-gap-2' : 'gm-flex gm-justify-between'}
    >
      <div className={isMobile ? '' : 'gm-mr-2'}>
        <Radio.Group
          optionType='button'
          size={isMobile ? 'small' : 'middle'}
          className='gm-font-normal'
          options={[
            {
              label: t('home:filter.today'),
              value: TODAY,
            },
            {
              label: t('home:filter.recent7Days'),
              value: LAST_SEVEN_DAYS,
            },
            {
              label: t('home:filter.thisMonth'),
              value: THIS_MONTH,
            },
            {
              label: t('home:filter.lastMonth'),
              value: LAST_MONTH,
            },
          ]}
          onChange={handleDateButtons}
          defaultValue={LAST_SEVEN_DAYS}
        />
      </div>
      <div>
        <DatePicker.RangePicker
          allowClear={false}
          className='gm-w-64'
          disabledDate={handleDisabled}
          value={[moment(startDate, DatePickerFormat), moment(endDate, DatePickerFormat)]}
          onChange={(
            _dates: RangeValue<Moment>,
            [startDate, endDate]: [string, string],
          ) => setDate(startDate, endDate)}
        />
      </div>
    </div>
  );
});
