import { AgTable, Card, Empty } from '@gomi/component';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useImportLicensingListHook } from '@/hooks/importLicensing/list/useImportLicensingListHook';
import { useStore } from '@/stores';
import { FilterControlBar } from '@/views/importLicensing/importLicensingList/FilterControlBar';
import { getListColumn } from '@/views/importLicensing/importLicensingList/getListColumn';

export const List = observer(() => {
  const { t } = useTranslation(['importLicensingInfo']);
  const {
    importLicensingListModel: { filterControl },
  } = useStore();
  const { importLicensingListForTable, isLoading } =
    useImportLicensingListHook(filterControl);

  const listColumn = getListColumn({ t });

  return (
    <>
      <Card
        className='gm-h-full'
        header={<FilterControlBar />}
        isContentsBackground={false}
      >
        <AgTable
          loading={isLoading}
          rowData={importLicensingListForTable}
          columnDefs={listColumn}
          defaultColDef={{ resizable: true }}
          noRowsOverlayComponent={() => (
            <Empty onShowImage>{t('importLicensingInfo:table.empty')}</Empty>
          )}
          enableBrowserTooltips
        />
      </Card>
    </>
  );
});
