import { CommentOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Card } from '@gomi/component';
import { dates } from '@gomi/utils';
import { Drawer, Tooltip } from 'antd/es';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { useCommentHook } from '@/hooks/importLicensing/details/useCommentHook';
import { useImportLicensingDocumentHook } from '@/hooks/importLicensing/details/useImportLicensingDocumentHook';
import { findFileFormatFromExtension } from '@/lib/file';
import { useStore } from '@/stores';
import { FileViewer } from '@/views/importLicensing/importLicensingDetail/common/fileViewer';
import { FileInfo } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/fileList';

const FileGroupItem = styled(Card)`
  ${tw`gm-text-xs`}
`;

const CustomDrawer = styled(Drawer)`
  .ant-drawer-header {
    ${tw`gm-py-3 lg:gm-py-4 gm-px-5 lg:gm-px-6`}
  }
  .ant-drawer-body {
    ${tw`gm-p-0`}
  }
`;

interface FileGroup {
  createdAt: Date;
  fileList: {
    createdAt: Date;
    extension: string;
    fileName: string;
    id: string;
    link: string;
  }[];
  newCommentStatus: boolean;
  id: string;
}

export const FileUploadList = observer(() => {
  const { t } = useTranslation(['importLicensingInfo']);

  const {
    importLicensingDetailModel: { file },
  } = useStore();

  const [current, setCurrent] = useState<FileGroup | null>(null);
  const [open, setOpen] = useState(false);
  const { isPC } = useMediaQueryHook();

  /** hooks */
  const { data } = useImportLicensingDocumentHook({
    id: file.selected,
  });

  const { markAsReadComment } = useCommentHook({ fileId: file.selected });

  /** 파일 그룹 선택하기 */
  const handleCurrent = (group: FileGroup) => {
    // 댓글 읽기 완료 처리
    if (group.newCommentStatus) {
      markAsReadComment(group.id);
    }

    setCurrent(group);
    setOpen(true);
  };

  const files: FileInfo[] =
    current?.fileList?.map((file) => ({
      id: file.id,
      format: findFileFormatFromExtension(file.extension),
      fileName: file.fileName,
      url: file.link,
    })) || [];

  return (
    <>
      {data?.documentGroupList.map((group, index) => {
        const firstItem = index === 0;
        return (
          <Tooltip
            title={t('importLicensingInfo:detailPage.messages.newComment')}
            open={firstItem && group.newCommentStatus}
            key={group.id}
            placement='topLeft'
            zIndex={900}
          >
            <FileGroupItem
              className={`gm-mb-2 ${
                firstItem ? 'gm-border-blue-600 gm-cursor-pointer' : ''
              }`}
              headerClassName='gm-py-2 gm-px-3'
              key={group.id}
              header={
                <div
                  className={`gm-font-normal gm-flex gm-justify-between ${
                    firstItem ? `gm-text-blue-600` : ''
                  }`}
                  onClick={firstItem ? () => handleCurrent(group) : undefined}
                >
                  <div className='gm-flex gm-text-sm'>
                    <div className='gm-mr-2'>
                      {dates.getFormatYYYYMMDD(group.createdAt)}
                    </div>
                    <div className='gm-mr-2'>{data?.documentName}</div>
                  </div>
                  {group.commentCount > 0 ? (
                    <div className='gm-flex'>
                      <div className='gm-mr-1'>
                        <CommentOutlined />
                      </div>
                      <div>{group.commentCount}</div>
                    </div>
                  ) : null}
                </div>
              }
            />
          </Tooltip>
        );
      })}
      <CustomDrawer
        title={<div className='gm-ml-3'>{data?.documentName}</div>}
        placement='right'
        width={isPC ? '80%' : '100%'}
        onClose={() => setOpen(false)}
        open={open}
      >
        <FileViewer files={files} />
      </CustomDrawer>
    </>
  );
});
