export enum DateFormat {
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'dddd' = 'dddd',
  'a hh:mm' = 'a hh:mm',
  'hh:mm' = 'hh:mm',
  'h:mm a' = 'h:mm a',
  'a h:mm' = 'a h:mm',
  'hh:mm:ss' = 'hh:mm:ss',
  'h:mm' = 'h:mm',
  'a' = 'a',
  'YYYY-MM-DD HH:mm' = 'YYYY-MM-DD HH:mm',
  'YYYY-MM-DD HH:mm:ss' = 'YYYY-MM-DD HH:mm:ss',
  'HH:mm' = 'HH:mm',
  'HH:mm:ss' = 'HH:mm:ss',
  'utc' = 'YYYY-MM-DDTHH:mm:ssZ',
  'YYMMDD' = 'YYMMDD',
  'YYYYMM' = 'YYYYMM',
  'yyyy:MM:dd' = 'yyyy:MM:dd',
  'YYYYMMDDTHHmmss' = 'YYYYMMDDTHHmmss',
  'YY/MM/DD' = 'YY/MM/DD',
}
