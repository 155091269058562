import { Report } from '@/locale/data/types/report.type';

export const koReport: Report = {
  rootMenu: '리포트',
  label: {
    noSelect: '파트너를 선택해주세요.',
    brand: '브랜드명',
    item: '상품명',
    channel: '채널명',
    period: '조회기간',
    orderStatus: '주문상태',
    payStatus: '결제상태',
    deliveryStatus: '배송상태',
    gift: 'Gift 유무',
    excludeGift: 'Gift 제외',
    onlyGift: 'Gift만 보기',
    case: '건',
    total: '합계',
    all: '전체',
    ok: '확인',
    cancel: '취소',
    waitingForReturn: '취소 대기중',
    paid: '결제',
    notPaid: '미결제',
    refund: '환불',
    ready: '배송준비',
    delivering: '배송중',
    delivered: '배송완료',
    return: '반품',
    gomi: 'GOMI',
    gomiKr: 'GOMI_KR',
    mall: 'MALL',
    lazada: 'Lazada',
    sendo: 'Sendo',
    shopee: 'Shopee',
    tiki: 'Tiki',
    app: 'APP',
    tiktok: 'Tiktok',
  },
  title: {
    detailOrderItem: '주문 상세 정보',
    salesStatusByDate: '날짜별 판매현황',
    salesStatusByProduct: '상품별 판매현황',
    stock: '재고',
    stockIn: '입고',
    stockOut: '출고',
  },
  tab: {
    salesStatus: '판매현황',
    deliveryStatus: '물류현황',
  },
  error: {
    failedLoadReport: '리포트를 불러오는데 실패했습니다',
    noReport: '상품을 판매 중인 국가가 없습니다.',
    noProductsForSaleInCountry: '해당 국가에 판매중인 상품이 없습니다.',
  },
  chart: {
    salesStatus: '판매현황 (VND)',
    salesAmountByProduct: '상품별 판매금액 (VND)',
    legend: {
      ok: '주문확인',
      cancel: '주문취소',
      totalAmount: '총 금액',
    },
  },
  column: {
    date: '날짜',
    channel: '채널',
    orderNumber: '주문번호',
    payMethod: '결제방식',
    orderDate: '주문일',
    orderStatus: '주문상태',
    paymentStatus: '결제상태',
    shippingStatus: '배송상태',
    productName: '상품명',
    voucher: '바우처',
    price: '판매 가격',
    companyName: '업체',
    brandName: '브랜드',
    gspc: 'gspc',
    priceOriginal: '소비자가격',
    settlementMonth: '정산월',
    count: '총 판매 수량',
    location: '창고',
    han: '하노이',
    hcm: '호치민',
    totalPrice: '총 판매 금액',
    sum: '합계',
    ok: '확인',
    cancel: '취소',
  },
  salesPage: {
    title: '판매 리포트',
    detailFilter: '상세필터',
  },
  stockPage: {
    title: '물류 리포트',
    label: {
      download: '다운로드',
    },
    table: {
      information: {
        apt: '판매가능수량(Available To Promise)',
        usable: 'APT + Buffer(Capable To Promise)',
        buffer: '완충재고(안전재고)',
        inProgress: '패킹 작업중인 수량 (출고 대기)',
        inPicking: '피킹 작업중인 수량 (출고 대기)',
        unusable: '유통기한 만료 및 파손 (불용재고)',
        problem: '문제가 발생한 주문으로 인해 예약된 수량',
        incoming: '운송중 및 입고처리 대기',
      },
    },
  },
};
