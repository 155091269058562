import axios from 'axios';
import { useEffect, useState } from 'react';
import { BlockMapType } from 'react-notion';

import { NotionKey } from '@/types/enums';

export const useNotionContents = (key: NotionKey) => {
  const [contents, setContents] = useState<BlockMapType>({});
  const NOTION_URL = {
    HOME: 'https://notion-api.splitbee.io/v1/page/7ad23628abdc4aaf82b2a8137148e300',
    GUIDE: 'https://notion-api.splitbee.io/v1/page/e7caba6b8ceb4ee4adec810d57947670',
  };

  useEffect(() => {
    axios.get(NOTION_URL[key]).then((res) => setContents(res.data));
  }, []);

  return { contents };
};
