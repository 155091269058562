import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  useConfirmB2bOrderByBuyerMutation,
  useConfirmB2bOrderBySellerMutation,
} from '@/generated/graphql-react-query';
import { PATH } from '@/routes';

export const useConfirmB2bOrderHook = () => {
  const { mutateAsync, isLoading } = useConfirmB2bOrderBySellerMutation();
  const { mutateAsync: mutateAsyncForBuyer, isLoading: isLoadingForBuyer } =
    useConfirmB2bOrderByBuyerMutation();
  const history = useHistory();
  const { t } = useTranslation(['orderInfo']);

  const handleBySeller = async (
    orderId: string,
    details: {
      id: string;
      inventoryQuantity: number;
      orderQuantity: number;
    }[],
  ) => {
    try {
      await mutateAsync({
        input: {
          id: orderId,
          update: details,
        },
      });

      const orderDetailPath = PATH.ORDER_DETAIL.split('/');
      orderDetailPath.splice(3, 1, orderId);
      history.replace(orderDetailPath.join('/'));
      message.success(t('orderInfo:checkPage.message.confirmOrderSuccess'));
    } catch (error) {
      message.error(t('orderInfo:checkPage.message.confirmOrderError'));
    }
  };

  const handleByBuyer = async (orderId: string) => {
    try {
      await mutateAsyncForBuyer({
        confirmB2BOrderByBuyerId: orderId,
      });
      message.success('최종 주문이 확인되었습니다.');
    } catch (error) {
      message.error(t('orderInfo:checkPage.message.confirmOrderError'));
    }
  };

  return {
    confirmB2bOrderBySeller: handleBySeller,
    confirmB2bOrderByBuyer: handleByBuyer,
    isLoadingConfirmB2bOrderBySeller: isLoading,
    isLoadingConfirmB2bOrderByBuyer: isLoadingForBuyer,
  };
};
