import { notification } from 'antd';

type NotificationType = 'success' | 'error' | 'info';

const NotificationMessageBuilder = (message: string) => {
  // eslint-disable-next-line react/no-danger
  if (message) return <div dangerouslySetInnerHTML={{ __html: message }} />;
  return <div />;
};

export const useNotification = (
  type: NotificationType,
  message: string,
  description?: string,
  duration?: number
): void => {
  return notification[type]({
    message: NotificationMessageBuilder(message),
    description,
    duration,
  });
};
