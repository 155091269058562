import styled from '@emotion/styled';
import { AgTable, Card, Empty } from '@gomi/component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ImportLicensingSheetStepEnum } from '@/generated/graphql-react-query';
import { useContentListHook } from '@/hooks/importLicensing/details/useContentListHook';
import { IParams } from '@/types/importLicensing/details';
import { ProductListColumn } from '@/views/importLicensing/importLicensingDetail/estimateStep/ProductListColumn';
import { ProductListControlBar } from '@/views/importLicensing/importLicensingDetail/estimateStep/ProductListControlBar';

const CustomAgTable = styled(AgTable)`
  .ag-root-wrapper-body.ag-layout-normal {
    ${tw`gm-h-auto`}
  }
`;

export const ProductList = () => {
  const { t } = useTranslation(['importLicensingInfo']);
  const { importLicensingId } = useParams<IParams>();

  const { list, isLoading } = useContentListHook({
    step: ImportLicensingSheetStepEnum.CreateProduct,
    importLicensingId,
  });

  return (
    <Card
      className='gm-w-full'
      header={<ProductListControlBar />}
      isContentsBackground={false}
    >
      <CustomAgTable
        loading={isLoading}
        rowData={list}
        columnDefs={ProductListColumn()}
        defaultColDef={{ resizable: true }}
        noRowsOverlayComponent={() => (
          <Empty size='small' onShowImage>
            {t('importLicensingInfo:table.emptyProducts')}
          </Empty>
        )}
        enableBrowserTooltips
      />
    </Card>
  );
};
