import { ALL } from '@/constant';
import {
  OrderChannelEnum,
  useSalesAmountByProductQuery,
} from '@/generated/graphql-react-query';
import { IReportControl } from '@/types/report/type';

export const useSalesAmountByProductHook = ({
  startDate,
  endDate,
  brandId,
  channel,
  gift,
  orderStatus,
  paymentStatus,
  shippingStatus,
  salesCode,
  countryId,
}: IReportControl) => {
  const {
    data: salesAmountByProductList,
    isLoading,
    refetch: refetchSalesAmountByProduct,
  } = useSalesAmountByProductQuery({
    input: {
      filter: {
        countryId,
        startOrderedAt: startDate,
        endOrderedAt: endDate,
        ...(brandId !== ALL && { brandId }),
        ...(channel !== ALL && { channel }),
        ...(gift !== ALL && { giftType: gift }),
        ...(orderStatus !== ALL && { orderStatus }),
        ...(paymentStatus !== ALL && { paymentStatus: [paymentStatus] }),
        ...(shippingStatus !== ALL && { shippingStatus: [shippingStatus] }),
        ...(salesCode !== ALL && { salesCode: [salesCode] }),
      },
    },
  });

  const transformList =
    salesAmountByProductList?.salesAmountByProduct.node.map((item) => {
      const channelObject: { [key in OrderChannelEnum]: number } = item.channels.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.channelName]: cur.salesAmount,
        }),
        {
          ETC: 0,
          GOMI: 0,
          LAZADA: 0,
          LAZADA_BRAND: 0,
          MALL: 0,
          SENDO: 0,
          SENDO_BRAND: 0,
          SHOPEE: 0,
          SHOPEE_BRAND: 0,
          TIKI: 0,
          TIKI_BRAND: 0,
          TIKTOK: 0,
          TIKTOK_BRAND: 0,
        },
      );

      return {
        ...channelObject,
        name: item.productName,
        total: Object.values(channelObject).reduce(
          (acc: number, cur: number) => acc + cur,
          0,
        ),
      };
    }) || [];

  return {
    salesAmountByProduct: {
      list: transformList.length ? transformList.sort((a, b) => b.total - a.total) : [],
      isLoading,
      refetch: refetchSalesAmountByProduct,
    },
  };
};
