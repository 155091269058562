import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AgTable, Button, Card, Empty } from '@gomi/component';
import { Divider, Form, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { RegisterB2bOrderInputType } from '@/generated/graphql-react-query';
import { useGetB2bOrderHook } from '@/hooks/order/common/useGetB2bOrderHook';
import { useRegisterB2bOrderHook } from '@/hooks/order/create/useRegisterB2bOrderHook';
import { useUpdateB2bOrderQuantitiesHook } from '@/hooks/order/create/useUpdateB2bOrderQuantitiesHook';
import { PATH } from '@/routes';
import { FilterControlBar } from '@/views/order/create/FilterControlBar';
import { getListColumn } from '@/views/order/create/getListColumn';
import { OrderForm } from '@/views/order/create/OrderForm';

export const List = () => {
  const { t } = useTranslation(['orderInfo']);
  const { orderId } = useParams<{ orderId: string }>();
  const [form] = Form.useForm<Omit<RegisterB2bOrderInputType, 'orderDetails'>>();
  const history = useHistory();

  const { b2bOrder, isB2bOrderLoading } = useGetB2bOrderHook(orderId);
  const { b2bOrderQuantities, onOrderQuantityChange } =
    useUpdateB2bOrderQuantitiesHook(orderId);
  const { registerB2bOrder, isLoadingRegisterB2bOrder } = useRegisterB2bOrderHook({
    onSuccess: () => {
      const orderDetailPath = PATH.ORDER_CHECK.split('/');
      orderDetailPath.splice(3, 1, orderId);
      history.replace(orderDetailPath.join('/'));
      message.success(t('orderInfo:createPage.message.registerOrderSuccess'));
    },
    onError: () => message.error(t('orderInfo:createPage.message.registerOrderError')),
  });

  const listColumn = getListColumn({
    t,
    b2bOrderQuantities,
    onOrderQuantityChange,
  });

  const validateOrderQuantities = async () => {
    const noOrderQuantities = b2bOrderQuantities.filter(
      ({ orderQuantity }) => orderQuantity <= 0,
    );

    return noOrderQuantities.length >= b2bOrderQuantities.length
      ? Promise.reject()
      : Promise.resolve();
  };

  const handleRegisterOrderConfirmOk = async () => {
    try {
      await validateOrderQuantities();

      const receiverInfoValues = form.getFieldsValue();
      registerB2bOrder({
        input: {
          id: orderId,
          update: {
            orderDetails: b2bOrderQuantities.map(({ id, orderQuantity }) => ({
              id,
              orderQuantity,
            })),
            ...receiverInfoValues,
          },
        },
      });
    } catch {
      message.error(t('orderInfo:createPage.message.registerOrderValidate'));
    }
  };

  const handleRegisterOrder = async () => {
    await form.validateFields();

    Modal.confirm({
      title: t('orderInfo:createPage.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('orderInfo:createPage.message.registerOrderConfirm'),
      okText: t('orderInfo:createPage.button.register'),
      cancelText: t('orderInfo:createPage.button.cancel'),
      cancelButtonProps: { disabled: isLoadingRegisterB2bOrder },
      closable: !isLoadingRegisterB2bOrder,
      centered: true,
      onOk: handleRegisterOrderConfirmOk,
    });
  };

  console.log(b2bOrder);

  return (
    <>
      <Card
        className='gm-h-full'
        header={<FilterControlBar id={orderId} />}
        isContentsBackground={false}
      >
        <AgTable
          loading={isB2bOrderLoading}
          rowData={b2bOrder.orderDetails}
          columnDefs={listColumn}
          defaultColDef={{ resizable: true }}
          noRowsOverlayComponent={() => (
            <Empty onShowImage>{t('orderInfo:createPage.message.empty')}</Empty>
          )}
          enableBrowserTooltips
        />
      </Card>

      <Divider />

      <OrderForm form={form} />

      <div className='gm-w-full gm-mt-10'>
        <Button
          btnType='filled'
          className='gm-w-full'
          color='primary'
          onClick={handleRegisterOrder}
        >
          {t('orderInfo:createPage.title')}
        </Button>
      </div>
    </>
  );
};
