import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { DeleteOutlined } from '@ant-design/icons';
import { dates } from '@gomi/utils';
import { Popconfirm } from 'antd';
import { TFunction } from 'i18next';

interface IListColumn {
  t: TFunction;
  isDoneStep: boolean;
  onDeleteProduct(id: string): void;
}

/** list column */
export const ListColumn = ({
  t,
  isDoneStep,
  onDeleteProduct,
}: IListColumn): ColDef[] | ColGroupDef[] => {
  const convertYyyyMMdd = (params: ValueFormatterParams) =>
    params.value ? dates.getFormatYYYYMMDD(params.value) : '';

  return [
    {
      headerName: t('importLicensingInfo:table.no'),
      width: 55,
      cellStyle: { textAlign: 'center' },
      cellRenderer: ({ rowIndex }: ICellRendererParams) => rowIndex + 1,
    },
    {
      headerName: t('importLicensingInfo:table.productName'),
      field: 'contentName',
      cellClass: 'gm-flex gm-items-center',
      width: 240,
      minWidth: 240,
      flex: 1,
      cellRenderer: ({ data }: ICellRendererParams) => {
        return `${data.contentKrName} / ${data.contentName}`;
      },
    },
    {
      headerName: t('importLicensingInfo:table.brandName'),
      field: 'brandKrName',
      cellClass: 'gm-flex gm-items-center',
      cellRenderer: ({ data }: ICellRendererParams) => {
        return `${data.brandKrName} / ${data.brandName}`;
      },
      width: 220,
    },
    {
      headerName: t('importLicensingInfo:table.manufacturerName'),
      field: 'manufacturerKrName',
      cellClass: 'gm-flex gm-items-center',
      cellRenderer: ({ data }: ICellRendererParams) => {
        return `${data.manufacturerKrName} / ${data.manufacturerName}`;
      },
      width: 220,
    },
    {
      headerName: t('importLicensingInfo:table.salesPrice'),
      field: 'salesPrice',
      cellClass: 'gm-flex gm-items-center',
      width: 130,
      valueFormatter: ({ value }: ValueFormatterParams) => value.toLocaleString(),
    },
    {
      headerName: t('importLicensingInfo:table.desiredSalesPrice', { unit: '₫' }),
      field: 'desiredSalesPrice',
      cellClass: 'gm-flex gm-items-center',
      width: 130,
      valueFormatter: ({ value }: ValueFormatterParams) => value.toLocaleString(),
    },
    {
      headerName: t('importLicensingInfo:table.createdAt'),
      field: 'createdAt',
      cellClass: 'gm-flex gm-items-center',
      width: 120,
      valueFormatter: convertYyyyMMdd,
    },
    {
      cellClass: 'gm-flex gm-items-center',
      width: 60,
      cellRenderer: ({ data }: ICellRendererParams) =>
        !isDoneStep ? (
          <Popconfirm
            placement='topRight'
            title={t(
              'importLicensingInfo:detailPage.steps.first.content.deleteConfirm.title',
            )}
            onConfirm={() => onDeleteProduct(data.id)}
            okText={t(
              'importLicensingInfo:detailPage.steps.first.content.deleteConfirm.ok',
            )}
            cancelText={t(
              'importLicensingInfo:detailPage.steps.first.content.deleteConfirm.cancel',
            )}
          >
            <DeleteOutlined
              className='gm-flex gm-justify-center gm-items-center gm-w-full gm-h-full'
              onClick={(e) => e.stopPropagation()}
            />
          </Popconfirm>
        ) : null,
    },
  ];
};
