import i18next from 'i18next';

import { ALL } from '@/constant';
import { CountryEnum } from '@/generated/graphql-react-query';

export const defaultOption = {
  value: ALL,
  title: i18next.t('partnerInfo:control.label.all'),
};

/** 나라별 통화 기호 */
export const CURRENCY_UNIT: Record<CountryEnum, string> = {
  [CountryEnum.Vn]: '₫',
  [CountryEnum.Th]: '฿',
  [CountryEnum.Cl]: '',
  [CountryEnum.Jp]: '',
  [CountryEnum.Kr]: '',
  [CountryEnum.Sg]: '',
};
