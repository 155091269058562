import { types } from 'mobx-state-tree';

/**
 * mobx-state-tree 유틸
 */
export const mst = {
  /** types 유틸 */
  types: {
    /** enum 유틸 타입 */
    enumeration: <T extends string>(enumObj: Object) => {
      return types.enumeration<T>(Object.values(enumObj));
    },
  },
};
