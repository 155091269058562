import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Empty } from '@gomi/component';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { AuthContainer } from '@/container';
import { SlackChannelEnum, useSendSlackMutation } from '@/generated/graphql-react-query';
import { Header } from '@/views/settlement/common/monthlyDashboard/Header';

const { confirm } = Modal;

interface IEmptyCardProps {
  yearMonth: string;
}

export const EmptyCard = ({ yearMonth }: IEmptyCardProps) => {
  const { userInfo } = AuthContainer.useContainer();
  const { t } = useTranslation('settlementInfo');

  const { mutateAsync: sendSlackMutation } = useSendSlackMutation({});

  const handleConfirm = () => {
    confirm({
      title: t('empty.modal.title'),
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <div>{t('empty.modal.message.message1')}</div>
          <div>{t('empty.modal.message.message2')}</div>
        </>
      ),
      okText: t('empty.modal.button.inquiries'),
      cancelText: t('empty.modal.button.cancel'),
      centered: true,
      async onOk() {
        await sendSlackMutation({
          input: {
            message: `${userInfo?.partnerMe?.company?.companyKrName} 에서 ${yearMonth} 월에 대한 정산 문의를 요청하셨습니다.`,
            channel: SlackChannelEnum.WorkRequest,
          },
        });
      },
    });
  };

  return (
    <Card
      className='gm-mb-4 gm-h-full'
      header={
        <Header
          header={t('monthlyDashBoard.totalSettlementInfo')}
          isShowExchangeRate={false}
        />
      }
      articleClassName='gm-self-center'
    >
      <Empty onShowImage>
        <div className='gm-text-gray-900'>{t('empty.message.message1')}</div>
        <div>{t('empty.message.message2')}</div>
        <div className='gm-mt-3 gm-h-full'>
          <Button onClick={handleConfirm}>{t('empty.button.inquiries')}</Button>
        </div>
      </Empty>
    </Card>
  );
};
