import { MutateOptions, UseMutationOptions } from '@tanstack/react-query';

import {
  Exact,
  RequestConfirmB2bOrderMutation,
  UpdateDisableB2bOrderMutation,
  useRequestConfirmB2bOrderMutation,
  useUpdateDisableB2bOrderMutation,
} from '@/generated/graphql-react-query';

export const useUpdateB2bOrderHook = () => {
  const { mutateAsync: requestConfirmB2bOrderMutate } =
    useRequestConfirmB2bOrderMutation();
  const { mutate: updateDisableB2bOrderMutate } = useUpdateDisableB2bOrderMutation();

  const requestConfirmB2bOrder = async (
    id: string,
    options?: UseMutationOptions<
      RequestConfirmB2bOrderMutation,
      unknown,
      Exact<{
        requestConfirmB2BOrderId: string;
      }>,
      unknown
    >,
  ) => {
    await requestConfirmB2bOrderMutate({ requestConfirmB2BOrderId: id }, options);
  };

  const toggleDisableOfB2bOrder = (
    id: string,
    options?: MutateOptions<
      UpdateDisableB2bOrderMutation,
      unknown,
      Exact<{
        disableB2BOrderId: string;
      }>,
      unknown
    >,
  ) => {
    updateDisableB2bOrderMutate({ disableB2BOrderId: id }, options);
  };

  return {
    requestConfirmB2bOrder,
    toggleDisableOfB2bOrder,
  };
};
