import { UseMutationOptions } from '@tanstack/react-query';

import {
  DtoRegisterB2bOrderInput,
  Exact,
  RegisterB2bOrderMutation,
  useRegisterB2bOrderMutation,
} from '@/generated/graphql-react-query';

export const useRegisterB2bOrderHook = (
  options?: UseMutationOptions<
    RegisterB2bOrderMutation,
    unknown,
    Exact<{
      input: DtoRegisterB2bOrderInput;
    }>,
    unknown
  >,
) => {
  const { mutate, isLoading, ...rest } = useRegisterB2bOrderMutation(options);

  return {
    registerB2bOrder: mutate,
    isLoadingRegisterB2bOrder: isLoading,
    ...rest,
  };
};
