import { Card } from '@gomi/component';
import { ResponsivePie } from '@nivo/pie';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSalesRatioByChannel } from '@/hooks/home/useSalesRatioByChannel';
import { useStore } from '@/stores';
import { HomeEmpty } from '@/views/home/Empty';

const colors = [
  '#73D13D',
  '#1890FF',
  '#FFC53D',
  '#FF4D4F',
  '#D9D9D9',
  '#EB2F96',
  '#FA541C',
];

export const SalesRatioByChannel = observer(() => {
  const { t } = useTranslation(['home']);
  const {
    homeModel: { startDate, endDate },
  } = useStore();

  const { data, isLoading } = useSalesRatioByChannel({
    startDate,
    endDate,
  });
  const chartData = data.map((item, index) => ({
    id: item.channel.toLowerCase(),
    label: item.channel.toLowerCase(),
    value: item.ratio,
    color: colors[index] || '',
  }));

  return (
    <Card
      header={t('home:dashboard.percentageOfSalesByChannel.title')}
      loading={isLoading}
    >
      <div className='gm-h-[24rem] gm-text-sm'>
        {(!chartData.length && <HomeEmpty />) || null}
        {(chartData.length && (
          <ResponsivePie
            data={chartData}
            colors={(pie) => pie.data.color}
            valueFormat={(value) => `${value}%`}
            margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor='#333333'
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [['darker', 2]],
            }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
          />
        )) ||
          null}
      </div>
    </Card>
  );
});
