import { ALL } from '@/constant';
import { useChannelsForReportQuery } from '@/generated/graphql-react-query';
import { IReportControl } from '@/types/report/type';

export const useChannelsHook = ({
  startDate,
  endDate,
  brandId,
  channel,
  gift,
  orderStatus,
  paymentStatus,
  shippingStatus,
  salesCode,
  countryId,
}: IReportControl) => {
  const { data: channelList, isLoading } = useChannelsForReportQuery({
    input: {
      filter: {
        countryId,
        startOrderedAt: startDate,
        endOrderedAt: endDate,
        ...(brandId !== ALL && { brandId }),
        ...(channel !== ALL && { channel }),
        ...(gift !== ALL && { giftType: gift }),
        ...(orderStatus !== ALL && { orderStatus }),
        ...(paymentStatus !== ALL && { paymentStatus: [paymentStatus] }),
        ...(shippingStatus !== ALL && { shippingStatus: [shippingStatus] }),
        ...(salesCode !== ALL && { salesCode: [salesCode] }),
      },
    },
  });

  return {
    channelList: channelList?.channelsForReport || [],
    isLoading,
  };
};
