import styled from '@emotion/styled';
import { AgTable, Card, Empty } from '@gomi/component';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import {
  ImportLicensingDocumentStatusEnum,
  ImportLicensingSheetStepEnum,
} from '@/generated/graphql-react-query';
import { useContentListHook } from '@/hooks/importLicensing/details/useContentListHook';
import { useDeleteContentHook } from '@/hooks/importLicensing/details/useDeleteContentHook';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';
import { ControlBar } from '@/views/importLicensing/importLicensingDetail/productCreateStep/ControlBar';
import { ListColumn } from '@/views/importLicensing/importLicensingDetail/productCreateStep/ListColumn';

const CustomAgTable = styled(AgTable)`
  ${tw`gm-min-h-[auto]`}

  .ag-root-wrapper-body.ag-layout-normal {
    ${tw`gm-h-auto`}
  }
`;

export const List = observer(() => {
  const { importLicensingId } = useParams<IParams>();
  const { t } = useTranslation(['importLicensingInfo']);

  const {
    importLicensingDetailModel: { steps },
  } = useStore();

  /** 상품 목록 호출 */
  const { list, isLoading, refetch } = useContentListHook({
    importLicensingId,
    step: ImportLicensingSheetStepEnum.CreateProduct,
  });

  const { mutateAsync: deleteProduct } = useDeleteContentHook();

  const handleDeleteProduct = async (id: string) => {
    const result = await deleteProduct({
      deleteImportLicensingContentId: id,
    });

    if (result.deleteImportLicensingContent.id) {
      refetch();
    }
  };

  const isDoneStep = steps.status === ImportLicensingDocumentStatusEnum.Done;
  return (
    <Card
      className='gm-h-full gm-w-full'
      header={<ControlBar />}
      isContentsBackground={false}
    >
      <CustomAgTable
        loading={isLoading}
        rowData={list}
        columnDefs={ListColumn({ t, isDoneStep, onDeleteProduct: handleDeleteProduct })}
        defaultColDef={{ resizable: true }}
        noRowsOverlayComponent={() => (
          <Empty onShowImage>{t('importLicensingInfo:table.emptyProducts')}</Empty>
        )}
        enableBrowserTooltips
      />
    </Card>
  );
});
