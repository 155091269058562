import styled from '@emotion/styled';
import { FC } from 'react';
import tw from 'twin.macro';

import { DashBoard } from '@/views/settlement/consignment/DashBoard';
import { List } from '@/views/settlement/consignment/List';

interface IPropsPurchaseBySalesDetail {
  /** partner settlement id */
  partnerId: string;
}
/** style */
const Wrapper = styled.div`
  ${tw`gm-h-full gm-flex gm-flex-col gm-gap-4`}
`;

/** component: 월별 정산내역 > 위탁 상세 */
const ConsignmentDetail: FC<IPropsPurchaseBySalesDetail> = ({ partnerId }) => {
  return (
    <Wrapper>
      <DashBoard partnerId={partnerId} />
      <List partnerId={partnerId} />
    </Wrapper>
  );
};

export default ConsignmentDetail;
