import { Spin } from 'antd';
import { isEmpty } from 'lodash-es';
import React, { ReactNode } from 'react';
import tw, { styled } from 'twin.macro';

/** style props */
type TPropsWrapper = Pick<
  IPropsCard,
  'isFullWidth' | 'isContentsBackground' | 'arrayArticles'
>;

/** style */
const Wrapper = styled.div<TPropsWrapper>`
  width: ${(props) => (props.isFullWidth ? '100%' : 'auto')};

  ${tw`gm-flex gm-flex-col gm-border gm-border-gray-400`}

  .ant-spin-nested-loading {
    ${tw`gm-h-full`}

    .ant-spin-container {
      ${tw`gm-flex gm-flex-col gm-h-full`}
    }
  }

  .card-header {
    ${tw`gm-p-4 gm-font-semibold gm-overflow-x-auto gm-text-base`}

    &:not(:last-child) {
      ${tw`gm-border-b gm-border-gray-400`}
    }
  }

  .card-section {
    ${(props) => (props.isContentsBackground ? tw`gm-bg-gray-100` : tw`gm-bg-white`)}

    ${tw`gm-flex gm-flex-1 gm-overflow-x-auto`}

    .card-article {
      /** article 이 여러개일 경우 flex로 기본 width 비율이 flex-1 로 되고 없거나 1개이면 display: block 처리 */
      ${(props) =>
        (props.arrayArticles?.length || 0) > 1
          ? tw`gm-flex gm-flex-1 gm-w-fit`
          : tw`gm-flex gm-flex-col gm-w-full`}

      ${tw`gm-p-4`}

      &:not(:last-child) {
        ${tw`gm-border-r gm-border-gray-400`}
      }
    }
  }
`;

/** props */
export interface IPropsCard {
  /** 콘텐츠영역 background color 적용여부 */
  isContentsBackground?: boolean;
  /** wrapper css class */
  className?: string;
  /** header css class */
  headerClassName?: string;
  /** section css class */
  sectionClassName?: string;
  /** article css class */
  articleClassName?: string;
  /** width */
  isFullWidth?: boolean;
  /** header text 또는 컴포넌트 */
  header?: ReactNode;
  /** children */
  children?: ReactNode;
  /** 컨텐츠영역이 배열형으로 들어갈경우 사용 */
  arrayArticles?: ReactNode[];
  loading?: boolean;
}

/** component: 기본 카드 컴포넌트 */
export const Card = ({
  isContentsBackground = false,
  className,
  headerClassName,
  sectionClassName,
  articleClassName,
  isFullWidth,
  header,
  children,
  arrayArticles,
  loading = false,
}: IPropsCard) => {
  return (
    <Wrapper
      className={className}
      isFullWidth={isFullWidth}
      isContentsBackground={isContentsBackground}
    >
      <Spin spinning={loading}>
        {header && <div className={`card-header ${headerClassName || ''}`}>{header}</div>}
        {(!isEmpty(children) || !isEmpty(arrayArticles)) && (
          <div className={`card-section ${sectionClassName || ''}`}>
            {children && (
              <div className={`card-article ${articleClassName || ''}`}>{children}</div>
            )}
            {arrayArticles &&
              arrayArticles.map((v, idx) => (
                <div className={`card-article ${articleClassName}`} key={idx}>
                  {v}
                </div>
              ))}
          </div>
        )}
      </Spin>
    </Wrapper>
  );
};
