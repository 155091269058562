import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AgTable, Button, Card, Empty } from '@gomi/component';
import { Divider, Form, message, Modal } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { RegisterB2bOrderInputType } from '@/generated/graphql-react-query';
import { useConfirmB2bOrderHook } from '@/hooks/order/check/useConfirmB2bOrderHook';
import { useUpdateB2bInventoryQuantitiesHook } from '@/hooks/order/check/useUpdateB2bInventoryQuantitiesHook';
import { useGetB2bOrderHook } from '@/hooks/order/common/useGetB2bOrderHook';
import { FilterControlBar } from '@/views/order/check/FilterControlBar';
import { getListColumn } from '@/views/order/check/getListColumn';
import { OrderForm } from '@/views/order/check/OrderForm';

export const List = () => {
  const { t } = useTranslation(['orderInfo']);
  const { orderId } = useParams<{ orderId: string }>();
  const [form] = Form.useForm<Omit<RegisterB2bOrderInputType, 'orderDetails'>>();
  const history = useHistory();

  const { b2bOrder, isB2bOrderLoading, isBuyer } = useGetB2bOrderHook(orderId);
  const { b2bInventoryQuantities, onInventoryQuantityChange } =
    useUpdateB2bInventoryQuantitiesHook(orderId);
  const { confirmB2bOrderBySeller, isLoadingConfirmB2bOrderBySeller } =
    useConfirmB2bOrderHook();

  const listColumn = getListColumn({
    t,
    b2bInventoryQuantities,
    onInventoryQuantityChange,
  });

  const validateOrderQuantities = async () => {
    const wrongOrderQuantities = b2bInventoryQuantities.find(
      ({ inventoryQuantity }, index) =>
        inventoryQuantity < 0 ||
        inventoryQuantity > b2bOrder.orderDetails[index].orderQuantity,
    );

    return wrongOrderQuantities ? Promise.reject() : Promise.resolve();
  };

  const handleConfirmOrderConfirmOk = async () => {
    try {
      await validateOrderQuantities();

      await confirmB2bOrderBySeller(
        orderId,
        b2bOrder.orderDetails.map((detail, index) => ({
          id: detail.id,
          inventoryQuantity: b2bInventoryQuantities[index].inventoryQuantity,
          orderQuantity: detail.orderQuantity,
        })),
      );
    } catch {
      message.error(t('orderInfo:checkPage.message.confirmOrderValidate'));
    }
  };

  const handleConfirmOrder = async () => {
    await form.validateFields();

    Modal.confirm({
      title: t('orderInfo:checkPage.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('orderInfo:checkPage.message.confirmOrderConfirm'),
      okText: t('orderInfo:checkPage.button.confirm'),
      cancelText: t('orderInfo:checkPage.button.cancel'),
      cancelButtonProps: { disabled: isLoadingConfirmB2bOrderBySeller },
      closable: !isLoadingConfirmB2bOrderBySeller,
      centered: true,
      onOk: handleConfirmOrderConfirmOk,
    });
  };

  useEffect(() => {
    // 구매자일 경우 상세화면으로 이동
    if (isBuyer) {
      history.push(`/order/detail/${orderId}`);
    }
  }, [isBuyer]);

  return (
    <>
      <Card
        className='gm-h-full'
        header={<FilterControlBar id={orderId} />}
        isContentsBackground={false}
      >
        <AgTable
          loading={isB2bOrderLoading}
          rowData={b2bOrder.orderDetails}
          columnDefs={listColumn}
          defaultColDef={{ resizable: true }}
          noRowsOverlayComponent={() => (
            <Empty onShowImage>{t('orderInfo:checkPage.message.empty')}</Empty>
          )}
          enableBrowserTooltips
        />
      </Card>

      <Divider />

      <OrderForm form={form} />

      <Button
        btnType='filled'
        className='gm-w-full gm-mt-3'
        color='primary'
        onClick={handleConfirmOrder}
      >
        {t('orderInfo:checkPage.title')}
      </Button>
    </>
  );
};
