import { ALL } from '@/constant';
import {
  CountryEnum,
  StockTypeEnum,
  useStockExportExcelMutation,
  useStockLogsExportExcelMutation,
} from '@/generated/graphql';
import { StockLocationEnum } from '@/generated/graphql-react-query';
import { downloadFileFromUrl } from '@/lib/downloadFileFromUrl';
import { IReportControl } from '@/types/report/type';

export const useStockDownloadHook = ({
  startDate,
  endDate,
  brandId,
  countryId,
  location,
}: Pick<IReportControl, 'startDate' | 'endDate' | 'brandId' | 'countryId'> & {
  location: typeof ALL | StockLocationEnum;
}) => {
  const [downloadExcelForStockLogs] = useStockLogsExportExcelMutation({
    onCompleted: (res) => {
      downloadFileFromUrl({ url: res.stockLogsExportExcel });
    },
  });

  const [downloadExcelForStock] = useStockExportExcelMutation({
    onCompleted: (res) => {
      downloadFileFromUrl({ url: res.stockExportExcel });
    },
  });

  const onClickDownloadExcelForStockLogs = ({
    stockLogsType,
  }: {
    stockLogsType: StockTypeEnum;
  }) => {
    downloadExcelForStockLogs({
      variables: {
        input: {
          filter: {
            brandIds: brandId === ALL ? [] : [brandId],
            countryId: countryId as CountryEnum,
            startDateAt: startDate,
            endDateAt: endDate,
            type: stockLogsType,
            locations: location === ALL ? [] : [location],
          },
        },
      },
    });
  };

  const onClickDownloadExcelForStock = () => {
    downloadExcelForStock({
      variables: {
        input: {
          filter: {
            brandIds: brandId === ALL ? [] : [brandId],
            locations: location === ALL ? [] : [location],
            countryId: countryId as CountryEnum,
          },
        },
      },
    });
  };

  return {
    onClickDownloadExcelForStock,
    onClickDownloadExcelForStockLogs,
  };
};
