import { message } from 'antd/es';

import { useCancelOrderDetailMutation } from '@/generated/graphql-react-query';

export const useCancelOrderDetailHook = () => {
  const { mutateAsync, isLoading } = useCancelOrderDetailMutation();

  const handleCancel = async (id: string) => {
    try {
      await mutateAsync({
        cancelOrderDetailId: id,
      });
      message.success('취소되었습니다.');
    } catch (error) {
      message.error((error as Error).message);
    }
  };

  return {
    cancel: handleCancel,
    isLoading,
  };
};
