import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Empty } from '@gomi/component';
import { Divider, Form, Input, message, Space } from 'antd';
import { some, values } from 'lodash-es';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Unit } from '@/component/Unit';
import { CountryEnum, DtoReturnProductRequest } from '@/generated/graphql-react-query';
import { modalStyle } from '@/lib/product/getFormStyle';
import { entryOfCountryPriceField, getEntryOfCountry } from '@/lib/product/modal';
import { ProductFromNameEnum } from '@/types/product/list';
import { NumberInputFormItem } from '@/views/common/FormItems';

const { formItemLayout } = modalStyle;

export const HelperText = styled.div`
  color: #096dd9;
  font-size: 12px;
  align-self: center;
`;

const PriceInfo = styled.div`
  background: #fafafa;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 0.75rem;
  color: #096dd9;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  .anticon {
    margin-right: 0.625rem;
    font-size: 0.875rem;
  }
`;

/** ref */
export interface IRefPriceTab {
  submit: ({ isValidate }: { isValidate?: boolean }) => Promise<IFormPriceTab | null>;
}

export interface IPriceInfo {
  purchaseUnitPrice?: number | null;
  retailPriceKr?: number | null;
  retailPriceTh?: number | null;
  retailPriceVn?: number | null;
  regularSalePriceTh?: number | null;
  regularSalePriceVn?: number | null;
  discountSalePriceThA?: number | null;
  discountSalePriceThB?: number | null;
  discountSalePriceThS?: number | null;
  discountSalePriceVnA?: number | null;
  discountSalePriceVnB?: number | null;
  discountSalePriceVnS?: number | null;
  productGroup: {
    category?: string | null;
    krRecommendedSalesPrice?: string | null;
    productGroupKrName?: string | null;
    productGroupKrNameKeyword?: string | null;
    productGroupName: string;
    productGroupNameKeyword?: string | null;
    recommendedSalesPrice?: string | null;
    brand?: {
      countryIds: Array<CountryEnum>;
      id: string;
      brandName: string;
      brandKrName: string;
    } | null;
    company?: {
      id: string;
      companyName: string;
      companyKrName: string;
    } | null;
  };
}

/** props */
interface IPropsPriceTab {
  /** active 여부 */
  isActive: boolean;
  /** form 컨트롤 boxed 여부 */
  isBoxed: boolean;
  /** 수정 여부 */
  isModify?: boolean;
  /** 상품 정보 */
  productInfo?: IPriceInfo;
}

/** form extends */
type TFormExtends = Pick<
  DtoReturnProductRequest,
  | 'purchaseUnitPrice'
  | 'retailPriceKr'
  | 'retailPriceVn'
  | 'regularSalePriceVn'

  // vn
  | 'retailPriceVn'
  | 'regularSalePriceVn'
  | 'discountSalePriceVnS'
  | 'discountSalePriceVnA'
  | 'discountSalePriceVnB'

  // th
  | 'retailPriceTh'
  | 'regularSalePriceTh'
  | 'discountSalePriceThS'
  | 'discountSalePriceThA'
  | 'discountSalePriceThB'
>;

/** form */
export interface IFormPriceTab extends TFormExtends {
  discountSalePriceVn?: string;
  discountSalePriceTh?: string;
}

/** 필드정보 make 함수 -  */
const makeFieldsInfo = (productInfo?: IPriceInfo): IFormPriceTab => ({
  purchaseUnitPrice: productInfo?.purchaseUnitPrice,
  retailPriceKr: productInfo?.retailPriceKr,
  retailPriceVn: productInfo?.retailPriceVn,
  retailPriceTh: productInfo?.retailPriceTh,
  regularSalePriceVn: productInfo?.regularSalePriceVn,
  regularSalePriceTh: productInfo?.regularSalePriceTh,
  discountSalePriceVnS: productInfo?.discountSalePriceVnS,
  discountSalePriceThS: productInfo?.discountSalePriceThS,
  discountSalePriceVnA: productInfo?.discountSalePriceVnA,
  discountSalePriceThA: productInfo?.discountSalePriceThA,
  discountSalePriceVnB: productInfo?.discountSalePriceVnB,
  discountSalePriceThB: productInfo?.discountSalePriceThB,
  discountSalePriceVn:
    productInfo?.discountSalePriceVnA &&
    productInfo?.discountSalePriceVnB &&
    productInfo?.discountSalePriceVnS
      ? '0'
      : undefined,
  discountSalePriceTh:
    productInfo?.discountSalePriceThA &&
    productInfo?.discountSalePriceThB &&
    productInfo?.discountSalePriceThS
      ? '0'
      : undefined,
});

/** component: 상품상세 > 하단 탭영역 > price tab form */
const PriceTab: ForwardRefRenderFunction<IRefPriceTab, IPropsPriceTab> = (
  { isActive, isBoxed, isModify, productInfo },
  ref,
) => {
  const { t } = useTranslation(['productInfo', 'partnerInfo']);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [form] = Form.useForm<IFormPriceTab>();

  /** ref */
  useImperativeHandle(ref, () => {
    return {
      /** submit & validation */
      submit: async ({ isValidate = true }: { isValidate?: boolean }) => {
        let formValues: IFormPriceTab | null = null;

        try {
          formValues = isValidate
            ? await form.validateFields()
            : form.getFieldsValue(true);
        } catch (e) {
          message.error(t('partnerInfo:modal.message.lackOfInformation'));
        }

        return formValues;
      },
    };
  });

  /** effect */
  useEffect(() => {
    if (isActive && productInfo) {
      const info = makeFieldsInfo(productInfo);

      // field value set
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...info,
      });

      // empty check
      setIsEmpty(!some(values(info), (value) => !!value));
    }
  }, [isActive, productInfo]);

  return (
    <>
      {!isModify && isEmpty ? (
        <Empty size='small'>
          {t('productInfo:message.productTableEmptyWarning1')} <br />
          {t('productInfo:message.productTableEmptyWarning2')}
        </Empty>
      ) : (
        <Form
          form={form}
          name={ProductFromNameEnum.PRICE_FORM}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          autoComplete='off'
        >
          <PriceInfo>
            <ExclamationCircleOutlined />
            <div>
              {t('productInfo:message.priceInfo1')} <br />
              {t('productInfo:message.priceInfo2')} <br />
              {t('productInfo:message.priceInfo3')}
            </div>
          </PriceInfo>
          {/* 매입단가 */}
          <NumberInputFormItem
            formStyle={{ width: 160 }}
            rules={[
              {
                required: true,
                message: t('productInfo:message.purchaseUnitPriceEmpty'),
              },
            ]}
            label={t('productInfo:label.purchaseUnitPrice')}
            value={`${productInfo?.purchaseUnitPrice} ₩`}
            addonAfter={<Unit unit='₩' />}
            name='purchaseUnitPrice'
            boxed={isBoxed}
            disabled={!isModify}
          />
          {/* 소비자가 */}
          <NumberInputFormItem
            formStyle={{ width: 160 }}
            label={t('productInfo:label.retailPriceKr')}
            value={`${productInfo?.retailPriceKr} ₩`}
            rules={[
              {
                required: true,
                message: t('productInfo:message.retailPriceKrEmpty'),
              },
            ]}
            addonAfter={<Unit unit='₩' />}
            name='retailPriceKr'
            boxed={isBoxed}
            disabled={!isModify}
          />
          {getEntryOfCountry(productInfo?.productGroup)?.map((country) => {
            const countryField = entryOfCountryPriceField(t, productInfo)[country];

            return (
              countryField && (
                <div key={country}>
                  <Divider />
                  {/* 해당국가 소비자가 */}
                  <NumberInputFormItem
                    formStyle={{ width: 160 }}
                    label={countryField.retailPrice.label}
                    value={countryField.retailPrice.value}
                    rules={[
                      {
                        required: true,
                        message: t('productInfo:message.retailPriceVnEmpty'),
                      },
                    ]}
                    addonAfter={<Unit unit={countryField.unit} />}
                    name={countryField.retailPrice.name}
                    boxed={isBoxed}
                    disabled={!isModify}
                  />

                  {/* 해당국가 상시 판매가 */}
                  <NumberInputFormItem
                    formStyle={{ width: 160 }}
                    label={countryField.regularSalePrice.label}
                    value={countryField.regularSalePrice.value}
                    rules={[
                      {
                        required: true,
                        message: countryField.regularSalePrice.message,
                      },
                    ]}
                    addonAfter={<Unit unit={countryField.unit} />}
                    name={countryField.regularSalePrice.name}
                    boxed={isBoxed}
                    disabled={!isModify}
                  />

                  <Form.Item
                    {...formItemLayout}
                    style={{ marginBottom: 0 }}
                    label={countryField.discountSalePrice.label}
                    name={countryField.discountSalePrice.name}
                    extra={
                      <HelperText>
                        {t('productInfo:message.discountSalePriceHelp')}
                      </HelperText>
                    }
                    rules={
                      !isModify
                        ? []
                        : [
                            {
                              required: true,
                              message: countryField.discountSalePrice.message,
                            },
                          ]
                    }
                  >
                    <Space direction='vertical' style={{ marginBottom: '.5rem' }}>
                      {/* 할인 판매가 S급 */}
                      <NumberInputFormItem
                        label={null}
                        style={{ marginBottom: 0 }}
                        addonBefore={
                          <Input
                            disabled
                            style={{ width: 50 }}
                            value={t('productInfo:label.discountSalePriceS')}
                          />
                        }
                        addonAfter={<Unit unit={countryField.unit} />}
                        rules={[
                          {
                            required: true,
                            message: <></>,
                          },
                        ]}
                        name={countryField.discountSalePriceS.name}
                        value={countryField.discountSalePriceS.value}
                        boxed={isBoxed}
                        disabled={!isModify}
                      />
                      {/* 할인 판매가 A급 */}
                      <NumberInputFormItem
                        label={null}
                        style={{ marginBottom: 0 }}
                        addonBefore={
                          <Input
                            disabled
                            style={{ width: 50 }}
                            value={t('productInfo:label.discountSalePriceA')}
                          />
                        }
                        addonAfter={<Unit unit={countryField.unit} />}
                        rules={[
                          {
                            required: true,
                            message: <></>,
                          },
                        ]}
                        value={countryField.discountSalePriceA.value}
                        name={countryField.discountSalePriceA.name}
                        boxed={isBoxed}
                        disabled={!isModify}
                      />
                      {/* 할인 판매가 B급 */}
                      <NumberInputFormItem
                        label={null}
                        style={{ marginBottom: 0 }}
                        addonBefore={
                          <Input
                            disabled
                            style={{ width: 50 }}
                            value={t('productInfo:label.discountSalePriceB')}
                          />
                        }
                        addonAfter={<Unit unit={countryField.unit} />}
                        rules={[
                          {
                            required: true,
                            message: <></>,
                          },
                        ]}
                        value={countryField.discountSalePriceB.value}
                        name={countryField.discountSalePriceB.name}
                        boxed={isBoxed}
                        disabled={!isModify}
                      />
                    </Space>
                  </Form.Item>
                </div>
              )
            );
          })}
        </Form>
      )}
    </>
  );
};

export default forwardRef(PriceTab);
