import { AgGridReact } from '@ag-grid-community/react';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { AgTable, Card, Empty, Tooltip } from '@gomi/component';
import { Tabs } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import {
  SettlementOrderItemSettlementStatusEnum as StatusEnum,
  useSettlementConsignmentPartnerProductListByPartnerQuery,
} from '@/generated/graphql-react-query';
import { ListColumn } from '@/views/settlement/consignment/ListColumn';

import { OrdersModal } from './OrdersModal';

interface IPropsList {
  /** 파트너 아이디 */
  partnerId: string;
}

const { TabPane } = Tabs;

/** style */
const Wrapper = styled.div`
  ${tw`gm-flex gm-flex-col gm-flex-1`}
`;

/** component: 월별 정산내역 > 위탁 상세 > 판매/상계 내역 리스트 */
const List: FC<IPropsList> = ({ partnerId }) => {
  const [activeTab, setActiveTab] = useState<StatusEnum>(StatusEnum.Settlement);
  const { t } = useTranslation('settlementInfo');
  const refGridApi = useRef<AgGridReact>(null);
  const [salesCode, setSalesCode] = useState<string>('');
  const [productName, setProductName] = useState<string>('');

  /** tab 변경시 */
  const handleChangeTab = (tab: StatusEnum) => {
    setActiveTab(tab);
  };

  /** gql - 위탁 리스트 */
  const { data: list } = useSettlementConsignmentPartnerProductListByPartnerQuery(
    {
      input: {
        filter: {
          settlementPartnerId: partnerId,
          settlementStatus: activeTab,
        },
      },
    },
    {
      enabled: !!partnerId,
    },
  );

  const pinnedBottomRowData = [
    {
      productKrName: t('tableColumn.total'),
      channels: list?.settlementConsignmentPartnerProductListByPartner.channels || [],
      sumSalesCount:
        list?.settlementConsignmentPartnerProductListByPartner.totalSalesCount,
      sumCountrySalesPrice:
        list?.settlementConsignmentPartnerProductListByPartner.totalCountrySalesPrice,
      sumSalesPrice:
        list?.settlementConsignmentPartnerProductListByPartner.totalSalesPrice,
    },
  ];

  const listData = list?.settlementConsignmentPartnerProductListByPartner.nodes || [];

  /** 모달 열기시 */
  const handleOpenModal = (salesCodeId: string, productName: string) => {
    setSalesCode(salesCodeId);
    setProductName(productName);
  };

  /** 모달 닫기시 */
  const handleCancelModal = () => {
    setSalesCode('');
    setProductName('');
  };

  /** ag grid에서 탭 변경되었을 때 noRowsOverlayComponent 렌더가 정상적으로 되지 않는 이슈 */
  useEffect(() => {
    if (refGridApi?.current?.api && !listData.length) {
      refGridApi.current.api.showNoRowsOverlay();
    } else if (refGridApi?.current?.api) {
      refGridApi.current.api.hideOverlay();
    }
  }, [activeTab, listData]);

  return (
    <Wrapper>
      <Tabs
        type='card'
        defaultActiveKey={StatusEnum.Settlement}
        activeKey={activeTab}
        onChange={(tab) => handleChangeTab(tab as StatusEnum)}
      >
        <TabPane tab={t('tab.settlement')} key={StatusEnum.Settlement} />

        <TabPane tab={t('tab.setoff')} key={StatusEnum.Setoff} />
      </Tabs>
      <Card
        className='gm-h-[644px] gm-border-t-0'
        header={
          <div className='gm-text-md gm-text-gray-900 gm-flex gm-items-center gm-font-semibold'>
            {t('tableHeader.exportList')}
            <Tooltip title={<div>{t('tableHeader.productClickNotiTooltip')}</div>}>
              <InfoCircleOutlined className='gm-ml-1' />
            </Tooltip>
          </div>
        }
      >
        <AgTable
          ref={refGridApi}
          loading={false}
          columnDefs={ListColumn({ activeTab, onVisibleOrdersModal: handleOpenModal })}
          defaultColDef={{ resizable: true }}
          noRowsOverlayComponent={({ description }: { description: string }) => (
            <Empty>{description}</Empty>
          )}
          noRowsOverlayComponentParams={{
            description:
              activeTab === StatusEnum.Settlement
                ? t('message.tableNoDataMessage')
                : t('message.tableNoSetoffDataMessage'),
          }}
          suppressRowTransform
          rowData={listData}
          pinnedBottomRowData={pinnedBottomRowData}
        />
      </Card>
      <OrdersModal
        isVisible={!!salesCode}
        productName={productName}
        salesCodeId={salesCode}
        settlementPartnerId={partnerId}
        onCancel={handleCancelModal}
        activeTab={activeTab}
      />
    </Wrapper>
  );
};

export { List };
