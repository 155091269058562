import { format } from 'date-fns';

import { ALL } from '@/constant';
import { useSalesStatusByDateQuery } from '@/generated/graphql-react-query';
import { IReportControl } from '@/types/report/type';

// 아래 node 데이터 유형
// [
//   {
//     date: '2023-01-29T00:00:00.000Z',
//     statuses: [
//       {
//         channels: [
//           {
//             amount: 1942400,
//             channelName: 'SHOPEE',
//             count: 24,
//           },
//         ],
//         orderStatus: 'OK',
//       },
//       {
//         channels: [
//           {
//             amount: 598000,
//             channelName: 'SHOPEE',
//             count: 2,
//           },
//         ],
//         orderStatus: 'CANCEL',
//       },
//     ],
//   },
// ];

export const useSalesStatusByDateHook = ({
  startDate,
  endDate,
  brandId,
  channel,
  gift,
  orderStatus,
  paymentStatus,
  shippingStatus,
  salesCode,
  countryId,
}: IReportControl) => {
  const { data, isLoading } = useSalesStatusByDateQuery({
    input: {
      filter: {
        countryId,
        startOrderedAt: startDate,
        endOrderedAt: endDate,
        ...(brandId !== ALL && { brandId }),
        ...(channel !== ALL && { channel }),
        ...(gift !== ALL && { giftType: gift }),
        ...(orderStatus !== ALL && { orderStatus }),
        ...(paymentStatus !== ALL && { paymentStatus: [paymentStatus] }),
        ...(shippingStatus !== ALL && { shippingStatus: [shippingStatus] }),
        ...(salesCode !== ALL && { salesCode: [salesCode] }),
      },
    },
  });

  const result: any[] = [];
  let totalAmount = 0;
  let totalCount = 0;
  let totalChannelDict: { [key: string]: number } = {};

  (data?.salesStatusByDate.node || []).forEach((item) => {
    let eachChannelDict: { [key: string]: number } = {};
    let eachTotalAmount = 0;
    let eachTotalCount = 0;

    // 상품마다 채널별로 금액, 수량을 계산한다.
    item.statuses.forEach((status) => {
      status.channels.forEach((channel) => {
        eachTotalAmount += channel.amount;
        eachTotalCount += channel.count;
        eachChannelDict = {
          ...eachChannelDict,
          [`${channel.channelName}_${status.orderStatus}_AMOUNT`]: channel.amount,
          [`${channel.channelName}_${status.orderStatus}_COUNT`]: channel.count,
        };
      });
    });

    Object.keys(eachChannelDict).forEach((key) => {
      const isKeyInTotalChannelDict = key in totalChannelDict;
      if (isKeyInTotalChannelDict) {
        totalChannelDict = {
          ...totalChannelDict,
          [key]: eachChannelDict[key] + totalChannelDict[key],
        };
      }

      if (!isKeyInTotalChannelDict) {
        totalChannelDict = {
          ...totalChannelDict,
          [key]: eachChannelDict[key],
        };
      }
    });

    // 전체에 대해 채널별로 금액, 수량을 계산한다.
    totalCount += eachTotalCount;
    totalAmount += eachTotalAmount;

    result.push({
      ...eachChannelDict,
      date: format(new Date(item.date), 'yyyy-MM-dd'),
      totalAmount: eachTotalAmount,
      totalCount: eachTotalCount,
    });
  });

  return {
    data: result,
    footer: {
      ...totalChannelDict,
      date: '합계',
      totalCount,
      totalAmount,
    },
    isLoading,
  };
};
