import { Tooltip as AntTooltip } from 'antd';
import { TooltipPropsWithOverlay, TooltipPropsWithTitle } from 'antd/lib/tooltip';
import React, { FC } from 'react';

/** type - antd tooltip props */
type TTooltip = TooltipPropsWithTitle & TooltipPropsWithOverlay;

/** interface */
export interface IPropsTooltip extends TTooltip {}

/** component: tooltip */
export const Tooltip: FC<IPropsTooltip> = ({ title, ...props }) => {
  return (
    <AntTooltip
      overlayClassName='gm-max-w-max'
      {...props}
      title={<div className='gm-text-sm'>{title}</div>}
    >
      {props.children}
    </AntTooltip>
  );
};
