import i18next from 'i18next';
import nextId from 'react-id-generator';

import { UUID_KEY } from '@/constant';
import { RoleEnum } from '@/generated/graphql';
import { BrandListPage } from '@/pages/brand/ListPage';
import { Guide } from '@/pages/guide';
import { HomePage } from '@/pages/home';
import {
  ImportLicensingDetailPage,
  ImportLicensingListPage,
} from '@/pages/importLicensing';
import { OrderCheckPage } from '@/pages/order/OrderCheckPage';
import { OrderCreatePage } from '@/pages/order/OrderCreatePage';
import { OrderDetailPage } from '@/pages/order/OrderDetailPage';
import { OrderManagementPage } from '@/pages/order/OrderManagementPage';
import { ProductListPage } from '@/pages/product/ProductListPage';
import { LogisticReportPage } from '@/pages/report/LogisticReportPage';
import { SalesReportPage } from '@/pages/report/SalesReportPage';
import { SettlementPage } from '@/pages/settlement/SettlementPage';
import { PATH } from '@/routes/constant';
import { IRoute } from '@/routes/types';

export const routeList: IRoute[] = [
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.HOME'),
    path: PATH.HOME,
    page: HomePage,
    role: [RoleEnum.Partner],
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.GUIDE'),
    path: PATH.GUIDE,
    page: Guide,
    role: [RoleEnum.Partner],
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.SALES_REPORT'),
    path: PATH.SALES_REPORT,
    page: SalesReportPage,
    role: [RoleEnum.Partner],
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    path: PATH.LOGISTIC_REPORT,
    title: i18next.t('MENU.LOGISTIC_REPORT'),
    page: LogisticReportPage,
    role: [RoleEnum.Partner],
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.MONTHLY_SETTLEMENT'),
    path: PATH.MONTHLY_SETTLEMENT,
    role: [RoleEnum.Partner],
    page: SettlementPage,
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.brand.list'),
    path: PATH.BRAND_LIST,
    page: BrandListPage,
    role: [RoleEnum.Partner],
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.product.list'),
    path: PATH.PRODUCT_LIST,
    page: ProductListPage,
    role: [RoleEnum.Partner],
  },
  /** 수입 허가 */
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.importLicensing.list'),
    path: PATH.IMPORT_LICENSING_LIST,
    page: ImportLicensingListPage,
    role: [RoleEnum.Partner],
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.importLicensing.detail'),
    path: PATH.IMPORT_LICENSING_DETAIL,
    page: ImportLicensingDetailPage,
    menuPath: PATH.IMPORT_LICENSING_LIST,
    role: [RoleEnum.Partner],
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.order.management'),
    path: PATH.ORDER_MANAGEMENT,
    page: OrderManagementPage,
    menuPath: PATH.ORDER_MANAGEMENT,
    role: [RoleEnum.Partner],
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.order.management'),
    path: PATH.ORDER_CREATE,
    page: OrderCreatePage,
    menuPath: PATH.ORDER_MANAGEMENT,
    role: [RoleEnum.Partner],
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    path: PATH.ORDER_CHECK,
    title: i18next.t('MENU.order.management'),
    page: OrderCheckPage,
    menuPath: PATH.ORDER_MANAGEMENT,
    role: [RoleEnum.Partner],
  },
  {
    id: nextId(UUID_KEY.ROUTER),
    title: i18next.t('MENU.order.management'),
    path: PATH.ORDER_DETAIL,
    page: OrderDetailPage,
    menuPath: PATH.ORDER_MANAGEMENT,
    role: [RoleEnum.Partner],
  },
];
