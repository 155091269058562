import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import {
  B2bOrderDetailType,
  B2bOrderListQuery,
  B2bOrderStatusEnum,
  useB2bOrderListQuery,
  useB2bOrderSummaryQuery,
} from '@/generated/graphql-react-query';

export interface B2bOrder {
  id: string;
  sellerName?: string | null;
  buyerName?: string | null;
  status: B2bOrderStatusEnum;
  totalPrice: number;
  managerName?: string | null;
  orderDetails: Pick<B2bOrderDetailType, 'id' | 'inventoryQuantity' | 'orderQuantity'>[];
  isDisable: boolean;
  createdAt: string;
}

interface B2bOrderList {
  nodes: B2bOrder[];
  total: number;
}

const initialSummary = {
  totalPrice: 0,
  totalQuantity: 0,
  doneCount: 0,
  inProgressCount: 0,
  disableCount: 0,
};

export const useGetB2bOrderListHook = (
  options?: UseQueryOptions<B2bOrderListQuery, unknown, B2bOrderList, QueryKey>,
) => {
  const { data, refetch, isLoading, ...rest } = useB2bOrderListQuery(
    { input: { filter: {} } },
    {
      select: (data) => ({
        nodes: data.b2bOrderList.nodes.map((item) => ({
          id: item.id,
          sellerName: item.orderContract.sellerCompany.companyKrName,
          buyerName: item.orderContract.buyerCompany.companyKrName,
          status: item.status,
          totalPrice: item.totalPrice,
          managerName: item.orderContract.gomiManager.userName,
          orderDetails: item.orderDetails,
          isDisable: item.isDisable,
          createdAt: item.createdAt,
        })),
        total: data.b2bOrderList.total,
      }),
      ...options,
    },
  );
  const {
    data: b2bOrderSummary,
    refetch: refetchB2bOrderSummary,
    isLoading: isB2bOrderSummaryLoading,
  } = useB2bOrderSummaryQuery();

  const refetchB2bOrderList = () => {
    refetch();
    refetchB2bOrderSummary();
  };

  return {
    b2bOrderList: data?.nodes || [],
    total: data?.total || 0,
    b2bOrderSummary: b2bOrderSummary?.b2bOrderSummary || initialSummary,
    refetchB2bOrderList,
    isB2bOrderListLoading: isLoading,
    isB2bOrderSummaryLoading,
    ...rest,
  };
};
