import { Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { ChangeEvent, FC } from 'react';

const { Search } = Input;

/** props */
interface IPropsSearchBar {
  /** value */
  value?: string;
  /** search event */
  onSearch: (value: string) => void;
  /** change event */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  /** placeholder */
  placeholder?: string;
  /** css class */
  className?: string;
  /** control size */
  size?: SizeType;
}

/** component: search 박스 */
export const SearchBar: FC<IPropsSearchBar> = ({
  value,
  onSearch,
  onChange,
  placeholder,
  className,
  size,
}) => {
  return (
    <Search
      value={value}
      placeholder={placeholder}
      allowClear
      onSearch={onSearch}
      onChange={onChange}
      className={className}
      size={size}
    />
  );
};
