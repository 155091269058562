import { Form, Input, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { defaultOption } from '@/constant';
import { CountryEnum } from '@/generated/graphql';
import { ImportLicensingSheetStepEnum } from '@/generated/graphql-react-query';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { useImportLicensingListHook } from '@/hooks/importLicensing/list/useImportLicensingListHook';
import { useStore } from '@/stores';

export const FilterControlBar = observer(() => {
  const {
    importLicensingListModel: { filterControl, onChangeFilterControl },
  } = useStore();
  const { t } = useTranslation(['importLicensingInfo']);
  const { isMobile } = useMediaQueryHook();

  const { total } = useImportLicensingListHook({
    countryId: filterControl.countryId,
    step: filterControl.step,
    searchKeyword: filterControl.searchKeyword,
  });

  const handleStatusChange = (value: string) => {
    onChangeFilterControl('step', value);
  };

  const handleCountryChange = (value: string) => {
    onChangeFilterControl('countryId', value);
  };

  const handleSearch = (value: string) => {
    onChangeFilterControl('searchKeyword', value);
  };

  return (
    <div className='gm-flex gm-flex-col lg:gm-flex-row gm-justify-between lg:gm-items-center lg:gm-flex-nowrap md:gm-overflow-x-auto'>
      <div className='gm-min-w-24'>
        <span className='gm-mr-2'>{t('importLicensingInfo:control.title')}</span>
        <span>{t('importLicensingInfo:control.count', { count: total })}</span>
      </div>
      <Form
        layout={isMobile ? 'horizontal' : 'inline'}
        className='md:gm-flex-nowrap gm-gap-4 md:gm-mt-2 lg:gm-mt-0 gm-font-normal'
      >
        <Form.Item
          label={t('importLicensingInfo:control.status')}
          className='gm-my-2 md:gm-my-0'
        >
          <Select
            className='md:gm-w-40'
            defaultValue={defaultOption.value}
            onChange={handleStatusChange}
            value={filterControl.step}
          >
            <Select.Option value={defaultOption.value}>
              {defaultOption.title}
            </Select.Option>
            <Select.Option value={ImportLicensingSheetStepEnum.CreateProduct}>
              {t('importLicensingInfo:detailPage.steps.first.label')}
            </Select.Option>
            <Select.Option value={ImportLicensingSheetStepEnum.DocumentUploadPerProduct}>
              {t('importLicensingInfo:detailPage.steps.second.label')}
            </Select.Option>
            <Select.Option value={ImportLicensingSheetStepEnum.ExtraDocument}>
              {t('importLicensingInfo:detailPage.steps.third.label')}
            </Select.Option>
            <Select.Option value={ImportLicensingSheetStepEnum.DocumentDelivery}>
              {t('importLicensingInfo:detailPage.steps.forth.label')}
            </Select.Option>
            <Select.Option value={ImportLicensingSheetStepEnum.Estimate}>
              {t('importLicensingInfo:detailPage.steps.fifth.label')}
            </Select.Option>
            <Select.Option value={ImportLicensingSheetStepEnum.Done}>
              {t('importLicensingInfo:detailPage.steps.sixth.label')}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t('importLicensingInfo:control.country')}
          className='gm-mb-4 md:mg-mb-0'
        >
          <Select
            className='md:gm-w-20'
            defaultValue={defaultOption.value}
            onChange={handleCountryChange}
            value={filterControl.countryId}
          >
            <Select.Option value={defaultOption.value}>
              {defaultOption.title}
            </Select.Option>
            <Select.Option value={CountryEnum.Vn}>{CountryEnum.Vn}</Select.Option>
            <Select.Option value={CountryEnum.Th}>{CountryEnum.Th}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item className='gm-mb-0'>
          <Input.Search
            className='md:gm-w-72'
            defaultValue={filterControl.searchKeyword}
            placeholder={t('importLicensingInfo:control.searchPlaceHolder')}
            onSearch={handleSearch}
            allowClear
          />
        </Form.Item>
      </Form>
    </div>
  );
});
