import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ChangePasswordForm } from '@/views/auth/ChangePasswordForm';
import { FindPasswordForm } from '@/views/auth/FindPasswordForm';

const FindPasswordPageRow = styled(Row)`
  background-color: ${(props) => props.theme.color.neutrals[300]};
  height: 100vh;
  width: 100vw;
`;

const FindPasswordContentRow = styled(Row)<{ ischangepage: string }>`
  background-color: ${(props) => props.theme.color.neutrals[100]};
  height: ${(props) => (props.ischangepage ? '25rem' : '20rem')};
  width: 30rem;
`;

const FindPasswordTitleRow = styled(Row)`
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2rem;
`;

const LoginFormCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 3.5rem 4rem;
  align-items: center;
`;

export const FindPasswordPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  return (
    <FindPasswordPageRow justify='center' align='middle'>
      <FindPasswordContentRow ischangepage={id}>
        <LoginFormCol>
          <FindPasswordTitleRow>
            {id ? t('pageTitle.changePassword') : t('pageTitle.findPassword')}
          </FindPasswordTitleRow>
          {id ? <ChangePasswordForm /> : <FindPasswordForm />}
        </LoginFormCol>
      </FindPasswordContentRow>
    </FindPasswordPageRow>
  );
};
