import { mst } from '@gomi/utils';
import { Instance, SnapshotIn, types } from 'mobx-state-tree';

import { ALL } from '@/constant';
import {
  CountryEnum,
  ImportLicensingSheetStepEnum,
} from '@/generated/graphql-react-query';

const IDENTIFIER = 'importLicensingListModel';

export const country = { ...CountryEnum, [ALL]: ALL };
export type TCountry = keyof typeof country;

const step = { ...ImportLicensingSheetStepEnum, [ALL]: ALL };
export type TStep = keyof typeof step;

export type TFilterControlKeys = 'countryId' | 'step' | 'searchKeyword';

const FilterControl = types.model({
  step: mst.types.enumeration<TStep>(step),
  countryId: mst.types.enumeration<TCountry>(country),
  searchKeyword: types.string,
});

export type TFilterControl = Instance<typeof FilterControl>;

export const model = types
  .model(IDENTIFIER, {
    identifier: IDENTIFIER,
    filterControl: FilterControl,
  })
  .actions((self) => ({
    onChangeFilterControl(controlType: TFilterControlKeys, value: string) {
      self.filterControl = { ...self.filterControl, [controlType]: value };
    },
  }));

/** 초기화 값 */
const defaultValue: SnapshotIn<typeof model> = {
  filterControl: {
    countryId: ALL,
    step: ALL,
    searchKeyword: '',
  },
};

/** create or initialize */
const create = model.create(defaultValue);

const importLicensingListStore = {
  create,
  defaultValue,
  model,
};

/** 모델 타입 */
export type IImportLicensingModelType = Instance<typeof model>;

export default importLicensingListStore;
