import { HomeLocaleType } from '@/locale/data/types/homeLocale.type';

export const koHomeLocale: HomeLocaleType = {
  rootMenu: '대시보드',
  filter: {
    today: '오늘',
    recent7Days: '최근 7일',
    thisMonth: '이번달',
    lastMonth: '지난달',
  },
  summary: {
    title: '총 매출액',
    items: {
      total: '총 매출액(₫)',
      totalOrderCount: '총 주문 건',
      totalCancelCount: '총 취소 건',
    },
  },
  dashboard: {
    topSellingCategory: {
      title: '가장 많이 판매되는 카테고리',
    },
    topSellingProduct: {
      title: '가장 많이 판매되는 상품',
    },
    topSellingBrand: {
      title: '가장 많이 판매되는 브랜드',
    },
    percentageOfSalesByChannel: {
      title: '채널별 판매 비율',
    },
    statusOfAdvancement: {
      title: '진출 현황',
    },
  },
  empty: {
    content: '데이터가 존재하지 않습니다.',
  },
};
