import { useProductQuery } from '@/generated/graphql-react-query';

/** props */
interface IUseProductModalHook {
  productId?: string;
  isShow?: boolean;
}

/** 상품 > 상세 모달 hook */
export const useProductModalHook = ({
  productId = '',
  isShow = false,
}: IUseProductModalHook) => {
  /** gql - 상품 정보 */
  const productQuery = useProductQuery(
    {
      productId,
    },
    {
      enabled: !!productId && isShow,
    },
  );

  return {
    productQuery,
    dataProductInfo: productQuery.data?.product,
  };
};
