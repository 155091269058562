import styled from '@emotion/styled';
import { Layout } from 'antd';
import { Children, cloneElement, ReactElement, ReactNode, useState } from 'react';

import { MainHeader } from '@/component';
import { Menu } from '@/component/Menu';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';

const { Sider, Content } = Layout;

interface LayoutProps {
  children: ReactElement[];
}

interface HeaderLayoutProps {
  collapsed?: boolean;
  onChangeCollapsed?: () => void;
  children?: ReactNode;
}

interface ContentProps {
  children: ReactNode;
}

const FullScreenLayout = styled(Layout)`
  min-height: 100vh;
  max-height: 100vh;
`;

const NonePaddingContent = styled(Content)`
  padding: 0;
`;

const PaddingContent = styled(Content)`
  padding: 1.25rem;
`;

const LargeXPaddingContent = styled(Content)`
  padding: 1.25rem 3.5rem;
`;

export const DefaultLayout = ({ children }: LayoutProps) => {
  const [collapsed, setCollapsed] = useState<{ pc: boolean; mobile: boolean }>({
    pc: false,
    mobile: true,
  });
  const { isPC } = useMediaQueryHook();

  const onChangeCollapsed = () => {
    if (isPC) setCollapsed({ ...collapsed, pc: !collapsed.pc });
    else setCollapsed({ ...collapsed, mobile: !collapsed.mobile });
  };

  return (
    <FullScreenLayout>
      <Sider
        width={isPC ? 260 : '100%'}
        collapsedWidth={isPC ? undefined : 0}
        collapsed={isPC ? collapsed.pc : collapsed.mobile}
        className='gm-relative'
      >
        <Menu
          collapsed={isPC ? collapsed.pc : collapsed.mobile}
          onChangeCollapsed={onChangeCollapsed}
        />
      </Sider>
      <Layout>
        {Children.map(children, (child) =>
          cloneElement(child, {
            collapsed: isPC ? collapsed.pc : collapsed.mobile,
            onChangeCollapsed,
          }),
        )}
      </Layout>
    </FullScreenLayout>
  );
};

const HeaderLayout = ({
  collapsed = true,
  onChangeCollapsed = () => null,
  children,
}: HeaderLayoutProps) => {
  return (
    <MainHeader collapsed={collapsed} onChangeCollapsed={onChangeCollapsed}>
      {children}
    </MainHeader>
  );
};

const ContentLayout = ({ children }: ContentProps) => {
  return <PaddingContent>{children}</PaddingContent>;
};

const NonePaddingContentLayout = ({ children }: ContentProps) => {
  return <NonePaddingContent>{children}</NonePaddingContent>;
};

const LargeXPaddingContentLayout = ({ children }: ContentProps) => {
  return <LargeXPaddingContent>{children}</LargeXPaddingContent>;
};

DefaultLayout.Header = HeaderLayout;
DefaultLayout.Content = ContentLayout;
DefaultLayout.NonePaddingContent = NonePaddingContentLayout;
DefaultLayout.LargeXPaddingContent = LargeXPaddingContentLayout;
