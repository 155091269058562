import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ImportLicensingSheetStepEnum } from '@/generated/graphql-react-query';
import { useContentListHook } from '@/hooks/importLicensing/details/useContentListHook';
import { IParams } from '@/types/importLicensing/details';

export const ControlBar = () => {
  const { t } = useTranslation(['importLicensingInfo']);
  const { importLicensingId } = useParams<IParams>();

  const { total } = useContentListHook({
    importLicensingId,
    step: ImportLicensingSheetStepEnum.DocumentUploadPerProduct,
  });

  return (
    <div className='gm-flex gm-justify-between gm-items-center gm-flex-nowrap gm-overflow-x-auto'>
      <div className='gm-min-w-24'>
        <span className='gm-mr-2'>{t('importLicensingInfo:control.title')}</span>
        <span>{t('importLicensingInfo:control.count', { count: total })}</span>
      </div>
    </div>
  );
};
