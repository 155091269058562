import { IPaging } from '@gomi/hooks';
import { ValueGetterParams } from '@ag-grid-community/core';

/** ag-grid util */
export const agGrid = {
  /** row number */
  getRowNo:
    (paging: IPaging) =>
    ({ node }: ValueGetterParams) =>
      (paging.no - 1) * paging.size + (node?.rowIndex || 0) + 1,
};
