import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, LoggingClick } from '@gomi/component';
import { message, Modal } from 'antd';
import { Form, Radio, Select } from 'antd/es';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TRACK_EVENT_NAME } from '@/constant/track';
import { CountryEnum } from '@/generated/graphql';
import { useCreateImportLicensingMutation } from '@/generated/graphql-react-query';
import { useImportLicensingListHook } from '@/hooks/importLicensing/list/useImportLicensingListHook';
import { useTemplateList } from '@/hooks/importLicensing/template/useTemplateList';
import { useStore } from '@/stores';
import { CountryFlag } from '@/views/common/CountryFlag';

interface CreateModalProps {
  open: boolean;
  onClose(): void;
}

export const CreateModal = observer(({ open, onClose }: CreateModalProps) => {
  const { t } = useTranslation(['importLicensingInfo']);
  const {
    importLicensingListModel: { filterControl },
  } = useStore();

  const [form] = Form.useForm<{
    companyId: string;
    templateId: string;
    managerId: string;
    partnerContactEmail: string;
    countryId: CountryEnum;
  }>();
  const templateId = Form.useWatch('templateId', form);

  const { list: templateList, isLoading } = useTemplateList();
  const { refetch: refetchImportLicensingList } =
    useImportLicensingListHook(filterControl);

  const { mutate: createImportLicensing } = useCreateImportLicensingMutation({
    onSuccess: () => {
      message.success(t('importLicensingInfo:listPage.modal.message.success'));
      refetchImportLicensingList();
      onClose();
    },
    onError: () => {
      message.error(t('importLicensingInfo:listPage.modal.message.fail'));
    },
  });

  const handleSubmitConfirmOk = () => {
    const values = form.getFieldsValue();
    createImportLicensing({ input: values });
  };

  const handleSubmit = async () => {
    await form.validateFields();
    Modal.confirm({
      title: t('importLicensingInfo:listPage.modal.confirm.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('importLicensingInfo:listPage.modal.confirm.message'),
      okText: t('importLicensingInfo:listPage.modal.confirm.ok'),
      cancelText: t('importLicensingInfo:listPage.modal.confirm.cancel'),
      centered: true,
      onOk: handleSubmitConfirmOk,
    });
  };

  useEffect(() => {
    if (templateId) {
      const template = templateList.find((i) => i.id === templateId);
      if (template) {
        form.setFieldValue('countryId', template.countryId);
      }
    }
  }, [templateId]);

  return (
    <Modal
      title={t('importLicensingInfo:listPage.modal.title')}
      open={open}
      onCancel={onClose}
      width={664}
      destroyOnClose
      afterClose={form.resetFields}
      footer={
        <LoggingClick eventName={TRACK_EVENT_NAME.IMPORT_LICENSING_CREATE_CLICK}>
          <Button color='primary' btnType='filled' onClick={handleSubmit}>
            {t('importLicensingInfo:listPage.modal.form.submit')}
          </Button>
        </LoggingClick>
      }
    >
      <Form form={form} layout='vertical' autoComplete='off'>
        {/* 템플릿 */}
        <Form.Item
          name='templateId'
          label={t('importLicensingInfo:listPage.modal.form.template.label')}
        >
          <Select
            loading={isLoading}
            placeholder={t(
              'importLicensingInfo:listPage.modal.form.template.placeholder',
            )}
          >
            {templateList.map((template) => (
              <Select.Option key={template.id} value={template.id}>
                {template.templateName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* 국가 선택 */}
        <Form.Item
          name='countryId'
          label={t('importLicensingInfo:listPage.modal.form.country.label')}
          rules={[
            {
              required: true,
              message: t(
                'importLicensingInfo:listPage.modal.form.country.requiredMessage',
              ),
            },
          ]}
        >
          <Radio.Group disabled={!!templateId}>
            <Radio value={CountryEnum.Vn}>{CountryFlag(CountryEnum.Vn, 1, 1)}</Radio>
            <Radio value={CountryEnum.Th}>{CountryFlag(CountryEnum.Th, 1, 1)}</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
});
