import styled from '@emotion/styled';
import { AgChip, AgTable, Button } from '@gomi/component';
import { Select } from 'antd';
import { Modal } from 'antd/es';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  SettlementOrderItemSettlementStatusEnum as StatusEnum,
  useAllChannelsQuery,
  useSettlementConsignmentProductOrdersByPartnerQuery,
} from '@/generated/graphql-react-query';
import { PATH } from '@/routes';

import { OrdersModalColumn } from './OrdersModalColumn';

const { Option } = Select;

/** style */
const Wrapper = styled.div``;

/** props */
interface IPropsOrdersModal {
  /** 활성화 탭 _ 판매내역 or 상계 */
  activeTab: StatusEnum;
  /** visible 여부 */
  isVisible: boolean;
  /** 상품명 */
  productName: string;
  /** sales code id */
  salesCodeId: string;
  /** 정산서 id */
  settlementPartnerId: string;
  /** 닫기 클릭시 */
  onCancel?: () => void;
}

/** component: 판매내역 모달 - 월별 정산내역 > 위탁 상세 > 판매/상계 내역 */
const OrdersModal: FC<IPropsOrdersModal> = ({
  isVisible = true,
  activeTab,
  productName,
  salesCodeId,
  settlementPartnerId,
  onCancel,
}) => {
  const [channel, setChannel] = useState<string | null>(null);

  const { t } = useTranslation('settlementInfo');
  const history = useHistory();
  const tranModal = t('modal', {
    returnObjects: true,
  });

  /** gql - all channel */
  const { data: dataAllChannels } = useAllChannelsQuery();
  /** gql - 주문 리스트 */
  const { data: modalListData, isLoading: loadingModalList } =
    useSettlementConsignmentProductOrdersByPartnerQuery(
      {
        input: {
          filter: {
            salesCodeId,
            settlementPartnerId,
            channelId: channel as string,
            settlementStatus: activeTab,
          },
        },
      },
      {
        enabled: !!salesCodeId,
      },
    );

  /** 닫기 클릭시 */
  const handleCancel = () => {
    onCancel?.();
  };

  /** 채널 변경시 */
  const handleChangeChannel = (value: string) => {
    setChannel(value);
  };

  /** 세일즈 리포트로 이동 */
  const moveToSalesReport = () => {
    history.push(PATH.SALES_REPORT);
  };

  /** 모달 title */
  const ModalHeader = () => {
    return (
      <div className='gm-flex'>
        {activeTab === StatusEnum.Setoff ? (
          <AgChip color='red' text='상계' className='gm-mr-2' />
        ) : (
          ''
        )}{' '}
        {productName}
      </div>
    );
  };

  return (
    <Wrapper>
      <Modal
        open={isVisible}
        title={<ModalHeader />}
        width={672}
        onCancel={handleCancel}
        footer={null}
      >
        <div className='gm-flex gm-flex-col gm-gap-4'>
          <div className='gm-flex gm-items-center gm-gap-2'>
            <span className='gm-text-sm'>{tranModal.label.channelName}</span>
            <Select className='gm-w-30' value={channel} onChange={handleChangeChannel}>
              <Option key='0' value={null}>
                {tranModal.label.all}
              </Option>

              {dataAllChannels?.allChannels.map(({ id, channelName }) => (
                <Option key={id} value={id}>
                  {channelName}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <AgTable
              className='gm-h-[540px]'
              loading={loadingModalList}
              columnDefs={OrdersModalColumn()}
              defaultColDef={{ resizable: true }}
              rowData={modalListData?.settlementConsignmentProductOrdersByPartner || []}
            />
          </div>
        </div>
        <div className='gm-text-blue-600 gm-py-4'>
          {t('modal.footer.info1')}
          <Button
            type='button'
            btnType='text'
            color='primary'
            className='gm-underline gm-pl-1'
            onClick={moveToSalesReport}
          >
            {t('button.salesReport')}
          </Button>
          {t('modal.footer.info2')}
        </div>
      </Modal>
    </Wrapper>
  );
};

export { OrdersModal };
