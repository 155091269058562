import { useB2bOrderContractsQuery } from '@/generated/graphql-react-query';

export const useGetB2bOrderContractListHook = () => {
  const { data, refetch } = useB2bOrderContractsQuery();

  return {
    b2bOrderContractList: data?.b2bOrderContracts || [],
    refetchB2BOrderContractList: refetch,
  };
};
