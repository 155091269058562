import { ControlBar } from '@/views/importLicensing/importLicensingDetail/common/ControlBar';
import { Actions } from '@/views/importLicensing/importLicensingDetail/productCreateStep/Actions';
import { List } from '@/views/importLicensing/importLicensingDetail/productCreateStep/List';

export const ProductCreateStep = () => {
  return (
    <>
      <ControlBar>
        <Actions />
      </ControlBar>
      <div className='gm-mt-4 gm-flex gm-w-full gm-h-full'>
        <List />
      </div>
    </>
  );
};
