import styled from '@emotion/styled';
import { AntButton, LoggingClick } from '@gomi/component';
import { Form, Input } from 'antd';
import tw from 'twin.macro';

import { SIGN_IN_FORM } from '@/constant/auth';
import { TRACK_EVENT_NAME } from '@/constant/track';
import { DtoGetLogin } from '@/generated/graphql';

type UserLoginPayload = Required<DtoGetLogin>;

interface LoginFormProps {
  onSubmit: (formData: UserLoginPayload) => void;
}

const LoginFormArea = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 84%;
  margin: auto;
  ${tw`gm-mb-8`}
`;

const LoginFormInput = styled(Input)`
  border-radius: 5px;
  height: 45px;
`;

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
  return (
    <LoginFormArea
      requiredMark={false}
      layout='vertical'
      onFinish={(value) => onSubmit(value as UserLoginPayload)}
    >
      <Form.Item
        name='email'
        label={SIGN_IN_FORM.EMAIL.EMAIL_LABEL}
        rules={[
          {
            type: 'email',
            message: SIGN_IN_FORM.EMAIL.NONE_EMAIL_PATTERN,
          },
          {
            required: true,
            message: SIGN_IN_FORM.EMAIL.REQUIRED_EMAIL,
          },
        ]}
      >
        <LoginFormInput size='large' />
      </Form.Item>
      <Form.Item
        name='password'
        label={SIGN_IN_FORM.PASSWORD.PASSWORD_LABEL}
        rules={[
          {
            required: true,
            message: SIGN_IN_FORM.PASSWORD.REQUIRED_PASSWORD,
          },
        ]}
      >
        <LoginFormInput type='password' size='large' />
      </Form.Item>
      <LoggingClick eventName={TRACK_EVENT_NAME.LOGIN_CLICK}>
        <AntButton type='primary' htmlType='submit' size='large'>
          {SIGN_IN_FORM.SIGN_IN}
        </AntButton>
      </LoggingClick>
    </LoginFormArea>
  );
};
