import React from 'react';
import { useTranslation } from 'react-i18next';

import { ControlBar } from '@/views/importLicensing/importLicensingDetail/common/ControlBar';
import { Actions } from '@/views/importLicensing/importLicensingDetail/estimateStep/Actions';
import { BankInfo } from '@/views/importLicensing/importLicensingDetail/estimateStep/BankInfo';
import { EstimateList } from '@/views/importLicensing/importLicensingDetail/estimateStep/EstimateList';
import { ProductList } from '@/views/importLicensing/importLicensingDetail/estimateStep/ProductList';

export const EstimateStep = () => {
  const { t } = useTranslation(['importLicensingInfo']);

  return (
    <>
      <ControlBar>
        <Actions />
      </ControlBar>
      <div className='gm-mt-4 gm-flex gm-flex-col gm-w-full gm-h-full'>
        <ProductList />
        <br />
        <EstimateList />
        <br />
        <BankInfo />
        <br />
        <br />
        <ul className='gm-text-xs gm-pl-4 gm-text-gray-600'>
          <li>{t('importLicensingInfo:detailPage.steps.fifth.information.info1')}</li>
          <li>{t('importLicensingInfo:detailPage.steps.fifth.information.info2')}</li>
        </ul>
      </div>
    </>
  );
};
