import { FileFormat } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/fileList';

export const findFileFormatFromExtension = (ext: string): FileFormat => {
  const format: { [key: string]: FileFormat } = {
    '.pdf': FileFormat.PDF,
    '.png': FileFormat.PNG,
    '.jpg': FileFormat.JPG,
    '.gif': FileFormat.GIF,
    '.jpeg': FileFormat.JPEG,
    '.csv': FileFormat.CSV,
    '.xlsx': FileFormat.EXCEL,
  };

  return format[ext];
};
