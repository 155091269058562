import { Card, Empty, ExcelButton, LoggingClick } from '@gomi/component';
import { usePagingHook } from '@gomi/hooks';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { AgTable } from '@/component/Table/AgTable';
import { TRACK_EVENT_NAME } from '@/constant/track';
import { ReportExportExcelEnum } from '@/generated/graphql-react-query';
import { useOrderDetailsHook } from '@/hooks/report/useOrderDetailsHook';
import { useReportDownloadHook } from '@/hooks/report/useReportDownloadHook';
import { useStore } from '@/stores';
import {
  DetailOrderInfoColumn,
  DetailOrderInfoDefaultColumn,
} from '@/views/report/sales/DetailOrderInfoColumn';

export const OrderDetailList = observer(() => {
  const { t } = useTranslation(['common', 'report']);
  const { paging, onChangePage, getOffset } = usePagingHook({
    defaultSize: 250,
  });

  const {
    reportControlModel: {
      countryId,
      brandId,
      salesCode,
      startDate,
      endDate,
      orderStatus,
      paymentStatus,
      shippingStatus,
      channel,
      gift,
    },
  } = useStore();

  const { data, footer, isLoading, total } = useOrderDetailsHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
    pagination: {
      offset: getOffset(),
      limit: paging.size,
    },
  });

  /* 주문 상세 엑셀 다운로드 */
  const { download, isLoading: isDownloadLoading } = useReportDownloadHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
  });

  const handleDownloadExcel = () => {
    download(ReportExportExcelEnum.OrderDetails);
  };

  return (
    <div className='gm-my-4'>
      <Card
        header={
          <div className='gm-flex gm-justify-between gm-items-center'>
            {t('report:title.detailOrderItem')}
            <LoggingClick eventName={TRACK_EVENT_NAME.REPORT_ORDER_DETAIL_DOWNLOAD}>
              <ExcelButton
                type='button'
                loading={isDownloadLoading}
                onClick={handleDownloadExcel}
                isDisabled={!data.length || isDownloadLoading}
              >
                {t('common:common.button.download')}
              </ExcelButton>
            </LoggingClick>
          </div>
        }
      >
        <AgTable
          loading={isLoading}
          defaultColDef={DetailOrderInfoDefaultColumn}
          column={DetailOrderInfoColumn({ tran: t })}
          paginationProps={{
            totalCount: total,
            currentPage: paging.no,
            onChangePage,
            pageSize: paging.size,
          }}
          rowData={data}
          pinnedBottomRowData={footer}
          noRowsOverlayComponent={() => <Empty onShowImage>No Data</Empty>}
        />
      </Card>
    </div>
  );
});
