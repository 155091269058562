import styled from '@emotion/styled';
import { ExcelButton } from '@gomi/component';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import {
  SalesTypeEnum,
  useExportExcelSettlementConsignmentPartnerByPartnerMutation,
  useExportExcelSettlementPartnerByPartnerMutation,
} from '@/generated/graphql-react-query';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { downloadFileUrl } from '@/lib/downloadFile';

type DatePickerFunctionType = (date: Moment | null, dateString: string) => void;

interface IPropsMonthlySettlementHeader {
  /** year Month */
  yearMonth: string;
  /** 달력 change */
  onChangeDate: DatePickerFunctionType;
  /** settlement partnerId */
  partnerId: string;
  /** sales type _  위탁, 판매분 사입 */
  salesType?: SalesTypeEnum;
}

/** style */
const Wrapper = styled.div`
  ${tw`gm-flex gm-flex-col md:gm-flex-row gm-items-end md:gm-items-center md:gm-justify-between gm-pb-4`}
`;

/** component: 월별 정산 내역 헤더 */
export const MonthlySettlementHeader = ({
  yearMonth,
  onChangeDate,
  partnerId,
  salesType,
}: IPropsMonthlySettlementHeader) => {
  const { t } = useTranslation('settlementInfo');
  const { isPC } = useMediaQueryHook();

  /** gql - 월별 정산내역 위탁 엑셀 다운로드 */
  const { mutateAsync: exportConsignmentPartnerList } =
    useExportExcelSettlementConsignmentPartnerByPartnerMutation({
      onSuccess: (data) => {
        downloadFileUrl(data.exportExcelSettlementConsignmentPartnerByPartner);
      },
    });

  /** gql - 월별 정산내역 판매분사입 엑셀 다운로드 */
  const { mutateAsync: exportPartnerByPartnerList } =
    useExportExcelSettlementPartnerByPartnerMutation({
      onSuccess: (data) => {
        downloadFileUrl(data.exportExcelSettlementPartnerByPartner);
      },
    });

  /** 엑셀 variable */
  const exportExcelVariable = () => ({
    input: {
      filter: {
        settlementPartnerId: partnerId,
      },
    },
  });

  /** 엑셀 다운로드 */
  const handleExcelDown = () => {
    if (salesType === SalesTypeEnum.Consignment) {
      exportConsignmentPartnerList(exportExcelVariable());
    }
    if (salesType === SalesTypeEnum.PurchaseBySales) {
      exportPartnerByPartnerList(exportExcelVariable());
    }
  };

  /** 현재 연월/일 이후 날짜는 선택 불가능하도록 처리 */
  const handleDisabledDateMonthly: RangePickerProps['disabledDate'] = (current) => {
    return current && current >= moment().startOf('month');
  };
  const month = yearMonth ? yearMonth.slice(5) : moment().format('MM');

  return (
    <Wrapper>
      <div
        className={`gm-flex gm-justify-start gm-items-center ${
          isPC ? '' : 'gm-w-full gm-flex-col gm-gap-2'
        }`}
      >
        <div className={`${isPC ? 'gm-text-xl gm-mr-4' : 'gm-text-md'} gm-font-semibold`}>
          {t('header.label.countyInfo')} {month} {t('header.label.title')}
        </div>

        <DatePicker
          value={moment(yearMonth)}
          onChange={onChangeDate as DatePickerFunctionType}
          picker='month'
          disabledDate={handleDisabledDateMonthly}
          allowClear={false}
        />
      </div>

      {isPC ? (
        <ExcelButton
          onClick={handleExcelDown}
          type='button'
          className='gm-w-fit gm-mt-2 md:gm-mt-0'
          isDisabled={!partnerId}
        >
          {t('button.download')}
        </ExcelButton>
      ) : null}
    </Wrapper>
  );
};
