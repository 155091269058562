import styled from '@emotion/styled';
import { ExcelButton, LoggingClick } from '@gomi/component';
import { Breadcrumb, Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { AgTable, DefaultLayout } from '@/component';
import { TRACK_EVENT_NAME } from '@/constant/track';
import { StockTypeEnum } from '@/generated/graphql';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { useStockDownloadHook } from '@/hooks/report/useStockDownloadHook';
import { useStockHook } from '@/hooks/report/useStockHook';
import { StockColumn, StockLogColumn } from '@/pages/report/elements/StockTableColumns';
import { useStore } from '@/stores';
import { Controller } from '@/views/report/stock/Controller';

const StockLayout = styled.div`
  padding-top: 1rem;
`;

export const StockContent = observer(() => {
  const { t } = useTranslation(['report']);
  const { isMobile } = useMediaQueryHook();

  const {
    reportControlModel: { countryId, brandId, startDate, endDate, location },
  } = useStore();

  const {
    stockOutData,
    stockInData,
    loadingStockOuts,
    loadingStocks,
    loadingStockIns,
    stockData,
  } = useStockHook({
    startDate,
    endDate,
    brandId,
    countryId,
    location,
  });

  const { onClickDownloadExcelForStock, onClickDownloadExcelForStockLogs } =
    useStockDownloadHook({
      startDate,
      endDate,
      brandId,
      countryId,
      location,
    });

  return (
    <>
      <Controller />

      <StockLayout>
        <Card
          className='report-status-panel'
          title={t('report:title.stockOut')}
          extra={
            <LoggingClick eventName={TRACK_EVENT_NAME.LOGISTIC_STOCK_OUT_CLICK}>
              <ExcelButton
                onClick={() =>
                  onClickDownloadExcelForStockLogs({
                    stockLogsType: StockTypeEnum.StockOut,
                  })
                }
                isDisabled={!stockOutData.rowData.length}
              >
                {t('report:stockPage.label.download')}
              </ExcelButton>
            </LoggingClick>
          }
        >
          <AgTable
            tableHeight='14rem'
            rowData={stockOutData.rowData}
            loading={loadingStockOuts}
            column={StockLogColumn(stockOutData.datesForColumn, isMobile)}
          />
        </Card>
        <br />
        <Card
          className='report-status-panel'
          title={t('report:title.stockIn')}
          extra={
            <LoggingClick eventName={TRACK_EVENT_NAME.LOGISTIC_STOCK_OUT_CLICK}>
              <ExcelButton
                onClick={() => {
                  onClickDownloadExcelForStockLogs({
                    stockLogsType: StockTypeEnum.StockIn,
                  });
                }}
                isDisabled={!stockInData.rowData.length}
              >
                {t('report:stockPage.label.download')}
              </ExcelButton>
            </LoggingClick>
          }
        >
          <AgTable
            tableHeight='14rem'
            rowData={stockInData.rowData}
            loading={loadingStockIns}
            column={StockLogColumn(stockInData.datesForColumn, isMobile)}
          />
        </Card>
        <br />
        <Card
          className='report-status-panel'
          title={t('report:title.stock')}
          extra={
            <LoggingClick eventName={TRACK_EVENT_NAME.LOGISTIC_STOCK_CLICK}>
              <ExcelButton
                onClick={onClickDownloadExcelForStock}
                isDisabled={!stockData.length}
              >
                {t('report:stockPage.label.download')}
              </ExcelButton>
            </LoggingClick>
          }
        >
          <AgTable rowData={stockData} loading={loadingStocks} column={StockColumn()} />
        </Card>
      </StockLayout>
    </>
  );
});

export const LogisticReportPage = () => {
  const { t } = useTranslation(['report']);
  return (
    <DefaultLayout>
      <DefaultLayout.Header>
        <Breadcrumb>
          <Breadcrumb.Item>{t('report:rootMenu')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('report:stockPage.title')}</Breadcrumb.Item>
        </Breadcrumb>
      </DefaultLayout.Header>
      <DefaultLayout.Content>
        <StockContent />
      </DefaultLayout.Content>
    </DefaultLayout>
  );
};
