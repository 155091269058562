import React, { FC, ReactNode } from 'react';
import tw, { styled } from 'twin.macro';

const Wrapper = styled.button<IPropsButton>`
  /* default _ type, color, size 미설정시 보여지는 버튼 */
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  ${tw`gm-rounded-sm gm-cursor-pointer gm-bg-white gm-border-gray-400 gm-border gm-text-gray-900`}

  /* size */
  &.size-sm {
    ${tw`gm-h-fit gm-px-2 gm-py-1.5 gm-text-sm gm-leading-5`};
  }
  &.size-md {
    ${tw`gm-h-fit gm-px-3 gm-py-1.5 gm-text-sm gm-leading-5`};
  }
  &.size-lg {
    ${tw`gm-h-fit gm-px-3.5 gm-py-1.5 gm-text-base gm-leading-6`};
  }

  /* filled type */
  &.type-filled {
    ${tw`gm-text-white`}
    &.color-default {
      ${tw`!gm-bg-gray-900 !gm-border-gray-900`}
      :hover {
        ${tw`!gm-bg-gray-800`}
      }
      :active {
        ${tw`!gm-bg-black !gm-border-black`}
      }
    }
    &.disabled {
      ${tw`!gm-bg-gray-200 !gm-border-gray-200 !gm-text-gray-500`}
    }
  }

  /* outlined type */
  &.type-outlined {
    &.color-default {
      ${tw`!gm-text-gray-900 gm-bg-white gm-border-gray-400 gm-border`}
      :hover {
        ${tw`!gm-text-blue-600`}
      }
      :active {
        ${tw`!gm-bg-blue-100 !gm-border-blue-600`}
      }
    }
    &.disabled {
      ${tw`!gm-bg-gray-200 !gm-border-gray-400 !gm-text-gray-500`}
      :hover {
        ${tw`!gm-bg-gray-200 !gm-border-gray-400 !gm-text-gray-500`}
      }
    }
  }

  /* ghost type */
  &.type-ghost {
    ${tw`gm-bg-transparent gm-border-none gm-text-gray-900`}
    :hover {
      ${tw`!gm-bg-gray-100 !gm-border !gm-border-gray-100`}
    }
    :active {
      ${tw`!gm-bg-gray-100 !gm-border !gm-border-gray-100`}
    }
    &.disabled {
      ${tw`!gm-bg-transparent !gm-text-gray-500`}
    }
  }
  /* text type */
  &.type-text {
    ${tw`gm-bg-transparent gm-border-none gm-p-0`}
    &.disabled {
      ${tw`!gm-bg-transparent !gm-text-gray-500`}
    }
  }
`;

const colorVariation = {
  primary: 'blue',
  default: 'default',
  success: 'green',
  warning: 'yellow',
  danger: 'red',
};

export interface IPropsButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** btnType _ outlined, filled, ghost, text */
  btnType?: 'outlined' | 'filled' | 'ghost' | 'text';
  /** color _ default, primary, danger, warning, success */
  color?: keyof typeof colorVariation;
  /** size _ lg, md, sm */
  size?: 'sm' | 'md' | 'lg';
  /** disabled */
  isDisabled?: boolean;
  /** css class */
  className?: string;
  loading?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
}

/** component: 기본 버튼 컴포넌트 */
export const Button: FC<IPropsButton> = ({
  btnType = 'outlined',
  color = 'default',
  size = 'md',
  isDisabled = false,
  className,
  children,
  icon,
  ...props
}) => {
  const btnColor = colorVariation[color];

  // style 정의
  let mainColor = '';
  let hoverColor = '';
  let activeColor = '';

  switch (btnType) {
    case 'filled': {
      mainColor = `gm-bg-${btnColor}-600 gm-border-${btnColor}-600`;
      hoverColor = `hover:gm-bg-${btnColor}-500 hover:gm-border-${btnColor}-500`;
      activeColor = `active:gm-bg-${btnColor}-700 active:gm-border-${btnColor}-700`;
      break;
    }

    case 'outlined': {
      mainColor = `gm-text-${btnColor}-600 gm-border-${btnColor}-600`;
      hoverColor = `hover:gm-border-${btnColor}-500 hover:gm-text-${btnColor}-500`;
      activeColor = `active:gm-bg-${btnColor}-100 active:gm-border-${btnColor}-700 active:gm-text-${btnColor}-700`;
      break;
    }

    case 'ghost': {
      mainColor = `gm-bg-none gm-text-${btnColor}-600`;
      break;
    }

    case 'text': {
      mainColor = `gm-text-${btnColor}-600`;
      hoverColor = `hover:gm-text-${btnColor}-500`;
      activeColor = `active:gm-text-${btnColor}-700`;
      break;
    }

    default: {
      break;
    }
  }

  const styleClassName = `${mainColor} ${hoverColor} ${activeColor}`;

  return (
    <Wrapper
      type={props.type}
      onClick={isDisabled ? undefined : props.onClick}
      className={
        isDisabled
          ? `disabled ${styleClassName} type-${btnType} color-${btnColor} size-${size} ${className} gm-cursor-not-allowed`
          : `${styleClassName} type-${btnType} color-${btnColor} size-${size} ${className}`
      }
    >
      {icon ? <span className='gm-pr-1'>{icon}</span> : ''}
      {children}
    </Wrapper>
  );
};
