import { UseMutationOptions } from '@tanstack/react-query';

import {
  CreateB2bOrderMutation,
  DtoCreateB2bOrderInput,
  Exact,
  useCreateB2bOrderMutation,
} from '@/generated/graphql-react-query';

export const useCreateB2bOrderHook = (
  options?: UseMutationOptions<
    CreateB2bOrderMutation,
    unknown,
    Exact<{
      input: DtoCreateB2bOrderInput;
    }>,
    unknown
  >,
) => {
  const { mutate, ...rest } = useCreateB2bOrderMutation(options);

  return {
    createB2bOrder: mutate,
    ...rest,
  };
};
