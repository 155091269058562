import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button } from '@gomi/component';
import { DatePicker, Divider, Select } from 'antd/es';
import { flatMap } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { ALL } from '@/constant';
import { DatePickerFormat } from '@/constant/report';
import {
  GiftEnum,
  OrderPaymentStatusEnum,
  OrderShippingStatusEnum,
  SettlementOrderStatus,
} from '@/generated/graphql';
import { CountryEnum } from '@/generated/graphql-react-query';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { useBrandListHook } from '@/hooks/report/useBrandListHook';
import { useChannelsHook } from '@/hooks/report/useChannelsHook';
import { useSalesCodeListHook } from '@/hooks/report/useSalesCodeListHook';
import { useStore } from '@/stores';
import { RangeValue } from '@/types/report/type';

const Wrapper = styled.div`
  ${tw`gm-bg-gray-100 gm-border-gray-400 gm-border gm-my-4 gm-p-4 gm-overflow-x-auto gm-min-h-[4rem]`}

  .row {
    ${tw`gm-flex gm-items-center gm-gap-4 gm-w-max`}
    .wrap {
      ${tw`gm-flex gm-items-center`}
    }
  }

  .col {
    ${tw`gm-flex gm-flex-col gm-gap-4`}
    .label {
      ${tw`gm-block gm-mb-2`}
    }
    .ant-select {
      width: 100% !important;
    }
  }

  .row,
  .col {
    .label {
      ${tw`gm-text-sm gm-mr-2 gm-min-w-fit`}
    }
  }
`;

export const Controller = observer(() => {
  const { t } = useTranslation(['report']);
  const {
    reportControlModel: {
      countryId,
      setCountryId,
      brandId,
      setBrandId,
      salesCode,
      setSalesCode,
      startDate,
      endDate,
      setDate,
      orderStatus,
      setOrderStatus,
      setPaymentStatus,
      setShippingStatus,
      paymentStatus,
      shippingStatus,
      channel,
      setChannel,
      gift,
      setGift,
      isQuicksight,
    },
  } = useStore();
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
  const { isMobile } = useMediaQueryHook();

  const { brandList } = useBrandListHook();
  const { salesCodeList } = useSalesCodeListHook();
  const { channelList, isLoading: isChannelLoading } = useChannelsHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
  });

  const countryOptions = flatMap(brandList, (data) => {
    return data.countryIds;
  }).map((countryId) => ({
    value: countryId,
    title: countryId,
  }));

  return (
    <>
      <div className='gm-flex gm-justify-between'>
        <Select
          className='gm-w-30'
          defaultValue={countryId}
          value={countryId}
          showSearch
          onChange={(value: CountryEnum) => setCountryId(value)}
          options={countryOptions}
        />
      </div>
      {(countryId === CountryEnum.Vn && !isQuicksight && (
        <Wrapper
          className={`gm-bg-gray-100 gm-border-gray-400 gm-border gm-my-4 gm-p-4 gm-overflow-x-scroll gm-min-h-[4rem] ${
            visibleFilter ? 'gm-min-h-[8.125rem]' : 'gm-min-h-[4rem]'
          }`}
        >
          <div className={isMobile ? 'col' : 'row'}>
            <div className='wrap'>
              <span className='label'>{t('report:label.brand')}</span>
              <Select
                className='gm-w-60'
                value={brandId}
                showSearch
                onChange={(value: string) => {
                  const brand = brandList.find((brand) => brand.id === value);
                  if (brand) {
                    setBrandId(value);
                  }
                }}
              >
                <Select.Option value={ALL}>{t('report:label.all')}</Select.Option>
                {brandList.map((brand) => (
                  <Select.Option value={brand.id} key={brand.id}>
                    {brand.brandKrName || brand.brandName}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className='wrap'>
              <span className='label'>{t('report:label.item')}</span>
              <Select
                className='gm-w-96'
                value={salesCode}
                showSearch
                onChange={(value: string) => setSalesCode(value)}
              >
                <Select.Option value={ALL}>{t('report:label.all')}</Select.Option>
                {salesCodeList.map((salesCode) => (
                  <Select.Option value={salesCode.salesCode} key={salesCode.salesCode}>
                    {salesCode.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className='wrap'>
              <span className='label'>{t('report:label.period')}</span>
              <DatePicker.RangePicker
                allowClear={false}
                className='gm-w-64'
                value={[
                  moment(startDate, DatePickerFormat),
                  moment(endDate, DatePickerFormat),
                ]}
                onChange={(
                  _dates: RangeValue<Moment>,
                  [startDate, endDate]: [string, string],
                ) => setDate(startDate, endDate)}
              />
            </div>
            <Button
              type='button'
              btnType='ghost'
              className='gm-min-w-fit gm-items-end'
              onClick={() => setVisibleFilter((visibleFilter) => !visibleFilter)}
              icon={visibleFilter ? <UpOutlined /> : <DownOutlined />}
            >
              {t('report:salesPage.detailFilter')}
            </Button>
          </div>
          {visibleFilter ? (
            <>
              <Divider className='gm-my-4 row' />
              <div className={isMobile ? 'col' : 'row'}>
                <div className='wrap'>
                  <span className='label gm-min-w-max'>
                    {t('report:label.orderStatus')}
                  </span>
                  <Select
                    className='gm-w-30'
                    value={orderStatus}
                    onChange={(value) => setOrderStatus(value)}
                  >
                    <Select.Option value={ALL}>{t('report:label.all')}</Select.Option>
                    <Select.Option value={SettlementOrderStatus.Ok}>
                      {t('report:label.ok')}
                    </Select.Option>
                    <Select.Option value={SettlementOrderStatus.Cancel}>
                      {t('report:label.cancel')}
                    </Select.Option>
                  </Select>
                </div>
                <div className='wrap'>
                  <span className='label'>{t('report:label.payStatus')}</span>
                  <Select
                    className='gm-w-60'
                    value={paymentStatus}
                    onChange={(value) => setPaymentStatus(value)}
                  >
                    <Select.Option value={ALL}>{t('report:label.all')}</Select.Option>
                    <Select.Option value={OrderPaymentStatusEnum.Paid}>
                      {t('report:label.paid')}
                    </Select.Option>
                    <Select.Option value={OrderPaymentStatusEnum.NotPaid}>
                      {t('report:label.notPaid')}
                    </Select.Option>
                    <Select.Option value={OrderPaymentStatusEnum.Refund}>
                      {t('report:label.refund')}
                    </Select.Option>
                  </Select>
                </div>
                <div className='wrap'>
                  <span className='label'>{t('report:label.deliveryStatus')}</span>
                  <Select
                    className='gm-w-60'
                    value={shippingStatus}
                    onChange={(value) => setShippingStatus(value)}
                  >
                    <Select.Option value={ALL}>{t('report:label.all')}</Select.Option>
                    <Select.Option value={OrderShippingStatusEnum.Readytopick}>
                      {t('report:label.ready')}
                    </Select.Option>
                    <Select.Option value={OrderShippingStatusEnum.Delivering}>
                      {t('report:label.delivering')}
                    </Select.Option>
                    <Select.Option value={OrderShippingStatusEnum.Delivered}>
                      {t('report:label.delivered')}
                    </Select.Option>
                    <Select.Option value={OrderShippingStatusEnum.Return}>
                      {t('report:label.return')}
                    </Select.Option>
                  </Select>
                </div>
                <div className='wrap'>
                  <span className='label'>{t('report:label.channel')}</span>
                  <Select
                    className='gm-w-40'
                    value={channel}
                    loading={isChannelLoading}
                    onChange={(value) => setChannel(value)}
                  >
                    <Select.Option value={ALL}>{t('report:label.all')}</Select.Option>
                    {channelList.map((channel) => (
                      <Select.Option
                        key={channel.channelName}
                        value={channel.channelName}
                      >
                        {channel.displayName}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className='wrap'>
                  <span className='label'>{t('report:label.gift')}</span>
                  <Select
                    className='gm-w-30'
                    value={gift}
                    onChange={(value) => {
                      setGift(value);
                    }}
                  >
                    <Select.Option value={ALL}>{t('report:label.all')}</Select.Option>
                    <Select.Option value={GiftEnum.ExcludeGift}>
                      {t('report:label.excludeGift')}
                    </Select.Option>
                    <Select.Option value={GiftEnum.OnlyGift}>
                      {t('report:label.onlyGift')}
                    </Select.Option>
                  </Select>
                </div>
              </div>
            </>
          ) : null}
        </Wrapper>
      )) ||
        null}
    </>
  );
});
