import { momentDateFormat } from '@/lib';

interface CommentProps {
  writer: string;
  updateAt: string;
  content: string;
}

export const Comment = ({ writer, updateAt, content }: CommentProps) => (
  <div className='gm-relative gm-px-3 gm-py-4 gm-bg-white'>
    <div className='gm-flex gm-gap-1.5 gm-items-center gm-text-xs gm-text-gray-700'>
      <span className='gm-max-w-[calc(100%-7.5rem)] gm-text-ellipsis gm-whitespace-nowrap gm-overflow-hidden'>
        {writer}
      </span>
      <div className='gm-w-[1px] gm-h-3 gm-bg-gray-600' />
      <span>{momentDateFormat.convertDateYYYYMMDD(updateAt)}</span>
    </div>
    <p className='gm-mt-2 gm-mb-0 gm-text-sm'>{content}</p>
  </div>
);
