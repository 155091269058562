import { AgTable, Card, Empty } from '@gomi/component';
import { Divider, Form } from 'antd/es';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  B2bOrderStatusEnum,
  RegisterB2bOrderInputType,
} from '@/generated/graphql-react-query';
import { useGetB2bOrderHook } from '@/hooks/order/common/useGetB2bOrderHook';
import { useCancelOrderDetailHook } from '@/hooks/order/detail/useCancelOrderDetailHook';
import { FilterControlBar } from '@/views/order/detail/FilterControlBar';
import { getListColumn } from '@/views/order/detail/getListColumn';
import { OrderForm } from '@/views/order/detail/OrderForm';

export const List = () => {
  const { t } = useTranslation(['orderInfo']);
  const { orderId } = useParams<{ orderId: string }>();
  const [form] = Form.useForm<Omit<RegisterB2bOrderInputType, 'orderDetails'>>();
  const { cancel } = useCancelOrderDetailHook();

  const { b2bOrder, isB2bOrderLoading, isBuyer, isSeller, refetchB2bOrder } =
    useGetB2bOrderHook(orderId);
  const isCheckingInventory = [
    B2bOrderStatusEnum.OrderRequestConfirm,
    B2bOrderStatusEnum.OrderCreate,
  ].includes(b2bOrder.status);

  const listColumn = getListColumn({
    t,
    isCheckingInventory,
    isBuyer,
    isSeller,
    status: b2bOrder.status,
    onDelete: async (id: string) => {
      await cancel(id);
      await refetchB2bOrder();
    },
  });

  return (
    <>
      <Card
        className='gm-h-full'
        header={<FilterControlBar id={orderId} />}
        isContentsBackground={false}
      >
        <AgTable
          loading={isB2bOrderLoading}
          rowData={b2bOrder.orderDetails}
          columnDefs={listColumn}
          defaultColDef={{ resizable: true }}
          noRowsOverlayComponent={() => (
            <Empty onShowImage>{t('orderInfo:createPage.message.empty')}</Empty>
          )}
          enableBrowserTooltips
        />
      </Card>

      <Divider />

      <OrderForm form={form} />
    </>
  );
};
