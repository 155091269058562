import styled from '@emotion/styled';
import { AgTable, Card, Empty } from '@gomi/component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { useEstimateHook } from '@/hooks/importLicensing/details/useEstimateHook';
import { IParams } from '@/types/importLicensing/details';
import { EstimateListColumn } from '@/views/importLicensing/importLicensingDetail/estimateStep/EstimateListColumn';
import { EstimateListControlBar } from '@/views/importLicensing/importLicensingDetail/estimateStep/EstimateListControlBar';

const CustomAgTable = styled(AgTable)`
  .ag-root-wrapper-body.ag-layout-normal {
    ${tw`gm-h-auto`}
  }
`;

export type Estimate = {
  count: number;
  document: string;
  itemName: string;
  price: number;
  unit: string;
  vat: number;
};

export const EstimateList = () => {
  const { importLicensingId } = useParams<IParams>();
  const {
    list: { list, footer, isLoading },
  } = useEstimateHook(importLicensingId);
  const { t } = useTranslation(['importLicensingInfo']);

  return (
    <Card
      className='gm-w-full'
      header={<EstimateListControlBar />}
      isContentsBackground={false}
    >
      <CustomAgTable
        loading={isLoading}
        rowData={list}
        columnDefs={EstimateListColumn()}
        defaultColDef={{ resizable: true }}
        noRowsOverlayComponent={() => (
          <Empty size='small' onShowImage>
            {t('importLicensingInfo:table.emptyEstimate')}
          </Empty>
        )}
        pinnedBottomRowData={list.length ? [footer] : undefined}
        enableBrowserTooltips
      />
    </Card>
  );
};
