import { useExistPartnerUserEmailQuery } from '@/generated/graphql-react-query';

export const useEmailCheckHook = ({ email }: { email: string }) => {
  const { data } = useExistPartnerUserEmailQuery(
    {
      email,
    },
    {
      enabled: !!email,
    },
  );

  return {
    exist: !!data?.existPartnerUserEmail,
  };
};
