import i18next from 'i18next';

export const ALL = 'all';

export const MESSAGE = {
  CONFIRM_DELETE: '정말 삭제하시겠습니까?',
  CONFIRM_PROCESS: '정말 처리하시겠습니까?',
  TOKEN_EXPIRATION: '로그인 토큰이 만료되었습니다. \n 로그인 창으로 이동합니다',
  UNAUTHORIZED: '권한이 없습니다',
  SERVER_ERROR: '서버 접근이 불가합니다',
  FORBIDDEN_RESOURCE: '접근 권한이 없습니다',
  FAIL_FILE_DOWNLOAD: '파일 다운로드에 실패했습니다',
};

export const COPYRIGHT = 'Copyright © 2023 Gomi Corporation';

export const TABLE = {
  DEFAULT_PAGE_LIMIT: 20,
  MID_PAGE_LIMIT: 10,
  DEFAULT_PAGE: 1,
};
export const UUID_KEY = {
  ROUTER: 'router',
};

export const defaultOption = {
  value: ALL,
  title: i18next.t('partnerInfo:control.label.all'),
};

export const COUNTRY = {
  VN: 'vn',
  CL: 'cl',
  TH: 'th',
  JP: 'jp',
  NONE: 'none',
} as const;
