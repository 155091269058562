import { Breadcrumb } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DefaultLayout } from '@/component';
import { CountryEnum } from '@/generated/graphql-react-query';
import { useStore } from '@/stores';
import { Controller } from '@/views/report/sales/Controller';
import { OrderDetailList } from '@/views/report/sales/OrderDetailList';
import { ReportStatusWrap } from '@/views/report/sales/ReportStatus';
import { SalesAmountByProductChart } from '@/views/report/sales/salesAmountByProductChart';
import { SalesStatusByDateList } from '@/views/report/sales/SalesStatusByDateList';
import { SalesStatusByProductList } from '@/views/report/sales/SalesStatusByProductList';
import { SalesStatusChart } from '@/views/report/sales/salesStatusChart';
import { ThQuicksightReport } from '@/views/report/sales/ThQuicksightReport';

const ReportContent = observer(() => {
  const {
    reportControlModel: { countryId, isQuicksight },
  } = useStore();

  return (
    <>
      <Controller />

      {(countryId === CountryEnum.Vn && !isQuicksight && (
        <>
          {/* 판매현황 */}
          <SalesStatusChart />

          {/* 주문상태, 결제상태, 배송상태 */}
          <ReportStatusWrap />

          {/* 상품별 판매금액 */}
          <SalesAmountByProductChart />

          {/* 상품별 판매현황 */}
          <SalesStatusByProductList />

          {/* 날짜별 판매현황 */}
          <SalesStatusByDateList />

          {/* 주문상세 정보 */}
          <OrderDetailList />
        </>
      )) ||
        null}

      {/* Th 임시로 QuickSight로 대체 (FIXME: 내재화 후 ThReport 제거) */}
      {(countryId === CountryEnum.Th && (
        <>
          <ThQuicksightReport />
        </>
      )) ||
        null}
    </>
  );
});

export const SalesReportPage = () => {
  const { t } = useTranslation(['report']);

  return (
    <DefaultLayout>
      <DefaultLayout.Header>
        <Breadcrumb>
          <Breadcrumb.Item>{t('report:rootMenu')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('report:salesPage.title')}</Breadcrumb.Item>
        </Breadcrumb>
      </DefaultLayout.Header>
      <DefaultLayout.Content>
        <ReportContent />
      </DefaultLayout.Content>
    </DefaultLayout>
  );
};
