import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import '../../styles/agGrid.scss';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { GridReadyEvent, Module } from '@ag-grid-community/core';
import { AgGridReact, AgGridReactProps, AgReactUiProps } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { Pagination, Spin } from 'antd';
import React, { forwardRef, ForwardRefRenderFunction, LegacyRef, useMemo } from 'react';
import tw, { styled } from 'twin.macro';

/** style */
export const DEFAULT_HEIGHT = 44;
export const DEFAULT_BROWSER_HEIGHT = 1055;

const Wrapper = styled.div`
  ${tw`gm-h-full gm-flex gm-flex-col gm-min-h-[10rem]`}

  .ag-header {
    ${tw`gm-z-10`}
  }

  .ag-cell,
  .ag-header-cell,
  .ag-header-group-cell {
    ${tw`!gm-leading-10`}
  }

  .ag-theme-alpine {
    /* bright green, 10% opacity */
    --ag-selected-row-background-color: none;
    --ag-range-selection-border-color: transparent;
    --ag-range-selection-border-style: none;
    --ag-range-selection-background-color: none;
  }

  .ant-spin-nested-loading,
  .ant-spin-container,
  .aggrid-container {
    ${tw`gm-h-full`}
  }
`;

const PaginationCenter = styled(Pagination)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;

type TAgGridOptions = AgGridReactProps & AgReactUiProps;

/** props */
export interface IPropsAgTable extends TAgGridOptions {
  ref?: LegacyRef<AgGridReact>;
  loading: boolean;
  rowToFit?: boolean;
  tableHeight?: string;
  rowHeight?: number;
  modules?: Module[];
  paginationProps?: {
    totalCount: number;
    currentPage: number;
    onChangePage?: (value: number, pageSize: number) => void;
    pageSize?: number;
    pageSizeOptions?: number[];
  };
  visibleFooter?: boolean;
  visibleTotalFooter?: boolean;
}

/** component: 기본 테이블 컴포넌트 */
const AgTable: ForwardRefRenderFunction<AgGridReact, IPropsAgTable> = (
  {
    rowHeight = DEFAULT_HEIGHT,
    loading,
    rowToFit,
    paginationProps,
    modules,
    className,
    visibleFooter = false,
    visibleTotalFooter = false,
    ...args
  },
  ref,
) => {
  /** ag-grid modules */
  const agModules = useMemo(
    () => [
      ClientSideRowModelModule,
      RowGroupingModule,
      ClipboardModule,
      RangeSelectionModule,
      ...(modules ?? []),
    ],
    [],
  );
  const gridOption = {
    onGridReady(event: GridReadyEvent) {
      return rowToFit && event.api.sizeColumnsToFit();
    },
  };

  return (
    <Wrapper className={className}>
      <Spin spinning={loading}>
        <div className='aggrid-container ag-theme-alpine'>
          <AgGridReact
            groupIncludeFooter={visibleFooter}
            groupIncludeTotalFooter={visibleTotalFooter}
            ref={ref}
            modules={agModules}
            rowSelection='single'
            headerHeight={44}
            rowHeight={rowHeight}
            tooltipShowDelay={0}
            tooltipHideDelay={1000}
            gridOptions={gridOption}
            enableRangeSelection
            {...args}
          />
        </div>
      </Spin>
      {paginationProps && (
        <PaginationCenter
          onChange={paginationProps?.onChangePage}
          current={paginationProps?.currentPage}
          total={paginationProps?.totalCount}
          showSizeChanger={!!paginationProps?.pageSize}
          pageSize={paginationProps?.pageSize ?? 20}
          pageSizeOptions={paginationProps?.pageSizeOptions ?? [20, 50]}
        />
      )}
    </Wrapper>
  );
};

export default forwardRef(AgTable);
