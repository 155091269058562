import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Upload } from '@gomi/component';
import { message, Modal } from 'antd';
import { UploadFile } from 'antd/es';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import {
  FilePathEnum,
  ImportLicensingDocumentStatusEnum,
  useCreateImportLicensingFileUploadMutation,
} from '@/generated/graphql-react-query';
import { useUploadedFilesHook } from '@/hooks/files/useUploadFilesHook';
import { useContentListHook } from '@/hooks/importLicensing/details/useContentListHook';
import { useImportLicensingDocumentHook } from '@/hooks/importLicensing/details/useImportLicensingDocumentHook';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';
import { changeStringStep } from '@/views/importLicensing/importLicensingDetail/Steps';

const Notice = styled.section`
  ${tw`gm-p-4 gm-mt-4 gm-bg-gray-100`}
`;

export const FileUploadDragger = observer(() => {
  const { t } = useTranslation(['importLicensingInfo']);
  const { importLicensingId } = useParams<IParams>();
  const {
    importLicensingDetailModel: { file, steps },
  } = useStore();

  /** hooks */
  const {
    fileList,
    uploadFile,
    removeFile,
    resetFiles,
    isLoading: isUploadLoading,
  } = useUploadedFilesHook();
  const { data, refetch } = useImportLicensingDocumentHook({ id: file.selected });
  const { refetch: refetchContentList } = useContentListHook({
    importLicensingId,
    step: changeStringStep(steps.current),
  });

  const {
    mutateAsync: uploadImportLicensingFiles,
    isLoading: isLoadingImportLicensingFiles,
  } = useCreateImportLicensingFileUploadMutation({
    onSuccess: () => {
      message.success(i18next.t('importLicensingInfo:detailPage.upload.success'));
      resetFiles();
      refetch();
      refetchContentList();
    },
  });

  const handleChange = ({ fileList: files }: { fileList: UploadFile[] }) => {
    uploadFile(files, FilePathEnum.ImportLicense);
  };

  const handleSubmit = () => {
    if (!fileList.length) {
      message.warning(t('importLicensingInfo:detailPage.upload.empty'));
      return;
    }

    Modal.confirm({
      title: t('importLicensingInfo:detailPage.upload.confirm.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('importLicensingInfo:detailPage.upload.confirm.content'),
      okText: t('importLicensingInfo:detailPage.upload.confirm.ok'),
      cancelText: t('importLicensingInfo:detailPage.upload.confirm.cancel'),
      centered: true,
      onOk: async () => {
        if (data) {
          await uploadImportLicensingFiles({
            input: {
              documentId: data.id,
              fileIds: fileList.map((file) => file.uid),
            },
          });
        }
      },
    });
  };

  const isDoneStep = steps.status === ImportLicensingDocumentStatusEnum.Done;

  return (
    <>
      <div className='gm-flex gm-justify-end gm-mb-3'>
        {data?.formFileLink && (
          <Button onClick={() => data?.formFileLink && window.open(data.formFileLink)}>
            양식 다운로드
          </Button>
        )}
        {data?.exampleFileLink && (
          <Button
            onClick={() => data?.exampleFileLink && window.open(data.exampleFileLink)}
            className='gm-ml-2'
          >
            {t('importLicensingInfo:detailPage.upload.exampleView')}
          </Button>
        )}
      </div>
      <Upload
        type='ALL'
        disabled={isDoneStep}
        maxFiles={10}
        fileList={fileList}
        isLoading={isUploadLoading}
        onChange={handleChange}
        onRemove={removeFile}
        multiple
      />
      {(data?.warnings.length && (
        <Notice>
          <div className='gm-text-xs gm-font-bold gm-mb-2'>
            {t('importLicensingInfo:detailPage.upload.info.title')}
          </div>

          {data?.warnings.map((warning, index) => (
            <div className='gm-text-xs gm-mb-1' key={warning}>
              {index + 1}. {warning}
            </div>
          ))}
        </Notice>
      )) ||
        null}
      <div className='gm-flex gm-justify-end gm-mt-4'>
        <Button
          color='primary'
          btnType='filled'
          loading={isLoadingImportLicensingFiles}
          onClick={handleSubmit}
          isDisabled={!data?.id || isDoneStep}
        >
          {t('importLicensingInfo:detailPage.upload.createButton')}
        </Button>
      </div>
    </>
  );
});
