import { Theme } from '@emotion/react';

export const basic: Theme = {
  color: {
    primary: '#1890FF',
    neutrals: {
      100: 'hsl(0, 0%, 100%)',
      200: 'hsl(0, 14%, 96%)',
      300: 'hsl(0, 0%, 88%)',
      400: 'hsl(231, 6%, 48%)',
      500: 'hsl(0, 6%, 31%)',
      600: 'hsl(0, 0%, 0%)',
    },
    status: {
      warning: 'hsl(0, 94%, 62%)',
      error: '',
      success: '',
    },
  },
};
