import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import styled from '@emotion/styled';
import { numbers } from '@gomi/utils';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import {
  ReturnSettlementConsignmentPartnerProductListItemType,
  SettlementOrderItemSettlementStatusEnum as StatusEnum,
} from '@/generated/graphql-react-query';

const SalesPriceColumnWrapper = styled.div`
  ${tw`gm-flex gm-flex-col gm-h-full gm-w-full`}

  .sales-price {
    ${tw`gm-leading-6 gm-text-right`}
  }

  .fee-rate {
    ${tw`gm-leading-4 gm-text-gray-700 gm-text-xs gm-text-right`}
  }
`;

interface ISalesPriceColumnProps {
  params: ICellRendererParams<ReturnSettlementConsignmentPartnerProductListItemType>;
  channelName: string;
}

const SalesPriceColumn = ({ params, channelName }: ISalesPriceColumnProps) => {
  const data = params?.data?.channels.find(
    (channel) => channel.channelName === channelName,
  );
  const defaultFeeRate = 30;
  const salesPrice = data?.countrySalesPrice ?? '';
  const feeRate = data?.feeRateV2 ?? '';

  const isShowFeeRate = feeRate && feeRate !== defaultFeeRate;
  if (!isShowFeeRate) {
    return <>{numbers.convertMoneyText(salesPrice)}</>;
  }

  return (
    <SalesPriceColumnWrapper>
      <div className='sales-price'>{numbers.convertMoneyText(salesPrice)}</div>
      <div className='fee-rate'>
        {i18next.t('settlementInfo:formatter.feeRate', {
          feeRate,
        })}
      </div>
    </SalesPriceColumnWrapper>
  );
};

/** component: 월별 정산내역 > 위탁 상세 > 판매/상계 내역 리스트 컬럼 */
export const ListColumn = ({
  activeTab,
  onVisibleOrdersModal,
}: {
  activeTab: StatusEnum;
  onVisibleOrdersModal: (salesCodeId: string, productKrName: string) => void;
}): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation('settlementInfo');
  const tranTableColumn = t('tableColumn', {
    returnObjects: true,
  });

  /** 상계일 경우 prefix -(마이너스) 추가 */
  const addPrefixForSetoff = (value: number | string | undefined) => {
    if (!value || value === 0 || value === '0') {
      return '';
    }

    return activeTab === StatusEnum.Settlement ? value : `-${value}`;
  };

  /** 채널 별 수량 */
  const countValueGetterByChannel = (
    params: ValueGetterParams<ReturnSettlementConsignmentPartnerProductListItemType>,
    channelName: string,
  ) => {
    const gomiChannel = params?.data?.channels.find(
      (channel) => channel.channelName === channelName,
    );
    return addPrefixForSetoff(gomiChannel ? gomiChannel.salesCount : 0);
  };

  /** 금액 콤마 표시 */
  const convertMoney = (params: ValueFormatterParams) =>
    params.value ? numbers.convertMoneyText(params.value) : params.value;

  /** 수량, 판매 가격 Cell 스타일 */
  const defaultCellClass = ({ node }: CellClassParams) => {
    if (node && !node.isRowPinned()) {
      return 'gm-flex gm-justify-end gm-items-center';
    }

    return 'gm-font-semibold gm-flex gm-justify-end gm-items-center';
  };

  /** 합계 Cell 스타일 */
  const sumCellClass = ({ node }: CellClassParams) => {
    if (node && !node.isRowPinned()) {
      return 'gm-flex gm-justify-end gm-items-center';
    }

    const fontColor =
      activeTab === StatusEnum.Settlement ? 'gm-text-blue-700' : 'gm-text-red-600';
    return `gm-font-semibold ${fontColor} gm-flex gm-justify-end gm-items-center`;
  };

  const channelSalesPriceHeader =
    activeTab === StatusEnum.Settlement
      ? tranTableColumn.channelSalesPrice
      : tranTableColumn.setoffPrice;

  return [
    {
      // no
      headerName: tranTableColumn.no,
      valueGetter: ({ node }) => {
        if (node && !node.isRowPinned()) {
          return Number(node.rowIndex) + 1;
        }

        return '';
      },
      width: 48,
      headerClass: 'gm-text-center header-span-3',
      cellClass: 'gm-text-center',
    },
    {
      // 상품명
      headerName: tranTableColumn.productName,
      field: 'productKrName',
      minWidth: 380,
      flex: 1,
      rowSpan: (params) => params.data.rowspan || 1,
      cellClassRules: {
        'custom-ag-table-row-span': (params) => params.data.rowspan > 1,
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (!params?.node?.isRowPinned()) {
          return (
            <div
              onClick={() =>
                onVisibleOrdersModal?.(
                  params?.data?.salesCodeId,
                  params?.data?.productKrName,
                )
              }
              className='hover:gm-underline gm-cursor-pointer'
            >
              {params.data.productKrName}
            </div>
          );
        }

        return params?.data?.productKrName || '';
      },
      headerClass: 'gm-text-center header-span-3',
      cellClass: ({ node }) => {
        if (node && !node.isRowPinned()) {
          return 'gm-text-left gm-flex gm-justify-start gm-items-center';
        }

        return 'gm-font-semibold gm-flex gm-justify-end gm-items-center';
      },
    },
    {
      // 판매가격
      headerName: tranTableColumn.salesPrice,
      field: 'countrySalesPrice',
      width: 120,
      headerClass: 'gm-text-center header-span-3',
      cellClass: 'gm-flex gm-justify-end gm-items-center',
      valueFormatter: (params) => (params.value ? convertMoney(params) : ''),
    },
    {
      // gomi
      headerName: 'GOMI',
      headerClass: 'header-span-t-2',
      children: [
        {
          // 수량
          headerName: tranTableColumn.salesCount,
          headerClass: 'gm-text-right',
          width: 64,
          valueGetter: (params) => countValueGetterByChannel(params, 'GOMI'),
          valueFormatter: convertMoney,
          cellClass: defaultCellClass,
        },
        {
          // 판매 금액
          headerName: channelSalesPriceHeader,
          headerClass: 'gm-text-right',
          width: 120,
          cellRenderer: (params: ICellRendererParams) => (
            <SalesPriceColumn params={params} channelName='GOMI' />
          ),
          valueFormatter: convertMoney,
          cellClass: defaultCellClass,
        },
      ],
    },
    {
      headerName: tranTableColumn.externalChannel,
      children: [
        {
          // shopee
          headerName: 'SHOPEE',
          columnGroupShow: 'closed',
          children: [
            {
              // 수량
              headerName: tranTableColumn.salesCount,
              headerClass: 'gm-text-right',
              field: '',
              width: 64,
              valueGetter: (params) => countValueGetterByChannel(params, 'SHOPEE'),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
            {
              // 판매 금액
              headerName: channelSalesPriceHeader,
              headerClass: 'gm-text-right',
              field: '',
              width: 120,
              cellRenderer: (params: ICellRendererParams) => (
                <SalesPriceColumn params={params} channelName='SHOPEE' />
              ),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
          ],
        },
        {
          // shopee
          headerName: 'SHOPEE',
          columnGroupShow: 'open',
          children: [
            {
              // 수량
              headerName: channelSalesPriceHeader,
              headerClass: 'gm-text-right',
              field: '',
              width: 64,
              valueGetter: (params) => countValueGetterByChannel(params, 'SHOPEE'),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
            {
              // 판매 금액
              headerName: tranTableColumn.channelSalesPrice,
              headerClass: 'gm-text-right',
              field: '',
              width: 120,
              cellRenderer: (params: ICellRendererParams) => (
                <SalesPriceColumn params={params} channelName='SHOPEE' />
              ),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
          ],
        },
        {
          // lazada
          headerName: 'LAZADA',
          columnGroupShow: 'open',
          width: 80,
          children: [
            {
              // 수량
              headerName: tranTableColumn.salesCount,
              headerClass: 'gm-text-right',
              field: '',
              width: 64,
              valueGetter: (params) => countValueGetterByChannel(params, 'LAZADA'),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
            {
              // 판매 금액
              headerName: channelSalesPriceHeader,
              headerClass: 'gm-text-right',
              field: '',
              width: 120,
              cellRenderer: (params: ICellRendererParams) => (
                <SalesPriceColumn params={params} channelName='LAZADA' />
              ),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
          ],
        },
        {
          // sendo
          headerName: 'SENDO',
          columnGroupShow: 'open',
          width: 80,
          children: [
            {
              // 수량
              headerName: tranTableColumn.salesCount,
              headerClass: 'gm-text-right',
              field: '',
              width: 64,
              valueGetter: (params) => countValueGetterByChannel(params, 'SENDO'),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
            {
              // 판매 금액
              headerName: channelSalesPriceHeader,
              headerClass: 'gm-text-right',
              field: '',
              width: 120,
              cellRenderer: (params: ICellRendererParams) => (
                <SalesPriceColumn params={params} channelName='SENDO' />
              ),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
          ],
        },
        {
          // tiki
          headerName: 'TIKI',
          columnGroupShow: 'open',
          width: 80,
          children: [
            {
              // 수량
              headerName: tranTableColumn.salesCount,
              headerClass: 'gm-text-right',
              field: '',
              width: 64,
              valueGetter: (params) => countValueGetterByChannel(params, 'TIKI'),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
            {
              // 판매 금액
              headerName: channelSalesPriceHeader,
              headerClass: 'gm-text-right',
              field: '',
              width: 120,
              cellRenderer: (params: ICellRendererParams) => (
                <SalesPriceColumn params={params} channelName='TIKI' />
              ),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
          ],
        },
        {
          // ETC
          headerName: 'ETC',
          columnGroupShow: 'open',
          width: 80,
          children: [
            {
              // 수량
              headerName: tranTableColumn.salesCount,
              headerClass: 'gm-text-right',
              field: '',
              width: 64,
              valueGetter: (params) => countValueGetterByChannel(params, 'ETC'),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
            {
              // 판매 금액
              headerName: channelSalesPriceHeader,
              headerClass: 'gm-text-right',
              field: '',
              width: 120,
              cellRenderer: (params: ICellRendererParams) => (
                <SalesPriceColumn params={params} channelName='ETC' />
              ),
              valueFormatter: convertMoney,
              cellClass: defaultCellClass,
            },
          ],
        },
      ],
    },
    {
      // 합계
      headerName: tranTableColumn.sum,
      headerClass: 'header-span-t-2',
      children: [
        {
          // 수량
          headerName: tranTableColumn.salesCount,
          headerClass: 'gm-text-right',
          field: 'sumSalesCount',
          width: 64,
          valueFormatter: convertMoney,
          cellClass: sumCellClass,
          valueGetter: (params) => addPrefixForSetoff(params?.data?.sumSalesCount || 0),
        },
        {
          // 총 판매 금액 (₫)
          headerName:
            activeTab === StatusEnum.Settlement
              ? tranTableColumn.sumCountrySalesPrice
              : tranTableColumn.sumCountrySetoffPrice,
          headerClass: 'gm-text-right',
          field: 'sumCountrySalesPrice',
          width: 120,
          valueFormatter: convertMoney,
          cellClass: sumCellClass,
          valueGetter: (params) =>
            addPrefixForSetoff(params?.data?.sumCountrySalesPrice || 0),
        },
        {
          // 총 판매 금액 (₩)
          headerName:
            activeTab === StatusEnum.Settlement
              ? tranTableColumn.sumSalesPrice
              : tranTableColumn.sumSetoffPrice,
          headerClass: 'gm-text-right',
          field: 'sumSalesPrice',
          width: 120,
          valueFormatter: convertMoney,
          cellClass: sumCellClass,
          valueGetter: (params) => addPrefixForSetoff(params?.data?.sumSalesPrice || 0),
        },
      ],
    },
  ];
};
