import { Card, Empty } from '@gomi/component';
import { Divider } from 'antd/es';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useImportLicensingDocumentHook } from '@/hooks/importLicensing/details/useImportLicensingDocumentHook';
import { useStore } from '@/stores';
import { FileUploadDragger } from '@/views/importLicensing/importLicensingDetail/common/fileUploadArea/FileUploadDragger';
import { FileUploadList } from '@/views/importLicensing/importLicensingDetail/common/fileUploadArea/FileUploadList';

export const FileUploadArea = observer(() => {
  const { t } = useTranslation(['importLicensingInfo']);
  const {
    importLicensingDetailModel: { file },
  } = useStore();

  /** hooks */
  const { data, isLoading } = useImportLicensingDocumentHook({ id: file.selected });
  const header =
    file.selected && !isLoading
      ? `${file.contentName} - ${data?.documentName || '-'}`
      : t('importLicensingInfo:detailPage.upload.title');

  return (
    <div className='lg:gm-w-[33rem]'>
      <Card
        className='gm-h-full'
        header={header}
        articleClassName='gm-block'
        sectionClassName='gm-block'
        loading={isLoading}
        isContentsBackground={false}
      >
        {!data && (
          <div className='gm-mt-12'>
            <Empty onShowImage>{t('importLicensingInfo:detailPage.upload.empty')}</Empty>
          </div>
        )}
        {data && (
          <>
            <FileUploadDragger />
            <Divider />
            <FileUploadList />
          </>
        )}
      </Card>
    </div>
  );
});
