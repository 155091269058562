import './index.scss';

import { Notion } from '@gomi/component';

import { ContentHeader, DefaultLayout } from '@/component';
import { useNotionContents } from '@/hooks/useNotionContents';
import { NotionKey } from '@/types/enums';

export const Guide = () => {
  const { contents } = useNotionContents(NotionKey.Guide);
  return (
    <DefaultLayout>
      <DefaultLayout.Header />
      <DefaultLayout.Content>
        <ContentHeader title='사용설명서'>
          <a
            href='https://www.notion.so/e7caba6b8ceb4ee4adec810d57947670'
            target='_blank'
            rel='noreferrer'
          >
            설명서 새창으로 열기
          </a>
        </ContentHeader>
        <Notion blockMap={contents} />
      </DefaultLayout.Content>
    </DefaultLayout>
  );
};
