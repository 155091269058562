import { Card } from '@gomi/component';
import { ResponsiveBar } from '@nivo/bar';
import { max } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTopSellingCategories } from '@/hooks/home/useTopSellingCategories';
import { getTextWidth } from '@/lib/getTextWidth';
import { useStore } from '@/stores';
import { HomeEmpty } from '@/views/home/Empty';
import { padding } from '@/views/home/TopSellingBrands';

export const TopSellingCategories = observer(() => {
  const { t } = useTranslation(['home']);
  const {
    homeModel: { startDate, endDate },
  } = useStore();
  const { data, isLoading } = useTopSellingCategories({
    startDate,
    endDate,
  });
  const chartData = data.sort((a, b) => a.count - b.count);
  return (
    <Card header={t('home:dashboard.topSellingCategory.title')} loading={isLoading}>
      <div className='gm-h-[12rem]'>
        {(!data.length && <HomeEmpty />) || null}
        {(data.length && (
          <ResponsiveBar
            data={chartData}
            keys={['count']}
            indexBy='categoryName'
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: max(
                chartData.map((item) => getTextWidth(item.categoryName, '11px', 25)),
              ),
            }}
            padding={padding[chartData.length]}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={(bar) => (bar.index !== chartData.length - 1 ? '#D9D9D9' : '#1890FF')}
            layout='horizontal'
            enableGridY={false}
            enableGridX={false}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            role='application'
          />
        )) ||
          null}
      </div>
    </Card>
  );
});
