import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from '@gomi/component';
import { Modal } from 'antd/es';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ImportLicensingDocumentStatusEnum,
  ImportLicensingSheetStepEnum,
} from '@/generated/graphql-react-query';
import { useControllerHandler } from '@/hooks/importLicensing/details/useControllerHandler';
import { useImportLicensingHook } from '@/hooks/importLicensing/details/useImportLicensingHook';
import { useStore } from '@/stores';
import { IParams } from '@/types/importLicensing/details';
import { TaxBillModal } from '@/views/importLicensing/importLicensingDetail/estimateStep/TaxBillModal';

export const Actions = observer(() => {
  const {
    importLicensingDetailModel: { steps },
  } = useStore();
  const { t } = useTranslation(['importLicensingInfo']);
  const { importLicensingId } = useParams<IParams>();

  const { refetch } = useImportLicensingHook({
    importLicensingId,
  });
  const { requestConfirmation, isLoading } = useControllerHandler();

  const handleSuccess = async () => {
    Modal.confirm({
      title: t('importLicensingInfo:detailPage.steps.fifth.submitConfirm.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('importLicensingInfo:detailPage.steps.fifth.submitConfirm.content'),
      okText: t('importLicensingInfo:detailPage.steps.fifth.submitConfirm.ok'),
      cancelText: t('importLicensingInfo:detailPage.steps.fifth.submitConfirm.cancel'),
      centered: true,
      onOk: async () => {
        await requestConfirmation({
          input: {
            id: importLicensingId,
            update: {
              step: ImportLicensingSheetStepEnum.Estimate,
            },
          },
        });

        await refetch();
      },
    });
  };

  const getActionButtons = (status: ImportLicensingDocumentStatusEnum) => {
    switch (status) {
      case ImportLicensingDocumentStatusEnum.Done:
        return null;
      case ImportLicensingDocumentStatusEnum.RequestEdit:
        return (
          <Button
            color='primary'
            btnType='outlined'
            isDisabled={isLoading}
            onClick={handleSuccess}
          >
            {t('importLicensingInfo:detailPage.steps.fifth.controlBar.deposit')}
          </Button>
        );
      case ImportLicensingDocumentStatusEnum.RequestConfirm:
        return (
          <Button color='primary' btnType='outlined' isDisabled>
            {t('importLicensingInfo:detailPage.steps.fifth.controlBar.onDepositChecking')}
          </Button>
        );
      default:
        return (
          <Button color='primary' btnType='outlined' isDisabled>
            {t('importLicensingInfo:detailPage.steps.fifth.submitConfirm.title')}
          </Button>
        );
    }
  };

  return (
    <>
      {getActionButtons(steps.status)}
      <TaxBillModal />
    </>
  );
});
