import { applySnapshot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';

import brandStore from '@/stores/brand/common/store';
import brandListStore from '@/stores/brand/list/store';
import homeStore from '@/stores/home/store';
import importLicensingDetailStore from '@/stores/importLicensing/details/store';
import importLicensingListStore from '@/stores/importLicensing/list/store';
import reportControlStore from '@/stores/report/control/store';
import monthlyStore from '@/stores/settlement/monthly/store';

let initStore: IStore | undefined;

/** root store */
const store = types.model('store', {
  /** 스토어 아이덴티티 */
  identifier: types.optional(types.identifier, 'store'),

  /** 리포트 컨트롤 */
  reportControlModel: types.optional(
    reportControlStore.model,
    () => reportControlStore.create,
  ),

  /** 브랜드 관리 */
  brandModel: types.optional(brandStore.model, () => brandStore.create),
  brandListModel: types.optional(brandListStore.model, () => brandListStore.create),

  /** 월별 정산 내역 관리 */
  monthlyModel: types.optional(monthlyStore.model, () => monthlyStore.create),

  /** 수입 허가 */
  importLicensingListModel: types.optional(
    importLicensingListStore.model,
    () => importLicensingListStore.create,
  ),
  importLicensingDetailModel: types.optional(
    importLicensingDetailStore.model,
    () => importLicensingDetailStore.create,
  ),

  /** 홈 스토어 */
  homeModel: types.optional(homeStore.model, () => homeStore.create),
});

/** default state value */
const defaultValue: IStoreSnapshotIn = {
  monthlyModel: { ...monthlyStore.defaultValue },
};

/** 스토어 initialize */
const initializeStore = (snapshot: null | IStoreSnapshotIn = null): IStore => {
  const storeVal = initStore ?? store.create(defaultValue);

  if (snapshot) {
    applySnapshot(storeVal, { ...defaultValue, ...snapshot });
  }

  if (!initStore) initStore = storeVal;

  return initStore;
};

/** context api */
const RootStoreContext = createContext<null | IStore>(null);
const StoreProvider = RootStoreContext.Provider;

/** mobx 스토어 hooks */
const useStore = (): IStore => {
  const store = useContext(RootStoreContext);

  if (store === null) {
    throw new Error('Store is null');
  }

  return store;
};

/** store export */
export { defaultValue, initializeStore, StoreProvider, useStore };

/** type export */
export interface IStoreInjectType {
  store: IStore;
}
export type IStore = Instance<typeof store>;
export type IStoreSnapshotIn = SnapshotIn<typeof store>;
export type IStoreSnapshotOut = SnapshotOut<typeof store>;
export type { IMonthlyModelType } from '@/stores/settlement/monthly/store';
