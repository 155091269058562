const LOCALE = {
  vi: { code: 'vi-VN', currency: 'VND' },
  ko: { code: 'ko-KR', currency: 'KRW' },
} as const;

export const convertNumberFormat = (locale: keyof typeof LOCALE, value: number) => {
  return new Intl.NumberFormat(LOCALE[locale].code).format(value);
};

export const convertCurrencyFormat = (locale: keyof typeof LOCALE, value: number) => {
  return new Intl.NumberFormat(LOCALE[locale].code, {
    style: 'currency',
    currency: LOCALE[locale].currency,
  }).format(value);
};

export const convertDateFormat = ({
  locale = 'ko',
  date,
}: {
  locale?: keyof typeof LOCALE;
  date: string;
}) => {
  const value = new Date(date);
  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'full',
    timeStyle: 'short',
  }).format(value);
};
