import { CountryEnum } from '@/generated/graphql';

export const AUTH = {
  NOTICE: '이 웹사이트는 구글 크롬 브라우저에 최적화되어 있습니다',
};

export const REFERRER_SESSION_KEY = 'referrer';

export const SIGN_IN_FORM = {
  SIGN_IN: '로그인',
  EMAIL: {
    EMAIL_LABEL: '이메일',
    REQUIRED_EMAIL: '이메일을 입력해주세요',
    NONE_EMAIL_PATTERN: '이메일 형식이 아닙니다',
  },
  PASSWORD: {
    PASSWORD_LABEL: '비밀번호',
    REQUIRED_PASSWORD: '비밀번호를 입력해주세요',
  },
};

export const NOTIFICATION = {
  MESSAGE: {
    FAILED_SIGN_IN: '로그인에 실패했습니다',
  },
};

export const CountryOrder = [CountryEnum.Vn, CountryEnum.Th, CountryEnum.Cl];
