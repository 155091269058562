import { useTopSellingCategoriesQuery } from '@/generated/graphql-react-query';

export const useTopSellingCategories = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const { data, isLoading } = useTopSellingCategoriesQuery({
    input: {
      filter: {
        startedAt: startDate,
        endAt: endDate,
      },
    },
  });

  return {
    data: data?.topSellingCategories || [],
    isLoading,
  };
};
