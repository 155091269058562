import { message } from 'antd/es';

import {
  ImportLicensingDocumentStatusEnum,
  ImportLicensingSheetStepEnum,
  useImportLicensingQuery,
  useRequestImportLicensingTaxBillMutation,
} from '@/generated/graphql-react-query';

interface IImportLicensingHookProps {
  importLicensingId: string;
}

export const useImportLicensingHook = ({
  importLicensingId,
}: IImportLicensingHookProps) => {
  const { data, isLoading, refetch } = useImportLicensingQuery(
    {
      importLicensingId,
    },
    {
      enabled: !!importLicensingId,
    },
  );

  const { mutateAsync: requestTaxBill, isLoading: isRequestTaxBillLoading } =
    useRequestImportLicensingTaxBillMutation({
      onSuccess: () => {
        message.success('세금계산서 발행을 요청하였습니다.');
        refetch();
      },
    });

  const stepStatuses: { [key: number]: ImportLicensingDocumentStatusEnum } = {
    0:
      data?.importLicensing.productCreateStatus ||
      ImportLicensingDocumentStatusEnum.Ready,
    1:
      data?.importLicensing.documentPerProductStatus ||
      ImportLicensingDocumentStatusEnum.Ready,
    2:
      data?.importLicensing.extraDocumentStatus ||
      ImportLicensingDocumentStatusEnum.Ready,
    3:
      data?.importLicensing.documentDeliveryStatus ||
      ImportLicensingDocumentStatusEnum.Ready,
    4: data?.importLicensing.estimateStatus || ImportLicensingDocumentStatusEnum.Ready,
    5: data?.importLicensing.doneStatus || ImportLicensingDocumentStatusEnum.Ready,
  };

  /** 현재 스탭으로 유효한지 판단하는 함수 */
  const isValidStep = (current: number) => {
    const alwaysValidSteps = [0, 1, 2]; // 상품 등록 / 상품별 서류 제출 / 추가 서류 단계는 제외;
    if (alwaysValidSteps.includes(current)) {
      return true;
    }

    // 이전 스탭이 완료일 경우만 유효
    return stepStatuses[current - 1] === ImportLicensingDocumentStatusEnum.Done;
  };

  const isValidNextStep = (current: number) => {
    const alwaysValidSteps = [0, 1]; // 상품 등록 / 상품별 서류 제출 제외;
    if (alwaysValidSteps.includes(current)) {
      return true;
    }

    // 현재 스탭이 완료될 때
    return stepStatuses[current] === ImportLicensingDocumentStatusEnum.Done;
  };

  return {
    /** 각 숫자형 스탭 별 상태 */
    stepStatuses,
    taxBill: {
      request: requestTaxBill,
      loading: isRequestTaxBillLoading,
      requested: data?.importLicensing.isRequestTaxInvoice,
    },
    notarizationFile: {
      link: data?.importLicensing.notarizationFileLink,
    },
    step: data?.importLicensing.step || ImportLicensingSheetStepEnum.CreateProduct,
    importLicenseName: data?.importLicensing.importLicenseName || '',
    requiredPeriod: data?.importLicensing.requiredPeriod,
    companyId: data?.importLicensing.company.id,
    isValidStep,
    isValidNextStep,
    isLoading,
    refetch,
  };
};
