import { IPaging } from '@gomi/hooks';
import { message } from 'antd';
import { isEmpty } from 'lodash-es';

import { ALL } from '@/constant';
import { FindProductsFilter, InputMaybe } from '@/generated/graphql';
import {
  useExportProductsV2Mutation,
  useProductListQuery,
} from '@/generated/graphql-react-query';

interface IUseProductListHook {
  paging: IPaging;
  searchKeyword: string;
  brandIds: string[];
}

/** hook - 상품목록 */
export const useProductListHook = ({
  paging,
  searchKeyword,
  brandIds,
}: IUseProductListHook) => {
  /** gql query, mutation filter */
  const getFilter = () => ({
    ...(!isEmpty(brandIds.filter((id) => id !== ALL)) && {
      brandIds: brandIds.filter((brandId) => brandId !== ALL),
    }),
  });

  /** gql - 상품 목록 */
  const productListQuery = useProductListQuery(
    {
      input: {
        pagination: {
          limit: paging.size,
          offset: (paging.no - 1) * paging.size,
        },
        filter: getFilter() as InputMaybe<FindProductsFilter>,
        keyword: `%${searchKeyword}%`,
      },
    },
    {
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  /** gql - 엑셀 다운로드 */
  const exceldownMutation = useExportProductsV2Mutation({
    onSuccess: ({ exportProductsV2 }) => {
      window.open(exportProductsV2);
    },
  });

  return {
    productListQuery,
    exceldownMutation,
    productListTotalCount: productListQuery.data?.productList.total || 0,
    getFilter,
  };
};
