import styled from '@emotion/styled';
import { Card, Empty, ExcelButton } from '@gomi/component';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import tw from 'twin.macro';

import { OrderChannelEnum } from '@/generated/graphql';
import { ReportExportExcelEnum } from '@/generated/graphql-react-query';
import { useChannelsHook } from '@/hooks/report/useChannelsHook';
import { useReportDownloadHook } from '@/hooks/report/useReportDownloadHook';
import { useSalesAmountByProductHook } from '@/hooks/report/useSalesAmountByProductHook';
import { convertNumberFormat } from '@/lib/convertIntlFormat';
import { useStore } from '@/stores';
import { ChartTooltip } from '@/views/report/sales/salesAmountByProductChart/ChartTooltip';

const BarTypesByChannel: { [key in OrderChannelEnum]: { color: string } } = {
  [OrderChannelEnum.Gomi]: { color: '#91D5FF' },
  [OrderChannelEnum.Mall]: { color: '#85A5FF' },
  [OrderChannelEnum.Shopee]: { color: '#9254DE' },
  [OrderChannelEnum.ShopeeBrand]: { color: '#FFC069' },
  [OrderChannelEnum.Lazada]: { color: '#FF7A45' },
  [OrderChannelEnum.LazadaBrand]: { color: '#B7EB8F' },
  [OrderChannelEnum.Sendo]: { color: '#36CFC9' },
  [OrderChannelEnum.SendoBrand]: { color: '#17A589' },
  [OrderChannelEnum.Tiki]: { color: '#D35400' },
  [OrderChannelEnum.TikiBrand]: { color: '#73C6B6' },
  [OrderChannelEnum.Tiktok]: { color: '#F4D03F' },
  [OrderChannelEnum.TiktokBrand]: { color: '#239B56' },
  [OrderChannelEnum.Etc]: { color: '#2C3E50' },
};

const Wrapper = styled.div`
  .card-article {
    ${tw`gm-h-[25rem]`}
    .recharts-responsive-container {
      ${tw`gm-flex gm-justify-center gm-items-center`}
    }
  }
`;

const FormatLegendText = (value: string) => {
  return (
    <span className='gm-text-xs' style={{ color: '#262626' }}>
      {value}
    </span>
  );
};

export const SalesAmountByProductChart = observer(() => {
  const {
    reportControlModel: {
      countryId,
      brandId,
      salesCode,
      startDate,
      endDate,
      orderStatus,
      paymentStatus,
      shippingStatus,
      channel,
      gift,
    },
  } = useStore();

  const { channelList } = useChannelsHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
  });

  const { salesAmountByProduct } = useSalesAmountByProductHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
  });

  /* 상품별 판매 금액 엑셀 다운로드 */
  const { download, isLoading: isDownloadLoading } = useReportDownloadHook({
    countryId,
    brandId,
    salesCode,
    startDate,
    endDate,
    orderStatus,
    paymentStatus,
    shippingStatus,
    channel,
    gift,
  });

  const { t } = useTranslation();

  const formatLabel = (label: string) =>
    label.length > 13 ? label.substr(0, 20).concat('..') : label;

  const formatXAxis = (value: string) => {
    return convertNumberFormat('ko', Number(value));
  };

  const handleDownloadExcel = () => {
    download(ReportExportExcelEnum.SalesAmountByProduct);
  };

  return (
    <Wrapper>
      <Spin spinning={salesAmountByProduct?.isLoading}>
        <Card
          header={
            <div className='gm-flex gm-justify-between gm-items-center'>
              {t('report:chart.salesAmountByProduct')}
              <ExcelButton
                type='button'
                onClick={handleDownloadExcel}
                loading={isDownloadLoading}
                isDisabled={!salesAmountByProduct.list.length || isDownloadLoading}
              >
                {t('common:common.button.download')}
              </ExcelButton>
            </div>
          }
        >
          <ResponsiveContainer width='100%' height='100%'>
            {!salesAmountByProduct.list.length ? (
              <Empty onShowImage>No Data</Empty>
            ) : (
              <ComposedChart
                layout='vertical'
                data={salesAmountByProduct.list}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 10,
                  left: 10,
                }}
              >
                <CartesianGrid stroke='#f5f5f5' />
                <XAxis
                  type='number'
                  fontSize={10}
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={formatXAxis}
                />
                <YAxis
                  dataKey='name'
                  type='category'
                  fontSize={10}
                  width={150}
                  tickFormatter={(label) => formatLabel(label)}
                />
                <Tooltip content={<ChartTooltip channelList={channelList} />} />
                <Legend
                  iconSize={12}
                  iconType='square'
                  formatter={(value: string) =>
                    FormatLegendText(
                      channelList.find((ch) => ch.channelName === value)?.displayName ||
                        value,
                    )
                  }
                />
                {channelList.map((channel) => {
                  return (
                    <Bar
                      key={channel.channelName}
                      dataKey={channel.channelName}
                      fill={
                        BarTypesByChannel[channel.channelName as OrderChannelEnum].color
                      }
                      stackId='a'
                      barSize={24}
                    />
                  );
                })}
              </ComposedChart>
            )}
          </ResponsiveContainer>
        </Card>
      </Spin>
    </Wrapper>
  );
});
