import { Button } from 'antd';
import { ReactNode } from 'react';

import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';

interface MenuOpenCloseProps {
  onChangeCollapsed(): void;
  children: ReactNode;
}

export const MenuOpenClose = ({ onChangeCollapsed, children }: MenuOpenCloseProps) => {
  const { isPC } = useMediaQueryHook();

  return (
    <Button
      type='text'
      key='trigger-key-2'
      onClick={onChangeCollapsed}
      className={isPC ? 'gm-absolute gm-translate-x-[-87%]' : 'gm-ml-[-0.5rem]'}
    >
      <span id='sidebar-trigger' key='trigger-key-3'>
        {children}
      </span>
    </Button>
  );
};
