import { momentDateFormat } from '@/lib';
import { convertNumberFormat } from '@/lib/convertIntlFormat';

export const axisNumberFormat = (value: string): string => {
  return convertNumberFormat('ko', Number(value));
};

export const axisDateFormat = (value: string) => {
  return momentDateFormat.convertDateYYMMDD(value);
};
