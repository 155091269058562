import { globalEnv } from '@/config/env';
import { localStorageController } from '@/lib/localStorageController';

export const authTokenStorage = {
  getToken: () => {
    if (!globalEnv.TOKEN_KEY) return null;
    const token = localStorageController.get(globalEnv.TOKEN_KEY);
    return token || null;
  },
  setToken: (token: string): void => {
    if (!globalEnv.TOKEN_KEY) return;
    localStorageController.set(globalEnv.TOKEN_KEY, token);
  },
  clearToken: (): void => {
    if (!globalEnv.TOKEN_KEY) return;
    localStorageController.remove(globalEnv.TOKEN_KEY);
  },
};
