import styled from '@emotion/styled';
import { Input } from 'antd';

export const UnitInput = styled(Input)`
  background-color: white !important;
  width: 40px !important;
  padding-left: 0;
  padding-right: 0;
`;

export const Unit = ({ unit }: { unit: string }) => {
  return <UnitInput disabled value={unit} />;
};
