import { Button } from '@gomi/component';
import { useModalHook } from '@gomi/hooks';
import { useTranslation } from 'react-i18next';

import { ContentHeader as PageHeader } from '@/component';
import { CreateModal } from '@/views/order/management/CreateModal';

export const ContentHeader = () => {
  const { open, onModalOpen, onModalClose } = useModalHook();
  const { t } = useTranslation(['orderInfo']);

  const handleCreateModalOpen = () => {
    onModalOpen();
  };

  return (
    <>
      <PageHeader title={t('orderInfo:managementPage.title')}>
        <Button color='primary' btnType='filled' onClick={handleCreateModalOpen}>
          {t('orderInfo:managementPage.createButton')}
        </Button>
      </PageHeader>
      <CreateModal open={open} onClose={onModalClose} />
    </>
  );
};
