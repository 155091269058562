import { fetcher } from '@gomi/utils';

import { globalEnv } from '@/config/env';
import { authTokenStorage } from '@/lib/authToken';

const getAuthToken = () => {
  return authTokenStorage.getToken();
};
const graphqlUrl = globalEnv.GRAPHQL_URL;

/** react-query 에서 graphql 호출을 위한 fetcher */
export const gqlFetcher = fetcher(graphqlUrl, getAuthToken);
