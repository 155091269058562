import { ColDef, ColGroupDef, ICellRendererParams } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';

import { convertNumberFormat } from '@/lib/convertIntlFormat';

/** list column */
export const EstimateListColumn = (): ColDef[] | ColGroupDef[] => {
  const { t } = useTranslation(['importLicensingInfo']);

  return [
    {
      headerName: t('importLicensingInfo:table.no'),
      width: 55,
      cellStyle: { textAlign: 'center' },
      cellRenderer: ({ rowIndex }: ICellRendererParams) => rowIndex + 1,
    },
    {
      headerName: t('importLicensingInfo:table.item'),
      cellClass: 'gm-flex gm-items-center',
      field: 'itemName',
      cellStyle: { textAlign: 'left' },
      width: 240,
      minWidth: 240,
      flex: 1,
    },
    {
      headerName: t('importLicensingInfo:table.document'),
      cellClass: 'gm-flex gm-items-center',
      width: 280,
      field: 'document',
      cellStyle: { textAlign: 'left' },
    },
    {
      headerName: t('importLicensingInfo:table.unit'),
      cellClass: 'gm-flex gm-items-center',
      width: 160,
      field: 'unit',
      cellStyle: { textAlign: 'left' },
    },
    {
      headerName: t('importLicensingInfo:table.count'),
      cellClass: 'gm-flex gm-items-center',
      width: 140,
      field: 'count',
      cellStyle: { textAlign: 'left' },
      cellRenderer: (row: { value: number }) =>
        row.value ? convertNumberFormat('ko', row.value) : '',
    },
    {
      headerName: t('importLicensingInfo:table.price'),
      cellClass: 'gm-flex gm-items-center',
      width: 140,
      field: 'price',
      cellStyle: { textAlign: 'left' },
      cellRenderer: (row: { value: number }) =>
        row.value ? convertNumberFormat('ko', row.value) : '',
    },
    {
      headerName: t('importLicensingInfo:table.vat'),
      cellClass: 'gm-flex gm-items-center',
      width: 140,
      field: 'vat',
      cellStyle: { textAlign: 'left' },
      cellRenderer: (row: { value: number }) =>
        row.value ? convertNumberFormat('ko', row.value) : '',
    },
    {
      headerName: t('importLicensingInfo:table.totalPrice'),
      cellClass: 'gm-flex gm-items-center',
      width: 140,
      field: 'total',
      cellStyle: { textAlign: 'left' },
      cellRenderer: (row: { value: number }) =>
        row.value ? convertNumberFormat('ko', row.value) : '',
    },
  ];
};
