export const getTextWidth = (text: string, font: string, space = 0) => {
  if (!document) {
    return 0;
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return 0;
  }

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text).width + space;
};
