import styled from '@emotion/styled';
import { AntButton } from '@gomi/component';
import { Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SIGN_IN_FORM } from '@/constant/auth';
import { AuthContainer } from '@/container';
import { PATH } from '@/routes';
import { FindPasswordFormData } from '@/types/types';

const FindPasswordInfoRow = styled(Row)`
  width: 100%;
  margin-bottom: 1rem;
`;

const FindPasswordInput = styled(Input)`
  display: block;
`;

const FindPasswordEmailForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FindPasswordSubmitButton = styled(AntButton)`
  padding: 0 1.5rem;
`;

const SendEmailMessage = styled.span`
  margin-top: 2rem;
  font-size: 1rem;
`;

const FindPasswordInputFormItem = styled(Form.Item)`
  width: 100%;
`;

const ToLoginPageButton = styled(AntButton)`
  margin-top: 40px;
`;

export const FindPasswordForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { findPasswordPage } = AuthContainer.useContainer();

  const onClickToLoginPageButton = () => {
    history.push(PATH.LOGIN);
  };

  return (
    <>
      {!findPasswordPage.isSendFindEmail ? (
        <FindPasswordEmailForm
          id='findPasswordForm'
          onFinish={(value) =>
            findPasswordPage.onFinishFindPassword(value as FindPasswordFormData)
          }
        >
          <FindPasswordInfoRow>{t('info.inputEmailSignup')}</FindPasswordInfoRow>
          <FindPasswordInputFormItem
            name='emailFindPassword'
            rules={[
              {
                required: true,
                message: t('validationMessage.requiredEmail'),
              },
              {
                pattern: new RegExp('[\\w\\d._+]+@[\\d\\w]+\\.[\\d\\w.]'),
                message: SIGN_IN_FORM.EMAIL.NONE_EMAIL_PATTERN,
              },
            ]}
          >
            <FindPasswordInput placeholder={t('placeholder.email')} size='large' />
          </FindPasswordInputFormItem>
          <FindPasswordSubmitButton htmlType='submit' type='primary'>
            {t('common.button.confirm')}
          </FindPasswordSubmitButton>
        </FindPasswordEmailForm>
      ) : (
        <>
          <SendEmailMessage>{t('info.sendEmail')}</SendEmailMessage>
          <ToLoginPageButton onClick={onClickToLoginPageButton} type='primary'>
            {t('common.button.toLoginPage')}
          </ToLoginPageButton>
        </>
      )}
    </>
  );
};
