export const ko = {
  MENU: {
    HOME: '홈',
    ORDER: '주문',
    information: '정보 관리',
    REPORT: '리포트',
    IMPORT_LICENSING: '수입 허가 등록',
    SALES_REPORT: '판매 리포트',
    LOGISTIC_REPORT: '물류 리포트',
    MONTHLY_SETTLEMENT: '정산',
    GUIDE: '사용설명서',
    SETTLEMENT: '정산',
    product: {
      list: '상품 목록',
    },
    brand: {
      rootMenu: '브랜드 정보',
      list: '브랜드 목록',
    },
    importLicensing: {
      list: '수입 허가 등록',
      template: '템플릿 관리',
    },
    order: {
      management: '주문 관리',
    },
  },
  common: {
    contactMe: {
      description:
        "'의견 보내기' 는 별도의 답변을 드리고 있지는 않으나 소중한 의견들을 토대로 발전하는 파트너센터가 되겠습니다.",
      title: '의견 보내기',
      button: {
        send: '보내기',
      },
      placeholder: {
        content: '제안사항이나 의견을 적어주세요. (최대 500자)',
      },
      completedModal: {
        title: '의견 보내기 완료',
        content1: '소중한 의견을 보내주셔서 감사합니다.',
        content2: '항상 발전하는 고미 파트너가 되겠습니다.',
        button: {
          confirm: '확인',
        },
      },
    },
    userNameSubtext: '님',
    placeholder: {
      searchPartner: '파트너를 검색하세요',
      dragClickFileUpload: '클릭하거나 파일을 드래그하면 업로드합니다',
      selectBox: '선택해주세요',
      https: 'https://',
      selectBrand: '브랜드를 선택하세요',
      country: '전체 국가',
      name: '이름',
    },
    button: {
      add: '추가',
      delete: '삭제',
      register: '등록',
      toRegister: '등록하기',
      confirm: '확인',
      cancel: '취소',
      new: '신규',
      exist: '기존',
      download: '다운로드',
      searchAddress: '주소검색',
      save: '저장',
      toDelete: '삭제하기',
      toEdit: '수정하기',
      toSignUp: '가입하기',
      toLoginPage: '로그인 페이지로 이동',
      excelExport: '엑셀 다운로드',
    },
    confirm: {
      create: '등록하시겠습니까?',
      edit: '수정하시겠습니까?',
      deleteTitle: '정말 삭제하시겠습니까?',
      addTitle: '추가하시겠습니까?',
      process: '정말 처리하시겠습니까?',
      ok: '확인',
      cancel: '취소',
    },
    notification: {
      failFileDownload: '파일 다운로드에 실패했습니다',
      failProcess: '처리에 실패했습니다.',
      successCreate: '등록되었습니다.',
      successProcess: '처리되었습니다',
      successDelete: '삭제되었습니다',
      failedDelete: '삭제를 실패했습니다',
      invalidRequest: '유효하지 않은 요청입니다.',
      successSave: '변경되었습니다.',
    },
  },
  COMMON_POP_CONFIRM: {
    CONFIRM_ADD: '추가하시겠습니까?',
    CONFIRM_DELETE: '정말 삭제하시겠습니까?',
    OK: '확인',
    CANCLE: '취소',
  },
  COMMON_MODAL_FOOTER: {
    SAVE: '저장',
    CANCLE: '취소',
  },
  COMMON_BUTTON: {
    DELETE: '삭제',
  },
  SETTLEMENT: {
    APPROVAL: '정산처리 되었습니다.',
    APPROVAL_CANCEL: '정산처리가 취소 되었습니다.',
    APPROVAL_ERROR: '처리를 실패했습니다.',
    APPROVAL_CANCEL_ERROR: '처리에 실패했습니다.',
    OFFSET_ERROR: '처리에 실패했습니다.',
  },
  SETTLEMENT_STATUS_BADGE: {
    EDITING: '수정중',
    APPROVAL: '승인',
  },
  SETTLEMENT_DETAIL_TYPE: {
    ALL: 'ALL',
    ERROR_SETTLEMENT: '정산 오류',
    ERROR_OFFSET: '상계 오류',
    CANCEL: '취소',
    UNSETTLEMENT: '미정산',
    ERROR_GSPC: 'GSPC 누락',
    ERROR_ORDER_NUMBER: '주문번호 누락',
    WHOLESALE: '도매',
    SETTLEMENT: '정산',
    OFFSET: '상계',
  },
  PARTNER_USER: {
    SEARCH_PLACEHOLDER: '이름 혹은 이메일 혹은 파트너명',
    SEARCH_KEYWORD_TOO_SHORT_ERROR: '검색어는 3글자 이상이어야 합니다.',
  },
  GOMI_USER: {
    SEARCH_PLACEHOLDER: '이름 혹은 이메일',
    SEARCH_KEYWORD_TOO_SHORT_ERROR: '검색어는 3글자 이상이어야 합니다.',
  },
  NOTIFICATION: {
    SUCCESS_DELETE: '삭제되었습니다',
    FAILED_DELETE: '삭제를 실패했습니다',
  },
  settlement: {
    detailList: '정산 내역 목록',
    review: '정산 검토',
    partnerApproval: '정산 승인',
    requestApproval: '승인 요청',
    approval: '승인 완료',
    paid: '지급 완료',
    detail: {
      title: '정산내역',
      table: {
        tab: {
          paid: {
            title: '결제내역',
          },
          offset: {
            title: '상계내역',
          },
        },
      },
    },
  },
  notification: {
    settlement: {
      pending:
        '보류된 정산내역 {{count}}건이 승인 완료되었습니다. 지난 내역에서 확인해주세요.',
      detailOnReview: '아직 정산내역이 준비되지 않았습니다.',
      detailBlockApproval: '정산내역을 다시 검토 중입니다. 잠시만 기다려주세요.',
      dashboardEmpty: '해당월의 정산이 미완료 상태입니다',
      dashboardError: '정산 내역을 불러올 수 없습니다.',
      successSendEmail: '메일을 전송했습니다',
      successRequestApproval: '승인 요청을 보냈습니다',
      successCancelRequestApproval: '승인 취소되었습니다',
      successApproval: '승인되었습니다',
      successPayment: '지급 처리되었습니다',
      successCancelPayment: '지급 취소 처리되었습니다',
      failedSendEmail: '메일 전송을 실패했습니다.',
      partner: {
        register: {
          success: '정산 대상으로 등록했습니다.',
          fail: '등록하지 못했습니다.',
        },
        delete: {
          success: '정산 대상에서 삭제했습니다.',
          fail: '삭제하지 못했습니다.',
        },
      },
      changeOrderStatueReasonEmpty: '사유를 입력해주세요',
      noSearchOrderData: '검색결과가 없습니다',
      alreadyExistOrder: '이번 달 정산에 포함되어 있습니다',
      includeOrderInSettlement: '정산대상에 포함되었습니다',
      includeOrderInSettlementFail: '오류가 발생했습니다',
      failCreateSettlementIndex: '정산 대시보드 생성에 실패했습니다.',
      notExistSettlementIndex:
        '해당 연월에 대시보드가 존재하지 않습니다.\n 아래 버튼을 클릭하여 대시보드를 생성해주세요.',
      failUploadFile: '업로드에 실패했습니다. 잠시 후 다시 시도해주세요.',
    },
    entering: {
      deleteBrand: '등록된 브랜드를 먼저 삭제해주세요',
      registerBrand: '브랜드를 등록하세요',
    },
    signUp: {
      successRegister: '회원가입에 성공했습니다',
    },
    serverError: '잠시후 다시 시도해주세요',
    internalServerError:
      '예상하지 못한 오류가 발생했습니다. 파트너센터 파트에 문의해주세요.',
  },
  country: {
    vn: '베트남',
    cl: '칠레',
    th: '태국',
    jp: '일본',
    kr: '한국',
  },
  column: {
    settlement: {
      partner: '파트너',
      price: '결제금액(원)',
      feePrice: '수수료(원)',
      offsetPrice: '상계금액(원)',
      totalPrice: '최종 정산금액(원)',
      requestApprovalDate: '승인 요청일',
      approvalDate: '승인 완료일',
      paidDate: '지급 완료일',
      orderNumber: '주문번호',
      productName: '상품명',
      orderDate: '주문 날짜',
      count: '수량',
      orderPrice: '가격',
      description: '비고',
      channel: '채널',
      gspc: 'GSPC',
      orderStatus: '주문상태',
      paymentStatus: '결제상태',
      partnerCenterPrice: '파트너센터 가격 (₫)',
      misaPrice: '미사 가격 (₫)',
      itemName: '상품명',
      payment: '결제방식',
      gomiflowPrice: '결제금액(VND)',
      exchangeRate: '평균 환율',
      feeRate: '수수료율(%)',
      settlementPriceKr: '정산금액(원)',
      emailCount: '메일 전송 횟수',
    },
    partner: {
      entering: {
        title: '파트너',
        companyRegistrationNumber: '사업자등록번호',
        brandCount: '브랜드 수',
        memberCount: '맴버 수',
      },
    },
    brand: {
      entering: {
        title: '브랜드',
        logo: '로고',
        homepage: '홈페이지',
        countries: '진출 국가',
        partner: '파트너',
        status: '상태',
      },
      list: {
        brandKrName: '국문 브랜드명',
        brandEnName: '영문 브랜드명',
        productCount: '등록 상품 수량',
        brandCountry: '진출 국가',
        brandLogoImage: '로고 이미지',
      },
    },
    product: {
      list: {
        productKrName: '국문 상품명',
        gspc: 'GSPC',
        brandKrName: '국문 브랜드명',
        brandName: '영문 브랜드명',
        countryOfEntry: '진출 국가',
        category: '제품 카테고리',
        productGroupKrName: '국문 제품 그룹명',
        productGroupName: '영문 제품 그룹명',
        barcode: '바코드',
        supplierProductCode: '공급사 제품 코드',
        hsCode: 'HS 코드',
        productUnit: '제품 단위',
        outerBoxIntake: '외박스 입수량',
        outerBoxStandardWidth: '외박스 규격 (가로)',
        outerBoxStandardHeight: '외박스 규격 (세로)',
        palletIntake: '팔레트 입수량',
        palletStandardWidth: '팔레트 규격 (가로)',
        palletStandardHeight: '팔레트 규격 (세로)',
        productNetWeight: '제품 순중량',
        productTotalWeight: '제품 총중량',
        outerBoxTotalWeight: '외박스 총중량',
        retailPriceVn: '소비자가 (베트남)',
        retailPriceTh: '소비자가 (태국)',
        regularSalePrice: '판매가 (상시)',
        discountSalePrice: '판매가 (할인)',
        purchaseUnitPrice: '매입 단가',
        supplyUnitPrice: '공급 단가',
        productNameVn: '제품명 (베트남)',
        productNameTh: '제품명 (태국)',
        importLicenseNameVn: '수입허가명 (베트남)',
        importLicenseNameTh: '수입허가명 (태국)',
        manufacturerName: '제조사명',
        productKeyword: '제품 키워드',
      },
    },
    user: {
      list: {
        name: '이름',
        email: '이메일',
        phoneNumber: '연락처',
      },
    },
    report: {
      date: '날짜',
      channel: '채널',
      orderNumber: '주문번호',
      payMethod: '결제방식',
      orderDate: '주문일',
      orderStatus: '주문상태',
      paymentStatus: '결제상태',
      shippingStatus: '배송상태',
      productName: '상품명',
      price: '판매 가격',
      priceOriginal: '소비자가격',
      settlementMonth: '정산월',
      count: '수량',
      totalPrice: '총 판매 금액',
      sum: '합계',
      ok: '확인',
      cancel: '취소',
    },
  },
  button: {
    registration: '등록',
    delete: '삭제',
    change: '변경',
    header: {
      logout: '로그아웃',
      guide: '사용설명서',
    },
    settlement: {
      delete: '삭제',
      approvalPartner: '승인',
      cancelPaid: '지급 완료 취소',
      cancelApproval: '승인 요청 취소',
      sendEmail: '정산 메일 보내기',
      requestApproval: '정산 승인 요청',
      paid: '정산 지급',
      orderManagement: '주문관리',
      addPartner: '정산대상 추가',
      uploadDataCheck: '업로드 데이터 확인',
      fileDownload: '정산 파일 다운로드',
      misaUpload: '정산 파일 업로드',
      selectFile: '파일 선택',
      uploadFile: '업로드 하기',
      confirm: '확정',
      cancelConfirm: '확정 취소',
      create: '생성',
      uploadRunning: '파일 업로드 중입니다...',
      completeUpload: '파일 업로드 완료',
      misaDataConfirm: '미사 데이터 확정',
      misaCancelUpload: '미사 업로드 취소',
      offset: '상계',
      settlement: '정산',
    },
    partner: {
      businessRegistration: '사업자 등록증 보기',
      emptyBusinessRegistration: '사업자 등록증 미등록',
      account: '계좌 사본 보기',
      emptyAccount: '계좌 사본 미등록',
      entering: {
        registration: '파트너 등록',
      },
    },
    brand: {
      entering: {
        registration: '브랜드 등록',
      },
    },
    product: {
      all: 'ALL',
      default: '기본',
      logis: '물류',
      price: '가격',
      detail: '상세',
    },
  },
  tag: {
    settlement: {
      review: '정산 검토',
      requestApproval: '승인 요청',
      approval: '승인 완료',
      paid: '지급 완료',
      cancelPayment: '지급 취소',
      cancelRequestApproval: '승인 취소',
    },
    entering: {
      waitConfirm: '승인대기중',
      approve: '승인',
      onReview: '검토중',
      deny: '거절',
    },
  },
  placeholder: {
    email: '이메일',
    search: '검색',
    newPassword: '새 비밀번호 (10자리 이상)',
    confirmPassword: '비밀번호 확인',
    settlement: {
      searchOrder: '주문번호를 검색해주세요',
      changeOrderStatueReasonTextArea: '사유를 입력해주세요',
    },
    entering: {
      postalCode: '우편번호',
      defaultAddress: '기본 주소',
      detailAddress: '상세 주소',
      accountHolder: '예금주',
      accountNumber: '계좌번호',
      bank: '은행 선택',
    },
    signUp: {
      email: '이메일',
      password: '비밀번호(10자리 이상)',
      confirmPassword: '비밀번호 확인',
      userName: '이름(2글자 이상)',
      phoneNumber: 'ex: 01012345678',
    },
    brand: {
      EnKrBrandName: '국문, 영문 브랜드명',
    },
    product: {
      productNameGspc: '상품명, GSPC',
    },
  },
  pageTitle: {
    settlement: {
      partner: '정산대상 관리',
      verification: '상세 내역',
      misaUpload: '정산 파일 업로드',
      orderRegister: '주문 관리',
    },
    partner: {
      info: '회사 정보',
      businessRegistration: '사업자 등록증',
      account: '계좌 사본',
      entering: {
        signup: '파트너 가입',
        enrollment: '파트너 등록',
        info: '파트너 정보',
        editPartner: '파트너 정보 수정',
      },
    },
    brand: {
      entering: {
        management: '브랜드 관리',
        enrollment: '브랜드 등록',
      },
      list: '브랜드 ({{count}})',
    },
    product: {
      list: '상품 ({{count}})',
    },
    user: {
      list: '사용자 ({{count}})',
    },
    signUp: {
      description1: '고미를 통해',
      description2: '해외 판매를 시작하세요!',
    },
    findPassword: '비밀번호 찾기',
    changePassword: '비밀번호 변경',
  },
  title: {
    report: {
      detailOrderItem: '주문 상세 정보',
      salesStatusByDate: '날짜별 판매현황',
      salesStatusByProduct: '상품별 판매현황',
      stock: '재고',
      stockIn: '입고',
      stockOut: '출고',
    },
  },
  modalTitle: {
    settlement: {
      partner: '정산대상 추가',
      misaUpload: '정산 파일 업로드',
    },
  },
  channel: {
    gomimall: 'gomi',
    haravanMall: 'mall',
    haravanKr: 'gomi kr',
    haravanMobile: 'app',
    tiki: 'tiki',
    haravan: 'haravan',
    sendo: 'sendo',
    lazada: 'lazada',
    shopee: 'shopee',
  },
  popConfirm: {
    settlement: {
      approval:
        '승인처리하면 이전 상태로 돌아갈 수 없습니다. 정말 승인 처리하시겠습니까?',
      requestApproval: '정말 승인 요청 처리하시겠습니까?',
      payment: '정말 지급 처리하시겠습니까?',
      cancelPayment: '정말 지급 취소 처리하시겠습니까?',
      cancelRequestApproval: '정말 승인 요청 취소 처리하시겠습니까?',
      sendEmail: '정말 메일을 전송하시겠습니까? ({{count}}회 전송)',
      askConfirm: '정산 데이터를 확정하시겠습니까?',
      aksCancelConfirm: '확정을 취소하시겠습니까?',
    },
    entering: {
      requestApproval: '승인 요청하시겠습니까?',
      cancelRequestApproval: '승인 요청을 취소하시겠습니까?',
    },
  },
  card: {
    settlement: {
      settlementAmount: '정산 금액',
      offsetAmount: '상계 금액',
      finalAmount: '최종 정산 금액',
      amountPaid: '결제금액',
      totalPaidDetailVn: '결제내역 합계 (베트남 통화)',
      exchangeRateInfo: '평균환율 (매매기준율 - 100VND)',
      totalPaidDetailKr: '결제내역 합계 (한국 통화)',
      fee: '수수료',
      totalFeeGomi: '고미몰 수수료 합계',
      totalFeeChannel: '기타 채널 수수료 합계',
      amountOffset: '상계금액',
      totalOffsetDetailKr: '상계내역 합계 (한국 통화)',
      totalSettlementAmount: '최종 정산금액',
      totalSettlementAmountDesc: '(결제금액) - (수수료) - (상계금액)',
    },
  },
  tooltip: {
    settlement: {
      offset: '이전 정산 완료된 주문 건의 변동 사항 발생 내역',
    },
  },
  label: {
    entering: {
      companyRegistration: '사업자등록증',
      accountCopy: '정산계좌사본',
    },
    report: {
      brand: '브랜드명',
      item: '상품명',
      channel: '채널명',
      period: '조회기간',
      orderStatus: '주문상태',
      payStatus: '결제상태',
      deliveryStatus: '배송상태',
      gift: 'Gift 유무',
      excludeGift: 'Gift 제외',
      onlyGift: 'Gift만 보기',
      case: '건',
      total: '합계',
      all: 'ALL',
      ok: '확인',
      cancel: '취소',
      paid: '결제',
      notPaid: '미결제',
      refund: '환불',
      ready: '배송준비',
      delivering: '배송중',
      delivered: '배송완료',
      return: '반품',
      waitingForReturn: '반품대기',
      gomi: 'GOMI',
      gomiKr: 'GOMI_KR',
      mall: 'MALL',
      lazada: 'Lazada',
      sendo: 'Sendo',
      shopee: 'Shopee',
      tiki: 'Tiki',
      app: 'APP',
      tiktok: 'Tiktok',
    },
  },
  inputLabel: {
    entering: {
      companyRegistrationNumber: '사업자등록번호',
      companyRegistration: '사업자등록증',
      representativeName: '대표자 명',
      companyKrName: '법인명',
      companyUsName: '영문 법인명',
      corporationRegistrationNumber: '법인등록번호',
      address: '사업장주소',
      account: '정산계좌',
      taxBillEmail: '전자세금계산서 이메일 주소',
      partner: '파트너',
      countries: '진출 국가',
      brandKo: '브랜드 (국문)',
      brandEn: '브랜드 (영문)',
      brandDesc: '브랜드 설명',
      homepage: '홈페이지',
      brandLogoImage: '브랜드 로고 이미지',
      logoImage: '로고 이미지',
      bankBook: '통장사본',
    },
    signUp: {
      email: '이메일',
      password: '비밀번호(10자리 이상)',
      confirmPassword: '비밀번호 확인',
      userName: '이름(2글자 이상)',
      phoneNumber: '휴대폰 번호',
    },
  },
  validationMessage: {
    requiredEmail: '이메일을 입력해주세요',
    selectBrand: '브랜드를 선택하세요',
    requiredNewPassword: '새 비밀번호를 입력해주세요',
    requiredPassword: '비밀번호를 입력해주세요',
    passwordMinLength: '10자이상 입력해주세요',
    notMatchPassword: '비밀번호가 일치하지 않습니다',
    entering: {
      required: {
        defaultMessage: '{{title}}을(를) 입력해주세요',
        companyRegistrationNumber: '사업자등록번호를 입력해주세요',
        representativeName: '대표자 명을 입력해주세요',
        companyKrName: '법인명(국문)을 입력해주세요',
        companyUsName: '법인명(영문)을 입력해주세요',
        postalCode: '우편번호를 입력해주세요',
        detailAddress: '상세주소를 입력해주세요',
        defaultAddress: '기본주소를 입력해주세요',
        selectBank: '은행을 선택해주세요',
        accountHolder: '예금주를 입력해주세요',
        accountNumber: '계좌번호를 입력해주세요',
        upload: '이미지를 업로드해주세요',
      },
      pattern: {
        companyRegistrationNumber: '000-00-00000 의 형식이 아닙니다',
      },
    },
    signUp: {
      email: '올바른 형식의 메일주소가 필요합니다',
      password: '비밀번호를 10자리 이상 입력해주세요',
      confirmPassword: '비밀번호가 일치하지 않습니다',
      userName: '이름을 2글자 이상 입력해주세요',
      phoneNumber: '올바른 형식의 휴대폰 번호가 필요합니다',
    },
  },
  link: {
    findPassword: '비밀번호 찾기',
  },
  info: {
    changedPassword: '비밀번호가 변경되었습니다',
    sendEmail: '이메일을 발송했습니다',
    inputEmailSignup: '가입하신 이메일을 입력해주세요',
  },
  table: {
    message: {
      noRowBrandData: '등록된 브랜드가 없습니다.',
      noRowProductData: '등록된 상품이 없습니다.',
      noRowUserData: '등록된 유저가 없습니다',
      noSearchRow: '검색 결과가 없습니다.',
    },
  },
  tab: {
    report: {
      salesStatus: '판매현황',
      deliveryStatus: '물류현황',
    },
  },
  error: {
    report: {
      failedLoadReport: '리포트를 불러오는데 실패했습니다',
      noReport: '상품을 판매 중인 국가가 없습니다.',
      noProductsForSaleInCountry: '해당 국가에 판매중인 상품이 없습니다.',
    },
  },
  chart: {
    salesStatus: '판매현황 (VND)',
    salesAmountByProduct: '상품별 판매금액 (VND)',
    legend: {
      ok: '주문확인',
      cancel: '주문취소',
      totalAmount: '총 금액',
    },
  },
};
