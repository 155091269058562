import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import GomiAdminLogo from '../assets/icons/gomiAdminLogo.svg?component';
import GomiAdminLogoSymbol from '../assets/icons/gomiAdminSymbol.svg?component';
import GomiLogo from '../assets/icons/gomiLogo.svg?component';
import GomiLogoSymbol from '../assets/icons/gomiSymbol.svg?component';

const LogoWrapper = styled('div')`
  margin: 1.25rem 0;
  svg {
    display: block;
  }
`;

interface LogoInterface {
  collapsed: boolean;
  size?: 'small' | 'medium';
  path: string;
  isAdmin?: boolean;
}

const logoSize = {
  small: {
    height: 26,
  },
  medium: {
    height: 32,
  },
};

export const SideMenuLogo: FC<LogoInterface> = ({
  collapsed,
  size = 'medium',
  path,
  isAdmin = false,
}) => {
  const logoRenderer = isAdmin ? (
    <GomiAdminLogo {...logoSize[size]} />
  ) : (
    <GomiLogo {...logoSize[size]} />
  );
  const symbolRenderer = isAdmin ? (
    <GomiAdminLogoSymbol {...logoSize[size]} />
  ) : (
    <GomiLogoSymbol {...logoSize[size]} />
  );

  return (
    <Link to={path}>
      <LogoWrapper
        className={collapsed ? 'gm-flex gm-justify-center' : 'lg:gm-pl-[1.5rem]'}
      >
        {collapsed ? symbolRenderer : logoRenderer}
      </LogoWrapper>
    </Link>
  );
};
