export const TRACK_EVENT_NAME = {
  REPORT_ORDER_DETAIL_DOWNLOAD: '리포트_주문상세정보_다운로드',
  REPORT_SALES_STATUS_BY_DATE_DOWNLOAD: '리포트_날짜별판매현황_다운로드',
  REPORT_SALES_STATUS_BY_PRODUCT_DOWNLOAD: '리포트_상품별판매현황_다운로드',
  REPORT_SALES_AMOUNT_BY_PRODUCT_DOWNLOAD: '리포트_상품별판매금액_다운로드',
  REPORT_SALES_STATUS_DOWNLOAD: '리포트_판매현황_다운로드',
  LOGISTIC_STOCK_OUT_CLICK: '물류_출고_다운로드',
  LOGISTIC_STOCK_IN_CLICK: '물류_입고_다운로드',
  LOGISTIC_STOCK_CLICK: '물류_재고_다운로드',
  BRAND_DETAIL_CLICK: '브랜드_상세_클릭',
  BRAND_DOWNLOAD_CLICK: '브랜드_다운로드_클릭',
  PRODUCT_DETAIL_CLICK: '상품_상세_클릭',
  PRODUCT_DOWNLOAD_CLICK: '상품_다운로드_클릭',
  LOGIN_CLICK: 'LOGIN_CLICK',
  LOGOUT_CLICK: 'LOGOUT_CLICK',
  REGISTER_CLICK: 'REGISTER_CLICK',
  IMPORT_LICENSING_CREATE_CLICK: '수입허가_등록_클릭',
  IMPORT_LICENSING_CREATE_MODAL_CLICK: '수입허가_등록_모달_클릭',
};
