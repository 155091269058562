import { ALL } from '@/constant';
import { usePaymentStatusCountQuery } from '@/generated/graphql-react-query';
import { IReportControl } from '@/types/report/type';

export const usePaymentStatusCountHook = ({
  startDate,
  endDate,
  brandId,
  channel,
  gift,
  orderStatus,
  paymentStatus,
  shippingStatus,
  salesCode,
  countryId,
}: IReportControl) => {
  const { data, isLoading } = usePaymentStatusCountQuery({
    input: {
      filter: {
        countryId,
        startOrderedAt: startDate,
        endOrderedAt: endDate,
        ...(brandId !== ALL && { brandId }),
        ...(channel !== ALL && { channel }),
        ...(gift !== ALL && { giftType: gift }),
        ...(orderStatus !== ALL && { orderStatus }),
        ...(paymentStatus !== ALL && { paymentStatus: [paymentStatus] }),
        ...(shippingStatus !== ALL && { shippingStatus: [shippingStatus] }),
        ...(salesCode !== ALL && { salesCode: [salesCode] }),
      },
    },
  });

  return {
    data: data?.paymentStatusCount || {
      paid: 0,
      not_paid: 0,
      refund: 0,
    },
    isLoading,
  };
};
