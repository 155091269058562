import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Divider, Form, Modal, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FilePathEnum, useBrandQuery } from '@/generated/graphql-react-query';
import { useUploadedFilesHook } from '@/hooks/brand/useUploadedFilesHook';
import { useStore } from '@/stores';
import { IBrandInfoForm } from '@/types/brand';
import { FileUploadFormItem, InputFormItem } from '@/views/common/FormItems';

interface IPropsDetailModal {
  brandId: string;
}

export const DetailModal = observer(({ brandId }: IPropsDetailModal) => {
  const {
    brandModel: { visibleModal, setVisibleModal },
  } = useStore();
  const { t } = useTranslation(['partnerInfo', 'brandInfo']);

  // 모달 내 업로드 파일 상태
  const { uploadedFiles, setUploadFilesValue } = useUploadedFilesHook();

  // 브랜드 정보(form value로 사용)
  const isVisible = visibleModal === 'detail';
  const { data: brandData } = useBrandQuery({ brandId }, { enabled: isVisible });
  const brand = brandData?.partnerBrand;

  const [form] = Form.useForm<IBrandInfoForm>();

  useEffect(() => {
    if (brand) {
      const { brandImage, brandName, brandKrName, brandCode, countryIds } = brand;

      form.setFieldsValue({
        brandKrName,
        brandName,
        brandCode,
        countryIds,
        brandImageId: brandImage?.id,
      });

      setUploadFilesValue({ data: brand });
    }
  }, [brand, isVisible]);

  return (
    <Modal
      title={
        brand?.brandKrName ||
        brand?.brandName ||
        t('brandInfo:modal.title.registrationTitle')
      }
      open={isVisible}
      onCancel={() => setVisibleModal(null)}
      width={664}
      afterClose={form.resetFields}
      destroyOnClose
      footer={null}
    >
      <Form
        form={form}
        name='BRAND_INFO_FORM'
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        autoComplete='off'
      >
        {/* 브랜드 코드 */}
        <InputFormItem
          name='brandCode'
          label={t('brandInfo:modal.label.brandCode')}
          disabled
          hidden={!brand?.brandCode}
        />

        {/* 브랜드명 국문 */}
        <InputFormItem
          name='brandKrName'
          label={t('brandInfo:modal.label.brandKrName')}
          placeholder={t('brandInfo:modal.placeholder.noSpaceAndNoSpecialChar')}
          disabled
        />

        {/* 브랜드명 영문 */}
        <InputFormItem
          name='brandName'
          label={t('brandInfo:modal.label.brandName')}
          placeholder={t('brandInfo:modal.placeholder.noSpaceAndNoSpecialCharEn')}
          disabled
        />

        {/* 진출 국가 */}
        <Form.Item name='countryIds' label={t('brandInfo:modal.label.countries')}>
          <Select
            mode='multiple'
            className='partnerInfoSelectInput'
            placeholder={t('brandInfo:modal.placeholder.countries')}
            options={brand?.countryIds.map((id) => ({ value: id, label: id }))}
            disabled
          />
        </Form.Item>

        {/* 로고 */}
        <FileUploadFormItem
          name='brandImageId'
          label={t('brandInfo:modal.label.logo')}
          uploadFile={uploadedFiles[FilePathEnum.BrandLogoImage]}
          placeholder={t('partnerInfo:modal.placeholder.notExistFile')}
          fileType={FilePathEnum.BrandLogoImage}
          disabled
        />

        <Divider />
      </Form>
    </Modal>
  );
});
