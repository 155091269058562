// @ts-ignore (@types 설치를 안하는 이유는 버전이 낮음)
import mixpanel from 'mixpanel-browser';

interface ITrackerInitOptions {
  track_pageview?: boolean;
}

interface Dict {
  [key: string]: any;
}

/**
 * @types/mixpanel-browser에 지원하지 않은 mixpanel
 * */
class Tracker {
  init(token: string, options?: ITrackerInitOptions) {
    mixpanel.init(token, options);
  }

  track(eventName: string, options?: Dict) {
    mixpanel.track(eventName, options);
  }

  identify(email: string) {
    mixpanel.identify(email);
  }

  set(properties: Dict) {
    mixpanel.people.set(properties);
  }
}

export const Track = new Tracker();
