import i18next from 'i18next';

import { checkDefinedLocaleLang, localeStorage } from '@/locale/lib';
import { LocaleEnum } from '@/locale/types';

export const getInitLocaleLang = () => {
  const definedLocaleLang = checkDefinedLocaleLang(
    localeStorage.getItem() || LocaleEnum.Ko,
  );
  localeStorage.setItem(definedLocaleLang);
  i18next.changeLanguage(definedLocaleLang);
  return definedLocaleLang;
};
