import { NewCommon } from '@/locale/data/types/newCommon.type';

export const enCommon: NewCommon = {
  message: {
    copied: 'copied.',
    update: 'edited',
    upload: {
      fail: 'fail to upload.',
    },
    error: '유요하지 않은 요청입니다. 아래 채널톡으로 문의해주세요.',
  },
  login: {
    findPassword: '비밀번호 찾기',
  },
  register: {
    title: '회원가입',
    form: {
      email: {
        label: '이메일',
        required: '이메일을 입력해주세요.',
        pattern: '이메일 양식이 유효하지 않습니다.',
        exist: '이미 존재하는 이메일입니다.',
      },
      companyName: {
        label: '회사명',
        required: '회사명을 입력해주세요.',
      },
      password: {
        label: '비밀번호',
        required: '비밀번호를 입력해주세요.',
      },
    },
    submit: '가입하기',
    success: '로그인 후 이용해주세요.',
  },
};
