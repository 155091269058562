import { Button, LoggingClick } from '@gomi/component';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentHeader as PageHeader } from '@/component';
import { TRACK_EVENT_NAME } from '@/constant/track';
import { CreateModal } from '@/views/importLicensing/importLicensingList/CreateModal';

export const ContentHeader = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { t } = useTranslation(['importLicensingInfo']);

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
  };

  return (
    <>
      <PageHeader title={t('importLicensingInfo:listPage.title')}>
        <LoggingClick eventName={TRACK_EVENT_NAME.IMPORT_LICENSING_CREATE_MODAL_CLICK}>
          <Button color='primary' btnType='filled' onClick={handleCreateModalOpen}>
            {t('importLicensingInfo:listPage.modal.button')}
          </Button>
        </LoggingClick>
      </PageHeader>
      <CreateModal open={isCreateModalOpen} onClose={handleCreateModalClose} />
    </>
  );
};
