import {
  useMutation,
  useQuery,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { gqlFetcher } from '@/lib/gqlFetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export enum AuthorizationEnum {
  CompanyRequestInspect = 'COMPANY_REQUEST_INSPECT',
  CompanyRequestRequest = 'COMPANY_REQUEST_REQUEST',
  ImportLicensingRoot = 'IMPORT_LICENSING_ROOT',
  OrderRoot = 'ORDER_ROOT',
  ReportRoot = 'REPORT_ROOT',
  Root = 'ROOT',
  SettlementRoot = 'SETTLEMENT_ROOT',
}

export type B2bOrderContractCompanyType = {
  __typename?: 'B2bOrderContractCompanyType';
  /** 파트너 국문명 */
  companyKrName?: Maybe<Scalars['String']>;
  /** 파트너 영문명 */
  companyName?: Maybe<Scalars['String']>;
  /** 파트너 ID */
  id?: Maybe<Scalars['ID']>;
};

export type B2bOrderContractConfigProductType = {
  __typename?: 'B2bOrderContractConfigProductType';
  /** GSPC */
  gspc: Scalars['String'];
  /** 상품 ID */
  id: Scalars['ID'];
  /** 상품명 국문 */
  productKrName: Scalars['String'];
  /** 상품명 영문 */
  productName: Scalars['String'];
};

export type B2bOrderContractFilterType = {
  /** 파트너 id */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 연결 해지 여부 */
  isDisconnect?: InputMaybe<Scalars['Boolean']>;
};

export type B2bOrderContractType = {
  __typename?: 'B2bOrderContractType';
  /** 구매 회사 정보 */
  buyerCompany: B2bOrderContractCompanyType;
  /** 고미 담당자 */
  gomiManager: B2bOrderUserType;
  /** 파트너 연결 ID */
  id: Scalars['ID'];
  /** 판매 회사 정보 */
  sellerCompany: B2bOrderContractCompanyType;
};

export type B2bOrderDetailProductType = {
  __typename?: 'B2bOrderDetailProductType';
  /** 바코드 */
  barcode?: Maybe<Scalars['String']>;
  /** 상품 ID */
  id: Scalars['ID'];
  /** 내박스 수량 */
  innerBoxQuantity: Scalars['Int'];
  /** 외박스 수량 */
  outerBoxQuantity: Scalars['Int'];
  /** 상품명 국문 */
  productKrName: Scalars['String'];
  /** 상품명 영문 */
  productName: Scalars['String'];
};

export type B2bOrderDetailType = {
  __typename?: 'B2bOrderDetailType';
  /** 주문 ID */
  b2bOrderId: Scalars['ID'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 주문 상세 ID */
  id: Scalars['ID'];
  /** 재고 수량 */
  inventoryQuantity: Scalars['Int'];
  /** 주문 수량 */
  orderQuantity: Scalars['Int'];
  /** 상품 */
  product: B2bOrderDetailProductType;
  /** 매입 단가 */
  purchaseUnitPrice: Scalars['Float'];
  /** 소비자가(MSRP) */
  retailPrice: Scalars['Int'];
  /** 공급가 */
  salesPrice: Scalars['Float'];
  /** 상품 총 금액 */
  totalPrice: Scalars['Float'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type B2bOrderFileType = {
  __typename?: 'B2bOrderFileType';
  /** 파일 확장자 */
  fileExtension: Scalars['String'];
  /** 파일  */
  fileKey: Scalars['String'];
  /** 파일 */
  fileName: Scalars['String'];
  /** 파일 ID */
  id: Scalars['ID'];
  /** 파일링크 */
  link: Scalars['String'];
  /** 파일 타입 */
  type: FilePathEnum;
};

export type B2bOrderListInput = {
  /** 파트너 id */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 수출 진행 제외 여부 */
  isExcludeExport?: InputMaybe<Scalars['Boolean']>;
  /** 주문 상태 - ORDER_READY: 주문 준비, ORDER_CREATE: 주문을 최초 생성, ORDER_REQUEST_CONFIRM: 주문 확인 요청, PENDING_DEPOSIT: 입금 대기, DONE: 완료 */
  status?: InputMaybe<B2bOrderStatusEnum>;
};

export enum B2bOrderStatusEnum {
  Done = 'DONE',
  OrderCreate = 'ORDER_CREATE',
  OrderReady = 'ORDER_READY',
  OrderRequestConfirm = 'ORDER_REQUEST_CONFIRM',
  OrderWaitingForConfirm = 'ORDER_WAITING_FOR_CONFIRM',
  PendingDeposit = 'PENDING_DEPOSIT',
}

export type B2bOrderUserType = {
  __typename?: 'B2bOrderUserType';
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 유저 ID */
  id?: Maybe<Scalars['ID']>;
  /** 이름 */
  userName?: Maybe<Scalars['String']>;
};

export type BulkUploadInputDto = {
  /** BASIC: 기본 정보, LOGISTICS: 물류 정보, PRICE: 가격 정보, DETAIL: 상세 정보 */
  type: ProductRequestTypeEnum;
};

export type ChannelType = {
  __typename?: 'ChannelType';
  /** 채널 정보 */
  channel: OrderChannelEnum;
  /** 주문 상태 */
  orderStatuses: Array<OrderStatusType>;
};

export enum CompanyGroupEnum {
  G01 = 'G01',
  G02 = 'G02',
  G03 = 'G03',
  G08 = 'G08',
  G16 = 'G16',
  G24 = 'G24',
  G40 = 'G40',
  G50 = 'G50',
  G99 = 'G99',
}

export type CompanyGroupType = {
  __typename?: 'CompanyGroupType';
  /** 파트너 그룹(01 / 고미코퍼레이션 본사 (KR)', '02 / 고미퍼레이션 해외 지사', '03 / 고미코퍼레이션 자회사', '08 / 제조사', '16 / 공급사', '24 / 유통사', '40 / 물류 협력사', '50 / 마케팅 협력사', '99 / 기타 협력사) */
  groupId?: Maybe<CompanyGroupEnum>;
  /** 파트너 그룹 명 */
  name: Scalars['String'];
};

export type CompanyRequestGomiManagerRel = {
  __typename?: 'CompanyRequestGomiManagerRel';
  /** 파트너 요청 ID */
  companyRequestId: Scalars['ID'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** id */
  id?: Maybe<Scalars['ID']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 담당자 아이디 */
  userId: Scalars['ID'];
};

export enum CompanyRequestStatusEnum {
  Done = 'DONE',
  ExternalRequest = 'EXTERNAL_REQUEST',
  Rejection = 'REJECTION',
  RejectionComplete = 'REJECTION_COMPLETE',
  Request = 'REQUEST',
  RequestComplete = 'REQUEST_COMPLETE',
  Temporary = 'TEMPORARY',
}

export type ConfirmB2bOrderInputType = {
  /** 주문 상세 ID */
  id: Scalars['ID'];
  /** 재고 수량 */
  inventoryQuantity: Scalars['Int'];
  /** 주문 수량 */
  orderQuantity: Scalars['Int'];
};

export type ContentType = {
  __typename?: 'ContentType';
  documents: Array<DocumentType>;
  /** content ID */
  id: Scalars['ID'];
  /** 상품명 */
  productName: Scalars['String'];
};

export enum CountryEnum {
  Cl = 'CL',
  Jp = 'JP',
  Kr = 'KR',
  Sg = 'SG',
  Th = 'TH',
  Vn = 'VN',
}

export type CreateProductGroupOptionGroupType = {
  /** 옵션 그룹 명 */
  productOptionGroupName: Scalars['String'];
  /** 옵션 목록 */
  productOptions: Array<CreateProductGroupOptionType>;
};

export type CreateProductGroupOptionType = {
  /** 옵션 명 */
  productOptionName: Scalars['String'];
};

export type CreateProductRequestByInspectorInputType = {
  /** 바코드 */
  barcode: Scalars['String'];
  /** 태국 판매가(할인) A */
  discountSalePriceThA?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(할인) B */
  discountSalePriceThB?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(할인) S */
  discountSalePriceThS?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) A */
  discountSalePriceVnA?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) B */
  discountSalePriceVnB?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) S */
  discountSalePriceVnS?: InputMaybe<Scalars['Int']>;
  /** HS 코드 */
  hsCode?: InputMaybe<Scalars['String']>;
  /** 패키지 수량 */
  innerBoxCount?: InputMaybe<Scalars['Int']>;
  /** 외박스 수량 */
  outerBoxCount?: InputMaybe<Scalars['Int']>;
  /** 상품 그룹 id */
  productGroupId: Scalars['ID'];
  /** 상품 ID */
  productId?: InputMaybe<Scalars['ID']>;
  /** 상품명 국문 */
  productKrName: Scalars['String'];
  /** 상품명 영문 */
  productName: Scalars['String'];
  /** 상품 옵션 */
  productOptionRels: Array<CreateProductRequestOptionRel>;
  /** 매입 단가 */
  purchaseUnitPrice?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(상시) */
  regularSalePriceTh?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(상시) */
  regularSalePriceVn?: InputMaybe<Scalars['Int']>;
  /** 소비자가(한국) */
  retailPriceKr?: InputMaybe<Scalars['Int']>;
  /** 소비자가(태국) */
  retailPriceTh?: InputMaybe<Scalars['Int']>;
  /** 소비자가(베트남) */
  retailPriceVn?: InputMaybe<Scalars['Int']>;
  /** 요청 상태 - DONE: 검수완료 */
  status: CompanyRequestStatusEnum;
  /** 공급사 상품 코드 */
  supplierProductCode: Scalars['String'];
  /** BASIC: 기본 정보, LOGISTICS: 물류 정보, PRICE: 가격 정보, DETAIL: 상세 정보 */
  type?: InputMaybe<ProductRequestTypeEnum>;
};

export type CreateProductRequestOptionRel = {
  /** 상품 그룹 id */
  productOptionId: Scalars['ID'];
  /** 상품 요청 id */
  productRequestId?: InputMaybe<Scalars['ID']>;
};

export type DepartmentType = {
  __typename?: 'DepartmentType';
  /** 부서 아이디 */
  departmentId?: Maybe<Scalars['ID']>;
  /** 부서 */
  departmentName?: Maybe<Scalars['String']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  /** 서류명 */
  documentName: Scalars['String'];
  /** document ID */
  id: Scalars['ID'];
  /** 메시지 */
  message?: Maybe<Scalars['String']>;
};

export type DtoB2bOrderContractListInput = {
  /** 발주 파트너 연결 조회 필터 */
  filter?: InputMaybe<B2bOrderContractFilterType>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
};

export type DtoB2bOrderListInput = {
  /** 발주 파트너 연결 조회 필터 */
  filter?: InputMaybe<B2bOrderListInput>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
};

export type DtoConfirmB2bOrderInput = {
  /** 주문 ID */
  id: Scalars['ID'];
  /** 주문 확인할 주문 상세 데이터 */
  update: Array<ConfirmB2bOrderInputType>;
};

export type DtoCreateB2bOrderContractConfigInput = {
  /** 파트너 연결 ID */
  b2bOrderContractId: Scalars['ID'];
  /** 상품 ID */
  productId: Scalars['ID'];
  /** 매입 단가 */
  purchaseUnitPrice: Scalars['Float'];
  /** 소비자가 */
  retailPrice: Scalars['Int'];
  /** 공급액 */
  salesPrice: Scalars['Float'];
};

export type DtoCreateB2bOrderContractInput = {
  /** 구매 회사 ID */
  buyerCompanyId: Scalars['ID'];
  /** 구매 담당자 ID */
  buyerManagerId: Scalars['ID'];
  /** 고미 담당자 ID */
  gomiManagerId: Scalars['ID'];
  /** 구매자 배송 주소 */
  receiverAddress: Scalars['String'];
  /** 구매자 연락처 */
  receiverContactInformation: Scalars['String'];
  /** 구매자 수령인 이름 */
  receiverName: Scalars['String'];
  /** 판매 회사 ID */
  sellerCompanyId: Scalars['ID'];
  /** 판매 담당자 ID */
  sellerManagerId: Scalars['ID'];
};

export type DtoCreateB2bOrderInput = {
  /** B2B 주문 계약 id */
  b2bOrderContractId: Scalars['ID'];
};

export type DtoCreateBrandRequest = {
  /** 브랜드명 약어 */
  brandAbbr?: InputMaybe<Scalars['String']>;
  /** 브랜드 id */
  brandId?: InputMaybe<Scalars['ID']>;
  /** 브랜드 이미지 id */
  brandImageId?: InputMaybe<Scalars['ID']>;
  /** 브랜드명 (국문) */
  brandKrName: Scalars['String'];
  /** 브랜드명 (영문) */
  brandName: Scalars['String'];
  /** 파트너 id */
  companyId: Scalars['ID'];
  /** 국가 id 리스트 */
  countryIds?: InputMaybe<Array<CountryEnum>>;
  /** 담당자 id 리스트 */
  managerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 요청 상태 */
  status?: InputMaybe<CompanyRequestStatusEnum>;
};

export type DtoCreateCompanyRequest = {
  /** 계좌번호, - 허용 */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** 주소 */
  address?: InputMaybe<Scalars['String']>;
  /** 통장사본 이미지 ID */
  bankImageId?: InputMaybe<Scalars['ID']>;
  /** 은행이름 */
  bankName?: InputMaybe<Scalars['String']>;
  /** 대표자명 */
  ceoName?: InputMaybe<Scalars['String']>;
  /** 파트너 id */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 회사 이미지 ID */
  companyImageId?: InputMaybe<Scalars['ID']>;
  /** 회사명 국문 */
  companyKrName: Scalars['String'];
  /** 회사명, 기본 영어 */
  companyName: Scalars['String'];
  /** 계약서 ID */
  contractId?: InputMaybe<Scalars['ID']>;
  /** 법인등록번호 */
  corporateRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 파트너 id */
  countryId?: InputMaybe<CountryEnum>;
  /** 전자세금계산서 이메일 */
  digitalTaxInvoiceEmail?: InputMaybe<Scalars['String']>;
  /** 파트너 그룹(01 / 고미코퍼레이션 본사 (KR)', '02 / 고미퍼레이션 해외 지사', '03 / 고미코퍼레이션 자회사', '08 / 제조사', '16 / 공급사', '24 / 유통사', '40 / 물류 협력사', '50 / 마케팅 협력사', '99 / 기타 협력사) */
  groupId?: InputMaybe<CompanyGroupEnum>;
  /** 담당자 id 리스트 */
  managerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 사업자등록 이미지 ID */
  registrationImageId?: InputMaybe<Scalars['ID']>;
  /** 사업자 등록번호, - 사용 */
  registrationNumber?: InputMaybe<Scalars['String']>;
  /** 위탁: CONSIGNMENT,0  사입: PURCHASE,1 판매분 사입: PURCHASE,2 */
  salesType?: InputMaybe<SalesTypeEnum>;
  /** 요청 상태 - REQUEST: 검수요청, TEMPORARY: 임시저장 */
  status?: InputMaybe<CompanyRequestStatusEnum>;
};

export type DtoCreateCompanyRequestByExternal = {
  /** 계좌번호, - 허용 */
  accountNumber: Scalars['String'];
  /** 주소 */
  address?: InputMaybe<Scalars['String']>;
  /** 통장사본 이미지 ID */
  bankImageId?: InputMaybe<Scalars['ID']>;
  /** 은행이름 */
  bankName: Scalars['String'];
  /** 대표자명 */
  ceoName?: InputMaybe<Scalars['String']>;
  /** 회사 이미지 ID */
  companyImageId?: InputMaybe<Scalars['ID']>;
  /** 회사명 국문 */
  companyKrName: Scalars['String'];
  /** 회사명, 기본 영어 */
  companyName: Scalars['String'];
  /** 계약서 ID */
  contractId?: InputMaybe<Scalars['ID']>;
  /** 법인등록번호 */
  corporateRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 파트너 id */
  countryId?: InputMaybe<CountryEnum>;
  /** 전자세금계산서 이메일 */
  digitalTaxInvoiceEmail: Scalars['String'];
  /** 파트너 그룹(01 / 고미코퍼레이션 본사 (KR)', '02 / 고미퍼레이션 해외 지사', '03 / 고미코퍼레이션 자회사', '08 / 제조사', '16 / 공급사', '24 / 유통사', '40 / 물류 협력사', '50 / 마케팅 협력사', '99 / 기타 협력사) */
  groupId: CompanyGroupEnum;
  /** 사업자등록 이미지 ID */
  registrationImageId: Scalars['ID'];
  /** 사업자 등록번호, - 사용 */
  registrationNumber: Scalars['String'];
  /** 위탁: CONSIGNMENT,0  사입: PURCHASE,1 판매분 사입: PURCHASE,2 */
  salesType?: InputMaybe<SalesTypeEnum>;
};

export type DtoCreateCompanyRequestByInspector = {
  /** 계좌번호, - 허용 */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** 주소 */
  address?: InputMaybe<Scalars['String']>;
  /** 통장사본 이미지 ID */
  bankImageId?: InputMaybe<Scalars['ID']>;
  /** 은행이름 */
  bankName?: InputMaybe<Scalars['String']>;
  /** 대표자명 */
  ceoName?: InputMaybe<Scalars['String']>;
  /** 회사 ID */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 회사 이미지 ID */
  companyImageId?: InputMaybe<Scalars['ID']>;
  /** 회사명 국문 */
  companyKrName: Scalars['String'];
  /** 회사명, 기본 영어 */
  companyName: Scalars['String'];
  /** 계약서 ID */
  contractId?: InputMaybe<Scalars['ID']>;
  /** 법인등록번호 */
  corporateRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 파트너 국가 ID */
  countryId: CountryEnum;
  /** 전자세금계산서 이메일 */
  digitalTaxInvoiceEmail?: InputMaybe<Scalars['String']>;
  /** 파트너 그룹(01 / 고미코퍼레이션 본사 (KR)', '02 / 고미퍼레이션 해외 지사', '03 / 고미코퍼레이션 자회사', '08 / 제조사', '16 / 공급사', '24 / 유통사', '40 / 물류 협력사', '50 / 마케팅 협력사', '99 / 기타 협력사) */
  groupId: CompanyGroupEnum;
  /** gomiManagerRels, 고미 담당자 아이디 */
  managerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 사업자등록 이미지 ID */
  registrationImageId?: InputMaybe<Scalars['ID']>;
  /** 사업자 등록번호, - 사용 */
  registrationNumber: Scalars['String'];
  /** 위탁: CONSIGNMENT,0  사입: PURCHASE,1 판매분 사입: PURCHASE,2 */
  salesType?: InputMaybe<SalesTypeEnum>;
};

export type DtoCreateExport = {
  /** 국가 정보 */
  countryId?: InputMaybe<CountryEnum>;
  /** export company id */
  exportCompanyId?: InputMaybe<Scalars['ID']>;
};

/** 고미 유저 생성 정보 */
export type DtoCreateGomiUserInput = {
  /** 권한 목록 (기본 권한: []) */
  authorizations?: InputMaybe<Array<AuthorizationEnum>>;
  /** 부서 ID */
  departmentId?: InputMaybe<Scalars['ID']>;
  /** 이메일 */
  email: Scalars['String'];
  /** ERP 코드 (사원번호) */
  erpUserCode?: InputMaybe<Scalars['String']>;
  /** 언어 */
  languageId?: InputMaybe<CountryEnum>;
  /** 패스워드 */
  password: Scalars['String'];
  /** 연락처 */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** 직원 이름 */
  userName: Scalars['String'];
};

export type DtoCreateImportLicensingCommentInput = {
  /** 코멘트 내용 */
  comment: Scalars['String'];
  /** 파일 ID */
  importLicenseFileId: Scalars['ID'];
};

export type DtoCreateImportLicensingContentInput = {
  /** 브랜드명 국문 */
  brandKrName: Scalars['String'];
  /** 브랜드명 */
  brandName: Scalars['String'];
  /** 컨텐츠명 국문 */
  contentKrName: Scalars['String'];
  /** 컨텐츠명 */
  contentName: Scalars['String'];
  /** 희망 판매가 */
  desiredSalesPrice?: InputMaybe<Scalars['Int']>;
  /** 수입 허가 시트 ID */
  importLicensingId: Scalars['ID'];
  /** 제조사명 국문 */
  manufacturerKrName: Scalars['String'];
  /** 제조사명 */
  manufacturerName: Scalars['String'];
  /** 상품 ID */
  productId: Scalars['ID'];
  /** 실제 판매가 */
  salesPrice?: InputMaybe<Scalars['Int']>;
};

export type DtoCreateImportLicensingEstimateInput = {
  /** 수량 */
  count: Scalars['Int'];
  /** 서류 */
  document: Scalars['String'];
  /** 수입 허가 등록 ID */
  importLicenseId: Scalars['ID'];
  /** 항목 */
  itemName: Scalars['String'];
  /** 단가 */
  price: Scalars['Int'];
  /** 단위 */
  unit: Scalars['String'];
};

export type DtoCreateImportLicensingExtraDocumentInput = {
  /** 수입 허가 단계 */
  documentName: Scalars['String'];
  /** 수입 허가 시트 ID */
  importLicensingId: Scalars['ID'];
};

export type DtoCreateImportLicensingFileUploadInput = {
  /** 서류 ID */
  documentId: Scalars['ID'];
  /** 파일 ID 목록 */
  fileIds: Array<Scalars['ID']>;
};

export type DtoCreateImportLicensingInput = {
  /** 파트너 ID */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 국가 ID */
  countryId: CountryEnum;
  /** 수입 허가 등록명 */
  importLicenseName?: InputMaybe<Scalars['String']>;
  /** 담당자 ID */
  managerId?: InputMaybe<Scalars['ID']>;
  /** 파트너 담당자 이메일 */
  partnerContactEmail?: InputMaybe<Scalars['String']>;
  /** 템플릿 ID */
  templateId: Scalars['ID'];
};

export type DtoCreateImportLicensingTemplateInput = {
  /** 카테고리 명 */
  categoryName: Scalars['String'];
  /** 카테고리 명 */
  countryId: CountryEnum;
  /** 소요 기간 */
  requiredPeriod: Scalars['Int'];
  /** 템플릿 내용 JSON 문자열 */
  templateContent: Scalars['String'];
  /** 템플릿명 */
  templateName: Scalars['String'];
};

export type DtoCreateProductGroup = {
  /** 브랜드 ID */
  brandId: Scalars['ID'];
  category?: InputMaybe<Scalars['String']>;
  /** 파트너 ID */
  companyId: Scalars['ID'];
  productGroupKrName: Scalars['String'];
  productGroupName: Scalars['String'];
  productOptionGroups: Array<CreateProductGroupOptionGroupType>;
};

export type DtoCreateProductOption = {
  /** 상품 옵션 그룹 id */
  productOptionGroupId: Scalars['ID'];
  /** 추가할 상품 옵션 목록 */
  productOptionNames: Array<Scalars['String']>;
};

export type DtoCreateProductRequestByRequester = {
  /** 바코드 */
  barcode: Scalars['String'];
  /** 태국 판매가(할인) A */
  discountSalePriceThA?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(할인) B */
  discountSalePriceThB?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(할인) S */
  discountSalePriceThS?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) A */
  discountSalePriceVnA?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) B */
  discountSalePriceVnB?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) S */
  discountSalePriceVnS?: InputMaybe<Scalars['Int']>;
  /** HS 코드 */
  hsCode?: InputMaybe<Scalars['String']>;
  /** 패키지 수량 */
  innerBoxCount?: InputMaybe<Scalars['Int']>;
  /** 외박스 수량 */
  outerBoxCount?: InputMaybe<Scalars['Int']>;
  /** 상품 그룹 id */
  productGroupId: Scalars['ID'];
  /** 상품 ID */
  productId?: InputMaybe<Scalars['ID']>;
  /** 상품명 국문 */
  productKrName: Scalars['String'];
  /** 상품명 영문 */
  productName: Scalars['String'];
  /** 상품 옵션 */
  productOptionRels: Array<CreateProductRequestOptionRel>;
  /** 매입 단가 */
  purchaseUnitPrice?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(상시) */
  regularSalePriceTh?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(상시) */
  regularSalePriceVn?: InputMaybe<Scalars['Int']>;
  /** 소비자가(한국) */
  retailPriceKr?: InputMaybe<Scalars['Int']>;
  /** 소비자가(태국) */
  retailPriceTh?: InputMaybe<Scalars['Int']>;
  /** 소비자가(베트남) */
  retailPriceVn?: InputMaybe<Scalars['Int']>;
  status: CompanyRequestStatusEnum;
  /** 공급사 상품 코드 */
  supplierProductCode: Scalars['String'];
  /** BASIC: 기본 정보, LOGISTICS: 물류 정보, PRICE: 가격 정보, DETAIL: 상세 정보 */
  type?: InputMaybe<ProductRequestTypeEnum>;
};

export type DtoCreateProductRequestsByInspector = {
  productRequests: Array<CreateProductRequestByInspectorInputType>;
};

export type DtoCreateProductRequestsByRequester = {
  productRequests: Array<DtoCreateProductRequestByRequester>;
};

export type DtoCreateSettlementCriteriaConsignment = {
  /** 채널 아이디 */
  channelId: Scalars['String'];
  /** 국가 id */
  countryId: CountryEnum;
  /** 수수료 적용 종료 날짜 */
  endAt?: InputMaybe<Scalars['DateTime']>;
  /** 수수료율 */
  feeRate: Scalars['Int'];
  /** 기본값 여부 0: 이벤트, 1: 기본 */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** 판매 코드 id */
  salesCodeId: Scalars['ID'];
  /** 수수료 적용 시작 날짜 */
  startAt: Scalars['DateTime'];
};

/** 유저 생성 정보 */
export type DtoCreateUser = {
  /** 권한 */
  authorizations?: InputMaybe<Array<AuthorizationEnum>>;
  /** 파트너사 id: c + 16 */
  companyId?: InputMaybe<Scalars['Int']>;
  /** 이메일 */
  email: Scalars['String'];
  /** 패스워드 */
  password: Scalars['String'];
  /** 전화번호 */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** 직원 이름 */
  userName: Scalars['String'];
};

export type DtoExportAgency = {
  __typename?: 'DtoExportAgency';
  address?: Maybe<Scalars['String']>;
  ceo?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  /** id */
  id: Scalars['ID'];
  krAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type DtoExportList = {
  __typename?: 'DtoExportList';
  /** 수출 관리 목록 정보 */
  nodes: Array<DtoReturnExport>;
  /** 총 수량 */
  total: Scalars['Int'];
};

export type DtoExportOrderSalesInfoExcel = {
  filter?: InputMaybe<FindOrderItemsFilter>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
  /** 리포트 엑셀 common 타입(SALES_STATUS: 판매 현황, SALES_STATUS_BY_DATE: 날짜별 판매 현황, SALES_AMOUNT_BY_PRODUCT: 상품별 판매 금액, SALES_STATUS_BY_PRODUCT: 상품별 판매 현황, ORDER_DETAILS: 주문 상세 */
  type: ReportExportExcelEnum;
};

export type DtoExportProduct = {
  __typename?: 'DtoExportProduct';
  barcode?: Maybe<Scalars['String']>;
  boxCount?: Maybe<Scalars['Int']>;
  brandName?: Maybe<Scalars['String']>;
  expireDate?: Maybe<Scalars['String']>;
  exportPrice?: Maybe<Scalars['Float']>;
  exportUsPrice?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  gspc?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  /** id */
  id: Scalars['ID'];
  lotNo?: Maybe<Scalars['String']>;
  netWeight?: Maybe<Scalars['Float']>;
  optionName?: Maybe<Scalars['String']>;
  outerBoxCount?: Maybe<Scalars['Int']>;
  outerBoxDepth?: Maybe<Scalars['Float']>;
  outerBoxHeight?: Maybe<Scalars['Float']>;
  outerBoxWeight?: Maybe<Scalars['Float']>;
  outerBoxWidth?: Maybe<Scalars['Float']>;
  productId: Scalars['ID'];
  productKrName?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  purchaseUnitPrice?: Maybe<Scalars['Float']>;
  shippingMarkNo?: Maybe<Scalars['String']>;
  supplierProductCode?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
};

export type DtoExportProductList = {
  __typename?: 'DtoExportProductList';
  /** 수출 관리 목록 정보 */
  nodes: Array<DtoExportProduct>;
  /** 총 수량 */
  total: Scalars['Int'];
};

export type DtoExporter = {
  __typename?: 'DtoExporter';
  /** 수출자 파트너명 */
  companyName?: Maybe<Scalars['String']>;
};

export type DtoFileInfoList = {
  /** 파일 목록 호출 필터 */
  filter?: InputMaybe<FileInfoListFilterType>;
};

export type DtoFindBrandRequests = {
  filter?: InputMaybe<FindBrandRequestsFilter>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
};

export type DtoFindBrands = {
  filter?: InputMaybe<FindBrandsFilter>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
};

export type DtoFindCompanies = {
  /** 필터 */
  filter?: InputMaybe<FindCompaniesFilter>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
};

export type DtoFindCompanyRequests = {
  filter?: InputMaybe<FindCompanyRequestsFilter>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
};

export type DtoFindOrderItems = {
  filter?: InputMaybe<FindOrderItemsFilter>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
};

export type DtoFindProductGroups = {
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
};

export type DtoFindProductRequests = {
  filter: FindProductRequestsType;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationDto>;
};

export type DtoFindProducts = {
  filter?: InputMaybe<FindProductsFilter>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
};

export type DtoGetGoogleLogin = {
  /** 구글 로그인 토큰 */
  googleToken: Scalars['String'];
};

export type DtoGetLogin = {
  /** 이메일 */
  email?: InputMaybe<Scalars['String']>;
  /** 패스워드 */
  password?: InputMaybe<Scalars['String']>;
};

export type DtoGetMonthlyExchangeRate = {
  filter: GetMonthlyExchangeRateFilterType;
};

export type DtoGetRefreshToken = {
  /** 리프레시 토큰 */
  token: Scalars['String'];
};

export type DtoGetSettlementConsignmentMonthlyInput = {
  /** 필터 */
  filter: GetSettlementConsignmentPartnersFilterType;
  /** 파트너 명 혹은 파트너 코드 */
  keyword?: InputMaybe<KeywordDto>;
};

export type DtoGetSettlementConsignmentPartner = {
  /** 필터 */
  filter: GetSettlementConsignmentPartnerFilterType;
};

export type DtoGetSettlementConsignmentPartnerProductListInput = {
  /** 필터 */
  filter: GetSettlementConsignmentPartnerProductListFilterType;
};

export type DtoGetSettlementConsignmentProductOrders = {
  /** 필터 */
  filter: GetSettlementConsignmentProductOrdersFilterType;
};

export type DtoGetSettlementMonthlies = {
  /** 필터 */
  filter: GetSettlementMonthliesFilterType;
};

export type DtoGetSettlementMonthlySummary = {
  /** 필터 */
  filter: GetSettlementMonthlySummaryType;
};

export type DtoGetSettlementPartner = {
  filter: GetSettlementPartnerFilterTypeV2;
};

export type DtoGetSettlementPartnerCurrentEmails = {
  filter: GetSettlementPartnerCurrentEmailsFilterType;
};

export type DtoGetSettlementPartnerId = {
  filter: GetSettlementPartnerIdFilterType;
};

export type DtoGetSettlementPartners = {
  filter: GetSettlementPartnerFilterType;
  /** 파트너 명 혹은 파트너 코드 */
  keyword?: InputMaybe<KeywordDto>;
};

export type DtoGetSettlementProductList = {
  filter: GetSettlementProductListFilterType;
};

export type DtoGetSettlementProductOrders = {
  filter: GetSettlementProductOrderFilterType;
};

export type DtoGetSettlementProductSummary = {
  filter: GetSettlementProductSummaryFilterType;
};

export type DtoGetSettlementSummary = {
  /** 필터 */
  filter: GetSettlementSummaryFilterType;
};

/** 고미 담당자 목록 */
export type DtoGomiUsers = {
  /** 필터 */
  filter?: InputMaybe<GetGomiUsersFilterType>;
  /** 검색 키워드 */
  keyword?: InputMaybe<KeywordDto>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
};

export type DtoHomeInput = {
  filter?: InputMaybe<HomeInputFilter>;
};

export type DtoImportAgency = {
  __typename?: 'DtoImportAgency';
  address?: Maybe<Scalars['String']>;
  ceo?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  /** id */
  id: Scalars['ID'];
  krAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type DtoImportLicensingCommentListInput = {
  /** 수입 허가 comment 목록 필터 */
  filter: ImportLicensingCommentListInputFilterType;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
};

export type DtoImportLicensingContentListInput = {
  /** 수입 허가 시트 필터 */
  filter?: InputMaybe<ImportLicensingContentListInputFilterType>;
};

export type DtoImportLicensingListInput = {
  /** 수입 허가 시트 필터 */
  filter?: InputMaybe<ImportLicensingListInputFilterType>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
};

export type DtoImportLicensingTemplateListInput = {
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
};

export type DtoInputBasicSettlementCriteriaConsignment = {
  filter: GetBasicSettlementCriteriaConsignmentInputFilterDto;
};

export type DtoInputBasicSettlementCriteriaPurchaseOfSales = {
  filter: GetBasicSettlementCriteriaPurchaseOfSalesInputFilterDto;
};

export type DtoInputFilterSettlementCriteriaConsignmentList = {
  /** 판매 코드 식별키 */
  salesCodeId: Scalars['ID'];
};

export type DtoInputFilterSettlementCriteriaPurchaseOfSalesList = {
  /** 상품 id */
  productId: Scalars['ID'];
};

export type DtoInputSettlementCriteriaConsignmentList = {
  filter: DtoInputFilterSettlementCriteriaConsignmentList;
};

export type DtoInputSettlementCriteriaPurchaseOfSalesList = {
  filter: DtoInputFilterSettlementCriteriaPurchaseOfSalesList;
};

export type DtoLoadExport = {
  /** common id */
  orderId?: InputMaybe<Scalars['ID']>;
};

/** 파트너 유저 목록 */
export type DtoPartnerUsers = {
  /** 필터 */
  filter?: InputMaybe<GetPartnerUsersFilterType>;
  /** 검색 키워드 */
  keyword?: InputMaybe<KeywordDto>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
};

export type DtoRegisterB2bOrderInput = {
  /** 주문 ID */
  id: Scalars['ID'];
  /** 주문 확인할 주문 상세 데이터 */
  update: RegisterB2bOrderInputType;
};

/** 회원가입 인풋 */
export type DtoRegisterUser = {
  /** 회사명 */
  companyName: Scalars['String'];
  /** 이메일 */
  email: Scalars['String'];
  /** 파트너 그룹(01 / 고미코퍼레이션 본사 (KR)', '02 / 고미퍼레이션 해외 지사', '03 / 고미코퍼레이션 자회사', '08 / 제조사', '16 / 공급사', '24 / 유통사', '40 / 물류 협력사', '50 / 마케팅 협력사', '99 / 기타 협력사) */
  groupId?: InputMaybe<CompanyGroupEnum>;
  /** 패스워드 */
  password: Scalars['String'];
};

export type DtoRequestEditImportLicensingInput = {
  /** 수입 허가 시트 ID */
  id: Scalars['ID'];
  update: RequestEditImportLicensingInputType;
};

export type DtoRequestTaxBillInput = {
  /** 수입 허가 시트 id */
  id: Scalars['ID'];
  update: RequestTaxBillInputType;
};

export type DtoReturnB2bOrder = {
  __typename?: 'DtoReturnB2bOrder';
  /** 구매자 입금 확인 파일 정보 */
  buyerDepositConfirmationFile?: Maybe<B2bOrderFileType>;
  /** 거래 완료일 */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 도착 예정일 */
  estimatedArrivalDate?: Maybe<Scalars['DateTime']>;
  /** 주문 ID */
  id: Scalars['ID'];
  /** 비활성 여부 */
  isDisable: Scalars['Boolean'];
  /** 주문 확정서 파일 정보 */
  orderConfirmDocumentFile?: Maybe<B2bOrderFileType>;
  /** 파트너 연결 정보 */
  orderContract: B2bOrderContractType;
  /** 주문 상세 */
  orderDetails: Array<B2bOrderDetailType>;
  /** 발주서 파일 정보 */
  orderDocumentFile?: Maybe<B2bOrderFileType>;
  /** 배송지 주소 */
  receiverAddress: Scalars['String'];
  /** 수령인 연락처 */
  receiverContactInformation: Scalars['String'];
  /** 수령인 이름 */
  receiverName: Scalars['String'];
  /** 판매자 입금 확인 파일 정보 */
  sellerDepositConfirmationFile?: Maybe<B2bOrderFileType>;
  /** 주문 상태 - ORDER_CREATE: 주문을 최초 생성, ORDER_REQUEST_CONFIRM: 주문 확인 요청, PENDING_DEPOSIT: 입금 대기, DONE: 완료 */
  status: B2bOrderStatusEnum;
  /** 총 주문 수량 */
  totalOrderQuantity: Scalars['Int'];
  /** 총 주문 금액 */
  totalPrice: Scalars['Float'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnB2bOrderContract = {
  __typename?: 'DtoReturnB2bOrderContract';
  /** 구매 회사 */
  buyerCompany: ReturnB2bOrderContractCompanyType;
  /** 구매 담당자 */
  buyerManager: ReturnB2bOrderContractUserType;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 고미 담당자 */
  gomiManager: ReturnB2bOrderContractUserType;
  /** 발주 - 파트너 연결 ID */
  id: Scalars['ID'];
  /** 연결 해지 여부 */
  isDisconnect: Scalars['Boolean'];
  /** 구매자 배송 주소 */
  receiverAddress: Scalars['String'];
  /** 구매자 연락처 */
  receiverContactInformation: Scalars['String'];
  /** 구매자 수령인 이름 */
  receiverName: Scalars['String'];
  /** 판매 회사 */
  sellerCompany: ReturnB2bOrderContractCompanyType;
  /** 판매 담당자 */
  sellerManager: ReturnB2bOrderContractUserType;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnB2bOrderContractConfig = {
  __typename?: 'DtoReturnB2bOrderContractConfig';
  /** 발주 파트너 연결 ID */
  b2bOrderContractId: Scalars['ID'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 거래구성 ID */
  id: Scalars['ID'];
  /** 상품 */
  product: B2bOrderContractConfigProductType;
  /** 매입단가 */
  purchaseUnitPrice: Scalars['Float'];
  /** 소비자가(MSRP) */
  retailPrice: Scalars['Int'];
  /** 공급가 */
  salesPrice: Scalars['Float'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnB2bOrderContractConfigList = {
  __typename?: 'DtoReturnB2bOrderContractConfigList';
  nodes: Array<DtoReturnB2bOrderContractConfig>;
  /** 총 수량 */
  total: Scalars['Int'];
};

export type DtoReturnB2bOrderContractList = {
  __typename?: 'DtoReturnB2bOrderContractList';
  /** 파트너 연결 정보 */
  nodes: Array<DtoReturnB2bOrderContract>;
  /** 총 수량 */
  total: Scalars['Int'];
};

export type DtoReturnB2bOrderList = {
  __typename?: 'DtoReturnB2bOrderList';
  /** 주문 정보 */
  nodes: Array<DtoReturnB2bOrder>;
  /** 총 수량 */
  total: Scalars['Int'];
};

export type DtoReturnB2bOrderSummary = {
  __typename?: 'DtoReturnB2bOrderSummary';
  /** 비활성화 수 */
  disableCount: Scalars['Int'];
  /** 완료 수 */
  doneCount: Scalars['Int'];
  /** 진행중 수 */
  inProgressCount: Scalars['Int'];
  /** 총 금액 */
  totalPrice: Scalars['Float'];
  /** 총 주문 수 */
  totalQuantity: Scalars['Int'];
};

export type DtoReturnBasicCriteriaConsignment = {
  __typename?: 'DtoReturnBasicCriteriaConsignment';
  /** 채널 아이디 */
  channelId: Scalars['ID'];
  /** 채널명 */
  channelName: Scalars['String'];
  /** 수수료율 */
  feeRate: Scalars['Int'];
  /** 기준 아이디 */
  id: Scalars['ID'];
};

/** 기본 기준 판매분 사입 응답 타입 */
export type DtoReturnBasicCriteriaPurchaseOfSales = {
  __typename?: 'DtoReturnBasicCriteriaPurchaseOfSales';
  /** 기준 금액 */
  criteriaPrice: Scalars['Int'];
};

export type DtoReturnBrand = {
  __typename?: 'DtoReturnBrand';
  /** 브랜드 약어 */
  brandAbbr?: Maybe<Scalars['String']>;
  /** 브랜드 코드 */
  brandCode?: Maybe<Scalars['String']>;
  /** 브랜드 이미지 */
  brandImage?: Maybe<ReturnBrandFileInfoType>;
  /** 브랜드명 (국문) */
  brandKrName: Scalars['String'];
  /** 브랜드명 (영문) */
  brandName: Scalars['String'];
  /** 파트너 */
  company: ReturnBrandCompanyType;
  /** 국가 id 리스트 */
  countryIds: Array<CountryEnum>;
  /** 브랜드 id */
  id: Scalars['ID'];
  /** 브랜드 매니저 리스트 */
  managers: Array<ReturnBrandManagerType>;
  /** 업데이트 일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnBrandList = {
  __typename?: 'DtoReturnBrandList';
  /** 브랜드 리스트 */
  nodes: Array<DtoReturnBrand>;
  /** 전체 브랜드 수 */
  total: Scalars['Float'];
};

/** 브랜드 정보 목록 담당자 필터 목록 */
export type DtoReturnBrandManager = {
  __typename?: 'DtoReturnBrandManager';
  department?: Maybe<Scalars['String']>;
  gomiUser: Array<GomiUserEntity>;
};

export type DtoReturnBrandRequest = {
  __typename?: 'DtoReturnBrandRequest';
  /** 검수한 유저 id */
  approvalUserId?: Maybe<Scalars['ID']>;
  /** 브랜드명 약어 */
  brandAbbr?: Maybe<Scalars['String']>;
  /** 브랜드 코드 */
  brandCode?: Maybe<Scalars['String']>;
  /** 브랜드 id */
  brandId?: Maybe<Scalars['ID']>;
  /** 브랜드 요청 이미지 */
  brandImage?: Maybe<ReturnBrandRequestFileInfoType>;
  /** 브랜드명 (국문) */
  brandKrName: Scalars['String'];
  /** 브랜드명 (영문) */
  brandName: Scalars['String'];
  /** 파트너 */
  company: ReturnBrandRequestCompanyType;
  /** 파트너 id */
  companyId: Scalars['ID'];
  /** 국가 id 리스트 */
  countryIds: Array<CountryEnum>;
  /** 브랜드 요청 id */
  id: Scalars['ID'];
  /** 브랜드 요청 매니저 리스트 */
  managers: Array<ReturnBrandRequestManagerType>;
  /** 반려 사유 */
  rejectReason?: Maybe<Scalars['String']>;
  /** 요청한 유저 id */
  requestUserId?: Maybe<Scalars['ID']>;
  /** 요청 상태 */
  status: CompanyRequestStatusEnum;
  /** 업데이트 일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnBrandRequestList = {
  __typename?: 'DtoReturnBrandRequestList';
  /** 브랜드 요청 리스트 */
  nodes: Array<DtoReturnBrandRequest>;
  /** 전체 브랜드 요청 수 */
  total: Scalars['Float'];
};

/** 브랜드 요청 목록의 담당자 필터 목록 */
export type DtoReturnBrandRequestManager = {
  __typename?: 'DtoReturnBrandRequestManager';
  department?: Maybe<Scalars['String']>;
  gomiUser: Array<GomiUserEntity>;
};

/** 채널 DTO */
export type DtoReturnChannel = {
  __typename?: 'DtoReturnChannel';
  /** 채널명 */
  channelName: Scalars['String'];
  /** 채널 아이디 */
  id: Scalars['ID'];
};

export type DtoReturnChannelsForReport = {
  __typename?: 'DtoReturnChannelsForReport';
  /** 채널명 */
  channelName: OrderChannelEnum;
  /** 디스플레이 채널명 */
  displayName: Scalars['String'];
};

export type DtoReturnCompany = {
  __typename?: 'DtoReturnCompany';
  /** 계좌번호, - 허용 */
  accountNumber?: Maybe<Scalars['String']>;
  /** 주소 */
  address?: Maybe<Scalars['String']>;
  /** 통장 사본 */
  bankImage?: Maybe<ReturnCompanyFileInfoType>;
  /** 은행이름 */
  bankName?: Maybe<Scalars['String']>;
  /** 대표자명 */
  ceoName?: Maybe<Scalars['String']>;
  /** 파트너 코드(코어개발팀 발급) */
  companyCode?: Maybe<Scalars['String']>;
  /** 회사 이미지 */
  companyImage?: Maybe<ReturnCompanyFileInfoType>;
  /** 회사 국문명 */
  companyKrName: Scalars['String'];
  /** 담당자 */
  companyManagers?: Maybe<Array<ReturnCompanyManagerType>>;
  /** 회사명, 기본 영어 */
  companyName: Scalars['String'];
  /** 계약서 */
  contract?: Maybe<ReturnCompanyFileInfoType>;
  /** 법인등록번호 */
  corporateRegistrationNumber?: Maybe<Scalars['String']>;
  /** 파트너 국가 */
  countryId: CountryEnum;
  /** 파트너가 진출한 국가 정보 */
  countryOfEntry?: Maybe<Array<DtoReturnCountry>>;
  /** 전자세금계산서 이메일 */
  digitalTaxInvoiceEmail?: Maybe<Scalars['String']>;
  /** 파트너 그룹 */
  group?: Maybe<ReturnCompanyGroupType>;
  /** ID */
  id: Scalars['ID'];
  /** 사업자 */
  registrationImage?: Maybe<ReturnCompanyFileInfoType>;
  /** 사업자등록번호 */
  registrationNumber: Scalars['String'];
  /** 위탁: CONSIGNMENT,0  사입: PURCHASE,1 판매분 사입: PURCHASE,2 */
  salesType?: Maybe<SalesTypeEnum>;
  /** 업데이트 일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnCompanyGroup = {
  __typename?: 'DtoReturnCompanyGroup';
  /** ID */
  id: CompanyGroupEnum;
  /** 파트너 그룹 명 */
  name?: Maybe<Scalars['String']>;
};

export type DtoReturnCompanyList = {
  __typename?: 'DtoReturnCompanyList';
  /** 파트너 리스트 */
  nodes: Array<DtoReturnCompany>;
  /** 총 파트너 수 */
  total: Scalars['Int'];
};

export type DtoReturnCompanyManager = {
  __typename?: 'DtoReturnCompanyManager';
  departmentName?: Maybe<Scalars['String']>;
  gomiUser: Array<ReturnCompanyManagerType>;
};

export type DtoReturnCompanyRequest = {
  __typename?: 'DtoReturnCompanyRequest';
  /** 계좌번호, - 허용 */
  accountNumber?: Maybe<Scalars['String']>;
  /** 주소 */
  address?: Maybe<Scalars['String']>;
  /** 검수한 유저 */
  approvalUser?: Maybe<GomiUserType>;
  /** 통장사본 이미지 ID */
  bankImage?: Maybe<FileInfoType>;
  /** 은행이름 */
  bankName?: Maybe<Scalars['String']>;
  /** 대표자명 */
  ceoName?: Maybe<Scalars['String']>;
  /** 회사 코드 (코어개발팀 발급) */
  companyCode?: Maybe<Scalars['String']>;
  /** 파트너 id */
  companyId?: Maybe<Scalars['ID']>;
  /** 회사 이미지 ID */
  companyImage?: Maybe<FileInfoType>;
  /** 회사명 국문 */
  companyKrName: Scalars['String'];
  /** 파트너 관리자(gomiManagerRels) */
  companyManagers: Array<ReturnCompanyRequestManagerType>;
  /** 회사명, 기본 영어 */
  companyName: Scalars['String'];
  /** 계약서 ID */
  contract?: Maybe<FileInfoType>;
  /** 법인등록번호 */
  corporateRegistrationNumber?: Maybe<Scalars['String']>;
  /** 파트너 id */
  countryId?: Maybe<CountryEnum>;
  /** 전자세금계산서 이메일 */
  digitalTaxInvoiceEmail?: Maybe<Scalars['String']>;
  /** 회사 그룹 */
  group?: Maybe<CompanyGroupType>;
  /** 아이디 */
  id: Scalars['ID'];
  /** 사업자등록 이미지 ID */
  registrationImage?: Maybe<FileInfoType>;
  /** 사업자 등록번호, - 사용 */
  registrationNumber?: Maybe<Scalars['String']>;
  /** 반려 사유 */
  rejectReason?: Maybe<Scalars['String']>;
  /** 요청한 유저 */
  requestUser?: Maybe<GomiUserType>;
  /** 인입경로 */
  routeType?: Maybe<InformationManagementRouteTypeEnum>;
  /** 위탁: CONSIGNMENT,0  사입: PURCHASE,1 판매분 사입: PURCHASE,2 */
  salesType?: Maybe<SalesTypeEnum>;
  /** 요청 상태 - REQUEST: 검수요청, TEMPORARY: 임시저장, DONE: 등록완료, REJECTION: 반려, REQUEST_COMPLETE: 검수요청 히스토리, REJECTION_COMPLETE: 반려 히스토리 */
  status: CompanyRequestStatusEnum;
  /** 업데이트 일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnCompanyRequestList = {
  __typename?: 'DtoReturnCompanyRequestList';
  /** 파트너 요청 리스트 */
  nodes: Array<DtoReturnCompanyRequest>;
  /** 전체 파트너 요청 수 */
  total: Scalars['Float'];
};

export type DtoReturnCompanyRequestManager = {
  __typename?: 'DtoReturnCompanyRequestManager';
  department?: Maybe<Scalars['String']>;
  gomiUser: Array<ReturnCompanyRequestManagerGomiUserType>;
};

export type DtoReturnCountry = {
  __typename?: 'DtoReturnCountry';
  /** 국가 아이디 */
  countryId: CountryEnum;
  /** 국가 이름 */
  countryName: Scalars['String'];
};

export type DtoReturnDepartment = {
  __typename?: 'DtoReturnDepartment';
  /** 부서명 */
  departmentName: Scalars['String'];
  /** ERP 부서코드 */
  erpDepartmentCode?: Maybe<Scalars['String']>;
  /** 고미 어드민 */
  gomiUsers?: Maybe<Array<GomiUserEntity>>;
  /** id */
  id: Scalars['ID'];
};

export type DtoReturnExport = {
  __typename?: 'DtoReturnExport';
  bankDetails?: Maybe<Scalars['String']>;
  countryOfFinalDestination?: Maybe<Scalars['String']>;
  countryOfOriginalOfGoods?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exportAgentId?: Maybe<Scalars['ID']>;
  exportCompanyId?: Maybe<Scalars['ID']>;
  exportDate?: Maybe<Scalars['String']>;
  exportImporterId?: Maybe<Scalars['ID']>;
  exportNo?: Maybe<Scalars['String']>;
  /** 수출자 */
  exporter?: Maybe<DtoExporter>;
  gomiManagerId?: Maybe<Scalars['ID']>;
  /** id */
  id: Scalars['ID'];
  incoterms?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['ID']>;
  methodOfDispatch?: Maybe<Scalars['String']>;
  methodOfPayment?: Maybe<Scalars['String']>;
  portOfArrival?: Maybe<Scalars['String']>;
  portOfDeparture?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  status: ExportStatusEnum;
  typeOfShipment?: Maybe<Scalars['String']>;
  vesselAircraft?: Maybe<Scalars['String']>;
  voyageFlightNo?: Maybe<Scalars['String']>;
};

export type DtoReturnFileInfo = {
  __typename?: 'DtoReturnFileInfo';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 파일 확장자 */
  fileExtension: Scalars['String'];
  /** 파일 키 */
  fileKey: Scalars['String'];
  /** 파일 명 */
  fileName: Scalars['String'];
  /** 파일 아이디 */
  id: Scalars['ID'];
  /** 파일 링크 */
  link: Scalars['String'];
  /** 파일 타입 */
  type: FilePathEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnGomiUser = {
  __typename?: 'DtoReturnGomiUser';
  /** 권한 정보 */
  authorizations: Array<AuthorizationEnum>;
  /**
   * 국가 정보
   * @deprecated languageId로 사용해주세요.
   */
  countryId: CountryEnum;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 부서 */
  department?: Maybe<DepartmentType>;
  /** 이메일 */
  email: Scalars['String'];
  /** ERP 코드 (사원번호) */
  erpUserCode?: Maybe<Scalars['String']>;
  /** 고미 유저 아이디 */
  id: Scalars['ID'];
  /** 언어 */
  languageId?: Maybe<CountryEnum>;
  /** 전화번호 */
  phoneNumber?: Maybe<Scalars['String']>;
  /** 역할 */
  role: RoleEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 고미 유저 이름 */
  userName: Scalars['String'];
};

export type DtoReturnHomeBrand = {
  __typename?: 'DtoReturnHomeBrand';
  /** 브랜드 명 */
  brandKrName: Scalars['String'];
  /** 총 수 */
  count: Scalars['Int'];
};

export type DtoReturnHomeCategory = {
  __typename?: 'DtoReturnHomeCategory';
  /** 카테고리 명 */
  categoryName: Scalars['String'];
  /** 총 수 */
  count: Scalars['Int'];
};

export type DtoReturnHomeChannel = {
  __typename?: 'DtoReturnHomeChannel';
  /** 채널 명 */
  channel: Scalars['String'];
  /** 총 비율 */
  ratio: Scalars['Float'];
};

export type DtoReturnHomeProduct = {
  __typename?: 'DtoReturnHomeProduct';
  /** 총 수 */
  count: Scalars['Int'];
  /** 상품 명 */
  productName: Scalars['String'];
};

export type DtoReturnHomeSummary = {
  __typename?: 'DtoReturnHomeSummary';
  /** 주문 정보 */
  orders: ReturnHomeSummaryOrderType;
  /** 총 취소 주문 수 */
  salesPrice: Scalars['Float'];
};

export type DtoReturnImportLicensing = {
  __typename?: 'DtoReturnImportLicensing';
  /** 파트너 */
  company: ReturnImportLicensingItemCompanyType;
  /** 완료일 */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** 국가 id */
  countryId: CountryEnum;
  /** 등록일 */
  createdAt: Scalars['DateTime'];
  /** 실물 서류 배송 상태, 기본값: READY, 수정 요청: REQUEST_EDIT, 검수 완료: DONE, 확인 요청: REQUEST_CONFIRM */
  documentDeliveryStatus: ImportLicensingDocumentStatusEnum;
  /** 상품별 서류 진행 상태, 기본값: READY, 수정 요청: REQUEST_EDIT, 검수 완료: DONE, 확인 요청: REQUEST_CONFIRM */
  documentPerProductStatus: ImportLicensingDocumentStatusEnum;
  /** 수입 허가 등록 상태, 기본값: READY, 수정 요청: REQUEST_EDIT, 검수 완료: DONE, 확인 요청: REQUEST_CONFIRM */
  doneStatus: ImportLicensingDocumentStatusEnum;
  /** 견적서 상태, 기본값: READY, 수정 요청: REQUEST_EDIT, 검수 완료: DONE, 확인 요청: REQUEST_CONFIRM */
  estimateStatus: ImportLicensingDocumentStatusEnum;
  /** 추가 서류 진행 상태, 기본값: READY, 수정 요청: REQUEST_EDIT, 검수 완료: DONE, 확인 요청: REQUEST_CONFIRM */
  extraDocumentStatus: ImportLicensingDocumentStatusEnum;
  /** 수입 허가 시트 id */
  id: Scalars['ID'];
  /** 수입 허가 명 */
  importLicenseName: Scalars['String'];
  /** 세금계산서 발행 요청 여부 */
  isRequestTaxInvoice: Scalars['Boolean'];
  /** 담당자 아이디 */
  managerId?: Maybe<Scalars['ID']>;
  /** 담당자 명 */
  managerName?: Maybe<Scalars['String']>;
  /** 공증 파일 id */
  notarizationFileId?: Maybe<Scalars['ID']>;
  /** 공증 파일 링크 */
  notarizationFileLink?: Maybe<Scalars['String']>;
  /** 파트너 담당자 이메일 */
  partnerContactEmail: Scalars['String'];
  /** 상품 등록 상태, 기본값: READY, 수정 요청: REQUEST_EDIT, 검수 완료: DONE, 확인 요청: REQUEST_CONFIRM */
  productCreateStatus: ImportLicensingDocumentStatusEnum;
  /** 소요 기간 */
  requiredPeriod: Scalars['Int'];
  /** 시트 상태 */
  step: ImportLicensingSheetStepEnum;
};

export type DtoReturnImportLicensingComment = {
  __typename?: 'DtoReturnImportLicensingComment';
  /** comment */
  comment: Scalars['String'];
  /** 작성일 */
  createdAt: Scalars['DateTime'];
  /** 수입 허가 comment id */
  id: Scalars['ID'];
  /** 작성자 */
  writer: ReturnImportLicensingCommentWriterType;
};

export type DtoReturnImportLicensingCommentList = {
  __typename?: 'DtoReturnImportLicensingCommentList';
  nodes: Array<DtoReturnImportLicensingComment>;
  total: Scalars['Int'];
};

export type DtoReturnImportLicensingContent = {
  __typename?: 'DtoReturnImportLicensingContent';
  /** 브랜드 국문명 */
  brandKrName?: Maybe<Scalars['String']>;
  /** 브랜드 영문명 */
  brandName?: Maybe<Scalars['String']>;
  /** 완료일 */
  completeAt?: Maybe<Scalars['DateTime']>;
  /** 수입 허가 컨텐츠명 국문 */
  contentKrName?: Maybe<Scalars['String']>;
  /** 수입 허가 컨텐츠명 */
  contentName: Scalars['String'];
  /** 등록일 */
  createdAt: Scalars['DateTime'];
  /** 희망 판매가 */
  desiredSalesPrice: Scalars['Int'];
  /** 업로드 서류 목록 */
  documentList?: Maybe<Array<DtoReturnImportLicensingDocument>>;
  /** 수입 허가 컨텐츠 id */
  id: Scalars['ID'];
  /** 제조사 국문명 */
  manufacturerKrName?: Maybe<Scalars['String']>;
  /** 제조사 영문명 */
  manufacturerName?: Maybe<Scalars['String']>;
  /** 실제 판매가 */
  salesPrice: Scalars['Int'];
};

export type DtoReturnImportLicensingContentList = {
  __typename?: 'DtoReturnImportLicensingContentList';
  nodes: Array<DtoReturnImportLicensingContent>;
  total: Scalars['Int'];
};

export type DtoReturnImportLicensingDocument = {
  __typename?: 'DtoReturnImportLicensingDocument';
  /** 완료일 */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** 등록일 */
  createdAt: Scalars['DateTime'];
  /** 서류 그룹 목록 */
  documentGroupList: Array<ReturnImportLicensingContentDocumentGroupType>;
  /** 수입 허가 서류 명 */
  documentName: Scalars['String'];
  /** 예시보기 */
  exampleFileLink?: Maybe<Scalars['String']>;
  /** 양식보기 */
  formFileLink?: Maybe<Scalars['String']>;
  /** 수입 허가 서류 id */
  id: Scalars['ID'];
  /** 상태 */
  status: ImportLicensingDocumentStatusEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 업로드 시 주의사항 */
  warnings: Array<Scalars['String']>;
};

export type DtoReturnImportLicensingEstimate = {
  __typename?: 'DtoReturnImportLicensingEstimate';
  /** 수량 */
  count: Scalars['Int'];
  /** 서류 */
  document: Scalars['String'];
  /** 수입 허가 견적 id */
  id: Scalars['ID'];
  /** 항목 */
  itemName: Scalars['String'];
  /** 단가 */
  price: Scalars['Int'];
  /** 단위 */
  unit: Scalars['String'];
  /** VAT */
  vat: Scalars['Int'];
};

export type DtoReturnImportLicensingEstimateList = {
  __typename?: 'DtoReturnImportLicensingEstimateList';
  /** 수입 허가 견적 목록 */
  nodes?: Maybe<Array<DtoReturnImportLicensingEstimate>>;
  /** 총 수량 */
  total: Scalars['Int'];
};

export type DtoReturnImportLicensingList = {
  __typename?: 'DtoReturnImportLicensingList';
  nodes: Array<DtoReturnImportLicensing>;
  total: Scalars['Int'];
};

export type DtoReturnImportLicensingRequireOriginalDocumentList = {
  __typename?: 'DtoReturnImportLicensingRequireOriginalDocumentList';
  /** 상품별 서류 목록 */
  documentsByProduct: Array<ContentType>;
  /** 공통 서류 목록 */
  extraDocuments: Array<DocumentType>;
};

export type DtoReturnImportLicensingTemplate = {
  __typename?: 'DtoReturnImportLicensingTemplate';
  /** 카테고리 명 */
  categoryName: Scalars['String'];
  /** 카테고리 명 */
  countryId: CountryEnum;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 템플릿 ID */
  id: Scalars['ID'];
  /** 수정자 */
  modifierName: Scalars['String'];
  /** 소요 기간 */
  requiredPeriod: Scalars['Int'];
  /** 템플릿 내용 JSON 문자열 */
  templateContent: Scalars['String'];
  /** 템플릿명 */
  templateName: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnImportLicensingTemplateList = {
  __typename?: 'DtoReturnImportLicensingTemplateList';
  nodes: Array<DtoReturnImportLicensingTemplate>;
  total: Scalars['Int'];
};

export type DtoReturnLogin = {
  __typename?: 'DtoReturnLogin';
  /** 인증 토큰 */
  accessToken: Scalars['String'];
  /** 권한 목록 */
  authorizationList?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 파트너사 id */
  companyId?: Maybe<Scalars['String']>;
  /** 이메일 */
  email: Scalars['String'];
  /** 유저아이디 */
  id: Scalars['Int'];
  /** 권한종류 */
  role?: Maybe<RoleEnum>;
  /** 유저이름 */
  userName: Scalars['String'];
};

export type DtoReturnOrderItem = {
  __typename?: 'DtoReturnOrderItem';
  /** 브랜드 */
  brand?: Maybe<OrderItemBrandType>;
  /** 브랜드 id */
  brandId?: Maybe<Scalars['ID']>;
  /** 채널 */
  channel: OrderChannelEnum;
  /** 상품 수 */
  count: Scalars['Int'];
  /** 브랜드 id */
  countryId?: Maybe<CountryEnum>;
  /** 전시용 채널 */
  displayChannel: Scalars['String'];
  /** GSPC */
  gspc?: Maybe<Scalars['String']>;
  /** 주문 id */
  orderId: Scalars['String'];
  /** 주문 상세 고유키 */
  orderItemId?: Maybe<Scalars['ID']>;
  /** 주문 번호 */
  orderNumber: Scalars['String'];
  /** 주문상태 */
  orderStatus: SettlementOrderStatus;
  /** 주문일 */
  orderedAt: Scalars['DateTime'];
  /** 소비자가 */
  originalPrice: Scalars['Int'];
  /** 결제방식 */
  payMethod?: Maybe<Scalars['String']>;
  /** 결제상태 */
  paymentStatus: OrderPaymentStatusEnum;
  /** 금액 */
  price: Scalars['Int'];
  /** 상품명 */
  productName?: Maybe<Scalars['String']>;
  /** sales_code */
  salesCode: Scalars['String'];
  /** 정산 연월 */
  settlementYearMonth?: Maybe<Scalars['String']>;
  /** 배송상태 */
  shippingStatus?: Maybe<OrderShippingStatusEnum>;
  /** 총 판매금액 */
  totalPrice: Scalars['Int'];
  /** 바우처 금액 */
  voucher: Scalars['Int'];
};

export type DtoReturnOrderPaymentStatusCount = {
  __typename?: 'DtoReturnOrderPaymentStatusCount';
  /** 미결제 */
  not_paid: Scalars['Int'];
  /** 결제완료 */
  paid: Scalars['Int'];
  /** 환불 */
  refund: Scalars['Int'];
};

export type DtoReturnOrderSalesAmountByProduct = {
  __typename?: 'DtoReturnOrderSalesAmountByProduct';
  /** 상품별 판매 금액 */
  node: Array<ProductAmountType>;
};

export type DtoReturnOrderSalesShippingStatusCount = {
  __typename?: 'DtoReturnOrderSalesShippingStatusCount';
  /** 배송 완료 */
  delivered: Scalars['Int'];
  /** 배송 중 */
  delivering: Scalars['Int'];
  /** 배송 준비 */
  readytopick: Scalars['Int'];
  /** 반송 */
  return: Scalars['Int'];
};

export type DtoReturnOrderSalesStatus = {
  __typename?: 'DtoReturnOrderSalesStatus';
  /** 취소 주문 수량 */
  cancelOrderCount: Scalars['Int'];
  /** 확인 주문 수량 */
  confirmOrderCount: Scalars['Int'];
  /** 날짜 */
  date: Scalars['DateTime'];
  /** 총 판매금액 */
  totalPrice: Scalars['Int'];
};

export type DtoReturnOrderSalesStatusByDate = {
  __typename?: 'DtoReturnOrderSalesStatusByDate';
  /** 날짜 별 주문 데이터 */
  node: Array<SalesStatusByDate>;
};

export type DtoReturnOrderSalesStatusByProduct = {
  __typename?: 'DtoReturnOrderSalesStatusByProduct';
  /** 상품별 판매금액 데이터 */
  node: Array<SalesStatusByProductType>;
};

export type DtoReturnOrderSalesStatusCount = {
  __typename?: 'DtoReturnOrderSalesStatusCount';
  /** 취소 */
  cancel: Scalars['Int'];
  /** 주문 완료 */
  ok: Scalars['Int'];
};

export type DtoReturnPaginatedGomiUser = {
  __typename?: 'DtoReturnPaginatedGomiUser';
  /** 고미 담당자 */
  nodes: Array<DtoReturnGomiUser>;
  /** 전체 고미 담당자 수 */
  total: Scalars['Float'];
};

export type DtoReturnPaginatedOrderItem = {
  __typename?: 'DtoReturnPaginatedOrderItem';
  /** 주문 리스트 */
  nodes: Array<DtoReturnOrderItem>;
  /** 주문 전체 요약 */
  summary: OrderItemSummaryDto;
  /** 총 주문 수 */
  total: Scalars['Int'];
};

export type DtoReturnPaginatedUser = {
  __typename?: 'DtoReturnPaginatedUser';
  /** 파트너 유저 */
  nodes: Array<DtoReturnUser>;
  /** 전체 파트너 수 */
  total: Scalars['Float'];
};

export type DtoReturnProductCategoryList = {
  __typename?: 'DtoReturnProductCategoryList';
  nodes: Array<ReturnProductCategoryType>;
  total: Scalars['Float'];
};

/** 상품 그룹 DTO */
export type DtoReturnProductGroup = {
  __typename?: 'DtoReturnProductGroup';
  brand?: Maybe<ReturnBrandType>;
  category?: Maybe<Scalars['String']>;
  company?: Maybe<ReturnCompanyType>;
  /** 상품 그룹 ID */
  id: Scalars['ID'];
  productGroupKrName?: Maybe<Scalars['String']>;
  productGroupName: Scalars['String'];
  productOptionGroups: Array<ProductOptionGroupType>;
};

export type DtoReturnProductList = {
  __typename?: 'DtoReturnProductList';
  nodes: Array<DtoReturnProductV2>;
  total: Scalars['Float'];
};

/** 상품 옵션 DTO */
export type DtoReturnProductOption = {
  __typename?: 'DtoReturnProductOption';
  /** 상품 옵션 ID */
  id: Scalars['ID'];
  /** 상품 옵션 그룹 ID */
  productOptionGroupId: Scalars['ID'];
  /** 옵션 명 */
  productOptionName: Scalars['String'];
};

/** 상품 DTO */
export type DtoReturnProductRequest = {
  __typename?: 'DtoReturnProductRequest';
  /** 승인 유저 id */
  approvalUserId?: Maybe<Scalars['Int']>;
  /** 바코드 */
  barcode?: Maybe<Scalars['String']>;
  /** 태국 판매가(할인) A */
  discountSalePriceThA?: Maybe<Scalars['Int']>;
  /** 태국 판매가(할인) B */
  discountSalePriceThB?: Maybe<Scalars['Int']>;
  /** 태국 판매가(할인) S */
  discountSalePriceThS?: Maybe<Scalars['Int']>;
  /** 베트남 판매가(할인) A */
  discountSalePriceVnA?: Maybe<Scalars['Int']>;
  /** 베트남 판매가(할인) B */
  discountSalePriceVnB?: Maybe<Scalars['Int']>;
  /** 베트남 판매가(할인) S */
  discountSalePriceVnS?: Maybe<Scalars['Int']>;
  /** 신규 GSPC(2022.ver) */
  gspc?: Maybe<Scalars['String']>;
  /** HS 코드 */
  hsCode?: Maybe<Scalars['String']>;
  /** 상품 식별키 */
  id: Scalars['ID'];
  /** 패키지 수량 */
  innerBoxCount?: Maybe<Scalars['Int']>;
  /** 외박스 수량 */
  outerBoxCount?: Maybe<Scalars['Int']>;
  /** 상품 그룹 */
  productGroup: ReturnProductGroupType;
  /** 상품 그룹 id */
  productGroupId: Scalars['ID'];
  /** 상품 ID */
  productId?: Maybe<Scalars['Int']>;
  /** 상품명 국문 */
  productKrName?: Maybe<Scalars['String']>;
  /** 상품명 영문 */
  productName?: Maybe<Scalars['String']>;
  /** 상품 옵션 */
  productOptions?: Maybe<Array<ReturnProductOptionType>>;
  /** 매입 단가 */
  purchaseUnitPrice?: Maybe<Scalars['Int']>;
  /** 태국 판매가(상시) */
  regularSalePriceTh?: Maybe<Scalars['Int']>;
  /** 베트남 판매가(상시) */
  regularSalePriceVn?: Maybe<Scalars['Int']>;
  /** 반려 사유 */
  rejectReason?: Maybe<Scalars['String']>;
  /** 요청 유저 id */
  requestUserId?: Maybe<Scalars['Int']>;
  /** 소비자가(한국) */
  retailPriceKr?: Maybe<Scalars['Int']>;
  /** 소비자가(태국) */
  retailPriceTh?: Maybe<Scalars['Int']>;
  /** 소비자가(베트남) */
  retailPriceVn?: Maybe<Scalars['Int']>;
  /** 요청 상태 - REQUEST: 검수 요청, TEMPORARY: 임시 저장, DONE: 등록 완료, REJECTION: 반려, REJECTION_COMPLETE: 반려 완료, REQUEST_COMPLETE: 검수 요청 완료 */
  status: CompanyRequestStatusEnum;
  /** 공급사 상품 코드 */
  supplierProductCode?: Maybe<Scalars['String']>;
  /** BASIC: 기본 정보, LOGISTICS: 물류 정보, PRICE: 가격 정보, DETAIL: 상세 정보 */
  type: ProductRequestTypeEnum;
  /** 업데이트 일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnProductRequestList = {
  __typename?: 'DtoReturnProductRequestList';
  nodes: Array<DtoReturnProductRequest>;
  total: Scalars['Float'];
};

/** 상품 DTO */
export type DtoReturnProductV2 = {
  __typename?: 'DtoReturnProductV2';
  barcode?: Maybe<Scalars['String']>;
  discountSalePriceThA?: Maybe<Scalars['Int']>;
  discountSalePriceThB?: Maybe<Scalars['Int']>;
  discountSalePriceThS?: Maybe<Scalars['Int']>;
  discountSalePriceVnA?: Maybe<Scalars['Int']>;
  discountSalePriceVnB?: Maybe<Scalars['Int']>;
  discountSalePriceVnS?: Maybe<Scalars['Int']>;
  gspc: Scalars['String'];
  hsCode?: Maybe<Scalars['String']>;
  /** 상품 식별키 */
  id: Scalars['ID'];
  /** 패키지 수량 */
  innerBoxCount?: Maybe<Scalars['Int']>;
  /** 외박스 수량 */
  outerBoxCount?: Maybe<Scalars['Int']>;
  /** 상품 그룹 */
  productGroup: ReturnProductGroupType;
  productGroupId: Scalars['String'];
  productKeyword?: Maybe<Scalars['String']>;
  productKrName: Scalars['String'];
  productName: Scalars['String'];
  productOptions: Array<ReturnProductOptionType>;
  purchaseUnitPrice?: Maybe<Scalars['Int']>;
  regularSalePriceTh?: Maybe<Scalars['Int']>;
  regularSalePriceVn?: Maybe<Scalars['Int']>;
  retailPriceKr?: Maybe<Scalars['Int']>;
  retailPriceTh?: Maybe<Scalars['Int']>;
  retailPriceVn?: Maybe<Scalars['Int']>;
  supplierProductCode?: Maybe<Scalars['String']>;
  /** 업데이트 일 */
  updatedAt: Scalars['DateTime'];
};

export type DtoReturnRefreshToken = {
  __typename?: 'DtoReturnRefreshToken';
  /** 인증 토큰 */
  accessToken: Scalars['String'];
};

export type DtoReturnSettlementConsignmentPartner = {
  __typename?: 'DtoReturnSettlementConsignmentPartner';
  /** 파트너 코드 */
  companyCode?: Maybe<Scalars['String']>;
  /** 파트너 명 */
  companyName: Scalars['String'];
  /** 수수료 */
  fee: Scalars['Int'];
  /** 파트너 아이디 */
  id: Scalars['ID'];
  /** 사용 여부 상태 */
  isDisabled?: Maybe<Scalars['Boolean']>;
  /** 판매 수 */
  salesCount: Scalars['Int'];
  /** 매출 금액 */
  salesPrice: Scalars['Int'];
  /** 정산서 발송 상태 READY: 정산서 발송 전, SENT:  정산서 발송 완료 */
  sendStatus: SettlementPartnerSendStatusEnum;
  /** 상계 금액 */
  setoffPrice: Scalars['Int'];
  /** 정산 금액 */
  settlementPrice: Scalars['Int'];
  /** 정산 오류 상태 INVALID_FEE: 수수료 or 정산 기준 금액 오류, CLEAR: 정상 */
  status: SettlementPartnerStatusEnum;
};

export type DtoReturnSettlementConsignmentPartnerFeeSummary = {
  __typename?: 'DtoReturnSettlementConsignmentPartnerFeeSummary';
  /** 채널 */
  channels: Array<ReturnSettlementConsignmentPartnerFeeSummaryChannelType>;
  /** 총 수수료 */
  totalFee: Scalars['Int'];
};

export type DtoReturnSettlementConsignmentPartnerSalesPriceSummary = {
  __typename?: 'DtoReturnSettlementConsignmentPartnerSalesPriceSummary';
  /** 채널 */
  channels: Array<ReturnSettlementConsignmentPartnerSalesPriceSummaryChannelType>;
  /** 총 매출 금액 */
  totalSalesPrice: Scalars['Int'];
};

export type DtoReturnSettlementConsignmentPartnerSummaryDto = {
  __typename?: 'DtoReturnSettlementConsignmentPartnerSummaryDto';
  /** 파트너 명 */
  companyName: Scalars['String'];
  /** 수수료 */
  fee: Scalars['Int'];
  /** 판매 금액 */
  salesPrice: Scalars['Int'];
  /** 상계 금액 */
  setoffPrice: Scalars['Int'];
  /** 정산 금액 */
  settlementPrice: Scalars['Int'];
  /** 정산서 발송 여부 */
  settlementSendStatus: SettlementPartnerSendStatusEnum;
  /** 정산 월 */
  settlementYearMonth: Scalars['String'];
  /** 정산서 상태 */
  status: SettlementPartnerStatusEnum;
};

export type DtoReturnSettlementConsignmentProductOrders = {
  __typename?: 'DtoReturnSettlementConsignmentProductOrders';
  /** 채널명 */
  channelName: Scalars['String'];
  /** 국가기준 매출 금액 */
  countrySalesPrice: Scalars['Int'];
  /** 국가기준 판매 가격 */
  countrySinglePrice: Scalars['Int'];
  /** 주문번호 */
  orderNumber: Scalars['String'];
  /** 주문날짜 */
  orderedAt: Scalars['DateTime'];
  /** 판매 수량 */
  salesCount: Scalars['Int'];
};

export type DtoReturnSettlementCriteriaConsignment = {
  __typename?: 'DtoReturnSettlementCriteriaConsignment';
  /** 채널명 */
  channel: ReturnSettlementCriteriaConsignmentChannelType;
  /** 국가 id */
  countryId: CountryEnum;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 수수료 적용 종료 날짜 */
  endAt?: Maybe<Scalars['DateTime']>;
  /** 수수료율 */
  feeRate: Scalars['Int'];
  /** 식별키 */
  id: Scalars['ID'];
  /** 기본값 여부 0: 이벤트, 1: 기본 */
  isDefault: Scalars['Boolean'];
  /** 수정 가능: 0, 불가능: 1 */
  isDisabled: Scalars['Boolean'];
  /** 담당자명 */
  manager?: Maybe<ReturnSettlementCriteriaConsignmentManagerType>;
  /** 수정자 */
  modifier?: Maybe<ReturnSettlementCriteriaConsignmentModifierType>;
  /** sales_code */
  salesCode: ReturnSettlementCriteriaConsignmentSalesCodeType;
  /** 수수료 적용 시작 날짜 */
  startAt: Scalars['DateTime'];
};

export type DtoReturnSettlementCriteriaPartnerProductList = {
  __typename?: 'DtoReturnSettlementCriteriaPartnerProductList';
  /** 유효한 채널 */
  channels: Array<ReturnSettlementConsignmentPartnerProductListChannelType>;
  /** 상품 리스트 */
  nodes: Array<ReturnSettlementConsignmentPartnerProductListItemType>;
  /** 총 합계 판매 금액(판매 국가 기준) */
  totalCountrySalesPrice: Scalars['Int'];
  /** 총 합계 수량 */
  totalSalesCount: Scalars['Int'];
  /** 총 합계 판매 금액 */
  totalSalesPrice: Scalars['Int'];
};

export type DtoReturnSettlementCriteriaPurchaseOfSales = {
  __typename?: 'DtoReturnSettlementCriteriaPurchaseOfSales';
  /** 국가 id */
  countryId: CountryEnum;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 기준 금액 */
  criteriaPrice: Scalars['Int'];
  /** 판매분사입 정산기준가 id */
  id: Scalars['ID'];
  /** 수정 가능 여부 */
  isDisabled: Scalars['Boolean'];
  /** 담당자명 */
  manager?: Maybe<ReturnSettlementCriteriaPurchaseOfSalesManagerType>;
  /** 수정자 */
  modifier?: Maybe<ReturnSettlementCriteriaPurchaseOfSalesModifierType>;
  /** 상품 */
  product: ReturnSettlementCriteriaPurchaseOfSalesProductType;
  /** 정산 기준가 시작일 */
  startAt: Scalars['DateTime'];
};

export type DtoReturnSettlementEmailHistories = {
  __typename?: 'DtoReturnSettlementEmailHistories';
  /** 참조 이메일 목록 */
  cc: Array<Scalars['String']>;
  /** 수신 이메일 목록 */
  to: Array<Scalars['String']>;
};

export type DtoReturnSettlementMonthlies = {
  __typename?: 'DtoReturnSettlementMonthlies';
  /** 국가 ID */
  countryId: CountryEnum;
  /** settlement_monthly_id */
  id: Scalars['ID'];
  /** 정산 여부 */
  isSuccess: Scalars['Boolean'];
  /** 정산 연월 */
  settlementYearMonth: Scalars['String'];
};

export type DtoReturnSettlementMonthly = {
  __typename?: 'DtoReturnSettlementMonthly';
  isSuccess: Scalars['Boolean'];
  salesCount: Scalars['Int'];
  salesFee: Scalars['Float'];
  salesPrice: Scalars['Float'];
  setoffPrice: Scalars['Float'];
  settlementPrice: Scalars['Float'];
};

export type DtoReturnSettlementPartnerEmailReceivers = {
  __typename?: 'DtoReturnSettlementPartnerEmailReceivers';
  /** 이메일 */
  emails: Array<Scalars['String']>;
};

export type DtoReturnSettlementPartners = {
  __typename?: 'DtoReturnSettlementPartners';
  /** 파트너 코드 */
  companyCode?: Maybe<Scalars['String']>;
  /** 파트너 명 */
  companyName: Scalars['String'];
  /** settlement_partner_id */
  id: Scalars['ID'];
  /** 사용 여부 상태 */
  isDisabled?: Maybe<Scalars['Boolean']>;
  /** 판매 수 */
  salesCount: Scalars['Int'];
  /** 매출 금액 */
  salesPrice: Scalars['Int'];
  /** 정산서 발송 상태 READY: 정산서 발송 전, SENT:  정산서 발송 완료 */
  sendStatus: SettlementPartnerSendStatusEnum;
  /** 상계 금액 */
  setoffPrice: Scalars['Int'];
  /** 정산 금액 */
  settlementPrice: Scalars['Int'];
  /** 정산 오류 상태 INVALID_FEE: 수수료 or 정산 기준 금액 오류, CLEAR: 정상 */
  status: SettlementPartnerStatusEnum;
};

export type DtoReturnSettlementProductList = {
  __typename?: 'DtoReturnSettlementProductList';
  nodes?: Maybe<Array<SettlementProduct>>;
  /** 총 수량 */
  totalCount: Scalars['Int'];
  /** 총 정산 금액 */
  totalSettlementPrice: Scalars['Int'];
};

export type DtoReturnSettlementProductOrders = {
  __typename?: 'DtoReturnSettlementProductOrders';
  /** 총 수량 */
  channelName: Scalars['String'];
  /** 주문 번호 */
  orderNumber: Scalars['String'];
  /** 주문 날짜 */
  orderedAt: Scalars['DateTime'];
  /** 판매 가격 */
  originalPrice: Scalars['Int'];
  /** 수량 */
  salesCount: Scalars['Int'];
  /** 총 판매 금액 */
  salesPrice: Scalars['Int'];
};

export type DtoReturnSettlementProductSummary = {
  __typename?: 'DtoReturnSettlementProductSummary';
  /** 파트너 명 */
  companyName: Scalars['String'];
  /** 매출 금액 */
  salesPrice: Scalars['Int'];
  /** 상계 금액 */
  setoffPrice: Scalars['Int'];
  /** 정산 금액 */
  settlementPrice: Scalars['Int'];
  /** 정산서 발송 여부 */
  settlementSendStatus: SettlementPartnerSendStatusEnum;
  /** 정산 연월 */
  settlementYearMonth: Scalars['String'];
  /** 정산서 상태 */
  status: SettlementPartnerStatusEnum;
};

export type DtoReturnSettlementSummary = {
  __typename?: 'DtoReturnSettlementSummary';
  /** 총 판매 수량 */
  totalSalesCount: Scalars['Int'];
  /** 총 수수료 금액 */
  totalSalesFee: Scalars['Int'];
  /** 총 판매 금액 */
  totalSalesPrice: Scalars['Int'];
  /** 총 상계 금액 */
  totalSetoffPrice: Scalars['Int'];
  /** 총 정산 금액 */
  totalSettlementPrice: Scalars['Int'];
};

export type DtoReturnUser = {
  __typename?: 'DtoReturnUser';
  /** 비밀번호 변경 인증 코드 */
  authCode?: Maybe<Scalars['String']>;
  /** 권한 정보 */
  authorizations: Array<AuthorizationEnum>;
  /** 파트너 정보 */
  company?: Maybe<ReturnUserCompanyType>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 이메일 */
  email: Scalars['String'];
  /** 유저 아이디 */
  id: Scalars['ID'];
  /** 전화번호 */
  phoneNumber?: Maybe<Scalars['String']>;
  /** 역할 */
  role: RoleEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 파트너사 유저 id: u + 16 */
  userCode?: Maybe<Scalars['String']>;
  /** 파트너사 유저 이름 */
  userName: Scalars['String'];
};

export type DtoSendSettlementConsignmentPartner = {
  /** 이메일 기억하기 */
  isRemember: Scalars['Boolean'];
  /** 받는 사람 이메일 */
  receiverEmails: Array<Scalars['String']>;
  /** 참조 이메일 */
  referenceEmails?: InputMaybe<Array<Scalars['String']>>;
  /** 정산 월 파트너 아이디 */
  settlementPartnerId: Scalars['ID'];
};

export type DtoSendSettlementPartner = {
  /** 이메일 기억 여부 */
  isRemember: Scalars['Boolean'];
  /** 전송 이메일 */
  receiverEmails: Array<Scalars['String']>;
  /** 참조 이메일 */
  referenceEmails?: InputMaybe<Array<Scalars['String']>>;
  /** 정산 대상 파트너 id */
  settlementPartnerId: Scalars['ID'];
};

export type DtoUpdateB2bOrderBankDepositInput = {
  /** 주문 ID */
  id: Scalars['ID'];
  update: UpdateB2bOrderBankDepositInput;
};

export type DtoUpdateB2bOrderContractConfigInput = {
  /** 거래구성 id  */
  id: Scalars['ID'];
  /** 업데이트 목록 */
  update: UpdateB2bOrderContractConfigInputType;
};

export type DtoUpdateB2bOrderEstimatedArrivalDateInput = {
  /** 주문 ID */
  id: Scalars['ID'];
  update: UpdateB2bOrderEstimatedArrivalDateInput;
};

export type DtoUpdateBrandRequest = {
  /** 식별자 */
  id: Scalars['ID'];
  update: UpdateBrandRequestInputType;
};

export type DtoUpdateCompany = {
  /** common id */
  id?: InputMaybe<Scalars['ID']>;
  update: UpdateCompanyType;
};

export type DtoUpdateCompanyRequest = {
  /** 아이디 */
  id: Scalars['ID'];
  update: UpdateCompanyRequestInputType;
};

export type DtoUpdateCompanyRequestByInspector = {
  /** company request id */
  id: Scalars['ID'];
  /** update 내용 */
  update: UpdateCompanyRequestByInspectorType;
};

export type DtoUpdateCompanyRequestByRequester = {
  /** 아이디 */
  id: Scalars['ID'];
  /** 업데이트 */
  update: UpdateCompanyRequestByRequesterType;
};

export type DtoUpdateExport = {
  /** common id */
  id?: InputMaybe<Scalars['ID']>;
  update: UpdateExportType;
};

/** 고미 담당자 수정 정보 */
export type DtoUpdateGomiUserInput = {
  /** 고미 담당자 식별키 */
  id: Scalars['ID'];
  /** 고미 담당자 수정 내용 */
  update: UpdateGomiUserType;
};

export type DtoUpdateImportLicensingCommentInput = {
  /** comment id */
  id: Scalars['ID'];
  update: UpdateImportLicensingCommentInputType;
};

export type DtoUpdateImportLicensingContentInput = {
  /** 컨텐츠 ID */
  id: Scalars['ID'];
  update: UpdateImportLicensingContentInputType;
};

export type DtoUpdateImportLicensingDocumentInput = {
  /** 서류 id */
  id: Scalars['ID'];
  update: UpdateImportLicensingDocumentInputType;
};

export type DtoUpdateImportLicensingEstimateInput = {
  /** 수입 허가 견적 id */
  id: Scalars['ID'];
  update: UpdateImportLicensingEstimateInputType;
};

export type DtoUpdateImportLicensingInput = {
  /** 수입허가 등록 id */
  id: Scalars['ID'];
  update: UpdateImportLicensingInputType;
};

export type DtoUpdateImportLicensingTemplateInput = {
  /** template id */
  id: Scalars['ID'];
  update: UpdateImportLicensingTemplateInputType;
};

export type DtoUpdateProduct = {
  /** 상품 id */
  id: Scalars['ID'];
  /** 업데이트 필드 */
  update: UpdateProductInputType;
};

export type DtoUpdateProductExport = {
  /** common product id */
  id?: InputMaybe<Scalars['ID']>;
  update: UpdateExportProductType;
};

export type DtoUpdateProductGroup = {
  /** 상품 그룹 ID */
  id: Scalars['ID'];
  /** 상품 그룹 수정 */
  update: UpdateProductGroupType;
};

export type DtoUpdateProductRequestByInspectorV2 = {
  /** 상품 요청 id */
  id: Scalars['ID'];
  /** 업데이트 필드 */
  update: UpdateProductRequestByInspectorInputType;
};

export type DtoUpdateProductRequestByRequesterV2 = {
  /** 상품 요청 id */
  id: Scalars['ID'];
  /** 업데이트 필드 */
  update: UpdateProductRequestByRequesterInputType;
};

export type DtoUpdateSettlementCriteriaConsignment = {
  /** 식별키 */
  id: Scalars['ID'];
  update: UpdateSettlementCriteriaConsignmentUpdateType;
};

export type DtoUpdateSettlementCriteriaPurchaseOfSales = {
  /** 식별자 */
  id: Scalars['ID'];
  update: UpdateSettlementCriteriaPurchaseOfSalesInputType;
};

/** 유저 생성 정보 */
export type DtoUpdateUser = {
  /** 파트너 유저 식별키 */
  id: Scalars['ID'];
  /** 파트너 유저 수정 내용 */
  update: UpdateUserType;
};

/** error code */
export enum ErrorCodeEnum {
  AuthorizationNotExist = 'AUTHORIZATION_NOT_EXIST',
  AuthInvalidPassword = 'AUTH_INVALID_PASSWORD',
  AuthPasswordChangingStatus = 'AUTH_PASSWORD_CHANGING_STATUS',
  AuthPasswordShort = 'AUTH_PASSWORD_SHORT',
  B2BOrderBulkUploadInvalidExcelFormat = 'B2B_ORDER_BULK_UPLOAD_INVALID_EXCEL_FORMAT',
  B2BOrderContractConfigEmpty = 'B2B_ORDER_CONTRACT_CONFIG_EMPTY',
  B2BOrderContractConfigExist = 'B2B_ORDER_CONTRACT_CONFIG_EXIST',
  B2BOrderContractEmpty = 'B2B_ORDER_CONTRACT_EMPTY',
  B2BOrderContractExist = 'B2B_ORDER_CONTRACT_EXIST',
  B2BOrderDetailEmpty = 'B2B_ORDER_DETAIL_EMPTY',
  B2BOrderDoNotUpdateDoneStatus = 'B2B_ORDER_DO_NOT_UPDATE_DONE_STATUS',
  B2BOrderDoNotUpdateEstimatedArrivalDate = 'B2B_ORDER_DO_NOT_UPDATE_ESTIMATED_ARRIVAL_DATE',
  B2BOrderEmpty = 'B2B_ORDER_EMPTY',
  B2BOrderUnableToConfirmation = 'B2B_ORDER_UNABLE_TO_CONFIRMATION',
  B2BOrderUnableToRequestOrderConfirmation = 'B2B_ORDER_UNABLE_TO_REQUEST_ORDER_CONFIRMATION',
  BrandEmpty = 'BRAND_EMPTY',
  BrandInvalidCompany = 'BRAND_INVALID_COMPANY',
  BrandInvalidCountry = 'BRAND_INVALID_COUNTRY',
  BrandNotEmpty = 'BRAND_NOT_EMPTY',
  BrandRequestSystemBrandCodeGenerationIdLengthExceeded = 'BRAND_REQUEST_SYSTEM_BRAND_CODE_GENERATION_ID_LENGTH_EXCEEDED',
  BrandRequestSystemBulkUploadCompanyNotExist = 'BRAND_REQUEST_SYSTEM_BULK_UPLOAD_COMPANY_NOT_EXIST',
  BrandRequestSystemBulkUploadInvalidExcelFormat = 'BRAND_REQUEST_SYSTEM_BULK_UPLOAD_INVALID_EXCEL_FORMAT',
  BrandRequestSystemCanDeleteOnlyRejectionOrTemporary = 'BRAND_REQUEST_SYSTEM_CAN_DELETE_ONLY_REJECTION_OR_TEMPORARY',
  BrandRequestSystemCanModifyOnlyTemporaryWithoutChangingStatus = 'BRAND_REQUEST_SYSTEM_CAN_MODIFY_ONLY_TEMPORARY_WITHOUT_CHANGING_STATUS',
  BrandRequestSystemGomiManagerNotExist = 'BRAND_REQUEST_SYSTEM_GOMI_MANAGER_NOT_EXIST',
  BrandRequestSystemInspectorCanCreateOnlyDoneOrTemporary = 'BRAND_REQUEST_SYSTEM_INSPECTOR_CAN_CREATE_ONLY_DONE_OR_TEMPORARY',
  BrandRequestSystemInspectorInvalidStatus = 'BRAND_REQUEST_SYSTEM_INSPECTOR_INVALID_STATUS',
  BrandRequestSystemRequesterCanCreateOnlyRequestOrTemporary = 'BRAND_REQUEST_SYSTEM_REQUESTER_CAN_CREATE_ONLY_REQUEST_OR_TEMPORARY',
  BrandRequestSystemRequesterInvalidStatus = 'BRAND_REQUEST_SYSTEM_REQUESTER_INVALID_STATUS',
  CompanyCodeCreateFail = 'COMPANY_CODE_CREATE_FAIL',
  CompanyEmpty = 'COMPANY_EMPTY',
  CompanyRequestSystemBulkUploadInvalidExcelFormat = 'COMPANY_REQUEST_SYSTEM_BULK_UPLOAD_INVALID_EXCEL_FORMAT',
  CompanyRequestSystemGomiManagerNotExist = 'COMPANY_REQUEST_SYSTEM_GOMI_MANAGER_NOT_EXIST',
  CompanyRequestSystemInspectorInvalidStatus = 'COMPANY_REQUEST_SYSTEM_INSPECTOR_INVALID_STATUS',
  CompanyRequestSystemInvalidCompleteStatus = 'COMPANY_REQUEST_SYSTEM_INVALID_COMPLETE_STATUS',
  CompanyRequestSystemRequesterInvalidStatus = 'COMPANY_REQUEST_SYSTEM_REQUESTER_INVALID_STATUS',
  CoreApiGspcCodeError = 'CORE_API_GSPC_CODE_ERROR',
  CoreApiLoginError = 'CORE_API_LOGIN_ERROR',
  CoreApiPartnerCodeError = 'CORE_API_PARTNER_CODE_ERROR',
  CountryEmpty = 'COUNTRY_EMPTY',
  DemoUserEmpty = 'DEMO_USER_EMPTY',
  EmptyResourceForRequest = 'EMPTY_RESOURCE_FOR_REQUEST',
  ExchangeRateEmpty = 'EXCHANGE_RATE_EMPTY',
  ExistEmail = 'EXIST_EMAIL',
  ExpiredToken = 'EXPIRED_TOKEN',
  ExportEmpty = 'EXPORT_EMPTY',
  ExportNoData = 'EXPORT_NO_DATA',
  Forbidden = 'FORBIDDEN',
  GomiflowOrderDuplicateOrderItemId = 'GOMIFLOW_ORDER_DUPLICATE_ORDER_ITEM_ID',
  GomiflowOrderItemInvalid = 'GOMIFLOW_ORDER_ITEM_INVALID',
  GomiflowOrderItemUpdateNotExist = 'GOMIFLOW_ORDER_ITEM_UPDATE_NOT_EXIST',
  GomiflowOrderNotExist = 'GOMIFLOW_ORDER_NOT_EXIST',
  GomiUserEmpty = 'GOMI_USER_EMPTY',
  GoogleEmailNotVerified = 'GOOGLE_EMAIL_NOT_VERIFIED',
  GspcBarcodeNotEmpty = 'GSPC_BARCODE_NOT_EMPTY',
  GspcEmpty = 'GSPC_EMPTY',
  GspcNotEmpty = 'GSPC_NOT_EMPTY',
  IdEmpty = 'ID_EMPTY',
  ImportLicenseCommentNotFound = 'IMPORT_LICENSE_COMMENT_NOT_FOUND',
  ImportLicenseContentNotFound = 'IMPORT_LICENSE_CONTENT_NOT_FOUND',
  ImportLicenseDocumentNotFound = 'IMPORT_LICENSE_DOCUMENT_NOT_FOUND',
  ImportLicenseDocumentUnUpdatableStatus = 'IMPORT_LICENSE_DOCUMENT_UN_UPDATABLE_STATUS',
  ImportLicenseEstimateNotFound = 'IMPORT_LICENSE_ESTIMATE_NOT_FOUND',
  ImportLicenseEstimateSheetNotFound = 'IMPORT_LICENSE_ESTIMATE_SHEET_NOT_FOUND',
  ImportLicenseLicenseFileNotFound = 'IMPORT_LICENSE_LICENSE_FILE_NOT_FOUND',
  ImportLicenseLicenseTemplateNotFound = 'IMPORT_LICENSE_LICENSE_TEMPLATE_NOT_FOUND',
  ImportLicenseLicenseUnableDownloadAllDocumentFiles = 'IMPORT_LICENSE_LICENSE_UNABLE_DOWNLOAD_ALL_DOCUMENT_FILES',
  ImportLicenseLicenseUnableDownloadToDocumentFiles = 'IMPORT_LICENSE_LICENSE_UNABLE_DOWNLOAD_TO_DOCUMENT_FILES',
  ImportLicenseLicenseUnableToRequestTaxBill = 'IMPORT_LICENSE_LICENSE_UNABLE_TO_REQUEST_TAX_BILL',
  ImportLicenseLicenseUnUpdatableStatus = 'IMPORT_LICENSE_LICENSE_UN_UPDATABLE_STATUS',
  ImportLicenseManagerNotFound = 'IMPORT_LICENSE_MANAGER_NOT_FOUND',
  ImportLicenseNotFound = 'IMPORT_LICENSE_NOT_FOUND',
  ImportLicenseTemplateNotFound = 'IMPORT_LICENSE_TEMPLATE_NOT_FOUND',
  InformationManagementInspectorInvalidStatus = 'INFORMATION_MANAGEMENT_INSPECTOR_INVALID_STATUS',
  InformationManagementSystemInspectorCannotDoneIfAlreadyDone = 'INFORMATION_MANAGEMENT_SYSTEM_INSPECTOR_CANNOT_DONE_IF_ALREADY_DONE',
  InformationManagementSystemInspectorRejectionOnlyRequest = 'INFORMATION_MANAGEMENT_SYSTEM_INSPECTOR_REJECTION_ONLY_REQUEST',
  InformationManagementSystemNotRequestToRejection = 'INFORMATION_MANAGEMENT_SYSTEM_NOT_REQUEST_TO_REJECTION',
  InformationManagementSystemNotTemporaryToRejection = 'INFORMATION_MANAGEMENT_SYSTEM_NOT_TEMPORARY_TO_REJECTION',
  InformationManagementSystemRequesterInvalidStatus = 'INFORMATION_MANAGEMENT_SYSTEM_REQUESTER_INVALID_STATUS',
  InformationManagementSystemUnableToDelete = 'INFORMATION_MANAGEMENT_SYSTEM_UNABLE_TO_DELETE',
  InformationManagementSystemUnableToUpdateStatus = 'INFORMATION_MANAGEMENT_SYSTEM_UNABLE_TO_UPDATE_STATUS',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  InvalidFile = 'INVALID_FILE',
  NotFound = 'NOT_FOUND',
  OrderInfoBulkUploadInvalidExcelFormat = 'ORDER_INFO_BULK_UPLOAD_INVALID_EXCEL_FORMAT',
  PartnerUserAlreadyExist = 'PARTNER_USER_ALREADY_EXIST',
  PartnerUserEmpty = 'PARTNER_USER_EMPTY',
  ProductGroupNotFound = 'PRODUCT_GROUP_NOT_FOUND',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  ProductOptionInvalidDeleteRequest = 'PRODUCT_OPTION_INVALID_DELETE_REQUEST',
  ProductRequestSystemBulkUploadBasicEmptyBarcode = 'PRODUCT_REQUEST_SYSTEM_BULK_UPLOAD_BASIC_EMPTY_BARCODE',
  ProductRequestSystemBulkUploadEmptyData = 'PRODUCT_REQUEST_SYSTEM_BULK_UPLOAD_EMPTY_DATA',
  ProductRequestSystemBulkUploadEmptyImportLicenseName = 'PRODUCT_REQUEST_SYSTEM_BULK_UPLOAD_EMPTY_IMPORT_LICENSE_NAME',
  ProductRequestSystemBulkUploadEmptySupplierProductCode = 'PRODUCT_REQUEST_SYSTEM_BULK_UPLOAD_EMPTY_SUPPLIER_PRODUCT_CODE',
  ProductRequestSystemBulkUploadInvalidCountryImportLicenseName = 'PRODUCT_REQUEST_SYSTEM_BULK_UPLOAD_INVALID_COUNTRY_IMPORT_LICENSE_NAME',
  ProductRequestSystemBulkUploadInvalidExcelFormat = 'PRODUCT_REQUEST_SYSTEM_BULK_UPLOAD_INVALID_EXCEL_FORMAT',
  ProductRequestSystemInspectorInvalidStatus = 'PRODUCT_REQUEST_SYSTEM_INSPECTOR_INVALID_STATUS',
  ProductRequestSystemRequestNotFound = 'PRODUCT_REQUEST_SYSTEM_REQUEST_NOT_FOUND',
  ProductRequestSystemSqsFailed = 'PRODUCT_REQUEST_SYSTEM_SQS_FAILED',
  ProductRequestSystemSqsServerError = 'PRODUCT_REQUEST_SYSTEM_SQS_SERVER_ERROR',
  QueueAlreadyOnRunning = 'QUEUE_ALREADY_ON_RUNNING',
  QueueEmpty = 'QUEUE_EMPTY',
  SettlementCanNotApprovalForRequireCheckExist = 'SETTLEMENT_CAN_NOT_APPROVAL_FOR_REQUIRE_CHECK_EXIST',
  SettlementCriteriaConsignmentFailedCreate_1 = 'SETTLEMENT_CRITERIA_CONSIGNMENT_FAILED_CREATE_1',
  SettlementCriteriaConsignmentFailedCreate_2 = 'SETTLEMENT_CRITERIA_CONSIGNMENT_FAILED_CREATE_2',
  SettlementCriteriaConsignmentFailedCreate_3 = 'SETTLEMENT_CRITERIA_CONSIGNMENT_FAILED_CREATE_3',
  SettlementCriteriaConsignmentFailedCreate_4 = 'SETTLEMENT_CRITERIA_CONSIGNMENT_FAILED_CREATE_4',
  SettlementCriteriaConsignmentFailedDelete = 'SETTLEMENT_CRITERIA_CONSIGNMENT_FAILED_DELETE',
  SettlementCriteriaConsignmentFailedUpdate_1 = 'SETTLEMENT_CRITERIA_CONSIGNMENT_FAILED_UPDATE_1',
  SettlementCriteriaConsignmentFailedUpdate_2 = 'SETTLEMENT_CRITERIA_CONSIGNMENT_FAILED_UPDATE_2',
  SettlementCriteriaConsignmentFailedUpdate_3 = 'SETTLEMENT_CRITERIA_CONSIGNMENT_FAILED_UPDATE_3',
  SettlementCriteriaPurchaseOfSalesFailedUpdate_1 = 'SETTLEMENT_CRITERIA_PURCHASE_OF_SALES_FAILED_UPDATE_1',
  SettlementCriteriaPurchaseOfSalesFailedUpdate_2 = 'SETTLEMENT_CRITERIA_PURCHASE_OF_SALES_FAILED_UPDATE_2',
  SettlementGenerationCallFailed = 'SETTLEMENT_GENERATION_CALL_FAILED',
  SettlementGenerationMwaaTokenNotExist = 'SETTLEMENT_GENERATION_MWAA_TOKEN_NOT_EXIST',
  SettlementIndexAlreadyExist = 'SETTLEMENT_INDEX_ALREADY_EXIST',
  SettlementIndexEmpty = 'SETTLEMENT_INDEX_EMPTY',
  SettlementInvalidMisaExcelFile = 'SETTLEMENT_INVALID_MISA_EXCEL_FILE',
  SettlementOrderItemAlreadySettlementedExist = 'SETTLEMENT_ORDER_ITEM_ALREADY_SETTLEMENTED_EXIST',
  SettlementOrderItemAlreadySettlementedNotExist = 'SETTLEMENT_ORDER_ITEM_ALREADY_SETTLEMENTED_NOT_EXIST',
  SettlementOrderItemExist = 'SETTLEMENT_ORDER_ITEM_EXIST',
  SettlementOrderItemNotCancelStatus = 'SETTLEMENT_ORDER_ITEM_NOT_CANCEL_STATUS',
  SettlementOrderItemNotExist = 'SETTLEMENT_ORDER_ITEM_NOT_EXIST',
  SettlementOrderItemNotManual = 'SETTLEMENT_ORDER_ITEM_NOT_MANUAL',
  SettlementPartnerAlreadyBeenSent = 'SETTLEMENT_PARTNER_ALREADY_BEEN_SENT',
  SettlementPartnerHasFeeError = 'SETTLEMENT_PARTNER_HAS_FEE_ERROR',
  SettlementPartnerNotFound = 'SETTLEMENT_PARTNER_NOT_FOUND',
  SettlementYearMonthNotFound = 'SETTLEMENT_YEAR_MONTH_NOT_FOUND',
  TooLargePayload = 'TOO_LARGE_PAYLOAD',
  Unauthorized = 'UNAUTHORIZED',
  UserAlreadyExist = 'USER_ALREADY_EXIST',
  UserEmpty = 'USER_EMPTY',
  UserForbiddenDeleteMyself = 'USER_FORBIDDEN_DELETE_MYSELF',
  UserInvalidEmail = 'USER_INVALID_EMAIL',
  UserInvalidErpCode = 'USER_INVALID_ERP_CODE',
  UserPasswordShort = 'USER_PASSWORD_SHORT',
}

export enum ExportStatusEnum {
  Ready = 'READY',
}

export type FileInfoListFilterType = {
  /** 복수 파일 아이디 */
  fileIds: Array<Scalars['ID']>;
};

export type FileInfoType = {
  __typename?: 'FileInfoType';
  /** 파일 확장자 */
  fileExtension: Scalars['String'];
  /** 파일 키 */
  fileKey: Scalars['String'];
  /** 파일 명 */
  fileName: Scalars['String'];
  /** 아이디 */
  id: Scalars['ID'];
  /** 파일 링크 */
  link: Scalars['String'];
};

export enum FilePathEnum {
  B2BOrderConfirmationDocument = 'B2B_ORDER_CONFIRMATION_DOCUMENT',
  B2BOrderDetail = 'B2B_ORDER_DETAIL',
  B2BOrderDocument = 'B2B_ORDER_DOCUMENT',
  BankImage = 'BANK_IMAGE',
  BrandExport = 'BRAND_EXPORT',
  BrandImage = 'BRAND_IMAGE',
  BrandLogoImage = 'BRAND_LOGO_IMAGE',
  CompanyExport = 'COMPANY_EXPORT',
  CompanyImage = 'COMPANY_IMAGE',
  ContractPath = 'CONTRACT_PATH',
  ExportCiPl = 'EXPORT_CI_PL',
  ImportLicense = 'IMPORT_LICENSE',
  ImportLicenseEstimate = 'IMPORT_LICENSE_ESTIMATE',
  ProductExport = 'PRODUCT_EXPORT',
  RegistrationImage = 'REGISTRATION_IMAGE',
  ReportExcel = 'REPORT_EXCEL',
  SettlementExport = 'SETTLEMENT_EXPORT',
}

export type FindBrandRequestsFilter = {
  /** 파트너 id */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 국가 id */
  countryId?: InputMaybe<CountryEnum>;
  /** 요청 유저 id */
  requestUserId?: InputMaybe<Scalars['ID']>;
  /** 요청 상태 */
  status?: InputMaybe<CompanyRequestStatusEnum>;
  /** 유저 id 리스트 */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type FindBrandsFilter = {
  /** 파트너 id */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 국가 id */
  countryId?: InputMaybe<CountryEnum>;
  /** 유저 id 리스트 */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type FindCompaniesFilter = {
  /** 파트너 국가 id */
  countryId?: InputMaybe<CountryEnum>;
  /** 진출한 국가 id */
  entryCountryId?: InputMaybe<CountryEnum>;
  /** 파트너 그룹 id */
  groupId?: InputMaybe<CompanyGroupEnum>;
  /** 유저 id 리스트 */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type FindCompanyRequestsFilter = {
  /** 국가 id */
  countryId?: InputMaybe<CountryEnum>;
  /** 파트너 그룹 id */
  groupId?: InputMaybe<CompanyGroupEnum>;
  /** 요청 상태 */
  status?: InputMaybe<CompanyRequestStatusEnum>;
  /** 유저 id 리스트 */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type FindOrderItemsFilter = {
  /** 브랜드 ID */
  brandId?: InputMaybe<Scalars['ID']>;
  /** 채널 정보 */
  channel?: InputMaybe<OrderChannelEnum>;
  /** 파트너 아이디 */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 국가 아이디 */
  countryId: CountryEnum;
  /** (예: YYYY-MM-DD) */
  endOrderedAt?: InputMaybe<Scalars['String']>;
  /** 증정품 여부 */
  giftType?: InputMaybe<GiftEnum>;
  orderNumber?: InputMaybe<Scalars['String']>;
  /** 주문 상태 */
  orderStatus?: InputMaybe<SettlementOrderStatus>;
  /** 결제 상태 */
  paymentStatus?: InputMaybe<Array<OrderPaymentStatusEnum>>;
  /** sales_code */
  salesCode?: InputMaybe<Array<Scalars['String']>>;
  /** 배송 상태 */
  shippingStatus?: InputMaybe<Array<OrderShippingStatusEnum>>;
  /** (예: YYYY-MM-DD) */
  startOrderedAt?: InputMaybe<Scalars['String']>;
};

export type FindProductRequestsType = {
  /** 브랜드 ids */
  brandIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 요청 유저 id */
  requestUserId?: InputMaybe<Scalars['ID']>;
  /** 요청 상태 */
  status?: InputMaybe<CompanyRequestStatusEnum>;
};

export type FindProductsFilter = {
  /** 브랜드 ID */
  brandIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 파트너 ID */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 상품 그룹 ID */
  productGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetBasicSettlementCriteriaConsignmentInputFilterDto = {
  /** 판매 코드 식별키 */
  salesCodeId: Scalars['ID'];
};

export type GetBasicSettlementCriteriaPurchaseOfSalesInputFilterDto = {
  /** 상품 id */
  productId: Scalars['ID'];
};

export type GetGomiUsersFilterType = {
  /** 고미 담당자 이름 */
  userName?: InputMaybe<Scalars['String']>;
};

export type GetMonthlyExchangeRateFilterType = {
  /** 조회 국가 id */
  countryId: CountryEnum;
  /** 조회 연월 */
  yearMonth: Scalars['String'];
};

export type GetPartnerUsersFilterType = {
  /** 파트너 아이디 */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 파트너 유저명 */
  userName?: InputMaybe<Scalars['String']>;
};

export type GetSalesCodesInputDto = {
  /** sales code 조회 필터 */
  filter?: InputMaybe<SalesCodesInputFilter>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
};

export type GetSettlementConsignmentPartnerFilterType = {
  /** 정산 월 파트너 아이디 */
  settlementPartnerId: Scalars['ID'];
};

export type GetSettlementConsignmentPartnerProductListFilterType = {
  /** 정산 월 파트너 아이디 */
  settlementPartnerId: Scalars['ID'];
  /** 정산상태 (정산 - SETTLEMENT, 상계 - SETOFF) */
  settlementStatus: SettlementOrderItemSettlementStatusEnum;
};

export type GetSettlementConsignmentPartnersFilterType = {
  /** 전송 상태 */
  sendStatus?: InputMaybe<SettlementPartnerSendStatusEnum>;
  /** 정산 월 아이디 */
  settlementMonthlyId: Scalars['ID'];
};

export type GetSettlementConsignmentProductOrdersFilterType = {
  /** 채널 아이디 */
  channelId?: InputMaybe<Scalars['ID']>;
  /** 판매 코드 아이디 */
  salesCodeId: Scalars['ID'];
  /** 정산 월 파트너 아이디 */
  settlementPartnerId: Scalars['ID'];
  /** 정산상태 (정산 - SETTLEMENT, 상계 - SETOFF) */
  settlementStatus: SettlementOrderItemSettlementStatusEnum;
};

export type GetSettlementMonthliesFilterType = {
  /** 파트너 국가 ID */
  countryId: CountryEnum;
};

export type GetSettlementMonthlySummaryType = {
  countryId: CountryEnum;
  /** 정산 연월 */
  yearMonth: Scalars['String'];
};

export type GetSettlementPartnerCurrentEmailsFilterType = {
  /** 정산 대상 파트너 id */
  settlementPartnerId: Scalars['ID'];
};

export type GetSettlementPartnerFilterType = {
  /** 전송 상태 */
  sendStatus?: InputMaybe<SettlementPartnerSendStatusEnum>;
  /** 정산 연월 id */
  settlementMonthlyId: Scalars['ID'];
};

export type GetSettlementPartnerFilterTypeV2 = {
  /** 정산 대상 파트너 id */
  settlementPartnerId: Scalars['ID'];
};

export type GetSettlementPartnerIdFilterType = {
  /** 국가 id */
  countryId: CountryEnum;
  /** 정산 연월 */
  settlementYearMonth: Scalars['String'];
};

export type GetSettlementProductListFilterType = {
  /** 정산 대상 파트너 id */
  settlementPartnerId: Scalars['ID'];
  /** 정산상태 (정산: SETTLEMENT, 상계: SETOFF)  */
  settlementStatus: SettlementOrderItemSettlementStatusEnum;
};

export type GetSettlementProductOrderFilterType = {
  /** 상품 ID */
  productId: Scalars['ID'];
  /** 정산 대상 파트너 id */
  settlementPartnerId: Scalars['ID'];
  /** 정산상태 (정산: SETTLEMENT, 상계: SETOFF) */
  settlementStatus: SettlementOrderItemSettlementStatusEnum;
};

export type GetSettlementProductSummaryFilterType = {
  /** 정산 대상 파트너 id */
  settlementPartnerId: Scalars['ID'];
};

export type GetSettlementSummaryFilterType = {
  /** 정산 연월 id */
  settlementMonthlyId: Scalars['ID'];
};

export type GetStockInputDto = {
  /** 필터 */
  filter?: InputMaybe<GetStockInputType>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
};

export type GetStockInputType = {
  /** 브랜드 고유코드 */
  brandIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 파트너 고유키 */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 국가 id */
  countryId: CountryEnum;
  /** 창고 위치 */
  locations?: InputMaybe<Array<StockLocationEnum>>;
};

export type GetStockLogInputDto = {
  /** 필터 */
  filter?: InputMaybe<GetStockLogInputFilterType>;
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 페이지네이션 */
  pagination?: InputMaybe<PaginationDto>;
};

export type GetStockLogInputFilterType = {
  /** 브랜드 고유코드 */
  brandIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 파트너 고유키 */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 국가 id */
  countryId: CountryEnum;
  /** (예: 2019-12-03) */
  endDateAt: Scalars['DateTime'];
  /** 창고 위치 */
  locations?: InputMaybe<Array<StockLocationEnum>>;
  /** (예: 2019-12-03) */
  startDateAt: Scalars['DateTime'];
  /** 거래구분(Stock_In(입고), Stock_Out(출고) */
  type?: InputMaybe<StockTypeEnum>;
};

export enum GiftEnum {
  ExcludeGift = 'EXCLUDE_GIFT',
  OnlyGift = 'ONLY_GIFT',
}

export type GomiUserEntity = {
  __typename?: 'GomiUserEntity';
  /** 비밀번호 변경 인증 코드 */
  authCode?: Maybe<Scalars['String']>;
  /** 국가 id */
  countryId: CountryEnum;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 부서 아이디 */
  departmentId?: Maybe<Scalars['ID']>;
  /** 이메일 */
  email: Scalars['String'];
  /** ERP 사원코드 */
  erpUserCode?: Maybe<Scalars['String']>;
  /** 기존 고미 유저 코드 */
  gomiUserCode?: Maybe<Scalars['String']>;
  /** id */
  id: Scalars['ID'];
  /** 전화번호 */
  phoneNumber: Scalars['String'];
  /** 역할 */
  role: RoleEnum;
  /** 소셜 로그인 고유키 */
  socialId?: Maybe<Scalars['String']>;
  /** 소셜 공급자 정보 */
  socialProvider?: Maybe<SocialProviderEnum>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 직원 이름 */
  userName: Scalars['String'];
};

export type GomiUserType = {
  __typename?: 'GomiUserType';
  /** 국가 id */
  countryId: CountryEnum;
  /** 부서 아이디 */
  departmentId?: Maybe<Scalars['ID']>;
  /** 이메일 */
  email: Scalars['String'];
  /** 아이디 */
  id: Scalars['ID'];
  /** 전화번호 */
  phoneNumber: Scalars['String'];
  /** 소셜 로그인 고유키 */
  socialId?: Maybe<Scalars['String']>;
  /** 직원 이름 */
  userName: Scalars['String'];
};

export type HomeInputFilter = {
  /** (예: YYYY-MM-DD) */
  endAt?: InputMaybe<Scalars['String']>;
  /** (예: YYYY-MM-DD) */
  startedAt?: InputMaybe<Scalars['String']>;
};

export type ImportLicensingCommentListInputFilterType = {
  /** 파일 id */
  fileId: Scalars['ID'];
};

export type ImportLicensingContentListInputFilterType = {
  /** 수입 허가 시트 ID */
  importLicensingId: Scalars['ID'];
  /** 수입 허가 상태 */
  step: ImportLicensingSheetStepEnum;
};

export enum ImportLicensingDocumentStatusEnum {
  Done = 'DONE',
  Ready = 'READY',
  ReadyDone = 'READY_DONE',
  RequestConfirm = 'REQUEST_CONFIRM',
  RequestEdit = 'REQUEST_EDIT',
}

export type ImportLicensingListInputFilterType = {
  /** 국가 */
  countryId?: InputMaybe<CountryEnum>;
  /** 수입 허가 단계 */
  step?: InputMaybe<ImportLicensingSheetStepEnum>;
};

export enum ImportLicensingSheetStepEnum {
  CreateProduct = 'CREATE_PRODUCT',
  DocumentDelivery = 'DOCUMENT_DELIVERY',
  DocumentUploadPerProduct = 'DOCUMENT_UPLOAD_PER_PRODUCT',
  Done = 'DONE',
  Estimate = 'ESTIMATE',
  ExtraDocument = 'EXTRA_DOCUMENT',
}

export enum InformationManagementRouteTypeEnum {
  Admin = 'ADMIN',
  Partner = 'PARTNER',
}

export type KeywordDto = {
  /** 검색 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** 수출 상품 추가하기 */
  addExportProduct: Scalars['Boolean'];
  /** 주문 생성 */
  cancelOrderDetail: B2bOrderDetailProductType;
  /** 파트너 비밀번호 변경 */
  changePartnerPasswordV2?: Maybe<Scalars['Boolean']>;
  /** 비밀번호 변경 */
  changePasswordV2?: Maybe<Scalars['Boolean']>;
  /** 수입 허가 단계 별 확인 완료 */
  completeConfirmationImportLicensing: Scalars['Boolean'];
  /** (파트너) 수입 허가 단계 별 확인 완료 */
  completeConfirmationImportLicensingByPartner: Scalars['Boolean'];
  /** 구매자 주문 확인 */
  confirmB2bOrderByBuyer: DtoReturnB2bOrder;
  /** 판매자 주문 확인 */
  confirmB2bOrderBySeller: DtoReturnB2bOrder;
  /** 코멘트 확인 */
  confirmFileComment: Scalars['Boolean'];
  /** 주문 생성 */
  createB2bOrder: DtoReturnB2bOrder;
  /** 파트너 연결 생성 */
  createB2bOrderContract: DtoReturnB2bOrderContract;
  /** 발주 거래 구성 생성 */
  createB2bOrderContractConfig: DtoReturnB2bOrderContractConfig;
  /** 브랜드 요청 생성 */
  createBrandRequest: DtoReturnBrandRequest;
  /** 브랜드 요청 업데이트 (검수자용) / 브랜드 목록 -> 즉시 등록, 브랜드 관리 -> 즉시 등록 에서 사용합니다. */
  createBrandRequestToDoneByInspectorV2: DtoReturnBrandRequest;
  /** 파트너 요청 생성 */
  createCompanyRequest: DtoReturnCompanyRequest;
  /** 외부용 파트너 생성 요청 */
  createCompanyRequestByExternal: DtoReturnCompanyRequest;
  /** 파트너 요청 업데이트 (검수자용) / 파트너 목록 -> 즉시 등록, 파트너 관리 -> 즉시 등록 에서 사용합니다. */
  createCompanyRequestToDoneByInspectorV2: DtoReturnCompanyRequest;
  /** 수출 신규 생성 */
  createExport: DtoReturnExport;
  /** 수출자 등록 */
  createExportAgency: Scalars['Boolean'];
  /** 고미 유저 생성 */
  createGomiUserV2: DtoReturnGomiUser;
  /** 수입자 등록 */
  createImportAgency: Scalars['Boolean'];
  /** 수입 허가 등록 구성 */
  createImportLicensing: DtoReturnImportLicensing;
  /** 수입 허가 파일 comment 생성 */
  createImportLicensingComment: DtoReturnImportLicensingComment;
  /** 수입 허가 컨텐츠 등록 */
  createImportLicensingContent: DtoReturnImportLicensingContent;
  /** 수입 허가 견적 추가 */
  createImportLicensingEstimate: DtoReturnImportLicensingEstimate;
  /** 수입 허가 추가서류 생성 */
  createImportLicensingExtraDocument: DtoReturnImportLicensingContent;
  /** 자료 업로드 > 등록 API 목업 작업 */
  createImportLicensingFileUpload: Scalars['Boolean'];
  /** 수입 허가 템플릿 생성 */
  createImportLicensingTemplate: DtoReturnImportLicensingTemplate;
  /** 파트너 유저 생성 by 관리자 */
  createPartnerUser: DtoReturnUser;
  /** product Group 추가 */
  createProductGroup: DtoReturnProductGroup;
  /** product 옵션 추가 */
  createProductOptions: Array<DtoReturnProductOption>;
  /** 검수자 검수 요청 생성 */
  createProductRequestByInspector: Array<DtoReturnProductRequest>;
  /** 편집자 검수 요청 생성 */
  createProductRequestByRequester: DtoReturnProductRequest;
  /** 검수자 검수 요청 다중 생성 */
  createProductRequestsByInspector: Array<DtoReturnProductRequest>;
  /** 편집자 검수 요청 다중 생성 */
  createProductRequestsByRequester: Array<DtoReturnProductRequest>;
  /** 기준 위탁 생성하기 */
  createSettlementCriteriaConsignment: Scalars['Boolean'];
  /** 발주 거래 구성 삭제 */
  deleteB2bOrderContractConfig: DtoReturnB2bOrderContractConfig;
  /** 브랜드 요청 삭제 */
  deleteBrandRequest: DtoReturnBrandRequest;
  /** 파트너 요청 삭제 */
  deleteCompanyRequest: DtoReturnCompanyRequest;
  /** 고미 유저 삭제 */
  deleteGomiUserV2: DtoReturnGomiUser;
  /** 수입 허가 파일 comment 삭제 */
  deleteImportLicensingComment: DtoReturnImportLicensingComment;
  /** 수입 허가 컨텐츠 삭제 */
  deleteImportLicensingContent: DtoReturnImportLicensingContent;
  /** 수입 허가 서류 삭제 */
  deleteImportLicensingDocument: DtoReturnImportLicensingDocument;
  /** 수입 허가 견적 삭제 */
  deleteImportLicensingEstimate: DtoReturnImportLicensingEstimate;
  /** 수입 허가 템플릿 삭제 */
  deleteImportLicensingTemplate: DtoReturnImportLicensingTemplate;
  /** 파트너 유저 수정 by 관리자 */
  deletePartnerUser: DtoReturnUser;
  /** product 옵션 삭제 */
  deleteProductOption: DtoReturnProductOption;
  /** [nestjs query 변경] deleteProductRequestEntity 에서 변경됨, TEMPORARY, REJECTION 상태의 요청을 삭제 시 사용됨 */
  deleteProductRequest: DtoReturnProductRequest;
  /** 위탁 정산기준가 삭제하기 */
  deleteSettlementCriteriaConsignment: DtoReturnSettlementCriteriaConsignment;
  /** 데모 계정 연동 */
  demo: DtoReturnLogin;
  /** 파트너 연결 해지 */
  disconnectB2bOrderContract: DtoReturnB2bOrderContract;
  /** CI/PL 다운로드 */
  downloadCIPL: Scalars['String'];
  /** 수입 허가 서류 단건 다운로드 */
  downloadImportLicensingDocumentFile: Scalars['String'];
  /** Shipping Mark 다운로드 */
  downloadShippingMark: Scalars['String'];
  /** 주문 상세 엑셀 추출 */
  exportB2bOrderDetailExcel: Scalars['String'];
  /** 브랜드 목록 엑셀 추출 */
  exportBrandsV2: Scalars['String'];
  /** 파트너 목록 엑셀 추출 */
  exportCompaniesV2: Scalars['String'];
  /** 수입 허가 견적 엑셀 추출 */
  exportExcelImportLicensingEstimate: Scalars['String'];
  /** 위탁 정산서 파트너 엑셀 추출 */
  exportExcelSettlementConsignmentPartner: Scalars['String'];
  /** 위탁 정산서 파트너 엑셀 추출 by 파트너 */
  exportExcelSettlementConsignmentPartnerByPartner: Scalars['String'];
  /** 위탁 월별 정산 엑셀 추출 */
  exportExcelSettlementConsignmentPartners: Scalars['String'];
  /** 판매분 사입 파트너(정산서) 엑셀 추출 by 파트너 */
  exportExcelSettlementPartnerByPartner: Scalars['String'];
  /** 판매분 사입 파트너(정산서) 엑셀 추출 */
  exportExcelSettlementPartnerV2: Scalars['String'];
  /** 판매분 사입 월별 정산 엑셀 추출 */
  exportExcelSettlementPartners: Scalars['String'];
  /** 브랜드 목록 엑셀 추출 (파트너용) */
  exportPartnerBrandList: Scalars['String'];
  /** 상품 목록 엑셀 파일 내보내기 */
  exportProductsV2: Scalars['String'];
  /** google 로그인 */
  googleLoginV2: DtoReturnLogin;
  /** 수출 불러오기 */
  loadExport: DtoReturnExport;
  /** 로그인 */
  loginV3: DtoReturnLogin;
  /** 파트너 로그인 */
  partnerLoginV3: DtoReturnLogin;
  /** 인증 토큰 리프레시 */
  refreshTokenV2: DtoReturnRefreshToken;
  /** 파트너 회원가입 */
  register: DtoReturnUser;
  /** 주문 */
  registerB2bOrder: DtoReturnB2bOrder;
  /** 리포트 엑셀 추출 - (판매 현황, 날짜별 판매 현황, 상품별 판매 금액, 상품별 판매 현황, 주문 상세) */
  reportExportExcelV2: Scalars['String'];
  /** 주문 확인 요청 */
  requestConfirmB2bOrder: DtoReturnB2bOrder;
  /** 수입 허가 단계 별 확인 요청 */
  requestConfirmationImportLicensing: Scalars['Boolean'];
  /** 수입 허가 단계 별 수정 요청 */
  requestEditImportLicensing: Scalars['Boolean'];
  /** 수입 허가 세금 계산서 요청 */
  requestImportLicensingTaxBill: Scalars['Boolean'];
  /** 파트너 비밀번호 변경 이메일 전송 */
  sendPartnerPasswordChangeEmailV2?: Maybe<Scalars['Boolean']>;
  /** 비밀번호 변경 이메일 전송 */
  sendPasswordChangeEmailV2?: Maybe<Scalars['Boolean']>;
  /** 위탁 정산서 발송 */
  sendSettlementToConsignmentPartnerV2: Scalars['Boolean'];
  /** 판매분 사입 정산서 발송 */
  sendSettlementToPartnerV2: Scalars['Boolean'];
  sendSlack: Scalars['Boolean'];
  /** 재고 목록 엑셀 추출 */
  stockExportExcel: Scalars['String'];
  /** 재고 입,출고 엑셀 추출 */
  stockLogsExportExcel: Scalars['String'];
  /** 구매자 입금 확인서 업로드 */
  updateB2bOrderBuyerBankDepositConfirmation: DtoReturnB2bOrder;
  /** 주문 도착 예정일 설정 */
  updateB2bOrderByEstimatedArrivalDate: DtoReturnB2bOrder;
  /** 발주 거래 구성 수정 */
  updateB2bOrderContractConfig: DtoReturnB2bOrderContractConfig;
  /** 판매자 입금 확인서 업로드 */
  updateB2bOrderSellerBankDepositConfirmation: DtoReturnB2bOrder;
  /** 브랜드 요청 수정 */
  updateBrandRequest: DtoReturnBrandRequest;
  /** 브랜드 요청 업데이트 (검수자용) / 임시저장 -> 완료, 검수요청 -> 완료 or 반려, 반려 -> 임시저장 or 완료 상태로 변경시에 사용합니다. */
  updateBrandRequestByInspectorV2: DtoReturnBrandRequest;
  /** 브랜드 요청 업데이트 (편집자용) / 반려상태에서 임시저장, 요청 상태로 변경시에 사용합니다. */
  updateBrandRequestByRequesterV2: DtoReturnBrandRequest;
  /** 파트너 수정 */
  updateCompany: DtoReturnCompany;
  /** 파트너 요청 수정 */
  updateCompanyRequest: DtoReturnCompanyRequest;
  /** 파트너 요청 업데이트 (검수자용) / 임시저장 -> 완료, 검수요청 -> 완료 or 반려, 반려 -> 임시저장 or 완료 상태로 변경시에 사용합니다. */
  updateCompanyRequestByInspectorV2: DtoReturnCompanyRequest;
  /** 파트너 요청 업데이트 (편집자용) / 반려상태 -> 임시저장 or 요청 상태로 변경시에 사용합니다. */
  updateCompanyRequestByRequesterV2: DtoReturnCompanyRequest;
  /** 주문 비활성화 */
  updateDisableB2bOrder: DtoReturnB2bOrder;
  /** 주문 최종 완료 */
  updateDoneB2bOrder: DtoReturnB2bOrder;
  /** 수출 수정하기 */
  updateExport: DtoReturnExport;
  /** 수출 상품 수정하기 */
  updateExportProduct: Scalars['Boolean'];
  /** 고미 유저 수정 */
  updateGomiUser: DtoReturnGomiUser;
  /** 수입 허가 등록 수정 */
  updateImportLicensing: DtoReturnImportLicensing;
  /** 수입 허가 파일 comment 수정 */
  updateImportLicensingComment: DtoReturnImportLicensingComment;
  /** 수입 허가 컨텐츠 수정 */
  updateImportLicensingContent: DtoReturnImportLicensingContent;
  /** 수입 허가 컨텐츠 수정 */
  updateImportLicensingDocument: DtoReturnImportLicensingDocument;
  /** 수입 허가 견적 수정 */
  updateImportLicensingEstimate: DtoReturnImportLicensingEstimate;
  /** 수입 허가 템플릿 수정 */
  updateImportLicensingTemplate: DtoReturnImportLicensingTemplate;
  /** 파트너 유저 수정 by 관리자 */
  updatePartnerUser: DtoReturnUser;
  /** 상품 수정 */
  updateProduct: DtoReturnProductV2;
  /** product Group 수정 */
  updateProductGroup: DtoReturnProductGroup;
  /** 편집자 검수 요청 수정 */
  updateProductRequestByInspector: DtoReturnProductRequest;
  /** 검수자 검수 요청 수정 */
  updateProductRequestByRequester: DtoReturnProductRequest;
  /** 위탁 정산기준가 수정하기 */
  updateSettlementCriteriaConsignment: DtoReturnSettlementCriteriaConsignment;
  /** 위탁 정산기준가 수정하기 */
  updateSettlementCriteriaPurchaseOfSales: DtoReturnSettlementCriteriaPurchaseOfSales;
  /** 주문 상세 엑셀 업로드 */
  uploadB2bOrderDetails: Scalars['Boolean'];
  /** 브랜드 등록 요청 엑셀 벌크 업로드 */
  uploadBrandRequests: Scalars['Boolean'];
  /** 파트너 등록요청 엑셀 벌크 업로드 */
  uploadCompanyRequests: Scalars['Boolean'];
  /** 최대 10개의 이미지, PDF 업로드(S3에 업로드 후, 파일 정보를 반환합니다.) */
  uploadFilesToS3: Array<DtoReturnFileInfo>;
  uploadOrderShippingInfo: Scalars['Boolean'];
  /** 상품 관리 기본정보 벌크 업로드 */
  uploadProductRequestsByBasic: Scalars['Boolean'];
  /** 상품 관리 - 물류, 가격, 상세 정보 벌크 업로드 */
  uploadProductRequestsByLogisticsOrPriceOrDetail: Scalars['Boolean'];
  /** 이미지, PDF 업로드(S3에 업로드 후, 파일 정보를 반환합니다.) */
  uploadToS3V2: DtoReturnFileInfo;
};

export type MutationAddExportProductArgs = {
  exportId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type MutationCancelOrderDetailArgs = {
  id: Scalars['ID'];
};

export type MutationChangePartnerPasswordV2Args = {
  authCode: Scalars['String'];
  password: Scalars['String'];
};

export type MutationChangePasswordV2Args = {
  authCode: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCompleteConfirmationImportLicensingArgs = {
  input: DtoRequestEditImportLicensingInput;
};

export type MutationCompleteConfirmationImportLicensingByPartnerArgs = {
  input: DtoRequestEditImportLicensingInput;
};

export type MutationConfirmB2bOrderByBuyerArgs = {
  id: Scalars['ID'];
};

export type MutationConfirmB2bOrderBySellerArgs = {
  input: DtoConfirmB2bOrderInput;
};

export type MutationConfirmFileCommentArgs = {
  id: Scalars['ID'];
};

export type MutationCreateB2bOrderArgs = {
  input: DtoCreateB2bOrderInput;
};

export type MutationCreateB2bOrderContractArgs = {
  input: DtoCreateB2bOrderContractInput;
};

export type MutationCreateB2bOrderContractConfigArgs = {
  input: DtoCreateB2bOrderContractConfigInput;
};

export type MutationCreateBrandRequestArgs = {
  input: DtoCreateBrandRequest;
};

export type MutationCreateBrandRequestToDoneByInspectorV2Args = {
  input: DtoCreateBrandRequest;
};

export type MutationCreateCompanyRequestArgs = {
  input: DtoCreateCompanyRequest;
};

export type MutationCreateCompanyRequestByExternalArgs = {
  input: DtoCreateCompanyRequestByExternal;
};

export type MutationCreateCompanyRequestToDoneByInspectorV2Args = {
  input: DtoCreateCompanyRequestByInspector;
};

export type MutationCreateExportArgs = {
  input: DtoCreateExport;
};

export type MutationCreateExportAgencyArgs = {
  companyId: Scalars['ID'];
};

export type MutationCreateGomiUserV2Args = {
  input: DtoCreateGomiUserInput;
};

export type MutationCreateImportAgencyArgs = {
  companyId: Scalars['ID'];
};

export type MutationCreateImportLicensingArgs = {
  input: DtoCreateImportLicensingInput;
};

export type MutationCreateImportLicensingCommentArgs = {
  input: DtoCreateImportLicensingCommentInput;
};

export type MutationCreateImportLicensingContentArgs = {
  input: DtoCreateImportLicensingContentInput;
};

export type MutationCreateImportLicensingEstimateArgs = {
  input: DtoCreateImportLicensingEstimateInput;
};

export type MutationCreateImportLicensingExtraDocumentArgs = {
  input: DtoCreateImportLicensingExtraDocumentInput;
};

export type MutationCreateImportLicensingFileUploadArgs = {
  input: DtoCreateImportLicensingFileUploadInput;
};

export type MutationCreateImportLicensingTemplateArgs = {
  input: DtoCreateImportLicensingTemplateInput;
};

export type MutationCreatePartnerUserArgs = {
  input: DtoCreateUser;
};

export type MutationCreateProductGroupArgs = {
  input: DtoCreateProductGroup;
};

export type MutationCreateProductOptionsArgs = {
  input: DtoCreateProductOption;
};

export type MutationCreateProductRequestByInspectorArgs = {
  input: DtoCreateProductRequestsByInspector;
};

export type MutationCreateProductRequestByRequesterArgs = {
  input: DtoCreateProductRequestByRequester;
};

export type MutationCreateProductRequestsByInspectorArgs = {
  input: DtoCreateProductRequestsByInspector;
};

export type MutationCreateProductRequestsByRequesterArgs = {
  input: DtoCreateProductRequestsByRequester;
};

export type MutationCreateSettlementCriteriaConsignmentArgs = {
  input: DtoCreateSettlementCriteriaConsignment;
};

export type MutationDeleteB2bOrderContractConfigArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBrandRequestArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCompanyRequestArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteGomiUserV2Args = {
  id: Scalars['ID'];
};

export type MutationDeleteImportLicensingCommentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteImportLicensingContentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteImportLicensingDocumentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteImportLicensingEstimateArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteImportLicensingTemplateArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePartnerUserArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProductOptionArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProductRequestArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteSettlementCriteriaConsignmentArgs = {
  id: Scalars['ID'];
};

export type MutationDisconnectB2bOrderContractArgs = {
  id: Scalars['ID'];
};

export type MutationDownloadCiplArgs = {
  exportId: Scalars['ID'];
};

export type MutationDownloadImportLicensingDocumentFileArgs = {
  id: Scalars['ID'];
};

export type MutationDownloadShippingMarkArgs = {
  exportId: Scalars['ID'];
};

export type MutationExportB2bOrderDetailExcelArgs = {
  id: Scalars['ID'];
};

export type MutationExportBrandsV2Args = {
  input: DtoFindBrands;
};

export type MutationExportCompaniesV2Args = {
  input: DtoFindCompanies;
};

export type MutationExportExcelImportLicensingEstimateArgs = {
  importLicenseId: Scalars['ID'];
};

export type MutationExportExcelSettlementConsignmentPartnerArgs = {
  input: DtoGetSettlementConsignmentPartner;
};

export type MutationExportExcelSettlementConsignmentPartnerByPartnerArgs = {
  input: DtoGetSettlementConsignmentPartner;
};

export type MutationExportExcelSettlementConsignmentPartnersArgs = {
  input: DtoGetSettlementConsignmentMonthlyInput;
};

export type MutationExportExcelSettlementPartnerByPartnerArgs = {
  input: DtoGetSettlementPartner;
};

export type MutationExportExcelSettlementPartnerV2Args = {
  input: DtoGetSettlementPartner;
};

export type MutationExportExcelSettlementPartnersArgs = {
  input: DtoGetSettlementPartners;
};

export type MutationExportPartnerBrandListArgs = {
  input: DtoFindBrands;
};

export type MutationExportProductsV2Args = {
  input: DtoFindProducts;
};

export type MutationGoogleLoginV2Args = {
  input: DtoGetGoogleLogin;
};

export type MutationLoadExportArgs = {
  input: DtoLoadExport;
};

export type MutationLoginV3Args = {
  input: DtoGetLogin;
};

export type MutationPartnerLoginV3Args = {
  input: DtoGetLogin;
};

export type MutationRefreshTokenV2Args = {
  input: DtoGetRefreshToken;
};

export type MutationRegisterArgs = {
  input: DtoRegisterUser;
};

export type MutationRegisterB2bOrderArgs = {
  input: DtoRegisterB2bOrderInput;
};

export type MutationReportExportExcelV2Args = {
  input: DtoExportOrderSalesInfoExcel;
};

export type MutationRequestConfirmB2bOrderArgs = {
  id: Scalars['ID'];
};

export type MutationRequestConfirmationImportLicensingArgs = {
  input: DtoRequestEditImportLicensingInput;
};

export type MutationRequestEditImportLicensingArgs = {
  input: DtoRequestEditImportLicensingInput;
};

export type MutationRequestImportLicensingTaxBillArgs = {
  input: DtoRequestTaxBillInput;
};

export type MutationSendPartnerPasswordChangeEmailV2Args = {
  email: Scalars['String'];
};

export type MutationSendPasswordChangeEmailV2Args = {
  email: Scalars['String'];
};

export type MutationSendSettlementToConsignmentPartnerV2Args = {
  input: DtoSendSettlementConsignmentPartner;
};

export type MutationSendSettlementToPartnerV2Args = {
  input: DtoSendSettlementPartner;
};

export type MutationSendSlackArgs = {
  input: SlackDto;
};

export type MutationStockExportExcelArgs = {
  input: GetStockInputDto;
};

export type MutationStockLogsExportExcelArgs = {
  input: GetStockLogInputDto;
};

export type MutationUpdateB2bOrderBuyerBankDepositConfirmationArgs = {
  input: DtoUpdateB2bOrderBankDepositInput;
};

export type MutationUpdateB2bOrderByEstimatedArrivalDateArgs = {
  input: DtoUpdateB2bOrderEstimatedArrivalDateInput;
};

export type MutationUpdateB2bOrderContractConfigArgs = {
  input: DtoUpdateB2bOrderContractConfigInput;
};

export type MutationUpdateB2bOrderSellerBankDepositConfirmationArgs = {
  input: DtoUpdateB2bOrderBankDepositInput;
};

export type MutationUpdateBrandRequestArgs = {
  input: DtoUpdateBrandRequest;
};

export type MutationUpdateBrandRequestByInspectorV2Args = {
  input: DtoUpdateBrandRequest;
};

export type MutationUpdateBrandRequestByRequesterV2Args = {
  input: DtoUpdateBrandRequest;
};

export type MutationUpdateCompanyArgs = {
  input: DtoUpdateCompany;
};

export type MutationUpdateCompanyRequestArgs = {
  input: DtoUpdateCompanyRequest;
};

export type MutationUpdateCompanyRequestByInspectorV2Args = {
  input: DtoUpdateCompanyRequestByInspector;
};

export type MutationUpdateCompanyRequestByRequesterV2Args = {
  input: DtoUpdateCompanyRequestByRequester;
};

export type MutationUpdateDisableB2bOrderArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateDoneB2bOrderArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateExportArgs = {
  input: DtoUpdateExport;
};

export type MutationUpdateExportProductArgs = {
  input: DtoUpdateProductExport;
};

export type MutationUpdateGomiUserArgs = {
  input: DtoUpdateGomiUserInput;
};

export type MutationUpdateImportLicensingArgs = {
  input: DtoUpdateImportLicensingInput;
};

export type MutationUpdateImportLicensingCommentArgs = {
  input: DtoUpdateImportLicensingCommentInput;
};

export type MutationUpdateImportLicensingContentArgs = {
  input: DtoUpdateImportLicensingContentInput;
};

export type MutationUpdateImportLicensingDocumentArgs = {
  input: DtoUpdateImportLicensingDocumentInput;
};

export type MutationUpdateImportLicensingEstimateArgs = {
  input: DtoUpdateImportLicensingEstimateInput;
};

export type MutationUpdateImportLicensingTemplateArgs = {
  input: DtoUpdateImportLicensingTemplateInput;
};

export type MutationUpdatePartnerUserArgs = {
  input: DtoUpdateUser;
};

export type MutationUpdateProductArgs = {
  input: DtoUpdateProduct;
};

export type MutationUpdateProductGroupArgs = {
  input: DtoUpdateProductGroup;
};

export type MutationUpdateProductRequestByInspectorArgs = {
  input: DtoUpdateProductRequestByInspectorV2;
};

export type MutationUpdateProductRequestByRequesterArgs = {
  input: DtoUpdateProductRequestByRequesterV2;
};

export type MutationUpdateSettlementCriteriaConsignmentArgs = {
  input: DtoUpdateSettlementCriteriaConsignment;
};

export type MutationUpdateSettlementCriteriaPurchaseOfSalesArgs = {
  input: DtoUpdateSettlementCriteriaPurchaseOfSales;
};

export type MutationUploadB2bOrderDetailsArgs = {
  file: Scalars['Upload'];
  id: Scalars['ID'];
};

export type MutationUploadBrandRequestsArgs = {
  file: Scalars['Upload'];
};

export type MutationUploadCompanyRequestsArgs = {
  file: Scalars['Upload'];
};

export type MutationUploadFilesToS3Args = {
  files: Array<Scalars['Upload']>;
  type: FilePathEnum;
};

export type MutationUploadOrderShippingInfoArgs = {
  file: Scalars['Upload'];
};

export type MutationUploadProductRequestsByBasicArgs = {
  file: Scalars['Upload'];
};

export type MutationUploadProductRequestsByLogisticsOrPriceOrDetailArgs = {
  file: Scalars['Upload'];
  input: BulkUploadInputDto;
};

export type MutationUploadToS3V2Args = {
  file: Scalars['Upload'];
  type: FilePathEnum;
};

export enum OrderChannelEnum {
  Etc = 'ETC',
  Gomi = 'GOMI',
  Lazada = 'LAZADA',
  LazadaBrand = 'LAZADA_BRAND',
  Mall = 'MALL',
  Sendo = 'SENDO',
  SendoBrand = 'SENDO_BRAND',
  Shopee = 'SHOPEE',
  ShopeeBrand = 'SHOPEE_BRAND',
  Tiki = 'TIKI',
  TikiBrand = 'TIKI_BRAND',
  Tiktok = 'TIKTOK',
  TiktokBrand = 'TIKTOK_BRAND',
}

export type OrderCountPriceType = {
  __typename?: 'OrderCountPriceType';
  /** 주문 수량 */
  count: Scalars['Int'];
  /** 금액 */
  totalPrice: Scalars['Int'];
};

export type OrderItemBrandType = {
  __typename?: 'OrderItemBrandType';
  /** 브랜드 국문명 */
  brandKrName: Scalars['String'];
  /** 브랜드 명 */
  brandName: Scalars['String'];
  /** 브랜드 id */
  company?: Maybe<OrderItemCompanyType>;
  /** 브랜드 id */
  id: Scalars['ID'];
};

export type OrderItemCompanyType = {
  __typename?: 'OrderItemCompanyType';
  /** 파트너 국문명 */
  companyKrName: Scalars['String'];
  /** 파트너 명 */
  companyName: Scalars['String'];
  /** 파트너 id */
  id: Scalars['ID'];
};

export type OrderItemSummaryDto = {
  __typename?: 'OrderItemSummaryDto';
  /** 총 판매금액 */
  totalCount: Scalars['Int'];
  /** 총 판매금액 */
  totalPrice: Scalars['Float'];
};

export enum OrderPaymentStatusEnum {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  Refund = 'REFUND',
}

export enum OrderShippingStatusEnum {
  Cancel = 'CANCEL',
  Delivered = 'DELIVERED',
  Delivering = 'DELIVERING',
  Readytopick = 'READYTOPICK',
  Return = 'RETURN',
  WaitingForReturn = 'WAITING_FOR_RETURN',
}

export type OrderStatusType = {
  __typename?: 'OrderStatusType';
  /** 주문 수량, 금액 */
  orderCountPrice: OrderCountPriceType;
  /** 가격 */
  orderStatus: SettlementOrderStatus;
};

export type PaginationDto = {
  /** Limit */
  limit: Scalars['Float'];
  /** Offset */
  offset: Scalars['Float'];
};

export type PriceType = {
  __typename?: 'PriceType';
  /** 채널 */
  channels: Array<ChannelType>;
  /** 가격 */
  price: Scalars['Int'];
};

export type ProductAmountType = {
  __typename?: 'ProductAmountType';
  /** 팬매 채널 */
  channels: Array<SalesAmountChannelType>;
  /** 상품명 */
  productName: Scalars['String'];
};

export type ProductOptionGroupType = {
  __typename?: 'ProductOptionGroupType';
  /** 옵션 그룹 ID */
  id: Scalars['ID'];
  /** 옵션 그룹 명 */
  productOptionGroupName: Scalars['String'];
  /** 옵션 목록 */
  productOptions: Array<ProductOptionType>;
};

export type ProductOptionType = {
  __typename?: 'ProductOptionType';
  /** 삭제 여부 */
  deletable: Scalars['Boolean'];
  /** 옵션 ID */
  id: Scalars['ID'];
  /** 옵션 명 */
  productOptionName: Scalars['String'];
};

export enum ProductRequestTypeEnum {
  Basic = 'BASIC',
  Detail = 'DETAIL',
  Logistics = 'LOGISTICS',
  Price = 'PRICE',
}

export type Query = {
  __typename?: 'Query';
  /** 전체 채널 목록 */
  allChannels: Array<DtoReturnChannel>;
  /** 주문 조회 */
  b2bOrder: DtoReturnB2bOrder;
  /** 파트너 연결 목록 조회 */
  b2bOrderContract: DtoReturnB2bOrderContract;
  /** 발주 거래구성 목록 조회 */
  b2bOrderContractConfigList: DtoReturnB2bOrderContractConfigList;
  /** 파트너 연결 목록 조회 */
  b2bOrderContractList: DtoReturnB2bOrderContractList;
  /** 파트너 연결 전체 조회 */
  b2bOrderContracts: Array<DtoReturnB2bOrderContract>;
  /** 주문 목록 조회 */
  b2bOrderList: DtoReturnB2bOrderList;
  /** 주문 관리 요약 */
  b2bOrderSummary: DtoReturnB2bOrderSummary;
  /** 기본 기준 위탁 가져오기 */
  basicCriteriaConsignment: Array<DtoReturnBasicCriteriaConsignment>;
  /** 기본 기준 판매분 사입 가져오기 */
  basicCriteriaPurchaseOfSales?: Maybe<DtoReturnBasicCriteriaPurchaseOfSales>;
  /** 브랜드 단건 조회 */
  brand: DtoReturnBrand;
  /** 브랜드 목록 조회 */
  brandList: DtoReturnBrandList;
  /** 브랜드 요청 목록 조회 */
  brandRequestList: DtoReturnBrandRequestList;
  /** 브랜드 요청 단건 조회 */
  brandRequestV2: DtoReturnBrandRequest;
  /**
   * 전체 채널 목록
   * @deprecated allChannels로 대체
   */
  channels: Array<DtoReturnChannel>;
  /** 리포트 채널 정보 */
  channelsForReport: Array<DtoReturnChannelsForReport>;
  /** 파트너용 파트너 단건 조회 */
  companyByPartner: DtoReturnCompany;
  /** 파트너 단건 조회 */
  companyGroups: Array<DtoReturnCompanyGroup>;
  /** 파트너 리스트 조회 */
  companyList: DtoReturnCompanyList;
  /** 검수요청 조회 */
  companyRequestList: DtoReturnCompanyRequestList;
  /** 검수요청 단건 조회 */
  companyRequestV2: DtoReturnCompanyRequest;
  /** 파트너 단건 조회 */
  companyV2: DtoReturnCompany;
  /** 국가 목록 */
  countriesV2: Array<DtoReturnCountry>;
  /** 회사가 진출한 국가 목록 */
  countryOfEntryV2: Array<DtoReturnCountry>;
  /** 국가 단건 */
  countryV2?: Maybe<DtoReturnCountry>;
  /** 국가 목록 */
  departmentsV2: Array<DtoReturnDepartment>;
  /** 에러 코드 */
  errorCodes: ErrorCodeEnum;
  /** 파트너 유저 이메일 중복 체크 */
  existGomiUserEmail: Scalars['Boolean'];
  /** 파트너 유저 ERP 코드 중복 체크 */
  existGomiUserErpUserCode: Scalars['Boolean'];
  /** 파트너 유저 이메일 중복 체크 */
  existPartnerUserEmail: Scalars['Boolean'];
  /** 수출 관리 목록 조회 */
  export: DtoReturnExport;
  /** 수출 대리인 목록 조회 */
  exportAgencies: Array<DtoExportAgency>;
  /** 수출 관리 목록 조회 */
  exportList: DtoExportList;
  /** 수출 관리 목록 조회 */
  exportProduct: DtoExportProduct;
  /** 수출 관리 목록 조회 */
  exportProductList: DtoExportProductList;
  /** 파일 정보 목록 */
  fileInfoList: Array<DtoReturnFileInfo>;
  /** 상품 임시 저장 및 검수 요청, 반려, 완료 등이 존재하는 브랜드 목록 */
  filteredBrandsByProductRequestV2: Array<DtoReturnBrand>;
  /** SKU가 존재하는 브랜드 목록 */
  filteredBrandsByProductV2: Array<DtoReturnBrand>;
  /** 브랜드 정보 관리 시스템 담당자 필터 목록 */
  filteredManagersByBrand: Array<DtoReturnBrandManager>;
  /** 브랜드 검수 시스템 담당자 필터 목록 */
  filteredManagersByBrandRequest: Array<DtoReturnBrandRequestManager>;
  /** 파트너 정보 관리 시스템 담당자 필터 목록 */
  filteredManagersByCompany: Array<DtoReturnCompanyManager>;
  /** 파트너 정보 관리 시스템 담당자 필터 목록 */
  filteredManagersByCompanyRequest: Array<DtoReturnCompanyRequestManager>;
  /** 고미 사용자 정보 조회 */
  gomiMeV2: DtoReturnGomiUser;
  /** 고미 사용자 조회 */
  gomiUser: DtoReturnGomiUser;
  /** 고미 사용자 목록 조회 */
  gomiUserList: DtoReturnPaginatedGomiUser;
  /** 고미 사용자 목록 전체 조회 */
  gomiUsers: Array<DtoReturnGomiUser>;
  /** 홈 > 총 매출액 화면 */
  homeSummary: DtoReturnHomeSummary;
  /** 수입 대리인 목록 조회 */
  importAgencies: Array<DtoImportAgency>;
  /** 수입 허가 시트 단건 조회 */
  importLicensing: DtoReturnImportLicensing;
  /** 수입 허가 코멘트 목록 */
  importLicensingCommentList: DtoReturnImportLicensingCommentList;
  /** 수입 허가 컨텐츠 */
  importLicensingContent: DtoReturnImportLicensingContent;
  /** 수입 허가 컨텐츠 목록 */
  importLicensingContentList: DtoReturnImportLicensingContentList;
  /** 수입 허가 서류 단일 조회 */
  importLicensingDocument: DtoReturnImportLicensingDocument;
  /** 수입 허가 견적서 목록 조회 */
  importLicensingLicensingEstimateList: DtoReturnImportLicensingEstimateList;
  /** 수입 허가 시트 목록 */
  importLicensingList: DtoReturnImportLicensingList;
  /** 수입 허가 원본이 필요한 서류 조회 */
  importLicensingRequiredOriginalDocuments: DtoReturnImportLicensingRequireOriginalDocumentList;
  /** 수입 허가 템플릿 단건 */
  importLicensingTemplate: DtoReturnImportLicensingTemplate;
  /** 수입 허가 템플릿 목록 */
  importLicensingTemplateList: DtoReturnImportLicensingTemplateList;
  /** 월 평균 환율 */
  monthlyExchangeRate: ReturnExchangeRateDto;
  /** 주문 상세정보 V2 */
  orderDetails: DtoReturnPaginatedOrderItem;
  /** (파트너용) 브랜드 단건 조회 */
  partnerBrand: DtoReturnBrand;
  /** 브랜드 목록 (파트너용) */
  partnerBrandsV2: DtoReturnBrandList;
  /** 현재 로그인한 파트너 유저 정보 조회 */
  partnerMe: DtoReturnUser;
  /** 단일 파트너 유저 가져오기 */
  partnerUser: DtoReturnUser;
  /** 파트너 유저 목록 가져오기 */
  partnerUsers: DtoReturnPaginatedUser;
  /** 결제 상태 */
  paymentStatusCount: DtoReturnOrderPaymentStatusCount;
  /** [nestjs query 대체] ProductEntity 쿼리에서 변경 */
  product: DtoReturnProductV2;
  /** 상품 카테고리 목록 */
  productCategoryList: DtoReturnProductCategoryList;
  /** 단일 상품 그룹 조회 */
  productGroup: DtoReturnProductGroup;
  /** 복수 상품 그룹 조회 */
  productGroups: Array<DtoReturnProductGroup>;
  /** [nestjs query 대체] ProductEntities 쿼리에서 변경 */
  productList: DtoReturnProductList;
  /** [nestjs query 변경] productRequestEntity에서 변경됨 */
  productRequest: DtoReturnProductRequest;
  /** [nestjs query 변경] productRequestEntities에서 변경됨 */
  productRequestList: DtoReturnProductRequestList;
  /** 상품별 판매금액 */
  salesAmountByProduct: DtoReturnOrderSalesAmountByProduct;
  /** Sales Code 목록 */
  salesCodes: Array<ReturnSalesCodesOutputDto>;
  /** 홈 > 채널별 판매 비율 */
  salesRatioByChannel: Array<DtoReturnHomeChannel>;
  /** 판매현황 */
  salesStatus: Array<DtoReturnOrderSalesStatus>;
  /** 날짜별 판매현황 */
  salesStatusByDate: DtoReturnOrderSalesStatusByDate;
  /** 상품별 판매현황 */
  salesStatusByProduct: DtoReturnOrderSalesStatusByProduct;
  /** 주문 상태 */
  salesStatusCount: DtoReturnOrderSalesStatusCount;
  /** 위탁 정산서 채널별 수수료 정산내역 */
  settlementConsignmentPartnerFeeSummary: DtoReturnSettlementConsignmentPartnerFeeSummary;
  /** 위탁 정산서 채널별 수수료 정산내역 by 파트너 */
  settlementConsignmentPartnerFeeSummaryByPartner: DtoReturnSettlementConsignmentPartnerFeeSummary;
  /** 위탁 정산서 상품 리스트 */
  settlementConsignmentPartnerProductList: DtoReturnSettlementCriteriaPartnerProductList;
  /** 파트너 위탁 정산서 상품 리스트 */
  settlementConsignmentPartnerProductListByPartner: DtoReturnSettlementCriteriaPartnerProductList;
  /** 위탁 정산서 채널별 매출금액 정산내역 */
  settlementConsignmentPartnerSalesPriceSummary: DtoReturnSettlementConsignmentPartnerSalesPriceSummary;
  /** 위탁 정산서 채널별 매출금액 정산내역 by 파트너 */
  settlementConsignmentPartnerSalesPriceSummaryByPartner: DtoReturnSettlementConsignmentPartnerSalesPriceSummary;
  /** 위탁 정산서 전체 정산내역 */
  settlementConsignmentPartnerSummary: DtoReturnSettlementConsignmentPartnerSummaryDto;
  /** 위탁 정산서 전체 정산내역 by 파트너 */
  settlementConsignmentPartnerSummaryByPartner: DtoReturnSettlementConsignmentPartnerSummaryDto;
  /** 위탁 월별 정산 파트너 목록 */
  settlementConsignmentPartners: Array<DtoReturnSettlementConsignmentPartner>;
  /** 위탁 정산서 상세 내역 */
  settlementConsignmentProductOrders: Array<DtoReturnSettlementConsignmentProductOrders>;
  /** 위탁 정산서 상세 내역 by 파트너 */
  settlementConsignmentProductOrdersByPartner: Array<DtoReturnSettlementConsignmentProductOrders>;
  /** 위탁 정산기준가 단건 가져오기 */
  settlementCriteriaConsignment: DtoReturnSettlementCriteriaConsignment;
  /** 위탁 정산기준가 목록 가져오기 */
  settlementCriteriaConsignmentList: Array<DtoReturnSettlementCriteriaConsignment>;
  /** 판매분사입 정산기준가 단건 가져오기 */
  settlementCriteriaPurchaseOfSales: DtoReturnSettlementCriteriaPurchaseOfSales;
  /** 판매분사입 정산기준가 목록 가져오기 */
  settlementCriteriaPurchaseOfSalesList: Array<DtoReturnSettlementCriteriaPurchaseOfSales>;
  /** 이전에 정산서를 발송한 이메일 목록 */
  settlementCurrentEmails: DtoReturnSettlementEmailHistories;
  /** 정산 연월 목록 */
  settlementMonthliesV2: Array<DtoReturnSettlementMonthlies>;
  /** 정산 연월 요약 */
  settlementMonthlySummary: DtoReturnSettlementMonthly;
  /** 판매분 사입 정산서 상품 주문 내역 (정산, 상계) */
  settlementOrders: Array<DtoReturnSettlementProductOrders>;
  /** 판매분 사입 정산서 상품 주문 내역 (정산, 상계) by 파트너 */
  settlementOrdersByPartner: Array<DtoReturnSettlementProductOrders>;
  /** 정산서 전송 이메일 조회 */
  settlementPartnerEmailReceivers: DtoReturnSettlementPartnerEmailReceivers;
  /** 정산 파트너 조회 */
  settlementPartnerV2: DtoReturnSettlementPartners;
  /** 월별 정산 요약 */
  settlementPartnersSummary: DtoReturnSettlementSummary;
  /** 판매분 사입 월별 정산 목록 */
  settlementPartnersV2: Array<DtoReturnSettlementPartners>;
  /** 판매분 사입 정산서 아이템 목록 (정산, 상계) */
  settlementProductList: DtoReturnSettlementProductList;
  /** 판매분 사입 정산서 아이템 목록 (정산, 상계) by 파트너 */
  settlementProductListByPartner: DtoReturnSettlementProductList;
  /** 판매분 사입 정산서 아이템 요약 */
  settlementProductSummary: DtoReturnSettlementProductSummary;
  /** 판매분 사입 정산서 아이템 요약 by 파트너 */
  settlementProductSummaryByPartner: DtoReturnSettlementProductSummary;
  /** 배송 상태 */
  shippingStatusCount: DtoReturnOrderSalesShippingStatusCount;
  /** 재고 목록 */
  stockExpiryDates: ReturnPaginatedExpiryDateOutputDto;
  /** 입고,출고 현황 목록 */
  stockLogs: Array<ReturnStockLogOutputDto>;
  /** 재고 목록 */
  stocks: ReturnPaginatedStockOutputDto;
  /** 홈 > 가장 많이 판매 되는 브랜드 */
  topSellingBrands: Array<DtoReturnHomeBrand>;
  /** 홈 > 가장 많이 판매되는 카테고리 화면 */
  topSellingCategories: Array<DtoReturnHomeCategory>;
  /** 홈 > 가장 많이 판매되는 상품 화면 */
  topSellingProducts: Array<DtoReturnHomeProduct>;
};

export type QueryB2bOrderArgs = {
  id: Scalars['ID'];
};

export type QueryB2bOrderContractArgs = {
  id: Scalars['ID'];
};

export type QueryB2bOrderContractConfigListArgs = {
  b2bOrderContractId: Scalars['ID'];
};

export type QueryB2bOrderContractListArgs = {
  input: DtoB2bOrderContractListInput;
};

export type QueryB2bOrderListArgs = {
  input: DtoB2bOrderListInput;
};

export type QueryBasicCriteriaConsignmentArgs = {
  input: DtoInputBasicSettlementCriteriaConsignment;
};

export type QueryBasicCriteriaPurchaseOfSalesArgs = {
  input: DtoInputBasicSettlementCriteriaPurchaseOfSales;
};

export type QueryBrandArgs = {
  id: Scalars['ID'];
};

export type QueryBrandListArgs = {
  input: DtoFindBrands;
};

export type QueryBrandRequestListArgs = {
  input: DtoFindBrandRequests;
};

export type QueryBrandRequestV2Args = {
  id: Scalars['ID'];
};

export type QueryChannelsForReportArgs = {
  input: DtoFindOrderItems;
};

export type QueryCompanyListArgs = {
  input?: InputMaybe<DtoFindCompanies>;
};

export type QueryCompanyRequestListArgs = {
  input: DtoFindCompanyRequests;
};

export type QueryCompanyRequestV2Args = {
  id: Scalars['ID'];
};

export type QueryCompanyV2Args = {
  id: Scalars['ID'];
};

export type QueryCountryV2Args = {
  countryId: Scalars['String'];
};

export type QueryExistGomiUserEmailArgs = {
  email: Scalars['String'];
};

export type QueryExistGomiUserErpUserCodeArgs = {
  erpUserCode: Scalars['String'];
};

export type QueryExistPartnerUserEmailArgs = {
  email: Scalars['String'];
};

export type QueryExportArgs = {
  exportId: Scalars['ID'];
};

export type QueryExportProductArgs = {
  exportProductId: Scalars['ID'];
};

export type QueryExportProductListArgs = {
  exportId: Scalars['ID'];
};

export type QueryFileInfoListArgs = {
  input: DtoFileInfoList;
};

export type QueryGomiUserArgs = {
  id: Scalars['ID'];
};

export type QueryGomiUserListArgs = {
  input: DtoGomiUsers;
};

export type QueryHomeSummaryArgs = {
  input?: InputMaybe<DtoHomeInput>;
};

export type QueryImportLicensingArgs = {
  id: Scalars['ID'];
};

export type QueryImportLicensingCommentListArgs = {
  input: DtoImportLicensingCommentListInput;
};

export type QueryImportLicensingContentArgs = {
  id: Scalars['ID'];
};

export type QueryImportLicensingContentListArgs = {
  input: DtoImportLicensingContentListInput;
};

export type QueryImportLicensingDocumentArgs = {
  id: Scalars['ID'];
};

export type QueryImportLicensingLicensingEstimateListArgs = {
  id: Scalars['ID'];
};

export type QueryImportLicensingListArgs = {
  input: DtoImportLicensingListInput;
};

export type QueryImportLicensingRequiredOriginalDocumentsArgs = {
  importLicenseId: Scalars['ID'];
};

export type QueryImportLicensingTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryImportLicensingTemplateListArgs = {
  input: DtoImportLicensingTemplateListInput;
};

export type QueryMonthlyExchangeRateArgs = {
  input: DtoGetMonthlyExchangeRate;
};

export type QueryOrderDetailsArgs = {
  input: DtoFindOrderItems;
};

export type QueryPartnerBrandArgs = {
  id: Scalars['ID'];
};

export type QueryPartnerBrandsV2Args = {
  input: DtoFindBrands;
};

export type QueryPartnerUserArgs = {
  id: Scalars['ID'];
};

export type QueryPartnerUsersArgs = {
  input: DtoPartnerUsers;
};

export type QueryPaymentStatusCountArgs = {
  input: DtoFindOrderItems;
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProductGroupArgs = {
  id: Scalars['ID'];
};

export type QueryProductGroupsArgs = {
  input: DtoFindProductGroups;
};

export type QueryProductListArgs = {
  input: DtoFindProducts;
};

export type QueryProductRequestArgs = {
  id: Scalars['ID'];
};

export type QueryProductRequestListArgs = {
  input: DtoFindProductRequests;
};

export type QuerySalesAmountByProductArgs = {
  input: DtoFindOrderItems;
};

export type QuerySalesCodesArgs = {
  input: GetSalesCodesInputDto;
};

export type QuerySalesRatioByChannelArgs = {
  input?: InputMaybe<DtoHomeInput>;
};

export type QuerySalesStatusArgs = {
  input: DtoFindOrderItems;
};

export type QuerySalesStatusByDateArgs = {
  input: DtoFindOrderItems;
};

export type QuerySalesStatusByProductArgs = {
  input: DtoFindOrderItems;
};

export type QuerySalesStatusCountArgs = {
  input: DtoFindOrderItems;
};

export type QuerySettlementConsignmentPartnerFeeSummaryArgs = {
  input: DtoGetSettlementConsignmentPartner;
};

export type QuerySettlementConsignmentPartnerFeeSummaryByPartnerArgs = {
  input: DtoGetSettlementConsignmentPartner;
};

export type QuerySettlementConsignmentPartnerProductListArgs = {
  input: DtoGetSettlementConsignmentPartnerProductListInput;
};

export type QuerySettlementConsignmentPartnerProductListByPartnerArgs = {
  input: DtoGetSettlementConsignmentPartnerProductListInput;
};

export type QuerySettlementConsignmentPartnerSalesPriceSummaryArgs = {
  input: DtoGetSettlementConsignmentPartner;
};

export type QuerySettlementConsignmentPartnerSalesPriceSummaryByPartnerArgs = {
  input: DtoGetSettlementConsignmentPartner;
};

export type QuerySettlementConsignmentPartnerSummaryArgs = {
  input: DtoGetSettlementConsignmentPartner;
};

export type QuerySettlementConsignmentPartnerSummaryByPartnerArgs = {
  input: DtoGetSettlementConsignmentPartner;
};

export type QuerySettlementConsignmentPartnersArgs = {
  input: DtoGetSettlementConsignmentMonthlyInput;
};

export type QuerySettlementConsignmentProductOrdersArgs = {
  input: DtoGetSettlementConsignmentProductOrders;
};

export type QuerySettlementConsignmentProductOrdersByPartnerArgs = {
  input: DtoGetSettlementConsignmentProductOrders;
};

export type QuerySettlementCriteriaConsignmentArgs = {
  id: Scalars['ID'];
};

export type QuerySettlementCriteriaConsignmentListArgs = {
  input: DtoInputSettlementCriteriaConsignmentList;
};

export type QuerySettlementCriteriaPurchaseOfSalesArgs = {
  id: Scalars['ID'];
};

export type QuerySettlementCriteriaPurchaseOfSalesListArgs = {
  input: DtoInputSettlementCriteriaPurchaseOfSalesList;
};

export type QuerySettlementCurrentEmailsArgs = {
  input: DtoGetSettlementPartnerCurrentEmails;
};

export type QuerySettlementMonthliesV2Args = {
  input: DtoGetSettlementMonthlies;
};

export type QuerySettlementMonthlySummaryArgs = {
  input: DtoGetSettlementMonthlySummary;
};

export type QuerySettlementOrdersArgs = {
  input: DtoGetSettlementProductOrders;
};

export type QuerySettlementOrdersByPartnerArgs = {
  input: DtoGetSettlementProductOrders;
};

export type QuerySettlementPartnerEmailReceiversArgs = {
  input: DtoGetSettlementPartnerCurrentEmails;
};

export type QuerySettlementPartnerV2Args = {
  input: DtoGetSettlementPartnerId;
};

export type QuerySettlementPartnersSummaryArgs = {
  input: DtoGetSettlementSummary;
};

export type QuerySettlementPartnersV2Args = {
  input: DtoGetSettlementPartners;
};

export type QuerySettlementProductListArgs = {
  input: DtoGetSettlementProductList;
};

export type QuerySettlementProductListByPartnerArgs = {
  input: DtoGetSettlementProductList;
};

export type QuerySettlementProductSummaryArgs = {
  input: DtoGetSettlementProductSummary;
};

export type QuerySettlementProductSummaryByPartnerArgs = {
  input: DtoGetSettlementProductSummary;
};

export type QueryShippingStatusCountArgs = {
  input: DtoFindOrderItems;
};

export type QueryStockExpiryDatesArgs = {
  input: GetStockInputDto;
};

export type QueryStockLogsArgs = {
  input: GetStockLogInputDto;
};

export type QueryStocksArgs = {
  input: GetStockInputDto;
};

export type QueryTopSellingBrandsArgs = {
  input?: InputMaybe<DtoHomeInput>;
};

export type QueryTopSellingCategoriesArgs = {
  input?: InputMaybe<DtoHomeInput>;
};

export type QueryTopSellingProductsArgs = {
  input?: InputMaybe<DtoHomeInput>;
};

export type RegisterB2bOrderDetailInputType = {
  /** 주문 상세 ID */
  id: Scalars['ID'];
  /** 주문 수량 */
  orderQuantity: Scalars['Int'];
};

export type RegisterB2bOrderInputType = {
  /** 주문 상세 */
  orderDetails: Array<RegisterB2bOrderDetailInputType>;
  /** 구매자 배송 주소 */
  receiverAddress: Scalars['String'];
  /** 구매자 연락처 */
  receiverContactInformation: Scalars['String'];
  /** 구매자 수령인 이름 */
  receiverName: Scalars['String'];
};

export enum ReportExportExcelEnum {
  OrderDetails = 'ORDER_DETAILS',
  SalesAmountByProduct = 'SALES_AMOUNT_BY_PRODUCT',
  SalesStatus = 'SALES_STATUS',
  SalesStatusByDate = 'SALES_STATUS_BY_DATE',
  SalesStatusByProduct = 'SALES_STATUS_BY_PRODUCT',
}

export type RequestEditImportLicensingInputType = {
  /** 수입 허가 상태 */
  step?: InputMaybe<ImportLicensingSheetStepEnum>;
};

export type RequestTaxBillInputType = {
  /** 세금 계산서 이메일 */
  email: Scalars['String'];
};

export type ReturnB2bOrderContractCompanyType = {
  __typename?: 'ReturnB2bOrderContractCompanyType';
  /** 파트너 */
  companyKrName: Scalars['String'];
  /** 파트너 */
  companyName: Scalars['String'];
  /** 파트너 id */
  id: Scalars['ID'];
};

export type ReturnB2bOrderContractUserType = {
  __typename?: 'ReturnB2bOrderContractUserType';
  /** 이메일 */
  email: Scalars['String'];
  /** 유저 id */
  id: Scalars['ID'];
  /** 사용자  */
  userName: Scalars['String'];
};

export type ReturnBrandCompanyType = {
  __typename?: 'ReturnBrandCompanyType';
  /** 파트너 코드 */
  companyCode?: Maybe<Scalars['String']>;
  /** 파트너 국문 이름 */
  companyKrName: Scalars['String'];
  /** 파트너 영문 이름 */
  companyName: Scalars['String'];
  /** 파트너 id */
  id: Scalars['ID'];
};

export type ReturnBrandDepartmentType = {
  __typename?: 'ReturnBrandDepartmentType';
  /** 부서명 */
  departmentName: Scalars['String'];
  /** 부서 id */
  id: Scalars['ID'];
};

export type ReturnBrandFileInfoType = {
  __typename?: 'ReturnBrandFileInfoType';
  /** 파일 정보 이름 */
  fileName: Scalars['String'];
  /** 파일 정보 id */
  id: Scalars['ID'];
  /** 파일 정보 링크 */
  link: Scalars['String'];
  /** 파일 정보 타입 */
  type: FilePathEnum;
};

export type ReturnBrandManagerType = {
  __typename?: 'ReturnBrandManagerType';
  /** 부서 */
  department?: Maybe<ReturnBrandDepartmentType>;
  /** 매니저 id */
  id: Scalars['ID'];
  /** 매니저명 */
  userName: Scalars['String'];
};

export type ReturnBrandRequestCompanyType = {
  __typename?: 'ReturnBrandRequestCompanyType';
  /** 파트너 코드 */
  companyCode?: Maybe<Scalars['String']>;
  /** 파트너 국문 이름 */
  companyKrName: Scalars['String'];
  /** 파트너 영문 이름 */
  companyName: Scalars['String'];
  /** 파트너 id */
  id: Scalars['ID'];
};

export type ReturnBrandRequestDepartmentType = {
  __typename?: 'ReturnBrandRequestDepartmentType';
  /** 부서명 */
  departmentName: Scalars['String'];
  /** 부서 id */
  id: Scalars['ID'];
};

export type ReturnBrandRequestFileInfoType = {
  __typename?: 'ReturnBrandRequestFileInfoType';
  /** 파일 정보 이름 */
  fileName: Scalars['String'];
  /** 파일 정보 id */
  id: Scalars['ID'];
  /** 파일 정보 링크 */
  link: Scalars['String'];
  /** 파일 정보 타입 */
  type: FilePathEnum;
};

export type ReturnBrandRequestManagerType = {
  __typename?: 'ReturnBrandRequestManagerType';
  /** 부서 */
  department?: Maybe<ReturnBrandRequestDepartmentType>;
  /** 매니저 id */
  id: Scalars['ID'];
  /** 매니저명 */
  userName: Scalars['String'];
};

export type ReturnBrandType = {
  __typename?: 'ReturnBrandType';
  /** 브랜드 국문 명 */
  brandKrName: Scalars['String'];
  /** 브랜드 영문 명 */
  brandName: Scalars['String'];
  /** 파트너 ID */
  companyId: Scalars['ID'];
  /** 국가 ID */
  countryIds: Array<CountryEnum>;
  /** 브랜드 ID */
  id: Scalars['ID'];
};

export type ReturnCompanyFileInfoType = {
  __typename?: 'ReturnCompanyFileInfoType';
  /** 파일 확장자 */
  fileExtension: Scalars['String'];
  /** 파일 키 */
  fileKey: Scalars['String'];
  /** 파일 명 */
  fileName: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** 파일 링크 */
  link: Scalars['String'];
};

export type ReturnCompanyGroupType = {
  __typename?: 'ReturnCompanyGroupType';
  /** 파트너 그룹(01 / 고미코퍼레이션 본사 (KR)', '02 / 고미퍼레이션 해외 지사', '03 / 고미코퍼레이션 자회사', '08 / 제조사', '16 / 공급사', '24 / 유통사', '40 / 물류 협력사', '50 / 마케팅 협력사', '99 / 기타 협력사) */
  id: CompanyGroupEnum;
  /** 파트너 그룹 명 */
  name: Scalars['String'];
};

export type ReturnCompanyManagerDepartmentType = {
  __typename?: 'ReturnCompanyManagerDepartmentType';
  /** 부서명 */
  departmentName: Scalars['String'];
  /** ERP 부서코드 */
  erpDepartmentCode?: Maybe<Scalars['String']>;
  /** 매니저 id */
  id: Scalars['ID'];
};

export type ReturnCompanyManagerType = {
  __typename?: 'ReturnCompanyManagerType';
  /** 국가 id */
  countryId: CountryEnum;
  /** 부서 */
  department?: Maybe<ReturnCompanyManagerDepartmentType>;
  /** 이메일 */
  email: Scalars['String'];
  /** 매니저 id */
  id: Scalars['ID'];
  /** 전화번호 */
  phoneNumber: Scalars['String'];
  /** 소셜 로그인 고유키 */
  socialId?: Maybe<Scalars['String']>;
  /** 직원 ID */
  userId: Scalars['ID'];
  /** 직원 이름 */
  userName: Scalars['String'];
};

export type ReturnCompanyRequestManagerDepartmentType = {
  __typename?: 'ReturnCompanyRequestManagerDepartmentType';
  /** 부서명 */
  departmentName: Scalars['String'];
  /** ERP 부서코드 */
  erpDepartmentCode?: Maybe<Scalars['String']>;
  /** 부서 ID */
  id: Scalars['ID'];
};

export type ReturnCompanyRequestManagerGomiUserType = {
  __typename?: 'ReturnCompanyRequestManagerGomiUserType';
  /** 국가 id */
  countryId: CountryEnum;
  /** 부서 아이디 */
  departmentId?: Maybe<Scalars['ID']>;
  /** 이메일 */
  email: Scalars['String'];
  /** 매니저 id */
  id: Scalars['ID'];
  /** 전화번호 */
  phoneNumber: Scalars['String'];
  /** 직원 이름 */
  userName: Scalars['String'];
};

export type ReturnCompanyRequestManagerType = {
  __typename?: 'ReturnCompanyRequestManagerType';
  /** 국가 id */
  countryId: CountryEnum;
  /** 부서 */
  department: ReturnCompanyRequestManagerDepartmentType;
  /** 이메일 */
  email: Scalars['String'];
  /** 직원 아이디 */
  id: Scalars['ID'];
  /** 전화번호 */
  phoneNumber: Scalars['String'];
  /** 소셜 로그인 고유키 */
  socialId?: Maybe<Scalars['String']>;
  /** 직원 아이디 */
  userId: Scalars['ID'];
  /** 직원 이름 */
  userName: Scalars['String'];
};

export type ReturnCompanyType = {
  __typename?: 'ReturnCompanyType';
  /** 파트너 ERP 코드 */
  companyCode?: Maybe<Scalars['String']>;
  /** 파트너 국문 명 */
  companyKrName: Scalars['String'];
  /** 파트너 영문 명 */
  companyName: Scalars['String'];
  /** 파트너 ID */
  id: Scalars['ID'];
};

export type ReturnExchangeRateDto = {
  __typename?: 'ReturnExchangeRateDto';
  /** 월 평균 환율 */
  avgExchangeRate: Scalars['Float'];
  /** 국가 id */
  countryId: CountryEnum;
  /** 통화 기준 금액 */
  currencyCriteriaAmount: Scalars['Int'];
  /** 해당 환율의 연월 */
  yearMonth: Scalars['String'];
};

export type ReturnExpiryDateStock = {
  __typename?: 'ReturnExpiryDateStock';
  /** 배치 */
  batchNumber: Scalars['String'];
  /** 유통기한 */
  expiryDate?: Maybe<Scalars['String']>;
  /** gspc */
  gspc: Scalars['String'];
  /** 유통기한 여부 */
  isExpiryDate: Scalars['Boolean'];
  /** 창고 위치 */
  location: StockLocationEnum;
  /** Unusable */
  unusableQuantity: Scalars['Int'];
  /** Usable */
  usableQuantity: Scalars['Int'];
};

export type ReturnHomeSummaryOrderType = {
  __typename?: 'ReturnHomeSummaryOrderType';
  /** 총 취소 주문 수 */
  cancel: Scalars['Int'];
  /** 총 주문 수 */
  total: Scalars['Int'];
};

export type ReturnImportLicensingCommentWriterType = {
  __typename?: 'ReturnImportLicensingCommentWriterType';
  /** gomi user name */
  writerName: Scalars['String'];
};

export type ReturnImportLicensingContentDocumentFileType = {
  __typename?: 'ReturnImportLicensingContentDocumentFileType';
  /** 등록일 */
  createdAt: Scalars['DateTime'];
  /** 파일 타입 */
  extension: Scalars['String'];
  /** 파일명 */
  fileName: Scalars['String'];
  /** 수입 허가 서류 파일 id */
  id: Scalars['ID'];
  /** 파일 링크 */
  link: Scalars['String'];
};

export type ReturnImportLicensingContentDocumentGroupType = {
  __typename?: 'ReturnImportLicensingContentDocumentGroupType';
  /** 코멘트 수 */
  commentCount: Scalars['Int'];
  /** 업로드일 */
  createdAt: Scalars['DateTime'];
  /** 파일 목록 */
  fileList: Array<ReturnImportLicensingContentDocumentFileType>;
  /** 수입 허가 서류 그룹 id */
  id: Scalars['ID'];
  /** 신규 코멘트 생성 여부 */
  newCommentStatus: Scalars['Boolean'];
};

export type ReturnImportLicensingItemCompanyType = {
  __typename?: 'ReturnImportLicensingItemCompanyType';
  /** 파트너 */
  companyKrName: Scalars['String'];
  /** 파트너 */
  companyName: Scalars['String'];
  /** 파트너 id */
  id: Scalars['ID'];
};

export type ReturnPaginatedExpiryDateOutputDto = {
  __typename?: 'ReturnPaginatedExpiryDateOutputDto';
  nodes: Array<ReturnExpiryDateStock>;
  total: Scalars['Int'];
};

export type ReturnPaginatedStockOutputDto = {
  __typename?: 'ReturnPaginatedStockOutputDto';
  nodes: Array<ReturnStockOutputType>;
  total: Scalars['Int'];
};

export type ReturnProductCategoryType = {
  __typename?: 'ReturnProductCategoryType';
  /** 카테고리 식별키 */
  category: Scalars['String'];
  /** 전시용 카테고리 */
  displayCategory: Scalars['String'];
};

export type ReturnProductGroupType = {
  __typename?: 'ReturnProductGroupType';
  brand?: Maybe<ReturnBrandType>;
  category?: Maybe<Scalars['String']>;
  company?: Maybe<ReturnCompanyType>;
  productGroupKrName?: Maybe<Scalars['String']>;
  productGroupKrNameKeyword?: Maybe<Scalars['String']>;
  productGroupName: Scalars['String'];
  productGroupNameKeyword?: Maybe<Scalars['String']>;
};

export type ReturnProductOptionGroupType = {
  __typename?: 'ReturnProductOptionGroupType';
  id: Scalars['ID'];
  productOptionGroupName: Scalars['String'];
};

export type ReturnProductOptionType = {
  __typename?: 'ReturnProductOptionType';
  id: Scalars['ID'];
  productOptionGroup: ReturnProductOptionGroupType;
  productOptionName: Scalars['String'];
  productOptionRelId: Scalars['ID'];
};

/** Sales Code 정보 */
export type ReturnSalesCodesOutputDto = {
  __typename?: 'ReturnSalesCodesOutputDto';
  /** SalesCodeId */
  id: Scalars['ID'];
  /** 상품명 */
  name: Scalars['String'];
  /** 상품 판매코드 (구 GSPC) */
  salesCode: Scalars['String'];
};

export type ReturnSettlementConsignmentPartnerFeeSummaryChannelType = {
  __typename?: 'ReturnSettlementConsignmentPartnerFeeSummaryChannelType';
  /** 채널명 */
  channelName: Scalars['String'];
  /** 수수료 */
  fee: Scalars['Int'];
  /** 채널 아이디 */
  id: Scalars['ID'];
};

export type ReturnSettlementConsignmentPartnerProductListChannelType = {
  __typename?: 'ReturnSettlementConsignmentPartnerProductListChannelType';
  /** 채널명 */
  channelName: Scalars['String'];
  /** 판매 금액(판매 국가 기준) */
  countrySalesPrice: Scalars['Int'];
  /** 채널 아이디 */
  id: Scalars['ID'];
  /** 수량 */
  salesCount: Scalars['Int'];
};

export type ReturnSettlementConsignmentPartnerProductListItemChannelType = {
  __typename?: 'ReturnSettlementConsignmentPartnerProductListItemChannelType';
  /** 채널명 */
  channelName: Scalars['String'];
  /** 판매 금액(판매 국가 기준) */
  countrySalesPrice: Scalars['Int'];
  /** 수수료율 V2 */
  feeRateV2: Scalars['Float'];
  /** 채널 아이디 */
  id: Scalars['ID'];
  /** 수량 */
  salesCount: Scalars['Int'];
};

export type ReturnSettlementConsignmentPartnerProductListItemType = {
  __typename?: 'ReturnSettlementConsignmentPartnerProductListItemType';
  /** 채널 */
  channels: Array<ReturnSettlementConsignmentPartnerProductListItemChannelType>;
  /** 판매 금액(판매 국가 기준) */
  countrySalesPrice: Scalars['Int'];
  /** 국문 상품명 */
  productKrName: Scalars['String'];
  /** 열 개수 */
  rowspan: Scalars['Int'];
  /** 판매 코드 아이디 */
  salesCodeId: Scalars['ID'];
  /** 합계 판매 금액(판매 국가 기준) */
  sumCountrySalesPrice: Scalars['Int'];
  /** 합계 수량 */
  sumSalesCount: Scalars['Int'];
  /** 합계 판매 금액 */
  sumSalesPrice: Scalars['Int'];
};

export type ReturnSettlementConsignmentPartnerSalesPriceSummaryChannelType = {
  __typename?: 'ReturnSettlementConsignmentPartnerSalesPriceSummaryChannelType';
  /** 채널명 */
  channelName: Scalars['String'];
  /** 채널 아이디 */
  id: Scalars['ID'];
  /** 매출 금액 */
  salesPrice: Scalars['Int'];
};

export type ReturnSettlementCriteriaConsignmentChannelType = {
  __typename?: 'ReturnSettlementCriteriaConsignmentChannelType';
  /** 채널명 */
  channelName: Scalars['String'];
  /** 채널 아이디 */
  id: Scalars['ID'];
};

export type ReturnSettlementCriteriaConsignmentDepartmentType = {
  __typename?: 'ReturnSettlementCriteriaConsignmentDepartmentType';
  /** 담당 부서명 */
  departmentName: Scalars['String'];
  /** 부서 아이디 */
  id: Scalars['ID'];
};

export type ReturnSettlementCriteriaConsignmentManagerType = {
  __typename?: 'ReturnSettlementCriteriaConsignmentManagerType';
  /** 담당 부서 */
  department?: Maybe<ReturnSettlementCriteriaConsignmentDepartmentType>;
  /** 담당자 아이디 */
  id: Scalars['ID'];
  /** 담당자명 */
  managerName: Scalars['String'];
};

export type ReturnSettlementCriteriaConsignmentModifierType = {
  __typename?: 'ReturnSettlementCriteriaConsignmentModifierType';
  /** 담당 부서 */
  department?: Maybe<ReturnSettlementCriteriaConsignmentDepartmentType>;
  /** 수정자 아이디 */
  id: Scalars['ID'];
  /** 수정자명 */
  managerName: Scalars['String'];
};

export type ReturnSettlementCriteriaConsignmentSalesCodeType = {
  __typename?: 'ReturnSettlementCriteriaConsignmentSalesCodeType';
  /** 국문 브랜드명 */
  brandKrName: Scalars['String'];
  /** 국가 id */
  countryId: CountryEnum;
  /** sales_code 아이디 */
  id: Scalars['ID'];
  /** 콜렉션 여부 */
  isCollection: Scalars['Boolean'];
  /** 국문 상품명 */
  productKrName: Scalars['String'];
  /** 상품 판매코드 (구 GSPC) */
  salesCode: Scalars['String'];
};

export type ReturnSettlementCriteriaPurchaseOfSalesDepartmentType = {
  __typename?: 'ReturnSettlementCriteriaPurchaseOfSalesDepartmentType';
  /** 담당 부서명 */
  departmentName?: Maybe<Scalars['String']>;
  /** 부서 아이디 */
  id: Scalars['ID'];
};

export type ReturnSettlementCriteriaPurchaseOfSalesManagerType = {
  __typename?: 'ReturnSettlementCriteriaPurchaseOfSalesManagerType';
  /** 담당 부서 */
  department?: Maybe<ReturnSettlementCriteriaPurchaseOfSalesDepartmentType>;
  /** 담당자 아이디 */
  id: Scalars['ID'];
  /** 담당자명 */
  managerName: Scalars['String'];
};

export type ReturnSettlementCriteriaPurchaseOfSalesModifierType = {
  __typename?: 'ReturnSettlementCriteriaPurchaseOfSalesModifierType';
  /** 담당 부서 */
  department?: Maybe<ReturnSettlementCriteriaPurchaseOfSalesDepartmentType>;
  /** 수정자 아이디 */
  id: Scalars['ID'];
  /** 수정자명 */
  managerName: Scalars['String'];
};

export type ReturnSettlementCriteriaPurchaseOfSalesProductType = {
  __typename?: 'ReturnSettlementCriteriaPurchaseOfSalesProductType';
  /** gspc */
  gspc: Scalars['String'];
  /** 상품 아이디 */
  id: Scalars['ID'];
  /** 상품 국문명 */
  productKrName?: Maybe<Scalars['String']>;
  /** 상품 영문명 */
  productName?: Maybe<Scalars['String']>;
};

export type ReturnStockLogOutputDto = {
  __typename?: 'ReturnStockLogOutputDto';
  formattedDate?: Maybe<Scalars['String']>;
  /** (구)GSPC */
  gspc?: Maybe<Scalars['String']>;
  /** 거래일시 */
  orderedAt?: Maybe<Scalars['DateTime']>;
  /** 제품명 */
  productName?: Maybe<Scalars['String']>;
  /** 수량 */
  quantity: Scalars['Int'];
};

export type ReturnStockOutputType = {
  __typename?: 'ReturnStockOutputType';
  /** Buffer */
  bufferQuantity: Scalars['Int'];
  /** (구)GSPC */
  gspc?: Maybe<Scalars['String']>;
  /** In Picking */
  inPickingQuantity: Scalars['Int'];
  /** In Progress */
  inProcessQuantity: Scalars['Int'];
  /** Incoming */
  incomingQuantity: Scalars['Int'];
  /** 창고 위치 */
  location: StockLocationEnum;
  /** Problem */
  problemQuantity: Scalars['Int'];
  /** 제품명 */
  productName?: Maybe<Scalars['String']>;
  /** 수량 */
  quantity: Scalars['Int'];
  /** Unusable */
  unusableQuantity: Scalars['Int'];
  /** Unit of Measure */
  uom?: Maybe<Scalars['String']>;
  /** Usable */
  usableQuantity: Scalars['Int'];
};

export type ReturnUserCompanyType = {
  __typename?: 'ReturnUserCompanyType';
  /** 파트너 코드 */
  companyCode?: Maybe<Scalars['String']>;
  /** 파트너 국문명 */
  companyKrName: Scalars['String'];
  /** 파트너 영문명 */
  companyName: Scalars['String'];
  /** 파트너 아이디 */
  id: Scalars['ID'];
  /** 리포트 V2 이전 고객 여부 (기준 21년 6년 이전 입점한 파트너사) */
  isBeforeReportV2: Scalars['Boolean'];
  /** 위탁: CONSIGNMENT,0  사입: PURCHASE,1 판매분 사입: PURCHASE,2 */
  salesType?: Maybe<SalesTypeEnum>;
};

export enum RoleEnum {
  Gomi = 'GOMI',
  Partner = 'PARTNER',
}

export type SalesAmountChannelType = {
  __typename?: 'SalesAmountChannelType';
  /** 채널명 */
  channelName: OrderChannelEnum;
  /** 판매 금액 */
  salesAmount: Scalars['Float'];
};

export type SalesCodesInputFilter = {
  /** 브랜드 ids */
  brandIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 파트너 id */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 국가 id */
  countryId: CountryEnum;
};

export type SalesStatusByDate = {
  __typename?: 'SalesStatusByDate';
  /** 날짜 */
  date?: Maybe<Scalars['DateTime']>;
  /** 주문 상태 */
  statuses: Array<SalesStatusByDateOrderStatusType>;
};

export type SalesStatusByDateChannelType = {
  __typename?: 'SalesStatusByDateChannelType';
  /** 주문 금액 */
  amount: Scalars['Int'];
  /** 채널명 */
  channelName: OrderChannelEnum;
  /** 주문 수량 */
  count: Scalars['Int'];
};

export type SalesStatusByDateOrderStatusType = {
  __typename?: 'SalesStatusByDateOrderStatusType';
  /** 채널 별 수량 금액 */
  channels: Array<SalesStatusByDateChannelType>;
  /** 주문 상태 */
  orderStatus: SettlementOrderStatus;
};

export type SalesStatusByProductType = {
  __typename?: 'SalesStatusByProductType';
  /** 상품 가격 */
  prices: Array<PriceType>;
  /** 상품명 */
  productName: Scalars['String'];
};

export enum SalesTypeEnum {
  Consignment = 'CONSIGNMENT',
  None = 'NONE',
  Purchase = 'PURCHASE',
  PurchaseBySales = 'PURCHASE_BY_SALES',
}

export enum SettlementOrderItemSettlementStatusEnum {
  Setoff = 'SETOFF',
  Settlement = 'SETTLEMENT',
}

export enum SettlementOrderStatus {
  Cancel = 'CANCEL',
  Ok = 'OK',
}

export enum SettlementPartnerSendStatusEnum {
  Ready = 'READY',
  Sent = 'SENT',
}

export enum SettlementPartnerStatusEnum {
  Clear = 'CLEAR',
  InvalidFee = 'INVALID_FEE',
}

export type SettlementProduct = {
  __typename?: 'SettlementProduct';
  /** 상품 id */
  productId: Scalars['ID'];
  /** 상품 명 */
  productName: Scalars['String'];
  /** 수량 */
  salesCount: Scalars['Int'];
  /** 총 판매 금액 */
  salesPrice: Scalars['Int'];
  /** 매입 단가 */
  settlementCriteriaPrice: Scalars['Int'];
};

export enum SlackChannelEnum {
  ContactMe = 'CONTACT_ME',
  WorkRequest = 'WORK_REQUEST',
}

export type SlackDto = {
  /** 채널 */
  channel: SlackChannelEnum;
  /** 슬랙 메시지 */
  message: Scalars['String'];
};

export enum SocialProviderEnum {
  Google = 'GOOGLE',
}

export enum StockLocationEnum {
  Han = 'HAN',
  Sgn = 'SGN',
}

export enum StockTypeEnum {
  StockIn = 'STOCK_IN',
  StockOut = 'STOCK_OUT',
}

export type UpdateB2bOrderBankDepositInput = {
  /** 입금 확인서 파일 정보 ID */
  fileInfoId: Scalars['ID'];
};

export type UpdateB2bOrderContractConfigInputType = {
  /** 상품 ID */
  productId: Scalars['ID'];
  /** 매입 공급율 */
  purchaseFeeRate: Scalars['Float'];
  /** 소비자가 */
  retailPrice: Scalars['Int'];
  /** 공급율 */
  salesFeeRate: Scalars['Float'];
};

export type UpdateB2bOrderEstimatedArrivalDateInput = {
  /** 도착 예정일 */
  estimatedArrivalDate: Scalars['DateTime'];
};

export type UpdateBrandRequestInputType = {
  /** 브랜드명 약어 */
  brandAbbr?: InputMaybe<Scalars['String']>;
  /** 브랜드 이미지 id */
  brandImageId?: InputMaybe<Scalars['ID']>;
  /** 브랜드명 (국문) */
  brandKrName: Scalars['String'];
  /** 브랜드명 (영문) */
  brandName: Scalars['String'];
  /** 파트너 id */
  companyId: Scalars['ID'];
  /** 국가 id 리스트 */
  countryIds: Array<CountryEnum>;
  /** 담당자 id 리스트 */
  managerIds: Array<Scalars['ID']>;
  /** 반려 사유 */
  rejectReason?: InputMaybe<Scalars['String']>;
  /** 요청 상태 */
  status: CompanyRequestStatusEnum;
};

export type UpdateCompanyRequestByInspectorType = {
  /** 계좌번호, - 허용 */
  accountNumber: Scalars['String'];
  /** 주소 */
  address?: InputMaybe<Scalars['String']>;
  /** 통장사본 이미지 ID */
  bankImageId?: InputMaybe<Scalars['ID']>;
  /** 은행이름 */
  bankName: Scalars['String'];
  /** 대표자명 */
  ceoName?: InputMaybe<Scalars['String']>;
  /** 회사 이미지 ID */
  companyImageId?: InputMaybe<Scalars['ID']>;
  /** 회사명 국문 */
  companyKrName: Scalars['String'];
  /** 회사명, 기본 영어 */
  companyName: Scalars['String'];
  /** 계약서 ID */
  contractId?: InputMaybe<Scalars['ID']>;
  /** 법인등록번호 */
  corporateRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 파트너 국가 ID */
  countryId: CountryEnum;
  /** 전자세금계산서 이메일 */
  digitalTaxInvoiceEmail: Scalars['String'];
  /** 파트너 그룹(01 / 고미코퍼레이션 본사 (KR)', '02 / 고미퍼레이션 해외 지사', '03 / 고미코퍼레이션 자회사', '08 / 제조사', '16 / 공급사', '24 / 유통사', '40 / 물류 협력사', '50 / 마케팅 협력사', '99 / 기타 협력사) */
  groupId: CompanyGroupEnum;
  /** gomiManagerRels, 고미 담당자 아이디 */
  managerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 사업자등록 이미지 ID */
  registrationImageId: Scalars['ID'];
  /** 사업자 등록번호, - 사용 */
  registrationNumber: Scalars['String'];
  /** 반려 사유 */
  rejectReason?: InputMaybe<Scalars['String']>;
  /** 위탁: CONSIGNMENT,0  사입: PURCHASE,1 판매분 사입: PURCHASE,2 */
  salesType?: InputMaybe<SalesTypeEnum>;
  /** 요청 상태 - DONE: 검수완료, REJECTION: 반려, TEMPORARY: 임시저장 */
  status: CompanyRequestStatusEnum;
};

export type UpdateCompanyRequestByRequesterType = {
  /** 계좌번호, - 허용 */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** 주소 */
  address?: InputMaybe<Scalars['String']>;
  /** 통장사본 이미지 ID */
  bankImageId?: InputMaybe<Scalars['ID']>;
  /** 은행이름 */
  bankName?: InputMaybe<Scalars['String']>;
  /** 대표자명 */
  ceoName?: InputMaybe<Scalars['String']>;
  /** 파트너사 아이디 */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 회사 이미지 ID */
  companyImageId?: InputMaybe<Scalars['ID']>;
  /** 회사명 국문 */
  companyKrName?: InputMaybe<Scalars['String']>;
  /** 회사명, 기본 영어 */
  companyName?: InputMaybe<Scalars['String']>;
  /** 계약서 ID */
  contractId?: InputMaybe<Scalars['ID']>;
  /** 법인등록번호 */
  corporateRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 파트너 국가 ID */
  countryId?: InputMaybe<CountryEnum>;
  /** 전자세금계산서 이메일 */
  digitalTaxInvoiceEmail?: InputMaybe<Scalars['String']>;
  /** 파트너 그룹(01 / 고미코퍼레이션 본사 (KR)', '02 / 고미퍼레이션 해외 지사', '03 / 고미코퍼레이션 자회사', '08 / 제조사', '16 / 공급사', '24 / 유통사', '40 / 물류 협력사', '50 / 마케팅 협력사', '99 / 기타 협력사) */
  groupId?: InputMaybe<CompanyGroupEnum>;
  /** gomiManagerRels, 고미 담당자 아이디 */
  managerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 사업자등록 이미지 ID */
  registrationImageId?: InputMaybe<Scalars['ID']>;
  /** 사업자 등록번호, - 사용 */
  registrationNumber?: InputMaybe<Scalars['String']>;
  /** 위탁: CONSIGNMENT,0  사입: PURCHASE,1 판매분 사입: PURCHASE,2 */
  salesType?: InputMaybe<SalesTypeEnum>;
  /** 요청 상태 - TEMPORARY: 임시저장, REQUEST: 검수 요청 */
  status: CompanyRequestStatusEnum;
};

export type UpdateCompanyRequestInputType = {
  /** 계좌번호, - 허용 */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** 주소 */
  address?: InputMaybe<Scalars['String']>;
  /** 통장사본 이미지 ID */
  bankImageId?: InputMaybe<Scalars['ID']>;
  /** 은행이름 */
  bankName?: InputMaybe<Scalars['String']>;
  /** 대표자명 */
  ceoName?: InputMaybe<Scalars['String']>;
  /** 회사 이미지 ID */
  companyImageId?: InputMaybe<Scalars['ID']>;
  /** 회사명 국문 */
  companyKrName: Scalars['String'];
  /** 회사명, 기본 영어 */
  companyName: Scalars['String'];
  /** 계약서 ID */
  contractId?: InputMaybe<Scalars['ID']>;
  /** 법인등록번호 */
  corporateRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 국가 id */
  countryId?: InputMaybe<CountryEnum>;
  /** 전자세금계산서 이메일 */
  digitalTaxInvoiceEmail?: InputMaybe<Scalars['String']>;
  /** 파트너 그룹(01 / 고미코퍼레이션 본사 (KR)', '02 / 고미퍼레이션 해외 지사', '03 / 고미코퍼레이션 자회사', '08 / 제조사', '16 / 공급사', '24 / 유통사', '40 / 물류 협력사', '50 / 마케팅 협력사', '99 / 기타 협력사) */
  groupId?: InputMaybe<CompanyGroupEnum>;
  /** 담당자 id 리스트 */
  managerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 사업자등록 이미지 ID */
  registrationImageId?: InputMaybe<Scalars['ID']>;
  /** 사업자 등록번호, - 사용 */
  registrationNumber?: InputMaybe<Scalars['String']>;
  /** 위탁: CONSIGNMENT,0  사입: PURCHASE,1 판매분 사입: PURCHASE,2 */
  salesType?: InputMaybe<SalesTypeEnum>;
  /** 요청 상태 - REQUEST: 검수요청, TEMPORARY: 임시저장 */
  status: CompanyRequestStatusEnum;
};

export type UpdateCompanyType = {
  address?: InputMaybe<Scalars['String']>;
  ceoName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  krAddress?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateExportProductType = {
  boxCount?: InputMaybe<Scalars['Float']>;
  /** 만료일 */
  expireDate?: InputMaybe<Scalars['String']>;
  /** 가격 정보 */
  exportPrice?: InputMaybe<Scalars['Float']>;
  /** 가격 정보 */
  exportUsPrice?: InputMaybe<Scalars['Float']>;
  grossWeight?: InputMaybe<Scalars['Float']>;
  lotNo?: InputMaybe<Scalars['String']>;
  netWeight?: InputMaybe<Scalars['Float']>;
  shippingMarkNo?: InputMaybe<Scalars['String']>;
  uom?: InputMaybe<Scalars['String']>;
};

export type UpdateExportType = {
  bankDetails?: InputMaybe<Scalars['String']>;
  countryOfFinalDestination?: InputMaybe<Scalars['String']>;
  countryOfOriginalOfGoods?: InputMaybe<Scalars['String']>;
  /** 수출 대리 파트너 id */
  exportAgentId?: InputMaybe<Scalars['ID']>;
  /** 수출 진행하는 파트너 id */
  exportCompanyId?: InputMaybe<Scalars['ID']>;
  /** 수출일 */
  exportDate?: InputMaybe<Scalars['String']>;
  /** 수입자 파트너 id */
  exportImporterId?: InputMaybe<Scalars['ID']>;
  /** 고미 담당자 id */
  gomiManagerId?: InputMaybe<Scalars['ID']>;
  incoterms?: InputMaybe<Scalars['String']>;
  /** 담당자 id */
  managerId?: InputMaybe<Scalars['ID']>;
  methodOfDispatch?: InputMaybe<Scalars['String']>;
  methodOfPayment?: InputMaybe<Scalars['String']>;
  portOfArrival?: InputMaybe<Scalars['String']>;
  portOfDeparture?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  /** 상태 */
  status?: InputMaybe<ExportStatusEnum>;
  typeOfShipment?: InputMaybe<Scalars['String']>;
  vesselAircraft?: InputMaybe<Scalars['String']>;
  voyageFlightNo?: InputMaybe<Scalars['String']>;
};

export type UpdateGomiUserType = {
  /** 권한 목록 (기본 권한: []) */
  authorizations?: InputMaybe<Array<AuthorizationEnum>>;
  /** 부서 ID */
  departmentId?: InputMaybe<Scalars['ID']>;
  /** ERP 코드 (사원번호) */
  erpUserCode?: InputMaybe<Scalars['String']>;
  /** 언어 */
  languageId?: InputMaybe<CountryEnum>;
  /** 연락처 */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** 고미 담당자 이름 */
  userName?: InputMaybe<Scalars['String']>;
};

export type UpdateImportLicensingCommentInputType = {
  /** comment */
  comment?: InputMaybe<Scalars['String']>;
};

export type UpdateImportLicensingContentInputType = {
  /** 브랜드명 국문 */
  brandKrName?: InputMaybe<Scalars['String']>;
  /** 브랜드명 */
  brandName?: InputMaybe<Scalars['String']>;
  /** 컨텐츠명 */
  contentKrName?: InputMaybe<Scalars['String']>;
  /** 컨텐츠명 */
  contentName?: InputMaybe<Scalars['String']>;
  /** 희망 판매가 */
  desiredSalesPrice?: InputMaybe<Scalars['Int']>;
  /** 제조사명 국문 */
  manufacturerKrName?: InputMaybe<Scalars['String']>;
  /** 제조사명 */
  manufacturerName?: InputMaybe<Scalars['String']>;
  /** 실제 판매가 */
  salesPrice?: InputMaybe<Scalars['Int']>;
};

export type UpdateImportLicensingDocumentInputType = {
  /** 서류명 */
  documentName?: InputMaybe<Scalars['String']>;
  /** 변경할 상태 */
  status?: InputMaybe<ImportLicensingDocumentStatusEnum>;
};

export type UpdateImportLicensingEstimateInputType = {
  /** 수량 */
  count?: InputMaybe<Scalars['Int']>;
  /** 서류 */
  document?: InputMaybe<Scalars['String']>;
  /** 항목 */
  itemName?: InputMaybe<Scalars['String']>;
  /** 단가 */
  price?: InputMaybe<Scalars['Int']>;
  /** 단위 */
  unit?: InputMaybe<Scalars['String']>;
};

export type UpdateImportLicensingInputType = {
  /** 수입 허가 등록명 */
  importLicenseName?: InputMaybe<Scalars['String']>;
  /** 담당자 ID */
  managerId?: InputMaybe<Scalars['ID']>;
  /** 공증 ID */
  notarizationFileId?: InputMaybe<Scalars['Int']>;
};

export type UpdateImportLicensingTemplateInputType = {
  /** 카테고리 명 */
  categoryName: Scalars['String'];
  /** 카테고리 명 */
  countryId: CountryEnum;
  /** 소요 기간 */
  requiredPeriod: Scalars['Int'];
  /** 템플릿 내용 JSON 문자열 */
  templateContent: Scalars['String'];
  /** 템플릿명 */
  templateName: Scalars['String'];
};

export type UpdateProductGroupOptionGroupType = {
  /** 상품 그룹 ID */
  id?: InputMaybe<Scalars['ID']>;
  /** 옵션 그룹 명 */
  productOptionGroupName: Scalars['String'];
  /** 옵션 목록 */
  productOptions?: InputMaybe<Array<UpdateProductGroupOptionType>>;
};

export type UpdateProductGroupOptionType = {
  /** 상품 그룹 ID */
  id?: InputMaybe<Scalars['ID']>;
  /** 옵션 명 */
  productOptionName: Scalars['String'];
};

export type UpdateProductGroupType = {
  /** 카테고리 */
  category?: InputMaybe<Scalars['String']>;
  /** 상품 그룹 국문명 */
  productGroupKrName?: InputMaybe<Scalars['String']>;
  /** 상품 그룹명 */
  productGroupName?: InputMaybe<Scalars['String']>;
  /** 상품 옵션 그룹 (추가할 상품 그룹만 작성하고 기존 생성된 상품 그룹 수정/삭제 불가) */
  productOptionGroups?: InputMaybe<Array<UpdateProductGroupOptionGroupType>>;
};

export type UpdateProductInputType = {
  /** 바코드 */
  barcode?: InputMaybe<Scalars['String']>;
  /** hs code */
  hsCode?: InputMaybe<Scalars['String']>;
  /** 외박스 수량 */
  outerBoxCount?: InputMaybe<Scalars['Int']>;
  /** 외박스 세로 */
  outerBoxDepth?: InputMaybe<Scalars['Float']>;
  /** 외박스 높이 */
  outerBoxHeight?: InputMaybe<Scalars['Float']>;
  /** 외박스 중량 */
  outerBoxWeight?: InputMaybe<Scalars['Float']>;
  /** 외박스 가로 */
  outerBoxWidth?: InputMaybe<Scalars['Float']>;
  /** 옵션 정보 */
  productOptionRels?: InputMaybe<Array<UpdateProductOptionRelInputType>>;
  /** 공급사 상품 코드 */
  supplierProductCode?: InputMaybe<Scalars['String']>;
};

export type UpdateProductOptionRelInputType = {
  /** 상품 옵션 연결 id */
  id?: InputMaybe<Scalars['ID']>;
  /** 상품 id */
  productId: Scalars['ID'];
  /** 상품 옵션 id */
  productOptionId: Scalars['ID'];
};

export type UpdateProductRequestByInspectorInputType = {
  /** 바코드 */
  barcode?: InputMaybe<Scalars['String']>;
  /** 태국 판매가(할인) A */
  discountSalePriceThA?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(할인) B */
  discountSalePriceThB?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(할인) S */
  discountSalePriceThS?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) A */
  discountSalePriceVnA?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) B */
  discountSalePriceVnB?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) S */
  discountSalePriceVnS?: InputMaybe<Scalars['Int']>;
  /** HS 코드 */
  hsCode?: InputMaybe<Scalars['String']>;
  /** 패키지 수량 */
  innerBoxCount?: InputMaybe<Scalars['Int']>;
  /** 외박스 수량 */
  outerBoxCount?: InputMaybe<Scalars['Int']>;
  /** 상품 그룹 id */
  productGroupId?: InputMaybe<Scalars['ID']>;
  /** 상품명 국문 */
  productKrName?: InputMaybe<Scalars['String']>;
  /** 상품명 영문 */
  productName?: InputMaybe<Scalars['String']>;
  /** 매입 단가 */
  purchaseUnitPrice?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(상시) */
  regularSalePriceTh?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(상시) */
  regularSalePriceVn?: InputMaybe<Scalars['Int']>;
  /** 반려 사유 */
  rejectReason?: InputMaybe<Scalars['String']>;
  /** 소비자가(한국) */
  retailPriceKr?: InputMaybe<Scalars['Int']>;
  /** 소비자가(태국) */
  retailPriceTh?: InputMaybe<Scalars['Int']>;
  /** 소비자가(베트남) */
  retailPriceVn?: InputMaybe<Scalars['Int']>;
  /** 요청 상태 - DONE: 검수완료, REJECTION: 반려, TEMPORARY: 임시저장 */
  status: CompanyRequestStatusEnum;
  /** 공급사 상품 코드 */
  supplierProductCode?: InputMaybe<Scalars['String']>;
  /** BASIC: 기본 정보, LOGISTICS: 물류 정보, PRICE: 가격 정보, DETAIL: 상세 정보 */
  type?: InputMaybe<ProductRequestTypeEnum>;
};

export type UpdateProductRequestByRequesterInputType = {
  /** 바코드 */
  barcode?: InputMaybe<Scalars['String']>;
  /** 태국 판매가(할인) A */
  discountSalePriceThA?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(할인) B */
  discountSalePriceThB?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(할인) S */
  discountSalePriceThS?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) A */
  discountSalePriceVnA?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) B */
  discountSalePriceVnB?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(할인) S */
  discountSalePriceVnS?: InputMaybe<Scalars['Int']>;
  /** HS 코드 */
  hsCode?: InputMaybe<Scalars['String']>;
  /** 패키지 수량 */
  innerBoxCount?: InputMaybe<Scalars['Int']>;
  /** 외박스 수량 */
  outerBoxCount?: InputMaybe<Scalars['Int']>;
  /** 상품 그룹 id */
  productGroupId?: InputMaybe<Scalars['ID']>;
  /** 상품명 국문 */
  productKrName?: InputMaybe<Scalars['String']>;
  /** 상품명 영문 */
  productName?: InputMaybe<Scalars['String']>;
  /** 매입 단가 */
  purchaseUnitPrice?: InputMaybe<Scalars['Int']>;
  /** 태국 판매가(상시) */
  regularSalePriceTh?: InputMaybe<Scalars['Int']>;
  /** 베트남 판매가(상시) */
  regularSalePriceVn?: InputMaybe<Scalars['Int']>;
  /** 반려 사유 */
  rejectReason?: InputMaybe<Scalars['String']>;
  /** 소비자가(한국) */
  retailPriceKr?: InputMaybe<Scalars['Int']>;
  /** 소비자가(태국) */
  retailPriceTh?: InputMaybe<Scalars['Int']>;
  /** 소비자가(베트남) */
  retailPriceVn?: InputMaybe<Scalars['Int']>;
  /** 요청 상태 - TEMPORARY: 임시저장, REQUEST: 검수 요청 */
  status: CompanyRequestStatusEnum;
  /** 공급사 상품 코드 */
  supplierProductCode?: InputMaybe<Scalars['String']>;
  /** BASIC: 기본 정보, LOGISTICS: 물류 정보, PRICE: 가격 정보, DETAIL: 상세 정보 */
  type?: InputMaybe<ProductRequestTypeEnum>;
};

export type UpdateSettlementCriteriaConsignmentUpdateType = {
  /** 수수료 적용 종료 날짜 */
  endAt?: InputMaybe<Scalars['DateTime']>;
  /** 수수료율 */
  feeRate: Scalars['Int'];
  /** 수수료 적용 시작 날짜 */
  startAt: Scalars['DateTime'];
};

export type UpdateSettlementCriteriaPurchaseOfSalesInputType = {
  /** 정산 기준가 시작일 */
  startAt: Scalars['DateTime'];
};

export type UpdateUserType = {
  /** 권한 */
  authorizations?: InputMaybe<Array<AuthorizationEnum>>;
  /** 파트너 식별키 */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 연락처 */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** 파트너 유저명 */
  userName?: InputMaybe<Scalars['String']>;
};

export type SendSlackMutationVariables = Exact<{
  input: SlackDto;
}>;

export type SendSlackMutation = { __typename?: 'Mutation'; sendSlack: boolean };

export type ExportPartnerBrandListMutationVariables = Exact<{
  input: DtoFindBrands;
}>;

export type ExportPartnerBrandListMutation = {
  __typename?: 'Mutation';
  exportPartnerBrandList: string;
};

export type BrandListQueryVariables = Exact<{
  input: DtoFindBrands;
}>;

export type BrandListQuery = {
  __typename?: 'Query';
  partnerBrandsV2: {
    __typename?: 'DtoReturnBrandList';
    total: number;
    nodes: Array<{
      __typename?: 'DtoReturnBrand';
      brandCode?: string | null;
      brandKrName: string;
      brandName: string;
      countryIds: Array<CountryEnum>;
      id: string;
      updatedAt: any;
      brandImage?: {
        __typename?: 'ReturnBrandFileInfoType';
        fileName: string;
        id: string;
        link: string;
        type: FilePathEnum;
      } | null;
      company: {
        __typename?: 'ReturnBrandCompanyType';
        id: string;
        companyName: string;
        companyKrName: string;
        companyCode?: string | null;
      };
      managers: Array<{
        __typename?: 'ReturnBrandManagerType';
        userName: string;
        id: string;
        department?: {
          __typename?: 'ReturnBrandDepartmentType';
          departmentName: string;
          id: string;
        } | null;
      }>;
    }>;
  };
};

export type BrandQueryVariables = Exact<{
  brandId: Scalars['ID'];
}>;

export type BrandQuery = {
  __typename?: 'Query';
  partnerBrand: {
    __typename?: 'DtoReturnBrand';
    brandCode?: string | null;
    updatedAt: any;
    brandKrName: string;
    brandName: string;
    countryIds: Array<CountryEnum>;
    id: string;
    brandImage?: {
      __typename?: 'ReturnBrandFileInfoType';
      fileName: string;
      id: string;
      link: string;
      type: FilePathEnum;
    } | null;
    company: {
      __typename?: 'ReturnBrandCompanyType';
      id: string;
      companyKrName: string;
      companyName: string;
      companyCode?: string | null;
    };
    managers: Array<{
      __typename?: 'ReturnBrandManagerType';
      userName: string;
      id: string;
      department?: {
        __typename?: 'ReturnBrandDepartmentType';
        departmentName: string;
        id: string;
      } | null;
    }>;
  };
};

export type DemoMutationVariables = Exact<{ [key: string]: never }>;

export type DemoMutation = {
  __typename?: 'Mutation';
  demo: {
    __typename?: 'DtoReturnLogin';
    accessToken: string;
    authorizationList?: Array<string | null> | null;
    companyId?: string | null;
    email: string;
    id: number;
    role?: RoleEnum | null;
    userName: string;
  };
};

export type UploadToS3MutationVariables = Exact<{
  file: Scalars['Upload'];
  type: FilePathEnum;
}>;

export type UploadToS3Mutation = {
  __typename?: 'Mutation';
  uploadToS3V2: {
    __typename?: 'DtoReturnFileInfo';
    createdAt: any;
    deletedAt?: any | null;
    fileExtension: string;
    fileKey: string;
    fileName: string;
    id: string;
    link: string;
    type: FilePathEnum;
    updatedAt: any;
  };
};

export type UploadFilesToS3MutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
  type: FilePathEnum;
}>;

export type UploadFilesToS3Mutation = {
  __typename?: 'Mutation';
  uploadFilesToS3: Array<{
    __typename?: 'DtoReturnFileInfo';
    createdAt: any;
    deletedAt?: any | null;
    fileExtension: string;
    fileKey: string;
    fileName: string;
    id: string;
    link: string;
    type: FilePathEnum;
    updatedAt: any;
  }>;
};

export type HomeSummaryQueryVariables = Exact<{
  input?: InputMaybe<DtoHomeInput>;
}>;

export type HomeSummaryQuery = {
  __typename?: 'Query';
  homeSummary: {
    __typename?: 'DtoReturnHomeSummary';
    salesPrice: number;
    orders: { __typename?: 'ReturnHomeSummaryOrderType'; cancel: number; total: number };
  };
};

export type TopSellingCategoriesQueryVariables = Exact<{
  input?: InputMaybe<DtoHomeInput>;
}>;

export type TopSellingCategoriesQuery = {
  __typename?: 'Query';
  topSellingCategories: Array<{
    __typename?: 'DtoReturnHomeCategory';
    categoryName: string;
    count: number;
  }>;
};

export type TopSellingProductsQueryVariables = Exact<{
  input?: InputMaybe<DtoHomeInput>;
}>;

export type TopSellingProductsQuery = {
  __typename?: 'Query';
  topSellingProducts: Array<{
    __typename?: 'DtoReturnHomeProduct';
    count: number;
    productName: string;
  }>;
};

export type SalesRatioByChannelQueryVariables = Exact<{
  input?: InputMaybe<DtoHomeInput>;
}>;

export type SalesRatioByChannelQuery = {
  __typename?: 'Query';
  salesRatioByChannel: Array<{
    __typename?: 'DtoReturnHomeChannel';
    channel: string;
    ratio: number;
  }>;
};

export type TopSellingBrandsQueryVariables = Exact<{
  input?: InputMaybe<DtoHomeInput>;
}>;

export type TopSellingBrandsQuery = {
  __typename?: 'Query';
  topSellingBrands: Array<{
    __typename?: 'DtoReturnHomeBrand';
    brandKrName: string;
    count: number;
  }>;
};

export type DownloadImportLicensingDocumentFileMutationVariables = Exact<{
  downloadImportLicensingDocumentFileId: Scalars['ID'];
}>;

export type DownloadImportLicensingDocumentFileMutation = {
  __typename?: 'Mutation';
  downloadImportLicensingDocumentFile: string;
};

export type RequestImportLicensingTaxBillMutationVariables = Exact<{
  input: DtoRequestTaxBillInput;
}>;

export type RequestImportLicensingTaxBillMutation = {
  __typename?: 'Mutation';
  requestImportLicensingTaxBill: boolean;
};

export type ConfirmFileCommentMutationVariables = Exact<{
  confirmFileCommentId: Scalars['ID'];
}>;

export type ConfirmFileCommentMutation = {
  __typename?: 'Mutation';
  confirmFileComment: boolean;
};

export type CompleteConfirmationImportLicensingByPartnerMutationVariables = Exact<{
  input: DtoRequestEditImportLicensingInput;
}>;

export type CompleteConfirmationImportLicensingByPartnerMutation = {
  __typename?: 'Mutation';
  completeConfirmationImportLicensingByPartner: boolean;
};

export type RequestConfirmationImportLicensingMutationVariables = Exact<{
  input: DtoRequestEditImportLicensingInput;
}>;

export type RequestConfirmationImportLicensingMutation = {
  __typename?: 'Mutation';
  requestConfirmationImportLicensing: boolean;
};

export type DeleteImportLicensingContentMutationVariables = Exact<{
  deleteImportLicensingContentId: Scalars['ID'];
}>;

export type DeleteImportLicensingContentMutation = {
  __typename?: 'Mutation';
  deleteImportLicensingContent: {
    __typename?: 'DtoReturnImportLicensingContent';
    id: string;
  };
};

export type CreateImportLicensingExtraDocumentMutationVariables = Exact<{
  input: DtoCreateImportLicensingExtraDocumentInput;
}>;

export type CreateImportLicensingExtraDocumentMutation = {
  __typename?: 'Mutation';
  createImportLicensingExtraDocument: {
    __typename?: 'DtoReturnImportLicensingContent';
    id: string;
  };
};

export type CreateImportLicensingContentMutationVariables = Exact<{
  input: DtoCreateImportLicensingContentInput;
}>;

export type CreateImportLicensingContentMutation = {
  __typename?: 'Mutation';
  createImportLicensingContent: {
    __typename?: 'DtoReturnImportLicensingContent';
    brandKrName?: string | null;
    brandName?: string | null;
    completeAt?: any | null;
    contentKrName?: string | null;
    contentName: string;
    createdAt: any;
    desiredSalesPrice: number;
    id: string;
    manufacturerKrName?: string | null;
    manufacturerName?: string | null;
    salesPrice: number;
    documentList?: Array<{
      __typename?: 'DtoReturnImportLicensingDocument';
      completedAt?: any | null;
      createdAt: any;
      documentName: string;
      id: string;
      status: ImportLicensingDocumentStatusEnum;
    }> | null;
  };
};

export type CreateImportLicensingFileUploadMutationVariables = Exact<{
  input: DtoCreateImportLicensingFileUploadInput;
}>;

export type CreateImportLicensingFileUploadMutation = {
  __typename?: 'Mutation';
  createImportLicensingFileUpload: boolean;
};

export type UpdateImportLicensingMutationVariables = Exact<{
  input: DtoUpdateImportLicensingInput;
}>;

export type UpdateImportLicensingMutation = {
  __typename?: 'Mutation';
  updateImportLicensing: { __typename?: 'DtoReturnImportLicensing'; id: string };
};

export type ExportExcelImportLicensingEstimateMutationVariables = Exact<{
  importLicenseId: Scalars['ID'];
}>;

export type ExportExcelImportLicensingEstimateMutation = {
  __typename?: 'Mutation';
  exportExcelImportLicensingEstimate: string;
};

export type ImportLicensingLicensingEstimateListQueryVariables = Exact<{
  importLicensingLicensingEstimateListId: Scalars['ID'];
}>;

export type ImportLicensingLicensingEstimateListQuery = {
  __typename?: 'Query';
  importLicensingLicensingEstimateList: {
    __typename?: 'DtoReturnImportLicensingEstimateList';
    total: number;
    nodes?: Array<{
      __typename?: 'DtoReturnImportLicensingEstimate';
      count: number;
      document: string;
      id: string;
      itemName: string;
      price: number;
      unit: string;
      vat: number;
    }> | null;
  };
};

export type ImportLicensingCommentListQueryVariables = Exact<{
  input: DtoImportLicensingCommentListInput;
}>;

export type ImportLicensingCommentListQuery = {
  __typename?: 'Query';
  importLicensingCommentList: {
    __typename?: 'DtoReturnImportLicensingCommentList';
    total: number;
    nodes: Array<{
      __typename?: 'DtoReturnImportLicensingComment';
      comment: string;
      createdAt: any;
      id: string;
      writer: {
        __typename?: 'ReturnImportLicensingCommentWriterType';
        writerName: string;
      };
    }>;
  };
};

export type ImportLicensingContentListQueryVariables = Exact<{
  input: DtoImportLicensingContentListInput;
}>;

export type ImportLicensingContentListQuery = {
  __typename?: 'Query';
  importLicensingContentList: {
    __typename?: 'DtoReturnImportLicensingContentList';
    total: number;
    nodes: Array<{
      __typename?: 'DtoReturnImportLicensingContent';
      brandKrName?: string | null;
      brandName?: string | null;
      completeAt?: any | null;
      contentKrName?: string | null;
      contentName: string;
      createdAt: any;
      desiredSalesPrice: number;
      id: string;
      manufacturerKrName?: string | null;
      manufacturerName?: string | null;
      salesPrice: number;
      documentList?: Array<{
        __typename?: 'DtoReturnImportLicensingDocument';
        completedAt?: any | null;
        createdAt: any;
        documentName: string;
        id: string;
        status: ImportLicensingDocumentStatusEnum;
      }> | null;
    }>;
  };
};

export type ImportLicensingDocumentQueryVariables = Exact<{
  importLicensingDocumentId: Scalars['ID'];
}>;

export type ImportLicensingDocumentQuery = {
  __typename?: 'Query';
  importLicensingDocument: {
    __typename?: 'DtoReturnImportLicensingDocument';
    completedAt?: any | null;
    createdAt: any;
    documentName: string;
    id: string;
    status: ImportLicensingDocumentStatusEnum;
    updatedAt: any;
    warnings: Array<string>;
    exampleFileLink?: string | null;
    formFileLink?: string | null;
    documentGroupList: Array<{
      __typename?: 'ReturnImportLicensingContentDocumentGroupType';
      commentCount: number;
      newCommentStatus: boolean;
      id: string;
      createdAt: any;
      fileList: Array<{
        __typename?: 'ReturnImportLicensingContentDocumentFileType';
        createdAt: any;
        extension: string;
        fileName: string;
        id: string;
        link: string;
      }>;
    }>;
  };
};

export type ImportLicensingQueryVariables = Exact<{
  importLicensingId: Scalars['ID'];
}>;

export type ImportLicensingQuery = {
  __typename?: 'Query';
  importLicensing: {
    __typename?: 'DtoReturnImportLicensing';
    countryId: CountryEnum;
    documentDeliveryStatus: ImportLicensingDocumentStatusEnum;
    documentPerProductStatus: ImportLicensingDocumentStatusEnum;
    doneStatus: ImportLicensingDocumentStatusEnum;
    estimateStatus: ImportLicensingDocumentStatusEnum;
    importLicenseName: string;
    extraDocumentStatus: ImportLicensingDocumentStatusEnum;
    id: string;
    productCreateStatus: ImportLicensingDocumentStatusEnum;
    isRequestTaxInvoice: boolean;
    notarizationFileLink?: string | null;
    requiredPeriod: number;
    step: ImportLicensingSheetStepEnum;
    company: { __typename?: 'ReturnImportLicensingItemCompanyType'; id: string };
  };
};

export type ImportLicensingRequiredOriginalDocumentsQueryVariables = Exact<{
  importLicenseId: Scalars['ID'];
}>;

export type ImportLicensingRequiredOriginalDocumentsQuery = {
  __typename?: 'Query';
  importLicensingRequiredOriginalDocuments: {
    __typename?: 'DtoReturnImportLicensingRequireOriginalDocumentList';
    extraDocuments: Array<{
      __typename?: 'DocumentType';
      documentName: string;
      message?: string | null;
      id: string;
    }>;
    documentsByProduct: Array<{
      __typename?: 'ContentType';
      id: string;
      productName: string;
      documents: Array<{ __typename?: 'DocumentType'; documentName: string; id: string }>;
    }>;
  };
};

export type CreateImportLicensingMutationVariables = Exact<{
  input: DtoCreateImportLicensingInput;
}>;

export type CreateImportLicensingMutation = {
  __typename?: 'Mutation';
  createImportLicensing: {
    __typename?: 'DtoReturnImportLicensing';
    countryId: CountryEnum;
    createdAt: any;
    id: string;
    importLicenseName: string;
    managerName?: string | null;
    notarizationFileId?: string | null;
    step: ImportLicensingSheetStepEnum;
    company: {
      __typename?: 'ReturnImportLicensingItemCompanyType';
      companyKrName: string;
      companyName: string;
    };
  };
};

export type ImportLicensingListQueryVariables = Exact<{
  input: DtoImportLicensingListInput;
}>;

export type ImportLicensingListQuery = {
  __typename?: 'Query';
  importLicensingList: {
    __typename?: 'DtoReturnImportLicensingList';
    total: number;
    nodes: Array<{
      __typename?: 'DtoReturnImportLicensing';
      countryId: CountryEnum;
      createdAt: any;
      id: string;
      importLicenseName: string;
      managerName?: string | null;
      notarizationFileId?: string | null;
      step: ImportLicensingSheetStepEnum;
      company: {
        __typename?: 'ReturnImportLicensingItemCompanyType';
        companyKrName: string;
        companyName: string;
      };
    }>;
  };
};

export type ImportLicensingTemplateListQueryVariables = Exact<{
  input: DtoImportLicensingTemplateListInput;
}>;

export type ImportLicensingTemplateListQuery = {
  __typename?: 'Query';
  importLicensingTemplateList: {
    __typename?: 'DtoReturnImportLicensingTemplateList';
    total: number;
    nodes: Array<{
      __typename?: 'DtoReturnImportLicensingTemplate';
      categoryName: string;
      countryId: CountryEnum;
      id: string;
      templateContent: string;
      templateName: string;
      createdAt: any;
      updatedAt: any;
    }>;
  };
};

export type CancelOrderDetailMutationVariables = Exact<{
  cancelOrderDetailId: Scalars['ID'];
}>;

export type CancelOrderDetailMutation = {
  __typename?: 'Mutation';
  cancelOrderDetail: { __typename?: 'B2bOrderDetailProductType'; id: string };
};

export type UploadB2bOrderDetailsMutationVariables = Exact<{
  file: Scalars['Upload'];
  uploadB2BOrderDetailsId: Scalars['ID'];
}>;

export type UploadB2bOrderDetailsMutation = {
  __typename?: 'Mutation';
  uploadB2bOrderDetails: boolean;
};

export type ExportB2bOrderDetailExcelMutationVariables = Exact<{
  exportB2BOrderDetailExcelId: Scalars['ID'];
}>;

export type ExportB2bOrderDetailExcelMutation = {
  __typename?: 'Mutation';
  exportB2bOrderDetailExcel: string;
};

export type RequestConfirmB2bOrderMutationVariables = Exact<{
  requestConfirmB2BOrderId: Scalars['ID'];
}>;

export type RequestConfirmB2bOrderMutation = {
  __typename?: 'Mutation';
  requestConfirmB2bOrder: {
    __typename?: 'DtoReturnB2bOrder';
    createdAt: any;
    id: string;
    isDisable: boolean;
    receiverAddress: string;
    receiverContactInformation: string;
    receiverName: string;
    status: B2bOrderStatusEnum;
    totalOrderQuantity: number;
    totalPrice: number;
    updatedAt: any;
    orderContract: {
      __typename?: 'B2bOrderContractType';
      id: string;
      buyerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
      gomiManager: {
        __typename?: 'B2bOrderUserType';
        id?: string | null;
        userName?: string | null;
      };
      sellerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
    };
    orderDetails: Array<{
      __typename?: 'B2bOrderDetailType';
      b2bOrderId: string;
      createdAt: any;
      id: string;
      inventoryQuantity: number;
      orderQuantity: number;
      purchaseUnitPrice: number;
      retailPrice: number;
      salesPrice: number;
      totalPrice: number;
      updatedAt: any;
      deletedAt?: any | null;
      product: {
        __typename?: 'B2bOrderDetailProductType';
        barcode?: string | null;
        id: string;
        innerBoxQuantity: number;
        outerBoxQuantity: number;
        productKrName: string;
        productName: string;
      };
    }>;
  };
};

export type UpdateDisableB2bOrderMutationVariables = Exact<{
  disableB2BOrderId: Scalars['ID'];
}>;

export type UpdateDisableB2bOrderMutation = {
  __typename?: 'Mutation';
  updateDisableB2bOrder: {
    __typename?: 'DtoReturnB2bOrder';
    createdAt: any;
    id: string;
    isDisable: boolean;
    receiverAddress: string;
    receiverContactInformation: string;
    receiverName: string;
    status: B2bOrderStatusEnum;
    totalOrderQuantity: number;
    totalPrice: number;
    updatedAt: any;
    orderContract: {
      __typename?: 'B2bOrderContractType';
      id: string;
      buyerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
      gomiManager: {
        __typename?: 'B2bOrderUserType';
        id?: string | null;
        userName?: string | null;
      };
      sellerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
    };
    orderDetails: Array<{
      __typename?: 'B2bOrderDetailType';
      b2bOrderId: string;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      inventoryQuantity: number;
      orderQuantity: number;
      purchaseUnitPrice: number;
      retailPrice: number;
      salesPrice: number;
      totalPrice: number;
      updatedAt: any;
      product: {
        __typename?: 'B2bOrderDetailProductType';
        id: string;
        innerBoxQuantity: number;
        outerBoxQuantity: number;
        productKrName: string;
        productName: string;
      };
    }>;
  };
};

export type CreateB2bOrderMutationVariables = Exact<{
  input: DtoCreateB2bOrderInput;
}>;

export type CreateB2bOrderMutation = {
  __typename?: 'Mutation';
  createB2bOrder: {
    __typename?: 'DtoReturnB2bOrder';
    createdAt: any;
    id: string;
    isDisable: boolean;
    receiverAddress: string;
    receiverContactInformation: string;
    receiverName: string;
    status: B2bOrderStatusEnum;
    totalOrderQuantity: number;
    totalPrice: number;
    updatedAt: any;
    orderContract: {
      __typename?: 'B2bOrderContractType';
      id: string;
      buyerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
      gomiManager: {
        __typename?: 'B2bOrderUserType';
        id?: string | null;
        userName?: string | null;
      };
      sellerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
    };
    orderDetails: Array<{
      __typename?: 'B2bOrderDetailType';
      b2bOrderId: string;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      inventoryQuantity: number;
      orderQuantity: number;
      purchaseUnitPrice: number;
      retailPrice: number;
      salesPrice: number;
      totalPrice: number;
      updatedAt: any;
      product: {
        __typename?: 'B2bOrderDetailProductType';
        id: string;
        innerBoxQuantity: number;
        outerBoxQuantity: number;
        productKrName: string;
        productName: string;
      };
    }>;
  };
};

export type RegisterB2bOrderMutationVariables = Exact<{
  input: DtoRegisterB2bOrderInput;
}>;

export type RegisterB2bOrderMutation = {
  __typename?: 'Mutation';
  registerB2bOrder: {
    __typename?: 'DtoReturnB2bOrder';
    createdAt: any;
    id: string;
    isDisable: boolean;
    receiverAddress: string;
    receiverContactInformation: string;
    receiverName: string;
    status: B2bOrderStatusEnum;
    totalOrderQuantity: number;
    totalPrice: number;
    updatedAt: any;
    orderContract: {
      __typename?: 'B2bOrderContractType';
      id: string;
      buyerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
      gomiManager: {
        __typename?: 'B2bOrderUserType';
        id?: string | null;
        userName?: string | null;
      };
      sellerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
    };
    orderDetails: Array<{
      __typename?: 'B2bOrderDetailType';
      b2bOrderId: string;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      inventoryQuantity: number;
      orderQuantity: number;
      purchaseUnitPrice: number;
      retailPrice: number;
      salesPrice: number;
      totalPrice: number;
      updatedAt: any;
      product: {
        __typename?: 'B2bOrderDetailProductType';
        barcode?: string | null;
        id: string;
        innerBoxQuantity: number;
        outerBoxQuantity: number;
        productKrName: string;
        productName: string;
      };
    }>;
  };
};

export type ConfirmB2bOrderBySellerMutationVariables = Exact<{
  input: DtoConfirmB2bOrderInput;
}>;

export type ConfirmB2bOrderBySellerMutation = {
  __typename?: 'Mutation';
  confirmB2bOrderBySeller: { __typename?: 'DtoReturnB2bOrder'; id: string };
};

export type ConfirmB2bOrderByBuyerMutationVariables = Exact<{
  confirmB2BOrderByBuyerId: Scalars['ID'];
}>;

export type ConfirmB2bOrderByBuyerMutation = {
  __typename?: 'Mutation';
  confirmB2bOrderByBuyer: { __typename?: 'DtoReturnB2bOrder'; id: string };
};

export type UpdateB2bOrderByEstimatedArrivalDateMutationVariables = Exact<{
  input: DtoUpdateB2bOrderEstimatedArrivalDateInput;
}>;

export type UpdateB2bOrderByEstimatedArrivalDateMutation = {
  __typename?: 'Mutation';
  updateB2bOrderByEstimatedArrivalDate: {
    __typename?: 'DtoReturnB2bOrder';
    createdAt: any;
    id: string;
    isDisable: boolean;
    estimatedArrivalDate?: any | null;
    receiverAddress: string;
    receiverContactInformation: string;
    receiverName: string;
    status: B2bOrderStatusEnum;
    totalOrderQuantity: number;
    totalPrice: number;
    updatedAt: any;
    orderContract: {
      __typename?: 'B2bOrderContractType';
      id: string;
      buyerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
      gomiManager: {
        __typename?: 'B2bOrderUserType';
        id?: string | null;
        userName?: string | null;
      };
      sellerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
    };
    orderDetails: Array<{
      __typename?: 'B2bOrderDetailType';
      b2bOrderId: string;
      createdAt: any;
      id: string;
      inventoryQuantity: number;
      orderQuantity: number;
      purchaseUnitPrice: number;
      retailPrice: number;
      salesPrice: number;
      totalPrice: number;
      updatedAt: any;
      product: {
        __typename?: 'B2bOrderDetailProductType';
        barcode?: string | null;
        id: string;
        innerBoxQuantity: number;
        outerBoxQuantity: number;
        productKrName: string;
        productName: string;
      };
    }>;
  };
};

export type UpdateB2bOrderSellerBankDepositConfirmationMutationVariables = Exact<{
  input: DtoUpdateB2bOrderBankDepositInput;
}>;

export type UpdateB2bOrderSellerBankDepositConfirmationMutation = {
  __typename?: 'Mutation';
  updateB2bOrderSellerBankDepositConfirmation: {
    __typename?: 'DtoReturnB2bOrder';
    completedAt?: any | null;
    createdAt: any;
    deletedAt?: any | null;
    estimatedArrivalDate?: any | null;
    id: string;
    isDisable: boolean;
    receiverAddress: string;
    receiverContactInformation: string;
    receiverName: string;
    status: B2bOrderStatusEnum;
    totalOrderQuantity: number;
    totalPrice: number;
    updatedAt: any;
    buyerDepositConfirmationFile?: {
      __typename?: 'B2bOrderFileType';
      fileExtension: string;
      fileKey: string;
      fileName: string;
      id: string;
      link: string;
      type: FilePathEnum;
    } | null;
    orderContract: {
      __typename?: 'B2bOrderContractType';
      id: string;
      buyerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
      gomiManager: {
        __typename?: 'B2bOrderUserType';
        email?: string | null;
        id?: string | null;
        userName?: string | null;
      };
      sellerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
    };
    orderDetails: Array<{
      __typename?: 'B2bOrderDetailType';
      b2bOrderId: string;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      inventoryQuantity: number;
      orderQuantity: number;
      purchaseUnitPrice: number;
      retailPrice: number;
      salesPrice: number;
      totalPrice: number;
      updatedAt: any;
      product: {
        __typename?: 'B2bOrderDetailProductType';
        barcode?: string | null;
        id: string;
        innerBoxQuantity: number;
        outerBoxQuantity: number;
        productKrName: string;
        productName: string;
      };
    }>;
  };
};

export type UpdateB2bOrderBuyerBankDepositConfirmationMutationVariables = Exact<{
  input: DtoUpdateB2bOrderBankDepositInput;
}>;

export type UpdateB2bOrderBuyerBankDepositConfirmationMutation = {
  __typename?: 'Mutation';
  updateB2bOrderBuyerBankDepositConfirmation: {
    __typename?: 'DtoReturnB2bOrder';
    completedAt?: any | null;
    createdAt: any;
    deletedAt?: any | null;
    estimatedArrivalDate?: any | null;
    id: string;
    isDisable: boolean;
    receiverAddress: string;
    receiverContactInformation: string;
    receiverName: string;
    status: B2bOrderStatusEnum;
    totalOrderQuantity: number;
    totalPrice: number;
    updatedAt: any;
    buyerDepositConfirmationFile?: {
      __typename?: 'B2bOrderFileType';
      fileExtension: string;
      fileKey: string;
      fileName: string;
      id: string;
      link: string;
      type: FilePathEnum;
    } | null;
    orderContract: {
      __typename?: 'B2bOrderContractType';
      id: string;
      buyerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
      gomiManager: {
        __typename?: 'B2bOrderUserType';
        email?: string | null;
        id?: string | null;
        userName?: string | null;
      };
      sellerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
    };
    orderDetails: Array<{
      __typename?: 'B2bOrderDetailType';
      b2bOrderId: string;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      inventoryQuantity: number;
      orderQuantity: number;
      purchaseUnitPrice: number;
      retailPrice: number;
      salesPrice: number;
      totalPrice: number;
      updatedAt: any;
      product: {
        __typename?: 'B2bOrderDetailProductType';
        barcode?: string | null;
        id: string;
        innerBoxQuantity: number;
        outerBoxQuantity: number;
        productKrName: string;
        productName: string;
      };
    }>;
  };
};

export type UpdateDoneB2bOrderMutationVariables = Exact<{
  updateDoneB2BOrderId: Scalars['ID'];
}>;

export type UpdateDoneB2bOrderMutation = {
  __typename?: 'Mutation';
  updateDoneB2bOrder: {
    __typename?: 'DtoReturnB2bOrder';
    completedAt?: any | null;
    createdAt: any;
    deletedAt?: any | null;
    estimatedArrivalDate?: any | null;
    id: string;
    isDisable: boolean;
    receiverAddress: string;
    receiverContactInformation: string;
    receiverName: string;
    status: B2bOrderStatusEnum;
    totalOrderQuantity: number;
    totalPrice: number;
    updatedAt: any;
    buyerDepositConfirmationFile?: {
      __typename?: 'B2bOrderFileType';
      fileExtension: string;
      fileKey: string;
      fileName: string;
      id: string;
      link: string;
      type: FilePathEnum;
    } | null;
    orderContract: {
      __typename?: 'B2bOrderContractType';
      id: string;
      buyerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
      gomiManager: {
        __typename?: 'B2bOrderUserType';
        email?: string | null;
        id?: string | null;
        userName?: string | null;
      };
      sellerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
    };
    orderDetails: Array<{
      __typename?: 'B2bOrderDetailType';
      b2bOrderId: string;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      inventoryQuantity: number;
      orderQuantity: number;
      purchaseUnitPrice: number;
      retailPrice: number;
      salesPrice: number;
      totalPrice: number;
      updatedAt: any;
      product: {
        __typename?: 'B2bOrderDetailProductType';
        barcode?: string | null;
        id: string;
        innerBoxQuantity: number;
        outerBoxQuantity: number;
        productKrName: string;
        productName: string;
      };
    }>;
  };
};

export type B2bOrderListQueryVariables = Exact<{
  input: DtoB2bOrderListInput;
}>;

export type B2bOrderListQuery = {
  __typename?: 'Query';
  b2bOrderList: {
    __typename?: 'DtoReturnB2bOrderList';
    total: number;
    nodes: Array<{
      __typename?: 'DtoReturnB2bOrder';
      id: string;
      createdAt: any;
      isDisable: boolean;
      status: B2bOrderStatusEnum;
      totalPrice: number;
      orderContract: {
        __typename?: 'B2bOrderContractType';
        id: string;
        buyerCompany: {
          __typename?: 'B2bOrderContractCompanyType';
          companyKrName?: string | null;
          companyName?: string | null;
          id?: string | null;
        };
        gomiManager: {
          __typename?: 'B2bOrderUserType';
          id?: string | null;
          userName?: string | null;
        };
        sellerCompany: {
          __typename?: 'B2bOrderContractCompanyType';
          companyKrName?: string | null;
          companyName?: string | null;
          id?: string | null;
        };
      };
      orderDetails: Array<{
        __typename?: 'B2bOrderDetailType';
        id: string;
        inventoryQuantity: number;
        orderQuantity: number;
      }>;
    }>;
  };
};

export type B2bOrderSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type B2bOrderSummaryQuery = {
  __typename?: 'Query';
  b2bOrderSummary: {
    __typename?: 'DtoReturnB2bOrderSummary';
    disableCount: number;
    doneCount: number;
    inProgressCount: number;
    totalPrice: number;
    totalQuantity: number;
  };
};

export type B2bOrderQueryVariables = Exact<{
  b2BOrderId: Scalars['ID'];
}>;

export type B2bOrderQuery = {
  __typename?: 'Query';
  b2bOrder: {
    __typename?: 'DtoReturnB2bOrder';
    completedAt?: any | null;
    createdAt: any;
    deletedAt?: any | null;
    estimatedArrivalDate?: any | null;
    id: string;
    isDisable: boolean;
    receiverAddress: string;
    receiverContactInformation: string;
    receiverName: string;
    status: B2bOrderStatusEnum;
    totalOrderQuantity: number;
    totalPrice: number;
    updatedAt: any;
    buyerDepositConfirmationFile?: {
      __typename?: 'B2bOrderFileType';
      fileExtension: string;
      fileKey: string;
      fileName: string;
      id: string;
      link: string;
      type: FilePathEnum;
    } | null;
    orderConfirmDocumentFile?: {
      __typename?: 'B2bOrderFileType';
      fileExtension: string;
      fileKey: string;
      fileName: string;
      id: string;
      link: string;
      type: FilePathEnum;
    } | null;
    orderContract: {
      __typename?: 'B2bOrderContractType';
      id: string;
      buyerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
      gomiManager: {
        __typename?: 'B2bOrderUserType';
        email?: string | null;
        id?: string | null;
        userName?: string | null;
      };
      sellerCompany: {
        __typename?: 'B2bOrderContractCompanyType';
        companyKrName?: string | null;
        companyName?: string | null;
        id?: string | null;
      };
    };
    orderDetails: Array<{
      __typename?: 'B2bOrderDetailType';
      b2bOrderId: string;
      createdAt: any;
      id: string;
      inventoryQuantity: number;
      orderQuantity: number;
      purchaseUnitPrice: number;
      retailPrice: number;
      salesPrice: number;
      totalPrice: number;
      updatedAt: any;
      product: {
        __typename?: 'B2bOrderDetailProductType';
        barcode?: string | null;
        id: string;
        innerBoxQuantity: number;
        outerBoxQuantity: number;
        productKrName: string;
        productName: string;
      };
    }>;
    orderDocumentFile?: {
      __typename?: 'B2bOrderFileType';
      fileExtension: string;
      fileKey: string;
      fileName: string;
      id: string;
      link: string;
      type: FilePathEnum;
    } | null;
    sellerDepositConfirmationFile?: {
      __typename?: 'B2bOrderFileType';
      fileExtension: string;
      fileKey: string;
      fileName: string;
      id: string;
      link: string;
      type: FilePathEnum;
    } | null;
  };
};

export type B2bOrderContractsQueryVariables = Exact<{ [key: string]: never }>;

export type B2bOrderContractsQuery = {
  __typename?: 'Query';
  b2bOrderContracts: Array<{
    __typename?: 'DtoReturnB2bOrderContract';
    id: string;
    isDisconnect: boolean;
    buyerCompany: {
      __typename?: 'ReturnB2bOrderContractCompanyType';
      companyKrName: string;
      companyName: string;
      id: string;
    };
    sellerCompany: {
      __typename?: 'ReturnB2bOrderContractCompanyType';
      companyKrName: string;
      companyName: string;
      id: string;
    };
  }>;
};

export type CompanyByPartnerQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyByPartnerQuery = {
  __typename?: 'Query';
  companyByPartner: {
    __typename?: 'DtoReturnCompany';
    accountNumber?: string | null;
    address?: string | null;
    bankName?: string | null;
    ceoName?: string | null;
    companyCode?: string | null;
    companyKrName: string;
    companyName: string;
    corporateRegistrationNumber?: string | null;
    countryId: CountryEnum;
    digitalTaxInvoiceEmail?: string | null;
    id: string;
    registrationNumber: string;
    salesType?: SalesTypeEnum | null;
    updatedAt: any;
    countryOfEntry?: Array<{
      __typename?: 'DtoReturnCountry';
      countryId: CountryEnum;
      countryName: string;
    }> | null;
    companyManagers?: Array<{
      __typename?: 'ReturnCompanyManagerType';
      userName: string;
      id: string;
    }> | null;
  };
};

export type ExportProductsV2MutationVariables = Exact<{
  input: DtoFindProducts;
}>;

export type ExportProductsV2Mutation = {
  __typename?: 'Mutation';
  exportProductsV2: string;
};

export type FilteredBrandsByProductV2QueryVariables = Exact<{ [key: string]: never }>;

export type FilteredBrandsByProductV2Query = {
  __typename?: 'Query';
  filteredBrandsByProductV2: Array<{
    __typename?: 'DtoReturnBrand';
    brandCode?: string | null;
    brandKrName: string;
    brandName: string;
    id: string;
  }>;
};

export type SalesCodesQueryVariables = Exact<{
  input: GetSalesCodesInputDto;
}>;

export type SalesCodesQuery = {
  __typename?: 'Query';
  salesCodes: Array<{
    __typename?: 'ReturnSalesCodesOutputDto';
    name: string;
    salesCode: string;
  }>;
};

export type ProductQueryVariables = Exact<{
  productId: Scalars['ID'];
}>;

export type ProductQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'DtoReturnProductV2';
    barcode?: string | null;
    discountSalePriceThA?: number | null;
    discountSalePriceThB?: number | null;
    discountSalePriceThS?: number | null;
    discountSalePriceVnA?: number | null;
    discountSalePriceVnB?: number | null;
    discountSalePriceVnS?: number | null;
    gspc: string;
    hsCode?: string | null;
    innerBoxCount?: number | null;
    outerBoxCount?: number | null;
    id: string;
    productGroupId: string;
    productKeyword?: string | null;
    productKrName: string;
    productName: string;
    purchaseUnitPrice?: number | null;
    regularSalePriceTh?: number | null;
    regularSalePriceVn?: number | null;
    retailPriceKr?: number | null;
    retailPriceTh?: number | null;
    retailPriceVn?: number | null;
    supplierProductCode?: string | null;
    updatedAt: any;
    productGroup: {
      __typename?: 'ReturnProductGroupType';
      category?: string | null;
      productGroupKrName?: string | null;
      productGroupKrNameKeyword?: string | null;
      productGroupName: string;
      productGroupNameKeyword?: string | null;
      brand?: {
        __typename?: 'ReturnBrandType';
        countryIds: Array<CountryEnum>;
        id: string;
        brandName: string;
        brandKrName: string;
      } | null;
      company?: {
        __typename?: 'ReturnCompanyType';
        id: string;
        companyName: string;
        companyKrName: string;
      } | null;
    };
    productOptions: Array<{
      __typename?: 'ReturnProductOptionType';
      productOptionName: string;
      productOptionRelId: string;
      id: string;
      productOptionGroup: {
        __typename?: 'ReturnProductOptionGroupType';
        productOptionGroupName: string;
        id: string;
      };
    }>;
  };
};

export type ProductListQueryVariables = Exact<{
  input: DtoFindProducts;
}>;

export type ProductListQuery = {
  __typename?: 'Query';
  productList: {
    __typename?: 'DtoReturnProductList';
    total: number;
    nodes: Array<{
      __typename?: 'DtoReturnProductV2';
      barcode?: string | null;
      gspc: string;
      id: string;
      productGroupId: string;
      productKrName: string;
      productName: string;
      supplierProductCode?: string | null;
      updatedAt: any;
      productGroup: {
        __typename?: 'ReturnProductGroupType';
        productGroupKrName?: string | null;
        productGroupName: string;
        brand?: {
          __typename?: 'ReturnBrandType';
          id: string;
          brandName: string;
          brandKrName: string;
        } | null;
      };
      productOptions: Array<{
        __typename?: 'ReturnProductOptionType';
        id: string;
        productOptionName: string;
        productOptionRelId: string;
        productOptionGroup: {
          __typename?: 'ReturnProductOptionGroupType';
          productOptionGroupName: string;
          id: string;
        };
      }>;
    }>;
  };
};

export type ReportExportExcelV2MutationVariables = Exact<{
  input: DtoExportOrderSalesInfoExcel;
}>;

export type ReportExportExcelV2Mutation = {
  __typename?: 'Mutation';
  reportExportExcelV2: string;
};

export type StockLogsExportExcelMutationVariables = Exact<{
  input: GetStockLogInputDto;
}>;

export type StockLogsExportExcelMutation = {
  __typename?: 'Mutation';
  stockLogsExportExcel: string;
};

export type StockExportExcelMutationVariables = Exact<{
  input: GetStockInputDto;
}>;

export type StockExportExcelMutation = {
  __typename?: 'Mutation';
  stockExportExcel: string;
};

export type SalesStatusByDateQueryVariables = Exact<{
  input: DtoFindOrderItems;
}>;

export type SalesStatusByDateQuery = {
  __typename?: 'Query';
  salesStatusByDate: {
    __typename?: 'DtoReturnOrderSalesStatusByDate';
    node: Array<{
      __typename?: 'SalesStatusByDate';
      date?: any | null;
      statuses: Array<{
        __typename?: 'SalesStatusByDateOrderStatusType';
        orderStatus: SettlementOrderStatus;
        channels: Array<{
          __typename?: 'SalesStatusByDateChannelType';
          amount: number;
          channelName: OrderChannelEnum;
          count: number;
        }>;
      }>;
    }>;
  };
};

export type SalesStatusByProductQueryVariables = Exact<{
  input: DtoFindOrderItems;
}>;

export type SalesStatusByProductQuery = {
  __typename?: 'Query';
  salesStatusByProduct: {
    __typename?: 'DtoReturnOrderSalesStatusByProduct';
    node: Array<{
      __typename?: 'SalesStatusByProductType';
      productName: string;
      prices: Array<{
        __typename?: 'PriceType';
        price: number;
        channels: Array<{
          __typename?: 'ChannelType';
          channel: OrderChannelEnum;
          orderStatuses: Array<{
            __typename?: 'OrderStatusType';
            orderStatus: SettlementOrderStatus;
            orderCountPrice: {
              __typename?: 'OrderCountPriceType';
              count: number;
              totalPrice: number;
            };
          }>;
        }>;
      }>;
    }>;
  };
};

export type ShippingStatusCountQueryVariables = Exact<{
  input: DtoFindOrderItems;
}>;

export type ShippingStatusCountQuery = {
  __typename?: 'Query';
  shippingStatusCount: {
    __typename?: 'DtoReturnOrderSalesShippingStatusCount';
    delivered: number;
    delivering: number;
    readytopick: number;
    return: number;
  };
};

export type PaymentStatusCountQueryVariables = Exact<{
  input: DtoFindOrderItems;
}>;

export type PaymentStatusCountQuery = {
  __typename?: 'Query';
  paymentStatusCount: {
    __typename?: 'DtoReturnOrderPaymentStatusCount';
    not_paid: number;
    paid: number;
    refund: number;
  };
};

export type SalesStatusCountQueryVariables = Exact<{
  input: DtoFindOrderItems;
}>;

export type SalesStatusCountQuery = {
  __typename?: 'Query';
  salesStatusCount: {
    __typename?: 'DtoReturnOrderSalesStatusCount';
    cancel: number;
    ok: number;
  };
};

export type SalesAmountByProductQueryVariables = Exact<{
  input: DtoFindOrderItems;
}>;

export type SalesAmountByProductQuery = {
  __typename?: 'Query';
  salesAmountByProduct: {
    __typename?: 'DtoReturnOrderSalesAmountByProduct';
    node: Array<{
      __typename?: 'ProductAmountType';
      productName: string;
      channels: Array<{
        __typename?: 'SalesAmountChannelType';
        channelName: OrderChannelEnum;
        salesAmount: number;
      }>;
    }>;
  };
};

export type SalesStatusQueryVariables = Exact<{
  input: DtoFindOrderItems;
}>;

export type SalesStatusQuery = {
  __typename?: 'Query';
  salesStatus: Array<{
    __typename?: 'DtoReturnOrderSalesStatus';
    cancelOrderCount: number;
    confirmOrderCount: number;
    date: any;
    totalPrice: number;
  }>;
};

export type OrderDetailsQueryVariables = Exact<{
  input: DtoFindOrderItems;
}>;

export type OrderDetailsQuery = {
  __typename?: 'Query';
  orderDetails: {
    __typename?: 'DtoReturnPaginatedOrderItem';
    total: number;
    nodes: Array<{
      __typename?: 'DtoReturnOrderItem';
      brandId?: string | null;
      channel: OrderChannelEnum;
      displayChannel: string;
      count: number;
      countryId?: CountryEnum | null;
      gspc?: string | null;
      orderId: string;
      orderItemId?: string | null;
      orderNumber: string;
      orderStatus: SettlementOrderStatus;
      orderedAt: any;
      payMethod?: string | null;
      paymentStatus: OrderPaymentStatusEnum;
      originalPrice: number;
      voucher: number;
      price: number;
      productName?: string | null;
      salesCode: string;
      settlementYearMonth?: string | null;
      shippingStatus?: OrderShippingStatusEnum | null;
      totalPrice: number;
      brand?: {
        __typename?: 'OrderItemBrandType';
        brandKrName: string;
        brandName: string;
        id: string;
        company?: {
          __typename?: 'OrderItemCompanyType';
          id: string;
          companyName: string;
          companyKrName: string;
        } | null;
      } | null;
    }>;
    summary: {
      __typename?: 'OrderItemSummaryDto';
      totalCount: number;
      totalPrice: number;
    };
  };
};

export type ChannelsForReportQueryVariables = Exact<{
  input: DtoFindOrderItems;
}>;

export type ChannelsForReportQuery = {
  __typename?: 'Query';
  channelsForReport: Array<{
    __typename?: 'DtoReturnChannelsForReport';
    channelName: OrderChannelEnum;
    displayName: string;
  }>;
};

export type StockQueryVariables = Exact<{
  input: GetStockInputDto;
}>;

export type StockQuery = {
  __typename?: 'Query';
  stocks: {
    __typename?: 'ReturnPaginatedStockOutputDto';
    total: number;
    nodes: Array<{
      __typename?: 'ReturnStockOutputType';
      gspc?: string | null;
      inPickingQuantity: number;
      inProcessQuantity: number;
      incomingQuantity: number;
      location: StockLocationEnum;
      problemQuantity: number;
      bufferQuantity: number;
      productName?: string | null;
      quantity: number;
      unusableQuantity: number;
      usableQuantity: number;
    }>;
  };
};

export type StockLogsQueryVariables = Exact<{
  input: GetStockLogInputDto;
}>;

export type StockLogsQuery = {
  __typename?: 'Query';
  stockLogs: Array<{
    __typename?: 'ReturnStockLogOutputDto';
    productName?: string | null;
    quantity: number;
    orderedAt?: any | null;
  }>;
};

export type StocksQueryVariables = Exact<{
  stock: GetStockInputDto;
  stockIn: GetStockLogInputDto;
  stockOut: GetStockLogInputDto;
}>;

export type StocksQuery = {
  __typename?: 'Query';
  stocks: {
    __typename?: 'ReturnPaginatedStockOutputDto';
    total: number;
    nodes: Array<{
      __typename?: 'ReturnStockOutputType';
      gspc?: string | null;
      inPickingQuantity: number;
      inProcessQuantity: number;
      incomingQuantity: number;
      location: StockLocationEnum;
      problemQuantity: number;
      bufferQuantity: number;
      productName?: string | null;
      quantity: number;
      unusableQuantity: number;
      usableQuantity: number;
    }>;
  };
  stockIn: Array<{
    __typename?: 'ReturnStockLogOutputDto';
    productName?: string | null;
    quantity: number;
    orderedAt?: any | null;
  }>;
  stockOut: Array<{
    __typename?: 'ReturnStockLogOutputDto';
    productName?: string | null;
    quantity: number;
    orderedAt?: any | null;
  }>;
};

export type ExportExcelSettlementConsignmentPartnerByPartnerMutationVariables = Exact<{
  input: DtoGetSettlementConsignmentPartner;
}>;

export type ExportExcelSettlementConsignmentPartnerByPartnerMutation = {
  __typename?: 'Mutation';
  exportExcelSettlementConsignmentPartnerByPartner: string;
};

export type ExportExcelSettlementPartnerByPartnerMutationVariables = Exact<{
  input: DtoGetSettlementPartner;
}>;

export type ExportExcelSettlementPartnerByPartnerMutation = {
  __typename?: 'Mutation';
  exportExcelSettlementPartnerByPartner: string;
};

export type SettlementPartnerV2QueryVariables = Exact<{
  input: DtoGetSettlementPartnerId;
}>;

export type SettlementPartnerV2Query = {
  __typename?: 'Query';
  settlementPartnerV2: {
    __typename?: 'DtoReturnSettlementPartners';
    companyCode?: string | null;
    companyName: string;
    id: string;
    status: SettlementPartnerStatusEnum;
  };
};

export type MonthlyExchangeRateQueryVariables = Exact<{
  input: DtoGetMonthlyExchangeRate;
}>;

export type MonthlyExchangeRateQuery = {
  __typename?: 'Query';
  monthlyExchangeRate: {
    __typename?: 'ReturnExchangeRateDto';
    avgExchangeRate: number;
    countryId: CountryEnum;
    yearMonth: string;
    currencyCriteriaAmount: number;
  };
};

export type SettlementConsignmentPartnerSummaryByPartnerQueryVariables = Exact<{
  input: DtoGetSettlementConsignmentPartner;
}>;

export type SettlementConsignmentPartnerSummaryByPartnerQuery = {
  __typename?: 'Query';
  settlementConsignmentPartnerSummaryByPartner: {
    __typename?: 'DtoReturnSettlementConsignmentPartnerSummaryDto';
    companyName: string;
    fee: number;
    salesPrice: number;
    setoffPrice: number;
    settlementPrice: number;
    settlementYearMonth: string;
  };
};

export type SettlementConsignmentPartnerFeeSummaryByPartnerQueryVariables = Exact<{
  input: DtoGetSettlementConsignmentPartner;
}>;

export type SettlementConsignmentPartnerFeeSummaryByPartnerQuery = {
  __typename?: 'Query';
  settlementConsignmentPartnerFeeSummaryByPartner: {
    __typename?: 'DtoReturnSettlementConsignmentPartnerFeeSummary';
    totalFee: number;
    channels: Array<{
      __typename?: 'ReturnSettlementConsignmentPartnerFeeSummaryChannelType';
      channelName: string;
      fee: number;
      id: string;
    }>;
  };
};

export type SettlementConsignmentPartnerSalesPriceSummaryByPartnerQueryVariables = Exact<{
  input: DtoGetSettlementConsignmentPartner;
}>;

export type SettlementConsignmentPartnerSalesPriceSummaryByPartnerQuery = {
  __typename?: 'Query';
  settlementConsignmentPartnerSalesPriceSummaryByPartner: {
    __typename?: 'DtoReturnSettlementConsignmentPartnerSalesPriceSummary';
    totalSalesPrice: number;
    channels: Array<{
      __typename?: 'ReturnSettlementConsignmentPartnerSalesPriceSummaryChannelType';
      channelName: string;
      id: string;
      salesPrice: number;
    }>;
  };
};

export type SettlementConsignmentPartnerProductListByPartnerQueryVariables = Exact<{
  input: DtoGetSettlementConsignmentPartnerProductListInput;
}>;

export type SettlementConsignmentPartnerProductListByPartnerQuery = {
  __typename?: 'Query';
  settlementConsignmentPartnerProductListByPartner: {
    __typename?: 'DtoReturnSettlementCriteriaPartnerProductList';
    totalCountrySalesPrice: number;
    totalSalesCount: number;
    totalSalesPrice: number;
    channels: Array<{
      __typename?: 'ReturnSettlementConsignmentPartnerProductListChannelType';
      channelName: string;
      countrySalesPrice: number;
      id: string;
      salesCount: number;
    }>;
    nodes: Array<{
      __typename?: 'ReturnSettlementConsignmentPartnerProductListItemType';
      countrySalesPrice: number;
      productKrName: string;
      rowspan: number;
      salesCodeId: string;
      sumCountrySalesPrice: number;
      sumSalesCount: number;
      sumSalesPrice: number;
      channels: Array<{
        __typename?: 'ReturnSettlementConsignmentPartnerProductListItemChannelType';
        channelName: string;
        countrySalesPrice: number;
        feeRateV2: number;
        id: string;
        salesCount: number;
      }>;
    }>;
  };
};

export type SettlementConsignmentProductOrdersByPartnerQueryVariables = Exact<{
  input: DtoGetSettlementConsignmentProductOrders;
}>;

export type SettlementConsignmentProductOrdersByPartnerQuery = {
  __typename?: 'Query';
  settlementConsignmentProductOrdersByPartner: Array<{
    __typename?: 'DtoReturnSettlementConsignmentProductOrders';
    salesCount: number;
    countrySinglePrice: number;
    countrySalesPrice: number;
    orderNumber: string;
    orderedAt: any;
    channelName: string;
  }>;
};

export type AllChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type AllChannelsQuery = {
  __typename?: 'Query';
  allChannels: Array<{
    __typename?: 'DtoReturnChannel';
    channelName: string;
    id: string;
  }>;
};

export type SettlementProductSummaryByPartnerQueryVariables = Exact<{
  input: DtoGetSettlementProductSummary;
}>;

export type SettlementProductSummaryByPartnerQuery = {
  __typename?: 'Query';
  settlementProductSummaryByPartner: {
    __typename?: 'DtoReturnSettlementProductSummary';
    companyName: string;
    salesPrice: number;
    setoffPrice: number;
    settlementPrice: number;
    settlementYearMonth: string;
  };
};

export type SettlementProductListByPartnerQueryVariables = Exact<{
  input: DtoGetSettlementProductList;
}>;

export type SettlementProductListByPartnerQuery = {
  __typename?: 'Query';
  settlementProductListByPartner: {
    __typename?: 'DtoReturnSettlementProductList';
    totalCount: number;
    totalSettlementPrice: number;
    nodes?: Array<{
      __typename?: 'SettlementProduct';
      productId: string;
      productName: string;
      salesCount: number;
      salesPrice: number;
      settlementCriteriaPrice: number;
    }> | null;
  };
};

export type SettlementOrdersByPartnerQueryVariables = Exact<{
  input: DtoGetSettlementProductOrders;
}>;

export type SettlementOrdersByPartnerQuery = {
  __typename?: 'Query';
  settlementOrdersByPartner: Array<{
    __typename?: 'DtoReturnSettlementProductOrders';
    channelName: string;
    orderNumber: string;
    orderedAt: any;
    originalPrice: number;
    salesCount: number;
    salesPrice: number;
  }>;
};

export type PartnerLoginV3MutationVariables = Exact<{
  input: DtoGetLogin;
}>;

export type PartnerLoginV3Mutation = {
  __typename?: 'Mutation';
  partnerLoginV3: {
    __typename?: 'DtoReturnLogin';
    accessToken: string;
    authorizationList?: Array<string | null> | null;
    companyId?: string | null;
    email: string;
    id: number;
    role?: RoleEnum | null;
    userName: string;
  };
};

export type RegisterMutationVariables = Exact<{
  input: DtoRegisterUser;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  register: { __typename?: 'DtoReturnUser'; id: string };
};

export type SendPartnerPasswordChangeEmailV2MutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type SendPartnerPasswordChangeEmailV2Mutation = {
  __typename?: 'Mutation';
  sendPartnerPasswordChangeEmailV2?: boolean | null;
};

export type ChangePartnerPasswordV2MutationVariables = Exact<{
  authCode: Scalars['String'];
  password: Scalars['String'];
}>;

export type ChangePartnerPasswordV2Mutation = {
  __typename?: 'Mutation';
  changePartnerPasswordV2?: boolean | null;
};

export type GetPartnerInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetPartnerInfoQuery = {
  __typename?: 'Query';
  partnerMe: {
    __typename?: 'DtoReturnUser';
    authCode?: string | null;
    createdAt: any;
    email: string;
    id: string;
    phoneNumber?: string | null;
    role: RoleEnum;
    updatedAt: any;
    userCode?: string | null;
    authorizations: Array<AuthorizationEnum>;
    userName: string;
    company?: {
      __typename?: 'ReturnUserCompanyType';
      id: string;
      isBeforeReportV2: boolean;
      companyKrName: string;
      salesType?: SalesTypeEnum | null;
    } | null;
  };
};

export type ExistPartnerUserEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type ExistPartnerUserEmailQuery = {
  __typename?: 'Query';
  existPartnerUserEmail: boolean;
};

export const SendSlackDocument = `
    mutation SendSlack($input: SlackDto!) {
  sendSlack(input: $input)
}
    `;
export const useSendSlackMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SendSlackMutation,
    TError,
    SendSlackMutationVariables,
    TContext
  >,
) =>
  useMutation<SendSlackMutation, TError, SendSlackMutationVariables, TContext>(
    ['SendSlack'],
    (variables?: SendSlackMutationVariables) =>
      gqlFetcher<SendSlackMutation, SendSlackMutationVariables>(
        SendSlackDocument,
        variables,
      )(),
    options,
  );
export const ExportPartnerBrandListDocument = `
    mutation ExportPartnerBrandList($input: DtoFindBrands!) {
  exportPartnerBrandList(input: $input)
}
    `;
export const useExportPartnerBrandListMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ExportPartnerBrandListMutation,
    TError,
    ExportPartnerBrandListMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ExportPartnerBrandListMutation,
    TError,
    ExportPartnerBrandListMutationVariables,
    TContext
  >(
    ['ExportPartnerBrandList'],
    (variables?: ExportPartnerBrandListMutationVariables) =>
      gqlFetcher<ExportPartnerBrandListMutation, ExportPartnerBrandListMutationVariables>(
        ExportPartnerBrandListDocument,
        variables,
      )(),
    options,
  );
export const BrandListDocument = `
    query BrandList($input: DtoFindBrands!) {
  partnerBrandsV2(input: $input) {
    nodes {
      brandCode
      brandImage {
        fileName
        id
        link
        type
      }
      brandKrName
      brandName
      company {
        id
        companyName
        companyKrName
        companyCode
      }
      countryIds
      id
      managers {
        department {
          departmentName
          id
        }
        userName
        id
      }
      updatedAt
    }
    total
  }
}
    `;
export const useBrandListQuery = <TData = BrandListQuery, TError = unknown>(
  variables: BrandListQueryVariables,
  options?: UseQueryOptions<BrandListQuery, TError, TData>,
) =>
  useQuery<BrandListQuery, TError, TData>(
    ['BrandList', variables],
    gqlFetcher<BrandListQuery, BrandListQueryVariables>(BrandListDocument, variables),
    options,
  );

useBrandListQuery.getKey = (variables: BrandListQueryVariables) => [
  'BrandList',
  variables,
];
export const BrandDocument = `
    query Brand($brandId: ID!) {
  partnerBrand(id: $brandId) {
    brandCode
    updatedAt
    brandImage {
      fileName
      id
      link
      type
    }
    brandKrName
    brandName
    company {
      id
      companyKrName
      companyName
      companyCode
    }
    countryIds
    id
    managers {
      userName
      id
      department {
        departmentName
        id
      }
    }
  }
}
    `;
export const useBrandQuery = <TData = BrandQuery, TError = unknown>(
  variables: BrandQueryVariables,
  options?: UseQueryOptions<BrandQuery, TError, TData>,
) =>
  useQuery<BrandQuery, TError, TData>(
    ['Brand', variables],
    gqlFetcher<BrandQuery, BrandQueryVariables>(BrandDocument, variables),
    options,
  );

useBrandQuery.getKey = (variables: BrandQueryVariables) => ['Brand', variables];
export const DemoDocument = `
    mutation Demo {
  demo {
    accessToken
    authorizationList
    companyId
    email
    id
    role
    userName
  }
}
    `;
export const useDemoMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DemoMutation, TError, DemoMutationVariables, TContext>,
) =>
  useMutation<DemoMutation, TError, DemoMutationVariables, TContext>(
    ['Demo'],
    (variables?: DemoMutationVariables) =>
      gqlFetcher<DemoMutation, DemoMutationVariables>(DemoDocument, variables)(),
    options,
  );
export const UploadToS3Document = `
    mutation UploadToS3($file: Upload!, $type: FilePathEnum!) {
  uploadToS3V2(file: $file, type: $type) {
    createdAt
    deletedAt
    fileExtension
    fileKey
    fileName
    id
    link
    type
    updatedAt
  }
}
    `;
export const useUploadToS3Mutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UploadToS3Mutation,
    TError,
    UploadToS3MutationVariables,
    TContext
  >,
) =>
  useMutation<UploadToS3Mutation, TError, UploadToS3MutationVariables, TContext>(
    ['UploadToS3'],
    (variables?: UploadToS3MutationVariables) =>
      gqlFetcher<UploadToS3Mutation, UploadToS3MutationVariables>(
        UploadToS3Document,
        variables,
      )(),
    options,
  );
export const UploadFilesToS3Document = `
    mutation UploadFilesToS3($files: [Upload!]!, $type: FilePathEnum!) {
  uploadFilesToS3(files: $files, type: $type) {
    createdAt
    deletedAt
    fileExtension
    fileKey
    fileName
    id
    link
    type
    updatedAt
  }
}
    `;
export const useUploadFilesToS3Mutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UploadFilesToS3Mutation,
    TError,
    UploadFilesToS3MutationVariables,
    TContext
  >,
) =>
  useMutation<
    UploadFilesToS3Mutation,
    TError,
    UploadFilesToS3MutationVariables,
    TContext
  >(
    ['UploadFilesToS3'],
    (variables?: UploadFilesToS3MutationVariables) =>
      gqlFetcher<UploadFilesToS3Mutation, UploadFilesToS3MutationVariables>(
        UploadFilesToS3Document,
        variables,
      )(),
    options,
  );
export const HomeSummaryDocument = `
    query HomeSummary($input: DtoHomeInput) {
  homeSummary(input: $input) {
    orders {
      cancel
      total
    }
    salesPrice
  }
}
    `;
export const useHomeSummaryQuery = <TData = HomeSummaryQuery, TError = unknown>(
  variables?: HomeSummaryQueryVariables,
  options?: UseQueryOptions<HomeSummaryQuery, TError, TData>,
) =>
  useQuery<HomeSummaryQuery, TError, TData>(
    variables === undefined ? ['HomeSummary'] : ['HomeSummary', variables],
    gqlFetcher<HomeSummaryQuery, HomeSummaryQueryVariables>(
      HomeSummaryDocument,
      variables,
    ),
    options,
  );

useHomeSummaryQuery.getKey = (variables?: HomeSummaryQueryVariables) =>
  variables === undefined ? ['HomeSummary'] : ['HomeSummary', variables];
export const TopSellingCategoriesDocument = `
    query TopSellingCategories($input: DtoHomeInput) {
  topSellingCategories(input: $input) {
    categoryName
    count
  }
}
    `;
export const useTopSellingCategoriesQuery = <
  TData = TopSellingCategoriesQuery,
  TError = unknown,
>(
  variables?: TopSellingCategoriesQueryVariables,
  options?: UseQueryOptions<TopSellingCategoriesQuery, TError, TData>,
) =>
  useQuery<TopSellingCategoriesQuery, TError, TData>(
    variables === undefined
      ? ['TopSellingCategories']
      : ['TopSellingCategories', variables],
    gqlFetcher<TopSellingCategoriesQuery, TopSellingCategoriesQueryVariables>(
      TopSellingCategoriesDocument,
      variables,
    ),
    options,
  );

useTopSellingCategoriesQuery.getKey = (variables?: TopSellingCategoriesQueryVariables) =>
  variables === undefined
    ? ['TopSellingCategories']
    : ['TopSellingCategories', variables];
export const TopSellingProductsDocument = `
    query TopSellingProducts($input: DtoHomeInput) {
  topSellingProducts(input: $input) {
    count
    productName
  }
}
    `;
export const useTopSellingProductsQuery = <
  TData = TopSellingProductsQuery,
  TError = unknown,
>(
  variables?: TopSellingProductsQueryVariables,
  options?: UseQueryOptions<TopSellingProductsQuery, TError, TData>,
) =>
  useQuery<TopSellingProductsQuery, TError, TData>(
    variables === undefined ? ['TopSellingProducts'] : ['TopSellingProducts', variables],
    gqlFetcher<TopSellingProductsQuery, TopSellingProductsQueryVariables>(
      TopSellingProductsDocument,
      variables,
    ),
    options,
  );

useTopSellingProductsQuery.getKey = (variables?: TopSellingProductsQueryVariables) =>
  variables === undefined ? ['TopSellingProducts'] : ['TopSellingProducts', variables];
export const SalesRatioByChannelDocument = `
    query SalesRatioByChannel($input: DtoHomeInput) {
  salesRatioByChannel(input: $input) {
    channel
    ratio
  }
}
    `;
export const useSalesRatioByChannelQuery = <
  TData = SalesRatioByChannelQuery,
  TError = unknown,
>(
  variables?: SalesRatioByChannelQueryVariables,
  options?: UseQueryOptions<SalesRatioByChannelQuery, TError, TData>,
) =>
  useQuery<SalesRatioByChannelQuery, TError, TData>(
    variables === undefined
      ? ['SalesRatioByChannel']
      : ['SalesRatioByChannel', variables],
    gqlFetcher<SalesRatioByChannelQuery, SalesRatioByChannelQueryVariables>(
      SalesRatioByChannelDocument,
      variables,
    ),
    options,
  );

useSalesRatioByChannelQuery.getKey = (variables?: SalesRatioByChannelQueryVariables) =>
  variables === undefined ? ['SalesRatioByChannel'] : ['SalesRatioByChannel', variables];
export const TopSellingBrandsDocument = `
    query TopSellingBrands($input: DtoHomeInput) {
  topSellingBrands(input: $input) {
    brandKrName
    count
  }
}
    `;
export const useTopSellingBrandsQuery = <TData = TopSellingBrandsQuery, TError = unknown>(
  variables?: TopSellingBrandsQueryVariables,
  options?: UseQueryOptions<TopSellingBrandsQuery, TError, TData>,
) =>
  useQuery<TopSellingBrandsQuery, TError, TData>(
    variables === undefined ? ['TopSellingBrands'] : ['TopSellingBrands', variables],
    gqlFetcher<TopSellingBrandsQuery, TopSellingBrandsQueryVariables>(
      TopSellingBrandsDocument,
      variables,
    ),
    options,
  );

useTopSellingBrandsQuery.getKey = (variables?: TopSellingBrandsQueryVariables) =>
  variables === undefined ? ['TopSellingBrands'] : ['TopSellingBrands', variables];
export const DownloadImportLicensingDocumentFileDocument = `
    mutation DownloadImportLicensingDocumentFile($downloadImportLicensingDocumentFileId: ID!) {
  downloadImportLicensingDocumentFile(id: $downloadImportLicensingDocumentFileId)
}
    `;
export const useDownloadImportLicensingDocumentFileMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DownloadImportLicensingDocumentFileMutation,
    TError,
    DownloadImportLicensingDocumentFileMutationVariables,
    TContext
  >,
) =>
  useMutation<
    DownloadImportLicensingDocumentFileMutation,
    TError,
    DownloadImportLicensingDocumentFileMutationVariables,
    TContext
  >(
    ['DownloadImportLicensingDocumentFile'],
    (variables?: DownloadImportLicensingDocumentFileMutationVariables) =>
      gqlFetcher<
        DownloadImportLicensingDocumentFileMutation,
        DownloadImportLicensingDocumentFileMutationVariables
      >(DownloadImportLicensingDocumentFileDocument, variables)(),
    options,
  );
export const RequestImportLicensingTaxBillDocument = `
    mutation RequestImportLicensingTaxBill($input: DtoRequestTaxBillInput!) {
  requestImportLicensingTaxBill(input: $input)
}
    `;
export const useRequestImportLicensingTaxBillMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RequestImportLicensingTaxBillMutation,
    TError,
    RequestImportLicensingTaxBillMutationVariables,
    TContext
  >,
) =>
  useMutation<
    RequestImportLicensingTaxBillMutation,
    TError,
    RequestImportLicensingTaxBillMutationVariables,
    TContext
  >(
    ['RequestImportLicensingTaxBill'],
    (variables?: RequestImportLicensingTaxBillMutationVariables) =>
      gqlFetcher<
        RequestImportLicensingTaxBillMutation,
        RequestImportLicensingTaxBillMutationVariables
      >(RequestImportLicensingTaxBillDocument, variables)(),
    options,
  );
export const ConfirmFileCommentDocument = `
    mutation ConfirmFileComment($confirmFileCommentId: ID!) {
  confirmFileComment(id: $confirmFileCommentId)
}
    `;
export const useConfirmFileCommentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ConfirmFileCommentMutation,
    TError,
    ConfirmFileCommentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ConfirmFileCommentMutation,
    TError,
    ConfirmFileCommentMutationVariables,
    TContext
  >(
    ['ConfirmFileComment'],
    (variables?: ConfirmFileCommentMutationVariables) =>
      gqlFetcher<ConfirmFileCommentMutation, ConfirmFileCommentMutationVariables>(
        ConfirmFileCommentDocument,
        variables,
      )(),
    options,
  );
export const CompleteConfirmationImportLicensingByPartnerDocument = `
    mutation CompleteConfirmationImportLicensingByPartner($input: DtoRequestEditImportLicensingInput!) {
  completeConfirmationImportLicensingByPartner(input: $input)
}
    `;
export const useCompleteConfirmationImportLicensingByPartnerMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CompleteConfirmationImportLicensingByPartnerMutation,
    TError,
    CompleteConfirmationImportLicensingByPartnerMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CompleteConfirmationImportLicensingByPartnerMutation,
    TError,
    CompleteConfirmationImportLicensingByPartnerMutationVariables,
    TContext
  >(
    ['CompleteConfirmationImportLicensingByPartner'],
    (variables?: CompleteConfirmationImportLicensingByPartnerMutationVariables) =>
      gqlFetcher<
        CompleteConfirmationImportLicensingByPartnerMutation,
        CompleteConfirmationImportLicensingByPartnerMutationVariables
      >(CompleteConfirmationImportLicensingByPartnerDocument, variables)(),
    options,
  );
export const RequestConfirmationImportLicensingDocument = `
    mutation RequestConfirmationImportLicensing($input: DtoRequestEditImportLicensingInput!) {
  requestConfirmationImportLicensing(input: $input)
}
    `;
export const useRequestConfirmationImportLicensingMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RequestConfirmationImportLicensingMutation,
    TError,
    RequestConfirmationImportLicensingMutationVariables,
    TContext
  >,
) =>
  useMutation<
    RequestConfirmationImportLicensingMutation,
    TError,
    RequestConfirmationImportLicensingMutationVariables,
    TContext
  >(
    ['RequestConfirmationImportLicensing'],
    (variables?: RequestConfirmationImportLicensingMutationVariables) =>
      gqlFetcher<
        RequestConfirmationImportLicensingMutation,
        RequestConfirmationImportLicensingMutationVariables
      >(RequestConfirmationImportLicensingDocument, variables)(),
    options,
  );
export const DeleteImportLicensingContentDocument = `
    mutation DeleteImportLicensingContent($deleteImportLicensingContentId: ID!) {
  deleteImportLicensingContent(id: $deleteImportLicensingContentId) {
    id
  }
}
    `;
export const useDeleteImportLicensingContentMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DeleteImportLicensingContentMutation,
    TError,
    DeleteImportLicensingContentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    DeleteImportLicensingContentMutation,
    TError,
    DeleteImportLicensingContentMutationVariables,
    TContext
  >(
    ['DeleteImportLicensingContent'],
    (variables?: DeleteImportLicensingContentMutationVariables) =>
      gqlFetcher<
        DeleteImportLicensingContentMutation,
        DeleteImportLicensingContentMutationVariables
      >(DeleteImportLicensingContentDocument, variables)(),
    options,
  );
export const CreateImportLicensingExtraDocumentDocument = `
    mutation CreateImportLicensingExtraDocument($input: DtoCreateImportLicensingExtraDocumentInput!) {
  createImportLicensingExtraDocument(input: $input) {
    id
  }
}
    `;
export const useCreateImportLicensingExtraDocumentMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateImportLicensingExtraDocumentMutation,
    TError,
    CreateImportLicensingExtraDocumentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CreateImportLicensingExtraDocumentMutation,
    TError,
    CreateImportLicensingExtraDocumentMutationVariables,
    TContext
  >(
    ['CreateImportLicensingExtraDocument'],
    (variables?: CreateImportLicensingExtraDocumentMutationVariables) =>
      gqlFetcher<
        CreateImportLicensingExtraDocumentMutation,
        CreateImportLicensingExtraDocumentMutationVariables
      >(CreateImportLicensingExtraDocumentDocument, variables)(),
    options,
  );
export const CreateImportLicensingContentDocument = `
    mutation CreateImportLicensingContent($input: DtoCreateImportLicensingContentInput!) {
  createImportLicensingContent(input: $input) {
    brandKrName
    brandName
    completeAt
    contentKrName
    contentName
    createdAt
    desiredSalesPrice
    documentList {
      completedAt
      createdAt
      documentName
      id
      status
    }
    id
    manufacturerKrName
    manufacturerName
    salesPrice
  }
}
    `;
export const useCreateImportLicensingContentMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateImportLicensingContentMutation,
    TError,
    CreateImportLicensingContentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CreateImportLicensingContentMutation,
    TError,
    CreateImportLicensingContentMutationVariables,
    TContext
  >(
    ['CreateImportLicensingContent'],
    (variables?: CreateImportLicensingContentMutationVariables) =>
      gqlFetcher<
        CreateImportLicensingContentMutation,
        CreateImportLicensingContentMutationVariables
      >(CreateImportLicensingContentDocument, variables)(),
    options,
  );
export const CreateImportLicensingFileUploadDocument = `
    mutation CreateImportLicensingFileUpload($input: DtoCreateImportLicensingFileUploadInput!) {
  createImportLicensingFileUpload(input: $input)
}
    `;
export const useCreateImportLicensingFileUploadMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateImportLicensingFileUploadMutation,
    TError,
    CreateImportLicensingFileUploadMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CreateImportLicensingFileUploadMutation,
    TError,
    CreateImportLicensingFileUploadMutationVariables,
    TContext
  >(
    ['CreateImportLicensingFileUpload'],
    (variables?: CreateImportLicensingFileUploadMutationVariables) =>
      gqlFetcher<
        CreateImportLicensingFileUploadMutation,
        CreateImportLicensingFileUploadMutationVariables
      >(CreateImportLicensingFileUploadDocument, variables)(),
    options,
  );
export const UpdateImportLicensingDocument = `
    mutation UpdateImportLicensing($input: DtoUpdateImportLicensingInput!) {
  updateImportLicensing(input: $input) {
    id
  }
}
    `;
export const useUpdateImportLicensingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateImportLicensingMutation,
    TError,
    UpdateImportLicensingMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateImportLicensingMutation,
    TError,
    UpdateImportLicensingMutationVariables,
    TContext
  >(
    ['UpdateImportLicensing'],
    (variables?: UpdateImportLicensingMutationVariables) =>
      gqlFetcher<UpdateImportLicensingMutation, UpdateImportLicensingMutationVariables>(
        UpdateImportLicensingDocument,
        variables,
      )(),
    options,
  );
export const ExportExcelImportLicensingEstimateDocument = `
    mutation ExportExcelImportLicensingEstimate($importLicenseId: ID!) {
  exportExcelImportLicensingEstimate(importLicenseId: $importLicenseId)
}
    `;
export const useExportExcelImportLicensingEstimateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ExportExcelImportLicensingEstimateMutation,
    TError,
    ExportExcelImportLicensingEstimateMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ExportExcelImportLicensingEstimateMutation,
    TError,
    ExportExcelImportLicensingEstimateMutationVariables,
    TContext
  >(
    ['ExportExcelImportLicensingEstimate'],
    (variables?: ExportExcelImportLicensingEstimateMutationVariables) =>
      gqlFetcher<
        ExportExcelImportLicensingEstimateMutation,
        ExportExcelImportLicensingEstimateMutationVariables
      >(ExportExcelImportLicensingEstimateDocument, variables)(),
    options,
  );
export const ImportLicensingLicensingEstimateListDocument = `
    query ImportLicensingLicensingEstimateList($importLicensingLicensingEstimateListId: ID!) {
  importLicensingLicensingEstimateList(
    id: $importLicensingLicensingEstimateListId
  ) {
    nodes {
      count
      document
      id
      itemName
      price
      unit
      vat
    }
    total
  }
}
    `;
export const useImportLicensingLicensingEstimateListQuery = <
  TData = ImportLicensingLicensingEstimateListQuery,
  TError = unknown,
>(
  variables: ImportLicensingLicensingEstimateListQueryVariables,
  options?: UseQueryOptions<ImportLicensingLicensingEstimateListQuery, TError, TData>,
) =>
  useQuery<ImportLicensingLicensingEstimateListQuery, TError, TData>(
    ['ImportLicensingLicensingEstimateList', variables],
    gqlFetcher<
      ImportLicensingLicensingEstimateListQuery,
      ImportLicensingLicensingEstimateListQueryVariables
    >(ImportLicensingLicensingEstimateListDocument, variables),
    options,
  );

useImportLicensingLicensingEstimateListQuery.getKey = (
  variables: ImportLicensingLicensingEstimateListQueryVariables,
) => ['ImportLicensingLicensingEstimateList', variables];
export const ImportLicensingCommentListDocument = `
    query ImportLicensingCommentList($input: DtoImportLicensingCommentListInput!) {
  importLicensingCommentList(input: $input) {
    nodes {
      comment
      createdAt
      id
      writer {
        writerName
      }
    }
    total
  }
}
    `;
export const useImportLicensingCommentListQuery = <
  TData = ImportLicensingCommentListQuery,
  TError = unknown,
>(
  variables: ImportLicensingCommentListQueryVariables,
  options?: UseQueryOptions<ImportLicensingCommentListQuery, TError, TData>,
) =>
  useQuery<ImportLicensingCommentListQuery, TError, TData>(
    ['ImportLicensingCommentList', variables],
    gqlFetcher<ImportLicensingCommentListQuery, ImportLicensingCommentListQueryVariables>(
      ImportLicensingCommentListDocument,
      variables,
    ),
    options,
  );

useImportLicensingCommentListQuery.getKey = (
  variables: ImportLicensingCommentListQueryVariables,
) => ['ImportLicensingCommentList', variables];
export const ImportLicensingContentListDocument = `
    query ImportLicensingContentList($input: DtoImportLicensingContentListInput!) {
  importLicensingContentList(input: $input) {
    nodes {
      brandKrName
      brandName
      completeAt
      contentKrName
      contentName
      createdAt
      desiredSalesPrice
      documentList {
        completedAt
        createdAt
        documentName
        id
        status
      }
      id
      manufacturerKrName
      manufacturerName
      salesPrice
    }
    total
  }
}
    `;
export const useImportLicensingContentListQuery = <
  TData = ImportLicensingContentListQuery,
  TError = unknown,
>(
  variables: ImportLicensingContentListQueryVariables,
  options?: UseQueryOptions<ImportLicensingContentListQuery, TError, TData>,
) =>
  useQuery<ImportLicensingContentListQuery, TError, TData>(
    ['ImportLicensingContentList', variables],
    gqlFetcher<ImportLicensingContentListQuery, ImportLicensingContentListQueryVariables>(
      ImportLicensingContentListDocument,
      variables,
    ),
    options,
  );

useImportLicensingContentListQuery.getKey = (
  variables: ImportLicensingContentListQueryVariables,
) => ['ImportLicensingContentList', variables];
export const ImportLicensingDocumentDocument = `
    query ImportLicensingDocument($importLicensingDocumentId: ID!) {
  importLicensingDocument(id: $importLicensingDocumentId) {
    completedAt
    createdAt
    documentGroupList {
      fileList {
        createdAt
        extension
        fileName
        id
        link
      }
      commentCount
      newCommentStatus
      id
      createdAt
    }
    documentName
    id
    status
    updatedAt
    warnings
    exampleFileLink
    formFileLink
  }
}
    `;
export const useImportLicensingDocumentQuery = <
  TData = ImportLicensingDocumentQuery,
  TError = unknown,
>(
  variables: ImportLicensingDocumentQueryVariables,
  options?: UseQueryOptions<ImportLicensingDocumentQuery, TError, TData>,
) =>
  useQuery<ImportLicensingDocumentQuery, TError, TData>(
    ['ImportLicensingDocument', variables],
    gqlFetcher<ImportLicensingDocumentQuery, ImportLicensingDocumentQueryVariables>(
      ImportLicensingDocumentDocument,
      variables,
    ),
    options,
  );

useImportLicensingDocumentQuery.getKey = (
  variables: ImportLicensingDocumentQueryVariables,
) => ['ImportLicensingDocument', variables];
export const ImportLicensingDocument = `
    query ImportLicensing($importLicensingId: ID!) {
  importLicensing(id: $importLicensingId) {
    company {
      id
    }
    countryId
    documentDeliveryStatus
    documentPerProductStatus
    doneStatus
    estimateStatus
    importLicenseName
    extraDocumentStatus
    id
    productCreateStatus
    isRequestTaxInvoice
    notarizationFileLink
    requiredPeriod
    step
  }
}
    `;
export const useImportLicensingQuery = <TData = ImportLicensingQuery, TError = unknown>(
  variables: ImportLicensingQueryVariables,
  options?: UseQueryOptions<ImportLicensingQuery, TError, TData>,
) =>
  useQuery<ImportLicensingQuery, TError, TData>(
    ['ImportLicensing', variables],
    gqlFetcher<ImportLicensingQuery, ImportLicensingQueryVariables>(
      ImportLicensingDocument,
      variables,
    ),
    options,
  );

useImportLicensingQuery.getKey = (variables: ImportLicensingQueryVariables) => [
  'ImportLicensing',
  variables,
];
export const ImportLicensingRequiredOriginalDocumentsDocument = `
    query ImportLicensingRequiredOriginalDocuments($importLicenseId: ID!) {
  importLicensingRequiredOriginalDocuments(importLicenseId: $importLicenseId) {
    extraDocuments {
      documentName
      message
      id
    }
    documentsByProduct {
      documents {
        documentName
        id
      }
      id
      productName
    }
  }
}
    `;
export const useImportLicensingRequiredOriginalDocumentsQuery = <
  TData = ImportLicensingRequiredOriginalDocumentsQuery,
  TError = unknown,
>(
  variables: ImportLicensingRequiredOriginalDocumentsQueryVariables,
  options?: UseQueryOptions<ImportLicensingRequiredOriginalDocumentsQuery, TError, TData>,
) =>
  useQuery<ImportLicensingRequiredOriginalDocumentsQuery, TError, TData>(
    ['ImportLicensingRequiredOriginalDocuments', variables],
    gqlFetcher<
      ImportLicensingRequiredOriginalDocumentsQuery,
      ImportLicensingRequiredOriginalDocumentsQueryVariables
    >(ImportLicensingRequiredOriginalDocumentsDocument, variables),
    options,
  );

useImportLicensingRequiredOriginalDocumentsQuery.getKey = (
  variables: ImportLicensingRequiredOriginalDocumentsQueryVariables,
) => ['ImportLicensingRequiredOriginalDocuments', variables];
export const CreateImportLicensingDocument = `
    mutation CreateImportLicensing($input: DtoCreateImportLicensingInput!) {
  createImportLicensing(input: $input) {
    company {
      companyKrName
      companyName
    }
    countryId
    createdAt
    id
    importLicenseName
    managerName
    notarizationFileId
    step
  }
}
    `;
export const useCreateImportLicensingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateImportLicensingMutation,
    TError,
    CreateImportLicensingMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CreateImportLicensingMutation,
    TError,
    CreateImportLicensingMutationVariables,
    TContext
  >(
    ['CreateImportLicensing'],
    (variables?: CreateImportLicensingMutationVariables) =>
      gqlFetcher<CreateImportLicensingMutation, CreateImportLicensingMutationVariables>(
        CreateImportLicensingDocument,
        variables,
      )(),
    options,
  );
export const ImportLicensingListDocument = `
    query ImportLicensingList($input: DtoImportLicensingListInput!) {
  importLicensingList(input: $input) {
    nodes {
      company {
        companyKrName
        companyName
      }
      countryId
      createdAt
      id
      importLicenseName
      managerName
      notarizationFileId
      step
    }
    total
  }
}
    `;
export const useImportLicensingListQuery = <
  TData = ImportLicensingListQuery,
  TError = unknown,
>(
  variables: ImportLicensingListQueryVariables,
  options?: UseQueryOptions<ImportLicensingListQuery, TError, TData>,
) =>
  useQuery<ImportLicensingListQuery, TError, TData>(
    ['ImportLicensingList', variables],
    gqlFetcher<ImportLicensingListQuery, ImportLicensingListQueryVariables>(
      ImportLicensingListDocument,
      variables,
    ),
    options,
  );

useImportLicensingListQuery.getKey = (variables: ImportLicensingListQueryVariables) => [
  'ImportLicensingList',
  variables,
];
export const ImportLicensingTemplateListDocument = `
    query ImportLicensingTemplateList($input: DtoImportLicensingTemplateListInput!) {
  importLicensingTemplateList(input: $input) {
    nodes {
      categoryName
      countryId
      id
      templateContent
      templateName
      createdAt
      updatedAt
    }
    total
  }
}
    `;
export const useImportLicensingTemplateListQuery = <
  TData = ImportLicensingTemplateListQuery,
  TError = unknown,
>(
  variables: ImportLicensingTemplateListQueryVariables,
  options?: UseQueryOptions<ImportLicensingTemplateListQuery, TError, TData>,
) =>
  useQuery<ImportLicensingTemplateListQuery, TError, TData>(
    ['ImportLicensingTemplateList', variables],
    gqlFetcher<
      ImportLicensingTemplateListQuery,
      ImportLicensingTemplateListQueryVariables
    >(ImportLicensingTemplateListDocument, variables),
    options,
  );

useImportLicensingTemplateListQuery.getKey = (
  variables: ImportLicensingTemplateListQueryVariables,
) => ['ImportLicensingTemplateList', variables];
export const CancelOrderDetailDocument = `
    mutation CancelOrderDetail($cancelOrderDetailId: ID!) {
  cancelOrderDetail(id: $cancelOrderDetailId) {
    id
  }
}
    `;
export const useCancelOrderDetailMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CancelOrderDetailMutation,
    TError,
    CancelOrderDetailMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CancelOrderDetailMutation,
    TError,
    CancelOrderDetailMutationVariables,
    TContext
  >(
    ['CancelOrderDetail'],
    (variables?: CancelOrderDetailMutationVariables) =>
      gqlFetcher<CancelOrderDetailMutation, CancelOrderDetailMutationVariables>(
        CancelOrderDetailDocument,
        variables,
      )(),
    options,
  );
export const UploadB2bOrderDetailsDocument = `
    mutation UploadB2bOrderDetails($file: Upload!, $uploadB2BOrderDetailsId: ID!) {
  uploadB2bOrderDetails(file: $file, id: $uploadB2BOrderDetailsId)
}
    `;
export const useUploadB2bOrderDetailsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UploadB2bOrderDetailsMutation,
    TError,
    UploadB2bOrderDetailsMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UploadB2bOrderDetailsMutation,
    TError,
    UploadB2bOrderDetailsMutationVariables,
    TContext
  >(
    ['UploadB2bOrderDetails'],
    (variables?: UploadB2bOrderDetailsMutationVariables) =>
      gqlFetcher<UploadB2bOrderDetailsMutation, UploadB2bOrderDetailsMutationVariables>(
        UploadB2bOrderDetailsDocument,
        variables,
      )(),
    options,
  );
export const ExportB2bOrderDetailExcelDocument = `
    mutation ExportB2bOrderDetailExcel($exportB2BOrderDetailExcelId: ID!) {
  exportB2bOrderDetailExcel(id: $exportB2BOrderDetailExcelId)
}
    `;
export const useExportB2bOrderDetailExcelMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ExportB2bOrderDetailExcelMutation,
    TError,
    ExportB2bOrderDetailExcelMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ExportB2bOrderDetailExcelMutation,
    TError,
    ExportB2bOrderDetailExcelMutationVariables,
    TContext
  >(
    ['ExportB2bOrderDetailExcel'],
    (variables?: ExportB2bOrderDetailExcelMutationVariables) =>
      gqlFetcher<
        ExportB2bOrderDetailExcelMutation,
        ExportB2bOrderDetailExcelMutationVariables
      >(ExportB2bOrderDetailExcelDocument, variables)(),
    options,
  );
export const RequestConfirmB2bOrderDocument = `
    mutation RequestConfirmB2bOrder($requestConfirmB2BOrderId: ID!) {
  requestConfirmB2bOrder(id: $requestConfirmB2BOrderId) {
    createdAt
    id
    isDisable
    orderContract {
      buyerCompany {
        companyKrName
        companyName
        id
      }
      gomiManager {
        id
        userName
      }
      id
      sellerCompany {
        companyKrName
        companyName
        id
      }
    }
    orderDetails {
      b2bOrderId
      createdAt
      id
      inventoryQuantity
      orderQuantity
      product {
        barcode
        id
        innerBoxQuantity
        outerBoxQuantity
        productKrName
        productName
      }
      purchaseUnitPrice
      retailPrice
      salesPrice
      totalPrice
      updatedAt
      deletedAt
    }
    receiverAddress
    receiverContactInformation
    receiverName
    status
    totalOrderQuantity
    totalPrice
    updatedAt
  }
}
    `;
export const useRequestConfirmB2bOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RequestConfirmB2bOrderMutation,
    TError,
    RequestConfirmB2bOrderMutationVariables,
    TContext
  >,
) =>
  useMutation<
    RequestConfirmB2bOrderMutation,
    TError,
    RequestConfirmB2bOrderMutationVariables,
    TContext
  >(
    ['RequestConfirmB2bOrder'],
    (variables?: RequestConfirmB2bOrderMutationVariables) =>
      gqlFetcher<RequestConfirmB2bOrderMutation, RequestConfirmB2bOrderMutationVariables>(
        RequestConfirmB2bOrderDocument,
        variables,
      )(),
    options,
  );
export const UpdateDisableB2bOrderDocument = `
    mutation UpdateDisableB2bOrder($disableB2BOrderId: ID!) {
  updateDisableB2bOrder(id: $disableB2BOrderId) {
    createdAt
    id
    isDisable
    orderContract {
      buyerCompany {
        companyKrName
        companyName
        id
      }
      gomiManager {
        id
        userName
      }
      id
      sellerCompany {
        companyKrName
        companyName
        id
      }
    }
    orderDetails {
      b2bOrderId
      createdAt
      deletedAt
      id
      inventoryQuantity
      orderQuantity
      product {
        id
        innerBoxQuantity
        outerBoxQuantity
        productKrName
        productName
      }
      purchaseUnitPrice
      retailPrice
      salesPrice
      totalPrice
      updatedAt
    }
    receiverAddress
    receiverContactInformation
    receiverName
    status
    totalOrderQuantity
    totalPrice
    updatedAt
  }
}
    `;
export const useUpdateDisableB2bOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateDisableB2bOrderMutation,
    TError,
    UpdateDisableB2bOrderMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateDisableB2bOrderMutation,
    TError,
    UpdateDisableB2bOrderMutationVariables,
    TContext
  >(
    ['UpdateDisableB2bOrder'],
    (variables?: UpdateDisableB2bOrderMutationVariables) =>
      gqlFetcher<UpdateDisableB2bOrderMutation, UpdateDisableB2bOrderMutationVariables>(
        UpdateDisableB2bOrderDocument,
        variables,
      )(),
    options,
  );
export const CreateB2bOrderDocument = `
    mutation CreateB2bOrder($input: DtoCreateB2bOrderInput!) {
  createB2bOrder(input: $input) {
    createdAt
    id
    isDisable
    orderContract {
      buyerCompany {
        companyKrName
        companyName
        id
      }
      gomiManager {
        id
        userName
      }
      id
      sellerCompany {
        companyKrName
        companyName
        id
      }
    }
    orderDetails {
      b2bOrderId
      createdAt
      deletedAt
      id
      inventoryQuantity
      orderQuantity
      product {
        id
        innerBoxQuantity
        outerBoxQuantity
        productKrName
        productName
      }
      purchaseUnitPrice
      retailPrice
      salesPrice
      totalPrice
      updatedAt
    }
    receiverAddress
    receiverContactInformation
    receiverName
    status
    totalOrderQuantity
    totalPrice
    updatedAt
  }
}
    `;
export const useCreateB2bOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateB2bOrderMutation,
    TError,
    CreateB2bOrderMutationVariables,
    TContext
  >,
) =>
  useMutation<CreateB2bOrderMutation, TError, CreateB2bOrderMutationVariables, TContext>(
    ['CreateB2bOrder'],
    (variables?: CreateB2bOrderMutationVariables) =>
      gqlFetcher<CreateB2bOrderMutation, CreateB2bOrderMutationVariables>(
        CreateB2bOrderDocument,
        variables,
      )(),
    options,
  );
export const RegisterB2bOrderDocument = `
    mutation RegisterB2bOrder($input: DtoRegisterB2bOrderInput!) {
  registerB2bOrder(input: $input) {
    createdAt
    id
    isDisable
    orderContract {
      buyerCompany {
        companyKrName
        companyName
        id
      }
      gomiManager {
        id
        userName
      }
      id
      sellerCompany {
        companyKrName
        companyName
        id
      }
    }
    orderDetails {
      b2bOrderId
      createdAt
      deletedAt
      id
      inventoryQuantity
      orderQuantity
      product {
        barcode
        id
        innerBoxQuantity
        outerBoxQuantity
        productKrName
        productName
      }
      purchaseUnitPrice
      retailPrice
      salesPrice
      totalPrice
      updatedAt
    }
    receiverAddress
    receiverContactInformation
    receiverName
    status
    totalOrderQuantity
    totalPrice
    updatedAt
  }
}
    `;
export const useRegisterB2bOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RegisterB2bOrderMutation,
    TError,
    RegisterB2bOrderMutationVariables,
    TContext
  >,
) =>
  useMutation<
    RegisterB2bOrderMutation,
    TError,
    RegisterB2bOrderMutationVariables,
    TContext
  >(
    ['RegisterB2bOrder'],
    (variables?: RegisterB2bOrderMutationVariables) =>
      gqlFetcher<RegisterB2bOrderMutation, RegisterB2bOrderMutationVariables>(
        RegisterB2bOrderDocument,
        variables,
      )(),
    options,
  );
export const ConfirmB2bOrderBySellerDocument = `
    mutation ConfirmB2bOrderBySeller($input: DtoConfirmB2bOrderInput!) {
  confirmB2bOrderBySeller(input: $input) {
    id
  }
}
    `;
export const useConfirmB2bOrderBySellerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ConfirmB2bOrderBySellerMutation,
    TError,
    ConfirmB2bOrderBySellerMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ConfirmB2bOrderBySellerMutation,
    TError,
    ConfirmB2bOrderBySellerMutationVariables,
    TContext
  >(
    ['ConfirmB2bOrderBySeller'],
    (variables?: ConfirmB2bOrderBySellerMutationVariables) =>
      gqlFetcher<
        ConfirmB2bOrderBySellerMutation,
        ConfirmB2bOrderBySellerMutationVariables
      >(ConfirmB2bOrderBySellerDocument, variables)(),
    options,
  );
export const ConfirmB2bOrderByBuyerDocument = `
    mutation ConfirmB2bOrderByBuyer($confirmB2BOrderByBuyerId: ID!) {
  confirmB2bOrderByBuyer(id: $confirmB2BOrderByBuyerId) {
    id
  }
}
    `;
export const useConfirmB2bOrderByBuyerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ConfirmB2bOrderByBuyerMutation,
    TError,
    ConfirmB2bOrderByBuyerMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ConfirmB2bOrderByBuyerMutation,
    TError,
    ConfirmB2bOrderByBuyerMutationVariables,
    TContext
  >(
    ['ConfirmB2bOrderByBuyer'],
    (variables?: ConfirmB2bOrderByBuyerMutationVariables) =>
      gqlFetcher<ConfirmB2bOrderByBuyerMutation, ConfirmB2bOrderByBuyerMutationVariables>(
        ConfirmB2bOrderByBuyerDocument,
        variables,
      )(),
    options,
  );
export const UpdateB2bOrderByEstimatedArrivalDateDocument = `
    mutation UpdateB2bOrderByEstimatedArrivalDate($input: DtoUpdateB2bOrderEstimatedArrivalDateInput!) {
  updateB2bOrderByEstimatedArrivalDate(input: $input) {
    createdAt
    id
    isDisable
    estimatedArrivalDate
    orderContract {
      buyerCompany {
        companyKrName
        companyName
        id
      }
      gomiManager {
        id
        userName
      }
      id
      sellerCompany {
        companyKrName
        companyName
        id
      }
    }
    orderDetails {
      b2bOrderId
      createdAt
      id
      inventoryQuantity
      orderQuantity
      product {
        barcode
        id
        innerBoxQuantity
        outerBoxQuantity
        productKrName
        productName
      }
      purchaseUnitPrice
      retailPrice
      salesPrice
      totalPrice
      updatedAt
    }
    receiverAddress
    receiverContactInformation
    receiverName
    status
    totalOrderQuantity
    totalPrice
    updatedAt
  }
}
    `;
export const useUpdateB2bOrderByEstimatedArrivalDateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateB2bOrderByEstimatedArrivalDateMutation,
    TError,
    UpdateB2bOrderByEstimatedArrivalDateMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateB2bOrderByEstimatedArrivalDateMutation,
    TError,
    UpdateB2bOrderByEstimatedArrivalDateMutationVariables,
    TContext
  >(
    ['UpdateB2bOrderByEstimatedArrivalDate'],
    (variables?: UpdateB2bOrderByEstimatedArrivalDateMutationVariables) =>
      gqlFetcher<
        UpdateB2bOrderByEstimatedArrivalDateMutation,
        UpdateB2bOrderByEstimatedArrivalDateMutationVariables
      >(UpdateB2bOrderByEstimatedArrivalDateDocument, variables)(),
    options,
  );
export const UpdateB2bOrderSellerBankDepositConfirmationDocument = `
    mutation UpdateB2bOrderSellerBankDepositConfirmation($input: DtoUpdateB2bOrderBankDepositInput!) {
  updateB2bOrderSellerBankDepositConfirmation(input: $input) {
    buyerDepositConfirmationFile {
      fileExtension
      fileKey
      fileName
      id
      link
      type
    }
    completedAt
    createdAt
    deletedAt
    estimatedArrivalDate
    id
    isDisable
    orderContract {
      buyerCompany {
        companyKrName
        companyName
        id
      }
      gomiManager {
        email
        id
        userName
      }
      id
      sellerCompany {
        companyKrName
        companyName
        id
      }
    }
    orderDetails {
      b2bOrderId
      createdAt
      deletedAt
      id
      inventoryQuantity
      orderQuantity
      product {
        barcode
        id
        innerBoxQuantity
        outerBoxQuantity
        productKrName
        productName
      }
      purchaseUnitPrice
      retailPrice
      salesPrice
      totalPrice
      updatedAt
    }
    receiverAddress
    receiverContactInformation
    receiverName
    status
    totalOrderQuantity
    totalPrice
    updatedAt
  }
}
    `;
export const useUpdateB2bOrderSellerBankDepositConfirmationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateB2bOrderSellerBankDepositConfirmationMutation,
    TError,
    UpdateB2bOrderSellerBankDepositConfirmationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateB2bOrderSellerBankDepositConfirmationMutation,
    TError,
    UpdateB2bOrderSellerBankDepositConfirmationMutationVariables,
    TContext
  >(
    ['UpdateB2bOrderSellerBankDepositConfirmation'],
    (variables?: UpdateB2bOrderSellerBankDepositConfirmationMutationVariables) =>
      gqlFetcher<
        UpdateB2bOrderSellerBankDepositConfirmationMutation,
        UpdateB2bOrderSellerBankDepositConfirmationMutationVariables
      >(UpdateB2bOrderSellerBankDepositConfirmationDocument, variables)(),
    options,
  );
export const UpdateB2bOrderBuyerBankDepositConfirmationDocument = `
    mutation UpdateB2bOrderBuyerBankDepositConfirmation($input: DtoUpdateB2bOrderBankDepositInput!) {
  updateB2bOrderBuyerBankDepositConfirmation(input: $input) {
    buyerDepositConfirmationFile {
      fileExtension
      fileKey
      fileName
      id
      link
      type
    }
    completedAt
    createdAt
    deletedAt
    estimatedArrivalDate
    id
    isDisable
    orderContract {
      buyerCompany {
        companyKrName
        companyName
        id
      }
      gomiManager {
        email
        id
        userName
      }
      id
      sellerCompany {
        companyKrName
        companyName
        id
      }
    }
    orderDetails {
      b2bOrderId
      createdAt
      deletedAt
      id
      inventoryQuantity
      orderQuantity
      product {
        barcode
        id
        innerBoxQuantity
        outerBoxQuantity
        productKrName
        productName
      }
      purchaseUnitPrice
      retailPrice
      salesPrice
      totalPrice
      updatedAt
    }
    receiverAddress
    receiverContactInformation
    receiverName
    status
    totalOrderQuantity
    totalPrice
    updatedAt
  }
}
    `;
export const useUpdateB2bOrderBuyerBankDepositConfirmationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateB2bOrderBuyerBankDepositConfirmationMutation,
    TError,
    UpdateB2bOrderBuyerBankDepositConfirmationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateB2bOrderBuyerBankDepositConfirmationMutation,
    TError,
    UpdateB2bOrderBuyerBankDepositConfirmationMutationVariables,
    TContext
  >(
    ['UpdateB2bOrderBuyerBankDepositConfirmation'],
    (variables?: UpdateB2bOrderBuyerBankDepositConfirmationMutationVariables) =>
      gqlFetcher<
        UpdateB2bOrderBuyerBankDepositConfirmationMutation,
        UpdateB2bOrderBuyerBankDepositConfirmationMutationVariables
      >(UpdateB2bOrderBuyerBankDepositConfirmationDocument, variables)(),
    options,
  );
export const UpdateDoneB2bOrderDocument = `
    mutation UpdateDoneB2bOrder($updateDoneB2BOrderId: ID!) {
  updateDoneB2bOrder(id: $updateDoneB2BOrderId) {
    buyerDepositConfirmationFile {
      fileExtension
      fileKey
      fileName
      id
      link
      type
    }
    completedAt
    createdAt
    deletedAt
    estimatedArrivalDate
    id
    isDisable
    orderContract {
      buyerCompany {
        companyKrName
        companyName
        id
      }
      gomiManager {
        email
        id
        userName
      }
      id
      sellerCompany {
        companyKrName
        companyName
        id
      }
    }
    orderDetails {
      b2bOrderId
      createdAt
      deletedAt
      id
      inventoryQuantity
      orderQuantity
      product {
        barcode
        id
        innerBoxQuantity
        outerBoxQuantity
        productKrName
        productName
      }
      purchaseUnitPrice
      retailPrice
      salesPrice
      totalPrice
      updatedAt
    }
    receiverAddress
    receiverContactInformation
    receiverName
    status
    totalOrderQuantity
    totalPrice
    updatedAt
  }
}
    `;
export const useUpdateDoneB2bOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateDoneB2bOrderMutation,
    TError,
    UpdateDoneB2bOrderMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateDoneB2bOrderMutation,
    TError,
    UpdateDoneB2bOrderMutationVariables,
    TContext
  >(
    ['UpdateDoneB2bOrder'],
    (variables?: UpdateDoneB2bOrderMutationVariables) =>
      gqlFetcher<UpdateDoneB2bOrderMutation, UpdateDoneB2bOrderMutationVariables>(
        UpdateDoneB2bOrderDocument,
        variables,
      )(),
    options,
  );
export const B2bOrderListDocument = `
    query B2bOrderList($input: DtoB2bOrderListInput!) {
  b2bOrderList(input: $input) {
    nodes {
      id
      createdAt
      orderContract {
        id
        buyerCompany {
          companyKrName
          companyName
          id
        }
        gomiManager {
          id
          userName
        }
        sellerCompany {
          companyKrName
          companyName
          id
        }
      }
      orderDetails {
        id
        inventoryQuantity
        orderQuantity
      }
      isDisable
      status
      totalPrice
    }
    total
  }
}
    `;
export const useB2bOrderListQuery = <TData = B2bOrderListQuery, TError = unknown>(
  variables: B2bOrderListQueryVariables,
  options?: UseQueryOptions<B2bOrderListQuery, TError, TData>,
) =>
  useQuery<B2bOrderListQuery, TError, TData>(
    ['B2bOrderList', variables],
    gqlFetcher<B2bOrderListQuery, B2bOrderListQueryVariables>(
      B2bOrderListDocument,
      variables,
    ),
    options,
  );

useB2bOrderListQuery.getKey = (variables: B2bOrderListQueryVariables) => [
  'B2bOrderList',
  variables,
];
export const B2bOrderSummaryDocument = `
    query B2bOrderSummary {
  b2bOrderSummary {
    disableCount
    doneCount
    inProgressCount
    totalPrice
    totalQuantity
  }
}
    `;
export const useB2bOrderSummaryQuery = <TData = B2bOrderSummaryQuery, TError = unknown>(
  variables?: B2bOrderSummaryQueryVariables,
  options?: UseQueryOptions<B2bOrderSummaryQuery, TError, TData>,
) =>
  useQuery<B2bOrderSummaryQuery, TError, TData>(
    variables === undefined ? ['B2bOrderSummary'] : ['B2bOrderSummary', variables],
    gqlFetcher<B2bOrderSummaryQuery, B2bOrderSummaryQueryVariables>(
      B2bOrderSummaryDocument,
      variables,
    ),
    options,
  );

useB2bOrderSummaryQuery.getKey = (variables?: B2bOrderSummaryQueryVariables) =>
  variables === undefined ? ['B2bOrderSummary'] : ['B2bOrderSummary', variables];
export const B2bOrderDocument = `
    query B2bOrder($b2BOrderId: ID!) {
  b2bOrder(id: $b2BOrderId) {
    buyerDepositConfirmationFile {
      fileExtension
      fileKey
      fileName
      id
      link
      type
    }
    completedAt
    createdAt
    deletedAt
    estimatedArrivalDate
    id
    isDisable
    orderConfirmDocumentFile {
      fileExtension
      fileKey
      fileName
      id
      link
      type
    }
    orderContract {
      buyerCompany {
        companyKrName
        companyName
        id
      }
      gomiManager {
        email
        id
        userName
      }
      id
      sellerCompany {
        companyKrName
        companyName
        id
      }
    }
    orderDetails {
      b2bOrderId
      createdAt
      id
      inventoryQuantity
      orderQuantity
      product {
        barcode
        id
        innerBoxQuantity
        outerBoxQuantity
        productKrName
        productName
      }
      purchaseUnitPrice
      retailPrice
      salesPrice
      totalPrice
      updatedAt
    }
    orderDocumentFile {
      fileExtension
      fileKey
      fileName
      id
      link
      type
    }
    receiverAddress
    receiverContactInformation
    receiverName
    sellerDepositConfirmationFile {
      fileExtension
      fileKey
      fileName
      id
      link
      type
    }
    status
    totalOrderQuantity
    totalPrice
    updatedAt
  }
}
    `;
export const useB2bOrderQuery = <TData = B2bOrderQuery, TError = unknown>(
  variables: B2bOrderQueryVariables,
  options?: UseQueryOptions<B2bOrderQuery, TError, TData>,
) =>
  useQuery<B2bOrderQuery, TError, TData>(
    ['B2bOrder', variables],
    gqlFetcher<B2bOrderQuery, B2bOrderQueryVariables>(B2bOrderDocument, variables),
    options,
  );

useB2bOrderQuery.getKey = (variables: B2bOrderQueryVariables) => ['B2bOrder', variables];
export const B2bOrderContractsDocument = `
    query B2bOrderContracts {
  b2bOrderContracts {
    buyerCompany {
      companyKrName
      companyName
      id
    }
    id
    isDisconnect
    sellerCompany {
      companyKrName
      companyName
      id
    }
  }
}
    `;
export const useB2bOrderContractsQuery = <
  TData = B2bOrderContractsQuery,
  TError = unknown,
>(
  variables?: B2bOrderContractsQueryVariables,
  options?: UseQueryOptions<B2bOrderContractsQuery, TError, TData>,
) =>
  useQuery<B2bOrderContractsQuery, TError, TData>(
    variables === undefined ? ['B2bOrderContracts'] : ['B2bOrderContracts', variables],
    gqlFetcher<B2bOrderContractsQuery, B2bOrderContractsQueryVariables>(
      B2bOrderContractsDocument,
      variables,
    ),
    options,
  );

useB2bOrderContractsQuery.getKey = (variables?: B2bOrderContractsQueryVariables) =>
  variables === undefined ? ['B2bOrderContracts'] : ['B2bOrderContracts', variables];
export const CompanyByPartnerDocument = `
    query CompanyByPartner {
  companyByPartner {
    accountNumber
    address
    bankName
    ceoName
    companyCode
    companyKrName
    countryOfEntry {
      countryId
      countryName
    }
    companyManagers {
      userName
      id
    }
    companyName
    corporateRegistrationNumber
    countryId
    digitalTaxInvoiceEmail
    id
    registrationNumber
    salesType
    updatedAt
  }
}
    `;
export const useCompanyByPartnerQuery = <TData = CompanyByPartnerQuery, TError = unknown>(
  variables?: CompanyByPartnerQueryVariables,
  options?: UseQueryOptions<CompanyByPartnerQuery, TError, TData>,
) =>
  useQuery<CompanyByPartnerQuery, TError, TData>(
    variables === undefined ? ['CompanyByPartner'] : ['CompanyByPartner', variables],
    gqlFetcher<CompanyByPartnerQuery, CompanyByPartnerQueryVariables>(
      CompanyByPartnerDocument,
      variables,
    ),
    options,
  );

useCompanyByPartnerQuery.getKey = (variables?: CompanyByPartnerQueryVariables) =>
  variables === undefined ? ['CompanyByPartner'] : ['CompanyByPartner', variables];
export const ExportProductsV2Document = `
    mutation ExportProductsV2($input: DtoFindProducts!) {
  exportProductsV2(input: $input)
}
    `;
export const useExportProductsV2Mutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ExportProductsV2Mutation,
    TError,
    ExportProductsV2MutationVariables,
    TContext
  >,
) =>
  useMutation<
    ExportProductsV2Mutation,
    TError,
    ExportProductsV2MutationVariables,
    TContext
  >(
    ['ExportProductsV2'],
    (variables?: ExportProductsV2MutationVariables) =>
      gqlFetcher<ExportProductsV2Mutation, ExportProductsV2MutationVariables>(
        ExportProductsV2Document,
        variables,
      )(),
    options,
  );
export const FilteredBrandsByProductV2Document = `
    query FilteredBrandsByProductV2 {
  filteredBrandsByProductV2 {
    brandCode
    brandKrName
    brandName
    id
  }
}
    `;
export const useFilteredBrandsByProductV2Query = <
  TData = FilteredBrandsByProductV2Query,
  TError = unknown,
>(
  variables?: FilteredBrandsByProductV2QueryVariables,
  options?: UseQueryOptions<FilteredBrandsByProductV2Query, TError, TData>,
) =>
  useQuery<FilteredBrandsByProductV2Query, TError, TData>(
    variables === undefined
      ? ['FilteredBrandsByProductV2']
      : ['FilteredBrandsByProductV2', variables],
    gqlFetcher<FilteredBrandsByProductV2Query, FilteredBrandsByProductV2QueryVariables>(
      FilteredBrandsByProductV2Document,
      variables,
    ),
    options,
  );

useFilteredBrandsByProductV2Query.getKey = (
  variables?: FilteredBrandsByProductV2QueryVariables,
) =>
  variables === undefined
    ? ['FilteredBrandsByProductV2']
    : ['FilteredBrandsByProductV2', variables];
export const SalesCodesDocument = `
    query SalesCodes($input: GetSalesCodesInputDto!) {
  salesCodes(input: $input) {
    name
    salesCode
  }
}
    `;
export const useSalesCodesQuery = <TData = SalesCodesQuery, TError = unknown>(
  variables: SalesCodesQueryVariables,
  options?: UseQueryOptions<SalesCodesQuery, TError, TData>,
) =>
  useQuery<SalesCodesQuery, TError, TData>(
    ['SalesCodes', variables],
    gqlFetcher<SalesCodesQuery, SalesCodesQueryVariables>(SalesCodesDocument, variables),
    options,
  );

useSalesCodesQuery.getKey = (variables: SalesCodesQueryVariables) => [
  'SalesCodes',
  variables,
];
export const ProductDocument = `
    query Product($productId: ID!) {
  product(id: $productId) {
    barcode
    discountSalePriceThA
    discountSalePriceThB
    discountSalePriceThS
    discountSalePriceVnA
    discountSalePriceVnB
    discountSalePriceVnS
    gspc
    hsCode
    innerBoxCount
    outerBoxCount
    id
    productGroup {
      brand {
        countryIds
        id
        brandName
        brandKrName
      }
      category
      company {
        id
        companyName
        companyKrName
      }
      productGroupKrName
      productGroupKrNameKeyword
      productGroupName
      productGroupNameKeyword
    }
    productGroupId
    productKeyword
    productKrName
    productName
    productOptions {
      productOptionName
      productOptionRelId
      id
      productOptionGroup {
        productOptionGroupName
        id
      }
    }
    purchaseUnitPrice
    regularSalePriceTh
    regularSalePriceVn
    retailPriceKr
    retailPriceTh
    retailPriceVn
    supplierProductCode
    updatedAt
  }
}
    `;
export const useProductQuery = <TData = ProductQuery, TError = unknown>(
  variables: ProductQueryVariables,
  options?: UseQueryOptions<ProductQuery, TError, TData>,
) =>
  useQuery<ProductQuery, TError, TData>(
    ['Product', variables],
    gqlFetcher<ProductQuery, ProductQueryVariables>(ProductDocument, variables),
    options,
  );

useProductQuery.getKey = (variables: ProductQueryVariables) => ['Product', variables];
export const ProductListDocument = `
    query ProductList($input: DtoFindProducts!) {
  productList(input: $input) {
    nodes {
      barcode
      gspc
      id
      productGroup {
        productGroupKrName
        productGroupName
        brand {
          id
          brandName
          brandKrName
        }
      }
      productGroupId
      productKrName
      productName
      productOptions {
        id
        productOptionGroup {
          productOptionGroupName
          id
        }
        productOptionName
        productOptionRelId
      }
      supplierProductCode
      updatedAt
    }
    total
  }
}
    `;
export const useProductListQuery = <TData = ProductListQuery, TError = unknown>(
  variables: ProductListQueryVariables,
  options?: UseQueryOptions<ProductListQuery, TError, TData>,
) =>
  useQuery<ProductListQuery, TError, TData>(
    ['ProductList', variables],
    gqlFetcher<ProductListQuery, ProductListQueryVariables>(
      ProductListDocument,
      variables,
    ),
    options,
  );

useProductListQuery.getKey = (variables: ProductListQueryVariables) => [
  'ProductList',
  variables,
];
export const ReportExportExcelV2Document = `
    mutation ReportExportExcelV2($input: DtoExportOrderSalesInfoExcel!) {
  reportExportExcelV2(input: $input)
}
    `;
export const useReportExportExcelV2Mutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ReportExportExcelV2Mutation,
    TError,
    ReportExportExcelV2MutationVariables,
    TContext
  >,
) =>
  useMutation<
    ReportExportExcelV2Mutation,
    TError,
    ReportExportExcelV2MutationVariables,
    TContext
  >(
    ['ReportExportExcelV2'],
    (variables?: ReportExportExcelV2MutationVariables) =>
      gqlFetcher<ReportExportExcelV2Mutation, ReportExportExcelV2MutationVariables>(
        ReportExportExcelV2Document,
        variables,
      )(),
    options,
  );
export const StockLogsExportExcelDocument = `
    mutation StockLogsExportExcel($input: GetStockLogInputDto!) {
  stockLogsExportExcel(input: $input)
}
    `;
export const useStockLogsExportExcelMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    StockLogsExportExcelMutation,
    TError,
    StockLogsExportExcelMutationVariables,
    TContext
  >,
) =>
  useMutation<
    StockLogsExportExcelMutation,
    TError,
    StockLogsExportExcelMutationVariables,
    TContext
  >(
    ['StockLogsExportExcel'],
    (variables?: StockLogsExportExcelMutationVariables) =>
      gqlFetcher<StockLogsExportExcelMutation, StockLogsExportExcelMutationVariables>(
        StockLogsExportExcelDocument,
        variables,
      )(),
    options,
  );
export const StockExportExcelDocument = `
    mutation StockExportExcel($input: GetStockInputDto!) {
  stockExportExcel(input: $input)
}
    `;
export const useStockExportExcelMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    StockExportExcelMutation,
    TError,
    StockExportExcelMutationVariables,
    TContext
  >,
) =>
  useMutation<
    StockExportExcelMutation,
    TError,
    StockExportExcelMutationVariables,
    TContext
  >(
    ['StockExportExcel'],
    (variables?: StockExportExcelMutationVariables) =>
      gqlFetcher<StockExportExcelMutation, StockExportExcelMutationVariables>(
        StockExportExcelDocument,
        variables,
      )(),
    options,
  );
export const SalesStatusByDateDocument = `
    query SalesStatusByDate($input: DtoFindOrderItems!) {
  salesStatusByDate(input: $input) {
    node {
      date
      statuses {
        channels {
          amount
          channelName
          count
        }
        orderStatus
      }
    }
  }
}
    `;
export const useSalesStatusByDateQuery = <
  TData = SalesStatusByDateQuery,
  TError = unknown,
>(
  variables: SalesStatusByDateQueryVariables,
  options?: UseQueryOptions<SalesStatusByDateQuery, TError, TData>,
) =>
  useQuery<SalesStatusByDateQuery, TError, TData>(
    ['SalesStatusByDate', variables],
    gqlFetcher<SalesStatusByDateQuery, SalesStatusByDateQueryVariables>(
      SalesStatusByDateDocument,
      variables,
    ),
    options,
  );

useSalesStatusByDateQuery.getKey = (variables: SalesStatusByDateQueryVariables) => [
  'SalesStatusByDate',
  variables,
];
export const SalesStatusByProductDocument = `
    query SalesStatusByProduct($input: DtoFindOrderItems!) {
  salesStatusByProduct(input: $input) {
    node {
      prices {
        channels {
          channel
          orderStatuses {
            orderCountPrice {
              count
              totalPrice
            }
            orderStatus
          }
        }
        price
      }
      productName
    }
  }
}
    `;
export const useSalesStatusByProductQuery = <
  TData = SalesStatusByProductQuery,
  TError = unknown,
>(
  variables: SalesStatusByProductQueryVariables,
  options?: UseQueryOptions<SalesStatusByProductQuery, TError, TData>,
) =>
  useQuery<SalesStatusByProductQuery, TError, TData>(
    ['SalesStatusByProduct', variables],
    gqlFetcher<SalesStatusByProductQuery, SalesStatusByProductQueryVariables>(
      SalesStatusByProductDocument,
      variables,
    ),
    options,
  );

useSalesStatusByProductQuery.getKey = (variables: SalesStatusByProductQueryVariables) => [
  'SalesStatusByProduct',
  variables,
];
export const ShippingStatusCountDocument = `
    query ShippingStatusCount($input: DtoFindOrderItems!) {
  shippingStatusCount(input: $input) {
    delivered
    delivering
    readytopick
    return
  }
}
    `;
export const useShippingStatusCountQuery = <
  TData = ShippingStatusCountQuery,
  TError = unknown,
>(
  variables: ShippingStatusCountQueryVariables,
  options?: UseQueryOptions<ShippingStatusCountQuery, TError, TData>,
) =>
  useQuery<ShippingStatusCountQuery, TError, TData>(
    ['ShippingStatusCount', variables],
    gqlFetcher<ShippingStatusCountQuery, ShippingStatusCountQueryVariables>(
      ShippingStatusCountDocument,
      variables,
    ),
    options,
  );

useShippingStatusCountQuery.getKey = (variables: ShippingStatusCountQueryVariables) => [
  'ShippingStatusCount',
  variables,
];
export const PaymentStatusCountDocument = `
    query PaymentStatusCount($input: DtoFindOrderItems!) {
  paymentStatusCount(input: $input) {
    not_paid
    paid
    refund
  }
}
    `;
export const usePaymentStatusCountQuery = <
  TData = PaymentStatusCountQuery,
  TError = unknown,
>(
  variables: PaymentStatusCountQueryVariables,
  options?: UseQueryOptions<PaymentStatusCountQuery, TError, TData>,
) =>
  useQuery<PaymentStatusCountQuery, TError, TData>(
    ['PaymentStatusCount', variables],
    gqlFetcher<PaymentStatusCountQuery, PaymentStatusCountQueryVariables>(
      PaymentStatusCountDocument,
      variables,
    ),
    options,
  );

usePaymentStatusCountQuery.getKey = (variables: PaymentStatusCountQueryVariables) => [
  'PaymentStatusCount',
  variables,
];
export const SalesStatusCountDocument = `
    query SalesStatusCount($input: DtoFindOrderItems!) {
  salesStatusCount(input: $input) {
    cancel
    ok
  }
}
    `;
export const useSalesStatusCountQuery = <TData = SalesStatusCountQuery, TError = unknown>(
  variables: SalesStatusCountQueryVariables,
  options?: UseQueryOptions<SalesStatusCountQuery, TError, TData>,
) =>
  useQuery<SalesStatusCountQuery, TError, TData>(
    ['SalesStatusCount', variables],
    gqlFetcher<SalesStatusCountQuery, SalesStatusCountQueryVariables>(
      SalesStatusCountDocument,
      variables,
    ),
    options,
  );

useSalesStatusCountQuery.getKey = (variables: SalesStatusCountQueryVariables) => [
  'SalesStatusCount',
  variables,
];
export const SalesAmountByProductDocument = `
    query SalesAmountByProduct($input: DtoFindOrderItems!) {
  salesAmountByProduct(input: $input) {
    node {
      channels {
        channelName
        salesAmount
      }
      productName
    }
  }
}
    `;
export const useSalesAmountByProductQuery = <
  TData = SalesAmountByProductQuery,
  TError = unknown,
>(
  variables: SalesAmountByProductQueryVariables,
  options?: UseQueryOptions<SalesAmountByProductQuery, TError, TData>,
) =>
  useQuery<SalesAmountByProductQuery, TError, TData>(
    ['SalesAmountByProduct', variables],
    gqlFetcher<SalesAmountByProductQuery, SalesAmountByProductQueryVariables>(
      SalesAmountByProductDocument,
      variables,
    ),
    options,
  );

useSalesAmountByProductQuery.getKey = (variables: SalesAmountByProductQueryVariables) => [
  'SalesAmountByProduct',
  variables,
];
export const SalesStatusDocument = `
    query SalesStatus($input: DtoFindOrderItems!) {
  salesStatus(input: $input) {
    cancelOrderCount
    confirmOrderCount
    date
    totalPrice
  }
}
    `;
export const useSalesStatusQuery = <TData = SalesStatusQuery, TError = unknown>(
  variables: SalesStatusQueryVariables,
  options?: UseQueryOptions<SalesStatusQuery, TError, TData>,
) =>
  useQuery<SalesStatusQuery, TError, TData>(
    ['SalesStatus', variables],
    gqlFetcher<SalesStatusQuery, SalesStatusQueryVariables>(
      SalesStatusDocument,
      variables,
    ),
    options,
  );

useSalesStatusQuery.getKey = (variables: SalesStatusQueryVariables) => [
  'SalesStatus',
  variables,
];
export const OrderDetailsDocument = `
    query OrderDetails($input: DtoFindOrderItems!) {
  orderDetails(input: $input) {
    nodes {
      brandId
      channel
      displayChannel
      count
      countryId
      gspc
      orderId
      orderItemId
      orderNumber
      orderStatus
      orderedAt
      payMethod
      paymentStatus
      originalPrice
      voucher
      price
      productName
      salesCode
      settlementYearMonth
      shippingStatus
      totalPrice
      brand {
        brandKrName
        brandName
        company {
          id
          companyName
          companyKrName
        }
        id
      }
    }
    summary {
      totalCount
      totalPrice
    }
    total
  }
}
    `;
export const useOrderDetailsQuery = <TData = OrderDetailsQuery, TError = unknown>(
  variables: OrderDetailsQueryVariables,
  options?: UseQueryOptions<OrderDetailsQuery, TError, TData>,
) =>
  useQuery<OrderDetailsQuery, TError, TData>(
    ['OrderDetails', variables],
    gqlFetcher<OrderDetailsQuery, OrderDetailsQueryVariables>(
      OrderDetailsDocument,
      variables,
    ),
    options,
  );

useOrderDetailsQuery.getKey = (variables: OrderDetailsQueryVariables) => [
  'OrderDetails',
  variables,
];
export const ChannelsForReportDocument = `
    query ChannelsForReport($input: DtoFindOrderItems!) {
  channelsForReport(input: $input) {
    channelName
    displayName
  }
}
    `;
export const useChannelsForReportQuery = <
  TData = ChannelsForReportQuery,
  TError = unknown,
>(
  variables: ChannelsForReportQueryVariables,
  options?: UseQueryOptions<ChannelsForReportQuery, TError, TData>,
) =>
  useQuery<ChannelsForReportQuery, TError, TData>(
    ['ChannelsForReport', variables],
    gqlFetcher<ChannelsForReportQuery, ChannelsForReportQueryVariables>(
      ChannelsForReportDocument,
      variables,
    ),
    options,
  );

useChannelsForReportQuery.getKey = (variables: ChannelsForReportQueryVariables) => [
  'ChannelsForReport',
  variables,
];
export const StockDocument = `
    query Stock($input: GetStockInputDto!) {
  stocks(input: $input) {
    nodes {
      gspc
      inPickingQuantity
      inProcessQuantity
      incomingQuantity
      location
      problemQuantity
      bufferQuantity
      productName
      quantity
      unusableQuantity
      usableQuantity
    }
    total
  }
}
    `;
export const useStockQuery = <TData = StockQuery, TError = unknown>(
  variables: StockQueryVariables,
  options?: UseQueryOptions<StockQuery, TError, TData>,
) =>
  useQuery<StockQuery, TError, TData>(
    ['Stock', variables],
    gqlFetcher<StockQuery, StockQueryVariables>(StockDocument, variables),
    options,
  );

useStockQuery.getKey = (variables: StockQueryVariables) => ['Stock', variables];
export const StockLogsDocument = `
    query StockLogs($input: GetStockLogInputDto!) {
  stockLogs(input: $input) {
    productName
    quantity
    orderedAt
  }
}
    `;
export const useStockLogsQuery = <TData = StockLogsQuery, TError = unknown>(
  variables: StockLogsQueryVariables,
  options?: UseQueryOptions<StockLogsQuery, TError, TData>,
) =>
  useQuery<StockLogsQuery, TError, TData>(
    ['StockLogs', variables],
    gqlFetcher<StockLogsQuery, StockLogsQueryVariables>(StockLogsDocument, variables),
    options,
  );

useStockLogsQuery.getKey = (variables: StockLogsQueryVariables) => [
  'StockLogs',
  variables,
];
export const StocksDocument = `
    query Stocks($stock: GetStockInputDto!, $stockIn: GetStockLogInputDto!, $stockOut: GetStockLogInputDto!) {
  stocks(input: $stock) {
    nodes {
      gspc
      inPickingQuantity
      inProcessQuantity
      incomingQuantity
      location
      problemQuantity
      bufferQuantity
      productName
      quantity
      unusableQuantity
      usableQuantity
    }
    total
  }
  stockIn: stockLogs(input: $stockIn) {
    productName
    quantity
    orderedAt
  }
  stockOut: stockLogs(input: $stockOut) {
    productName
    quantity
    orderedAt
  }
}
    `;
export const useStocksQuery = <TData = StocksQuery, TError = unknown>(
  variables: StocksQueryVariables,
  options?: UseQueryOptions<StocksQuery, TError, TData>,
) =>
  useQuery<StocksQuery, TError, TData>(
    ['Stocks', variables],
    gqlFetcher<StocksQuery, StocksQueryVariables>(StocksDocument, variables),
    options,
  );

useStocksQuery.getKey = (variables: StocksQueryVariables) => ['Stocks', variables];
export const ExportExcelSettlementConsignmentPartnerByPartnerDocument = `
    mutation ExportExcelSettlementConsignmentPartnerByPartner($input: DtoGetSettlementConsignmentPartner!) {
  exportExcelSettlementConsignmentPartnerByPartner(input: $input)
}
    `;
export const useExportExcelSettlementConsignmentPartnerByPartnerMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ExportExcelSettlementConsignmentPartnerByPartnerMutation,
    TError,
    ExportExcelSettlementConsignmentPartnerByPartnerMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ExportExcelSettlementConsignmentPartnerByPartnerMutation,
    TError,
    ExportExcelSettlementConsignmentPartnerByPartnerMutationVariables,
    TContext
  >(
    ['ExportExcelSettlementConsignmentPartnerByPartner'],
    (variables?: ExportExcelSettlementConsignmentPartnerByPartnerMutationVariables) =>
      gqlFetcher<
        ExportExcelSettlementConsignmentPartnerByPartnerMutation,
        ExportExcelSettlementConsignmentPartnerByPartnerMutationVariables
      >(ExportExcelSettlementConsignmentPartnerByPartnerDocument, variables)(),
    options,
  );
export const ExportExcelSettlementPartnerByPartnerDocument = `
    mutation ExportExcelSettlementPartnerByPartner($input: DtoGetSettlementPartner!) {
  exportExcelSettlementPartnerByPartner(input: $input)
}
    `;
export const useExportExcelSettlementPartnerByPartnerMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ExportExcelSettlementPartnerByPartnerMutation,
    TError,
    ExportExcelSettlementPartnerByPartnerMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ExportExcelSettlementPartnerByPartnerMutation,
    TError,
    ExportExcelSettlementPartnerByPartnerMutationVariables,
    TContext
  >(
    ['ExportExcelSettlementPartnerByPartner'],
    (variables?: ExportExcelSettlementPartnerByPartnerMutationVariables) =>
      gqlFetcher<
        ExportExcelSettlementPartnerByPartnerMutation,
        ExportExcelSettlementPartnerByPartnerMutationVariables
      >(ExportExcelSettlementPartnerByPartnerDocument, variables)(),
    options,
  );
export const SettlementPartnerV2Document = `
    query SettlementPartnerV2($input: DtoGetSettlementPartnerId!) {
  settlementPartnerV2(input: $input) {
    companyCode
    companyName
    id
    status
  }
}
    `;
export const useSettlementPartnerV2Query = <
  TData = SettlementPartnerV2Query,
  TError = unknown,
>(
  variables: SettlementPartnerV2QueryVariables,
  options?: UseQueryOptions<SettlementPartnerV2Query, TError, TData>,
) =>
  useQuery<SettlementPartnerV2Query, TError, TData>(
    ['SettlementPartnerV2', variables],
    gqlFetcher<SettlementPartnerV2Query, SettlementPartnerV2QueryVariables>(
      SettlementPartnerV2Document,
      variables,
    ),
    options,
  );

useSettlementPartnerV2Query.getKey = (variables: SettlementPartnerV2QueryVariables) => [
  'SettlementPartnerV2',
  variables,
];
export const MonthlyExchangeRateDocument = `
    query MonthlyExchangeRate($input: DtoGetMonthlyExchangeRate!) {
  monthlyExchangeRate(input: $input) {
    avgExchangeRate
    countryId
    yearMonth
    currencyCriteriaAmount
  }
}
    `;
export const useMonthlyExchangeRateQuery = <
  TData = MonthlyExchangeRateQuery,
  TError = unknown,
>(
  variables: MonthlyExchangeRateQueryVariables,
  options?: UseQueryOptions<MonthlyExchangeRateQuery, TError, TData>,
) =>
  useQuery<MonthlyExchangeRateQuery, TError, TData>(
    ['MonthlyExchangeRate', variables],
    gqlFetcher<MonthlyExchangeRateQuery, MonthlyExchangeRateQueryVariables>(
      MonthlyExchangeRateDocument,
      variables,
    ),
    options,
  );

useMonthlyExchangeRateQuery.getKey = (variables: MonthlyExchangeRateQueryVariables) => [
  'MonthlyExchangeRate',
  variables,
];
export const SettlementConsignmentPartnerSummaryByPartnerDocument = `
    query SettlementConsignmentPartnerSummaryByPartner($input: DtoGetSettlementConsignmentPartner!) {
  settlementConsignmentPartnerSummaryByPartner(input: $input) {
    companyName
    fee
    salesPrice
    setoffPrice
    settlementPrice
    settlementYearMonth
  }
}
    `;
export const useSettlementConsignmentPartnerSummaryByPartnerQuery = <
  TData = SettlementConsignmentPartnerSummaryByPartnerQuery,
  TError = unknown,
>(
  variables: SettlementConsignmentPartnerSummaryByPartnerQueryVariables,
  options?: UseQueryOptions<
    SettlementConsignmentPartnerSummaryByPartnerQuery,
    TError,
    TData
  >,
) =>
  useQuery<SettlementConsignmentPartnerSummaryByPartnerQuery, TError, TData>(
    ['SettlementConsignmentPartnerSummaryByPartner', variables],
    gqlFetcher<
      SettlementConsignmentPartnerSummaryByPartnerQuery,
      SettlementConsignmentPartnerSummaryByPartnerQueryVariables
    >(SettlementConsignmentPartnerSummaryByPartnerDocument, variables),
    options,
  );

useSettlementConsignmentPartnerSummaryByPartnerQuery.getKey = (
  variables: SettlementConsignmentPartnerSummaryByPartnerQueryVariables,
) => ['SettlementConsignmentPartnerSummaryByPartner', variables];
export const SettlementConsignmentPartnerFeeSummaryByPartnerDocument = `
    query SettlementConsignmentPartnerFeeSummaryByPartner($input: DtoGetSettlementConsignmentPartner!) {
  settlementConsignmentPartnerFeeSummaryByPartner(input: $input) {
    channels {
      channelName
      fee
      id
    }
    totalFee
  }
}
    `;
export const useSettlementConsignmentPartnerFeeSummaryByPartnerQuery = <
  TData = SettlementConsignmentPartnerFeeSummaryByPartnerQuery,
  TError = unknown,
>(
  variables: SettlementConsignmentPartnerFeeSummaryByPartnerQueryVariables,
  options?: UseQueryOptions<
    SettlementConsignmentPartnerFeeSummaryByPartnerQuery,
    TError,
    TData
  >,
) =>
  useQuery<SettlementConsignmentPartnerFeeSummaryByPartnerQuery, TError, TData>(
    ['SettlementConsignmentPartnerFeeSummaryByPartner', variables],
    gqlFetcher<
      SettlementConsignmentPartnerFeeSummaryByPartnerQuery,
      SettlementConsignmentPartnerFeeSummaryByPartnerQueryVariables
    >(SettlementConsignmentPartnerFeeSummaryByPartnerDocument, variables),
    options,
  );

useSettlementConsignmentPartnerFeeSummaryByPartnerQuery.getKey = (
  variables: SettlementConsignmentPartnerFeeSummaryByPartnerQueryVariables,
) => ['SettlementConsignmentPartnerFeeSummaryByPartner', variables];
export const SettlementConsignmentPartnerSalesPriceSummaryByPartnerDocument = `
    query SettlementConsignmentPartnerSalesPriceSummaryByPartner($input: DtoGetSettlementConsignmentPartner!) {
  settlementConsignmentPartnerSalesPriceSummaryByPartner(input: $input) {
    channels {
      channelName
      id
      salesPrice
    }
    totalSalesPrice
  }
}
    `;
export const useSettlementConsignmentPartnerSalesPriceSummaryByPartnerQuery = <
  TData = SettlementConsignmentPartnerSalesPriceSummaryByPartnerQuery,
  TError = unknown,
>(
  variables: SettlementConsignmentPartnerSalesPriceSummaryByPartnerQueryVariables,
  options?: UseQueryOptions<
    SettlementConsignmentPartnerSalesPriceSummaryByPartnerQuery,
    TError,
    TData
  >,
) =>
  useQuery<SettlementConsignmentPartnerSalesPriceSummaryByPartnerQuery, TError, TData>(
    ['SettlementConsignmentPartnerSalesPriceSummaryByPartner', variables],
    gqlFetcher<
      SettlementConsignmentPartnerSalesPriceSummaryByPartnerQuery,
      SettlementConsignmentPartnerSalesPriceSummaryByPartnerQueryVariables
    >(SettlementConsignmentPartnerSalesPriceSummaryByPartnerDocument, variables),
    options,
  );

useSettlementConsignmentPartnerSalesPriceSummaryByPartnerQuery.getKey = (
  variables: SettlementConsignmentPartnerSalesPriceSummaryByPartnerQueryVariables,
) => ['SettlementConsignmentPartnerSalesPriceSummaryByPartner', variables];
export const SettlementConsignmentPartnerProductListByPartnerDocument = `
    query SettlementConsignmentPartnerProductListByPartner($input: DtoGetSettlementConsignmentPartnerProductListInput!) {
  settlementConsignmentPartnerProductListByPartner(input: $input) {
    channels {
      channelName
      countrySalesPrice
      id
      salesCount
    }
    nodes {
      channels {
        channelName
        countrySalesPrice
        feeRateV2
        id
        salesCount
      }
      countrySalesPrice
      productKrName
      rowspan
      salesCodeId
      sumCountrySalesPrice
      sumSalesCount
      sumSalesPrice
    }
    totalCountrySalesPrice
    totalSalesCount
    totalSalesPrice
  }
}
    `;
export const useSettlementConsignmentPartnerProductListByPartnerQuery = <
  TData = SettlementConsignmentPartnerProductListByPartnerQuery,
  TError = unknown,
>(
  variables: SettlementConsignmentPartnerProductListByPartnerQueryVariables,
  options?: UseQueryOptions<
    SettlementConsignmentPartnerProductListByPartnerQuery,
    TError,
    TData
  >,
) =>
  useQuery<SettlementConsignmentPartnerProductListByPartnerQuery, TError, TData>(
    ['SettlementConsignmentPartnerProductListByPartner', variables],
    gqlFetcher<
      SettlementConsignmentPartnerProductListByPartnerQuery,
      SettlementConsignmentPartnerProductListByPartnerQueryVariables
    >(SettlementConsignmentPartnerProductListByPartnerDocument, variables),
    options,
  );

useSettlementConsignmentPartnerProductListByPartnerQuery.getKey = (
  variables: SettlementConsignmentPartnerProductListByPartnerQueryVariables,
) => ['SettlementConsignmentPartnerProductListByPartner', variables];
export const SettlementConsignmentProductOrdersByPartnerDocument = `
    query SettlementConsignmentProductOrdersByPartner($input: DtoGetSettlementConsignmentProductOrders!) {
  settlementConsignmentProductOrdersByPartner(input: $input) {
    salesCount
    countrySinglePrice
    countrySalesPrice
    orderNumber
    orderedAt
    channelName
  }
}
    `;
export const useSettlementConsignmentProductOrdersByPartnerQuery = <
  TData = SettlementConsignmentProductOrdersByPartnerQuery,
  TError = unknown,
>(
  variables: SettlementConsignmentProductOrdersByPartnerQueryVariables,
  options?: UseQueryOptions<
    SettlementConsignmentProductOrdersByPartnerQuery,
    TError,
    TData
  >,
) =>
  useQuery<SettlementConsignmentProductOrdersByPartnerQuery, TError, TData>(
    ['SettlementConsignmentProductOrdersByPartner', variables],
    gqlFetcher<
      SettlementConsignmentProductOrdersByPartnerQuery,
      SettlementConsignmentProductOrdersByPartnerQueryVariables
    >(SettlementConsignmentProductOrdersByPartnerDocument, variables),
    options,
  );

useSettlementConsignmentProductOrdersByPartnerQuery.getKey = (
  variables: SettlementConsignmentProductOrdersByPartnerQueryVariables,
) => ['SettlementConsignmentProductOrdersByPartner', variables];
export const AllChannelsDocument = `
    query AllChannels {
  allChannels {
    channelName
    id
  }
}
    `;
export const useAllChannelsQuery = <TData = AllChannelsQuery, TError = unknown>(
  variables?: AllChannelsQueryVariables,
  options?: UseQueryOptions<AllChannelsQuery, TError, TData>,
) =>
  useQuery<AllChannelsQuery, TError, TData>(
    variables === undefined ? ['AllChannels'] : ['AllChannels', variables],
    gqlFetcher<AllChannelsQuery, AllChannelsQueryVariables>(
      AllChannelsDocument,
      variables,
    ),
    options,
  );

useAllChannelsQuery.getKey = (variables?: AllChannelsQueryVariables) =>
  variables === undefined ? ['AllChannels'] : ['AllChannels', variables];
export const SettlementProductSummaryByPartnerDocument = `
    query SettlementProductSummaryByPartner($input: DtoGetSettlementProductSummary!) {
  settlementProductSummaryByPartner(input: $input) {
    companyName
    salesPrice
    setoffPrice
    settlementPrice
    settlementYearMonth
  }
}
    `;
export const useSettlementProductSummaryByPartnerQuery = <
  TData = SettlementProductSummaryByPartnerQuery,
  TError = unknown,
>(
  variables: SettlementProductSummaryByPartnerQueryVariables,
  options?: UseQueryOptions<SettlementProductSummaryByPartnerQuery, TError, TData>,
) =>
  useQuery<SettlementProductSummaryByPartnerQuery, TError, TData>(
    ['SettlementProductSummaryByPartner', variables],
    gqlFetcher<
      SettlementProductSummaryByPartnerQuery,
      SettlementProductSummaryByPartnerQueryVariables
    >(SettlementProductSummaryByPartnerDocument, variables),
    options,
  );

useSettlementProductSummaryByPartnerQuery.getKey = (
  variables: SettlementProductSummaryByPartnerQueryVariables,
) => ['SettlementProductSummaryByPartner', variables];
export const SettlementProductListByPartnerDocument = `
    query SettlementProductListByPartner($input: DtoGetSettlementProductList!) {
  settlementProductListByPartner(input: $input) {
    nodes {
      productId
      productName
      salesCount
      salesPrice
      settlementCriteriaPrice
    }
    totalCount
    totalSettlementPrice
  }
}
    `;
export const useSettlementProductListByPartnerQuery = <
  TData = SettlementProductListByPartnerQuery,
  TError = unknown,
>(
  variables: SettlementProductListByPartnerQueryVariables,
  options?: UseQueryOptions<SettlementProductListByPartnerQuery, TError, TData>,
) =>
  useQuery<SettlementProductListByPartnerQuery, TError, TData>(
    ['SettlementProductListByPartner', variables],
    gqlFetcher<
      SettlementProductListByPartnerQuery,
      SettlementProductListByPartnerQueryVariables
    >(SettlementProductListByPartnerDocument, variables),
    options,
  );

useSettlementProductListByPartnerQuery.getKey = (
  variables: SettlementProductListByPartnerQueryVariables,
) => ['SettlementProductListByPartner', variables];
export const SettlementOrdersByPartnerDocument = `
    query SettlementOrdersByPartner($input: DtoGetSettlementProductOrders!) {
  settlementOrdersByPartner(input: $input) {
    channelName
    orderNumber
    orderedAt
    originalPrice
    salesCount
    salesPrice
  }
}
    `;
export const useSettlementOrdersByPartnerQuery = <
  TData = SettlementOrdersByPartnerQuery,
  TError = unknown,
>(
  variables: SettlementOrdersByPartnerQueryVariables,
  options?: UseQueryOptions<SettlementOrdersByPartnerQuery, TError, TData>,
) =>
  useQuery<SettlementOrdersByPartnerQuery, TError, TData>(
    ['SettlementOrdersByPartner', variables],
    gqlFetcher<SettlementOrdersByPartnerQuery, SettlementOrdersByPartnerQueryVariables>(
      SettlementOrdersByPartnerDocument,
      variables,
    ),
    options,
  );

useSettlementOrdersByPartnerQuery.getKey = (
  variables: SettlementOrdersByPartnerQueryVariables,
) => ['SettlementOrdersByPartner', variables];
export const PartnerLoginV3Document = `
    mutation PartnerLoginV3($input: DtoGetLogin!) {
  partnerLoginV3(input: $input) {
    accessToken
    authorizationList
    companyId
    email
    id
    role
    userName
  }
}
    `;
export const usePartnerLoginV3Mutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PartnerLoginV3Mutation,
    TError,
    PartnerLoginV3MutationVariables,
    TContext
  >,
) =>
  useMutation<PartnerLoginV3Mutation, TError, PartnerLoginV3MutationVariables, TContext>(
    ['PartnerLoginV3'],
    (variables?: PartnerLoginV3MutationVariables) =>
      gqlFetcher<PartnerLoginV3Mutation, PartnerLoginV3MutationVariables>(
        PartnerLoginV3Document,
        variables,
      )(),
    options,
  );
export const RegisterDocument = `
    mutation Register($input: DtoRegisterUser!) {
  register(input: $input) {
    id
  }
}
    `;
export const useRegisterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RegisterMutation,
    TError,
    RegisterMutationVariables,
    TContext
  >,
) =>
  useMutation<RegisterMutation, TError, RegisterMutationVariables, TContext>(
    ['Register'],
    (variables?: RegisterMutationVariables) =>
      gqlFetcher<RegisterMutation, RegisterMutationVariables>(
        RegisterDocument,
        variables,
      )(),
    options,
  );
export const SendPartnerPasswordChangeEmailV2Document = `
    mutation SendPartnerPasswordChangeEmailV2($email: String!) {
  sendPartnerPasswordChangeEmailV2(email: $email)
}
    `;
export const useSendPartnerPasswordChangeEmailV2Mutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SendPartnerPasswordChangeEmailV2Mutation,
    TError,
    SendPartnerPasswordChangeEmailV2MutationVariables,
    TContext
  >,
) =>
  useMutation<
    SendPartnerPasswordChangeEmailV2Mutation,
    TError,
    SendPartnerPasswordChangeEmailV2MutationVariables,
    TContext
  >(
    ['SendPartnerPasswordChangeEmailV2'],
    (variables?: SendPartnerPasswordChangeEmailV2MutationVariables) =>
      gqlFetcher<
        SendPartnerPasswordChangeEmailV2Mutation,
        SendPartnerPasswordChangeEmailV2MutationVariables
      >(SendPartnerPasswordChangeEmailV2Document, variables)(),
    options,
  );
export const ChangePartnerPasswordV2Document = `
    mutation ChangePartnerPasswordV2($authCode: String!, $password: String!) {
  changePartnerPasswordV2(authCode: $authCode, password: $password)
}
    `;
export const useChangePartnerPasswordV2Mutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ChangePartnerPasswordV2Mutation,
    TError,
    ChangePartnerPasswordV2MutationVariables,
    TContext
  >,
) =>
  useMutation<
    ChangePartnerPasswordV2Mutation,
    TError,
    ChangePartnerPasswordV2MutationVariables,
    TContext
  >(
    ['ChangePartnerPasswordV2'],
    (variables?: ChangePartnerPasswordV2MutationVariables) =>
      gqlFetcher<
        ChangePartnerPasswordV2Mutation,
        ChangePartnerPasswordV2MutationVariables
      >(ChangePartnerPasswordV2Document, variables)(),
    options,
  );
export const GetPartnerInfoDocument = `
    query getPartnerInfo {
  partnerMe {
    authCode
    createdAt
    email
    id
    phoneNumber
    role
    updatedAt
    userCode
    authorizations
    userName
    company {
      id
      isBeforeReportV2
      companyKrName
      salesType
    }
  }
}
    `;
export const useGetPartnerInfoQuery = <TData = GetPartnerInfoQuery, TError = unknown>(
  variables?: GetPartnerInfoQueryVariables,
  options?: UseQueryOptions<GetPartnerInfoQuery, TError, TData>,
) =>
  useQuery<GetPartnerInfoQuery, TError, TData>(
    variables === undefined ? ['getPartnerInfo'] : ['getPartnerInfo', variables],
    gqlFetcher<GetPartnerInfoQuery, GetPartnerInfoQueryVariables>(
      GetPartnerInfoDocument,
      variables,
    ),
    options,
  );

useGetPartnerInfoQuery.getKey = (variables?: GetPartnerInfoQueryVariables) =>
  variables === undefined ? ['getPartnerInfo'] : ['getPartnerInfo', variables];
export const ExistPartnerUserEmailDocument = `
    query ExistPartnerUserEmail($email: String!) {
  existPartnerUserEmail(email: $email)
}
    `;
export const useExistPartnerUserEmailQuery = <
  TData = ExistPartnerUserEmailQuery,
  TError = unknown,
>(
  variables: ExistPartnerUserEmailQueryVariables,
  options?: UseQueryOptions<ExistPartnerUserEmailQuery, TError, TData>,
) =>
  useQuery<ExistPartnerUserEmailQuery, TError, TData>(
    ['ExistPartnerUserEmail', variables],
    gqlFetcher<ExistPartnerUserEmailQuery, ExistPartnerUserEmailQueryVariables>(
      ExistPartnerUserEmailDocument,
      variables,
    ),
    options,
  );

useExistPartnerUserEmailQuery.getKey = (
  variables: ExistPartnerUserEmailQueryVariables,
) => ['ExistPartnerUserEmail', variables];
