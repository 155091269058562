import React from 'react';

import { ControlBar } from '@/views/importLicensing/importLicensingDetail/common/ControlBar';
import { ResultView } from '@/views/importLicensing/importLicensingDetail/issuanceCompletedStep/ResultView';

export const IssuanceCompletedStep = () => {
  return (
    <>
      <ControlBar />
      <ResultView />
    </>
  );
};
