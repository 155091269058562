import { useTranslation } from 'react-i18next';

export const BankInfoControlBar = () => {
  const { t } = useTranslation(['importLicensingInfo']);

  return (
    <div className='gm-flex gm-justify-between gm-items-center gm-flex-nowrap gm-overflow-x-auto'>
      <div className='gm-min-w-24'>
        <span className='gm-mr-2'>
          {t('importLicensingInfo:detailPage.steps.fifth.bankInfo.control.title')}
        </span>
        <span className='gm-font-normal gm-text-gray-600 gm-text-xs'>
          {t('importLicensingInfo:detailPage.steps.fifth.bankInfo.control.description')}
        </span>
      </div>
    </div>
  );
};
