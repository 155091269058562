import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentHeader, DefaultLayout } from '@/component';
import { Controller } from '@/views/home/Controller';
import { Dashboard } from '@/views/home/Dashboard';

export const HomePage = () => {
  const { t } = useTranslation(['home']);
  return (
    <DefaultLayout>
      <DefaultLayout.Header>{t('home:rootMenu')}</DefaultLayout.Header>
      <DefaultLayout.Content>
        <ContentHeader title={<Controller />} />
        <Dashboard />
      </DefaultLayout.Content>
    </DefaultLayout>
  );
};
