import { ICellRendererParams } from '@ag-grid-community/core';
import { Tag } from 'antd/es';
import { useTranslation } from 'react-i18next';

import { ImportLicensingSheetStepEnum } from '@/generated/graphql-react-query';

export const StepRenderer = ({ valueFormatted, value }: ICellRendererParams) => {
  const cellValue = (valueFormatted || value) as ImportLicensingSheetStepEnum;
  const { t } = useTranslation(['importLicensingInfo']);

  const stepTag: {
    [key in ImportLicensingSheetStepEnum]: { value: string; color: string };
  } = {
    [ImportLicensingSheetStepEnum.CreateProduct]: {
      value: t('importLicensingInfo:detailPage.steps.first.label'),
      color: 'blue',
    },
    [ImportLicensingSheetStepEnum.DocumentUploadPerProduct]: {
      value: t('importLicensingInfo:detailPage.steps.second.label'),
      color: 'geekblue',
    },
    [ImportLicensingSheetStepEnum.ExtraDocument]: {
      value: t('importLicensingInfo:detailPage.steps.third.label'),
      color: 'purple',
    },
    [ImportLicensingSheetStepEnum.DocumentDelivery]: {
      value: t('importLicensingInfo:detailPage.steps.forth.label'),
      color: 'orange',
    },
    [ImportLicensingSheetStepEnum.Estimate]: {
      value: t('importLicensingInfo:detailPage.steps.fifth.label'),
      color: 'cyan',
    },
    [ImportLicensingSheetStepEnum.Done]: {
      value: t('importLicensingInfo:detailPage.steps.sixth.label'),
      color: 'green',
    },
  };

  return cellValue ? (
    <Tag color={stepTag[cellValue].color}>{stepTag[cellValue].value}</Tag>
  ) : null;
};
