import { Card } from '@gomi/component';
import { Descriptions } from 'antd/es';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { BankInfoControlBar } from '@/views/importLicensing/importLicensingDetail/estimateStep/BankInfoControlBar';

export const BankInfo = () => {
  const { t } = useTranslation(['importLicensingInfo']);
  const { isMobile, isPC } = useMediaQueryHook();

  return (
    <Card
      className='gm-w-full'
      header={<BankInfoControlBar />}
      isContentsBackground={false}
    >
      <Descriptions
        bordered
        column={isPC ? 3 : 2}
        layout={isMobile ? 'vertical' : 'horizontal'}
      >
        <Descriptions.Item
          label={t('importLicensingInfo:detailPage.steps.fifth.bankInfo.accountHolder')}
        >
          {t('importLicensingInfo:detailPage.steps.fifth.bankInfo.accountHolderContent', {
            manager: '000',
          })}
        </Descriptions.Item>

        <Descriptions.Item
          label={t('importLicensingInfo:detailPage.steps.fifth.bankInfo.bankName')}
        >
          {t('importLicensingInfo:detailPage.steps.fifth.bankInfo.bankNameContent')}
        </Descriptions.Item>

        <Descriptions.Item
          label={t('importLicensingInfo:detailPage.steps.fifth.bankInfo.accountNumber')}
        >
          {t('importLicensingInfo:detailPage.steps.fifth.bankInfo.accountNumberContent')}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
