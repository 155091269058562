import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import { AuthContainer } from '@/container';
import {
  B2bOrderQuery,
  B2bOrderStatusEnum,
  useB2bOrderQuery,
} from '@/generated/graphql-react-query';

export interface B2bOrderDetail {
  id: string;
  productName: string;
  barcode: string;
  innerBoxQuantity: number;
  outerBoxQuantity: number;
  retailPrice: number;
  purchaseUnitPrice: number;
  salesPrice: number;
  inventoryQuantity: number;
  orderQuantity: number;
  totalPrice: number;
}

export interface B2bOrder {
  orderDetails: B2bOrderDetail[];
  receiverAddress: string;
  receiverName: string;
  receiverContactInformation: string;
  buyerId: string | undefined | null;
  sellerId: string | undefined | null;
  status: B2bOrderStatusEnum;
}

export const useGetB2bOrderHook = (
  id: string,
  options?: UseQueryOptions<B2bOrderQuery, unknown, B2bOrder, QueryKey>,
) => {
  const { userInfo } = AuthContainer.useContainer();
  const { data, refetch, isLoading, ...rest } = useB2bOrderQuery(
    { b2BOrderId: id },
    {
      enabled: !!id,
      select: (data) => ({
        orderDetails: data.b2bOrder.orderDetails.map((item) => ({
          id: item.id,
          productName: `${item.product.productKrName} (${item.product.productName})`,
          barcode: item.product.barcode || '-',
          innerBoxQuantity: item.product.innerBoxQuantity,
          outerBoxQuantity: item.product.outerBoxQuantity,
          retailPrice: item.retailPrice,
          purchaseUnitPrice: item.purchaseUnitPrice,
          salesPrice: item.salesPrice,
          inventoryQuantity: item.inventoryQuantity,
          orderQuantity: item.orderQuantity,
          totalPrice: item.totalPrice,
        })),
        buyerId: data.b2bOrder.orderContract.buyerCompany.id,
        sellerId: data.b2bOrder.orderContract.sellerCompany.id,
        status: data.b2bOrder.status,
        receiverAddress: data.b2bOrder.receiverAddress,
        receiverName: data.b2bOrder.receiverName,
        receiverContactInformation: data.b2bOrder.receiverContactInformation,
      }),
      ...options,
    },
  );

  return {
    b2bOrder: data || {
      orderDetails: [],
      receiverAddress: '',
      receiverName: '',
      status: B2bOrderStatusEnum.OrderReady,
      receiverContactInformation: '',
    },
    isBuyer: !!(data?.buyerId && userInfo?.partnerMe.company?.id === data?.buyerId),
    isSeller: !!(data?.sellerId && userInfo?.partnerMe.company?.id === data?.sellerId),
    refetchB2bOrder: refetch,
    isB2bOrderLoading: isLoading,
    ...rest,
  };
};
