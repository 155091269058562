import { Breadcrumb } from 'antd';
import moment, { Moment } from 'moment';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { DefaultLayout } from '@/component';
import { AuthContainer } from '@/container/AuthContainer';
import {
  CountryEnum,
  SalesTypeEnum,
  useSettlementPartnerV2Query,
} from '@/generated/graphql-react-query';
import { EmptyCard } from '@/views/settlement/common/EmptyCard';
import { MonthlySettlementHeader } from '@/views/settlement/common/MonthlySettlementHeader';
import ConsignmentDetail from '@/views/settlement/consignment';
import PurchaseBySalesDetail from '@/views/settlement/purchaseBySales';

const SettlementPage = () => {
  const { t } = useTranslation('settlementInfo');
  const { salesType } = AuthContainer.useContainer();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const yearMonthQuery = queryParams.get('year_month');
  const defaultYearMonth =
    yearMonthQuery || moment().subtract(1, 'month').format('YYYY-MM');
  const [yearMonth, setYearMonth] = useState<string>(defaultYearMonth);

  const { data } = useSettlementPartnerV2Query({
    input: {
      filter: {
        countryId: CountryEnum.Vn,
        settlementYearMonth: yearMonth,
      },
    },
  });

  const partnerId = data?.settlementPartnerV2.id || '';

  /**  handle header date */
  const handleDate = (dateString: string) => {
    sessionStorage.removeItem('referrer');
    setYearMonth(dateString);
  };

  const detailRenderer: { [key in SalesTypeEnum]: ReactNode } = {
    [SalesTypeEnum.Consignment]: <ConsignmentDetail partnerId={partnerId} />,
    [SalesTypeEnum.PurchaseBySales]: <PurchaseBySalesDetail partnerId={partnerId} />,
    [SalesTypeEnum.None]: null,
    [SalesTypeEnum.Purchase]: null,
  };

  return (
    <DefaultLayout>
      <DefaultLayout.Header>
        <Breadcrumb>
          <Breadcrumb.Item>{t('rootMenu')}</Breadcrumb.Item>
        </Breadcrumb>
      </DefaultLayout.Header>
      <DefaultLayout.Content>
        <MonthlySettlementHeader
          partnerId={partnerId}
          salesType={salesType}
          yearMonth={yearMonth}
          onChangeDate={(date: Moment | null, dateString: string) =>
            handleDate(dateString)
          }
        />
        {!partnerId && <EmptyCard yearMonth={yearMonth} />}
        {partnerId ? detailRenderer[salesType || SalesTypeEnum.None] : null}
      </DefaultLayout.Content>
    </DefaultLayout>
  );
};
export { SettlementPage };
