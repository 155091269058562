import {
  useConfirmFileCommentMutation,
  useImportLicensingCommentListQuery,
} from '@/generated/graphql-react-query';

interface ICommentProps {
  fileId: string;
  keyword?: string;
}

export const useCommentHook = ({ fileId, keyword }: ICommentProps) => {
  const { data, ...rest } = useImportLicensingCommentListQuery(
    {
      input: {
        filter: {
          fileId,
        },
        keyword,
      },
    },
    { enabled: !!fileId },
  );

  const { mutate: confirmFileComment } = useConfirmFileCommentMutation();

  const markAsReadComment = (id: string) => {
    confirmFileComment({
      confirmFileCommentId: id,
    });
  };

  return {
    markAsReadComment,
    commentList:
      data?.importLicensingCommentList.nodes.map((comment) => ({
        id: comment.id,
        writer: comment.writer?.writerName || '-',
        updateAt: comment.createdAt,
        content: comment.comment,
      })) || [],
    total: data?.importLicensingCommentList.total || 0,
    ...rest,
  };
};
