import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { numbers } from '@gomi/utils';
import { useTranslation } from 'react-i18next';

import { SettlementOrderItemSettlementStatusEnum as StatusEnum } from '@/generated/graphql';

interface IPropsListColumn {
  /** 활성화 탭 _ 판매내역 or 상계 */
  activeTab: StatusEnum;
  /** handle orders modal */
  onVisibleOrdersModal: (
    isVisible: boolean,
    productName: string,
    productId: string,
  ) => void;
}

export const ListColumn = ({
  activeTab,
  onVisibleOrdersModal,
}: IPropsListColumn): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation('settlementInfo');
  const tranTableColumn = t('tableColumn', {
    returnObjects: true,
  });

  /** 금액 콤마 표시 */
  const convertMoney = (params: ValueFormatterParams) =>
    numbers.convertMoneyText(params.value);

  /** 합계 Cell 스타일 */
  const sumCellClass = ({ node }: CellClassParams) => {
    if (node && !node.isRowPinned()) {
      return 'gm-flex gm-justify-end gm-items-center';
    }

    const fontColor =
      activeTab === StatusEnum.Settlement ? 'gm-text-blue-700' : 'gm-text-red-600';
    return `gm-flex gm-justify-end gm-items-center gm-font-semibold ${fontColor}`;
  };

  return [
    {
      // no
      headerName: tranTableColumn.no,
      field: 'no',
      headerClass: 'gm-text-center header-span-2',
      cellClass: 'gm-flex gm-justify-center gm-items-center',
      width: 64,
      colSpan: (params) => (params.node?.rowPinned === 'bottom' ? 2 : 1),
      cellRenderer: (params: ICellRendererParams) =>
        params.node.rowPinned === 'bottom' ? (
          <div className='gm-font-semibold gm-text-right'>{params.data.no}</div>
        ) : (
          Number(params.node.rowIndex) + 1
        ),
    },
    {
      // 상품 명
      headerName: tranTableColumn.productName,
      field: 'productName',
      headerClass: 'header-span-2',
      cellClass: 'gm-flex gm-justify-start gm-items-center',
      minWidth: 300,
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => (
        <div
          onClick={() =>
            onVisibleOrdersModal(true, params.data.productName, params.data.productId)
          }
          className='hover:gm-underline gm-cursor-pointer'
        >
          {params.data.productName}
        </div>
      ),
    },
    {
      // 매입 단가 (₩) = 정산 기준가
      headerName: tranTableColumn.originalPrice,
      field: 'settlementCriteriaPrice',
      width: 120,
      headerClass: 'gm-text-right header-span-2',
      cellClass: 'gm-flex gm-justify-end gm-items-center',
      cellRenderer: (params: ICellRendererParams) =>
        params.node.rowPinned === 'bottom'
          ? ''
          : numbers.convertMoneyText(params.data.settlementCriteriaPrice),
    },
    {
      // 합계
      headerName: tranTableColumn.sum,
      headerClass: 'header-span',
      children: [
        {
          // 수량
          columnGroupShow: 'open',
          headerName: tranTableColumn.salesCount,
          field: 'salesCount',
          width: 64,
          headerClass: 'gm-text-right',
          cellClass: sumCellClass,
          valueFormatter: convertMoney,
        },
        {
          // 총 판매 금액 (₩)
          columnGroupShow: 'open',
          headerName: tranTableColumn.sumSalesPrice,
          field: 'salesPrice',
          headerClass: 'gm-text-right',
          width: 120,
          cellClass: sumCellClass,
          valueFormatter: convertMoney,
        },
      ],
    },
  ];
};
