import { ColDef, ValueFormatterParams } from '@ag-grid-community/core';
import { TFunction } from 'react-i18next';

import { ReportTableColumnWidth } from '@/constant/report';
import {
  OrderPaymentStatusEnum,
  OrderShippingStatusEnum,
  SettlementOrderStatus,
} from '@/generated/graphql-react-query';
import { momentDateFormat } from '@/lib';

export const DetailOrderInfoDefaultColumn: ColDef = {
  resizable: true,
};

export const parseNumberStringFormat = (params: any): string => {
  return params.value?.toLocaleString();
};

interface IDetailOrderInfoColumn {
  tran: TFunction<('common' | 'report')[]>;
}

export const DetailOrderInfoColumn = ({ tran }: IDetailOrderInfoColumn): ColDef[] => {
  const convertDate = (params: ValueFormatterParams) =>
    params.value ? momentDateFormat.convertDateYYYYMMDDHHmm(params.value) : '';

  return [
    {
      field: 'formattedOrderedAt',
      headerName: tran('report:column.date'),
      pinned: 'left',
      width: ReportTableColumnWidth.DateColumnWidth,
    },
    {
      field: 'channel',
      headerName: tran('report:column.channel'),
      width: ReportTableColumnWidth.DefaultColumnWidth,
    },
    {
      field: 'orderNumber',
      headerName: tran('report:column.orderNumber'),
      width: ReportTableColumnWidth.DefaultColumnWidth * 3,
    },
    {
      field: 'payMethod',
      headerName: tran('report:column.payMethod'),
      tooltipField: 'payMethod',
    },
    {
      field: 'orderedAt',
      headerName: tran('report:column.orderDate'),
      valueFormatter: convertDate,
    },
    {
      field: 'orderStatus',
      headerName: tran('report:column.orderStatus'),
      width: ReportTableColumnWidth.DefaultColumnWidth,
      cellRenderer: ({ data }: { data: { orderStatus: SettlementOrderStatus } }) => {
        if (data?.orderStatus === SettlementOrderStatus.Ok) {
          return tran('report:label.ok');
        }

        if (data?.orderStatus === SettlementOrderStatus.Cancel) {
          return tran('report:label.cancel');
        }

        return '';
      },
    },
    {
      field: 'paymentStatus',
      headerName: tran('report:column.paymentStatus'),
      width: ReportTableColumnWidth.DefaultColumnWidth,
      cellRenderer: ({ data }: { data: { paymentStatus: OrderPaymentStatusEnum } }) => {
        if (data?.paymentStatus === OrderPaymentStatusEnum.Paid) {
          return tran('report:label.paid');
        }

        if (data?.paymentStatus === OrderPaymentStatusEnum.NotPaid) {
          return tran('report:label.notPaid');
        }

        if (data?.paymentStatus === OrderPaymentStatusEnum.Refund) {
          return tran('report:label.refund');
        }

        return '';
      },
    },
    {
      field: 'shippingStatus',
      headerName: tran('report:column.shippingStatus'),
      width: ReportTableColumnWidth.DefaultColumnWidth,
      cellRenderer: ({ data }: { data: { shippingStatus: OrderShippingStatusEnum } }) => {
        if (data?.shippingStatus === OrderShippingStatusEnum.Delivering) {
          return tran('report:label.delivering');
        }

        if (data?.shippingStatus === OrderShippingStatusEnum.Delivered) {
          return tran('report:label.delivered');
        }

        if (data?.shippingStatus === OrderShippingStatusEnum.Readytopick) {
          return tran('report:label.ready');
        }

        if (data?.shippingStatus === OrderShippingStatusEnum.Return) {
          return tran('report:label.return');
        }

        return '';
      },
    },
    {
      field: 'brandName',
      headerName: tran('report:column.brandName'),
      tooltipField: 'brandName',
      width: ReportTableColumnWidth.DefaultColumnWidth * 1.5,
    },
    {
      field: 'itemName',
      headerName: tran('report:column.productName'),
      tooltipField: 'itemName',
      width: ReportTableColumnWidth.ProductNameColumnWidth,
    },
    {
      field: 'originalPrice',
      headerName: tran('report:column.priceOriginal'),
      type: 'numberColumn',
      width: ReportTableColumnWidth.PriceColumnWidth,
      valueFormatter: parseNumberStringFormat,
    },
    {
      field: 'price',
      headerName: tran('report:column.price'),
      type: 'numberColumn',
      width: ReportTableColumnWidth.PriceColumnWidth,
      valueFormatter: parseNumberStringFormat,
    },
    {
      field: 'voucher',
      headerName: tran('report:column.voucher'),
      type: 'numberColumn',
      width: ReportTableColumnWidth.PriceColumnWidth,
      valueFormatter: parseNumberStringFormat,
    },
    {
      field: 'salesCode',
      headerName: 'SALES CODE',
      width: ReportTableColumnWidth.PriceColumnWidth,
    },
    {
      field: 'settlementYearMonth',
      headerName: tran('report:column.settlementMonth'),
      width: ReportTableColumnWidth.DefaultColumnWidth,
    },
    {
      field: 'count',
      headerName: tran('report:column.count'),
      type: 'numberColumn',
      width: ReportTableColumnWidth.CountColumnWidth,
      valueFormatter: parseNumberStringFormat,
    },
    {
      field: 'totalPrice',
      headerName: tran('report:column.totalPrice'),
      type: 'numberColumn',
      width: ReportTableColumnWidth.PriceColumnWidth,
      valueFormatter: parseNumberStringFormat,
    },
  ];
};
