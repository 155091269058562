import { useState } from 'react';

export const useModalHook = () => {
  const [open, setOpen] = useState(false);

  const onModalOpen = () => {
    setOpen(true);
  };

  const onModalClose = () => {
    setOpen(false);
  };

  return {
    open,
    onModalOpen,
    onModalClose,
  };
};
