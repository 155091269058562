import { useHomeSummaryQuery } from '@/generated/graphql-react-query';

export const useSummary = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const { data, isLoading } = useHomeSummaryQuery({
    input: {
      filter: {
        startedAt: startDate,
        endAt: endDate,
      },
    },
  });

  return {
    ...data?.homeSummary,
    isLoading,
  };
};
