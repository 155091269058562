import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { LoggingClick } from '@gomi/component';
import { IPaging } from '@gomi/hooks';
import { agGrid, dates } from '@gomi/utils';
import { TFunction } from 'react-i18next';

import { TRACK_EVENT_NAME } from '@/constant/track';

/** args */
interface IListColumn {
  /** 상품명 클릭시 */
  onClickProduct: (productId: string) => void;
  /** 페이징 정보 */
  paging: IPaging;
  /** 다국어 처리 */
  tran: TFunction<('partnerInfo' | 'productInfo')[]>;
  isPC: boolean;
}

/** list column */
export const ListColumn = ({
  onClickProduct,
  paging,
  tran,
  isPC,
}: IListColumn): ColDef[] | ColGroupDef[] => {
  const convertYyyyMMdd = (params: ValueFormatterParams) =>
    dates.getFormatYYYYMMDD(params.value);

  return [
    {
      headerName: tran('partnerInfo:table.no'),
      field: 'no',
      valueGetter: agGrid.getRowNo(paging),
      cellStyle: { textAlign: 'center' },
      width: 64,
    },
    {
      field: 'productKrName',
      headerName: tran('productInfo:table.productName'),
      cellStyle: { textAlign: 'left' },
      minWidth: isPC ? 450 : 380,
      flex: 1,
      cellRenderer: ({ value, data: { id } }: ICellRendererParams) => (
        <LoggingClick eventName={TRACK_EVENT_NAME.PRODUCT_DETAIL_CLICK}>
          <div
            className='hover:gm-cursor-pointer hover:gm-underline'
            onClick={() => onClickProduct(id)}
          >
            {value}
          </div>
        </LoggingClick>
      ),
    },
    {
      headerName: tran('productInfo:table.gspc'),
      field: 'gspc',
      width: isPC ? 250 : 150,
    },
    {
      headerName: tran('productInfo:table.barcode'),
      field: 'barcode',
      width: isPC ? 200 : 120,
    },
    {
      headerName: tran('productInfo:table.brandName'),
      field: 'brandName',
      width: 200,
      valueGetter: ({ data }) => {
        const brand = data.productGroup?.brand;

        return brand?.brandKrName || brand?.brandName;
      },
    },
    {
      headerName: tran('partnerInfo:table.dateUpdate'),
      field: 'updatedAt',
      width: 110,
      valueFormatter: convertYyyyMMdd,
    },
  ];
};
