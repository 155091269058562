import { PaperClipOutlined } from '@ant-design/icons';
import { Button } from '@gomi/component';
import { List } from 'antd';

export enum FileFormat {
  PDF = 'pdf',
  JPG = 'jpg',
  GIF = 'gif',
  JPEG = 'jpeg',
  PNG = 'png',
  CSV = 'csv',
  EXCEL = 'xlsx',
}

export interface FileInfo {
  id: string;
  format: FileFormat;
  fileName: string;
  url: string;
}

interface FileListProps {
  visibleFileId: string;
  changeVisibleFile(fileInfo: FileInfo): void;
  files: FileInfo[];
}

export const FileList = ({ visibleFileId, changeVisibleFile, files }: FileListProps) => {
  return (
    <List
      className='gm-w-[14%] gm-max-w-xs gm-min-w-40 gm-h-full gm-py-2 lg:gm-py-4'
      size='small'
      dataSource={files}
      renderItem={(file) => (
        <List.Item className='gm-py-0 gm-border-b-0'>
          <Button
            onClick={() => changeVisibleFile(file)}
            btnType='text'
            className={`gm-flex gm-w-full gm-items-center gm-text-${
              file.id === visibleFileId ? 'blue-600' : 'gray-700'
            }`}
            icon={<PaperClipOutlined className='gm-pr-1' />}
          >
            <div className='gm-text-ellipsis gm-whitespace-nowrap gm-overflow-hidden'>
              {file.fileName}
            </div>
          </Button>
        </List.Item>
      )}
    />
  );
};
