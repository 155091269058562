import styled from '@emotion/styled';
import React, { FC, ReactNode } from 'react';

/** style */
const Wrapper = styled.div`
  display: flex;
  flex-flow: column;

  .item-header {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .item-content {
    font-weight: 600;
    font-size: 1.5rem;
  }
`;

/** props */
export interface ICardTextItem {
  className?: string;
  header?: string;
  children?: ReactNode;
  headerClassName?: string;
  contentClassName?: string;
}

/** component: 단순 text로만 구성된 카드 */
export const CardTextItem: FC<ICardTextItem> = ({
  className,
  header,
  children,
  headerClassName,
  contentClassName,
}) => {
  return (
    <Wrapper className={className}>
      <div className={`item-header ${headerClassName}`}>{header}</div>
      <div className={`item-content ${contentClassName}`}>{children}</div>
    </Wrapper>
  );
};
