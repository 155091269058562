import moment, { MomentInput } from 'moment';

export const dates = {
  /** date 가 속한 달의 첫날 */
  getStartDayFromLastMonth: (date: MomentInput = new Date()) =>
    moment(date).startOf('month'),

  /** date 의 이전 달의 첫 날 */
  getFirstDayOfLastMonth: (date: MomentInput = new Date()) =>
    moment(date).subtract(1, 'months').date(1),

  /** date 의 이전 달의 마지막 날 */
  getLastDayOfLastMonth: (date: MomentInput = new Date()) =>
    moment(date).subtract(1, 'months').endOf('month'),

  /** date 가 속한 달의 마지막 날 */
  getLastDayOfMonth: (date: MomentInput = new Date()) => moment(date).endOf('month'),

  /** date 기준 6일 전 */
  get1WeekAgoFromToday: (date: MomentInput = new Date()) =>
    moment(date).subtract(6, 'days'),

  /** date 를 YYYY-MM-DD 형식으로 포맷 */
  getFormatYYYYMMDD: (date: MomentInput = new Date()) =>
    moment(date).format('YYYY-MM-DD'),

  /**
   * YYYYMM 형식 스트링을 월 + sufix 형태로 변환
   * @param date YYYYMM
   * @param sufix ex) " 월"
   * @returns ex) 05 월
   */
  getFormatYYYYMMtoMonth: (date: string, sufix: string = '') =>
    `${date.slice(5)}${sufix}`,
};
