import styled from '@emotion/styled';
import { DatePicker, Select } from 'antd/es';
import { flatMap } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { ALL } from '@/constant';
import { DatePickerFormat } from '@/constant/report';
import { CountryEnum, StockLocationEnum } from '@/generated/graphql-react-query';
import { useMediaQueryHook } from '@/hooks/common/useMediaQueryHook';
import { useBrandListHook } from '@/hooks/report/useBrandListHook';
import { useStore } from '@/stores';
import { RangeValue } from '@/types/report/type';

const Wrapper = styled.div`
  ${tw`gm-bg-gray-100 gm-border-gray-400 gm-border gm-my-4 gm-p-4 gm-overflow-x-auto gm-min-h-[4rem]`}

  .row {
    ${tw`gm-flex gm-items-center gm-gap-4 gm-w-max`}
    .wrap {
      ${tw`gm-flex gm-items-center`}
    }
  }

  .col {
    ${tw`gm-flex gm-flex-col gm-gap-4`}
    .label {
      ${tw`gm-block gm-mb-2`}
    }
    .ant-select {
      width: 100% !important;
    }
  }

  .row,
  .col {
    .label {
      ${tw`gm-text-sm gm-mr-2 gm-min-w-fit`}
    }
  }
`;

export const Controller = observer(() => {
  const { t } = useTranslation(['report']);
  const {
    reportControlModel: {
      countryId,
      setCountryId,
      brandId,
      setBrandId,
      startDate,
      endDate,
      setDate,
      location,
      setLocation,
    },
  } = useStore();
  const { isMobile } = useMediaQueryHook();

  const { brandList } = useBrandListHook();

  const countryOptions = flatMap(brandList, (data) => {
    return data.countryIds;
  }).map((countryId) => ({
    value: countryId,
    title: countryId,
  }));

  return (
    <>
      <div>
        <Select
          className='gm-w-30'
          defaultValue={countryId}
          value={countryId}
          showSearch
          onChange={(value: CountryEnum) => setCountryId(value)}
          options={countryOptions}
        />
      </div>
      <Wrapper className='gm-min-h-[4rem]'>
        <div className={isMobile ? 'col' : 'row'}>
          <div className='wrap'>
            <span className='label'>{t('report:column.location')}</span>
            <Select
              className='gm-w-30'
              value={location}
              showSearch
              onChange={(value: typeof ALL | StockLocationEnum) => {
                setLocation(value);
              }}
            >
              <Select.Option value={ALL}>{t('report:label.all')}</Select.Option>
              <Select.Option value={StockLocationEnum.Han}>
                {t('report:column.han')}
              </Select.Option>
              <Select.Option value={StockLocationEnum.Sgn}>
                {t('report:column.hcm')}
              </Select.Option>
            </Select>
          </div>

          <div className='wrap'>
            <span className='label'>{t('report:label.brand')}</span>
            <Select
              className='gm-w-60'
              value={brandId}
              showSearch
              onChange={(value: string) => {
                const brand = brandList.find((brand) => brand.id === value);
                if (brand) {
                  setBrandId(value);
                }
              }}
            >
              <Select.Option value={ALL}>{t('report:label.all')}</Select.Option>
              {brandList.map((brand) => (
                <Select.Option value={brand.id} key={brand.id}>
                  {brand.brandKrName || brand.brandName}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className='wrap'>
            <span className='label'>{t('report:label.period')}</span>
            <DatePicker.RangePicker
              allowClear={false}
              className='gm-w-64'
              value={[
                moment(startDate, DatePickerFormat),
                moment(endDate, DatePickerFormat),
              ]}
              onChange={(
                _dates: RangeValue<Moment>,
                [startDate, endDate]: [string, string],
              ) => setDate(startDate, endDate)}
            />
          </div>
        </div>
      </Wrapper>
    </>
  );
});
