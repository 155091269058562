import { useBrandListQuery } from '@/generated/graphql-react-query';

export const useBrandListHook = () => {
  const {
    data: brandList,
    isFetching,
    refetch,
  } = useBrandListQuery({
    input: {
      filter: {},
    },
  });

  return {
    brandList: brandList?.partnerBrandsV2.nodes || [],
    totalCount: brandList?.partnerBrandsV2.total || 0,
    isFetching,
    refetchBrandList: refetch,
  };
};
