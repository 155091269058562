import { AgTable, Card, Empty } from '@gomi/component';
import { useTranslation } from 'react-i18next';

import { useGetB2bOrderListHook } from '@/hooks/order/management/useGetB2bOrderListHook';
import { useUpdateB2bOrderHook } from '@/hooks/order/management/useUpdateB2bOrderHook';
import { FilterControlBar } from '@/views/order/management/FilterControlBar';
import { getListColumn } from '@/views/order/management/getListColumn';
import { Summary } from '@/views/order/management/Summary';

export const List = () => {
  const { t } = useTranslation(['orderInfo']);
  const { b2bOrderList, isB2bOrderListLoading, refetchB2bOrderList } =
    useGetB2bOrderListHook();
  const { toggleDisableOfB2bOrder } = useUpdateB2bOrderHook();

  const handleDisable = (id: string) => {
    toggleDisableOfB2bOrder(id, {
      onSuccess: refetchB2bOrderList,
    });
  };

  const listColumn = getListColumn({
    t,
    onDisable: handleDisable,
  });

  return (
    <>
      <Summary />
      <Card
        className='gm-h-full'
        header={<FilterControlBar />}
        isContentsBackground={false}
      >
        <AgTable
          loading={isB2bOrderListLoading}
          rowData={b2bOrderList}
          columnDefs={listColumn}
          defaultColDef={{ resizable: true }}
          noRowsOverlayComponent={() => (
            <Empty onShowImage>{t('orderInfo:managementPage.message.empty')}</Empty>
          )}
          enableBrowserTooltips
        />
      </Card>
    </>
  );
};
