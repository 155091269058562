import { Image, Spin } from 'antd';
import { useState } from 'react';

import { LoadError } from '@/views/importLicensing/importLicensingDetail/common/fileViewer/common/LoadError';

interface ImageViewerProps {
  url: string;
}

export const ImageViewer = ({ url }: ImageViewerProps) => {
  const [isLoadError, setIsLoadError] = useState(false);

  const handleReloadButtonClick = () => {
    // TODO: PDF refetch
  };

  const handleImageLoadError = () => {
    setIsLoadError(true);
  };

  return isLoadError ? (
    <LoadError onClick={handleReloadButtonClick} />
  ) : (
    <div className='gm-flex gm-justify-center gm-items-center gm-h-full gm-p-5 gm-overflow-auto'>
      <Image
        src={url}
        className='gm-max-w-full'
        placeholder={<Spin size='large' />}
        onError={handleImageLoadError}
      />
    </div>
  );
};
